import { Container } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'


const useStyles = makeStyles((theme) => ({
  section: {
    backgroundColor: theme.customColors.formSection.backgroundColor,
    marginBottom: ".6rem",
    border: `.1rem solid ${theme.customColors.lightGrey}`,
    borderRadius: ".3rem",
    padding: ".75rem 2.8rem 2rem 2.8rem",
    [theme.breakpoints.down('sm')]: {
      padding: "1rem",
    }
  }
}));


export default function FormSection({ children }) {
  const classes = useStyles();
  return (
    <Container className={classes.section}>{children}</Container>
  )
}
