import ApplicantIncome from "../../components/liheap/tx/applicant_info/ApplicantIncome";
import PersonalAndContactInfo from "../../components/liheap/tx/applicant_info/PersonalAndContactInfo";
import AddEditMembers from "../../components/liheap/tx/household/AddEditMembers";
import MemberIncome from "../../components/liheap/tx/household/MemberIncome";
import MemberPersonalInfo from "../../components/liheap/tx/household/MemberPersonalInfo";
import MemberAddEditEmployers from "../../components/liheap/tx/household/MemberAddEditEmployers";
import Review from "../../components/liheap/tx/Review";
import UtilityGeneralInformation from "../../components/liheap/tx/utility/UtilityGeneralInformation";
import UtilitySituationInformation from "../../components/liheap/tx/utility/UtilitySituationInformation";
import UtilityHousingInformation from "../../components/liheap/tx/utility/UtilityHousingInformation";
import UtilityWeatherProofingInformation from "../../components/liheap/tx/utility/UtilityWeatherProofingInformation";
import MemberDocumentUpload from "../../components/liheap/tx/household/MemberDocumentUpload";
import AddEditEmployers from "../../components/liheap/tx/applicant_info/AddEditEmployers";
import ApplicantDocumentUpload from "../../components/liheap/tx/applicant_info/ApplicantDocumentUpload";
import AdditionalDocumentUpload from "../../components/liheap/tx/AdditionalDocumentUpload";
import UtilityDocumentUpload from "../../components/liheap/tx/utility/UtilityDocumentUpload";
import Submit from "../../components/liheap/tx/Submit";
import ApplicationStatus from "../../components/liheap/ApplicationStatus";
import {
  incomeSchema, memberPersonalInfoSchema, personalAndContantInfoSchema, utilityWeatherProofingSchema,
  utilityInfoSchema, utilitySituationSchema, addEditEmployerSchema, memberAddEditEmployerSchema, utilityHousingSchema,
  applicantAdditionalInfoSchema, memberAdditionalInfoSchema, documentInfoSchema
} from "./stepValidationSchemas";
import { 
  employerFieldLabels, utilityHousingFieldLabels, incomeFieldLabels, utilityWeatherProofingFieldLabels,
  memberPersonalInfoFieldLabels, personalAndContantInfoFieldLabels, utilityFieldLabels, utilitySituationFieldLabels,
  applicantAdditionalInfoFieldLabels, memberAdditionalInfoFieldLabels, documentInfoFieldLabels
} from "../fieldLabels";
import ApplicantAdditionalInfo from "../../components/liheap/tx/applicant_info/ApplicantAdditionalInfo";
import MemberAdditionalInfo from "../../components/liheap/tx/household/MemberAdditionalInfo";
import ApplicantDocumentInfo from "../../components/liheap/tx/applicant_info/ApplicantDocumentInfo";
import MemberDocumentInfo from "../../components/liheap/tx/household/MemberDocumentInfo";
import RequiredDocuments from "../../components/liheap/RequiredDocuments";

export const processStepLabels = [
  {
    value: "Applicant Information",
    location: "personalAndContactInfo",
    link: "/liheapform/TX/personalAndContactInfo"
  },
  {
    value: "Household Members",
    location: "addEditHouseholdMembers",
    link: "/liheapform/TX/addEditHouseholdMembers"
  },
  {
    value: "Utility Information",
    location: "utilityGeneralInformation",
    link: "/liheapform/TX/utilityGeneralInformation"
  },
  {
    value: "Additional Documents",
    location: "additionalDocumentUpload",
    link: "/liheapform/TX/additionalDocumentUpload"
  },
  {
    value: "Review & Complete",
    location: "review",
    link: "/liheapform/TX/review"
  },
  {
    value: "Submit",
    location: "submit",
    link: "/liheapform/TX/submit"
  }
];

const showWeatherProofingInformation = (data) => {  
  const weatherProofingOrEfficiency = data?.weatherProofingOrEfficiency
  return weatherProofingOrEfficiency === 'yes'
}

export const processSteps = [
  {
    "location": "requiredDocuments",
    "component": RequiredDocuments,
    "showStep": true,
    "stepIndex": 0,
    "previous": "/dashboard",
    "next": "/liheapform/TX/personalAndContactInfo/:id",
  },
  {
    "location": "personalAndContactInfo",
    "component": PersonalAndContactInfo,
    "showStep": true,
    "stepIndex": 0,
    "stepSection": "applicantGeneralInformation",
    "stepValidationSchema": personalAndContantInfoSchema,
    "fields": personalAndContantInfoFieldLabels,
    "previous": "/liheapform/TX/requiredDocuments/:id",
    "next": "/liheapform/TX/applicantAdditionalInfo/:id",
  },
  {
    "location": "applicantAdditionalInfo",
    "component": ApplicantAdditionalInfo,
    "showStep": true,
    "stepIndex": 0,
    "stepSection": "applicantGeneralInformation",
    "stepValidationSchema": applicantAdditionalInfoSchema,
    "fields": applicantAdditionalInfoFieldLabels,
    "previous": "/liheapform/TX/personalAndContactInfo/:id",
    "next": "/liheapform/TX/applicantIncome/:id",
  },
  {
    "location": "applicantIncome",
    "component": ApplicantIncome,
    "showStep": true,
    "stepIndex": 0,
    "stepSection": "applicantIncomeInformation",
    "stepValidationSchema": incomeSchema,
    "fields": incomeFieldLabels,
    "previous": "/liheapform/TX/personalAndContactInfo/:id",
    "next": "/liheapform/TX/addEditEmployers/:id"
  },
  {
    "location": "addEditEmployers",
    "component": AddEditEmployers,
    "showStep": true,
    "stepIndex": 0,
    "stepSection": "applicantIncomeInformation",
    "stepValidationSchema": addEditEmployerSchema,
    "fields": employerFieldLabels,
    "previous": "/liheapform/TX/applicantIncome/:id",
    "next": "/liheapform/TX/applicantDocumentInfo/:id"
  },
  {
    "location": "applicantDocumentInfo",
    "component": ApplicantDocumentInfo,
    "showStep": true,
    "stepIndex": 0,
    "stepSection": "applicantGeneralInformation",
    "stepValidationSchema": documentInfoSchema,
    "fields": documentInfoFieldLabels,
    "previous": "/liheapform/TX/addEditEmployers/:id",
    "next": "/liheapform/TX/applicantDocumentUpload/:id"
  },
  {
    "location": "applicantDocumentUpload",
    "component": ApplicantDocumentUpload,
    "showStep": true,
    "stepIndex": 0,
    "stepSection": "applicantDocuments",
    "previous": "/liheapform/TX/applicantDocumentInfo/:id",
    "next": "/liheapform/TX/addEditHouseholdMembers/:id",
  },
  {
    "location": "addEditHouseholdMembers",
    "component": AddEditMembers,
    "showStep": true,
    "isNestedItem": true,
    "nestedFieldName": "householdMembers",
    "stepIndex": 1,
    "previous": "/liheapform/TX/applicantDocumentUpload/:id",
    "next": "/liheapform/TX/utilityGeneralInformation/:id",
  },
  {
    "location": "memberPersonalInfo",
    "component": MemberPersonalInfo,
    "showStep": true,
    "isNestedItem": true,
    "nestedFieldName": "householdMembers",
    "stepIndex": 1,
    "stepSection": "memberGeneralInformation",
    "stepValidationSchema": memberPersonalInfoSchema,
    "fields": memberPersonalInfoFieldLabels,
    "previous": "/liheapform/TX/addEditHouseholdMembers/:id",
    "next": "/liheapform/TX/memberAdditionalInfo/:id/:nestedItemId"
  },
  {
    "location": "memberAdditionalInfo",
    "component": MemberAdditionalInfo,
    "showStep": true,
    "isNestedItem": true,
    "nestedFieldName": "householdMembers",
    "stepIndex": 1,
    "stepSection": "memberGeneralInformation",
    "stepValidationSchema": memberAdditionalInfoSchema,
    "fields": memberAdditionalInfoFieldLabels,
    "previous": "/liheapform/TX/memberPersonalInfo/:id/:nestedItemId",
    "next": "/liheapform/TX/memberIncome/:id/:nestedItemId"
  },
  {
    "location": "memberIncome",
    "component": MemberIncome,
    "showStep": true,
    "isNestedItem": true,
    "nestedFieldName": "householdMembers",
    "stepIndex": 1,
    "stepSection": "memberIncomeInformation",
    "stepValidationSchema": incomeSchema,
    "fields": incomeFieldLabels,
    "previous": "/liheapform/TX/memberAdditionalInfo/:id/:nestedItemId",
    "next": "/liheapform/TX/memberAddEditEmployers/:id/:nestedItemId"
  },
  {
    "location": "memberAddEditEmployers",
    "component": MemberAddEditEmployers,
    "showStep": true,
    "isNestedItem": true,
    "nestedFieldName": "householdMembers",
    "stepIndex": 1,
    "stepSection": "memberIncomeInformation",
    "stepValidationSchema": memberAddEditEmployerSchema,
    "fields": employerFieldLabels,
    "previous": "/liheapform/TX/memberIncome/:id/:nestedItemId",
    "next": "/liheapform/TX/memberDocumentInfo/:id/:nestedItemId"
  },
  {
    "location": "memberDocumentInfo",
    "component": MemberDocumentInfo,
    "showStep": true,
    "isNestedItem": true,
    "nestedFieldName": "householdMembers",
    "stepIndex": 1,
    "stepSection": "memberGeneralInformation",
    "stepValidationSchema": documentInfoSchema,
    "fields": documentInfoFieldLabels,
    "previous": "/liheapform/TX/memberAddEditEmployers/:id/:nestedItemId",
    "next": "/liheapform/TX/memberDocumentUpload/:id/:nestedItemId"
  },
  {
    "location": "memberDocumentUpload",
    "component": MemberDocumentUpload,
    "showStep": true,
    "nestedFieldName": "householdMembers",
    "stepIndex": 1,
    "stepSection": "memberDocuments",
    "previous": "/liheapform/TX/memberDocumentInfo/:id/:nestedItemId",
    "next": "/liheapform/TX/addEditHouseholdMembers/:id",
  },
  {
    "location": "utilityGeneralInformation",
    "component": UtilityGeneralInformation,
    "showStep": true,
    "stepIndex": 2,
    "stepSection": "utilityGeneralInformation",
    "stepValidationSchema": utilityInfoSchema,
    "fields": utilityFieldLabels,
    "previous": "/liheapform/TX/addEditHouseholdMembers/:id",
    "next": "/liheapform/TX/utilityHousingInformation/:id"
  },
  {
    "location": "utilityHousingInformation",
    "component": UtilityHousingInformation,
    "showStep": true,
    "stepIndex": 2,
    "stepSection": "utilityGeneralInformation",
    "stepValidationSchema": utilityHousingSchema,
    "fields": utilityHousingFieldLabels,
    "previous": "/liheapform/TX/utilityGeneralInformation/:id",
    "next": "/liheapform/TX/utilitySituationInformation/:id",
    "hasNextCondition": true,
    "nextConditional": "/liheapform/TX/utilityWeatherProofingInformation/:id",
    "nextConditionalFunction": showWeatherProofingInformation
  },
  {
    "location": "utilityWeatherProofingInformation",
    "component": UtilityWeatherProofingInformation,
    "showStep": true,
    "stepIndex": 2,
    "stepSection": "utilityGeneralInformation",
    "stepValidationSchema": utilityWeatherProofingSchema,
    "fields": utilityWeatherProofingFieldLabels,
    "previous": "/liheapform/TX/utilityHousingInformation/:id",
    "next": "/liheapform/TX/utilitySituationInformation/:id"
  },
  {
    "location": "utilitySituationInformation",
    "component": UtilitySituationInformation,
    "showStep": true,
    "stepIndex": 2,
    "stepSection": "utilityGeneralInformation",
    "stepValidationSchema": utilitySituationSchema,
    "fields": utilitySituationFieldLabels,
    "previous": "/liheapform/TX/utilityHousingInformation/:id",
    "next": "/liheapform/TX/utilityDocumentUpload/:id",
    "hasPreviousCondition": true,
    "previousConditional": "/liheapform/TX/utilityWeatherProofingInformation/:id",
    "previousConditionalFunction": showWeatherProofingInformation
  },
  {
    "location": "utilityDocumentUpload",
    "component": UtilityDocumentUpload,
    "showStep": true,
    "stepIndex": 2,
    "stepSection": "utilityDocuments",
    "previous": "/liheapform/TX/utilitySituationInformation/:id",
    "next": "/liheapform/TX/additionalDocumentUpload/:id"
  },
  {
    "location": "additionalDocumentUpload",
    "component": AdditionalDocumentUpload,
    "showStep": true,
    "stepIndex": 3,
    "stepSection": "additionalDocuments",
    "previous": "/liheapform/TX/utilityDocumentUpload/:id",
    "next": "/liheapform/TX/review/:id"
  },
  {
    "location": "review",
    "component": Review,
    "showStep": true,
    "stepIndex": 4,
    "nestedFieldName": "householdMembers",
    "previous": "/liheapform/TX/additionalDocumentUpload/:id",
    "next": "/liheapform/TX/submit/:id"
  },
  {
    "location": "submit",
    "component": Submit,
    "showStep": true,
    "stepIndex": 5,
    "previous": "/liheapform/TX/review/:id",
    "next": "/liheapform/TX/status/:id"
  },
  {
    "location": "status",
    "component": ApplicationStatus,
    "showStep": false
  },
];
