export const getcapCountyOptions = [
  { 
    label: 'Anderson', 
    value: 'Anderson' 
  },
  { 
    label: 'Angelina', 
    value: 'Angelina' 
  },
  { 
    label: 'Chambers', 
    value: 'Chambers' 
  },
  { 
    label: 'Cherokee', 
    value: 'Cherokee' 
  },
  { 
    label: 'Galveston', 
    value: 'Galveston' 
  },
  { 
    label: 'Gregg', 
    value: 'Gregg' 
  },
  { 
    label: 'Hardin', 
    value: 'Hardin' 
  },
  { 
    label: 'Harrison', 
    value: 'Harrison' 
  },
  { 
    label: 'Henderson', 
    value: 'Henderson' 
  },
  { 
    label: 'Houston', 
    value: 'Houston' 
  },
  { 
    label: 'Jasper', 
    value: 'Jasper' 
  },
  { 
    label: 'Jefferson', 
    value: 'Jefferson' 
  },
  { 
    label: 'Kaufman', 
    value: 'Kaufman' 
  },
  { 
    label: 'Liberty', 
    value: 'Liberty' 
  },
  { 
    label: 'Nacogdoches', 
    value: 'Nacogdoches' 
  },
  { 
    label: 'Newton', 
    value: 'Newton' 
  },
  { 
    label: 'Orange', 
    value: 'Orange' 
  },
  { 
    label: 'Panola', 
    value: 'Panola' 
  },
  { 
    label: 'Polk', 
    value: 'Polk' 
  },
  { 
    label: 'Rusk', 
    value: 'Rusk' 
  },
  { 
    label: 'Sabine', 
    value: 'Sabine' 
  },
  { 
    label: 'San Augustine', 
    value: 'San Augustine' 
  },
  { 
    label: 'San Jacinto', 
    value: 'San Jacinto' 
  },
  { 
    label: 'Shelby', 
    value: 'Shelby' 
  },
  { 
    label: 'Smith', 
    value: 'Smith' 
  },
  { 
    label: 'Trinity', 
    value: 'Trinity' 
  },
  { 
    label: 'Tyler', 
    value: 'Tyler' 
  },
  { 
    label: 'Upshur', 
    value: 'Upshur' 
  },
  { 
    label: 'Van Zandt', 
    value: 'Van Zandt' 
  },
  { 
    label: 'Wood', 
    value: 'Wood' 
  },
];
