import { APIUtils } from './APIUtils';

const getFilesForApplication = (appId) => {
  return APIUtils.get(`appfile/${appId}`)
}

const getFilesForMember = (appId, nestedItemId) => {
    return APIUtils.get(`appfile/${appId}/member/${nestedItemId}`)
}

const getMergedFileForApplication = (appId, season) => {
  season = encodeURIComponent(season)
  return APIUtils.get(`file/merged/${appId}/${season}`)
}

const getMergedInProgressFileForApplication = (appId) => {
  return APIUtils.get(`file/inProgressMerged/${appId}`)
}

const createFile = (selectedFiles, appId, fileType, fileTypeLabel, employerId, memberId) => {
  let formData = new FormData();
  const headers = {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  }

  formData.append("file", selectedFiles[0])
  const postUrl = `file/${appId}/${fileType}?fileTypeLabel=${fileTypeLabel}&employerId=${employerId}&memberId=${memberId}`;

  return APIUtils
    .post(postUrl, formData, headers)
    .then(() => (getFilesForApplication(appId)));
  
}

const deleteFile = (appId, fileId, fileFolder) => {
    return APIUtils
      .delete(`file/${fileId}?fileFolder=${fileFolder}`)
      .then(() => (getFilesForApplication(appId)));
}

const getFormsForState = (state) => {
  return APIUtils.get(`stateForm/${state}`)
}

const getFormsForStateByRequired = (state, required) => {
  return APIUtils.get(`stateForm/${state}?required=${required}`)
}

const createStateForm = (data) => {
  let formData = new FormData();
  const headers = {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  }
  formData.append("file", data.file)
  const postUrl = `file/stateForm/${data.state}?title=${data.title}&description=${data.description}&required=${data.required}`;

  return APIUtils.post(postUrl, formData, headers)
}

const deleteStateForm = (fileId, state) => {
    return APIUtils.delete(`file/stateForm/${fileId}?fileFolder=${state}`)
}

export {
  getFilesForMember,
  getFilesForApplication,
  createFile,
  deleteFile,
  getMergedFileForApplication,
  getMergedInProgressFileForApplication,
  getFormsForState,
  createStateForm,
  deleteStateForm,
  getFormsForStateByRequired
};
