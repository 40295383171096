import { statuses } from "../constants/appStatuses"
import { isFeatureDisabled } from "../constants/features"

const PLEDGE_SUPPORTED_PROVIDERS = ["Entergy Services"]
const PLEDGE_SUPPORTED_TYPES = ["Regular Pledged", "Crisis"]
const INVALID_ACCOUNT_STATUSES = ["F", "PF", "WO", "N/A"]

export const FINA_ACCOUNT_ERROR = "This account is not active. Return the application to the customer so that they can enter a valid account number. If the account is in final status, they will need to contact customer service to set up a new account before proceeding."

export const isCreatePledgeEligible = (clientConfig, appData, newStatus, newStatusInfo) => {
  let isEligible = false
  const previousStatus = appData.status
  if (!isFeatureDisabled(clientConfig, appData.agencyId, "FINACreatePledge")){
    if (newStatus === statuses.APPROVED && ![statuses.PENDING, statuses.ERROR].includes(previousStatus)){
      for (const payment of newStatusInfo.payments){
        if (isSupportedPledgeProvider(payment.provider) && 
            PLEDGE_SUPPORTED_TYPES.includes(payment.type) &&
            payment.finaPledgeOptOut !== "yes"){
          isEligible = true
          break
        }
      }
    }
  }

  return isEligible
}

export const isAccountPledgeEligible = (accountInfo) => {
  let isEligible = false
  if (accountInfo && accountInfo.accountStatus && !INVALID_ACCOUNT_STATUSES.includes(accountInfo.accountStatus)){
    isEligible = true
  }
  return isEligible
}

export const isSupportedPledgeProvider = (provider) => {
  return PLEDGE_SUPPORTED_PROVIDERS.includes(provider)
}
