import * as Yup from 'yup';
import { statuses } from '../appStatuses'
import { ApprovedPaymentsValidationSchema } from '../sharedAgencyValidationSchema'

const requiredError = "Required Information";

export const AgencyStatusValidationSchema = Yup.object().shape({
  approvedPayments: Yup.array()
    .when('selectedStatus', {
      is: (selectedStatus) => selectedStatus === statuses.APPROVED,
      then: Yup.array().of(ApprovedPaymentsValidationSchema).required(requiredError)
    }),
  pledgeDate: Yup.date()
    .when('selectedStatus', {
      is: (selectedStatus) => selectedStatus === statuses.APPROVED,
      then: Yup.date().typeError('Invalid Date').required(requiredError).min('1900-01-01', 'Invalid Date').max('2099-12-31', 'Invalid Date')
    }),
  returnedNeededInfo: Yup.string()
    .when('selectedStatus', {
      is: (selectedStatus) => selectedStatus === statuses.RETURNED,
      then: Yup.string().required(requiredError)
    }),
  returnedDueDate: Yup.date()
    .when('selectedStatus', {
      is: (selectedStatus) => selectedStatus === statuses.RETURNED,
      then: Yup.date().typeError('Invalid Date').required(requiredError).min('1900-01-01', 'Invalid Date').max('2099-12-31', 'Invalid Date')
    }),
  deniedReasonSpecification: Yup.string()
    .when(['selectedStatus'], {
      is: (selectedStatus) => selectedStatus === statuses.DENIED,
      then: Yup.string().required(requiredError)
    })
  })
