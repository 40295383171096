import React, { useState, useEffect } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Controller } from 'react-hook-form';
import { getErrorMessage } from '../../utils/utilFunctions'
import { Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  applicationDateField: {
    marginTop: '1rem',
    marginBottom: '.5rem',
    backgroundColor: '#fff',
    width: '100%',
    '& > .MuiFormHelperText-contained' : {
      backgroundColor: theme.customColors.veryLightGray,
      margin: '0rem',
    }
  },
  agentDateField: {
    marginBottom: '.5rem',
    width: '100%',
    '& > .MuiFormHelperText-contained' : {
      margin: '0rem',
    }
  }
}));

export default function FormDatePicker({ control, name, errors, labelPlacement, label, defaultValue, variant, disabled, userType, validationTrigger }) {
  const classes = useStyles();

  const [helperText, setHelperText] = useState('')
  const [val, setVal] = useState(defaultValue ? new Date(defaultValue) : null)

  useEffect(() => {
    if(errors[name]){
      setHelperText(getErrorMessage(errors, name))
    }
    else{
      setHelperText('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(errors)]);

  const handleChange = (props, value) => {
    props.onChange(value)
    setVal(value)
    if(validationTrigger){
      validationTrigger(name)
    }
  }

  return (
    <>
    {(labelPlacement && labelPlacement === 'top') &&
      <Typography variant="subtitle1" style={{fontWeight: "bold", marginTop: "10px"}} >{label}</Typography>
    }
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue ? new Date(defaultValue) : null}
      render={(props) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            {...props}            
            slotProps={{
              textField: {
                helperText: helperText,
                classes: {root: userType === 'applicant' ? classes.applicationDateField : classes.agentDateField},
                error: Boolean(errors[name]),
                variant: variant
              }
            }}
            label={(labelPlacement && labelPlacement === 'top') ? "" : label} 
            onChange={(v) => handleChange(props, v)}
            value={val}
            disabled={disabled}
          />
        </LocalizationProvider>
      )}
    />
    </>
  )
}
