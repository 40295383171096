import { useEffect, useState, useContext } from 'react'
import { Grid, Typography, Button, IconButton, CircularProgress, Modal } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { crisisSituationOptions } from '../constants/ar/checkboxes/crisisSituationOptions';
import { useMutation } from 'react-query';
import { updateApplicationUrgency } from '../api/AgencyAPI'
import { AuthContext } from "../context/authContext";
import { useErrorViewer } from "../context/errorContext";
import { Close } from '@mui/icons-material';
import { agencyPermissions } from '../constants/agencyPermissions';
import SuccessModal from './SuccessModal';

function getModalStyle() {
    const top = 50;
    const left = 50;
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`
    };
  }

const useStyles = makeStyles((theme) => ({
  header: {
    padding: '1rem 0'
  },
  infoLabel: {
    fontWeight: 600,
  },
  info: {
    marginBottom: '.5rem',
    color: '#44474F',
  },
  yesNoVal: {
    textTransform: 'capitalize'
  },
  button: {
    textTransform: 'none',
    fontWeight: 600,
    margin: '1rem 0'
  },
  urgencyInfo: {
      height: '20rem',
      overflow: 'scroll'
  },
  spinner: {
      margin: '1rem'
  },
  modal: {
    position: 'absolute',
    background: 'linear-gradient(0deg, rgba(29, 93, 177, 0.11), rgba(29, 93, 177, 0.11)), #FDFBFF',
    boxShadow: '0 0 .3rem rgba(0, 0, 0, 0.2)',
    bozSizing: 'inherit',
    borderRadius: '1.75rem',
    padding: '3rem',
    width: '40rem',
    maxHeight: '35rem',
    overflow: 'scroll'
  },
  modalHeader:{
    marginBottom: '1rem',
    textTransform: 'capitalize'
  },
  modalButton: {
    marginTop: '1rem',
    textTransform: 'none',
    fontWeight: 'bold'
  },
}));

export default function UrgencyInfo({ application, open, handleClose, handleChange }){
  const { userInfo } = useContext(AuthContext);
  const userId = userInfo?.id;
  const classes = useStyles();
  const setError = useErrorViewer();
  const [modalStyle] = useState(getModalStyle);

  const [urgencyInfoOpen, setUrgencyInfoOpen] = useState(open);
  const [crisisSituations, setCrisisSituations] = useState([])
  const [changeSucessful, setChangeSucessful] = useState(false);
  const [changeType, setChangeType] = useState('');
  const userPermissions = userInfo?.permissions ? userInfo.permissions : [];

  useEffect(() => {
    setUrgencyInfoOpen(open)
  // eslint-disable-next-line
}, [open]);

useEffect(() => {
  if(application && application?.crisisSituations){
      let crisisArray = application?.crisisSituations;

      if(crisisArray && crisisArray[0] === 'noOption'){
          setCrisisSituations(['None of these apply to me'])
        }
      else{
          let crisisOps = crisisSituationOptions.filter((option) => crisisArray.includes(option.value)).map((op) => op.label)
          setCrisisSituations(crisisOps)
      }
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [application]);

  const updateUrgencyMutation = useMutation(newUrgencVal => {
    const urgencyObject = {
      applicationId: application.id,
      urgencyVal: newUrgencVal,
      userId: userId
    }
    return updateApplicationUrgency(urgencyObject);
  })

  const handleUpdateUrgency = (urgencyVal) => {
    updateUrgencyMutation.mutate(urgencyVal, {
      onSuccess: (data) => {
        handleSuccess(urgencyVal);
      },
      onError: (error) => {
        setError("An Error Occurred Updating The Application's Urgency")
      }
    })
  }

  const handleSuccess= (urgencyVal) => {
    setUrgencyInfoOpen(false);
    setChangeSucessful(true);
    setChangeType(urgencyVal);
  };

  const handleCloseSuccess = () => {
    handleChange('urgency');
    setChangeSucessful(false);
  }

  return(
    <>
    <Modal open={urgencyInfoOpen} onClose={() => handleClose('urgency')}>
      <Grid  style={modalStyle} className={classes.modal}>
        <Grid container item xs={12}>
          <Grid container className={classes.header}>
            <Grid container item xs={10}>
              <Typography variant='h6' >
                {`Urgency for ${application?.firstName} ${application?.lastName}`}
              </Typography>
            </Grid>
            <Grid container item xs={2} justifyContent='flex-end'>
                <IconButton onClick={() => handleClose('urgency')}>
                  <Close/>
                </IconButton>
            </Grid>
        </Grid>
        <Grid item xs={12} container className={classes.urgencyInfo}>
            <Grid item xs={12}>
                <Typography variant='subtitle2' className={classes.infoLabel}>Has your service been recently shut off due to an unpaid bill?</Typography>
                <Typography variant='subtitle2' className={`${classes.info} ${classes.yesNoVal}`}>{application?.serviceShutOff}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant='subtitle2' className={classes.infoLabel}>Do you have a disconnect date within 7 days?</Typography>
                <Typography variant='subtitle2' className={`${classes.info} ${classes.yesNoVal}`}>{application?.disconnectNotice}</Typography>
             </Grid>
            <Grid item xs={12}>
                <Typography variant='subtitle2' className={classes.infoLabel}>Is your situation life-threatening?</Typography>
                <Typography variant='subtitle2' className={`${classes.info} ${classes.yesNoVal}`}>{application?.crisisLifeThreatening}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant='subtitle2' className={classes.infoLabel}>Please explain in detail</Typography>
                <Typography variant='subtitle2' className={classes.info}>{application?.crisisExplaination ? application?.crisisExplaination : 'N/A'}</Typography>
            </Grid>
            {application?.crisisSituations &&
              <Grid item xs={12}>
                <Typography variant='subtitle2' className={classes.infoLabel}>Do any of the following apply to you?</Typography>
                {crisisSituations.map((situation, index) => (
                    <Typography variant='subtitle2' key={index} className={classes.info}>{situation}</Typography>
                ))}    
              </Grid>
            }
            </Grid>
            {/* Only agent with edit privileges can update application urgency. State user is view-only. */}
            {userPermissions.includes(agencyPermissions.REVIEW_APPLICATION_EDIT) && 
              <Grid item container xs={12} justifyContent='flex-end'>
                {updateUrgencyMutation.isLoading ? 
                    <Grid justifyContent="center" className={classes.spinner}>
                        <CircularProgress color="primary" />
                    </Grid>
                :
                <>
                    <Button variant='text' className={classes.button} onClick={() => handleUpdateUrgency('removed')}>Remove</Button>
                    <Button variant='text' className={classes.button} onClick={() => handleUpdateUrgency('confirmed')}>Confirm</Button>
                </>
                }
              </Grid>
            }
        </Grid>
      </Grid>
    </Modal>

    <SuccessModal
      open={changeSucessful}
      title={changeType}
      desc={`Urgent status for ${application?.firstName} ${application?.lastName} ${changeType}.`}
      okLabel="Okay"
      handleOk={handleCloseSuccess}
    />
    </>
  );
}
