import { Grid, Typography,Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ExpandMore } from '@mui/icons-material'
import React, {useEffect, useState} from 'react'
import { isEmptyObject } from '../../../../../utils/utilFunctions';

const useStyles = makeStyles((theme) => ({
  expandIcon: {
    color: theme.palette.primary.main,
    padding: '.75rem',
    [theme.breakpoints.down('md')]: {
      marginLeft: '1rem'
    }
  },
  details: {
    padding: '0 1rem 1rem 1rem'
  },
  field: {
    padding: '.5rem'
  },
  fieldBorder: {
    padding: '.5rem',
    borderBottom: '1px solid #c4c4c4'
  },
  fieldLabel:{
    padding: '.75rem 0',
    minHeight: '1.5rem',
    marginRight: '1rem',
    fontWeight: 'bold'
  },
  fieldValue:{
    color: '#000'
  },
  highlightValue:{
    color: theme.customColors.applicationStatus.approved
  },
  link: {
    color: theme.palette.primary.main
  }
}));

export default function TXReviewBenefitSection({ openAll, app, unearnedOptions, fuelSources, stateDocsLinks }) {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(true);
    const suggestedBenefit = app.suggestedBenefit;
    const quarters = ["Quarter 1", "Quarter 2", "Quarter 3", "Quarter 4"];

    useEffect(() => {
        setExpanded(openAll)
        // eslint-disable-next-line
    }, [openAll]);

    const setExpandedValue = () => {
        setExpanded(prevState => {return !prevState})
    }

    const getField = (label, value, index, isBold, isDollars, showBorder, isHighlighted=false) => (
        <Grid container justifyContent="space-between" direction="row" key={`${label}-${index}`} className={showBorder? classes.fieldBorder : classes.field}>
                <Typography variant="subtitle2" fontWeight={isBold? 'bold' : 'normal'}>
                    <label htmlFor={`${label}-id`}>
                        {label}
                    </label>
                </Typography>
                <Typography variant="subtitle2" textAlign='right' className={isHighlighted ? classes.highlightValue: classes.fieldValue} fontWeight={isBold? 'bold' : 'normal'} > 
                    {isDollars? '$ ' : '' }
                    {value}
                </Typography>
        </Grid>
    )

    const getFieldLabel = (fieldName, options) => {
        const retVal = options.find((item) => (item.value === fieldName));
        return retVal ? retVal.label : 'unknown';
    }

    if(!suggestedBenefit || isEmptyObject(suggestedBenefit))
        return <></>
    else 
        return (
            <Accordion expanded={expanded}>
                <AccordionSummary
                    expandIcon={<ExpandMore className={classes.expandIcon}/>}
                    aria-label={`benefit-content`}
                    id={`benefit-header`}
                    onClick={setExpandedValue}
                >
                    <Grid item xs={12}>
                        <Grid item xs={12}>
                            <Typography variant='subtitle2'>Suggested Benefit Amount</Typography> 
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                    <Grid container item xs={12} direction="row">
                        <Grid item md={12} lg={6} >
                            { suggestedBenefit.unearnedIncome.map((item, index) => (
                                    getField(getFieldLabel(item.label, unearnedOptions), item.value, index, false, true)
                                ))
                            }
                            { getField("Total Estimated Annual Unearned Income", suggestedBenefit.unearnedIncomeTotal, 1, true, true, true) }
                            { suggestedBenefit.earnedIncome.map((item, index) => (
                                    getField(item.label, item.value, index, false, true, false)
                                ))
                            }
                            { getField("Total Estimated Annual Earned Income", suggestedBenefit.earnedIncomeTotal, 1, true, true, true) }
                            { getField("Countable Income:", "", 0, true, false, false) }
                            { getField("Unearned Income + Earned Income", suggestedBenefit.countableIncome, 1, true, true, false) }
                        </Grid>
                        <Grid item md={12} lg={6}>
                            <>
                            { getField("Household Size", suggestedBenefit.householdSize, 1, false, false, false) }
                            { getField("Max Countable Income", suggestedBenefit.maxCountableIncome, 1, false, true, false) }
                            { getField("", suggestedBenefit.isEligible ? "Eligible" : "Not Eligible", 1, true, false, true, true) }
                            { getField("Primary Source", getFieldLabel(suggestedBenefit.primarySource, fuelSources), 1, false, false, false) }
                            { getField("Annual Benefit Amount", suggestedBenefit.annualSuggestedBenefit, 1, true, true, false, true) }
                            {quarters.map(quarter => (
                              <Grid container justifyContent="space-between" direction="row" className={classes.field}>
                                  <Typography variant="subtitle2">
                                    <label htmlFor={`${quarter}-id`}>
                                      {quarter}
                                    </label>
                                  </Typography>
                                  <Typography variant="subtitle2" textAlign='right' className={classes.fieldValue}  >                  
                                      ${suggestedBenefit.quarterlySuggestedBenefit}
                                  </Typography>
                              </Grid>
                            ))}                         
                            </>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        );
}
