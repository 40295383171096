export const submitTermHeading = "By typing my name in below or signing my name, electronically or otherwise, I am signing this document, agreeing to LIHEAP Applicant Portal Terms of Use, and agreeing to the following on behalf of myself or the applicant, (and in the latter case, represent and warrant that I am authorized to do so), and certify as follows:"

export const submitTerm1 = "1. I  understand   that   I   have   the   right   to   appeal   any   decision   regarding   this application   which   I   consider   improper,   and   also   any   delay   in   decision   or delivery of services."

export const submitTerm2 = "2. I understand that I must help establish my eligibility by providing as much information as I can about my circumstances."

export const submitTerm3 = "3. I   authorize   the   contracted   agency   to   release   information   relating   to   my application for LIHEAP to my Energy Supplier(s) to determine eligibility. I give permission to the Arkansas Energy Office (AEO) to use information provided on this form for purposes of research, evaluation, and analysis of the program."

export const submitTerm4 = "4. I   understand   that   my   utility   service   provider   will   have   no   control   over   the data   disclosed   pursuant   to   this   consent   and   will   not   be   responsible   for monitoring or taking any steps to ensure that the LIHEAP office maintains the confidentiality of the data or uses the data as I have authorized."

export const submitTerm5 = "5. I understand that no person may be denied assistance on the basis of race, color, sex, age, handicap, religion, national origin, or political belief."

export const submitTerm6 = "6. I understand that my signature on this application authorizes the agency to make any investigation concerning me or any household member and/or use a   copy   as   a   release   of   information   for   securing   information   needed   to determine my eligibility for services."

export const submitTerm7 = "7. I understand that if I receive assistance to which I am not entitled as a result of   withholding   information   or   knowingly   providing   false   or   fraudulent information   regarding   my   circumstances,   I   must  repay   the   cost   of   any assistance and may face penalty of criminal prosecution."

export const submitTerm8 = "8. The information given on this application is true to the best of my knowledge and   belief.   I  understand   that   this   form   is   signed   subject   to   penalties   for perjury."
