import { stripPhoneNumber } from '../utils/utilFunctions';
import { APIUtils } from './APIUtils';

const userLogin = (userName, password) => {
    const loginData = {
        userName,
        password
    };

    return APIUtils.post(`user/login`, loginData)
}

const createInvitation = (invitation) => {
    return APIUtils.post(`invitation`, invitation);
}

const getInvitation = (inviteId) => {
    return APIUtils.get(`invitation/${inviteId}`);
}

const acceptInvite = (registration) => {
    const newReg = Object.assign({}, registration, { "phone" : stripPhoneNumber(registration["phone"])});

    return APIUtils.post(`user/accept`, newReg);
}

const userRegister = (registration) => {
    const newReg = Object.assign({}, registration, { "phone" : stripPhoneNumber(registration["phone"])});

    return APIUtils.post(`user/register`, newReg);
}

const userConfirm = (userName, confirmationCode) => {
    const confirmation = {
        userName,
        confirmationCode
    };

    return APIUtils.post(`user/confirm`, confirmation)
}

const userResendConfirm = (userName) => {
    const confirmation = {
        userName,
    };

    return APIUtils.post(`user/resendconfirm`, confirmation)
}

const userConfirmReset = (email, confirmationCode, password) => {
    const confirmation = {
        email,
        confirmationCode,
        password
    };

    return APIUtils.post(`user/confirmreset`, confirmation);
}

const userResetPassword = (email) => {
    const resetInfo = {
        email
    };

    return APIUtils.post(`user/reset`, resetInfo)
}

const userChangePassword = (token, oldPassword, newPassword) => {
    const resetInfo = {
        token,
        oldPassword,
        newPassword
    };

    return APIUtils.post(`user/password`, resetInfo)
}

const userUpdatePhone = (userId, token, phone) => {
    const updateInfo = {
        userId,
        token,
        phone
    };
    return APIUtils.post(`user/updatePhone`, updateInfo)
}

const userConfirmResetAttribute = (token, attributeName, code) => {
    const confirmInfo = {
        token,
        attributeName,
        code
    };
    return APIUtils.post(`user/confirmUpdateAttribute`, confirmInfo)
}

const getUserInfo = (userId) => {
    return APIUtils.get(`user/info/${userId}`)
}

const updateUserInfo = (userId, updates) => {
    return APIUtils.patch(`user/info/${userId}`, updates)
}


export {
    userLogin,
    userRegister,
    userConfirm,
    userResetPassword,
    userResendConfirm,
    userConfirmReset,
    acceptInvite,
    createInvitation,
    getInvitation,
    userUpdatePhone,
    userConfirmResetAttribute,
    userChangePassword,
    getUserInfo,
    updateUserInfo
};