import { Button, CircularProgress, Grid, Box, Tab, Tabs, Divider} from "@mui/material";
import { useState } from "react";
import { FileDownloadOutlined } from "@mui/icons-material";
import { useErrorViewer } from "../../../context/errorContext";
import { downloadCSV } from "../../../utils/utilFunctions";
import DateRangePicker from "../../DateRangePicker";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import useStateReportData from "../../../hooks/useStateReportData";
import StateStatusReportTable from "../components/StateStatusReportTable";
import StateFundsReportTable from "../components/StateFundsReportTable";
import StateCrisisVsRegReportTable from "../components/StateCrisisVsRegReportTable";

export default function StateReportsMain() {
  const [dateRangeValue, setDateRangeValue] = useState([null, null]);
  const [tabIndex, setTabIndex] = useState(0);
  const [validDateRange, setValidDateRange] = useState(false)
  const dateUtil = new AdapterDateFns();
  const [reportType, setReportType] = useState("status")
  const [dateFilter, setDateFilter] = useState({})

  const { isFetching, isError, data } = useStateReportData(reportType, dateFilter)
  const setError = useErrorViewer();


  const reportTypeTabMap = {
    0: "status",
    1: "funds",
    2: "crisisvsreg"
  }

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex)
    setReportType(reportTypeTabMap[newTabIndex])
  
  }

  const handleDateRangeSelection = (startDate, endDate, errors) => {
    setDateRangeValue([startDate,endDate])
    setValidDateRange(!errors && startDate && dateUtil.isValid(startDate) && endDate && dateUtil.isValid(startDate))  
    if (!startDate && !endDate) {
      //filters cleared
      setDateFilter({})
    }
  }

  const handleApplyDateFilters = () => {
    let endDate = new Date(dateRangeValue[1])
    setDateFilter({
      "dateFilter": {
        "field" : "initialSubmittedDate",
        "gte" : dateRangeValue[0],
        "lte" : new Date(endDate.setDate(endDate.getDate() + 1))
      }   
    })
  }

  const jsonToCSV = (jsonData) => {
    const items = jsonData
    const replacer = (key, value) => value || value === 0 ? value : '0'
    let header = []
    //get distinct keys for csv header
    for(var i = 0;i < items.length; i++) {
      Object.keys(items[i]).forEach(function(key){
        if(header.indexOf(key) === -1) {
          header.push(key);
        }
      });
    }
    //const header = Object.keys(items[0])
    const csv = [
      header.join(','), // header row first
      ...items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
    ].join('\r\n')
    return csv
  }

  const onDownloadClick = (isDefaultReport) => {
    downloadCSV(jsonToCSV(data), `${reportType}_report_${new Date().toLocaleDateString("en-US")}`);   
  }

  if (isError) {
    setError("Error retrieving report data.")
  }

  

  return(
    <Box sx={{ ml: "2rem", textAlign:'center'}}>
      <Box>
        <Tabs value ={tabIndex} onChange={handleTabChange}>
          <Tab label="Status Report"  />
          <Tab label="Funds Report"/>
          <Tab label="Crisis Vs Regular Report"/>
        </Tabs>
      </Box>
      <Box> 
        <Grid item xs={12} sx={{mt: "2rem", mb: "2rem"}} container>
          <Grid item xs={6}>
            <DateRangePicker onDateRangeSelection={handleDateRangeSelection}/>
          </Grid>
          <Grid item xs={6} container justifyContent="end">
            <Button 
              variant="outlined"
              color="primary"
              component="span" 
              size='large' 
              onClick={() => handleApplyDateFilters()}
              disabled={!validDateRange}
            >      
              Apply Date Filter
            </Button>
          </Grid>
        </Grid>
        <Divider/>
        { isFetching ?                  
          <>        
            <Grid item xs={12} sx={{m: 5}} style={{textAlign: "center"}}>
              <CircularProgress color="primary" size={100} thickness={3}/> 
            </Grid>              
          </>
          :
          <>
            <Grid item xs={12} container justifyContent="end" sx={{mt: "2rem", mb: "2rem"}}>
              <Button 
                variant="text"
                color="primary"
                component="span" 
                size='large' 
                startIcon={
                  <FileDownloadOutlined color='#fff' />            
                }
                onClick={() => onDownloadClick()}
                disabled={!data}
              >      
                Save CSV
              </Button>
            </Grid>
            {reportType==="status" && 
              <Box>
                <StateStatusReportTable data={data} isFetching={isFetching}/>
              </Box>
            }
            {reportType==="funds" && 
              <Box>
                <StateFundsReportTable data={data} isFetching={isFetching}/>
              </Box>
            }
            {reportType==="crisisvsreg" &&
              <Box>
                <StateCrisisVsRegReportTable data={data} isFetching={isFetching}/>
              </Box>
            } 
          </>
        }          
      </Box>
    </Box>
  )
}
