export const cdcaaCountyOptions = [
  {
    value: 'Arkansas',
    label: 'Arkansas'
  },
  {
    value: 'Cleveland',
    label: 'Cleveland'
  },
  {
    value: 'Grant',
    label: 'Grant'
  },
  {
    value: 'Jefferson',
    label: 'Jefferson'
  },
  {
    value: 'Lincoln',
    label: 'Lincoln'
  }
];