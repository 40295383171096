import { useQuery } from 'react-query';
import { getFormsForStateByRequired } from '../api/FileAPI';

/**
* Fetch state form for give state and filter by required value
*/
export default function useStateForms(state, required) {
  return useQuery(['getFormsForStateByRequired', {state:state, required:required}], async () => {
    const { data } = await getFormsForStateByRequired(state, required)
    .then((response) => {
      if(response){
        let data = []
        data= response?.data;
        return {data: data}
      }      
    })
    return data;
  }, {enabled: true});
}
