import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography, Button, useMediaQuery, useTheme } from '@mui/material';
import { useHistory } from 'react-router';
import { howItWorksTextBlock1, howItWorksTextBlock2, howItWorksTextBlock3, howItWorksTextBlock4, howItWorksTextBlock5  } from '../../constants/home-page/howItWorks';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';
import { slides } from '../../constants/home-page/slides';

const useStyles = makeStyles((theme) => ({
    mainContainer:{
        margin: '2.5rem 0rem',
        width: 'fit-content',
        [theme.breakpoints.down('md')]: {
            margin: 'auto'
        }
    },
    info: {
        padding: '2rem 3rem 2rem 6rem !important',
        margin: 'auto',
        [theme.breakpoints.down('lg')]: {
            padding: '1rem 2rem !important',
            margin: 'auto',  
        },
    },
    title: {
        color: '#000',
        fontSize: '2.25rem',
    },
    subtext: {
        color: '#000',
        fontSize: '1rem',
        margin: '1.25rem 0rem'
    },
    button: {
        width: '13.625rem',
        textTransform: 'none',
        margin: '1rem 0',
        [theme.breakpoints.down('lg')]: {
            marginBottom: '2rem'
          },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
          },
    },
    slideContainer: {
        alignItems: 'center',
        paddingRight: '6rem',
        [theme.breakpoints.down('lg')]: {
            padding: '1rem 2rem !important',
            margin: 'auto',  
        }
    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    image: {
        marginRight: '3rem',
        maxHeight: '35rem',
    },
    selectedSlideDesc: {
        color: theme.customColors.deepBlue,
        fontWeight: 700,
        marginBottom: '2rem',
        fontSize: '1rem',
        cursor: 'pointer'
    },
    slideDesc: {
        color: '#000',
        marginBottom: '2rem',
        fontSize: '1rem',
        cursor: 'pointer'
    },

    carousel:{
        display: 'grid',
        marginBottom: '1rem',
        '& > .carousel':{
            width: 'auto',
            '& > .thumbs-wrapper':{
                display: 'none'
            },
            '& > .control-dots':{
                margin: 0,
                '& > .dot':{
                    background: '#fff',
                    width: '.75rem',
                    height: '.75rem',
                    margin: '0 1rem',
                    border: `2px solid ${theme.customColors.deepBlue}`,
                    opacity: '1',
                    boxShadow: 'none'
                },
                '& > .selected':{
                    background: theme.customColors.deepBlue,
                    width: '.75rem',
                    height: '.75rem',
                    margin: '0 1rem'
                },
                
            },
            '& > .control-arrow':{
                display: 'none'
            },
            '& > .carousel-status':{
                display: 'none'
            },
        }
    },
    carouselSlide: {
        padding: '1rem 4rem'
    },
    carouselDesc: {
        color: theme.customColors.deepBlue,
        fontWeight: 700,
        padding: '1rem 0',
        fontSize: '1rem',
    },
    
}));

export default function HowItWorks() {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const matchesXsDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [activeSlide, setActiveSlide] = useState(slides[0])

  const handleSlideClick = (slideNum) => {
    let selectedSlide = slides.find(x => x.slideNum === slideNum);
    setActiveSlide(selectedSlide)
  }

  const changeSlide = () => {
    setActiveSlide((current) => {
        return current.slideNum === 6 ? slides[0] : slides[current.slideNum]
    })
  }
 
  window.onload = function () {
    setInterval(changeSlide, 3000);
  };

  return (
    <Grid container spacing={1} className={classes.mainContainer}>
        <Grid item md={12} lg={6} className={classes.info}>
            <Typography className={classes.title}>How It Works</Typography>
            <Typography className={classes.subtext}>
                {howItWorksTextBlock1}
            </Typography>
            <Typography className={classes.subtext}>
                {howItWorksTextBlock2}
            </Typography>
            <ul>
                <li>
                    <Typography className={classes.subtext}>
                        {howItWorksTextBlock3}                    
                    </Typography>
                </li>
                <li>
                    <Typography className={classes.subtext}>
                        {howItWorksTextBlock4}                    
                    </Typography>
                </li>
            </ul>
            <Typography className={classes.subtext}>
                {howItWorksTextBlock5}            
            </Typography>
            <Grid container item xs={12}>
                <Button variant='contained' color='primary' className={classes.button} onClick={() => history.push(`/register`)}>
                    Apply Today
                </Button>
            </Grid>
        </Grid>

        <Grid container item md={12} lg={6} >
            {!matchesXsDown ?
                <Grid item container className={classes.slideContainer}> 
                    <Grid item xs={12} sm={6} md={6} className={classes.imageContainer}>
                        <img className={classes.image} src={activeSlide.path} alt="" />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                        {slides.map((slide) => 
                            <Typography 
                                key={slide.slideNum} 
                                className={activeSlide.slideNum === slide.slideNum ? classes.selectedSlideDesc : classes.slideDesc} 
                                onClick={() => handleSlideClick(slide.slideNum)}
                            >
                                {slide.desc}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
                :
                <Grid container item >
                    <Carousel infiniteLoop autoPlay className={classes.carousel}>
                        {slides.map((slide) => 
                            <Grid item xs={12} className={classes.carouselSlide} key={slide.slideNum}>
                                <img src={slide.path} alt="" />
                                <Typography className={classes.carouselDesc}>{slide.desc}</Typography>
                            </Grid>
                        )}
                    </Carousel>
                </Grid>
            }
        </Grid>
    </Grid>
)}

