export const healthInsuranceTypeOptions = [
  {
    value: 'medicaid',
    label: 'Medicaid'
  },
  {
    value: 'medicare',
    label: 'Medicare'
  },
  {
    value: 'stateChildrenInsProgram',
    label: 'State Children\'s Health Insurance Program'
  },
  {
    value: 'militaryHealthCare',
    label: 'Military Health Care'
  },
  {
    value: 'directPurchase',
    label: 'Direct Purchase'
  },
  {
    value: 'employmentBased',
    label: 'Employment Based'
  },
  {
    value: 'other',
    label: 'Other'
  }
]

