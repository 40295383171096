import { Grid, Button, CircularProgress } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useState } from "react";
import { getMergedFileForApplication } from "../../../api/FileAPI";
import { Print } from '@mui/icons-material'
import printJS from 'print-js'
import { useErrorViewer } from "../../../context/errorContext";

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: 'none',
    padding: '.75rem'
  },
  downloadIcon: {
    marginRight: '.5rem'
  }
}));

export default function PrintMergedPDF ({ appData }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const baseUrl = `${window.location.origin}/api/file/`
  const setError = useErrorViewer();

  const handleClick = (appData) => {
    if(!appData){
      setError("Application data is empty.")
      return;
    }
    setLoading(true);
    getMergedFileForApplication(appData?.id, appData?.recordType)
        .then((response) => {
          setLoading(false);
          printJS({printable: `${baseUrl + response.data?.url}`});
        })
        .catch((error) => {
            setLoading(false);
            setError("An Error occurred printing the consolated PDF. However, each file is printable individually from the Documents section.")
        });
  }

  if (loading) {
    return (
      <Grid item justifyContent="flex-start" >
        <CircularProgress color="primary" size={20} />
      </Grid>
    )
  }
  return (
    <Button
      className={classes.button}
      onClick={() => handleClick(appData)}
      variant="text"
      color="primary"
    >
      <Print className={classes.downloadIcon}/>
      Print PDF
    </Button>
)

}
