import { agencyPermissions } from "../agencyPermissions";

export const reviewAppPermissionOptions = [
  {
    value: agencyPermissions.REVIEW_APPLICATION_EDIT,
    label: 'Edit'
  },
  {
    value: agencyPermissions.REVIEW_APPLICATION_VIEW,
    label: 'View Only'
  },
  {
    value: agencyPermissions.REVIEW_APPLICATION_NONE,
    label: 'No Access'
  }
];