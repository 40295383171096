import { Container, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useParams } from 'react-router-dom';
import useLiheapForm from '../../../../hooks/useLiheapForm';
import StepButtons from '../../../StepButtons'
import StepHeader from '../../../StepHeader'
import Employers from '../Employers';
import PreviousEmployment from '../PreviousEmployment';
import { lastEmployedDateOnEditSchema } from '../../../../constants/tx/stepValidationSchemas';

const useStyles = makeStyles((theme) => ({
  mainContainer:{
    margin: "0"
  }
}));

export default function MemberAddEditEmployers({ data, back, next, save, validationSchema, validate }) {
  const classes = useStyles();
  const { nestedItemId: memberId } = useParams();
  const validateOnEditValidationSchema = lastEmployedDateOnEditSchema;
  const memberData = data?.householdMembers?.find(x => x.id === memberId);
  const employersData = memberData?.employers;
  const workStatus = memberData?.workStatus;

  const nestedSave= (formData) => {
    formData.id = memberId;  //add nestedItemId to returned formData
    save(formData);
  }
  const { control, errors, register, watch, setValue, trigger } = useLiheapForm(nestedSave, memberData, validate, validationSchema, false, validateOnEditValidationSchema);

  //show same page as unemployment if they did not set workStatus
  const determineEmploymentStatus = () => {
    if (workStatus === 'fullTime' || workStatus === 'partTime' || workStatus === 'migrantSeasonalOrFarmWorker') {
      return "employed";
    }else {
        return "unemployed";
    }
  }

  const employmentStatus = determineEmploymentStatus();

  const getTitle = (heading) => {
    const person = data?.householdMembers?.find(x => x.id === memberId);
    const itemIndex = person && (data?.householdMembers?.indexOf(person) >= 0) ? data?.householdMembers?.indexOf(person) + 1 : data?.householdMembers?.length + 1 || 1;
    return person && person.lastName && person.firstName ? `${heading} for ${person.firstName} ${person.lastName}` : `${heading} for Household Member #${itemIndex}`;
  }

  return (
    <Container className={classes.mainContainer}>
        <input type="hidden" name="workStatus" ref={register} defaultValue={memberData?.workStatus ? memberData.workStatus : ""}/>
        <Grid container spacing={1}>
          <StepHeader 
            title={getTitle("Employment Information")}
            desc = "Please provide the following information for this household member."
          />
          { employmentStatus === 'employed' &&
            <Employers 
              employersData={employersData}
              parentType={"householdMember"} 
              control={control} 
              register={register} 
              validate={validate} 
              errors={errors}
              watch={watch}
            />
          }
          { employmentStatus === 'unemployed' &&
            <PreviousEmployment
              control={control}
              register={register}
              errors={errors}
              setValue={setValue}
              type={"householdMember"}
              data={memberData}
              trigger={trigger}
            />
          }
          <StepButtons back={back} next={next}/>
        </Grid>
    </Container>
  )
}
