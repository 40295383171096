import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { FormControl, InputLabel, Select, TextField, Autocomplete, Checkbox, Grid } from '@mui/material';
import CheckedIcon from '../../CheckedIcon'
import DateRangePicker from '../../DateRangePicker';

let dateTimer;
const FILTER_DELAY = 1000;

const useStyles = makeStyles((theme) => ({
    dateSelect: {
        width: '9.25rem',
        height: '2.5rem',
        backgroundColor: '#D4E3FF',
        '& > .MuiOutlinedInput-notchedOutline':{
            border: '1px solid #74777F',
            borderRadius: '.5rem',
        },
    },
    dateSelectEmpty: {
        width: '9.25rem',
        height: '2.5rem',
        '& > .MuiOutlinedInput-notchedOutline':{
            border: '1px solid #74777F',
            borderRadius: '.5rem',
        },
    },
    label:{
        padding: 0,
        marginTop: '-.4rem',
        fontSize: '.875rem',
        color: '#263238',
        fontWeight: 500
    },
    optionContainer: {
        alignItems: 'baseline'
      },
    option: {
        padding: 0,
    },
    selectedOption: {
        padding: 0,
        backgroundColor: 'rgba(53, 118, 203, 0.12)'
    },
    checkbox: {
        marginRight: '0 !important',
        padding: '.5rem',
        color: '#707070' 
    },
    autocompleteNoneSelected: {
        '& > .MuiTextField-root' :{
            width: '9.25rem',
            '& > .MuiInputBase-root':{
                height: '2.5rem',
                '& > .MuiOutlinedInput-notchedOutline':{
                    border: '1px solid #74777F',
                    borderRadius: '.5rem',
                },
                '& > .MuiChip-root':{
                    display: 'none',
                },
                '& > .MuiAutocomplete-endAdornment': {
                    '& > .MuiAutocomplete-clearIndicator': {
                        display: 'none'
                    }
                }
            }
        }
    },
    autocompleteSelected: {
        '& > .MuiTextField-root' :{
            width: '9.25rem',
            '& > .MuiInputBase-root':{
                height: '2.5rem',
                backgroundColor: '#D4E3FF',
                '& > .MuiOutlinedInput-notchedOutline':{
                    border: '1px solid #74777F',
                    borderRadius: '.5rem',
                },
                '& > .MuiChip-root':{
                    display: 'none',
                },
                '& > .MuiAutocomplete-endAdornment': {
                    '& > .MuiAutocomplete-clearIndicator': {
                        display: 'none'
                    }
                }
            }
        }
    },
  autocompleteOpen: {
    '& > .MuiTextField-root' :{
        minWidth: '9.25rem',
        width: 'fit-content',
        maxWidth: '20rem',
        zIndex: '999',
        backgroundColor: '#fff',
        '& .MuiAutocomplete-inputRoot': {
            flexWrap: 'wrap',   
        },
        '& > .MuiInputBase-root':{
            minHeight: '2.5rem',
            padding: '0 .5rem',
            '& > .MuiOutlinedInput-notchedOutline':{
                border: '1px solid #74777F',
                borderRadius: '.5rem',
            },
            '& > .MuiChip-root':{
                marginTop: '.5rem',
                height: '1.5rem',
                width: 'fit-content',
                backgroundColor: '#fff',
                border: '1px solid #74777F',
                borderRadius: '.5rem'
            }
        }
    }
  },
  dateRangePicker: {
    padding: '1rem'
  },
}));

export default function TableFilter({ initialValue, label, options, handleFilterChange, clearAllTrigger=false }) {
  const classes = useStyles();

  const [calValue, setCalValue] = useState(initialValue ? initialValue : [null, null]);

  const [selectValue, setSelectValue] = useState(initialValue ? initialValue : []);
  const [selectOpen, setSelectOpen] = useState(false);

  useEffect(() => {
    if(clearAllTrigger){
        setSelectValue([])
        setCalValue([null, null])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearAllTrigger]);

  const handleFilterSelection = (label, value) => {
    let selections = [];
    switch (label) {
        case "Submitted":
            handleFilterChange(label, value)
            break
        case "Duplicate":
        case "Urgent":
        case "Status":
        case "County":
        case "Agency":
        case "Season":
        case "Assignee":
            selections = value.map((val) => val.value ? val.value : val)
            handleFilterChange(label, selections)
            break
        default:
            break
      }
  }

  const dateInputDelay = (callback, limit) => {
    window.clearTimeout(dateTimer);
    dateTimer = window.setTimeout(callback, limit);
  }

  const handleDateRangeSelection = (startDate, endDate, errors) => {
    if((calValue[0]!==startDate || calValue[1]!==endDate)){
        dateInputDelay(
            () => {
                setCalValue([startDate,endDate])
                handleFilterSelection('Submitted', [startDate,endDate]) 
            }, FILTER_DELAY);
    }
  }

  if(label === 'Submitted'){
      return (
        <FormControl fullWidth >
            <InputLabel className={classes.label}>
                {label}
            </InputLabel>
            <Select     
                autoWidth
                className={calValue[0] && calValue[1] ? classes.dateSelect : classes.dateSelectEmpty}
                MenuProps={{ classes: { list: classes.dateRangePicker } }}
            > 
                <DateRangePicker defaultStartVal={calValue[0]} defaultEndVal={calValue[1]} onDateRangeSelection={handleDateRangeSelection} />
            </Select>
        </FormControl>
      )
  }

  return (
    <FormControl fullWidth>
    <InputLabel id={`${label}-label`} aria-label={label} className={classes.label} style={{display: selectOpen ? 'none' : 'initial'}}>
        <label htmlFor={`${label}-input`} >
        {label}
        </label>
    </InputLabel>
    <Autocomplete
        className={selectOpen ? classes.autocompleteOpen : selectValue.length > 0 ? classes.autocompleteSelected : classes.autocompleteNoneSelected}
        onChange={(e, value) => {setSelectValue(value); handleFilterSelection(label, value)}}
        multiple
        fullWidth
        id={`${label}-input`} 
        value={selectValue}
        options={options}
        isOptionEqualToValue={(option, value) => {let val = value.value ? value.value : value; return option.value === val}}
        getOptionLabel={(option) => option.label ? option.label : option === 'Submitted' ? 'New' : option}
        renderOption={(props, option, { selected }) => (
            <li {...props} className={selected ? classes.selectedOption : classes.option}>
                <Grid container className={classes.optionContainer}>
                    <Grid item xs={3}>
                        <Checkbox
                          className={classes.checkbox}
                          checkedIcon={<CheckedIcon/>}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                    </Grid>
                    <Grid item xs={9}>
                        {option.label}
                    </Grid>
                </Grid>
            </li>
          )}
        renderInput={(params) => {
        return(
        <TextField
            {...params}
            onClick={() => {setSelectOpen(true)}}
            onBlur={() => {setSelectOpen(false)}}
            className={classes.textField}
            label={selectOpen ? label : ''}
        />
        )}}
    />
    </FormControl>
    )
}
