export const weatherProofingResetFields = {
    'weatherProofingPrevAsst': '',
    'weatherProofingPrevAsstDate': '', 
    'yearHomeWasBuilt': '',
    'airConditionerType': '',
    'heaterType': [],
    'gasSpaceHeaterCount': '', 
    'electricHeaterCount': '',
    'roofLeaking': '',
    'roofLeakingTimeLength': '', 
    'roofLeakingRoomCount': '', 
    'holesInFloor': '',
    'goodFoundation': ''
  }

  