import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from "../../../../../context/authContext";
import makeStyles from '@mui/styles/makeStyles';
import { FormControl, Modal, Button, MenuItem, Typography, Grid, CircularProgress, Select, InputLabel } from '@mui/material';
import { useMutation } from 'react-query';
import { updateApplicationStatus } from '../../../../../api/AgencyAPI';
import { useForm } from 'react-hook-form';
import { statuses } from '../../../../../constants/appStatuses';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller } from 'react-hook-form';
import { AgencyStatusValidationSchema } from '../../../../../constants/tx/agencyValidationSchema'
import ApprovedStatusInfo from './ApprovedStatusInfo';
import ReturnedStatusInfo from './ReturnedStatusInfo';
import DeniedStatusInfo from './DeniedStatusInfo';
import { Check, Clear, KeyboardReturn } from '@mui/icons-material';
import ConfirmationDialog from '../../../../ConfirmationDialog';
import CurrentStatus from './CurrentStatus';
import { useErrorViewer } from '../../../../../context/errorContext';
import { ConfigContext } from '../../../../../context/configContext';
import { FINA_ACCOUNT_ERROR, isAccountPledgeEligible, isCreatePledgeEligible } from '../../../../../utils/finaUtils';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const useStyles = makeStyles((theme) => ({
  approved: {
    color: theme.palette.success.main
  },
  denied: {
    color: theme.palette.error.main
  },
  formControl: {
    width: '100%',
  },
  select: {
    marginBottom: '1rem'
  },
  status: {
    display: 'flex'
  },
  statusLabel: {
    marginLeft: '.5rem'
  },
  spinner: {
    position: 'absolute',
    color: theme.palette.primary.main,
    zIndex: '999'
  },
  notifyButton: {
    margin: '1rem 0'
  },
  modal: {
    position: 'absolute',
    background: theme.customColors.modalBackground,
    boxShadow: '0 0 .3rem rgba(0, 0, 0, 0.2)',
    bozSizing: 'inherit',
    borderRadius: '1.75rem',
    padding: '3rem',
    width: '40rem'
  },
  modalHeader:{
    marginBottom: '1rem'
  },
  modalButton: {
    marginTop: '1rem',
    textTransform: 'none'
  },
}));

export default function TXChangeStatus({ application, onUpdate, stateConfig }) {
  const { userInfo } = useContext(AuthContext);
  const { clientConfig } = useContext(ConfigContext);
  const userId = userInfo?.id;
  const setError = useErrorViewer();
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  const [statusUpdateObject, setStatusUpdateObject] = useState('');
  const [newStatus, setNewStatus] = useState('');
  const [changeSucessful, setChangeSucessful] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationContent, setConfirmationContent] = useState(false);
  const [createFinaPledge, setCreateFinaPledge] = useState(false)

  const fundSourceOptions = stateConfig?.fundSources

  const { control, errors, register, getValues, trigger, handleSubmit, reset, watch } = useForm({ 
    mode: 'onSubmit',
    resolver: yupResolver(AgencyStatusValidationSchema),
    defaultValues: {"approvedPayments": []}
  })

  const submitMutation = useMutation(statusUpdate => {
    return updateApplicationStatus(statusUpdate);
  })

  useEffect(() => {
    if(statusUpdateObject){
      submitMutation.mutate(statusUpdateObject, {
        onSuccess: (data) => {
          //check for valid or not if it exists on response and display error if needed
          let response = data.data;
          if (response.hasOwnProperty("valid") && !response?.valid){
            if(response?.error === "Invalid account status for pledge."){
              setError(FINA_ACCOUNT_ERROR)
            }else{
              setError(`Error occurred updating the status: ${response?.error}`)
            }            
          }else{
            handleSuccess();
          }          
        },
        onError: (error) => setError("Error occurred updating the status.")
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusUpdateObject]);

  const handleSuccess= () => {
    setChangeSucessful(true);
  };

  const handleClose = () => {
    setNewStatus('');
    reset();
    setStatusUpdateObject('');
    setChangeSucessful(false);
    onUpdate();
  };

  const handleStatusSelect = (selection) => {
    setNewStatus(selection);
  };

  const onSubmit = async() => {
    const valid = await trigger();
    if(valid){
      const statusInfo = {
        payments: getValues().approvedPayments,
        pledgeDate: getValues('pledgeDate'),
        returnedNeededInfo: getValues('returnedNeededInfo'), 
        returnedDueDate: getValues('returnedDueDate'), 
        deniedReasonSpecification: getValues('deniedReasonSpecification')
      }

      let confirmStatus = newStatus
      let includeWarning = false
      //check if current status is PENDING or ERROR while trying to approve in order to display warning on confirmation
      if (newStatus === statuses.APPROVED && [statuses.PENDING, statuses.ERROR].includes(application.status)){
        includeWarning = true
      }else{
        //check for FINA pledge eligibility
        if (isCreatePledgeEligible(clientConfig, application, newStatus, statusInfo)){
          if (isAccountPledgeEligible(application?.entergyAccountInfo)){
            confirmStatus = statuses.PENDING
            setCreateFinaPledge(true)
          }else{
            setError(FINA_ACCOUNT_ERROR)
            return
          }
        }else{
          setCreateFinaPledge(false)
        }
      }

      setConfirmationContent(<CurrentStatus status={confirmStatus} statusInfo={statusInfo} includeFinaApproveWarning={includeWarning}/>);
      setShowConfirmation(true);
    }
  };

  const getSuccessConfirmationMsg = () => {
    let msg = `Application has been ${newStatus.toLowerCase()} and the applicant will be notified.`
    if (createFinaPledge){
      msg = "The application status has been set to pending."
    }
    return msg
  }

  const handleConfirmation = () => {
    switch(newStatus) {
      case statuses.APPROVED :
        const payments = getValues().approvedPayments;
        const totalPledgeAmount = payments.reduce((total, payment) => parseFloat(total) + parseFloat(payment.amount.replace(/[^\d.]/g,'')), 0);       
        setStatusUpdateObject({applicationId: application.id, status: newStatus, userId: userId, statusInfo: {
          pledgeAmount: totalPledgeAmount.toString(), 
          //current python verison doesn't support isoformat with trailing Z. can remove after upgrade to python 3.11
          pledgeDate: getValues('pledgeDate').toISOString().replace('Z', ''), 
          payments: payments,
        }});
        break;
      case statuses.RETURNED :
        setStatusUpdateObject({applicationId: application.id, status: newStatus, userId: userId, statusInfo: {
          returnedNeededInfo: getValues('returnedNeededInfo'), 
          //current python verison doesn't support isoformat with trailing Z. can remove after upgrade to python 3.11
          returnedDueDate: getValues('returnedDueDate').toISOString().replace('Z', ''), 
        }});
        break;
      case statuses.DENIED :
        setStatusUpdateObject({applicationId: application.id, status: newStatus, userId: userId, statusInfo: {
          deniedReasonSpecification: getValues('deniedReasonSpecification')
        }});
        break;
      default:
        break;
    }
    setShowConfirmation(false);
  }

  const isNotifyDisabled = () => {
    return !newStatus || submitMutation.isLoading || !AgencyStatusValidationSchema.isValidSync(watch())
  }

  return (
    <>
      <form  autoComplete="false" >
        {submitMutation.isLoading && 
          <Grid style={modalStyle} container justifyContent="center" className={classes.spinner}>
            <CircularProgress color="primary" />
          </Grid>
        }
        <FormControl className={classes.formControl}>
          <InputLabel>New Status</InputLabel>
            <Controller
              control={control}
              name='selectedStatus'
              defaultValue={newStatus}
              value={newStatus}
              as={
                <Select disabled={submitMutation.isLoading} label='New Status' className={classes.select}> 
                  <MenuItem value={statuses.APPROVED} onClick={() => handleStatusSelect(statuses.APPROVED)}>
                    <Grid item className={classes.status}>
                      <Check className={classes.approved}/>
                      <Typography className={classes.statusLabel}>
                        Approved
                      </Typography> 
                    </Grid> 
                  </MenuItem>
                  <MenuItem value={statuses.DENIED} onClick={() => handleStatusSelect(statuses.DENIED)}>
                    <Grid item className={classes.status}>
                      <Clear className={classes.denied}/> 
                      <Typography className={classes.statusLabel}>
                        Denied
                      </Typography>  
                    </Grid>
                  </MenuItem>
                  <MenuItem value={statuses.RETURNED} onClick={() => handleStatusSelect(statuses.RETURNED)} >
                    <Grid item className={classes.status}>
                      <KeyboardReturn className={classes.statusIcons} /> 
                      <Typography className={classes.statusLabel}>
                        Returned
                      </Typography>  
                    </Grid>
                  </MenuItem>
                </Select>
              } 
            />
            {newStatus && newStatus === statuses.APPROVED && 
              <ApprovedStatusInfo 
                application={application} 
                control={control} 
                register={register} 
                errors={errors} 
                loading={submitMutation.isLoading}
                trigger={trigger}
                fundSourceOptions={fundSourceOptions}
              />
            }
            {newStatus && newStatus === statuses.DENIED && 
              <DeniedStatusInfo
                application={application} 
                register={register} 
                errors={errors} 
                loading={submitMutation.isLoading}
              />
            }
            {newStatus && newStatus === statuses.RETURNED && 
              <ReturnedStatusInfo 
                application={application} 
                control={control} 
                register={register} 
                errors={errors} 
                loading={submitMutation.isLoading}
                trigger={trigger}
              />
            }  
        </FormControl>
          <Grid item xs={12} container justifyContent="flex-end">
            <Button 
              disabled={isNotifyDisabled()} 
              className={classes.notifyButton} 
              onClick={handleSubmit(onSubmit)} 
              variant="contained" 
              color="primary">
                Notify Applicant
            </Button>
          </Grid>
      </form>

      <ConfirmationDialog 
          open={showConfirmation}
          title={`${newStatus.charAt(0).toUpperCase() + newStatus.slice(1)} Details`}
          contentChildren={confirmationContent}
          cancelLabel="Edit"
          okLabel={createFinaPledge ? "Approve" : "Notify Applicant"}
          handleCancel={() => setShowConfirmation(false)}
          handleOk={() => handleConfirmation()}
        />

      <Modal open={changeSucessful} onClose={handleClose}>
        <Grid style={modalStyle} className={classes.modal}>
          <Grid item container justifyContent="flex-start">
            <Typography variant="h5" className={classes.modalHeader}>
              Success
            </Typography>
          </Grid>
          <Grid item container justifyContent="flex-start">
            <Typography variant="subtitle2">
              {getSuccessConfirmationMsg()}
            </Typography>
          </Grid>
          <Grid item xs={12} container justifyContent="flex-end">
            <Button className={classes.modalButton} onClick={handleClose} variant="text" color="primary">OKAY</Button>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
}