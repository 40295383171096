
import React, { useContext } from 'react';
import { Grid, Container, Divider, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { VpnKeyRounded, LocalPhoneRounded, NotificationsRounded, ArrowBackIos, EditOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/authContext';
import { useHistory } from 'react-router';
import StepHeader from '../../components/StepHeader';
import FormSection from '../../components/FormSection';
import SettingsStepper from '../../components/SettingsStepper';
import { formatPhoneNumber } from '../../utils/utilFunctions';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    margin: 'auto',
    padding: "2.8rem",
    width: "95%",
    [theme.breakpoints.down('lg')]: {
      padding: '2rem 1rem 0rem 1rem'
    }
  },
  header: {
    fontWeight: 'bold',
    margin: '2rem 1rem 3rem 1rem',
    color: theme.palette.primary.main,
  },
  section:{
    alignItems: 'center',
    margin: '2rem 1rem'
  },
  details:{
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      marginLeft: '1rem'
    }
  },
  labelAndEdit: {
    alignItems: 'center'
  },
  label: {
    fontWeight: 'bold',
    color: theme.palette.primary.main
  },
  link: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    textDecoration: 'none',
    padding: '1.5rem 0',
    minWidth: '3rem',
  },
  editIcon:{
    marginRight: '.5rem',
    [theme.breakpoints.down('md')]: {
      marginLeft: '2rem'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '1rem'
    }
  },
  icon: {
    border: '.3rem solid',
    borderRadius: '50%',
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    width: '2rem',
    height: '2rem',
    padding: '.25rem'
  },
  updated: {
    color: theme.palette.success.main,
    [theme.breakpoints.down('md')]: {
      marginLeft: '2rem'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '1rem'
    }
  },
  homeButton: {
    marginTop: '1rem',
    width: '100%',
    color: theme.palette.primary.main,    
    [theme.breakpoints.down('sm')]: {
      marginTop: '2.5rem',
    }
  },  
}));

export default function AccountSettings() {
  const classes = useStyles();
  const { userInfo } = useContext(AuthContext);
  const history = useHistory();
  const theme = useTheme();
  const matchesXsDown = useMediaQuery(theme.breakpoints.down('sm'));

  const updatedAttribute = history.location.state?.successfulUpdate ? history.location.state?.attribute : ''

  const AccountDetail = ({label, value, url}) => {
    return (
      <Grid container className={classes.details}>
        <Grid container item xs={12} sm={6} className={classes.labelAndEdit}>
          <Grid item xs={6} sm={4} justifyContent='flex-start'>
            <Typography className={classes.label}>
              {label}
            </Typography>
          </Grid>
          <Grid container item xs={6} sm={8} justifyContent='flex-start'>
            <Link className={classes.link} to={`${url}`}>
              <Grid container>
                <EditOutlined className={classes.editIcon}/>
                Edit 
            </Grid>  
          </Link>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={3}>
            {value}
        </Grid>
        {updatedAttribute === label &&
          <Grid item xs={6} sm={3}>
            <Typography variant='subtitle2' className={classes.updated}>
              Updated 
            </Typography>            
          </Grid>
        }
      </Grid>
    )
  }
  
  return (
    <Container className={classes.mainContainer}>
      <Grid container spacing={1}>
        {matchesXsDown &&
          <SettingsStepper attribute={false}/>
        }
        <StepHeader 
          title="Account Settings"
          desc="We use this information to make sure only you can have access to your account."
        />
        <FormSection>
          <Typography variant='h5' className={classes.header}>
            {`${userInfo.firstName} ${userInfo.lastName}'s Account`}  
          </Typography>
          <Grid container className={classes.section}>
            <Grid item xs={2} sm={1}>
              <VpnKeyRounded className={classes.icon}/>
            </Grid>
            <Grid item xs={10} sm={11}>
              <AccountDetail 
                label="Password" 
                value="********" 
                url="/password" 
              />
            </Grid>
          </Grid>
            <Divider />
          <Grid container className={classes.section}>
            <Grid item xs={2} sm={1}>
              <LocalPhoneRounded className={classes.icon}/>
            </Grid>
            <Grid item xs={10} sm={11}>
              <AccountDetail 
                label="Phone" 
                value={formatPhoneNumber(userInfo.phoneNumber)} 
                url="/phone" 
              />
            </Grid>
          </Grid>
            <Divider />
          <Grid container className={classes.section}>
            <Grid item xs={2} sm={1}>
              <NotificationsRounded className={classes.icon}/>
            </Grid>
            <Grid item xs={10} sm={11}>
              <AccountDetail 
                label="Notifications" 
                value={`Receive via ${userInfo.emailNotificationsEnabled ? 'email' : ''}${userInfo.emailNotificationsEnabled && userInfo.smsNotificationsEnabled ? ' & ' : ''}${userInfo.smsNotificationsEnabled ? 'text' : ''}`} 
                url="/notifications" 
              />
            </Grid>
          </Grid>
        </FormSection>
        <Grid xs={12} sm={2} item container justifyContent={matchesXsDown ? "center" : "flex-start"}>
          <Button
            className={classes.homeButton} 
            onClick={() => history.push(`/`)}
            color="primary"
            variant={matchesXsDown ? "outlined" : "text"}
            startIcon={matchesXsDown ? "" : <ArrowBackIos/>}
          >
            Home
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}
