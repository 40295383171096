export const bvcapCountyOptions = [
    { 
      label: 'Brazos', 
      value: 'Brazos' 
    },
    { 
      label: 'Burleson', 
      value: 'Burleson' 
    },
    { 
      label: 'Grimes', 
      value: 'Grimes' 
    },
    { 
      label: 'Leon', 
      value: 'Leon' 
    },
    { 
      label: 'Madison', 
      value: 'Madison' 
    },
    { 
      label: 'Montgomery', 
      value: 'Montgomery' 
    },
    { 
      label: 'Robertson', 
      value: 'Robertson' 
    },
    { 
      label: 'Walker', 
      value: 'Walker' 
    },
    { 
      label: 'Waller', 
      value: 'Waller' 
    },
    { 
      label: 'Washington', 
      value: 'Washington' 
    }
]