export const governmentIssuedPhotoIDOptions = [
    {
      value: 'idCard',
      label: 'Texas Drivers License or Photo ID (can be expired up to two years)',
      description: 'Please upload a copy of :typeContext Texas Drivers License or Photo ID.'
    },
    {
      value: 'governmentEmployeeID',
      label: 'Government Employee ID',
      description: 'Please upload a copy of :typeContext Government Employee ID.'
    },
    {
      value: 'militaryID',
      label: 'U.S. Military or Military Dependent ID',
      description: 'Please upload a copy of :typeContext.'
    },
    {
      value: 'noneOfTheAboveGovId',
      label: 'None of the above',
      description: ''
    }
  ]
  
  