export const workStatusOptions = [
  {
    value: 'employed',
    label: 'Employed'
  },
  {
    value: 'unemployed',
    label: 'Unemployed'
  }
]
