export const rentHousingTypeOptions = [
    {
      value: 'privateHomeRent',
      label: 'Private Home'
    },
    {
      value: 'mobileHomeRent',
      label: 'Mobile Home (single or double wide)'
    },
    {
      value: 'apartment',
      label: 'Apartment'
    },
    {
      value: 'subsidizedhousing',
      label: 'Subsidized housing'
    }
  ]
  
  