import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/system';

export const StyledDataGrid = styled(DataGrid)(({theme}) => ({
    height: "auto !important", 
    '& .MuiDataGrid-main': {
      height: '30rem'
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      overflow: 'visible',
    },
    '& .MuiDataGrid-columnHeaderTitleContainerContent':{
      cursor: 'pointer',
      minHeight: '2.2rem'
    },
    '& .MuiDataGrid-columnHeaders': {
      maxHeight: 'none !important',
      backgroundColor: theme.palette.primary.main,
      color: '#FFF'
    },
    '& .MuiDataGrid-columnSeparator': {
      visibility: 'hidden'
    },
    '& .MuiDataGrid-viewport': {
      maxHeight: "none !important",
    },
    '& .MuiDataGrid-renderingZone': {
      maxHeight: "none !important",
      height: '35rem'
    },
    '& .MuiDataGrid-columnHeader' : {
      display: 'grid',
      cursor: 'default',
      '& :hover':{
        backgroundColor: theme.palette.primary.veryDark,
        borderRadius: '1.8rem',
      }
    },
    '& .MuiDataGrid-cell': {
      lineHeight: "unset !important",
      maxHeight: "none !important",
      whiteSpace: "normal",
      overflowWrap: 'break-word',
      overflow: 'visible'
    },
    '& .MuiDataGrid-cell:focus': {
      outline: 'none'
    },
    '& .MuiDataGrid-cell:focus-within': {
      outline: 'none'
    },
    '& .MuiDataGrid-row': {
      maxHeight: "none !important"
    },
    '& .MuiDataGrid-iconButtonContainer':{
      visibility: 'visible',
      color: '#fff'
    },
    '& .MuiTablePagination-root':{
      marginRight: '1rem'
    }
  }
));
