import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'
import { yesNoOptions } from '../../../constants/radios/yesNoOptions';
import FormCurrencyField from '../../form/FormCurrencyField';
import FormRadioAsButtons from '../../form/FormRadioAsButtons';
import FormTextField from '../../form/FormTextField';
import FormSection from '../../FormSection';

const useStyles = makeStyles((theme) => ({
  sectionHeader: {
    fontWeight: "bold"
  },
  inputField: {
    maxWidth: "40rem",
    margin: "2rem 0rem 1.5rem 0rem"
  }
}));

export default function FinancialResources({ control, register, type, errors, watch}) {
  const classes = useStyles();
  const cashOnHandWatch = watch('cashOnHand');
  const checkingAccountWatch = watch('checkingAccount');
  const otherBankAccountWatch = watch('otherBankAccount');
  const certificateOfDepositWatch = watch('certificateOfDeposit');
  const otherFinancialResourcesWatch = watch('otherFinancialResources');
  return (
    <>
      <FormSection>
        <Grid item width="auto">
          <Typography variant="h6" className={classes.sectionHeader}>Cash on Hand</Typography>
        </Grid>
        <Grid item xs={12}>
          <FormRadioAsButtons
            control={control}
            name="cashOnHand"
            label={`${type === "applicant" ? "Do you" : "Does this household member"} have cash on hand?`}
            errors={errors}
            options={yesNoOptions}
          />         
        </Grid>
        {cashOnHandWatch === 'yes' &&
        <>
          <Grid item xs={12} className={classes.inputField}>
            <FormTextField
              name="cashOnHandLocation" 
              label={`Where ${type === "applicant" ? "do you" : "does this household member"} have these funds?`}
              labelPlacement="top" 
              register={register} 
              errors={errors}                
            />     
          </Grid>
          <Grid item xs={12} className={classes.inputField}>
            <FormCurrencyField
              name="cashOnHandAmount" 
              label={`How much cash ${type === "applicant" ? "do you" : "does this household member"} have access to?`}
              labelPlacement="top" 
              control={control}
              errors={errors}
            />
          </Grid>          
        </>
        }
      </FormSection>
      <FormSection>
        <Grid item width="auto">
          <Typography variant="h6" className={classes.sectionHeader}>Checking Account</Typography>
        </Grid>
        <Grid item xs={12}>
          <FormRadioAsButtons
            control={control}
            name="checkingAccount"
            label={`${type === "applicant" ? "Do you" : "Does this household member"} have a checking account?`}
            errors={errors}
            options={yesNoOptions}
          />         
        </Grid>
        {checkingAccountWatch === 'yes' &&
        <>
          <Grid item xs={12} className={classes.inputField}>
            <FormTextField
              name="checkingAccountBankName" 
              label={`What is the name of the bank associated with ${type === "applicant" ? "your" : "this household member's"} checking account?`}
              labelPlacement="top" 
              register={register} 
              errors={errors}                            
            />     
          </Grid>
          <Grid item xs={12} className={classes.inputField}>
            <FormCurrencyField
              name="checkingAccountAmount" 
              label={`How much money ${type === "applicant" ? "do you" : "does this household member"} have in this checking account?`}
              labelPlacement="top"
              control={control}
              errors={errors}
            />   
          </Grid>
        </>
        }
      </FormSection>
      <FormSection>
        <Grid item width="auto">
          <Typography variant="h6" className={classes.sectionHeader}>Other Bank Account</Typography>
        </Grid>
        <Grid item xs={12}>
          <FormRadioAsButtons
            control={control}
            name="otherBankAccount"
            label={`${type === "applicant" ? "Do you" : "Does this household member"} have any other bank accounts?`}
            errors={errors}
            options={yesNoOptions}
          />         
        </Grid>
        {otherBankAccountWatch === 'yes' &&
        <>
          <Grid item xs={12} className={classes.inputField}>
            <FormTextField
              name="otherBankAccountBankName" 
              label={`What is the name of the bank associated with ${type === "applicant" ? "your" : "this household member's"} other bank account?`}
              labelPlacement="top" 
              register={register} 
              errors={errors}                            
            />     
          </Grid>
          <Grid item xs={12} className={classes.inputField}>
            <FormCurrencyField
              name="otherBankAccountAmount" 
              label={`How much money ${type === "applicant" ? "do you" : "does this household member"} have in this other bank account?`}
              labelPlacement="top" 
              control={control}
              errors={errors}
            />   
          </Grid>
        </>
        }
      </FormSection>
      <FormSection>
        <Grid item width="auto">
          <Typography variant="h6" className={classes.sectionHeader}>Certificate of Deposit (CD)</Typography>
        </Grid>
        <Grid item xs={12}>
          <FormRadioAsButtons
            control={control}
            name="certificateOfDeposit"
            label={`${type === "applicant" ? "Do you" : "Does this household member"} have a Certificate of Deposit (CD)?`}
            errors={errors}
            options={yesNoOptions}
          />         
        </Grid>
        {certificateOfDepositWatch === 'yes' &&
        <>
          <Grid item xs={12} className={classes.inputField}>
            <FormTextField
              name="certificateOfDepositLocation" 
              label={`Where is ${type === "applicant" ? "your" : "this household member's"} Certificate of Deposit (CD)?`}
              labelPlacement="top" 
              register={register} 
              errors={errors}                            
            />     
          </Grid>
          <Grid item xs={12} className={classes.inputField}>
            <FormCurrencyField
              name="certificateOfDepositAmount" 
              label={`What is the total worth of ${type === "applicant" ? "your" : "this household member's"} Certificate of Deposit (CD)?`}
              labelPlacement="top" 
              control={control}
              errors={errors}
            />    
          </Grid>
        </>
        }
      </FormSection>
      <FormSection>
        <Grid item width="auto">
          <Typography variant="h6" className={classes.sectionHeader}>Other Financial Resources</Typography>
        </Grid>
        <Grid item xs={12}>
          <FormRadioAsButtons
            control={control}
            name="otherFinancialResources"
            label={`${type === "applicant" ? "Do you" : "Does this household member"} have any other financial resources?`}
            errors={errors}
            options={yesNoOptions}
          />         
        </Grid>
        {otherFinancialResourcesWatch === 'yes' &&
        <>
          <Grid item xs={12} className={classes.inputField}>
            <FormTextField
              name="otherFinancialResourcesLocation" 
              label={`Where is ${type === "applicant" ? "your" : "this household member's"} other financial resources (Bank, Investments, etc.)?`}
              labelPlacement="top" 
              register={register} 
              errors={errors}                            
            />     
          </Grid>
          <Grid item xs={12} className={classes.inputField}>
           <FormCurrencyField
              name="otherFinancialResourcesAmount" 
              label={`What is the total worth of ${type === "applicant" ? "your" : "this household member's"} other financial resources?`}
              labelPlacement="top" 
              control={control}
              errors={errors}
            />   
          </Grid>
        </>
        }
      </FormSection>
    </>
  )
}
