import React, { useState } from 'react';
import { Grid, InputAdornment, TextField, Typography, Tabs, Tab } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Search, NotificationImportant } from '@mui/icons-material';
import AdminUserTable from '../components/admin-panel/AdminUserTable';
import AdminInviteTable from '../components/admin-panel/AdminInviteTable';

const useStyles = makeStyles((theme) => ({
  tableActions: {
    alignItems: 'center',
    marginTop: '1.25rem',
    marginBottom: '1.25rem'
  },
  search: {
    borderRadius: '.5rem',
    height: '3rem'
  },
  warning: {
    border: `.188rem solid ${theme.palette.warning.main}`,
    borderRadius: '.5rem',
    margin: '1.5rem 0',
    alignItems: 'center'
  },
  warningIcon: {
    color: theme.palette.warning.main,
    height: '2.5rem',
    width: '2.5rem',
    transform: "rotate(20deg)",
    padding: '1rem'
  },
  tabs: {
    marginBottom: '2rem',
    '& > .MuiTabs-scroller':{
      '& > .MuiTabs-indicator': {
        height: '5px',
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px'
      }
    }
  },
  tab: {
    textTransform: 'none',
    fontSize: '1.25rem'
  }
}));

export default function AdminTable() {
  const classes = useStyles();

  const [value, setValue] = useState(0);
  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  const [searchValue, setSearchValue] = useState('');

  return (
    <>
      <Grid container item xs={12} className={classes.warning}>
        <NotificationImportant className={classes.warningIcon}/>
        <Typography variant="subtitle1">Warning! For security changes to go into effect, please have the user log out and log back in.</Typography>
      </Grid>
      <Grid item xs={12} >
        <Typography variant="h5">Admin Panel</Typography>
      </Grid>
      <Grid className={classes.tableActions} container item xs={12} justifyContent="flex-start">
        <Grid item xs={4}>
          <TextField
            id="outlined-search"
            fullWidth
            className={classes.search}
            value={searchValue}
            placeholder="Search"
            onChange={event => { setSearchValue(event.target.value) }}
            InputProps={{
              startAdornment: (<InputAdornment position="start"><Search /></InputAdornment>), 
              classes: {input: classes.searchInput}
            }}
          />            
        </Grid>
      </Grid>

        <Tabs className={classes.tabs} value={value} onChange={handleChange}>
          <Tab className={classes.tab} label="Active Users"  />
          <Tab className={classes.tab} label="Invited"  />
        </Tabs>

      {value === 0 ?
        <AdminUserTable searchValue={searchValue}/>
        :
        <AdminInviteTable searchValue={searchValue}/>
      }

    </>
  );
}
