export const educationLevelOptions = [
  {
    value: '0to8thGrade',
    label: '0-8th grade'
  },
  {
    value: '9to12thNonGraduate',
    label: '9-12th grade / Non-Graduate'
  },
  {
    value: 'hsGraduateOrGED',
    label: 'High School Graduate or GED'
  },
  {
    value: 'someCollege',
    label: 'Some College'
  },
  {
    value: '2to4YrCollegeGraduate',
    label: '2-4 year College Graduate'
  },
  {
    value: 'GraduateOtherPost2nd',
    label: 'Graduate of other Post-Secondary School'
  }
]

