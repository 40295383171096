export const cadcCountyOptions = [
    {
        value: 'Calhoun',
        label: 'Calhoun'
    },
    {
        value: 'Clark',
        label: 'Clark'
    },
    {
        value: 'Columbia',
        label: 'Columbia'
    },
    {
        value: 'Dallas',
        label: 'Dallas'
    },
    {
        value: 'Hempstead',
        label: 'Hempstead'
    },
    {
        value: 'Hot Spring',
        label: 'Hot Spring'
    },
    {
        value: 'Lafayette',
        label: 'Lafayette'
    },
    {
        value: 'Lonoke',
        label: 'Lonoke'
    },
    {
        value: 'Miller',
        label: 'Miller'
    },
    {
        value: 'Montgomery',
        label: 'Montgomery'
    },
    {
        value: 'Nevada',
        label: 'Nevada'
    },
    {
        value: 'Ouachita',
        label: 'Ouachita'
    },
    {
        value: 'Pike',
        label: 'Pike'
    },
    {
        value: 'Pulaski',
        label: 'Pulaski'
    },
    {
        value: 'Saline',
        label: 'Saline'
    },
    {
        value: 'Union',
        label: 'Union'
    }
  ];
