import { Container, Grid, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react'
import StepHeader from '../../StepHeader';
import { useParams } from 'react-router';
import StepButtons from '../../StepButtons';
import { getRequiredGenAndIncomeFileTypes, getUtilityFileTypes, getAdditionalFileType } from '../../../constants/tx/fileUtils';
import { getDocumentFileNames } from '../../../constants/sharedFileUtils';
import { heatOptions } from '../../../constants/tx/checkboxes/heatOptions';
import { raceOptions } from '../../../constants/tx/checkboxes/raceOptions';
import { ethnicityOptions } from '../../../constants/radios/ethnicityOptions';
import { unearnedBenefitOptions } from '../../../constants/tx/checkboxes/unearnedBenefitOptions';
import { relationToApplicantOptions } from '../../../constants/dropdowns/relationToApplicantOptions';
import { payFrequency } from '../../../constants/dropdowns/payFrequency';
import { submittingAsOptions } from '../../../constants/radios/submittingAsOptions';
import { yesNoOptions } from '../../../constants/radios/yesNoOptions';
import { genderOptions } from '../../../constants/radios/genderOptions';
import { workStatusOptions } from '../../../constants/tx/radios/workStatusOptions';
import { rentOwnOptions } from '../../../constants/radios/rentOwnOptions';
import { utilityProviderOptions } from '../../../constants/dropdowns/utilityProviderOptions';
import ReviewSubSection from './ReviewSubSection';
import SavePDF from '../../SavePDF';
import { educationLevelOptions } from '../../../constants/tx/radios/educationLevelOptions';
import { healthInsuranceTypeOptions } from '../../../constants/tx/checkboxes/healthInsuranceTypeOptions';
import { heaterOptions } from '../../../constants/tx/checkboxes/heaterOptions';
import { airConditionerOptions } from '../../../constants/tx/radios/airConditionerOptions';
import { rentHousingTypeOptions } from '../../../constants/tx/radios/rentHousingTypeOptions';
import { ownHousingTypeOptions } from '../../../constants/tx/radios/ownHousingTypeOptions';
import { otherFormOfIdentificationOptions } from '../../../constants/tx/checkboxes/otherFormOfIdentificationOptions';
import { proofOfCitizenshipOptions } from '../../../constants/tx/radios/proofOfCitizenshipOptions';
import { governmentIssuedPhotoIDOptions } from '../../../constants/tx/radios/governmentIssuedPhotoIDOptions';
import { programOptions } from '../../../constants/tx/checkboxes/programOptions';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    marginLeft: "0"
  },
  openAllButton: {
    textTransform: 'none'
  },  
  accordion: {
    '& > .MuiPaper-elevation1': {
      boxShadow: `0px 2px 1px -1px ${theme.customColors.shadowGray}, 0px 1px 1px 0px ${theme.customColors.shadowGray}, 0px 0px 3px 0px ${theme.customColors.shadowGray}`
    }
  },
}));


export default function Review({ data, back, next, isSaving, setValidate, processSteps, appState, fileData }) {
  const { id: appId } = useParams();
  const classes = useStyles();
  const [reviewTemplate, setReviewTemplate] = useState([]);
  const [showSSN, setShowSSN] = useState([{id: appId, visible: false}]);
  const [openAll, setOpenAll] = useState(false);

  const documentQueries = fileData;

  const applicationIsValid = () => {
    let appIsValid = false
    if(reviewTemplate && reviewTemplate.length > 0){
      appIsValid = true;
      reviewTemplate.forEach((item) => {
        if(!item.isValid){
          appIsValid = false;
        }
      })
      }
      return appIsValid;
    }

    useEffect(() => {
      setReviewTemplate(buildReviewTemplate());
     // eslint-disable-next-line
    }, [data, appId]); 

  const buildDocumentSection = (editLinkLocation, appOrMemberId, isPerson, requiredFileTypes, data, memberId) => {
    let result = []
    for(let fileType of requiredFileTypes){
      let label = isPerson ? `${data?.firstName} ${data?.lastName}'s ${fileType.label}` : fileType.label
      if (fileType?.employerName){
        label = `${label} (${fileType.employerName})`
      }
      let fileName = getDocumentFileNames(fileType, documentQueries, memberId);
      let doc = {
        label: label, 
        value: fileName, 
        isValid: fileName ? true : false, 
        link: appOrMemberId === appId ? buildEditLink(editLinkLocation, appId) : buildEditLink(editLinkLocation, appId, appOrMemberId)
      };
      result.push(doc)
    }
    return result;
  }

  const buildAdditionalDocumentsSection = (editLinkLocation, appId, fileTypes) => {
    let result = []
    for(let fileType of fileTypes){
      let label = fileType.label
      let fileName = getDocumentFileNames(fileType, documentQueries);
      let doc = {
        label: label, 
        value: fileName, 
        isValid: fileType.value === 'additional' ? true : fileName ? true : false, 
        link: buildEditLink(editLinkLocation, appId)
      };
      result.push(doc)
    }
    return result;
  }

  const buildFields = (data, stepSectionName, appId, memberId) => {
    let steps = processSteps.filter(x => x.stepSection === stepSectionName);
    let fields = [];
    let id = memberId ? memberId : appId
    steps.forEach(step => {
      let errors = [];
      let stepFields = step.fields; 
      let link = buildEditLink(step.location, appId, memberId);
      try{
        step.stepValidationSchema.validateSync(data, {abortEarly: false})
      }
      catch (error){
        errors = error.inner ? error.inner.map(e => e.path) : []
      }
      stepFields.forEach(field =>{
        if(field.value === 'employers'){
          let empFields = createEmployerFields(data, field, errors, step, appId, memberId)
          fields.push(empFields)
        }
        else{
          let value = Array.isArray(data[field.value]) ? getArrayConstants(data, field) : getDisplayValue(data[field.value])
          if(errors.includes(field.value) || (value !== undefined && value !== '' && value !== null)){
            fields.push({appOrMemberId: id, label: field.label, value: value, isValid: !errors.includes(field.value), link: link});
          }
        }
      })
    });

    return fields.flat();
  }

  const createEmployerFields = (data, field, errors, step, appId, memberId) => {
    let empFields = []
    let id = memberId ? memberId : appId

    if(data[field.value]){
      let link = buildEditLink(step.location, appId, memberId);
      data[field.value].forEach((employer, index) => {
        let label = `Employer #${index+1} `;
        empFields.push({
          appOrMemberId: id,
          label: label + 'Name', 
          value: getDisplayValue(employer.name), 
          isValid: !errors.includes(`employers[${index}].name`), 
          link: link
        });
        empFields.push({
          appOrMemberId: id,
          label: label + 'Monthly Income', 
          value: getDisplayValue(employer.monthlyIncomeAmount), 
          isValid: !errors.includes(`employers[${index}].monthlyIncomeAmount`), 
          link: link
        });
        empFields.push({
          appOrMemberId: id,
          label: label + 'Pay Frequency', 
          value: getDisplayValue(employer.payFrequency), 
          isValid: !errors.includes(`employers[${index}].payFrequency`), 
          link: link
        });
      })
    }
    return empFields
  }

  const getDisplayValue = (inputVal) => {
    let constantValues = [relationToApplicantOptions, payFrequency, 
      submittingAsOptions, genderOptions, workStatusOptions, yesNoOptions,
      rentOwnOptions, utilityProviderOptions, heatOptions, educationLevelOptions, 
      airConditionerOptions, rentHousingTypeOptions, proofOfCitizenshipOptions, 
      governmentIssuedPhotoIDOptions, ownHousingTypeOptions, ethnicityOptions];
    let searchArray = constantValues.flat().map(constant => constant.value)

    let displayVal = searchArray.includes(inputVal) ? [...new Set(constantValues.flat().filter(constant => constant.value === inputVal).map(constant => constant.label))] : inputVal

    return displayVal
  }

  const getArrayConstants = (data, field) => {
    let selectionArray = data[field.value];
    switch (field.value) {
      case ('race'):
        if(selectionArray && selectionArray[0] === 'noOption'){
          return 'Prefer not to say'
        }
        let raceOps = raceOptions.filter((option) => selectionArray.includes(option.value)).map((op) => op.label)
        return raceOps.join(', ')
      case ('utilityAssistanceNeeded'):
      case('secondarySources'):
        if(selectionArray && selectionArray[0] === 'noOption'){
          return 'None'
        }
        let heatOps = heatOptions.filter((option) => selectionArray.includes(option.value)).map((op) => op.label)
        return heatOps.join(', ')
      case ('unearnedBenefits'):
        if(selectionArray && selectionArray[0] === 'noOption'){
          return 'No, I do not receive any unearned income'
        }
        let benefitOps = unearnedBenefitOptions.filter((option) => selectionArray.includes(option.value)).map((op) => op.label)
        return benefitOps.join(', ')
      case ('healthInsurance'):
        if(selectionArray && selectionArray[0] === 'noOption'){
          return 'None'
        }
        let healthInsuranceOps = healthInsuranceTypeOptions.filter((option) => selectionArray.includes(option.value)).map((op) => op.label)
        return healthInsuranceOps.join(', ')
      case ('heaterType'):
        if(selectionArray && selectionArray[0] === 'noOption'){
          return 'None'
        }
        let heaterOps = heaterOptions.filter((option) => selectionArray.includes(option.value)).map((op) => op.label)
        return heaterOps.join(', ')
      case ('otherFormOfIdentification'):
        let docOps = otherFormOfIdentificationOptions.filter((option) => selectionArray.includes(option.value)).map((op) => op.label)
        return docOps.join(', ')
      case ('programApplyingFor'):
        let programOps = programOptions.filter((option) => selectionArray.includes(option.value)).map((op) => op.label)
        return programOps.join(', ')
      default:
        return selectionArray;
    }
  }

  const buildEditLink = (location, appId, memberId) => {
    //TODO: this is kind of ugly
    let editLink = `/liheapform/${appState}/${location}/${appId}`;
    if (memberId) {
      editLink = `${editLink}/${memberId}`;
    }
    return editLink;
  }

  const validateSection = (data, stepSectionName) => {
    let isValid = true;
    let steps = processSteps.filter(x => x.stepSection === stepSectionName);
    steps.forEach(step => {
      try{
        step.stepValidationSchema.validateSync(data, {abortEarly: false})
      }
      catch (error){
        isValid = false
      }
    })
    return isValid;
  }

  const validateDocumentSection = (documents) => {
    let isValid = true;
    documents.forEach(doc => {
      if(!doc.isValid){
        isValid = false
      }
    })
    return isValid;
  }

  /**
   * Builds json template of content for the review page.
   * 
   * @returns 
   */
  const buildReviewTemplate = () => {
    let result = []
    let documents = []

    //build Application Information Review section
    let appGenInfo = {
      title: data.firstName ? `${data.firstName} ${data.lastName}'s Information` : "Applicant's Information",
      isValid: validateSection(data, "applicantGeneralInformation"),
      fields: buildFields(data, "applicantGeneralInformation", appId)
    };
    result.push(appGenInfo);

    let appIncomeInfo = {
      title: data.firstName ? `${data.firstName} ${data.lastName}'s Financial Situation` : "Applicant Financial Situation",
      isValid: validateSection(data, "applicantIncomeInformation"),
      fields: buildFields(data, "applicantIncomeInformation", appId)
    };
    result.push(appIncomeInfo);

    let appDocs = buildDocumentSection("applicantDocumentUpload", appId, true, getRequiredGenAndIncomeFileTypes(data), data);
    documents.push(appDocs)

    //build Household Information Review section
    if(data.householdMembers && data.householdMembers.length > 0){
      for (const member of data.householdMembers) {

        setShowSSN((prevState) => {
          const alreadyInState = prevState.filter((el) => el.id === member.id)
          if(alreadyInState.length === 0){
            prevState.push({id: member.id, visible: false}); 
          }
          return prevState
        })

        let memberGenInfo = {
          title: member.firstName ? `${member.firstName} ${member.lastName}'s Information` : "Household Member Information",
          id: member.id,
          name: `${member.firstName} ${member.lastName}`,
          isValid: validateSection(member, "memberGeneralInformation"),
          fields: buildFields(member, "memberGeneralInformation", appId, member.id)
        };
        result.push(memberGenInfo);

        let memberIncomeInfo = {
          title: member.firstName ? `${member.firstName} ${member.lastName}'s Financial Situation` : "Household Member Financial Situation",
          id: member.id,
          name: `${member.firstName} ${member.lastName}`,
          isValid: validateSection(member, "memberIncomeInformation"),
          fields: buildFields(member, "memberIncomeInformation", appId, member.id),          
        };
        result.push(memberIncomeInfo);

        let memberDocs = buildDocumentSection("memberDocumentUpload", member.id, true, getRequiredGenAndIncomeFileTypes(member), member, member.id);
        documents.push(memberDocs)
      }
    }

    //build Utility Information Review section
    let utilityInfo = {
      title: "Utility Information",
      isValid: validateSection(data, "utilityGeneralInformation"),
      fields: buildFields(data, "utilityGeneralInformation", appId),
    };
    result.push(utilityInfo);

    let utilityDocs = buildDocumentSection("utilityDocumentUpload", appId, false, getUtilityFileTypes(data));
    documents.push(utilityDocs)

    let docs = {
      title: "Documents",
      isValid: validateDocumentSection(documents.flat()),
      fields: documents.flat()
    };
    result.push(docs);

    //build Additional Documents section
    let additionalDocuments = buildAdditionalDocumentsSection("additionalDocumentUpload", appId, getAdditionalFileType())

    let additionalDocs = {
      title: "Additional Documents",
      isValid: validateDocumentSection(additionalDocuments),
      fields: additionalDocuments
    };
    result.push(additionalDocs);

    return result;
  }

  /**
   * Iterates over built review template to display content
   * 
   * @returns 
   */
  const renderReviewInfo = () => {
    return (
      <Grid container>
        <Grid item container xs={6} justifyContent="flex-start">
          <SavePDF appData={data}/>
        </Grid>
        <Grid item container xs={6} justifyContent="flex-end">
          <Button
            className={classes.openAllButton}
            color="primary"
            onClick={() => setOpenAll((prevState) => {return !prevState})}
            variant="text"
          >
            {openAll ? 'Close All' : 'Open All'}
          </Button>
        </Grid>
        <Grid item xs={12} className={classes.accordion}>
          {reviewTemplate.map((info, index) => (
            <ReviewSubSection 
              key={index} 
              info={info} 
              setValidate={setValidate} 
              showSSN={showSSN} 
              setShowSSN={setShowSSN}
              openAll={openAll}/>
          ))}
        </Grid>
      </Grid>
    )
  }

  return (
    <Container className={classes.mainContainer}>
      <Grid container>
        <StepHeader
          title="Review Your Application"
          desc="Please review the following information for the application."
          reviewScreen={true}
        />
        {renderReviewInfo()}
        <StepButtons back={back} next={next} disableContinue={(isSaving || applicationIsValid() === false)} continueLabel='Sign your application >>'/>
      </Grid>
    </Container>
  )
}
