export const ownHousingTypeOptions = [
    {
      value: 'privateHomeOwn',
      label: 'Private Home'
    },
    {
      value: 'mobileHomeOwn',
      label: 'Mobile Home (single or double wide)'
    }
  ]
  
  