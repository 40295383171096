import React, { useState, useContext } from 'react'
import { Container, Grid, Typography, FormControlLabel, Checkbox } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FormTextField from '../../../form/FormTextField';
import FormDatePicker from '../../../form/FormDatePicker';
import FormSelect from '../../../form/FormSelect';
import FormCheckboxes from '../../../form/FormCheckboxes';
import FormRadioAsButtons from '../../../form/FormRadioAsButtons';
import { stateOptions } from '../../../../constants/dropdowns/stateOptions';
import StepHeader from '../../../StepHeader';
import StepButtons from '../../../StepButtons';
import FormSection from '../../../FormSection';
import { yesNoOptions } from '../../../../constants/radios/yesNoOptions';
import { genderOptions } from '../../../../constants/radios/genderOptions';
import { raceOptions } from '../../../../constants/ar/checkboxes/raceOptions';
import { ethnicityOptions } from '../../../../constants/radios/ethnicityOptions';
import CheckedIcon from '../../../CheckedIcon'
import useLiheapForm from '../../../../hooks/useLiheapForm';
import FormSSN from '../../../form/FormSSN';
import { submittingAsOptions } from '../../../../constants/radios/submittingAsOptions'
import { birthDateOnEditSchema } from '../../../../constants/ar/stepValidationSchemas';
import { getCountyOptions } from '../../../../utils/dashboardUtils/utilSharedTableFunctions';
import { AuthContext } from '../../../../context/authContext';
import { statuses } from '../../../../constants/appStatuses';

const useStyles = makeStyles((theme) => ({

  mainContainer: {
    marginLeft: "0"
  },
  row: {
    width: "auto"
  },
  sectionHeader: {
    fontWeight: "bold"
  },
  useServiceAddress: {
    marginTop: '1rem'
  },
  checkBox: {
    color: theme.customColors.mediumGray,
    padding: '.75rem'
  },
  textField: {
    maxWidth: '21rem',
    backgroundColor: '#fff',
    "& > .MuiFormHelperText-root.Mui-error": { 
      backgroundColor: theme.customColors.veryLightGray,
      margin:0
    },
  }
}));

export default function PersonalAndContactInfo({ data, back, next, save, validate, validationSchema }) {
  const validateOnEditValidationSchema = birthDateOnEditSchema;
  const { register, control, errors, setValue, getValues, watch, trigger } = useLiheapForm(save, data, validate, validationSchema, false, validateOnEditValidationSchema);
  const classes = useStyles();
  const [sameMailingAddress, setSameMailingAddress] = useState(data?.sameMailingAddress ? data?.sameMailingAddress : false);

  const { userInfo } = useContext(AuthContext);

  const [submittingAs, setSubmittingAs] = useState(data?.submittingAs);

  const appStatus = data?.status

  const handleSubmittingAsSelect = (val) => {
    if(val === 'myself'){
      setValue('preparerName', '', { shouldDirty: true })
    }
    setSubmittingAs(val)
  }


  // clears the placeholder value when auto-filled
  // eslint-disable-next-line
  const watchFields = watch([
    "serviceStreetAddress", "serviceCity", "serviceZip", "serviceCountyOrParish", "serviceApartmentNumber",
    "mailingStreetAddress", "mailingCity", "mailingZip", "mailingCountyOrParish", "mailingApartmentNumber"
  ]);

  const getLabelShrink = (fieldName) => {
    return getValues(fieldName) !== '';
  }

  const useServiceAddress = (event) => {
    if (event.target.checked) {
      setValue("mailingCity", getValues('serviceCity'), { shouldDirty: true });
      setValue("mailingState", getValues('serviceState'), { shouldDirty: true });
      setValue("mailingCountyOrParish", getValues('serviceCountyOrParish'), { shouldDirty: true });
      setValue("mailingZip", getValues('serviceZip'), { shouldDirty: true });
      setValue("mailingStreetAddress", getValues('serviceStreetAddress'), { shouldDirty: true })
      setValue("mailingApartmentNumber", getValues('serviceApartmentNumber'), { shouldDirty: true });
      setSameMailingAddress(true);
    }
    else {
      setValue("mailingCity", "", { shouldDirty: true });
      setValue("mailingState", "", { shouldDirty: true });
      setValue("mailingCountyOrParish", "", { shouldDirty: true });
      setValue("mailingZip", "", { shouldDirty: true });
      setValue("mailingStreetAddress", "", { shouldDirty: true })
      setValue("mailingApartmentNumber", "", { shouldDirty: true });
      setSameMailingAddress(false);
    }
  }

  const serviceInputEdited = (event) => {
    if(sameMailingAddress){
      switch(event.target.name) {
        case 'serviceStreetAddress':
          setValue("mailingStreetAddress", getValues('serviceStreetAddress'), { shouldDirty: true });
          break
        case 'serviceApartmentNumber':
          setValue("mailingApartmentNumber", getValues('serviceApartmentNumber'), { shouldDirty: true });
          break
        case 'serviceCity':
          setValue("mailingCity", getValues('serviceCity'), { shouldDirty: true });
          break
        case 'serviceState':
          setValue("mailingState", getValues('serviceState'), { shouldDirty: true });
          break
        case 'serviceZip':
          setValue("mailingZip", getValues('serviceZip'), { shouldDirty: true });
          break
        case 'serviceCountyOrParish':
          setValue("mailingCountyOrParish", getValues('serviceCountyOrParish'), { shouldDirty: true });
          break
        default :
          return null;
      }
    }
  }

  return (
    <Container className={classes.mainContainer} component="main">
        <Grid container spacing={1}>
          <StepHeader
            title="Applicant Information"
            desc="Please provide the following information for the applicant."
          />
          <FormSection>
            <Grid item width="auto">
              <Typography variant="h6" className={classes.sectionHeader}>Applicant General Information</Typography>
            </Grid>
            <Grid className={classes.row} container spacing={1}>
              <Grid item xs={12} sm={6} >
                <FormTextField name="firstName" label="First Name" register={register} errors={errors} disabled={appStatus !== statuses.NEW}/>
              </Grid>
              <Grid item xs={12} sm={6} >
                <FormTextField name="lastName" label="Last Name" register={register} errors={errors} disabled={appStatus !== statuses.NEW}/>
              </Grid>
              <Grid item xs={12} sm={6} >
                <FormTextField name="middleName" label="Middle Name" register={register} errors={errors} disabled={appStatus !== statuses.NEW}/>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormDatePicker
                  control={control}
                  name="birthDate"
                  label="Date of Birth"
                  defaultValue={data?.birthDate}
                  InputAdornmentProps={{ position: "end" }}
                  id="birthDate-date-picker"
                  errors={errors}
                  variant='outlined'
                  userType='applicant'
                  validationTrigger={trigger}
                  disabled={appStatus !== statuses.NEW}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormTextField name="email" label="Email" register={register} errors={errors} disabled={appStatus !== statuses.NEW}/>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormTextField name="phoneNumber" label="Phone Number" register={register} errors={errors} mask="(999) 999-9999"
                  inputProps={{
                    inputMode: 'tel',
                    maxLength: 14
                  }} />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormSSN name="ssn" register={register} errors={errors} validate={validate} trigger={trigger} disabled={appStatus !== statuses.NEW}/>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormRadioAsButtons
                  control={control}
                  name="submittingAs"
                  label="Are you submitting for yourself or on behalf of someone else?"
                  errors={errors}
                  options={submittingAsOptions}
                  onSelect={handleSubmittingAsSelect}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                {submittingAs === 'someoneElse' && 
                  <FormTextField
                    className={classes.textField}
                    name="preparerName" 
                    label="Enter preparer's full name"
                    labelPlacement="top" 
                    register={register} 
                    errors={errors}   />
                }    
              </Grid>
            </Grid>
          </FormSection>
          <FormSection>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.sectionHeader}>Utility Service Address Information</Typography>
            </Grid>
            <Grid className={classes.row} container spacing={1}>
              <Grid item xs={12} sm={6}>
                <FormTextField name="serviceStreetAddress" label="Utility Service Address" register={register} errors={errors} id="serviceStreetAddress"
                  InputLabelProps={{ shrink: getLabelShrink("serviceStreetAddress") }} onChange={serviceInputEdited} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormTextField name="serviceApartmentNumber" label="Apartment Number" register={register} errors={errors}
                  InputLabelProps={{ shrink: getLabelShrink("serviceApartmentNumber") }} onChange={serviceInputEdited} />
              </Grid>
            </Grid>
            <Grid className={classes.row} container spacing={1}>
              <Grid item xs={12} sm={6}>
                <FormTextField name="serviceCity" label="City" register={register} errors={errors}
                  InputLabelProps={{ shrink: getLabelShrink("serviceCity") }} onChange={serviceInputEdited} />
              </Grid>
              <Grid onClick={serviceInputEdited} item xs={12} sm={6}>
                <FormSelect
                  name="serviceState"
                  label="State"
                  labelId="serviceState-label"
                  labelPlacement="inside"
                  control={control}
                  errors={errors}
                  options={stateOptions}
                />
              </Grid>
            </Grid>
            <Grid className={classes.row} container spacing={1}>
              <Grid item xs={12} sm={6}>
                <FormTextField name="serviceZip" label="Zip" register={register} errors={errors} mask="99999"                
                  InputLabelProps={{ shrink: getLabelShrink("serviceZip") }}
                  inputProps={{ inputMode: 'tel', maxLength: 5, onChange: (e) => serviceInputEdited(e) }} />
              </Grid>
              <Grid onClick={serviceInputEdited} item xs={12} sm={6}>
                <FormSelect
                  name="serviceCountyOrParish"
                  label="County/Parish"
                  labelId="serviceCountyOrParish-label"
                  labelPlacement="inside"
                  control={control}
                  errors={errors}
                  options={getCountyOptions(userInfo?.agencyId)}
                />
              </Grid>
            </Grid>
          </FormSection>
          <FormSection>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.sectionHeader}>Applicant Mailing Address</Typography>
            </Grid>
            <FormControlLabel 
              className={classes.useServiceAddress}
              control={<Checkbox className={classes.checkBox}
              inputRef={register} 
              checked={sameMailingAddress} 
              checkedIcon={<CheckedIcon />} />}
              onChange={useServiceAddress}
              name="sameMailingAddress"
              label="Mailing Address is same as Utility Service Address"/>
            <Grid className={classes.row} container spacing={1} style={{display: sameMailingAddress ? 'none' : 'flex'}}>
              <Grid item xs={12} sm={6}>
                <FormTextField name="mailingStreetAddress" label="Mailing Address" register={register} errors={errors} id="mailingStreetAddress"
                  InputLabelProps={{ shrink: getLabelShrink("mailingStreetAddress") }} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormTextField name="mailingApartmentNumber" label="Apartment Number" register={register} errors={errors}
                  InputLabelProps={{ shrink: getLabelShrink("mailingApartmentNumber") }} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormTextField name="mailingCity" label="City" register={register} errors={errors}
                  InputLabelProps={{ shrink: getLabelShrink("mailingCity") }} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormSelect
                  name="mailingState"
                  label="State"
                  labelId="mailingState-label"
                  labelPlacement="inside"
                  control={control}
                  errors={errors}
                  options={stateOptions} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormTextField name="mailingZip" label="Zip" register={register} errors={errors} mask="99999" 
                  InputLabelProps={{ shrink: getLabelShrink("mailingZip") }}
                  inputProps={{ inputMode: 'tel', maxLength: 5 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {/* TODO: Possibly convert to select dependent on state selected */}
                <FormTextField name="mailingCountyOrParish" label="County/Parish" register={register} errors={errors}
                  InputLabelProps={{ shrink: getLabelShrink("mailingCountyOrParish") }} />
              </Grid>
            </Grid>
          </FormSection>
          <FormSection>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.sectionHeader}>Personal Information</Typography>
            </Grid>
            <Grid className={classes.row} item xs={12} container justifyContent="flex-start">
              <FormRadioAsButtons
                control={control}
                name="gender"
                label="What is your gender?"
                errors={errors}
                options={genderOptions}
              />
            </Grid>
            <Grid className={classes.row} item xs={12} container justifyContent="flex-start">
              <FormCheckboxes
                defaultValues={data?.race}
                register={register}
                name="race"
                label="What is your race? Please check all that apply."
                errors={errors}
                options={raceOptions}
                gridSize={12}
                noOption="Prefer not to say"
              />
            </Grid>
            <Grid className={classes.row} item xs={12} container justifyContent="flex-start">
              <FormRadioAsButtons
                control={control}
                name="ethnicity"
                label="What is your ethnicity?"
                errors={errors}
                options={ethnicityOptions}
              />
            </Grid>
            <Grid className={classes.row} item xs={12} container justifyContent="flex-start">
              <FormRadioAsButtons
                control={control}
                name="disabled"
                label="Do you have a disability?"
                errors={errors}
                options={yesNoOptions}
              />
            </Grid>
            <Grid className={classes.row} item xs={12} container justifyContent="flex-start">
              <FormRadioAsButtons
                control={control}
                name="veteranOrActivelyServing"
                label="Are you a veteran or actively serving in the military?"
                errors={errors}
                options={yesNoOptions}
              />
            </Grid>
          </FormSection>
          <StepButtons back={back} next={next}/>
        </Grid>
    </Container>
  );
}
