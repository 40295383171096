import { Button, CircularProgress, Grid, Typography, Tab, Tabs } from "@mui/material";
import { useState, useContext, useEffect, useRef } from "react";
import { FileDownloadOutlined } from "@mui/icons-material";
import { getReportData, getReportsAuditData} from "../../../api/AgencyAPI";
import { useErrorViewer } from "../../../context/errorContext";
import { downloadCSV } from "../../../utils/utilFunctions";
import { AuthContext } from '../../../context/authContext';
import makeStyles from '@mui/styles/makeStyles';
import DateRangePicker from "../../DateRangePicker";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ExportHistory from "../components/ExportHistory";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2)
  },
  openAllButton: {
    textTransform: 'none',
    padding: '.75rem'
  },
}));

export default function Reports() {
  const { userInfo } = useContext(AuthContext);
  const setError = useErrorViewer();
  const allowStrickland = userInfo.agencyState==="AR"
  let isDefaultReport = true;
  const [dateRangeValue, setDateRangeValue] = useState([null, null]);
  const [loading, setLoading] = useState(false);
  const [clickLoading, setClickLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0); //useState to rerender selected tab
  const [exportHistories, setExportHistories] = useState([]);
  const handleTabChange = (event, newTabIndex) => {setTabIndex(newTabIndex)};
  const dataFetchedRef = useRef(false);
  const classes = useStyles();
  const [openAll, setOpenAll] = useState(false);

  const [validDateRange, setValidDateRange] = useState(false)
  const dateUtil = new AdapterDateFns();

  useEffect(() => {
    if(dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    fetchData();
    setLoading(true);
  }, []) //pass in empty array as second argument; effect will only execute on first render

  const onDownloadClick = (isDefaultReport) => {
    let endDate = new Date(dateRangeValue[1])
    let requestBody = {
      "dateFilter": {
        "field" : "initialSubmittedDate",
        "gte" : dateRangeValue[0],
        "lte" : new Date(endDate.setDate(endDate.getDate() + 1))
      }, 
      "sort": "initialSubmittedDate:asc:date",
      "report": "default"     
    };

    setClickLoading(true);
    if (allowStrickland && !(isDefaultReport)) {
      let endDate = new Date(dateRangeValue[1])
      requestBody = {
        "dateFilter": {
          "field" : "statusDate",
          "gte" : dateRangeValue[0],
          "lte" : new Date(endDate.setDate(endDate.getDate() + 1))
        },
        "sort": "statusDate:asc:date",
        "report": "strickland"
      };
    }

    getReportData(requestBody).then((response) => {
      if (response.data){
        downloadCSV(response.data, `applications_${new Date().toLocaleDateString("en-US")}`);
        setLoading(true);
        fetchData();
      }else{
        setError("No applications meet the search criteria")
      }
      setClickLoading(false);
    })
    .catch((error) => {
      setLoading(false);
      setError("Error Generating Report");        
    });    
  }

  const onStricklandDownloadClick = (isDefaultReport) => {
    onDownloadClick(isDefaultReport)
  }

  const handleDateRangeSelection = (startDate, endDate, errors) => {
    setDateRangeValue([startDate,endDate])
    setValidDateRange(!errors && startDate && dateUtil.isValid(startDate) && endDate && dateUtil.isValid(startDate))
  }

  const fetchData = () => {
    getReportsAuditData().then((response) => {
      if (response) {
        let sorted = (response.data.sort((a, b) => new Date(a.createdDate) - new Date(b.createdDate))).reverse()
        setExportHistories(sorted)
        setLoading(false);
      }else{
      }})
      .catch((error) => {
        setLoading(false);
      });  
    }
    
  if(allowStrickland){
    return(
    <Grid container>
      <Tabs value ={tabIndex} onChange={handleTabChange}>
        <Tab label="Internal Reports"  />
        <Tab label="Export for Strickland"/>
      </Tabs>

      {tabIndex===0 && (
        <>
          <Grid item xs={12} sx={{m: 2}} >
            <Typography variant="h5">Generate Report</Typography>
          </Grid>
          <Grid item xs={12} >
            <Typography variant="h10" sx={{p:2}}>Please select date range</Typography>
          </Grid>
          <Grid item xs={12} sx={{m: 2}}>
            <DateRangePicker onDateRangeSelection={handleDateRangeSelection}/>
          </Grid>
          <Grid item xs={12} sx={{m: 2}}>
            <Button 
              variant="outlined"
              color="primary"
              component="span" 
              size='large' 
              startIcon={
                clickLoading ? 
                  <CircularProgress color="primary" size={20} />
                :
                  <FileDownloadOutlined color='#fff' />          
              }
              onClick={() => onDownloadClick(isDefaultReport)}
              disabled={!validDateRange}
            >      
              Download CSV
            </Button>
          </Grid>
        </>
      )}
      {tabIndex===1 && (
        <>
          <Grid item xs={12} sx={{m: 2}} >
            <Typography variant="h5">Generate Strickland Report</Typography>
          </Grid>
          <Grid item xs={12} >
            <Typography variant="h10"sx={{p:2}}>Please select date range</Typography>
          </Grid>
          <Grid item xs={12} sx={{m: 2}}>
            <DateRangePicker onDateRangeSelection={handleDateRangeSelection}/>
          </Grid>
          <Grid item xs={12} sx={{m: 2}}>
            <Button 
              variant="outlined"
              color="primary"
              component="span" 
              size='large' 
              startIcon={
                clickLoading ? 
                  <CircularProgress color="primary" size={20} />
                :
                  <FileDownloadOutlined color='#fff' />            
              }
              onClick={() => onStricklandDownloadClick(isDefaultReport = false)}
              disabled={!validDateRange}
            >      
              Download STRICKLAND CSV
            </Button>
          </Grid>
          <div className={classes.root}>
            <Grid
              container
              spacing={1}
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
              item
              xs={12}
            >
              {loading ?                  
                <Grid container >
                  <Grid item xs={12} >
                    <Typography variant="h5">Fetching Report Export History...</Typography>
                  </Grid>         
                  <Grid item xs={12} sx={{m: 5}} style={{textAlign: "center"}}>
                    <CircularProgress color="primary" size={50} thickness={3}/> 
                  </Grid>                    
                </Grid>
              :
                <>
                  <Grid container item xs={12} >
                    <Grid item xs={10}>
                      <Typography variant="h5">Strickland Report Export History</Typography>
                    </Grid>
                    <Grid item xs={2} textAlign={'right'}>
                      <Button
                        className={classes.openAllButton}
                        color="primary"
                        onClick={() => setOpenAll((prevState) => {return !prevState})}
                        variant="text"
                      >
                        {openAll ? 'Close All' : 'Open All'}
                      </Button>
                    </Grid>
                  </Grid>  
                  <Grid container item xs={12}>
                    {exportHistories.map((history, index) => (
                      <ExportHistory key={index} history={history} openAll={openAll}/>
                    ))}
                  </Grid>
                </>
              }                 
            </Grid>
          </div>
        </>
      )}
    </Grid>
  )
}
  else 
    return (
      <Grid container>
      <Grid item xs={12}>
        <Typography variant="h5">Reports</Typography>
      </Grid>
      <Grid item xs={12} sx={{marginTop: "1rem"}}>
        <Typography variant="h6">Data Export</Typography>
      </Grid>
      <Grid item xs={12} sx={{marginTop: "2rem"}}>
        <Typography variant="h7">Please select date range</Typography>
      </Grid>
      <Grid item xs={12} sx={{marginTop: "1rem"}}>
      <DateRangePicker onDateRangeSelection={handleDateRangeSelection}/>
      </Grid>
      <Grid item xs={12} sx={{marginTop: "2rem"}}>
        <Button 
          variant="outlined"
          color="primary"
          component="span" 
          size='large' 
          startIcon={
            loading ? 
              <CircularProgress color="primary" size={20} />
              :
              <FileDownloadOutlined color='#fff' />            
          }
          onClick={() => onDownloadClick()}
          disabled={!validDateRange}
        >      
          Download CSV
        </Button>
      </Grid>
    </Grid>
    )
}
