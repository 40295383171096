import { Grid, Typography,Accordion, AccordionSummary, AccordionDetails, IconButton, Input, Modal, Tooltip} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ExpandMore, Close, RemoveCircleRounded, AddCircleOutlined, Rotate90DegreesCw, Print, Download } from '@mui/icons-material'
import React, {useEffect, useState} from 'react'
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import printJS from 'print-js'
import { formatDate } from '../../../../utils/utilFunctions';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const useStyles = makeStyles((theme) => ({
  expandIcon: {
    color: theme.palette.primary.main,
    padding: '.75rem',
    [theme.breakpoints.down('md')]: {
      marginLeft: '1rem'
    }
  },
  summary: {
    alignItems: 'center',
    color: '#000',
    display: 'flex'
  },
  details: {
    padding: '0 1rem 1rem 1rem'
  },
  field: {
    padding: '.5rem'
  },
  updatedField: {
    backgroundClip: 'content-box',
    backgroundColor: theme.customColors.lightBlue,
    padding: '.5rem',
  },
  fieldLabel:{
    padding: '.75rem 0',
    minHeight: '1.5rem',
    marginRight: '1rem',
    fontWeight: 'bold'
  },
  fieldValue:{
    display: 'flex',
    alignItems: 'center',
    overflowWrap: 'anywhere',
    color: '#000'
  },
  docValue:{
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
    marginTop: '1rem',
    overflowWrap: 'anywhere',
  },
  ssnIcon: {
    marginLeft: '.5rem',
    padding: '0rem',
  },
  ssnField: {
    maxWidth: '5.4rem',
    maxHeight: '1.5rem',
    overflow: 'hidden',
  },
  modal: {
    position: 'absolute',
    boxSizing: 'inherit',
    maxHeight: '35rem',
    overflow: 'scroll',
    backgroundColor: '#fff',
    width: '55rem'
  },
  modalHeader:{
    padding: '1rem',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'sticky',
    top: 0,
    left: 0,
    zIndex: 99,
    backgroundColor: '#fff',
    width: '100%'
  },
  modalActions:{
    display: 'flex',
    color: '#757575',
    alignItems: 'center'
  }
}));


export default function ReviewSubSection({ info, showSSN, setShowSSN, openAll }) {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);
  const [file, setFile] = useState();
  const [fileLabel, setFileLabel] = useState();
  const [numPages, setNumPages] = useState(null);
  const baseUrl = `${window.location.origin}/api/file/`
  const [openPDF, setOpenPDF] = useState(false)
  const [modalStyle] = useState(getModalStyle);
  const [rotate, setRotate] = useState(0)

  function changeRotate() {
    setRotate(prevRotate => prevRotate + 90)
  }

  const [scale, setScale] = useState(1.0)
  const [percentage, setPercentage] = useState(100)

  function changeScale(offset) {
    setScale(prevScale => prevScale + offset)
    setPercentage(prevPercentage => prevPercentage + offset*100)
  }

  function zoomOut() {
    changeScale(-.25);
  }

  function zoomIn() {
    changeScale(+.25);
  }

  useEffect(() => {
      setExpanded(openAll)
    // eslint-disable-next-line
  }, [openAll]);

  const handleOpenFile = (doc, label) => {
    setFile(doc)
    setFileLabel(label)
    setOpenPDF(true);
  }

  const handleCloseFile = () => {
    setOpenPDF(false);
    setFile('');
    setFileLabel('');
    setScale(1.0);
    setPercentage(100);
    setRotate(0);
  };

  const getShowSSN = (fieldId) => {
    return showSSN.filter((el) => el.id === fieldId)[0].visible
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const handleSSNClick = (fieldId) => {
    setShowSSN((prevState) => {
      const visible = prevState.filter((el) => el.id === fieldId)[0].visible
      const newState = prevState.filter((el) => el.id !== fieldId)
      newState.push({id: fieldId, visible: !visible})
      return newState
    })
  }

  const handleMouseDownSSN = (event) => {
    event.preventDefault();
  };

  const setExpandedValue = () => {
    setExpanded(prevState => {return !prevState})
  }

  const downloadDoc = (encryptedUrl) => {
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = `${encryptedUrl}`;
    a.download = fileLabel
    a.click();
    document.body.removeChild(a);
  }

  const TooltipWrapper = ({condition, previousValue, children}) => {
    return condition ? 
      <Tooltip title={`Previous Value: ${previousValue || previousValue === 0 ? previousValue : '""'}`} placement='top-start'>{children}</Tooltip> 
      : 
      children
  }

  return (
    <Accordion expanded={expanded}>
      <AccordionSummary
        expandIcon={<ExpandMore className={classes.expandIcon}/>}
        aria-label={`${info.title}-content`}
        id={`${info.title}-header`}
        onClick={setExpandedValue}
      >
        <Grid className={classes.summary} item xs={12}>
          <Grid item xs={11}>
            <Typography variant='subtitle2'>{info.title}</Typography> 
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <Grid container>
          {info?.fields.map((field, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index} className={field.hasOwnProperty("previousValue") ? classes.updatedField : classes.field}>
              <Typography className={classes.fieldLabel} variant='subtitle2'>
                <label htmlFor={`${field.label}-id`}>
                  {field.label}
                </label>
              </Typography>
              { field.label === 'Social Security Number' && field.value ?
                <>
                  <Input 
                    readOnly 
                    disableUnderline 
                    id={`${field.label}-id`}
                    aria-label={`${field.label}`}
                    type={getShowSSN(field.appOrMemberId) ? 'text' : 'password'} 
                    value={field.value} 
                    className={classes.ssnField}
                  /> 
                  <TooltipWrapper
                    key={index}
                    condition={field.hasOwnProperty("previousValue")}
                    previousValue={field.previousValue}
                  >
                    <IconButton
                      aria-label="toggle SSN visibility"
                      onClick={() => handleSSNClick(field.appOrMemberId)}
                      onMouseDown={handleMouseDownSSN}
                      edge="end"
                      className={classes.ssnIcon}
                      size="large">
                      {getShowSSN(field.appOrMemberId) ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </TooltipWrapper>
                </>
              : 
              <>
              {info.title === 'Documents' ?
                <>
                  {Array.isArray(field?.value) && field.value.map((doc, index) => (
                    <TooltipWrapper
                    key={index}
                    condition={field.hasOwnProperty("previousValue")}
                    previousValue={field.previousValue}
                    >
                      <Typography key={index} variant='subtitle2' className={classes.docValue} onClick={() => handleOpenFile(doc, field.label)}> 
                        {doc.fileName.toString()}
                      </Typography>
                    </TooltipWrapper>
                  ))}
                </>
                : 
                <>
                {(field.label === 'Birth Date' || field.label === 'Last Employed Date') ?
                  <TooltipWrapper
                    condition={field.hasOwnProperty("previousValue")}
                    previousValue={field.previousValue ? formatDate(field.previousValue) : ""}
                  >
                    <Typography variant='subtitle2' className={field.isValid ? classes.fieldValue : classes.errorField}> 
                      {field.value ? formatDate(field.value) : 'No answer'}
                    </Typography>
                  </TooltipWrapper>
                :
                  <TooltipWrapper
                    condition={field.hasOwnProperty("previousValue")}
                    previousValue={field.previousValue}
                  >
                    <Typography variant='subtitle2' className={classes.fieldValue}> 
                      {field.value.toString()}
                    </Typography>
                  </TooltipWrapper>
                }
                </>
              }
              </>
            }
          </Grid>
      ))}
      </Grid>

      <Modal open={openPDF} onClose={handleCloseFile}>
        <Grid style={modalStyle} className={classes.modal}>
          <Grid container item xs={12} className={classes.modalHeader}>
          <Grid item xs={6}>
            <Typography variant='h6'>
              {fileLabel}
            </Typography>
            </Grid>
            <Grid item xs={6} className={classes.modalActions} justifyContent='flex-end'>
              {file &&
              <>
              <IconButton
                id="download" 
                aria-label="Download Document"
                type="button"
                onClick={()=>downloadDoc(`${baseUrl + file?.fileUrl}`)}
                >
                <Download />
              </IconButton>
              <IconButton
                id="print" 
                aria-label="Print Document"
                type="button"
                onClick={() => printJS({printable: `${baseUrl + file?.fileUrl}`})}
              >
                <Print />
              </IconButton>
              <IconButton
                id="rotate" 
                aria-label="Rotate Document"
                type="button"
                onClick={changeRotate}
              >
                <Rotate90DegreesCw />
              </IconButton>
              <IconButton
                type="button"
                id="zoomOut" 
                aria-label="Zoom Out Document"
                disabled={scale <= 0}
                onClick={zoomOut}
              >
                <RemoveCircleRounded/>
              </IconButton>
              <Typography variant='subtitle1'>
                {percentage}%
              </Typography>
              <IconButton
                type="button"
                id="zoomIn" 
                aria-label="Zoom In Document"
                disabled={scale >= 2.0}
                onClick={zoomIn}
              >
                <AddCircleOutlined/>
              </IconButton>
              </>
              }
              <IconButton onClick={handleCloseFile}>
                <Close/>
              </IconButton>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Document 
              file={baseUrl + file?.fileUrl}
              onLoadSuccess={onDocumentLoadSuccess} 
            >
              {Array.from(
                new Array(numPages),
                (el, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    scale={scale}
                    rotate={rotate}
                  />
                ),
              )}
            </Document>
          </Grid>
        </Grid>
      </Modal>
    </AccordionDetails>
  </Accordion>
  );
}
