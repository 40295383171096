import { useQuery } from 'react-query';
import { getStateReportData } from '../api/StateAPI';

export default function useStateReportData(reportType, filter) {
  return useQuery(['stateReportData', reportType, filter], async () => {
    const { data } = await getStateReportData(reportType, filter)
    .then((response) => {
      if(response){
        let data = []
        data= response?.data;
        return {data: data}
      }    
    })
    return data;
  }, {enabled: true});
}
