import { Container, Grid, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import StepHeader from '../StepHeader';
import StepButtons from '../StepButtons';
import FormSection from '../FormSection';


const useStyles = makeStyles((theme) => ({
  listItemTextPrimary: {
    fontWeight: "bold"
  },
  mainContainer: {
    marginLeft: "0",
  }
}));

export default function RequiredDocuments({ data, back, next }) {
  const classes = useStyles();

  return (
    <Container className={classes.mainContainer}>
      <Grid container spacing={1}>
        <StepHeader 
          title="You'll need these documents" 
          desc = "We've compiled a list of documents you will likely need to complete your LIHEAP application."
        />
        <FormSection> 
          <Grid item xs={12}>
            <ListItem alignItems="flex-start">
              <ListItemIcon>
                <img src="/img/id-card.webp" width="45" height="45" alt="Proof of Identity"/>
              </ListItemIcon>
              <ListItemText 
                primary="Proof of Identity"
                primaryTypographyProps={{className: classes.listItemTextPrimary}}
                secondary={
                  <>
                    <Typography component="span">
                      Driver's License or picture identification for each person living in the household over 17 y.o.
                    </Typography>
                  </>
                }
              />
            </ListItem> 
          </Grid> 
        </FormSection>
        <FormSection>  
          <Grid item xs={12}>      
            <ListItem alignItems="flex-start">
              <ListItemIcon>
                <img src="/img/ssCard.png" width="45" height="45" alt="Proof of Citizenship"/>
              </ListItemIcon>
              <ListItemText 
                primary="Social Security Card"
                primaryTypographyProps={{className: classes.listItemTextPrimary}}
                secondary={
                  <>
                    <Typography component="span">
                      Social Security cards for everyone living in the household.
                    </Typography>
                  </>
                }
              />
            </ListItem>
          </Grid>
        </FormSection>
        <FormSection>
          <Grid item xs={12}>
            <ListItem alignItems="flex-start">
              <ListItemIcon>
                <img src="/img/coin.webp" width="45" height="45" alt="Proof of Income"/>
              </ListItemIcon>
              <ListItemText 
                primary="Proof of Income"
                primaryTypographyProps={{className: classes.listItemTextPrimary}}
                secondary={
                  <>
                    <Typography component="span">
                      Last month's pay check stubs or statements for everyone 18 years and older living in the household.
                    </Typography>
                  </>
                }
              />
            </ListItem>
          </Grid>
        </FormSection>
        <FormSection>
          <Grid item xs={12}>
            <ListItem alignItems="flex-start">
              <ListItemIcon>
                <img src="/img/bill.webp" width="45" height="45" alt="Energy Bill "/>
              </ListItemIcon>
              <ListItemText 
                primary="Energy Bill"
                primaryTypographyProps={{className: classes.listItemTextPrimary}}
                secondary={
                  <>
                    <Typography component="span">
                      The most recent heating and cooling bill.
                    </Typography>
                  </>
                }
              />
            </ListItem>
          </Grid>
        </FormSection>
        <StepButtons back={back} next={next}/>
      </Grid>
    </Container>
  )
}
