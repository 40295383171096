import React, { useState } from "react";
import { Grid, Typography, Button, Modal, CircularProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import DateRangePicker from "../../DateRangePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useErrorViewer } from "../../../context/errorContext";
import dayjs from "dayjs";
import { parseISO } from "date-fns";
import { useMutation, useQueryClient } from "react-query";
import { updateStateConfig } from "../../../api/ConfigAPI";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const useStyles = makeStyles((theme) => ({
  subtitleStyle: {
    paddingBottom: "2rem",
    marginTop: "2rem",
  },
  separator: {
    padding: "1.5rem",
    paddingTop: "2rem",
    marginTop: "1rem",
    display: "inline",
    alignItems: "center",
  },
  modal: {
    position: 'absolute',
    background: '#FDFDFD',
    boxShadow: '0 0 .3rem rgba(0, 0, 0, 0.2)',
    bozSizing: 'inherit',
    borderRadius: '1.75rem',
    padding: '3rem',
    width: '40rem',
    maxHeight: '35rem',
    overflow: 'scroll'
  },
  modalHeader:{
    marginBottom: '1rem',
    textTransform: 'capitalize'
  },
  modalButton: {
    marginTop: '1rem',
    textTransform: 'none',
    fontWeight: 'bold'
  },
}));

export default function StateSeasonEditClosedModal({ data, season, open, onClose }) {
  const classes = useStyles();

  const [modalStyle] = useState(getModalStyle);
  const queryClient = useQueryClient();

  const [dateRangeValue, setDateRangeValue] = useState([data?.startDate, data?.endDate]);
  const [validDateRange, setValidDateRange] = useState(false);
  const dateUtil = new AdapterDateFns();
  const [startTimeValue] = useState(dayjs(data?.startDate));
  const [endTimeValue, setEndTimeValue] = useState(dayjs(data?.endDate));
  const [loading, setLoading] = useState(false)
  const [disableTimepicker, setDisableTimepicker] = useState(!endTimeValue);

  const setError = useErrorViewer();

  const handleDateRangeSelection = (startDate, endDate, errors) => {
    setDateRangeValue([startDate, endDate]);
    setValidDateRange(!errors && endDate && dateUtil.isValid(endDate));
    setDisableTimepicker(false);
    //Disable and reset timepickers if no start and end date selected
    if ((!startDate && !endDate) || (!startDate && !endDate && (endTimeValue))) {
      setDisableTimepicker(true);
      setEndTimeValue(null);
    }
  };

  const handleTimeEndSelection = (endTime) => {
    setEndTimeValue(endTime);
    let startDate = dateRangeValue[0];
    let endDate = dateRangeValue[1];
    endDate?.setHours(endTime.$H, endTime.$m, endTime.$s);
    setDateRangeValue([startDate ? startDate : null, endDate]);
    setValidDateRange(startDate < endDate);
  };  
  const {mutate: updateSeason} = useMutation(
    ({startDate, state, config}) => updateStateConfig({startDate, state, config}),
        {
          onSuccess: () => {
            setLoading(false);
            queryClient.invalidateQueries([`stateConfigData-${season}`]);
            onClose();
          },
            onError: (error) => {
              let err = error.response.data ? error.response.data.message : "An error occurred creating a new season."
              setError(err);
              setLoading(false);
            },
        }
  );

  const onSubmit = () => {
    setLoading(true);
    let updatedStateConfig = {
      startDate: data?.startDate,
      endDate: dateRangeValue[1],
    };

    updateSeason({startDate: data?.startDate, state: data?.state, config: updatedStateConfig});
  }

  return (
    <>
    <Modal open={open} onClose={onClose}>
      <Grid  style={modalStyle} className={classes.modal}>
        <Grid item container justifyContent="flex-start">
          <Typography variant="h5" className={classes.modalHeader}>
            Edit Date & Time
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.subtitleStyle}>
          <DateRangePicker
            onDateRangeSelection={handleDateRangeSelection}
            defaultStartVal={parseISO(data?.startDate)}
            defaultEndVal={parseISO(data?.endDate)}
            disableStartDate={true}
            showClear={false}
          />
          <br></br>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              label="Start Time"
              disabled
              value={startTimeValue}
            />
            <div className={classes.separator} />
            <TimePicker
              label="End Time"
              disabled={disableTimepicker}
              value={endTimeValue}
              onChange={handleTimeEndSelection}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item container justifyContent="flex-end" >
          {loading ? 
            <CircularProgress/> 
          : 
          <>
            <Button className={classes.modalButton} onClick={onClose} variant="text" color="primary">
              Cancel
            </Button>
            <Button className={classes.modalButton} onClick={onSubmit} variant="text" color="primary" disabled={!validDateRange}>
              Save
            </Button>
          </>
          }
        </Grid>
      </Grid>
    </Modal>

    </>
  );
}
