import { useErrorViewer } from "../../../../context/errorContext";
import { useMutation, useQueryClient } from 'react-query';
import { archiveAgencyUser, removeAgencyInvitation } from '../../../../api/AgencyAPI';
import { useContext, useState } from 'react';
import { Grid, IconButton } from '@mui/material';
import { DeleteOutlined } from '@mui/icons-material';
import ConfirmationDialog from '../../../ConfirmationDialog';
import { AuthContext } from "../../../../context/authContext";
import SuccessModal from "../../../SuccessModal";
import ModalWarning from "../../../ModalWarning";

export default function AdminRemoveUser({user, isActiveUser}){
  const queryClient = useQueryClient();
  const setError = useErrorViewer();
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { userInfo } = useContext(AuthContext);
  const [successOpen, setSuccessOpen] = useState(false);

  const archiveAgencyUserMutation = useMutation(({id}) => {
    return archiveAgencyUser(id);
  })

  const removeAgencyInvitationMutation = useMutation(({id}) => {
    return removeAgencyInvitation(id);
  })

  const handleDelete = (userOrInviteId) => {
    setIsLoading(true);
    if(isActiveUser){
      archiveAgencyUserMutation.mutate(({id: userOrInviteId}), {
        onSuccess: (response) => {
          setIsLoading(false);
          setDeleteConfirmationOpen(false);
          setSuccessOpen(true);
        },
        onError: (error) => {
          setIsLoading(false);
          setDeleteConfirmationOpen(false);
          setError('Error removing user.');
        }
      })
    }
    else{
      removeAgencyInvitationMutation.mutate(({id: userOrInviteId}), {
        onSuccess: (response) => {
          setIsLoading(false);
          setDeleteConfirmationOpen(false);
          setSuccessOpen(true);
        },
        onError: (error) => {
          setIsLoading(false);
          setDeleteConfirmationOpen(false);
          setError('Error removing invitation.');
        }
      })
    }
  }

  const handleCloseSuccessModal = () => {
    if(isActiveUser){
      queryClient.invalidateQueries(['users']);
      queryClient.invalidateQueries(['assignableUsers']); 
    }
    else{
      queryClient.invalidateQueries(['invitations']);
    }
    setSuccessOpen(false)
  }

  return (
     <Grid container item xs={12} justifyContent="center" justifyItems="center" alignContent="center">
       {userInfo?.id !== user.id &&
        <>
        <IconButton
          aria-label="delete"
          onClick={() => setDeleteConfirmationOpen(true)}
        >
          <DeleteOutlined />
        </IconButton>

        <ConfirmationDialog 
            open={deleteConfirmationOpen}
            title={isActiveUser ? "Remove Account" : "Remove Invitation"}
            desc={`Are you sure you want to remove ${user?.firstName} ${user?.lastName}${isActiveUser ? '' : `'s invitation`}? This can not be undone.`}
            cancelLabel="Cancel"
            okLabel='Remove'
            handleCancel={() => setDeleteConfirmationOpen(false)}
            handleOk={() => handleDelete(user?.id)}
            isLoading={isLoading}
            contentChildren={<ModalWarning description={"Warning! Any changes made to user’s roles will be effective on the next login. Please advise your team members to log out and log back in."}/>}
          />

        <SuccessModal
          title={isActiveUser ? "User Removed" : "Invitation Removed"}
          desc={`${user?.firstName} ${user?.lastName}${isActiveUser ? '' : `'s invitation`} has been removed. The user will no longer be able to access the platform.`} 
          open={successOpen} 
          handleOk={handleCloseSuccessModal}
        />
        </>
       }
     </Grid>
  )
}
