import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography, Button } from '@mui/material';
import { useHistory } from 'react-router';
import { introTextBlock } from '../../constants/home-page/intro';

const useStyles = makeStyles((theme) => ({
  mainContainer:{
    width: 'fit-content',
    margin: '2.5rem 0rem',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column-reverse'
    }
  },
  info: {
    padding: '2rem 3rem 2rem 6rem !important',
    [theme.breakpoints.down('lg')]: {
      padding: '1rem 2rem !important',
      margin: 'auto',
    }
  },
  image: {
    position: 'relative',
    margin: 'auto',
    width: '100%',
    [theme.breakpoints.down('lg')]: {
      margin: 'auto',
    },
  },
  title: {
    color: '#000',
    fontSize: '3.563rem',
    lineHeight: '4.5rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.25rem',
      lineHeight: '3rem',
    },
  },
  subtext: {
    color: '#000',
    fontSize: '1rem',
    margin: '2rem 0rem'
  },
  button: {
    width: '13.625rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    textTransform: 'none',
    marginBottom: '1rem'
  }
}));

export default function Intro() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid container item spacing={1} className={classes.mainContainer}>
      <Grid container item md={12} lg={6} className={classes.info}>
        <Typography className={classes.title}>When the going gets tough, getting help shouldn’t be.​</Typography>
        <Typography className={classes.subtext}>{introTextBlock}</Typography>
          <Grid container item xs={12} justifyContent='left' spacing={3}>
            <Grid item>
            <Button variant='contained' color='primary' className={classes.button} onClick={() => history.push(`/register`)}>
              Apply Today
            </Button>
            </Grid>
            <Grid item>
            <Button variant='outlined' color='primary' className={classes.button} onClick={() => document.getElementById('howItWorksScrollTo').scrollIntoView({behavior: 'smooth'})}>
              How It Works
            </Button>
            </Grid>
          </Grid>
      </Grid>
      <Grid item md={12} lg={6} className={classes.image}>
        <img src={`/img/homepage-image.webp`} alt="" width="100%" height="100%" />
      </Grid>
    </Grid>
)}

