import { Container, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'
import FormSection from "../../../FormSection";
import { heatOptions } from '../../../../constants/tx/checkboxes/heatOptions'
import FormCheckboxes from '../../../form/FormCheckboxes'
import FormRadioAsButtons from '../../../form/FormRadioAsButtons'
import { yesNoOptions } from '../../../../constants/radios/yesNoOptions'
import FormTextField from '../../../form/FormTextField'
import useLiheapForm from '../../../../hooks/useLiheapForm'
import StepButtons from '../../../StepButtons'
import StepHeader from '../../../StepHeader'
import FeatureFlag from '../../../FeatureFlag';
import FormCurrencyField from '../../../form/FormCurrencyField';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    marginLeft: "0"
  }
}));

export default function UtilitySituationInformation({ data, back, next, save, validate, validationSchema }) {
  const { control, errors, register, watch, setValue } = useLiheapForm(save, data, validate, validationSchema, false);

  const classes = useStyles();

  const electricityProvider = data?.electricityProvider

  const handleLifeThreatSelect = (val) => {
    if(val === 'no'){
      setValue("crisisExplaination", '', { shouldDirty: true });
    }
  }

  const handleOverdueSelect = (val) => {
    if(val === 'no'){
      setValue("overdueBalanceValue", '', { shouldDirty: true });
    }
  }

  const crisisLifeThreateningWatch = watch("crisisLifeThreatening");
  const overdueBalanceWatch = watch("overdueBalance");

  return (
    <Container className={classes.mainContainer}>
        <Grid container spacing={1}>
          <StepHeader 
            title="Utility Information" 
            desc = "Please provide the following information for the applicant."
          />
          <FormSection>
            <FormCheckboxes
              defaultValues={data?.utilityAssistanceNeeded}
              register={register}
              name="utilityAssistanceNeeded"
              label="What Utility Assistance do you need?"
              errors={errors}
              options={heatOptions}
              gridSize={12}
            />
          </FormSection>
          <FormSection>
            <FormRadioAsButtons
              control={control}
              name="serviceShutOff"
              label="Has your service been recently shut off due to an unpaid bill?"
              errors={errors}
              options={yesNoOptions}
            />
          </FormSection>
          <FormSection>
            <FormRadioAsButtons
              control={control}
              name="disconnectNotice"
              label="Do you have a disconnect date within 7 days?"
              errors={errors}
              options={yesNoOptions}
            />
          </FormSection>
          <FormSection>
            <Grid item xs={12} sm={12}>
                <FormRadioAsButtons
                  control={control}
                  name="crisisLifeThreatening"
                  label="Is your situation life-threatening?"
                  errors={errors}
                  options={yesNoOptions}
                  onSelect={handleLifeThreatSelect}
                />
            </Grid>
            <Grid item xs={12} sm={9}>
              {crisisLifeThreateningWatch === 'yes' &&
                <FormTextField
                  name="crisisExplaination" 
                  label="Please explain in detail"
                  labelPlacement="top" 
                  multiline
                  rows={6}
                  register={register} 
                  errors={errors}                
                />
              }
            </Grid>
          </FormSection>
          <FeatureFlag feature="OtherElectricProviders" hideOnDisabled={true}>
          {electricityProvider === 'other' &&
            <FormSection>
             <Grid item xs={12}>
                <FormRadioAsButtons
                  control={control}
                  name="overdueBalance"
                  label="Do you currently have a past due balance?"
                  errors={errors}
                  options={yesNoOptions}
                  onSelect={handleOverdueSelect}
                />
              </Grid>
              {overdueBalanceWatch === 'yes' &&
              <Grid item xs={12} md={6}>
                <FormCurrencyField
                  name="overdueBalanceValue" 
                  label="What is your current past due balance?"
                  labelPlacement="top"
                  control={control} 
                  errors={errors}                
                />
              </Grid>
              }
          </FormSection>
          }
          </FeatureFlag>
          <StepButtons back={back} next={next}/>
        </Grid>
    </Container>
  )
}
