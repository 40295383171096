import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { FormControl, Typography, Grid } from '@mui/material';
import FormTextField from '../../../../form/FormTextField';

const useStyles = makeStyles((theme) => ({
  outlinedInput: {
    marginTop: '.5rem',
    backgroundColor: '#FFF',    
    "& > .MuiFormHelperText-root.Mui-error": { 
      margin:0
    },
  },
  option: {
    display: 'flex',
    alignItems: 'baseline'
  },
  label: {
    fontWeight: "bold", 
    marginBottom: ".6rem",
    display: 'inline',
  },
}));

export default function DeniedStatusInfo({ application, register, errors, loading}) {
  const classes = useStyles();

  return (                      
  <FormControl>        
    <Grid container spacing={1}>
      <Grid item xs={12} className={classes.option}>
          <Grid item xs={12}>
            <Typography className={classes.label} variant='subtitle2' >
              Specific reason for denied application:
            </Typography>
            <FormTextField
              className={classes.outlinedInput}  
              disabled={loading} 
              name="deniedReasonSpecification" 
              label="Type message to applicant"
              defaultValue={application?.statusInfo?.deniedReasonSpecification}
              multiline
              rows={4}
              register={register} 
              errors={errors}     
            />
          </Grid>
        </Grid>
      </Grid>
  </FormControl>
)}