import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Controller } from 'react-hook-form';
import { FormTooltip } from './FormTooltip';
import RadioIcon from '../RadioIcon';

const useStyles = makeStyles((theme) => ({
  radioGroup: {
    marginTop: ".6rem",
  },
  radio: {
    padding: '.75rem'
  }
}));



export default function FormRadioAsButtons({ control, name, label, labelVariant="subtitle1", errors, toolTipText, options, onSelect, defaultValue = "", disabled=false, inline=false }) {
  const classes = useStyles();

  return (
    <FormControl component="fieldset" margin='normal' error={Boolean(errors[name])} disabled={disabled}>
      {toolTipText 
        ?
        <FormTooltip title={toolTipText} placement="top-end" arrow>
           <Typography variant={labelVariant} style={{fontWeight: "bold"}}>{label}</Typography>
        </FormTooltip>
        :
        <Typography variant={labelVariant} style={{fontWeight: "bold"}}>{label}</Typography>
      }
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        as={
          <RadioGroup className={classes.radioGroup} row>
            {options.map((option) => (
              <Grid item xs={inline ? 6 : 12} key={option.value ? option.value : option}>
                <FormControlLabel
                  value={option.value ? option.value : option}
                  label={option.label ? option.label : option}
                  control={
                    <Radio
                      className={classes.radio}
                      onClick={onSelect ? () => {onSelect(option.value ? option.value : option)} : null}
                      checkedIcon={<RadioIcon className={classes.radio}/>}
                    />
                  }
                />
              </Grid>
            ))}
          </RadioGroup>
        }
      />
      <FormHelperText>{errors[name]?.message}</FormHelperText>
    </FormControl>
  )
}
