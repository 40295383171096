import React, { useContext, useEffect, useState } from 'react';
import { Chip, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { useQuery } from 'react-query';
import { StyledDataGrid } from '../../../StyledDataGrid';
import { getFullName, getSortComparatorFullName, getFormattedDate, getPermissionOption } from '../../../../utils/utilAgencyTableFunctions';
import { AuthContext } from '../../../../context/authContext';
import { getAgencyInvitations } from '../../../../api/AgencyAPI';
import { reviewAppPermissionOptions } from '../../../../constants/dropdowns/reviewAppPermissionOptions';
import { clientIntakePermissionOptions } from '../../../../constants/dropdowns/clientIntakePermissionOptions';
import { agencyPermissions } from '../../../../constants/agencyPermissions';
import AdminRemoveUser from './AdminRemoveUser';
import { reportPermissionOptions } from '../../../../constants/dropdowns/reportPermissionOptions';
import AdminResendInvite from './AdminResendInvite';

const useStyles = makeStyles((theme) => ({
  searchResult: {
    color: theme.customColors.mediumGray
  },
  table: {
    minWidth: '75rem',
  },
  column: {
    fontWeight: '500',
    marginLeft: '.5rem',
    whiteSpace: 'break-spaces',
    maxHeight: 'none !important',
    lineHeight: '1.5rem',
  },
  cell: {
    fontWeight: '500',
    paddingLeft: '.5rem',
    width: '100%',
    whiteSpace: 'break-spaces',
    lineHeight: '1.5rem'
  },
  chip: {
    borderRadius: '.5rem', 
    fontSize: '.75rem',
    height: '1.25rem',
    color: '#fff',
    width: "5rem",
    marginBottom: '.1rem',
    fontWeight: 700,
    '& > .MuiChip-label': {
      textOverflow: 'clip',
    }
  },
}));

export default function AdminInviteTable({ searchValue }) {
  const classes = useStyles();
  const { userInfo } = useContext(AuthContext);
  const agencyId = userInfo?.agencyId;

  const { data, isFetching } = useQuery(['invitations', {agencyId: agencyId}], async () => {
    const { data } = await getAgencyInvitations();
    let filtered = searchAndFilterData(searchValue, data);
    setInvitations(filtered);
    return data;
  }, {enabled: !!agencyId});

  const [invitations, setInvitations] = useState(data);
  const [sortModel, setSortModel] = useState([{field: 'email', sort: 'desc'}]);

  const isAdmin = (user) => {return user?.permissions.includes(agencyPermissions.AGENCY_ADMIN_EDIT)}
  
  const getColumns = () => {
    return [
      {
        field: 'name', valueGetter: getFullName, flex: .6, sortComparator: getSortComparatorFullName,
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Name</Typography>),
        renderCell: (params) => (<Grid><Typography variant='subtitle1' className={classes.cell}>{params.value}</Typography>{isAdmin(params.row) && <Chip variant="filled" style={{backgroundColor: "#BA1B1B"}} className={classes.chip} label="ADMIN" />}</Grid>)
      },
      {
        field: 'email', flex: .6,
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Email</Typography>),
        renderCell: (params) => (<Typography variant='subtitle1' className={classes.cell}>{params.value}</Typography>)
      },
      {
        field: 'clientIntake', 
        valueGetter: (params) => getPermissionOption(params.row.permissions ? params.row.permissions : [], "ClientIntakeDashboard", "", clientIntakePermissionOptions).label, 
        flex: .5,
        sortComparator: permissionsSortComparator,
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Dashboard</Typography>),
        renderCell: (params) => (<Typography variant='subtitle1' className={classes.cell}>{params.value}</Typography>)
      },
      {
        field: 'application', 
        valueGetter: (params) => getPermissionOption(params.row.permissions ? params.row.permissions : [], "ReviewApplication", agencyPermissions.REVIEW_APPLICATION_NONE, reviewAppPermissionOptions).label,
        flex: .5,
        sortComparator: permissionsSortComparator,
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Application</Typography>),
        renderCell: (params) => (<Typography variant='subtitle1' className={classes.cell}>{params.value}</Typography>)
      },
      {
        field: 'reports', 
        valueGetter: (params) => getPermissionOption(params.row.permissions ? params.row.permissions : [], "Reports", agencyPermissions.REPORTS_NONE, reportPermissionOptions).label,
        flex: .4,
        sortComparator: permissionsSortComparator,
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Reports</Typography>),
        renderCell: (params) => (<Typography variant='subtitle1' className={classes.cell}>{params.value}</Typography>)
      },
      {
        field: 'createdByFullName', flex: .6,
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Invited By</Typography>),
        renderCell: (params) => (<Typography variant='subtitle1' className={classes.cell}>{params.value}</Typography>)
      },
      {
        field: 'createDate', flex: .4,
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Date</Typography>),
        renderCell: (params) => (<Typography variant='subtitle1' className={classes.cell}>{getFormattedDate(params.value)}</Typography>)
      },
      {
        field: 'resend',
        flex: 'auto',
        sortable: false,
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Resend</Typography>),
        renderCell: (params) => (
          <AdminResendInvite invite={params.row}/>
        )
      },
      {
        field: 'remove',
        flex: 'auto',
        sortable: false,
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Remove</Typography>),
        renderCell: (params) => (
          <AdminRemoveUser user={params.row} isActiveUser={false}/>
        )
      }       
    ];
  }

  const permissionsSortComparator = (v1, v2) => {
    return (v1.value).localeCompare(v2.value);
  }

  const SortedDescendingIcon = () => {
    return <KeyboardArrowDown style={{color: '#fff'}}/>;
  }

  const SortedAscendingIcon = () => {
    return <KeyboardArrowUp style={{color: '#fff'}}/>;
  }

  const handleSortChange = (model) => {
    if (JSON.stringify(sortModel[0]) !== JSON.stringify(model[0])){
      setSortModel(model)
    }
  }

  const searchAndFilterData = (searchValue, dataToFilter) => {
    let filtered = dataToFilter?.filter(item => {
        let fullName = item.firstName.toLowerCase() + item.lastName.toLowerCase();
        return (
            fullName.includes(searchValue.toLowerCase())
        )
        });
    return filtered;
  }


  useEffect(() => {
        let filtered = searchAndFilterData(searchValue, data);
        setInvitations(filtered);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  return (
    <div className={classes.table}>

      <Grid item xs={12}>
        <StyledDataGrid
          rows={invitations ? invitations : []}
          columns={getColumns()}
          hideFooterSelectedRowCount
          hideFooterPagination
          disableSelectionOnClick
          disableColumnMenu
          loading={isFetching}
          components={{
            ColumnSortedDescendingIcon: SortedDescendingIcon,
            ColumnSortedAscendingIcon: SortedAscendingIcon,
          }}
          sortModel={sortModel}
          onSortModelChange={handleSortChange}
        />
      </Grid>
      <Grid item container xs={12} justifyContent="flex-end">
        <Typography variant='subtitle1' className={classes.searchResult}>
          Search Results: {invitations ? invitations.length : 0}
        </Typography>          
      </Grid>

    </div>
  );
}
