import { unearnedBenefitOptions } from "./checkboxes/unearnedBenefitOptions"
import { proofOfCitizenshipOptions } from "./radios/proofOfCitizenshipOptions"
import { governmentIssuedPhotoIDOptions } from "./radios/governmentIssuedPhotoIDOptions"
import { otherFormOfIdentificationOptions } from "./checkboxes/otherFormOfIdentificationOptions"
import { Link } from "react-router-dom"
import { Typography } from "@mui/material"
import theme from "../../themes/theme"

const disconnectFileType = {
  value: 'disconnectNotice',
  label: 'Disconnect Notification',
  description: "Disconnect Notification"
}

const shutOffNoticeFileType = {
  value: 'shutOffNotice',
  label: 'Shutoff Notification',
  description: "Shutoff Notification"
}

const disabilityFileType = {
  value: 'disability',
  label: 'Proof of Disability',
  description: "Please upload a copy of :typeContext proof of disability."
}

const birthCertificateFileType = {
  value: 'birthCertificate',
  label: 'Birth Certificate',
  description: "Please upload a copy of :typeContext Birth Certificate."
}

const incomeFileType = {
  value: 'paystub',
  label: 'Paystub',
  description: "",
  employerName: "",
  employerId: ""
}

const electricityFileType = {
  value: 'electricity',
  label: 'Electricity Bill',
  description: "Please provide your most recent heating/cooling bill."
}

const naturalGasFileType = {
  value: 'naturalGas',
  label: 'Natural Gas',
  description: "Please provide your most recent natural gas bill."
}

const propaneFileType = {
  value: 'propane',
  label: 'Propane, Butane, or LPG Bill',
  description: "Please provide your most recent propane, butane, or LPG' bill."
}

const rentLeaseFileType = {
  value: 'rentLease',
  label: 'Lease',
  description: 'Please provide a copy of your lease that indicates utilities are included in your rent.'
}

const saveFormFileType = {
  value: 'saveForm',
  label: 'SAVE Form',
  description: 'You will need to fill out and upload the Save form and Release forms provided in the download below before submitting.',
  optional: false
}

const additionalFileDesc = () => {
  return(
    <Typography>
        {`If you have additional forms or documents that you need to provide, you can upload them here. Visit the `}
        <Link style={{color: theme.palette.primary.main}} to="/resources/TX">
          Applicant Resources page
        </Link> 
        {` to view all of the forms that are needed on a case-by-case basis. If you are unsure if this applies to you, don’t worry, your case worker will let you know if you are missing any part of your application.`}
    </Typography>

  )
}

const additionalFileType = {
  value: 'additional',
  label: 'Additional Forms and Documents',
  description: additionalFileDesc(),
  optional: true
}

export const getAdditionalFileType = () => {
  return [saveFormFileType, additionalFileType];
}

export const getRequiredAdditionalFileTypes = () => {
  return [saveFormFileType];
}

export const getGeneralFileTypes = (appData, type) => {
  let fileTypes = [];

  if(appData && appData['proofOfCitizenship'] && appData['proofOfCitizenship'] !== 'noneOfTheAbovePOC'){
    let selectedDocType = appData['proofOfCitizenship']
    let fileType = proofOfCitizenshipOptions.find(doc => doc.value === selectedDocType)
    addGeneralFileType(fileType, fileTypes, type);
  }
  else if(appData && appData['governmentIssuedPhotoID'] && appData['governmentIssuedPhotoID'] !== 'noneOfTheAboveGovId'){
    addGeneralFileType(birthCertificateFileType, fileTypes, type);
    let selectedDocType = appData['governmentIssuedPhotoID']
    let fileType = governmentIssuedPhotoIDOptions.find(doc => doc.value === selectedDocType)
    addGeneralFileType(fileType, fileTypes, type);
  }
  else if(appData && appData['otherFormOfIdentification'] && appData['otherFormOfIdentification'].length > 0){
    addGeneralFileType(birthCertificateFileType, fileTypes, type);
    appData['otherFormOfIdentification'].forEach(selectedDocType => {
      let fileType = otherFormOfIdentificationOptions.find(doc => doc.value === selectedDocType)
      addGeneralFileType(fileType, fileTypes, type);
    })
  }
  
  if(appData && appData['disabled'] === 'yes'){
    addGeneralFileType(disabilityFileType, fileTypes, type);
  }
  return fileTypes;
}

const addGeneralFileType = (generalFileType, fileTypes, type = 'applicant') => {
  let typeContext = "the applicant's";
  if(type !== 'applicant'){
    typeContext = "this household member's";
  }
  let fileType = Object.assign({}, generalFileType);
  fileType.description = fileType.description.replace(':typeContext', typeContext);
  fileTypes.push(fileType);
}

export const getUnearnedIncomeFileTypes = (appData) => {
  let fileTypes = [];
  const unearnedBenefits = appData?.unearnedBenefits;
  unearnedBenefits?.forEach((item) => {
    if(item !== "noOption"){
      let selection = unearnedBenefitOptions.find(obj =>  obj.value === item);
      if (selection && selection.documentationRequired){
        let displayLabel = selection.label;
        if(selection.value === 'other'){
          let otherUnearnedDetails = appData['otherUnearnedDetails'];
          if (otherUnearnedDetails){
            displayLabel = `${displayLabel} - ${otherUnearnedDetails}`
          }
        }      
        let fileType = {
          value: `unearned-${selection.value}`,
          label: displayLabel,
          description: `Supporting documentation for ${selection.label}`
        }
        fileTypes.push(fileType);
      }
    }
  })
  return fileTypes;
}

export const getIncomeFileTypes = (appData, employer) => {
  let fileTypes = [];
  if(appData && (appData['workStatus'] === 'fullTime' || appData['workStatus'] === 'partTime' || appData['workStatus'] === 'migrantSeasonalOrFarmWorker')){    
    let fileType = Object.assign({}, incomeFileType)
    fileType["employerId"] = employer.id;
    fileType["employerName"] = employer.name;
    fileTypes.push(fileType);  
  }
  return fileTypes;
}

export const getUtilityFileTypes = (appData) => {
  let fileTypes = [];
  
  if(appData?.primarySource === 'electricity' || appData?.secondarySources?.includes('electricity')){
    fileTypes.push(electricityFileType);
  }
  if(appData?.primarySource === 'naturalGas' || appData?.secondarySources?.includes('naturalGas')){
    fileTypes.push(naturalGasFileType);
  }
  if(appData?.primarySource === 'propane' || appData?.secondarySources?.includes('propane')){
    fileTypes.push(propaneFileType);
  }
  if(appData && appData['serviceShutOff'] === 'yes'){
    fileTypes.push(shutOffNoticeFileType);
  }
  if(appData && appData['disconnectNotice'] === 'yes'){
    fileTypes.push(disconnectFileType);
  }
  if(appData && appData['rentOrOwn'] === 'rent'){
    fileTypes.push(rentLeaseFileType);
  }
  return fileTypes;
}

export const getRequiredGenAndIncomeFileTypes = (appData) => {
  let requiredFileTypes = [];
  requiredFileTypes.push(...getGeneralFileTypes(appData));
  requiredFileTypes.push(...getUnearnedIncomeFileTypes(appData));
  appData?.employers?.forEach(employer => requiredFileTypes = requiredFileTypes.concat(getIncomeFileTypes(appData, employer)));
  return requiredFileTypes;
}

