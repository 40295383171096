  export const personalAndContantInfoFieldLabels = [
    {
      value: 'submittingAs',
      label: 'Submitting As'
    },
    {
      value: 'preparerName',
      label: 'Name of Preparer'
    },
    {
      value: 'firstName',
      label: 'First Name'
    },
    {
      value: 'lastName',
      label: 'Last Name'
    },
    {
      value: 'ssn',
      label: 'Social Security Number'
    },
    {
      value: 'birthDate',
      label: 'Birth Date'
    },
    {
      value: 'phoneNumber',
      label: 'Phone Number'
    }, 
    {
      value: 'email',
      label: 'Email'
    },
    {
      value: 'serviceStreetAddress',
      label: 'Service Street Address'
    },
    {
      value: 'serviceCity',
      label: 'Service City'
    },
    {
      value: 'serviceState',
      label: 'Service State'
    },
    {
      value: 'serviceZip',
      label: 'Service Zip'
    }, 
    {
      value: 'serviceCountyOrParish',
      label: 'Service County'
    },
    {
      value: 'mailingStreetAddress',
      label: 'Mailing Street Address'
    },
    {
      value: 'mailingCity',
      label: 'Mailing City'
    },
    {
      value: 'mailingState',
      label: 'Mailing State'
    },
    {
      value: 'mailingZip',
      label: 'Mailing Zip'
    }, 
    {
      value: 'mailingCountyOrParish',
      label: 'Mailing County'
    },
    {
      value: 'gender',
      label: 'Gender'
    },
    {
      value: 'race',
      label: 'Race'
    },
    {
      value: 'ethnicity',
      label: 'Ethnicity'
    },
    {
      value: 'disabled',
      label: 'Disability'
    },
    {
      value: 'veteranOrActivelyServing',
      label: 'Veteran Or Actively Serving'
    }
  ]

  export const applicantAdditionalInfoFieldLabels = [
    {
      value: 'healthInsurance',
      label: 'Health Insurance'
    },
    {
      value: 'education',
      label: 'Education'
    },
    {
      value: 'programApplyingFor',
      label: 'Program Applying For'
    }
  ]

  export const memberPersonalInfoFieldLabels = [
    {
      value: 'firstName',
      label: 'First Name'
    },
    {
      value: 'lastName',
      label: 'Last Name'
    },
    {
      value: 'ssn',
      label: 'Social Security Number'
    },
    {
      value: 'birthDate',
      label: 'Birth Date'
    },
    {
      value: 'relationToApplicant',
      label: 'Relation To Applicant'
    },
    {
      value: 'gender',
      label: 'Gender'
    },
    {
      value: 'race',
      label: 'Race'
    },
    {
      value: 'ethnicity',
      label: 'Ethnicity'
    },
    {
      value: 'disabled',
      label: 'Disability'
    },
    {
      value: 'veteranOrActivelyServing',
      label: 'Veteran Or Actively Serving'
    }
  ]

  export const memberAdditionalInfoFieldLabels = [
    {
      value: 'healthInsurance',
      label: 'Health Insurance'
    },
    {
      value: 'education',
      label: 'Education'
    }
  ]

  export const incomeFieldLabels = [
    {
      value: 'workStatus',
      label: 'Work Status'
    },
    {
      value: 'unearnedBenefits',
      label: 'Unearned Benefits'
    },
    {
      value: 'alimonyOrSpousalSupportPayFrequency',
      label: 'Alimony or other Spousal Support Pay Frequency'
    },
    {
      value: 'alimonyOrSpousalSupportMonthlyIncomeAmount',
      label: 'Alimony or other Spousal Support Monthly Income Amount'
    },
    {
      value: 'affordableCareActSubsidyPayFrequency',
      label: 'Affordable Care Act Subsidy Pay Frequency'
    },
    {
      value: 'affordableCareActSubsidyMonthlyIncomeAmount',
      label: 'Affordable Care Act Subsidy Monthly Income Amount'
    },
    {
      value: 'childSupportPayFrequency',
      label: 'Child Support Pay Frequency'
    },
    {
      value: 'childSupportMonthlyIncomeAmount',
      label: 'Child Support Monthly Income Amount'
    },
    {
      value: 'childcareVoucherPayFrequency',
      label: 'Childcare Voucher Pay Frequency'
    },
    {
      value: 'childcareVoucherMonthlyIncomeAmount',
      label: 'Childcare Voucher Monthly Income Amount'
    },
    {
      value: 'contributionsPayFrequency',
      label: 'Contributions Pay Frequency'
    },
    {
      value: 'contributionsMonthlyIncomeAmount',
      label: 'Contributions Monthly Income Amount'
    }, 
    {
      value: 'disabilityPensionPayFrequency',
      label: 'Disability Pension Pay Frequency'
    },
    {
      value: 'disabilityPensionMonthlyIncomeAmount',
      label: 'Disability Pension Monthly Income Amount'
    }, 
    {
      value: 'eitcPayFrequency',
      label: 'EITC Pay Frequency'
    },
    {
      value: 'eitcMonthlyIncomeAmount',
      label: 'EITC Monthly Income Amount'
    },
    {
      value: 'fosterCarePaymentsPayFrequency',
      label: 'Foster Care Payments Pay Frequency'
    },
    {
      value: 'fosterCarePaymentsMonthlyIncomeAmount',
      label: 'Foster Care Payments Monthly Income Amount'
    },
    {
      value: 'HousingChoiceVoucherPayFrequency',
      label: 'Housing Choice Voucher Pay Frequency'
    },
    {
      value: 'housingChoiceVoucherMonthlyIncomeAmount',
      label: 'Housing Choice Voucher Monthly Income Amount'
    },
    {
      value: 'hudVashPayFrequency',
      label: 'HUD-VASH Pay Frequency'
    }, 
    {
      value: 'hudVashMonthlyIncomeAmount',
      label: 'HUD-VASH Monthly Income Amount'
    },
    {
      value: 'interestAndDividensPayFrequency',
      label: 'Interest and Dividends Pay Frequency'
    },
    {
      value: 'interestAndDividensMonthlyIncomeAmount',
      label: 'Interest and Dividends Monthly Income Amount'
    },
    {
      value: 'partBHudUtilityPaymentsGreaterEqualToFiftyDollarsPayFrequency',
      label: 'Part B premium and HUD Utility Payments Pay Frequency'
    }, 
    {
      value: 'partBHudUtilityPaymentsGreaterEqualToFiftyDollarsMonthlyIncomeAmount',
      label: 'Part B premium and HUD Utility Payments Monthly Income Amount'
    },
    {
      value: 'pensionsAndAnnuitiesPayFrequency',
      label: 'Pensions and Annuities Pay Frequency'
    },
    {
      value: 'pensionsAndAnnuitiesMonthlyIncomeAmount',
      label: 'Pensions and Annuities Monthly Income Amount'
    },
    {
      value: 'permanentSupportHousingPayFrequency',
      label: 'Permanent Support Housing Pay Frequency'
    },
    {
      value: 'permanentSupportHousingMonthlyIncomeAmount',
      label: 'Permanent Support Housing Monthly Income Amount'
    },
    {
      value: 'privateDisabilityBenefitsPayFrequency',
      label: 'Private Disability Benefits Pay Frequency'
    },
    {
      value: 'privateDisabilityBenefitsMonthlyIncomeAmount',
      label: 'Private Disability Benefits Monthly Income Amount'
    },
    {
      value: 'publicHousingPayFrequency',
      label: 'Public Housing Pay Frequency'
    },
    {
      value: 'publicHousingMonthlyIncomeAmount',
      label: 'Public Housing Monthly Income Amount'
    },
    {
      value: 'snapBenefitsPayFrequency',
      label: 'SNAP Benefits Pay Frequency'
    },
    {
      value: 'snapBenefitsMonthlyIncomeAmount',
      label: 'SNAP Benefits Monthly Income Amount'
    },
    {
      value: 'socialSecurityPayFrequency',
      label: 'Retirement Income from Social Security Pay Frequency'
    },
    {
      value: 'socialSecurityMonthlyIncomeAmount',
      label: 'Retirement Income from Social Security Monthly Income Amount'
    }, 
    {
      value: 'retirementAndVeteranBenefitsPayFrequency',
      label: 'Retirement and Veteran Benefits Pay Frequency'
    },
    {
      value: 'retirementAndVeteranBenefitsMonthlyIncomeAmount',
      label: 'Retirement and Veteran Benefits Monthly Income Amount'
    },
    {
      value: 'ssdiPayFrequency',
      label: 'Social Security Disability Income (SSDI) Pay Frequency'
    },
    {
      value: 'ssdiMonthlyIncomeAmount',
      label: 'Social Security Disability Income (SSDI) Monthly Income Amount'
    },
    {
      value: 'ssiPayFrequency',
      label: 'Supplemental Security Income (SSI) Pay Frequency'
    }, 
    {
      value: 'ssiMonthlyIncomeAmount',
      label: 'Supplemental Security Income (SSI) Monthly Income Amount'
    },
    {
      value: 'tanfPayFrequency',
      label: 'Temporary Assistance for Needy Families (TANF) Pay Frequency'
    },
    {
      value: 'tanfMonthlyIncomeAmount',
      label: 'Temporary Assistance for Needy Families (TANF) Monthly Income Amount'
    },
    {
      value: 'teaPayFrequency',
      label: 'Transitional Employment Assistance (TEA) Pay Frequency'
    },
    {
      value: 'teaMonthlyIncomeAmount',
      label: 'Transitional Employment Assistance (TEA) Monthly Income Amount'
    },
    {
      value: 'unemploymentInsurancePayFrequency',
      label: 'Unemployment Insurance Pay Frequency'
    },
    {
      value: 'unemploymentInsuranceMonthlyIncomeAmount',
      label: 'Unemployment Insurance Monthly Income Amount'
    },
    {
      value: 'vaNonServiceConnectedPayFrequency',
      label: 'VA — Non-Service Connected Pay Frequency'
    },
    {
      value: 'vaNonServiceConnectedMonthlyIncomeAmount',
      label: 'VA — Non-Service Connected Monthly Income Amount'
    },
    {
      value: 'vaConnectedDisabilityBenefitsPayFrequency',
      label: 'VA — Connected Disability Benefits Pay Frequency'
    },
    {
      value: 'vaConnectedDisabilityBenefitsMonthlyIncomeAmount',
      label: 'VA — Connected Disability Benefits Monthly Income Amount'
    },
    {
      value: 'wicPayFrequency',
      label: 'WIC Pay Frequency'
    },
    {
      value: 'wicMonthlyIncomeAmount',
      label: 'WIC Monthly Income Amount'
    },
    {
      value: 'workersCompPayFrequency',
      label: 'Workers Compensation Pay Frequency'
    },
    {
      value: 'workersCompMonthlyIncomeAmount',
      label: 'Workers Compensation Monthly Income Amount'
    },
    {
      value: 'otherPayFrequency',
      label: 'Other Pay Frequency'
    },
    {
      value: 'otherMonthlyIncomeAmount',
      label: 'Other Monthly Income Amount'
    },
    {
      value: 'otherUnearnedDetails',
      label: 'Other Unearned Details'
    }
  ]
  export const employerFieldLabels = [
    {
      value: 'employers',
      label: 'Employers'
    },
    {
      value: 'name',
      label: 'Name'
    },
    {
      value: 'payFrequency',
      label: 'Pay Frequency'
    },
    {
      value: 'monthlyIncomeAmount',
      label: 'Monthly Income Amount'
    },
    {
      value: 'hasBeenEmployed',
      label: 'Has Been Employed'
    },
    {
      value: 'lastEmployedDate',
      label: 'Last Employed Date'
    },
    {
      value: 'lastEmployerName',
      label: 'Last Employer Name'
    }
  ]

  export const documentInfoFieldLabels = [
    {
      value: 'proofOfCitizenship',
      label: 'Proof of Citizenship'
    },
    {
      value: 'governmentIssuedPhotoID',
      label: 'Government Issued Photo ID'
    },
    {
      value: 'otherFormOfIdentification',
      label: 'Other Form of Identification'
    }
  ]

  export const utilityFieldLabels = [
    {
      value: 'rentOrOwn',
      label: 'Rent or Own'
    },
    {
      value: 'rentEnergyCostIncluded',
      label: 'Energy Cost Included in Rent'
    },
    {
      value: 'rentLandlordName',
      label: 'Landlord Name'
    },
    {
      value: 'rentLandlordPhoneNo',
      label: 'Landlord Phone Number'
    },
    {
      value: 'rentLandlordStreetAddress',
      label: 'Landlord Street Address'
    },
    {
      value: 'rentLandlordApartmentNumber',
      label: 'Landlord Apartment Number'
    },
    {
      value: 'rentLandlordCity',
      label: 'Landlord City'
    },
    {
      value: 'rentLandlordState',
      label: 'Landlord State'
    },
    {
      value: 'rentLandlordZip',
      label: 'Landlord Zip'
    },
    {
      value: 'rentLandlordCountyOrParish',
      label: 'Landlord County'
    },
    {
      value: 'residenceAllElectric',
      label: 'Residence All Electric'
    },
    {
      value: 'electricityProvider',
      label: 'Electricity Provider'
    },
    {
      value: 'otherElectricityProvider',
      label: 'Other Electricity Provider'
    },
    {
      value: 'electricityAcctNo',
      label: 'Electricity Account Number'
    },
    {
      value: 'electricityAccountClosed',
      label: 'Electricity Account Closed'
    },
    {
      value: 'electricityInApplicantName',
      label: 'Electricity In Applicant Name'
    },
    {
      value: 'electricityAccountOwnerName',
      label: 'Electricity Account Owner Name'
    },
    {
      value: 'electricityAccountOwnerLiveWithApplicant',
      label: 'Electricity Account Owner Lives With Applicant'
    },
    {
      value: 'electricityAccountOwnerRelationToApplicant',
      label: 'Electricity Account Owner Relation To Applicant'
    },
    {
      value: 'primarySource',
      label: 'Primary Heat Source'
    },
    {
      value: 'primarySourceAccountClosed',
      label: 'Primary Heat Source Account Closed'
    },
    {
      value: 'primarySourceInApplicantName',
      label: 'Primary Heat Source In Applicant Name'
    },
    {
      value: 'primarySourceAccountOwnerName',
      label: 'Primary Heat Source Account Owner Name'
    },
    {
      value: 'primarySourceAccountOwnerLiveWithApplicant',
      label: 'Primary Heat Source Account Owner Lives With Applicant'
    },
    {
      value: 'primarySourceAccountOwnerRelationToApplicant',
      label: 'Primary Heat Source Account Owner Relation To Applicant'
    },
    {
      value: 'secondarySources',
      label: 'Secondary Heat Sources'
    },
    {
      value: 'naturalGasProvider',
      label: 'Natural Gas Provider'
    },
    {
      value: 'naturalGasAcctNo',
      label: 'Natural Gas Account Number'
    },
    {
      value: 'naturalGasAccountClosed',
      label: 'Natural Gas Account Closed'
    },
    {
      value: 'naturalGasInApplicantName',
      label: 'Natural Gas In Applicant Name'
    },
    {
      value: 'naturalGasAccountOwnerName',
      label: 'Natural Gas Account Owner Name'
    },
    {
      value: 'naturalGasAccountOwnerLiveWithApplicant',
      label: 'Natural Gas Account Owner Lives With Applicant'
    },
    {
      value: 'naturalGasAccountOwnerRelationToApplicant',
      label: 'Natural Gas Account Owner Relation To Applicant'
    },
    {
      value: 'propaneProvider',
      label: 'Propane Provider'
    },
    {
      value: 'propaneAcctNo',
      label: 'Propane Account Number'
    },
    {
      value: 'propaneAccountClosed',
      label: 'Propane Account Closed'
    },
    {
      value: 'propaneInApplicantName',
      label: 'Propane In Applicant Name'
    },
    {
      value: 'propaneAccountOwnerName',
      label: 'Propane Account Owner Name'
    },
    {
      value: 'propaneAccountOwnerLiveWithApplicant',
      label: 'Propane Account Owner Lives With Applicant'
    },
    {
      value: 'propaneAccountOwnerRelationToApplicant',
      label: 'Propane Account Owner Relation To Applicant'
    },
    {
      value: 'otherHeatSource',
      label: 'Other Heat Source'
    },
    {
      value: 'otherAccountClosed',
      label: 'Other Account Closed'
    },
    {
      value: 'otherInApplicantName',
      label: 'Other In Applicant Name'
    },
    {
      value: 'otherAccountOwnerName',
      label: 'Other Account Owner Name'
    },
    {
      value: 'otherAccountOwnerLiveWithApplicant',
      label: 'Other Account Owner Lives With Applicant'
    },
    {
      value: 'otherAccountOwnerRelationToApplicant',
      label: 'Other Account Owner Relation To Applicant'
    },
    {
      value: 'weatherProofingOrEfficiency',
      label: 'Is your home in need of weather-proofing or energy efficiency services?'
    }
  ]

  export const utilityHousingFieldLabels = [
    {
      value: 'rentHousingType',
      label: 'Housing Type'
    },
    {
      value: 'ownHousingType',
      label: 'Housing Type'
    },
    {
      value: 'monthlyRentOrMortgage',
      label: 'Monthly Rent Or Mortgage'
    },
    {
      value: 'needForAssistanceExplaination',
      label: 'Please explain what has happened in the past 30 days that has caused you to seek our assistance and/or a reduction in income'
    },
    {
      value: 'affectedByCovid',
      label: 'Have you been affected by Covid?'
    },
    {
      value: 'covidExplaination',
      label: 'Affected By Covid Explanation'
    }
  ]

  export const utilityWeatherProofingFieldLabels = [
    {
      value: 'weatherProofingPrevAsst',
      label: 'Has this residence ever recieved services from the Weatherization Assistance Program?'
    },
    {
      value: 'weatherProofingPrevAsstDate',
      label: 'If yes, when?'
    },
    {
      value: 'yearHomeWasBuilt',
      label: 'What year was this home built?'
    },
    {
      value: 'airConditionerType',
      label: 'What type of air conditioner do you use?'
    },
    {
      value: 'heaterType',
      label: 'What type of heater do you use?'
    },
    {
      value: 'gasSpaceHeaterCount',
      label: 'How many gas space heaters do you use'
    },
    {
      value: 'electricHeaterCount',
      label: 'How many electric heaters do you use'
    },
    {
      value: 'roofLeaking',
      label: 'Is your roof leaking?'
    },
    {
      value: 'roofLeakingTimeLength',
      label: 'How long has it been leaking?'
    },
    {
      value: 'roofLeakingRoomCount',
      label: 'In how many rooms is it leaking?'
    },
    {
      value: 'holesInFloor',
      label: 'Are there holes in your floors?'
    },
    {
      value: 'goodFoundation',
      label: 'Does your home have a good foundation?'
    }
  ]

  export const utilitySituationFieldLabels = [
    {
      value: 'serviceShutOff',
      label: 'Service Shut Off Due to an Unpaid Bill'
    }, 
    {
      value: 'disconnectNotice',
      label: 'Disconnection Within 7 Days'
    },
    {
      value: 'utilityAssistanceNeeded',
      label: 'Utility Assistance Needed'
    },
    {
      value: 'crisisLifeThreatening',
      label: 'Crisis Life Threatening'
    },
    {
      value: 'crisisExplaination',
      label: 'Crisis Explanation'
    }, 
    {
      value: 'crisisSituations',
      label: 'Crisis Situations'
    },
    {
      value: 'overdueBalance',
      label: 'Overdue Balance'
    }, 
    {
      value: 'overdueBalanceValue',
      label: 'Overdue Balance Value'
    }
  ]

  export const financialResourcesFieldLabels = [
    {
      value: 'cashOnHand',
      label: 'Cash On Hand'
    },
    {
      value: 'cashOnHandAmount',
      label: 'Cash On Hand Amount'
    },
    {
      value: 'cashOnHandLocation',
      label: 'Cash On Hand Location'
    },
    {
      value: 'checkingAccount',
      label: 'Checking Account'
    },
    {
      value: 'checkingAccountBankName',
      label: 'Checking Account Bank Name'
    },
    {
      value: 'checkingAccountAmount',
      label: 'Checking Account Amount'
    },
    {
      value: 'otherBankAccount',
      label: 'Other Bank Account'
    },
    {
      value: 'otherBankAccountBankName',
      label: 'Other Bank Account Bank Name'
    },
    {
      value: 'otherBankAccountAmount',
      label: 'Other Bank Account Amount'
    },
    {
      value: 'certificateOfDeposit',
      label: 'Certificate Of Deposit'
    },
    {
      value: 'certificateOfDepositLocation',
      label: 'Certificate Of Deposit Location'
    },
    {
      value: 'certificateOfDepositAmount',
      label: 'Certificate Of Deposit Amount'
    },
    {
      value: 'otherFinancialResources',
      label: 'Other Financial Resources'
    },
    {
      value: 'otherFinancialResourcesLocation',
      label: 'Other Financial Resources Location'
    },
    {
      value: 'otherFinancialResourcesAmount',
      label: 'Other Financial Resources Amount'
    },
    {
      value: '',
      label: ''
    },
    {
      value: '',
      label: ''
    }
]

 