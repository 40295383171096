import { Button, IconButton, Link, Typography, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react'
import { FileUploadOutlined, DeleteOutlined } from '@mui/icons-material'
import { truncateForDisplay } from '../../../utils/utilFunctions';
import ConfirmationDialog from '../../ConfirmationDialog';

const useStyles = makeStyles((theme) => ({
  hidden: {
    display: "none"
  },
  docLink:{
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  endAlignemnt:{
    textAlign: 'end'
  },
  upload: {
    textTransform: "unset !important",
    fontWeight: "bold",
    marginBottom: '1rem'
  },
  error: {
    color: theme.customColors.errorText,
    fontSize: '.75rem'
  }
}));

export default function FormUpload({ changeHandler, register, errors }) {
  const classes = useStyles();

  const [file, setFile] = useState()
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const openFile = () => {
    let objectURL = URL.createObjectURL(file);
    window.open(objectURL, '_blank');
  }

  const fileUpload = (e) => {
    setFile(e.target.files[0])
    changeHandler(e.target.files[0]);
  }
  
  const fileDelete = () => {
    setFile('')
    changeHandler('')
    setDeleteConfirmationOpen(false)
  }

  const fileNameDisplay = (file) => {
    let displayFileName = file?.name ? file.name :file.fileName;
    displayFileName = truncateForDisplay(displayFileName, 20);
    return displayFileName;
  }

  const renderUploadButtons = (file) => {
    if(!file) {
      const fileInputId = 'stateForm'
      return (
        <Grid container item className={classes.endAlignemnt}>
          <Grid item xs={12} >
            <input className={classes.hidden}
              id={fileInputId}
              ref={register}
              type="file" 
              accept=".pdf" 
              name="file"
              label="Select A File" 
              onChange={(e) => { fileUpload(e); }} 
            />  
            <label htmlFor={fileInputId}>
              <Button variant="contained" color="primary" component="span" size='large' className={classes.upload} startIcon={<FileUploadOutlined color='#fff' />}>
                Add a File
              </Button>
            </label>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='subtitle2' color="textSecondary">Accepted Formats: PDF</Typography>
          </Grid>
          {errors?.file &&
            <Grid item xs={12}>
              <Typography className={classes.error}>{errors?.file?.message}</Typography>
            </Grid>
          }
        </Grid>
      )
    }
    else {
      return (
        <>
        <Grid container item className={classes.endAlignemnt}>
          <Grid item xs={12} >
            <Button component={Link} id="link" onClick={() => openFile()} download variant="outlined" target="__new" className={classes.docLink}>
              {fileNameDisplay(file)}
            </Button>
            <IconButton
              aria-label="delete"
              onClick={() => setDeleteConfirmationOpen(true)}
              size="large"
              >
                <DeleteOutlined color='primary'/>
            </IconButton>
          </Grid>
          <Grid item xs={12} >
            <Typography variant='subtitle2' color="textSecondary">Accepted Formats: PDF</Typography>
          </Grid>
        </Grid>
        
        <ConfirmationDialog 
          open={deleteConfirmationOpen}
          title="Remove File"
          desc={`Are you sure you want to remove this file?`}
          handleCancel={() => setDeleteConfirmationOpen(false)}
          handleOk={() => fileDelete()}
          okLabel="Okay"
        />
        </>
      );
    }
  }

  return (
    renderUploadButtons(file) 
  )
}
