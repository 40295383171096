import React, { useContext } from 'react'
import {
  Link,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory, useParams } from 'react-router';
import { statuses } from '../../constants/appStatuses';
import { ArrowBackIos, ErrorOutline, CheckCircleOutline, Circle } from '@mui/icons-material';
import { AuthContext } from '../../context/authContext';

const useStyles = makeStyles((theme) => ({
  stepHeader: {
    margin: '0rem 1.5rem',
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontWeight: 500,
    fontSize: '1rem',
    padding: '1rem 0',
    cursor: "pointer",
  },
  backArrow: {
    width: '1rem',
    height: '1rem'
  },
  stepper: {
    marginTop: ".6rem",
    marginLeft: "2.5rem",
    [theme.breakpoints.down('sm')]: {
        margin: ".5rem",
      },
    "& > .MuiStep-root" : {
      padding: 0
    },
    "& > .MuiStepConnector-root": {
      padding: '0 .75rem',
      [theme.breakpoints.down('sm')]: {
        padding: 0
      },
    }
  },
  stepperLink: {
    color: "inherit",
    cursor: "pointer",
    textDecoration: 'none',
    '& > .MuiStepLabel-iconContainer': {
      padding: 0
    }
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: 0
  },
  icon: {
    transform: 'scale(1.2)',
    color: theme.palette.primary.main, 
    fontSize: '1.5rem',
    display: 'block',
    padding: '.75rem'
  },
  unvisited: {
    color: theme.customColors.grey
  },
  iconText: {
    color: '#fff', 
    fontSize: '.9rem',
    position: 'absolute',
    padding: '.75rem'
  },

}));

export default function SidePanelStepper({ data, currStep, fileData }) {
  const { id: appId } = useParams();

  const { userInfo } = useContext(AuthContext);
  const stateConfig = userInfo?.stateInfo;
  
  const getRequiredGenAndIncomeFileTypes = stateConfig.fileUtils.getGenFileUtil;
  const getUtilityFileTypes = stateConfig.fileUtils.getUtilityFileUtil;
  const getRequiredAdditionalFileTypes = stateConfig.fileUtils.getRequiredAdditionalFileTypes;
  const validateDocuments = stateConfig.fileUtils.getValidateFileUtil;

  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const matchesXsDown = useMediaQuery(theme.breakpoints.down('sm'));

  const showLink = (location) => {
    return location !== "submit" && (data?.status === statuses.NEW || data?.status === statuses.RETURNED)
  }
  
 const documentQueries = fileData;

  const validateSteps = (index, data) => {
    let isValid = true;
    let steps = stateConfig.processSteps.filter(x => x.stepIndex === index);
    steps.forEach(step => {
      if(step.stepValidationSchema){
        try{
          step.stepValidationSchema.validateSync(data, {abortEarly: false})
        }
      catch (error){
        isValid = false
        }
      }
    })
    return isValid;
  }

  const validateSection = (stepIndex) => {
    let isValid = true;
    switch(stepIndex) {
      case 0:
        let applicantRequiredFiles = getRequiredGenAndIncomeFileTypes(data);
        if(validateSteps(stepIndex, data) === false || validateDocuments(applicantRequiredFiles, documentQueries) === false){
          isValid = false
        }
        return isValid
      case 1:
        if(!data.householdMembers || data.householdMembers.length === 0)
          return isValid;
        else {
          for (const member of data.householdMembers) {
            const memberRequiredFiles = getRequiredGenAndIncomeFileTypes(member);
            if(validateSteps(stepIndex, member) === false || validateDocuments(memberRequiredFiles, documentQueries, member.id) === false){
              isValid = false
            }
          }
          return isValid;
        }
      case 2:
        let utilityRequiredFiles = getUtilityFileTypes(data)
        if(validateSteps(stepIndex, data) === false ||  validateDocuments(utilityRequiredFiles, documentQueries) === false){
          isValid = false
        }
        return isValid
      case 3:
        let addtionalRequiredFiles = getRequiredAdditionalFileTypes()
        if(validateDocuments(addtionalRequiredFiles, documentQueries) === false){
          isValid = false
        }
        return isValid;
      case 4:
          return isValid;
      default:
        return isValid;
    }  
  }

  const getStepIcon = (currStep, stepLabel) => {
    let step = stateConfig.processSteps.find((item) => { return (item.location === stepLabel.location); });
    //if current step
    if(currStep.stepIndex === step.stepIndex){
      return (
        <div className={classes.iconContainer}>
          <Circle className={classes.icon}/> 
            <Typography className={classes.iconText}>
              {step.stepIndex + 1}
              </Typography>
          </div>
        )
    }

    //if step is less than current step or if step has been edited before, validate 
    else if(currStep.stepIndex > step.stepIndex || data?.highestStepEdited >= step.stepIndex){
      if(validateSection(step.stepIndex)){
        return (
            <div className={classes.iconContainer}>
                <CheckCircleOutline className={classes.icon}/>
            </div>
          )
        }
      else {
        return (
          <div className={classes.iconContainer}>
            <ErrorOutline className={classes.icon}/>
          </div>
        )
      }
    } 

    //if step has never been edited
    else{
      return (
        <div className={classes.iconContainer}>
          <Circle className={`${classes.icon} ${classes.unvisited}`} /> 
          <Typography className={classes.iconText}>
            {step.stepIndex + 1}
          </Typography>
        </div>
      )
    } 
  }
  
  return(
    <>
      <Link onClick={() => history.push(`/dashboard`)} className={classes.stepHeader}>
          <ArrowBackIos className={classes.backArrow}/>
          Application Status
      </Link>
      <Stepper className={classes.stepper} activeStep={currStep.stepIndex} orientation={matchesXsDown ? "horizontal" :"vertical"} >
        {stateConfig.processStepLabels.map((stepLabel) => (
          <Step key={stepLabel.value}>
              <StepLabel
                className={classes.stepperLink}
                StepIconComponent={() => getStepIcon(currStep, stepLabel)}
                onClick={showLink(stepLabel.location) 
                  ? () => history.push(`${stepLabel.link}/${appId}`) 
                  : () => false}
              >
                {!matchesXsDown &&
                  <>
                  {showLink(stepLabel.location)
                    ?
                      <Link
                        onClick={() => history.push(`${stepLabel.link}/${appId}`)} 
                        className={classes.stepperLink}>
                          {stepLabel.value}
                      </Link>
                    :
                      stepLabel.value
                  }
                  </>
                }
              </StepLabel>
          </Step>
        ))}
      </Stepper>
    </>
  );
  
}
