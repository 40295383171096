
import React, { useState } from 'react';
import { Grid, Typography, IconButton, Toolbar, AppBar, } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AdminTable from '../components/agency/pages/AdminTable';
import { ArrowBackRounded, PermContactCalendarOutlined } from '@mui/icons-material'
import { useHistory } from 'react-router';
import FeatureFlag from '../components/FeatureFlag';
import AdminInvite from '../components/agency/pages/AdminInvite';

const useStyles = makeStyles((theme) => ({
  sticky: {
    position: 'sticky',
    top: 0,
    zIndex: 99,
  },
  toolbar: {
    color: '#000',
    background: 'linear-gradient(0deg, rgba(124, 172, 248, 0.08), rgba(124, 172, 248, 0.08)), linear-gradient(0deg, rgba(117, 117, 117, 0.02), rgba(117, 117, 117, 0.02)), #FFFFFF',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
    padding: '0 2rem',
    marginLeft: '4rem',
  },
  actions: {
    display: "flex",
    paddingRight: '5rem',
    marginLeft: 'auto'
  },
  tabSection: {
    marginLeft: '2rem',
    display: 'flex'
  },
  tab: {
    margin: '0 .5rem',
    display: 'initial',
  },
  selectedTab: {
    color: "#3576CB",
    margin: '0 .5rem',
    display: 'initial',
  },
  tabLabel: {
    fontSize: '.75rem',
  },
  mainContainer: {
    marginLeft: '4rem',
    padding: "2rem 4rem",
    width: "95%"
  },
}));

export default function AgencyAdmin() {
  const classes = useStyles();

  const [showInvite, setShowInvite] = useState(false);
  const history = useHistory();

  const handleBackClick = () => {
    setShowInvite((current) => {
      if(current){
        return false
      }
      else{
        history.push(`/agency/home`)
      }
    })
  }

  return (
    <>
  <Grid className={classes.sticky}>
    <AppBar className={classes.toolbar} position="static">
      <Toolbar variant="dense">
        <Grid item className={classes.header}>
          <IconButton onClick={handleBackClick} edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
            <ArrowBackRounded/>
          </IconButton>
        </Grid>
        <Grid item className={classes.actions}>
          <Grid className={classes.tabSection}>
          <FeatureFlag feature="CreateInvitation" hideOnDisabled={true}>
            <IconButton className={showInvite ? classes.selectedTab : classes.tab} onClick={() => setShowInvite(true)}>
              <PermContactCalendarOutlined/>
              <Typography className={classes.tabLabel}>
                Invite User
              </Typography>
            </IconButton>
          </FeatureFlag>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  </Grid>
    
    <Grid xs={12} item container justifyContent="flex-start" spacing={1} direction="column" className={classes.mainContainer}>
      {showInvite ?
        <AdminInvite/>
        :
        <AdminTable />
      }
    </Grid>
    </>
  );
}
