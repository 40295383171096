import { useQuery } from "react-query";
import { getAssignableAgencyUsers } from "../api/AgencyAPI";
import { getUserOptions } from "../utils/utilFunctions";

export default function useAssignableAgencyUsers() {

  return useQuery(['assignableUsers'], async () => {
    const { data } = await getAssignableAgencyUsers();
    const userOptions = getUserOptions(data, "Unassigned", "Unassigned");
    return userOptions;
  }, {enabled: true});
}
