export const unearnedBenefitOptions = [
  {
    value: 'alimonyOrSpousalSupport',
    label: 'Alimony or other Spousal Support',
    additonalInfoRequired: true,
    documentationRequired: true
  },
  {
    value: 'affordableCareActSubsidy',
    label: 'Affordable Care Act Subsidy',
    additonalInfoRequired: false,
    documentationRequired: false
  },
  {
    value: 'childSupport',
    label: 'Child Support',
    additonalInfoRequired: false,
    documentationRequired: true
  },
  {
    value: 'childcareVoucher',
    label: 'Childcare Voucher',
    additonalInfoRequired: false,
    documentationRequired: false
  },
  {
    value: 'disabilityPension',
    label: 'Disability Pension',
    additonalInfoRequired: true,
    documentationRequired: true
  },
  {
    value: 'eitc',
    label: 'EITC',
    additonalInfoRequired: false,
    documentationRequired: false
  },
  {
    value: 'housingChoiceVoucher',
    label: 'Housing Choice Voucher',
    additonalInfoRequired: false,
    documentationRequired: true
  },
  {
    value: 'hudVash',
    label: 'HUD-VASH',
    additonalInfoRequired: false,
    documentationRequired: true
  },
  {
    value: 'pensionsAndAnnuities',
    label: 'Pension & Annuities',
    additonalInfoRequired: true,
    documentationRequired: true
  },
  {
    value: 'permanentSupportHousing',
    label: 'Permanent Support Housing',
    additonalInfoRequired: false,
    documentationRequired: false
  },
  {
    value: 'privateDisabilityBenefits',
    label: 'Private Disability Benefits',
    additonalInfoRequired: true,
    documentationRequired: true
  },
  {
    value: 'publicHousing',
    label: 'Public Housing',
    additonalInfoRequired: true,
    documentationRequired: true
  },
  {
    value: 'snapBenefits',
    label: 'SNAP Benefits',
    additonalInfoRequired: false,
    documentationRequired: true
  },
  {
    value: 'socialSecurity',
    label: 'Retirement Income from Social Security',
    additonalInfoRequired: true,
    documentationRequired: true
  },
  {
    value: 'ssdi',
    label: 'Social Security Disability Income (SSDI)',
    additonalInfoRequired: true,
    documentationRequired: true
  },
  {
    value: 'ssi',
    label: 'Supplemental Security Income (SSI)',
    additonalInfoRequired: true,
    documentationRequired: true
  },
  {
    value: 'tanf',
    label: 'Temporary Assistance for Needy Families (TANF)',
    additonalInfoRequired: true,
    documentationRequired: true
  },
  {
    value: 'unemploymentInsurance',
    label: 'Unemployment Insurance',
    additonalInfoRequired: true,
    documentationRequired: true
  },
  {
    value: 'vaNonServiceConnected',
    label: 'VA — Non-Service Connected',
    additonalInfoRequired: true,
    documentationRequired: true
  },
  {
    value: 'vaConnectedDisabilityBenefits',
    label: 'VA — Connected Disability Benefits',
    additonalInfoRequired: false,
    documentationRequired: true
  },
  {
    value: 'wic',
    label: 'WIC',
    additonalInfoRequired: false,
    documentationRequired: false
  },
  {
    value: 'workersComp',
    label: 'Worker\'s Compensation',
    additonalInfoRequired: true,
    documentationRequired: true
  },
  {
    value: 'other',
    label: 'Other',
    additonalInfoRequired: true,
    documentationRequired: true
  }
]

