import { Container, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState, useContext, useEffect } from 'react'
import FormTextField from '../../../form/FormTextField'
import FormCheckboxes from '../../../form/FormCheckboxes'
import StepButtons from '../../../StepButtons'
import StepHeader from '../../../StepHeader'
import FormSection from '../../../FormSection'
import FormRadioAsButtons from '../../../form/FormRadioAsButtons'
import { heatOptions } from '../../../../constants/tx/checkboxes/heatOptions'
import { rentOwnOptions } from '../../../../constants/radios/rentOwnOptions'
import { yesNoOptions } from '../../../../constants/radios/yesNoOptions'
import { relationToApplicantOptions } from '../../../../constants/dropdowns/relationToApplicantOptions'
import FormSelect from '../../../form/FormSelect'
import useLiheapForm from '../../../../hooks/useLiheapForm'
import { stateOptions } from '../../../../constants/dropdowns/stateOptions';
import { ConfigContext } from "../../../../context/configContext";
import { AuthContext } from '../../../../context/authContext';
import FeatureFlag from '../../../FeatureFlag';
import { electricityProviderOptions, electricityProviderEntergyOptions } from '../../../../constants/radios/electricityProviderOptions'
import { isFeatureDisabled } from '../../../../constants/features';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    marginLeft: "0"
  },
  label: {
    fontWeight: "bold", 
    marginBottom: ".6rem",
  },
  section: {
    marginTop: '1.5rem'
  },
  inputFields: {
    maxWidth: "21rem",
    backgroundColor: "#fff",
    "& > .MuiFormHelperText-root.Mui-error": { 
      backgroundColor: theme.customColors.veryLightGray,
      margin:0
    }
  }
}));

const Electricity = ({control, errors, register, electricityProviderWatch, onSelect}) => {
  const classes = useStyles();
  const { clientConfig } = useContext(ConfigContext);
  const { userInfo } = useContext(AuthContext);
  
  const providers = userInfo?.agencyInfo?.providers ? userInfo?.agencyInfo?.providers.filter(prov => prov !== 'Entergy Services') : []
  const agencyId = userInfo?.agencyId;
  const [availableOptions, setAvailableOptions] = useState([]);

  useEffect(() => {
    let isDisabled = true
    if (clientConfig && clientConfig["DisabledFeatures"]){
      isDisabled = isFeatureDisabled(clientConfig, agencyId, "OtherElectricProviders")
      setAvailableOptions(isDisabled ? electricityProviderEntergyOptions : electricityProviderOptions)
    }
    }, [agencyId, clientConfig]);

  return (
    <>
      <Grid item xs={12} container className={classes.section}>
        <FormRadioAsButtons
          control={control}
          name="electricityProvider"
          label="Please select your Electricity provider."
          errors={errors}
          options={availableOptions}
          onSelect={onSelect}
        />  
        <FeatureFlag feature="OtherElectricProviders" hideOnDisabled={true} >
        { electricityProviderWatch === 'other' && 
        <Grid item xs={12}>
          <FormSelect
            className={classes.inputFields}
            name="otherElectricityProvider"
            label="Electricity provider"
            labelId="otherElectricityProvider-label"
            labelPlacement="inside"
            control={control}
            errors={errors}
            options={providers}
            width="21rem"
          />       
        </Grid>
        }
        </FeatureFlag>
        <Grid item xs={12}>
          <Typography variant="subtitle1" className={classes.label}>Please enter your account number here.</Typography>
        </Grid>
        <FormTextField className={classes.inputFields} name="electricityAcctNo" label="Account Number" register={register} errors={errors}/>
      </Grid>
    </>
  );
}

const Other = ({errors, register}) => {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} container className={classes.section}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" className={classes.label}>Please enter the other way you heat your home.</Typography>
        </Grid>
        <FormTextField className={classes.inputFields} name="otherHeatSource" label="Other Heat Source" register={register} errors={errors}/>
      </Grid>
    </>
  );
}

const ProviderDetails = ({register, errors, accountType, accountTypeLabel}) => {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} container className={classes.section}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" className={classes.label}>{`Who is your ${accountTypeLabel} provider?`}</Typography>
        </Grid>  
        <FormTextField className={classes.inputFields} name={`${accountType}Provider`} label="Provider Name" register={register} errors={errors}/>
        <Grid item xs={12}>
          <Typography variant="subtitle1" className={classes.label}>Please enter your account number here.</Typography>
        </Grid>  
        <FormTextField className={classes.inputFields} name={`${accountType}AcctNo`} label="Account Number" register={register} errors={errors}/>
      </Grid>
    </>
  )
}

const AdditionalAccountDetails = ({control, register, errors, accountType, accountTypeLabel, accountTypeWatch}) => {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12}>
        <FormRadioAsButtons
          control={control}
          name={`${accountType}AccountClosed`}
          label="Is this account closed?"
          errors={errors}
          options={yesNoOptions}
        />
      </Grid>
      <Grid item xs={12}>
        <FormRadioAsButtons
          control={control}
          name={`${accountType}InApplicantName`}
          label={`Is your ${accountTypeLabel} bill in your name?`}
          errors={errors}
          options={yesNoOptions}
        />
      </Grid>
      {accountTypeWatch === 'no' &&
        <>
          <Grid item xs={12}>
            <FormTextField
              className={classes.inputFields}
              name={`${accountType}AccountOwnerName`} 
              label="Whose name is the account in?"
              labelPlacement="top" 
              register={register} 
              errors={errors}                
            />  
          </Grid>
          <Grid item xs={12}>
            <FormRadioAsButtons
              control={control}
              name={`${accountType}AccountOwnerLiveWithApplicant`}
              label="Does this person live with you?"
              errors={errors}
              options={yesNoOptions}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormSelect 
              width="21rem"
              name={`${accountType}AccountOwnerRelationToApplicant`} 
              labelId={`${accountType}AccountOwnerRelationToApplicant-label`}
              label="What is this person's relationship to you?"
              control={control}
              errors={errors}
              options={relationToApplicantOptions}
            />
          </Grid>
        </>
      }
    </>
  )
}

const PrimarySource = ({selection, control, errors, register}) => {
  switch(selection) {
    case 'naturalGas':
      return (<ProviderDetails control={control} errors={errors} register={register} accountType="naturalGas" accountTypeLabel="natural gas"/>);
    case 'fuelOilOrKerosene':
      return (<ProviderDetails control={control} errors={errors} register={register} accountType="fuelOilOrKerosene" accountTypeLabel="fuel oil or kerosene"/>);
    case 'propane':
      return (<ProviderDetails control={control} errors={errors} register={register} accountType="propane" accountTypeLabel="propane"/>);
    case 'other':
      return (<Other errors={errors} register={register}/>);
    default:
      return <></>;
  }
}

export default function UtilityGeneralInformation({ data, back, next, save, validate, validationSchema }) {
  const { control, errors, register, watch, getValues, setValue } = useLiheapForm(save, data, validate, validationSchema, false);

  const [primarySource, setPrimarySource] = useState(data?.primarySource);
  const [secondarySources, setSecondarySources] = useState(data?.secondarySources);
  const [secondaryOptions, setSecondaryOptions] = useState(heatOptions.filter((option) => option.value !== data?.primarySource));

  const classes = useStyles();

  const primarySourceHandler = (selectedVal) => {
    setPrimarySource(selectedVal);
    setSecondaryOptions(heatOptions.filter((option) => option.value !== selectedVal));
    setSecondarySources(prevSecondarySources => prevSecondarySources?.filter((source) => source !== selectedVal));
  }

  const secondarySourcesHandler = (selectedVals) => {
    setSecondarySources(selectedVals);
  }

  const handleElectricitySelect = (val) => {
    if(val === 'entergy'){
      setValue("otherElectricityProvider", '', { shouldDirty: true });
      setValue("overdueBalance", '', { shouldDirty: true });
      setValue("overdueBalanceValue", '', { shouldDirty: true });
    }
  }

  //register "overdueBalance" and "overdueBalanceValue" fields so they can be reset via handleElectricitySelect
  useEffect(()=> { 
    register("overdueBalance");
    register("overdueBalanceValue");
  },[register])

  const electricityProviderWatch = watch('electricityProvider');

  const rentOrOwnWatch = watch('rentOrOwn');
  const electricityInApplicantNameWatch = watch('electricityInApplicantName');
  const primarySourceInApplicantNameWatch = watch('primarySourceInApplicantName');

  const naturalGasInApplicantNameWatch = watch('naturalGasInApplicantName');
  const fuelOilOrKeroseneInApplicantNameWatch = watch('fuelOilOrKeroseneInApplicantName');
  const propaneInApplicantNameWatch = watch('propaneInApplicantName');
  const otherInApplicantNameWatch = watch('otherInApplicantName');

    // clears the placeholder value when auto-filled
  // eslint-disable-next-line
  const watchFields = watch([
    "rentLandlordStreetAddress", "rentLandlordCity", "rentLandlordZip", "rentLandlordCountyOrParish", "rentLandlordApartmentNumber",
  ]);

  const getLabelShrink = (fieldName) => {
    return getValues(fieldName) !== '';
  }

  return (
    <Container className={classes.mainContainer}>
      <Grid container spacing={1}>
        <StepHeader 
          title="Utility Information" 
          desc = "Please provide the following information for the applicant."
        />
        <FormSection>
          <Grid item xs={12}>
            <FormRadioAsButtons
              control={control}
              name="rentOrOwn"
              label="Do you rent or own?"
              errors={errors}
              options={rentOwnOptions}
            />
          </Grid>
          { rentOrOwnWatch === 'rent' && 
            <>
              <Grid item xs={12}>
                <FormRadioAsButtons
                  control={control}
                  name="rentEnergyCostIncluded"
                  label="Is your energy cost included in your rent payment?"
                  errors={errors}
                  options={yesNoOptions}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.sectionHeader}>Please provide your Landlord’s information</Typography>
              </Grid>
              <Grid className={classes.row} container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <FormTextField
                    name="rentLandlordName" 
                    label="Landlord Name"
                    labelPlacement="inside" 
                    register={register} 
                    errors={errors}                
                  />  
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormTextField
                    mask="(999) 999-9999"
                    name="rentLandlordPhoneNo" 
                    label="Landlord Phone Number"
                    labelPlacement="inside" 
                    register={register} 
                    errors={errors}  
                    inputProps={{
                      inputMode: 'tel',
                      maxLength: 14
                    }}              
                  /> 
                </Grid>
              </Grid>
              <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <FormTextField name="rentLandlordStreetAddress" label="Street Address" register={register} errors={errors} id="rentLandlordStreetAddress"
                  InputLabelProps={{ shrink: getLabelShrink("rentLandlordStreetAddress") }} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormTextField name="rentLandlordApartmentNumber" label="Apartment Number" register={register} errors={errors}
                  InputLabelProps={{ shrink: getLabelShrink("rentLandlordApartmentNumber") }} />
              </Grid>
            </Grid>
            <Grid className={classes.row} container spacing={1}>
              <Grid item xs={12} sm={6}>
                <FormTextField name="rentLandlordCity" label="City" register={register} errors={errors}
                  InputLabelProps={{ shrink: getLabelShrink("rentLandlordCity") }} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormSelect
                  name="rentLandlordState"
                  label="State"
                  labelId="rentLandlordState-label"
                  labelPlacement="inside"
                  control={control}
                  errors={errors}
                  options={stateOptions}
                />
              </Grid>
            </Grid>
            <Grid className={classes.row} container spacing={1}>
              <Grid item xs={12} sm={6}>
                <FormTextField name="rentLandlordZip" label="Zip" register={register} errors={errors} mask="99999"                
                  InputLabelProps={{ shrink: getLabelShrink("rentLandlordZip") }}
                  inputProps={{ inputMode: 'tel', maxLength: 5 }} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormTextField name="rentLandlordCountyOrParish" label="County/Parish" register={register} errors={errors}
                  InputLabelProps={{ shrink: getLabelShrink("rentLandlordCountyOrParish") }} />
              </Grid>
            </Grid>
          </>
          }
        </FormSection>
        <FormSection>
          <Grid item xs={12}>
            <FormRadioAsButtons
              control={control}
              name="residenceAllElectric"
              label="Is your residence all electric?"
              errors={errors}
              options={yesNoOptions}
            />
          </Grid>
          <Electricity 
              control={control} 
              errors={errors} 
              register={register}
              electricityProviderWatch={electricityProviderWatch}
              onSelect={handleElectricitySelect}
            />
          <AdditionalAccountDetails
            control={control}
            errors={errors}
            register={register}
            accountType="electricity"
            accountTypeLabel="electricity"
            accountTypeWatch={electricityInApplicantNameWatch}
          />
        </FormSection>
        <FormSection>
          <Grid item xs={12}>
            <FormRadioAsButtons
              control={control}
              name="primarySource"
              label="How do you primarily heat your home?"
              errors={errors}
              options={heatOptions}
              defaultValue={primarySource}
              onSelect={primarySourceHandler}
            />
          </Grid>
          {primarySource && 
            <>
              <PrimarySource 
                selection={primarySource} 
                control={control} 
                errors={errors} 
                register={register}
              />
              {primarySource !== 'electricity' &&
                <>
                  <AdditionalAccountDetails
                    control={control}
                    errors={errors}
                    register={register}
                    accountType="primarySource"
                    accountTypeLabel="primary heating source"
                    accountTypeWatch={primarySourceInApplicantNameWatch}
                  />
                </>
              }
            </>
          }
        </FormSection>
        <FormSection>
          <Grid item xs={12}>
            <FormCheckboxes
              defaultValues={secondarySources}
              register={register}
              name="secondarySources"
              label="Do you use any secondary sources to heat your home? (Please select all that apply)"
              options={secondaryOptions}
              gridSize={12}
              onSelect={secondarySourcesHandler}
              errors={errors}
              noOption="None"
            />
          </Grid>

          {secondarySources?.some(option => option === 'naturalGas') &&             
            <>
              <ProviderDetails
                control={control}
                errors={errors}
                register={register}
                accountType="naturalGas"
                accountTypeLabel="natural gas"
                accountTypeWatch={naturalGasInApplicantNameWatch}
              />
              <AdditionalAccountDetails
                control={control}
                errors={errors}
                register={register}
                accountType="naturalGas"
                accountTypeLabel="natural gas"
                accountTypeWatch={naturalGasInApplicantNameWatch}
              />
            </>
          }
          {secondarySources?.some(option => option === 'fuelOilOrKerosene') && 
            <>
              <ProviderDetails
                control={control}
                errors={errors}
                register={register}
                accountType="fuelOilOrKerosene"
                accountTypeLabel="fuel oil or kerosene"
                accountTypeWatch={fuelOilOrKeroseneInApplicantNameWatch}
              />              
              <AdditionalAccountDetails
                control={control}
                errors={errors}
                register={register}
                accountType="fuelOilOrKerosene"
                accountTypeLabel="fuel oil or kerosene"
                accountTypeWatch={fuelOilOrKeroseneInApplicantNameWatch}
              />
            </>
          }
          {secondarySources?.some(option => option === 'propane') && 
            <>
              <ProviderDetails
                control={control}
                errors={errors}
                register={register}
                accountType="propane"
                accountTypeLabel="propane"
                accountTypeWatch={propaneInApplicantNameWatch}
              />
              <AdditionalAccountDetails
                control={control}
                errors={errors}
                register={register}
                accountType="propane"
                accountTypeLabel="propane"
                accountTypeWatch={propaneInApplicantNameWatch}
              />
            </>
          }
          {secondarySources?.some(option => option === 'other') && 
            <>
              <Other errors={errors} register={register}/>
              <AdditionalAccountDetails
                control={control}
                errors={errors}
                register={register}
                accountType="other"
                accountTypeLabel="other secondary source"
                accountTypeWatch={otherInApplicantNameWatch}
              />
            </>
          }       
        </FormSection>
        <FormSection>
          <Grid item xs={12}>
            <FormRadioAsButtons
              control={control}
              name="weatherProofingOrEfficiency"
              label="Is your home in need of weather-proofing or energy efficiency services?"
              errors={errors}
              options={yesNoOptions}
            />       
          </Grid>
        </FormSection>   
        <StepButtons back={back} next={next}/>
      </Grid>
    </Container>
  )
}
