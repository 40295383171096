export const unearnedBenefitOptions = [
  {
    value: 'childSupport',
    label: 'Child Support'
  },
  {
    value: 'contributions',
    label: 'Contributions'
  },
  {
    value: 'fosterCarePayments',
    label: 'Foster-Care Payments'
  },
  {
    value: 'interestAndDividens',
    label: 'Interest & Dividends'
  },
  {
    value: 'partBHudUtilityPaymentsGreaterEqualToFiftyDollars',
    label: 'Part B premium and HUD utility payments >= $50.00'
  },
  {
    value: 'pensionsAndAnnuities',
    label: 'Pension & Annuities'
  },
  {
    value: 'socialSecurity',
    label: 'Retirement Income from Social Security'
  },
  {
    value: 'retirementAndVeteranBenefits',
    label: 'Retirement & Veteran Benefits'
  },
  {
    value: 'ssdi',
    label: 'Social Security Disability Income (SSDI)'
  },
  {
    value: 'ssi',
    label: 'Supplemental Security Income (SSI)'
  },
  {
    value: 'tanf',
    label: 'Temporary Assistance for Needy Families (TANF)'
  },
  {
    value: 'tea',
    label: 'Transitional Employment Assistance (TEA)'
  },
  {
    value: 'unemploymentInsurance',
    label: 'Unemployment Insurance'
  },
  {
    value: 'workersComp',
    label: 'Worker\'s Compensation'
  },
  {
    value: 'other',
    label: 'Other'
  }
]
