import { useState, useEffect } from "react";
import { AuthContext } from "../context/authContext";
import { setAPIRequestInterceptor, setAPIResponseInterceptor } from "../api/APIUtils";
import { stateConfigOptions } from "../constants/stateConfigOptions";

const LOCALSTORAGE_KEY = "userInfo";

export function AuthContextProvider({ children }) {
    const [ userInfo, setUserInfo ] = useState(false);

    const logout = () => {
        postUserInfo(false);
        window.sessionStorage.clear();
    }

    const getUserFromLocalStorage = () => {
        const localState = localStorage.getItem(LOCALSTORAGE_KEY);
        const jsonState = (localState) ? JSON.parse(localState) : false;

        return jsonState;
    }

    const getUserToken = () => {
        const jsonState = getUserFromLocalStorage();
        return jsonState["idToken"] || "";
    }

    const getRefreshToken = () => {
        const jsonState = getUserFromLocalStorage();
        return jsonState["refreshToken"] || "";
    }
    
    const resetUserToken = (token) => {
        const jsonState = getUserFromLocalStorage();
        const newTokenInfo =  {
            idToken : token["IdToken"]
        };

        const newState = Object.assign({}, jsonState, newTokenInfo);
        postUserInfo(newState);
    }

    const getUserInfoFromIdToken = (tokenResponse) => {
        const idToken = tokenResponse["IdToken"];
        const refreshToken = tokenResponse["RefreshToken"];
        const accessToken =  tokenResponse["AccessToken"];
        let userInfo = tokenResponse["UserInfo"];
        let agencyInfo = tokenResponse["AgencyInfo"];
        let stateInfo = tokenResponse["StateInfo"];
        userInfo["idToken"] = idToken;
        userInfo["refreshToken"] = refreshToken;
        userInfo["accessToken"] = accessToken;
        userInfo["agencyInfo"] = agencyInfo;
        userInfo["stateInfo"] = stateInfo;
        return userInfo
      }

    const getTokenFromLoginResponse = (response) => {
        postUserInfo(getUserInfoFromIdToken(response["data"]));
    }

    useEffect(() => {
        const initState = getUserFromLocalStorage();

        if(initState) {
            // feStateConfigs reference React components and can't be stored in localStorage; add in after
            let feStateConfigs = stateConfigOptions[initState['agencyState']]
            initState["stateInfo"] = {...feStateConfigs, ...initState["stateInfo"]}
            setUserInfo(initState);
        }

        setAPIRequestInterceptor(getUserToken);
        setAPIResponseInterceptor(logout, getRefreshToken, resetUserToken);
// eslint-disable-next-line
    }, []);

    const postUserInfo = (userInfo) => {
        localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(userInfo));

        if(userInfo){
        // feStateConfigs reference React components and can't be stored in localStorage; add in after
            let feStateConfigs = stateConfigOptions[userInfo['agencyState']]
            userInfo["stateInfo"] = {...feStateConfigs, ...userInfo["stateInfo"]}
        }

        setUserInfo(userInfo);   
    }
    
    return (
      <AuthContext.Provider value={{userInfo, setUserInfo, logout, getTokenFromLoginResponse }}>
        {children}
      </AuthContext.Provider>
    );
  }