export const arkansasCountyOptions = [
    {
        value: 'Arkansas',
        label: 'Arkansas'
    },
    {
        value: 'Ashley',
        label: 'Ashley'
    },
    {
        value: 'Bradley',
        label: 'Bradley'
    },
    {
        value: 'Calhoun',
        label: 'Calhoun'
    },
    {
        value: 'Chicot',
        label: 'Chicot'
    },
    {
        value: 'Clark',
        label: 'Clark'
    },
    {
        value: 'Cleburne',
        label: 'Cleburne'
    },
    {
        value: 'Cleveland',
        label: 'Cleveland'
    },
    {
        value: 'Columbia',
        label: 'Columbia'
    },
    {
        value: 'Conway',
        label: 'Conway'
    },
    {
        value: 'Dallas',
        label: 'Dallas'
    },
    {
        value: 'Desha',
        label: 'Desha'
    },
    {
        value: 'Drew',
        label: 'Drew'
    },
    {
        value: 'Faulkner',
        label: 'Faulkner'
    },
    {
        value: 'Garland',
        label: 'Garland'
    },
    {
        value: 'Grant',
        label: 'Grant'
    },
    {
        value: 'Hempstead',
        label: 'Hempstead'
    },
    {
        value: 'Hot Spring',
        label: 'Hot Spring'
    },
    {
        value: 'Jefferson',
        label: 'Jefferson'
    },
    {
        value: 'Lafayette',
        label: 'Lafayette'
    },
    {
        value: 'Lincoln',
        label: 'Lincoln'
    },
    {
        value: 'Logan',
        label: 'Logan'
    },
    {
        value: 'Lonoke',
        label: 'Lonoke'
    },
    {
        value: 'Miller',
        label: 'Miller'
    },
    {
        value: 'Mississippi',
        label: 'Mississippi'
    },
    {
        value: 'Montgomery',
        label: 'Montgomery'
    },
    {
        value: 'Nevada',
        label: 'Nevada'
    },
    {
        value: 'Ouachita',
        label: 'Ouachita'
    },
    {
        value: 'Perry',
        label: 'Perry'
    },
    {
        value: 'Pike',
        label: 'Pike'
    },
    {
        value: 'Pope',
        label: 'Pope'
    },
    {
        value: 'Pulaski',
        label: 'Pulaski'
    },
    {
        value: 'Saline',
        label: 'Saline'
    },
    {
        value: 'Scott',
        label: 'Scott'
    },
    {
        value: 'Union',
        label: 'Union'
    },
    {
        value: 'White',
        label: 'White'
    },
    {
        value: 'Yell',
        label: 'Yell'
    }
  ];

