import { useContext } from "react";
import { ConfigContext } from "../context/configContext";
import Banner from "./Banner";
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { AuthContext } from "../context/authContext";

const useStyles = makeStyles((theme) => ({
    spacer: {
      height: '5rem',
      [theme.breakpoints.down('md')]: {
        height: "2rem",
      }
    }
  }));

export default function MessageBanner (props) {
    const classes = useStyles();
    const { messageType } = props;
    const { clientConfig } = useContext(ConfigContext);
    const { userInfo } = useContext(AuthContext);
    const agencyId = userInfo?.agencyId;
    
    const getMessage = (messageType) =>{
      let message = null;
      if (clientConfig){
        if (clientConfig["Messages"]){
            if (clientConfig["Messages"][agencyId] &&
                (messageType in clientConfig["Messages"][agencyId])){
                //agency specific check
                message = clientConfig["Messages"][agencyId][messageType];
            }else{
                //global check
                message = clientConfig["Messages"][messageType];
            }
        }            
      }
      return message;
    }

    const message = getMessage(messageType);

    return (
        message ?
            <Banner 
                title={message["title"]} 
                body={message["body"]} 
                messageType={message["type"] ? message["type"] : "info"} 
            /> :
        messageType === 'FrontPage' ?
            <div className={classes.spacer}/>
            :
            null 
    );
}

MessageBanner.propTypes = {
    messageType: PropTypes.string.isRequired
};