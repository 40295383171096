import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails, useMediaQuery, useTheme } from '@mui/material';
import { ExpandMore } from '@mui/icons-material'
import { faqs } from '../../constants/home-page/faq';

const useStyles = makeStyles((theme) => ({
    mainContainer:{
        margin: '2.5rem 0rem',
        width: 'fit-content',
        justifyContent: 'center',
        padding: '2rem 6rem !important',
        [theme.breakpoints.down('lg')]: {
            padding: '1rem 2rem !important',
            margin: 'auto'
        },
    },
    accordion: {
        width: '100%',
        boxShadow: 'none'
    },
    summary: {
        padding: 0
    },
    expandIcon: {
        color: theme.palette.primary.main,
    },
    title: {
        color: '#000',
        fontSize: '2.25rem'
      },
    question: {
        color: '#000',
        fontSize: '1rem',
        fontWeight: 500
    },
    text: {
        color: '#000'
    }
}));

export default function FAQ() {
  const classes = useStyles();
  const theme = useTheme();
  const matchesXsDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container spacing={1} className={classes.mainContainer}>
        <Typography className={classes.title}>{matchesXsDown ? `FAQ` : `Frequently Asked Questions`}</Typography>
        <Grid container item xs={12}>
            {faqs?.map((faq, index) => 
            <Accordion key={index} className={classes.accordion}>
                <AccordionSummary expandIcon={<ExpandMore className={classes.expandIcon}/>} className={classes.summary}>
                    <Grid item xs={12}>
                        <Typography className={classes.question}>
                            {faq.question}
                        </Typography> 
                    </Grid>
                </AccordionSummary>
                <AccordionDetails >
                    <Grid item xs={12}>
                        <Typography className={classes.text}>
                            {faq.answer}
                        </Typography> 
                    </Grid>
                </AccordionDetails>
            </Accordion>
            )}

        </Grid>
    </Grid>
)}

