import React, { useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import InviteUser from '../components/InviteUser';
import InviteAdmin from '../components/InviteAdmin';

const useStyles = makeStyles((theme) => ({
  tabs: {
    marginBottom: '2rem',
    '& > .MuiTabs-scroller':{
      '& > .MuiTabs-indicator': {
        height: '5px',
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px'
      }
    }
  },
  tab: {
    textTransform: 'none',
    fontSize: '1.25rem'
  }
}));

export default function AdminInvite() {
  const classes = useStyles();

  const [value, setValue] = useState(0);
  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  return (
    <>
        <Tabs className={classes.tabs} value={value} onChange={handleChange}>
          <Tab className={classes.tab} label="Invite User"  />
          <Tab className={classes.tab} label="Invite Admin"  />
        </Tabs>

      {value === 0 ?
        <InviteUser />
        :
        <InviteAdmin />
      }

    </>
  );
}
