import { processSteps as arProcess, processStepLabels as arProcessStepLabels }  from './ar/processSteps';
import { processSteps as txProcess, processStepLabels as txProcessStepLabels }  from './tx/processSteps';
import { getAdditionalFileType as arGetAdditionalFileType, getGeneralFileTypes as arGetGeneralFileTypes, 
    getIncomeFileTypes as arGetIncomeFileTypes, getRequiredGenAndIncomeFileTypes as arGetRequiredGenAndIncomeFileTypes, 
    getUnearnedIncomeFileTypes as arGetUnearnedIncomeFileTypes, getUtilityFileTypes as arGetUtilityFileTypes,
    getRequiredAdditionalFileTypes as arGetRequiredAdditionalFileTypes
} from './ar/fileUtils';
import { getAdditionalFileType as txGetAdditionalFileType, getGeneralFileTypes as txGetGeneralFileTypes, 
    getIncomeFileTypes as txGetIncomeFileTypes, getRequiredGenAndIncomeFileTypes as txGetRequiredGenAndIncomeFileTypes, 
    getUnearnedIncomeFileTypes as txGetUnearnedIncomeFileTypes, getUtilityFileTypes as txGetUtilityFileTypes,
    getRequiredAdditionalFileTypes as txGetRequiredAdditionalFileTypes
} from './tx/fileUtils';
import { validateDocuments } from "./sharedFileUtils";
import { heatOptions as arHeatOptions } from './ar/checkboxes/heatOptions'
import { raceOptions as arRaceOptions } from './ar/checkboxes/raceOptions';
import { workStatusOptions as arWorkStatusOptions } from './ar/radios/workStatusOptions';
import { unearnedBenefitOptions as arUnearnedBenefitOptions} from './ar/checkboxes/unearnedBenefitOptions';
import { crisisSituationOptions as arCrisisSituationOptions } from './ar/checkboxes/crisisSituationOptions';
import { heatOptions as txHeatOptions } from './tx/checkboxes/heatOptions'
import { raceOptions as txRaceOptions } from './tx/checkboxes/raceOptions';
import { workStatusOptions as txWorkStatusOptions } from './tx/radios/workStatusOptions';
import { unearnedBenefitOptions as txUnearnedBenefitOptions} from './tx/checkboxes/unearnedBenefitOptions';
import { educationLevelOptions as txEducationLevelOptions } from './tx/radios/educationLevelOptions';
import { healthInsuranceTypeOptions as txHealthInsuranceTypeOptions } from './tx/checkboxes/healthInsuranceTypeOptions';
import { proofOfCitizenshipOptions as txProofOfCitizenshipOptions} from './tx/radios/proofOfCitizenshipOptions';
import { otherFormOfIdentificationOptions as txOtherFormOfIdentificationOptions } from './tx/checkboxes/otherFormOfIdentificationOptions';
import { governmentIssuedPhotoIDOptions as txGovernmentIssuedPhotoIDOptions } from './tx/radios/governmentIssuedPhotoIDOptions';
import { rentHousingTypeOptions as txRentHousingTypeOptions } from './tx/radios/rentHousingTypeOptions';
import { ownHousingTypeOptions as txOwnHousingTypeOptions } from './tx/radios/ownHousingTypeOptions';
import { heaterOptions as txHeaterOptions } from './tx/checkboxes/heaterOptions';
import { airConditionerOptions as txAirConditionerOptions } from './tx/radios/airConditionerOptions';
import { stateDocsLinks as arStateDocsLinks} from './ar/stateDocsLinks'
import ARCurrentStatus from '../components/agency/components/application-review/ar/CurrentStatus';
import TXCurrentStatus from '../components/agency/components/application-review/tx/CurrentStatus';
import ARChangeStatus from '../components/agency/components/application-review/ar/ChangeStatus';
import TXChangeStatus from '../components/agency/components/application-review/tx/ChangeStatus';
import ARReviewBenefitSection from '../components/agency/components/application-review/ar/ReviewBenefitSection';
import TXReviewBenefitSection from '../components/agency/components/application-review/tx/ReviewBenefitsSection';

export const stateConfigOptions = {
    "AR" : {
        "processSteps" : arProcess,
        "processStepLabels" : arProcessStepLabels,
        "agencyConfigs": {
            "currentStatusComponent": ARCurrentStatus,
            "changeStatusComponent": ARChangeStatus,
            "reviewBenefitsComponent": ARReviewBenefitSection,
        },
        "fileUtils" : {
            'getGenFileUtil': arGetRequiredGenAndIncomeFileTypes, 
            'getUtilityFileUtil': arGetUtilityFileTypes, 
            'getValidateFileUtil': validateDocuments,
            'getAdditionalFileType' : arGetAdditionalFileType,
            'getRequiredAdditionalFileTypes': arGetRequiredAdditionalFileTypes,
            'getGeneralFileTypes' : arGetGeneralFileTypes,
            'getIncomeFileTypes' : arGetIncomeFileTypes,
            'getUnearnedIncomeFileTypes' : arGetUnearnedIncomeFileTypes,
            'getUtilityFileTypes' : arGetUtilityFileTypes
        },
        "lists" : {
            'heatOptions' : arHeatOptions,
            'raceOptions' : arRaceOptions,
            'workStatusOptions' : arWorkStatusOptions,
            'unearnedBenefitOptions' : arUnearnedBenefitOptions,
            'crisisSituationOptions' : arCrisisSituationOptions,
            'educationLevelOptions': [],
            'healthInsuranceTypeOptions': [],
            'proofOfCitizenshipOptions': [],
            'otherFormOfIdentificationOptions': [],
            'governmentIssuedPhotoIDOptions': [],
            'rentHousingTypeOptions': [],
            'ownHousingTypeOptions': [],
            'heaterOptions': [],
            'airConditionerOptions': [],
            'stateDocsLinks': arStateDocsLinks
        }
    },
    "TX" : {
        "processSteps" : txProcess,
        "processStepLabels" : txProcessStepLabels,
        "agencyConfigs": {
            "currentStatusComponent": TXCurrentStatus,
            "changeStatusComponent": TXChangeStatus,
            "reviewBenefitsComponent": TXReviewBenefitSection,
        },
        "fileUtils" : {
            'getGenFileUtil': txGetRequiredGenAndIncomeFileTypes, 
            'getUtilityFileUtil': txGetUtilityFileTypes, 
            'getValidateFileUtil': validateDocuments,
            'getAdditionalFileType' : txGetAdditionalFileType,
            'getRequiredAdditionalFileTypes': txGetRequiredAdditionalFileTypes,
            'getGeneralFileTypes' : txGetGeneralFileTypes,
            'getIncomeFileTypes' : txGetIncomeFileTypes,
            'getUnearnedIncomeFileTypes' : txGetUnearnedIncomeFileTypes,
            'getUtilityFileTypes' : txGetUtilityFileTypes
        },
        "lists" : {
            'heatOptions' : txHeatOptions,
            'raceOptions' : txRaceOptions,
            'workStatusOptions' : txWorkStatusOptions,
            'unearnedBenefitOptions' : txUnearnedBenefitOptions,
            'crisisSituationOptions': [],
            'educationLevelOptions': txEducationLevelOptions,
            'healthInsuranceTypeOptions': txHealthInsuranceTypeOptions,
            'proofOfCitizenshipOptions': txProofOfCitizenshipOptions,
            'otherFormOfIdentificationOptions': txOtherFormOfIdentificationOptions,
            'governmentIssuedPhotoIDOptions': txGovernmentIssuedPhotoIDOptions,
            'rentHousingTypeOptions': txRentHousingTypeOptions,
            'ownHousingTypeOptions': txOwnHousingTypeOptions,
            'heaterOptions': txHeaterOptions,
            'airConditionerOptions': txAirConditionerOptions,
            'stateDocsLinks': []
        }
    }
};
