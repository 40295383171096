import { CircularProgress, Container, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useStateForms from '../../../hooks/useStateForms';
import StepButtons from '../../StepButtons'
import StepHeader from '../../StepHeader'
import Documents from './Documents';

const useStyles = makeStyles(() => ({
  mainContainer: {
    margin: "0",
  }
}));

export default function AdditionalDocumentUpload({ data: appData, back, next, appId, fileData, handleFileCreate, handleFileDelete, appState }) {
  const classes = useStyles();

  const { isFetching: fetchingStateForms, data: requiredStateForms } = useStateForms(appState, 'yes')

  return (
    <Container className={classes.mainContainer}>
      <Grid container spacing={1}>
        <StepHeader 
          title="Additional Documents" 
          desc = "Are there any additional documents you would like to include with your application?"
        />
        {fetchingStateForms ? 
          <Grid container justifyContent="center">
            <CircularProgress color="primary" size={50} />
          </Grid>
        :
          <>
          <Documents
            appId={appId} 
            appData={appData} 
            type='requiredForms'
            fileData={fileData}
            handleFileCreate={handleFileCreate}
            handleFileDelete={handleFileDelete} 
            requiredStateForms={requiredStateForms}
            />
          <Documents
            appId={appId} 
            appData={appData} 
            type='additionalDocuments'
            fileData={fileData}
            handleFileCreate={handleFileCreate}
            handleFileDelete={handleFileDelete} 
            />
          </>
        }
        <Grid item xs={12}>
          <StepButtons back={back} next={next}/>
        </Grid>
      </Grid>      
    </Container>
  )
}
