import { Container, Grid, Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState, createElement } from 'react'
import { getDocumentFileData } from '../../../../constants/sharedFileUtils';
import { relationToApplicantOptions } from '../../../../constants/dropdowns/relationToApplicantOptions';
import { payFrequency } from '../../../../constants/dropdowns/payFrequency';
import { submittingAsOptions } from '../../../../constants/radios/submittingAsOptions';
import { yesNoDontKnowOptions } from '../../../../constants/radios/yesNoDontKnowOptions';
import { agencyGenderOptions } from '../../../../constants/radios/genderOptions';
import { rentOwnOptions } from '../../../../constants/radios/rentOwnOptions';
import { ethnicityOptions } from '../../../../constants/radios/ethnicityOptions';
import { utilityProviderOptions } from '../../../../constants/dropdowns/utilityProviderOptions';
import ReviewSubSection from './ReviewSubSection';
import SaveMergedPDF from '../SaveMergedPDF';
import PrintMergedPDF from '../PrintMergedPDF';
import { programOptions } from '../../../../constants/tx/checkboxes/programOptions';
import AccountSummary from './AccountSummary';
import { isEmptyObject, isEqual } from '../../../../utils/utilFunctions';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    marginLeft: "0",
    maxWidth: '95%'
  },
  header: {
    marginBottom: '1rem'
  },
  openAllButton: {
    textTransform: 'none',
    padding: '.75rem'
  },
  pdfActions: {
    alignItems: 'center'
  },
  accordion: {
    '& > .MuiPaper-elevation1': {
      boxShadow: '0px 2px 1px -1px #C4C4C4, 0px 1px 1px 0px #C4C4C4, 0px 0px 3px 0px #C4C4C4'
    }
  },
}));


export default function ReviewMain({ application, fileData, stateConfig }) {
  const appId  = application?.id
  const classes = useStyles();
  const [reviewTemplate, setReviewTemplate] = useState([]);
  const [showSSN, setShowSSN] = useState([{id: appId, visible: false}]);
  const [openAll, setOpenAll] = useState(false);

  const isEntergyUser = application?.electricityProvider === 'entergy'
  
  const unearnedBenefitOptions = stateConfig.lists.unearnedBenefitOptions;
  const crisisSituationOptions = stateConfig.lists.crisisSituationOptions;
  const heatOptions = stateConfig.lists.heatOptions;
  const raceOptions = stateConfig.lists.raceOptions;
  const workStatusOptions = stateConfig.lists.workStatusOptions;
  const educationLevelOptions = stateConfig.lists.educationLevelOptions;
  const healthInsuranceTypeOptions = stateConfig.lists.healthInsuranceTypeOptions;
  const proofOfCitizenshipOptions = stateConfig.lists.proofOfCitizenshipOptions;
  const otherFormOfIdentificationOptions = stateConfig.lists.otherFormOfIdentificationOptions;
  const governmentIssuedPhotoIDOptions = stateConfig.lists.governmentIssuedPhotoIDOptions;
  const rentHousingTypeOptions = stateConfig.lists.rentHousingTypeOptions;
  const ownHousingTypeOptions = stateConfig.lists.ownHousingTypeOptions;
  const heaterOptions = stateConfig.lists.heaterOptions;
  const airConditionerOptions = stateConfig.lists.airConditionerOptions;
  const stateDocsLinks = stateConfig.lists.stateDocsLinks;
  const processSteps = stateConfig.processSteps;
  const { getGeneralFileTypes, getIncomeFileTypes, getUnearnedIncomeFileTypes, getUtilityFileTypes, getAdditionalFileType } = stateConfig.fileUtils;
  const documentQueries = fileData;

  const renderReviewBenefits = () => {
    return(
      createElement(stateConfig.agencyConfigs.reviewBenefitsComponent, 
        { openAll: openAll, app: application, unearnedOptions: unearnedBenefitOptions, fuelSources: heatOptions, stateDocsLinks: stateDocsLinks})
    );
  }

  useEffect(() => {
    setReviewTemplate(buildReviewTemplate());
    // eslint-disable-next-line
  }, [application, fileData]);

  const buildDocumentSection = (requiredFileTypes, application, memberId="", previousApp="") => {
    let result = []

    for(let fileType of requiredFileTypes){
      let label = `${application?.firstName} ${application?.lastName}'s ${fileType.label}`
      if (fileType?.employerName){
        label = `${label} (${fileType.employerName})`
      }
      let files = getDocumentFileData(fileType, documentQueries, memberId)

      if(files && files.length > 0){
        let doc = {
          label: label, 
          value: files
        };

        //check for document updates if previous submittal exists
        if (!isEmptyObject(previousApp) && fileType.value !== "filledForm"){
          let prevFiles = getDocumentFileData(fileType, previousApp.documents, memberId)
          if (files.length > prevFiles.length){
            doc['previousValue'] = "New File(s)"
          }else if (prevFiles.length > files.length){
            doc['previousValue'] = "File(s) Removed"
          }else {
            for (let file of files){
              let prevFile = prevFiles.find(el => el.id === file.id)
              if(!prevFile){
                doc['previousValue'] = "File(s) Updated"
                break
              }
            }
          }
        }
        result.push(doc)
      }
    }
    return result;
  }

  const buildRequiredAdditionalDocumentSection = (application, previousApp="") => {
    let result = []

    let filteredFiles = fileData ? fileData?.filter((file) => file.fileType.startsWith('requiredAdditional')) : []
    let additionalFileLabels = []

    for(let file of filteredFiles){
      if(!additionalFileLabels.includes(file.label)){
        additionalFileLabels.push(file.label)
        let label = `${application?.firstName} ${application?.lastName}'s ${file.label}`
        let fileTypeObj = {'value': file.fileType, 'label': file.label}
        let files = getDocumentFileData(fileTypeObj, documentQueries)

        let doc = {
          label: label, 
          value: files
        };

        //check for document updates if previous submittal exists
        if (!isEmptyObject(previousApp)){
          let prevFiles = getDocumentFileData(fileTypeObj, previousApp.documents)
          if (files.length > prevFiles.length){
            doc['previousValue'] = "New File(s)"
          }else if (prevFiles.length > files.length){
            doc['previousValue'] = "File(s) Removed"
          }else {
            for (let file of files){
              let prevFile = prevFiles.find(el => el.id === file.id)
              if(!prevFile){
                doc['previousValue'] = "File(s) Updated"
                break
              }
            }
          }
        }
        result.push(doc)
      } 
    }
    return result;
  }

  const buildSummaryFields = (application, appId) => {
    let fields = [];
    let fieldObj = {}
    const options = {
      year: "numeric",
      month:"2-digit",
      day:"2-digit",
    };
    let submitDate = new Date(application?.initialSubmittedDate).toLocaleDateString("en-US", options).replace(', ','\n');
    fields.push({label: 'Date Submitted',value: submitDate})

    fieldObj = {label: `Applicant's Name`, value: `${application?.firstName} ${application?.lastName}`}
    if (!isEmptyObject(application.prevSubmittal)){
      if (!isEqual(`${application?.firstName} ${application?.lastName}`, `${application?.prevSubmittal.firstName} ${application?.prevSubmittal.lastName}`)){
        fieldObj['previousValue'] = `${application?.prevSubmittal?.firstName} ${application?.prevSubmittal?.lastName}`
      }
    }
    fields.push(fieldObj)

    fieldObj = {label: 'Service County', value: application?.serviceCountyOrParish}
    if (!isEmptyObject(application.prevSubmittal)){
      if (!isEqual(application?.serviceCountyOrParish,  application?.prevSubmittal.serviceCountyOrParish)){
        fieldObj['previousValue'] = application?.prevSubmittal.serviceCountyOrParish
      }
    }
    fields.push(fieldObj)

    let householdSize = 1;
    if(application?.householdMembers && application?.householdMembers.length > 0){
      application?.householdMembers.forEach(mem => householdSize += 1)
    }

    fieldObj = {label: 'Household Size',value: householdSize}
    if (!isEmptyObject(application.prevSubmittal)){
      let prevHouseholdSize = 1;
      if(application?.prevSubmittal.householdMembers && application?.prevSubmittal.householdMembers.length > 0){
        application?.prevSubmittal.householdMembers.forEach(mem => prevHouseholdSize += 1)
      }
      if (!isEqual(householdSize,  prevHouseholdSize)){
        fieldObj['previousValue'] = prevHouseholdSize
      }
    }
    fields.push(fieldObj)
    
    let adultCount = getAdultCount(application)
    fieldObj = {label: '# of Adults',value: adultCount}
    if (!isEmptyObject(application.prevSubmittal)){
      let prevAdultCount = getAdultCount(application.prevSubmittal)
      if (!isEqual(adultCount,  prevAdultCount)){
        fieldObj['previousValue'] = prevAdultCount
      }
    }
    fields.push(fieldObj)

    let minorCount = getMinorCount(application)
    fieldObj = {label: '# of Minors',value: minorCount}
    if (!isEmptyObject(application.prevSubmittal)){
      let prevMinorCount = getMinorCount(application.prevSubmittal)
      if (!isEqual(minorCount,  prevMinorCount)){
        fieldObj['previousValue'] = prevMinorCount
      }
    }
    fields.push(fieldObj)

    let disabledCount = getDisabledCount(application)
    fieldObj = {label: '# with Disabilities',value: disabledCount}
    if (!isEmptyObject(application.prevSubmittal)){
      let prevDisabledCount = getDisabledCount(application.prevSubmittal)
      if (!isEqual(disabledCount,  prevDisabledCount)){
        fieldObj['previousValue'] = prevDisabledCount
      }
    }
    fields.push(fieldObj)

    //check for removed household members
    if (!isEmptyObject(application?.prevSubmittal) && application?.prevSubmittal["householdMembers"]){
      let removedHousehold = []
      application?.prevSubmittal["householdMembers"].forEach((member, index) => {
        let found = application["householdMembers"].find(el => el.id === member.id)
        if (!found){
          removedHousehold.push(`Household Member - Name: ${member.firstName} ${member.lastName}`)
        }
      })
      //add removed hh members at end if exists
      if (removedHousehold && removedHousehold.length > 0){
        fields.push({label: 'Removed Household Member(s)', value: removedHousehold, 'previousValue': ""})
      }
    }

    

    return fields.flat();
  }

  const buildFields = (application, stepSectionName, appId, memberId="", previousApp="") => {
    let steps = processSteps.filter(x => x.stepSection === stepSectionName);
    let fields = [];
    let removedFields = [];
    let id = memberId ? memberId : appId
    steps.forEach(step => {
      let stepFields = step.fields; 

      stepFields.forEach(field =>{
        if(field.value === 'employers'){
          let employerInfo = createEmployerFields(application, field, step, appId, memberId, previousApp)
          fields.push(employerInfo.empFields)
          removedFields = removedFields.concat(employerInfo.removedEmployers)
        }
        else if(field.value === 'birthDate'){
          let fieldProps = {appOrMemberId: id, label: field.label, value: application[field.value]}
          if (!isEmptyObject(previousApp)) {
            if (!isEqual(application[field.value], previousApp[field.value])) {
              fieldProps["previousValue"] = previousApp[field.value]
            }
          }
          fields.push(fieldProps);
          let age = getAge(application[field.value])
          let fieldPropsAge = {appOrMemberId: id, label: 'Age', value: age}
          if (!isEmptyObject(previousApp)) {
            let prevAge = getAge(previousApp[field.value])
            if (age !== prevAge){
              fieldPropsAge["previousValue"] = prevAge
            }
          }
          fields.push(fieldPropsAge);
        }
        else{
          let value = Array.isArray(application[field.value]) ? getArrayConstants(application, field) : getDisplayValue(application[field.value])
          if((value && application[field.value] !== undefined && application[field.value] !== '')){
            let fieldProps = {appOrMemberId: id, label: field.label, value: value}
            if (!isEmptyObject(previousApp)) {
              let prevValue = Array.isArray(previousApp[field.value]) ? getArrayConstants(previousApp, field) : getDisplayValue(previousApp[field.value])
              if (!isEqual(value, prevValue)) {
                fieldProps["previousValue"] = prevValue
              }
            }
            fields.push(fieldProps);
          }
        }

        //check for removed fields
        if (!isEmptyObject(previousApp)){
          if (previousApp[field.value] && !application[field.value]){
            let prevValue = Array.isArray(previousApp[field.value]) ? getArrayConstants(previousApp, field) : getDisplayValue(previousApp[field.value])
            removedFields.push(`${field.label} - ${prevValue}`)
          }
        }
      })      
    });

    //add removed fields at end if exists
    if (removedFields && removedFields.length > 0){
      fields.push({appOrMemberId: id, label: 'Removed Items', value: removedFields, 'previousValue': ""})
    }

    return fields.flat();
  }

  const createEmployerFields = (application, field, step, appId, memberId, previousApp) => {
    let empFields = []
    let removedEmployers = []
    let empFieldObj  = ""
    let id = memberId ? memberId : appId

    if(application[field.value]){
      application[field.value].forEach((employer, index) => {
        let prevEmployer = {}
        if (!isEmptyObject(previousApp) && previousApp[field.value]){
          prevEmployer = previousApp[field.value].find(el => el.id === employer.id)
        }

        let label = `Employer #${index+1} `;
        let empValue = getDisplayValue(employer.name)
        empFieldObj = {
          appOrMemberId: id,
          label: label + 'Name', 
          value: empValue, 
        }
        if (!isEmptyObject(previousApp)){
          let prevEmpValue = getDisplayValue(prevEmployer?.name)
          if (!isEqual(empValue, prevEmpValue)) {
            empFieldObj["previousValue"] = prevEmpValue
          }
        }
        empFields.push(empFieldObj);

        empValue = getDisplayValue(employer.monthlyIncomeAmount)
        empFieldObj = {
          appOrMemberId: id,
          label: label + 'Monthly Income', 
          value: empValue, 
        }
        if (!isEmptyObject(previousApp)){
          let prevEmpValue = getDisplayValue(prevEmployer?.monthlyIncomeAmount)
          if (!isEqual(empValue, prevEmpValue)) {
            empFieldObj["previousValue"] = prevEmpValue
          }
        }
        empFields.push(empFieldObj);

        empValue = getDisplayValue(employer.payFrequency)
        empFieldObj = {
          appOrMemberId: id,
          label: label + 'Pay Frequency', 
          value: getDisplayValue(empValue), 
        }
        if (!isEmptyObject(previousApp)){
          let prevEmpValue = getDisplayValue(prevEmployer?.payFrequency)
          if (!isEqual(empValue, prevEmpValue)) {
            empFieldObj["previousValue"] = prevEmpValue
          }
        }
        empFields.push(empFieldObj);
      })

      //check for removed employers
      if (!isEmptyObject(previousApp) && previousApp[field.value]){
        previousApp[field.value].forEach((employer, index) => {
          let found = false;
          if (application[field.value]){
            found = application[field.value].find(el => el.id === employer.id)
          }
          if (!found){
            removedEmployers.push(`Employer - Name: ${getDisplayValue(employer.name)}, Monthly Income ${getDisplayValue(employer.monthlyIncomeAmount)}, Pay Frequency: ${getDisplayValue(employer.payFrequency)}`)
          }
        })
      }
    }
    return {"empFields": empFields, "removedEmployers": removedEmployers}
  }

  const getDisplayValue = (inputVal) => {
    let constantValues = [relationToApplicantOptions, payFrequency, 
      submittingAsOptions, agencyGenderOptions, workStatusOptions, yesNoDontKnowOptions,
      rentOwnOptions, utilityProviderOptions, heatOptions, ethnicityOptions, 
      educationLevelOptions, proofOfCitizenshipOptions, governmentIssuedPhotoIDOptions,
      otherFormOfIdentificationOptions, rentHousingTypeOptions, airConditionerOptions,
      ownHousingTypeOptions
      ];
    let searchArray = constantValues.flat().map(constant => constant.value)

    let displayVal = searchArray.includes(inputVal) ? [...new Set(constantValues.flat().filter(constant => constant.value === inputVal).map(constant => constant.label))] : inputVal

    return displayVal
  }

  const getDisabledCount = (app) => {
    let count = 0;
    if(app['disabled'] === 'yes'){
      count++;
    }
    if(app?.householdMembers && app?.householdMembers.length > 0){
      for (const member of app?.householdMembers) {
        if(member['disabled'] === 'yes'){
          count++;
        }
      }
    }
    return count
  }

  const getAdultCount = (app) => {
    let count = 0;
    let age  = getAge(app['birthDate'])
    if(age >= 18){
      count++;
    }
    if(app?.householdMembers && app?.householdMembers.length > 0){
      for (const member of app?.householdMembers) {
        let memberAge  = getAge(member['birthDate'])
        if(memberAge >= 18){
          count++;
        }
      }
    }
    return count
  }

  const getMinorCount = (app) => {
    let count = 0;
    let age  = getAge(app['birthDate'])
    if(age < 18){
      count++;
    }
    if(app?.householdMembers && app?.householdMembers.length > 0){
      for (const member of app?.householdMembers) {
        let memberAge  = getAge(member['birthDate'])
        if(memberAge < 18){
          count++;
        }
      }
    }
    return count
  }

  const getAge = (birthDate) => {
    let dob = new Date(birthDate);  
    let monthDiff = Date.now() - dob.getTime();  
    let ageDate = new Date(monthDiff);   
    let year = ageDate.getUTCFullYear();
    let age = Math.abs(year - 1970);  
    return age
  }

  const getArrayConstants = (application, field) => {
    let selectionArray = application[field.value];
    switch (field.value) {
      case ('race'):
        if(selectionArray && selectionArray[0] === 'noOption'){
          return 'Unknown / Not Reported'
        }
        let raceOps = raceOptions.filter((option) => selectionArray.includes(option.value)).map((op) => op.label)
        return raceOps.join(', ')
      case ('utilityAssistanceNeeded'):
      case('secondarySources'):
        if(selectionArray && selectionArray[0] === 'noOption'){
          return 'None'
        }
        let heatOps = heatOptions.filter((option) => selectionArray.includes(option.value)).map((op) => op.label)
        return heatOps.join(', ')
      case ('unearnedBenefits'):
        if(selectionArray && selectionArray[0] === 'noOption'){
          return 'No, I do not receive any unearned income'
        }
        let benefitOps = unearnedBenefitOptions.filter((option) => selectionArray.includes(option.value)).map((op) => op.label)
        return benefitOps.join(', ')
      case ('crisisSituations'):
        if(selectionArray && selectionArray[0] === 'noOption'){
          return 'None of these apply to me'
        }
        let crisisOps = crisisSituationOptions.filter((option) => selectionArray.includes(option.value)).map((op) => op.label)
        return crisisOps.join(', ')
      case ('healthInsurance'):
        if(selectionArray && selectionArray[0] === 'noOption'){
          return 'None'
        }
        let insuranceOps = healthInsuranceTypeOptions.filter((option) => selectionArray.includes(option.value)).map((op) => op.label)
        return insuranceOps.join(', ')
      case ('heaterType'):
        if(selectionArray && selectionArray[0] === 'noOption'){
          return 'None'
        }
        let heaterOps = heaterOptions.filter((option) => selectionArray.includes(option.value)).map((op) => op.label)
        return heaterOps.join(', ')
      case ('otherFormOfIdentification'):
        let docOps = otherFormOfIdentificationOptions.filter((option) => selectionArray.includes(option.value)).map((op) => op.label)
        return docOps.join(', ')
      case ('programApplyingFor'):
        let programOps = programOptions.filter((option) => selectionArray.includes(option.value)).map((op) => op.label)
        return programOps.join(', ')
      default:
        return selectionArray;
    }
  }

  const getRequiredGenAndIncomeFileTypes = (appData) => {
    let requiredFileTypes = [];
    // add the completed form
    requiredFileTypes.push({ value: 'filledForm', label: 'Completed Application',})
    requiredFileTypes.push(...getGeneralFileTypes(appData));
    requiredFileTypes.push(...getUnearnedIncomeFileTypes(appData));
    appData?.employers?.forEach(employer => requiredFileTypes = requiredFileTypes.concat(getIncomeFileTypes(appData, employer)));
    return requiredFileTypes;
  }

  /**
   * Builds json template of content for the review page.
   * 
   * @returns 
   */
  const buildReviewTemplate = () => {
    let result = []
    let documents = []

    //build Application Summary section
    let appSummary = {
      title: "Application Summary",
      fields: buildSummaryFields(application, appId)
    };
    result.push(appSummary);

    //build Application Information Review section
    let appGenInfo = {
      title: application?.firstName ? `${application?.firstName} ${application?.lastName}'s Information` : "Applicant's Information",
      fields: buildFields(application, "applicantGeneralInformation", appId, "", application.prevSubmittal)
    };
    result.push(appGenInfo);

    let appIncomeInfo = {
      title: application?.firstName ? `${application?.firstName} ${application?.lastName}'s Financial Situation` : "Applicant Financial Situation",
      fields: buildFields(application, "applicantIncomeInformation", appId, "", application.prevSubmittal)
    };
    result.push(appIncomeInfo);

    let appDocs = buildDocumentSection(getRequiredGenAndIncomeFileTypes(application), application, "", application.prevSubmittal);
    documents.push(appDocs)

    //build Household Information Review section
    if(application?.householdMembers && application?.householdMembers.length > 0){
      for (const member of application?.householdMembers) {

        setShowSSN((prevState) => {
          const alreadyInState = prevState.filter((el) => el.id === member.id)
          if(alreadyInState.length === 0){
            prevState.push({id: member.id, visible: false}); 
          }
          return prevState
        })


        let prevMemberData ={}
        if (!isEmptyObject(application.prevSubmittal)){
          prevMemberData = ""
          if (application.prevSubmittal.householdMembers && application.prevSubmittal.householdMembers.length > 0) {
            prevMemberData = application.prevSubmittal.householdMembers.find(el => el.id === member.id)
          }
        }

        let memberGenInfo = {
          title: member.firstName ? `${member.firstName} ${member.lastName}'s Information` : "Household Member Information",
          id: member.id,
          name: `${member.firstName} ${member.lastName}`,
          fields: buildFields(member, "memberGeneralInformation", appId, member.id, prevMemberData)
        };
        result.push(memberGenInfo);

        let memberIncomeInfo = {
          title: member.firstName ? `${member.firstName} ${member.lastName}'s Financial Situation` : "Household Member Financial Situation",
          id: member.id,
          name: `${member.firstName} ${member.lastName}`,
          fields: buildFields(member, "memberIncomeInformation", appId, member.id, prevMemberData),          
        };
        result.push(memberIncomeInfo);

        let memberDocs = buildDocumentSection(getRequiredGenAndIncomeFileTypes(member), member, member.id, application.prevSubmittal);
        documents.push(memberDocs)
      }
    }

    //build Utility Information Review section
    let utilityInfo = {
      title: "Utility Information",
      fields: buildFields(application, "utilityGeneralInformation", appId, "", application.prevSubmittal),
    };
    result.push(utilityInfo);

    let utilityDocs = buildDocumentSection(getUtilityFileTypes(application), application, "", application.prevSubmittal);
    documents.push(utilityDocs)

    //build Additional Documents section
    let additionalDocs = buildDocumentSection(getAdditionalFileType(), application, "", application.prevSubmittal)
    documents.push(additionalDocs)

    let requiredAdditional = buildRequiredAdditionalDocumentSection(application, application.prevSubmittal)
    documents.push(requiredAdditional)

    let docs = {
      title: "Documents",
      fields: documents.flat()
    };
    result.push(docs);

    return result;
  }

  /**
   * Iterates over built review template to display content
   * 
   * @returns 
   */
  const renderReviewInfo = () => {
    return (
      <Grid container>
        <Grid item container xs={8} justifyContent="flex-start" className={classes.pdfActions}>
          <SaveMergedPDF appData={application}/>
          <PrintMergedPDF appData={application}/>
        </Grid>
        <Grid item container xs={4} justifyContent="flex-end">
          <Button
            className={classes.openAllButton}
            color="primary"
            onClick={() => setOpenAll((prevState) => {return !prevState})}
            variant="text"
          >
            {openAll ? 'Close All' : 'Open All'}
          </Button>
        </Grid>
        <Grid item xs={12} className={classes.accordion}>
          {reviewTemplate.map((info, index) => (
            <ReviewSubSection 
              key={index} 
              info={info} 
              showSSN={showSSN} 
              setShowSSN={setShowSSN}
              openAll={openAll}
            />
          ))}
          <>
            {renderReviewBenefits()}
          </>
        </Grid>
      </Grid>
    )
  }

  return (
    <Container className={classes.mainContainer}>
      <Typography variant='h6' className={classes.header}>
        {`Application Details for ${application?.firstName} ${application?.lastName}`}
      </Typography>
      <Grid container>
        { isEntergyUser && <AccountSummary application={application}/>}
        {renderReviewInfo()}
      </Grid>
    </Container>
  )
}
