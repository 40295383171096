export const programOptions = [
    {
      value: 'energyAssistance',
      label: 'Energy Assistance'
    },
    {
      value: 'weatherization',
      label: 'Weatherization'
    },
    {
      value: 'rise',
      label: 'Rise (Case Managment)'
    }, 
    {
      value: 'headStart',
      label: 'Head Start'
    },
    {
      value: 'water',
      label: 'Water'
    }
]

