import {
  Button,
  IconButton,
  Link,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react'
import { FileUploadOutlined, DeleteOutlined } from '@mui/icons-material'
import { truncateForDisplay } from '../utils/utilFunctions';
import ConfirmationDialog from './ConfirmationDialog';


const useStyles = makeStyles((theme) => ({
  hidden: {
    display: "none"
  },
  docLink:{
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  endAlignemnt:{
    textAlign: 'end'
  },
  startAlignemnt:{
    textAlign: 'start'
  },
  upload: {
    textTransform: "unset !important",
    fontWeight: "bold",
    marginBottom: '1rem'
  }
}));

export default function FileUpload({ fileId, createHandler, deleteHandler, fileType, fileTypeLabel, existingFile, employerId = '', memberId }) {
  const classes = useStyles();
  const theme = useTheme();
  const matchesXsDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const fileInputChanged = (e, fileType, fileTypeLabel, employerId, memberId) => {
    const createData = {
      "files": e.target.files, 
      "fileId": fileId, 
      "fileType": fileType, 
      "fileTypeLabel": fileTypeLabel, 
      "employerId": employerId,
      "memberId": memberId ?? "",
      "createDate": new Date()
    }
    
    createHandler(createData);
  }
  
  const fileDelete = (fileId, fileFolder) => {
    deleteHandler({ 
      fileId, 
      fileFolder,
      "createDate": new Date()
    });
  }

  const fileNameDisplay = (file) => {
    let displayFileName = file?.originalFileName ? file.originalFileName :file.fileName;
    displayFileName = truncateForDisplay(displayFileName, 20);
    return displayFileName;
  }

  const renderUploadButtons = (fileType, file, employerId) => {
    if(!file) {
      const fileInputId = employerId ? `${fileType}_${employerId}` : fileType;
      return (
        <form>
          <Grid container item className={matchesXsDown ? classes.startAlignemnt : classes.endAlignemnt}>
            <Grid item xs={12} >
                <input className={classes.hidden}
                  id={fileInputId}
                  type="file" 
                  accept=".txt,.pdf,.png,.jpg,.jpeg,image/*" 
                  name={fileType} 
                  label="Select A File" 
                  onChange={(e) => { fileInputChanged(e, fileType, fileTypeLabel, employerId, memberId); }} 
                />
              
              <label htmlFor={fileInputId}>
                <Button variant="contained" color="primary" component="span" size='large' className={classes.upload} startIcon={<FileUploadOutlined color='#fff' />}>
                  Add a File
                </Button>
              </label>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='subtitle2' color="textSecondary">Accepted Formats: PDF, JPG, PNG, TXT</Typography>
            </Grid>
          </Grid>
        </form>   
      )
    }
    else {
      return (
        <>
          <Grid container item className={matchesXsDown ? classes.startAlignemnt : classes.endAlignemnt}>
            <Grid item xs={12} >
              <Button component={Link} variant="outlined" href={`/api/file/${file.url}`} target="__new" className={classes.docLink}>{fileNameDisplay(file)}</Button>
                <IconButton
                  aria-label="delete"
                  onClick={() => setDeleteConfirmationOpen(true)}
                  size="large">
                  <DeleteOutlined color='primary'/>
                </IconButton>
            </Grid>
            <Grid item xs={12} >
              <Typography variant='subtitle2' color="textSecondary">Accepted Formats: PDF, JPG, PNG, TXT</Typography>
            </Grid>
          </Grid>
          <ConfirmationDialog 
            open={deleteConfirmationOpen}
            title="Remove File"
            desc={`Are you sure you want to remove this file?`}
            handleCancel={() => setDeleteConfirmationOpen(false)}
            handleOk={() => fileDelete(file.id, file.fileFolder)}
          />
        </>);
    }
  }

  return (
    renderUploadButtons(fileType, existingFile, employerId) 
  )
}
