import { Grid, CssBaseline, IconButton, InputAdornment, Container, Button, CircularProgress, Link, TextField, Typography } from '@mui/material'
import React, { useState, useContext } from 'react'
import { Redirect } from 'react-router-dom';
import { userRegister } from '../../api/UserAPI';
import makeStyles from '@mui/styles/makeStyles';
import Avatar from '@mui/material/Avatar';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import { Visibility, VisibilityOff, Check, Clear } from '@mui/icons-material';
import 'react-phone-input-2/lib/material.css';
import ReactPhoneInput from 'react-phone-input-material-ui';
import InputMask from "react-input-mask";
import Box from '@mui/material/Box';
import ConfirmUser from './confirm';
import { AuthContext } from '../../context/authContext';
import { arkansasCountyOptions } from '../../constants/ar/dropdowns/arkansasCountyOptions';
import FeatureFlag from '../FeatureFlag';
import MessageBanner from '../MessageBanner';
import { texasCountyOptions } from '../../constants/tx/dropdowns/texasCountyOptions';
import { registerStateOptions } from '../../constants/registerStateOptions';
import { useErrorViewer } from '../../context/errorContext';

const useStyles = makeStyles((theme) => ({
    successInput: {
        color: theme.palette.success.main
    },
    errorInput: {
        color: theme.palette.error.main
    },
    grayInput: {
        color: theme.customColors.lightMedGray
    },
    congtxtinput: {
        textAlign: "center",
        letterSpacing: "0.7em",
        fontSize: 20
    },
    confcodemuifield: {
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    },
    paper: {
        marginTop: theme.spacing(5),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: "center",
        background: "#ffffff",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.dark,
        fontWeight: "bolder"
    },
    link: {
        margin: theme.spacing(1),
        color: theme.palette.primary.dark,
    },
    phone: {
        margin: '1rem 0',
        '& > .react-tel-input':{
            '& > .MuiFormControl-root':{
                '& > .MuiOutlinedInput-root': {
                    height: '3rem'
                }
            }
        }
    },
    phoneSubtext: {
        color: theme.customColors.lightMedGray,
        textAlign: 'start'
    },
    form: {
        width: 'auto', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    passwordReqs: {
        alignItems: 'flex-start'
    },
    reqs: {
        display:'flex'
      },
      icon: {
        height: '1rem',
        marginTop: '.2rem'
      },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));
export default function Register() {
    const [formData, setFormData] = useState({
        email: "", password: "", confirmPassword: "", phone: "", birthday: "",
        firstName: "", lastName: "", middleName: "", confirmCode: "",
        serviceStreetAddress: "", serviceApartmentNumber: "", serviceCity: "",
        serviceState: "", serviceZip: "", serviceCountyOrParish: ""
    });

    const [showConfirmation, setShowConfirmation] = useState(false);
    const [loading, setLoading] = useState(false);
    const [navToLogin, setNavToLogin] = useState(false);
    const [showPASS, setShowPASS] = useState(false);
    const setError = useErrorViewer();

    const [eightCharError, setEightCharError] = useState(false);
    const [uppercaseError, setUppercaseError] = useState(false);
    const [lowercaseError, setLowercaseError] = useState(false);
    const [numberError, setNumberError] = useState(false);
    const [specialCharError, setSpecialCharError] = useState(false);

    const defaultCountyOptions = [{label: '', value: ''}];
    const [countyOptions, setCountyOptions] = useState(defaultCountyOptions);

    const auth = useContext(AuthContext);

    const isAgent = (userInfo) => {
        return (userInfo["userType"] === "agency");
    }

    const classes = useStyles();

    const getInputClasses = (val, isErr) => {
        return `${classes.reqs} ${val ? isErr ? classes.successInput : classes.errorInput : classes.grayInput}`
    }

    const handleChange = (event) => {
        let key = event.target.name;
        let value = event.target.value;
        if(key === 'serviceState'){
            let countyOptions = defaultCountyOptions;
            if (value === 'TX'){
                countyOptions = countyOptions.concat(texasCountyOptions);
            } else if (value === 'AR'){
                countyOptions = countyOptions.concat(arkansasCountyOptions);
            }
            setCountyOptions(countyOptions);
        }
        if(key === 'password'){
            let regex1 = /^.{8,}$/;
            setEightCharError(regex1.test(value));
            
            let regex2 = /^.*(?=.*[A-Z]).*$/;
            setUppercaseError(regex2.test(value));
            
            let regex3 = /^.*(?=.*\d).*$/;
            setNumberError(regex3.test(value));
            
            let regex4 = /^.*(?=.*[!$@%]).*$/;
            setSpecialCharError(regex4.test(value));

            let regex5 = /^.*(?=.*[a-z]).*$/;
            setLowercaseError(regex5.test(value));
        }
        setFormData({
            ...formData,
            [key]: value
        });
      };

    const handleMouseDownPASS = (event) => {
        event.preventDefault();
    };

    const validateRequired = () => {
        return (formData.email
            && formData.phone
            && formData.firstName
            && formData.lastName
            && formData.password
            && formData.serviceStreetAddress
            && formData.serviceCity
            && formData.serviceState
            && formData.serviceCountyOrParish
            && formData.serviceZip
        );

    }

    //https://www.w3resource.com/javascript/form/email-validation.php
    const validateEmailFormat = (email) => {
        const regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:|\\)*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:|\\)+)\])/;
        return regex.test(email)
    }

    const validatePhone = (phone) => {
        const regex = /^\(?\d{3}[.)-]?\s*\d{3}[-.\s]?\d{4}$/
        return regex.test(phone);
    }

    const validatePassword = (password) => {
        const regex = /^.*(?=.{8,50})(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!$@%]).*$/
        return regex.test(password);
    }

    const validateZip = (zip) => {
        return zip?.length === 5;
    }

    const enableSignUp = () => {
        return validateRequired()
            && validatePassword(formData.password)
            && validatePhone(formData.phone)
            && validateEmailFormat(formData.email)
            && validateZip(formData.serviceZip)
    }

    const handleSubmit = () => {
        setLoading(true);
        userRegister(formData).then(() => {
            setShowConfirmation(true);
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            if (error?.response?.data === 'Customer location is not supported') {
                setError('Our LIHEAP digital platform is not yet available in your area. Please contact your local agency for a paper application.')
            }else if (error?.response?.data === 'Applicant registration is not allowed'){
                setError('Registrations are currently not allowed.')
            }
            else{
                const err = error.response.data? error.response.data.message : "Unknown Error";
                setError(err);
            }
        });
    }

    const confirmSuccess = () => {
        setShowConfirmation(false);
        setNavToLogin(true);
    }

    if(auth["userInfo"]) {
        if(isAgent(auth["userInfo"]))
            return (<Redirect to="/agency/home"></Redirect>)
        else
            return (<Redirect to="/dashboard"></Redirect>)
    }
    if (navToLogin)
        return (
            <Redirect to="/login"></Redirect>
        )
    if (showConfirmation)
        return (
            <ConfirmUser userName={formData.email} phone={formData.phone} confirmSuccess={confirmSuccess} />
        )
    else
        return (
            <>
            <Grid xs={12} item container justifyContent="center" direction="column"> 
                <MessageBanner messageType="Register" />
            </Grid>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                {/* Global check*/}
                <FeatureFlag feature="Register" hideOnDisabled={true}>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <FolderSharedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign up
                    </Typography>
                    <form className={classes.form} noValidate>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    required
                                    name="firstName"
                                    label="First Name"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    inputProps={{
                                        maxLength: 20,
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    required
                                    name="lastName"
                                    label="Last Name"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    inputProps={{
                                        maxLength: 20,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    required
                                    name="email"
                                    label="E-mail Address"
                                    inputMode="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                //helperText="*This will be your username"
                                />
                            </Grid>

                            <Grid item xs={12} >
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    required
                                    name="serviceStreetAddress"
                                    label="Street Address"
                                    value={formData.serviceStreetAddress}
                                    onChange={handleChange}
                                    inputProps={{
                                        maxLength: 200,
                                    }}
                                />
                            </Grid>
                            
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth                                    
                                    name="serviceApartmentNumber"
                                    label="Apartment Number"
                                    value={formData.serviceApartmentNumber}
                                    onChange={handleChange}
                                    inputProps={{
                                        maxLength: 20,
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    required
                                    name="serviceCity"
                                    label="City"
                                    value={formData.serviceCity}
                                    onChange={handleChange}
                                    inputProps={{
                                        maxLength: 100,
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    select
                                    fullWidth
                                    required
                                    name="serviceState"
                                    label="State"
                                    value={formData.serviceState}
                                    onChange={handleChange}
                                    SelectProps={{
                                      native: true,
                                    }}
                                    InputProps={{
                                        readOnly: true
                                      }}
                                >
                                    {registerStateOptions.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </TextField>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <InputMask mask="99999" maskPlaceholder="" value={formData.serviceZip} onChange={handleChange}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    required
                                    name="serviceZip"
                                    label="Zip"
                                    value={formData.serviceZip}
                                    onChange={handleChange}
                                    //error={zipCode && !validZipCode}
                                    //helperText={getZipCodeErrorMessage()}
                                    inputProps={{
                                        maxLength: 5,
                                    }}
                                />
                                </InputMask>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    required
                                    select
                                    name="serviceCountyOrParish"
                                    label="County or Parish"
                                    value={formData.serviceCountyOrParish}
                                    onChange={handleChange}
                                    SelectProps={{
                                        native: true,
                                      }}
                                >
                                    {countyOptions.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} className={classes.phone}>
                                <ReactPhoneInput
                                    name="phone"
                                    disableDropdown
                                    disableCountryCode
                                    //countryCodeEditable='false'
                                    onlyCountries={['us']}
                                    inputStyle={{ width: '100%'}}
                                    id="phone"
                                    placeholder="(000) 000-0000"
                                    country={'us'}
                                    specialLabel='Cell Phone'
                                    variant="outlined"
                                    margin="normal"
                                    value={formData.phone}
                                    component={TextField}
                                    onChange={event =>
                                        setFormData({
                                            ...formData,
                                            'phone': event
                                        })}
                                    inputProps={{
                                        required: true,
                                    }}
                                />
                                <Typography variant="subtitle2" className={classes.phoneSubtext}>
                                    A confirmation code will be sent to this cell phone number.
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type={showPASS ? 'text' : 'password'}
                                id="password"
                                onChange={handleChange}
                                value={formData.password}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPASS(!showPASS)}
                                                onMouseDown={handleMouseDownPASS}
                                                edge="end"
                                                size="large">
                                                {showPASS ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />
                        </Grid>
                        <Grid item container direction="column" className={classes.passwordReqs}>
                            <Grid item className={getInputClasses(formData.password, eightCharError)}>
                                {formData.password && eightCharError && <Check className={classes.icon}/>}
                                {formData.password && !eightCharError && <Clear className={classes.icon}/>}
                                <Typography variant="subtitle2">
                                At least 8 characters
                                </Typography>
                            </Grid>
                            <Grid item className={getInputClasses(formData.password, lowercaseError)}>
                                {formData.password && lowercaseError && <Check className={classes.icon}/>}
                                {formData.password && !lowercaseError && <Clear className={classes.icon}/>}
                                <Typography variant="subtitle2">
                                    At least one lowercase letter (a-z)
                                </Typography>
                            </Grid>
                            <Grid item  className={getInputClasses(formData.password, uppercaseError)}>
                                {formData.password && uppercaseError && <Check className={classes.icon}/>}
                                {formData.password && !uppercaseError && <Clear className={classes.icon}/>}
                                <Typography variant="subtitle2">
                                    At least one uppercase letter (A-Z)
                                </Typography>
                            </Grid>
                            <Grid item  className={getInputClasses(formData.password, numberError)}>
                                {formData.password && numberError && <Check className={classes.icon}/>}
                                {formData.password && !numberError && <Clear className={classes.icon}/>}
                                <Typography variant="subtitle2">
                                    At least one number (0-9)
                                </Typography>
                            </Grid>
                            <Grid item  className={getInputClasses(formData.password, specialCharError)}>
                                {formData.password && specialCharError && <Check className={classes.icon}/>}
                                {formData.password && !specialCharError && <Clear className={classes.icon}/>}
                                <Typography variant="subtitle2">
                                    At least one special character (!$@%)
                                </Typography>
                            </Grid>
                        </Grid>
                        {loading ? <CircularProgress color="primary" size={36} /> :
                            <Button
                                className={classes.submit}
                                fullWidth
                                id="submit"
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={!enableSignUp()}
                                onClick={handleSubmit}>
                                Sign up
                            </Button>
                        }
                        <Grid container justifyContent="center">
                            <Grid item>
                                <Link href="/login" variant="body2">
                                    Already have an account? Sign in
                                </Link>
                            </Grid>
                            <Box mt={3}>
                                <Typography variant="body2">
                                    By continuing, you agree to Entergy's{' '}
                                    <Link href="/termsOfUse" variant="body2">                                    
                                        {"Terms of Service"}
                                    </Link>
                                    {' '}and acknowledge you've read our {' '}
                                    <a href="https://www.entergy.com/privacy-policy/" target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
                                        Privacy Policy
                                    </a>
                                </Typography>
                            </Box>
                        </Grid>
                    </form>
                </div>
                </FeatureFlag>
            </Container>
            </>
        );
}
