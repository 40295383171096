import { Grid, Typography,Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ExpandMore } from '@mui/icons-material'
import React, { useState, useEffect } from 'react'
import { format} from 'date-fns'

const useStyles = makeStyles((theme) => ({
    accordion:{
        width: '100%',
        '& > .MuiAccordionSummary-root':{
            padding: '0 1.5rem',
            '& > .MuiAccordionSummary-content':{
                marginBottom: '2rem',
            },
            '& > .Mui-expanded':{
                margin: 0
            },
            '& > .MuiAccordionSummary-expandIconWrapper':{
                alignSelf: 'flex-end',
            }
        }
    },
    expandIcon: {
        color: theme.palette.primary.main,
        padding: '.75rem'
    },
    details: {
        display: 'flex',
        justifyContent: 'end',
        paddingRight: '3rem'
    },
    providerList: {
        padding: 0,
        margin: 0
    }
}));

export default function ExportHistory({ history, openAll }) {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const expandable = Boolean(history.providerAmounts);

    const providerAmounts = history.providerAmounts ? history.providerAmounts : []

    const formatDate = function(str, days) {
        var myDate = new Date(str);
        myDate.setDate(myDate.getDate() - parseInt(days));
        return myDate.toLocaleDateString()
      }

    const setExpandedValue = () => {
        if(expandable){setExpanded(prevState => {return !prevState})}
    }

    useEffect(() => {
        if(expandable){
            setExpanded(openAll)
        }
      // eslint-disable-next-line
    }, [openAll]);

    return (
        <Accordion expanded={expanded} className={classes.accordion}>
            <AccordionSummary
                expandIcon={expandable ? <ExpandMore className={classes.expandIcon}/> : <div style={{padding: '1.5rem'}}></div>}
                onClick={setExpandedValue}
            >
                <Grid item xs={12}>
                    <Typography variant="subtitle1" fontWeight='bold'> {`Export ID# : ${history.id.slice(-4)}`}</Typography>
                    <Grid container >
                        <Grid item xs={8} xl={9} textAlign={'left'}>
                            <Typography variant="subtitle1">
                                {`Report generated by: ${history.userFullname}` }
                            </Typography>
                        </Grid>
                        <Grid item xs={4} xl={3} textAlign={'left'}>
                            <Typography variant="subtitle1">
                                {`Total exported applications: ${history.count}`}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container >
                        <Grid item xs={8} xl={9} textAlign={'left'}>
                            <Typography variant="subtitle1">
                                {`Export created on: ${new Date(history.createdDate).getMonth() + 1}/${new Date(history.createdDate).getDate()}/${new Date(history.createdDate).getFullYear()}  ${format(new Date(history.createdDate), 'h:mm a')}` }
                            </Typography>
                        </Grid>
                        <Grid item xs={4} xl={3} textAlign={'left'}>
                            <Typography variant="subtitle1">
                                {history?.grandTotal ? `Grand total: $${history?.grandTotal}` : ''}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container >
                        <Grid item xs={8} xl={9} textAlign={'left'}>
                            <Typography variant="subtitle1">
                                {`Export date range: ${(new Date(history.dateRangeStart)).toLocaleDateString()} - ${formatDate(history.dateRangeEnd, 1)}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} xl={3} textAlign={'left'}>
                            <Typography variant="subtitle1">
                                {history?.providerAmounts ? `Breakdown by vendors` : ''}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
                <Grid item xs={4} xl={3} textAlign={'left'}>
                    <ul className={classes.providerList}> 
                    {providerAmounts.map((pa, index) => (
                        <li key={index}>
                            <Typography variant="subtitle1">
                                {`${pa.provider}: $${pa.amount}`}
                            </Typography>
                        </li>
                    ))}
                    </ul>
                </Grid>
            </AccordionDetails>
        </Accordion>
        );
}
