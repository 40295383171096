
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Reports from '../components/agency/pages/Reports';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    marginLeft: '4rem',
    padding: "2rem",
    width: "95%"
  }
}));

export default function AgencyReports() {
  const classes = useStyles();

  return (
    <Grid xs={12} item container justifyContent="flex-start" spacing={1} direction="column" className={classes.mainContainer}>
      <Reports/>
    </Grid>
  );
}
