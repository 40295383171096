import { Typography, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AuthContext } from "../../context/authContext";
import React, { useContext } from 'react'
import { LocationOn, Phone, Mail, Send, WatchLater } from '@mui/icons-material'

const useStyles = makeStyles((theme) => ({
  headerSection: {
    margin: '1rem 0'
  },
  header: {
    fontSize: '.875rem',
    fontWeight: '600',
  },
  infoSection: {
    margin: '.5rem 0'
  },
  icon: {
    color: theme.customColors.deepBlue,
    height: '1.25rem',
    width: '1.25rem',
    marginRight: '.5rem'
  }
}));

export default function AgencyInfo() {
  const classes = useStyles();
  const { userInfo } = useContext(AuthContext);
  const agencyInfo = userInfo?.agencyInfo;

const getFormattedTime = (time) => {
    let [hours, minutes] = time.split(':');
    let amOrPm = ''

    if (hours > 12) {
      hours =  parseInt(hours - 12);
      amOrPm = 'p.m.'
    }
    else{
      hours = parseInt(hours)
      amOrPm = 'a.m.'
    }
  
    return `${hours}:${minutes} ${amOrPm}`;
  }

  const getFormattedWorkDays = (daysOpen) => {
    let daysOfWeek = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"]

    let firstDay = daysOpen[0];
    let daysLeft = daysOfWeek.splice(daysOfWeek.indexOf(firstDay))
    let lastDay = ''
    let displayString = `${firstDay}`;
    
    daysOpen.forEach((day, index) => {
      //if streak of days open is consecutive 
      if(day === daysLeft[index]){
        lastDay = day
        if(index !== 0 && index+1 === daysOpen.length){
          displayString += ` - ${day}`
        }
      }
      //a day of the week was skipped in daysOpen, end the last segment of consecutive days and start new segment 
      else{
        if(lastDay === firstDay){
          displayString += `, ${day}`
        }
        else{
          displayString += ` - ${lastDay}, ${day}`
        }
        firstDay = day
        lastDay = day
        daysLeft.splice(index, 1)
      }
    })
    return displayString
  }
  
  return (
<>
<Grid className={classes.headerSection}>
    <Typography variant="inherit" className={classes.header}>
      Your Agency:
    </Typography>
    {agencyInfo?.name &&
      <Typography variant="subtitle2">
        {agencyInfo?.name}
      </Typography>
    }
  
  </Grid>
          
      {agencyInfo?.physicalAddress &&
        <Grid container className={classes.infoSection}>
          <LocationOn className={classes.icon}/>
          <Grid>
            <Typography className={classes.header}>
              Location
            </Typography>
            <Typography variant="subtitle2">
              {`${agencyInfo?.physicalAddress}, ${agencyInfo?.physicalCity}, ${agencyInfo?.physicalState} ${agencyInfo?.physicalZip}`}
            </Typography>
          </Grid>
        </Grid>
      }

      {agencyInfo?.phone &&
        <Grid container className={classes.infoSection}>
          <Phone className={classes.icon}/>
          <Grid>
            <Typography className={classes.header}>
              Phone
            </Typography>
            <Typography variant="subtitle2">
              {agencyInfo?.phone}
            </Typography>

          </Grid>
        </Grid>
      }

      {agencyInfo?.mailingAddress &&
        <Grid container className={classes.infoSection}>
          <Mail className={classes.icon}/>
          <Grid>
            <Typography className={classes.header}>
              PO Box
            </Typography>
            <Typography variant="subtitle2">
            {`${agencyInfo?.mailingAddress}, ${agencyInfo?.mailingCity}, ${agencyInfo?.mailingState} ${agencyInfo?.mailingZip}`}
            </Typography>
          </Grid>
        </Grid>
      }
      
      {agencyInfo?.email &&
        <Grid container className={classes.infoSection}>
          <Send className={classes.icon}/>
          <Grid>
          <Typography className={classes.header}>
              Email
            </Typography>
            <Typography variant="subtitle2">
              {agencyInfo?.email}
            </Typography>
          </Grid>
        </Grid>
      }
      
      {agencyInfo?.officeHours &&
        <Grid container className={classes.infoSection}>
          <WatchLater className={classes.icon}/>
          <Grid>
          <Typography className={classes.header}>
              Office Hours
            </Typography>
            {agencyInfo?.officeHours.map((officeHour, index) => 
              <Typography variant="subtitle2" key={index}>
                {`${getFormattedTime(officeHour.startTime)} to ${getFormattedTime(officeHour.endTime)} ${officeHour.timezone}, ${getFormattedWorkDays(officeHour.workDays)}`}
              </Typography>
            )}
          </Grid>
        </Grid>
      }

    </>
  )
}
