import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { FormControl, Radio, RadioGroup, FormControlLabel, Typography, Grid } from '@mui/material';
import RadioIcon from '../../../../RadioIcon';
import FormTextField from '../../../../form/FormTextField';
import { Controller } from 'react-hook-form';
import FormMultiSelect from '../../../../form/FormMultiSelect';

const useStyles = makeStyles((theme) => ({
  outlinedInput: {
    marginTop: '.5rem',
    backgroundColor: '#FFF',    
    "& > .MuiFormHelperText-root.Mui-error": { 
      margin:0
    },
  },
  inlineInput: {
    marginBottom: '.6rem'
  },
  option: {
    display: 'flex',
    alignItems: 'baseline'
  },
  radioButton: {
    marginRight: 0
  },
  label: {
    fontWeight: "bold", 
    marginBottom: ".6rem",
    display: 'inline',
  },
}));

export default function DeniedStatusInfo({ application, control, register, errors, loading, deniedReason, onRadioSelect, setValue, trigger, policyOptions}) {
  const classes = useStyles();

  const onPolicySelect = (values) => {
    setValue("deniedPolicyNumber", values, { shouldDirty: true });
    trigger("deniedPolicyNumber");
  }

  const [clearAllMultiSelect, setClearAllMultiSelect] = useState(false);

  return (                      
  <FormControl>
    <Controller
      control={control}
      name='selectedDeniedReason'
      defaultValue={deniedReason}
      value={deniedReason}
      as={
        <RadioGroup >                    
          <Grid container spacing={1}>
            <Grid item xs={12} className={classes.option}>
              <Grid item>
                <FormControlLabel 
                  className={classes.radioButton}
                  disabled={loading}  
                  value="withdrawal" 
                  control={
                    <Radio 
                      onClick={() => {
                        onRadioSelect('withdrawal'); 
                        setClearAllMultiSelect(true);
                      }} 
                      checkedIcon={<RadioIcon />}/>
                    } 
                  />
              </Grid>
              <Grid item xs={11}>
                <Typography className={classes.label} variant='subtitle2' >
                  Denied due to your request to withdraw (policy #4040).
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.option}>
              <Grid item>
                <FormControlLabel 
                  className={classes.radioButton} 
                  disabled={loading} 
                  value="other" 
                  control={
                    <Radio 
                      onClick={() => {
                        onRadioSelect('other');
                        setClearAllMultiSelect(false);
                      }} 
                      checkedIcon={<RadioIcon />}
                    />
                  }
                /> 
              </Grid>
              <Grid item xs={11}>
                <Typography className={classes.label} variant='subtitle2' >
                  Denied based on Policy # 
                </Typography>
                <Grid className={classes.inlineInput} item xs={12} 
                  onClick={() => {
                    onRadioSelect('other'); 
                    setClearAllMultiSelect(false);
                    }} 
                  >
                    <FormMultiSelect 
                      disabled={loading}  
                      name="deniedPolicyNumber" 
                      label="Policy #"
                      defaultValue={application?.statusInfo?.deniedPolicyNumber}
                      control={control}
                      errors={errors}
                      options={policyOptions}
                      onSelect={onPolicySelect}
                      clearAllTrigger={clearAllMultiSelect}
                    />
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.label} variant='subtitle2' >
                    Specific reason for denied application:
                  </Typography>
                  <FormTextField
                    className={classes.outlinedInput}  
                    disabled={loading} 
                    name="deniedReasonSpecification" 
                    onClick={() => {
                      onRadioSelect('other'); 
                      setClearAllMultiSelect(false);
                    }}
                    label="Type message to applicant"
                    defaultValue={application?.statusInfo?.deniedReasonSpecification}
                    multiline
                    rows={4}
                    register={register} 
                    errors={errors}     
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </RadioGroup>
      }
    />
  </FormControl>
)}