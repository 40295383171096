import { Grid, Typography, Container, useMediaQuery, useTheme, Modal } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Info } from '@mui/icons-material'
import React, { useState } from 'react'

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
    };
}

const useStyles = makeStyles((theme) => ({
  header: {
    padding: "1.25rem 2.8rem",
    [theme.breakpoints.down('lg')]: {
      padding: '1rem'
    }
  },
  reviewScreenHeader: {
    padding: "1rem"
  },
  infoIcon: {
    color: theme.palette.primary.main,
    paddingBottom: '.75rem',
    paddingLeft: '.25rem'
  },
  modal: {
    position: 'absolute',
    background: theme.customColors.modalBackground,
    boxShadow: '0 0 .3rem rgba(0, 0, 0, 0.2)',
    boxSizing: 'inherit',
    borderRadius: '1.75rem',
    padding: '2rem',
    width: '80%'
  },
  modalHeader:{
    fontWeight: '600'
  },
  modalSection: {
    margin: '1rem 0'
  }
}));

export default function StepHeader({ title, desc, showInfo=false, reviewScreen=false }) {
  const classes = useStyles();
  const theme = useTheme();
  const matchesXsDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [statusInfoOpen, setStatusInfoOpen] = useState(false);
  const [modalStyle] = useState(getModalStyle);


  return (
    <>
    <Container className={reviewScreen ? classes.reviewScreenHeader : classes.header}>
      <Grid container item xs={12} justifyContent="flex-start" direction="column">
      {matchesXsDown && showInfo ? 
        <Typography onClick={() => setStatusInfoOpen(true)} variant="h2">
          {title}
          <Info className={classes.infoIcon} fontSize="small"/>
        </Typography>
        :
        <Typography variant="h2">{title}</Typography>
      }
        <Typography variant="subtitle1" color="textSecondary">{desc}</Typography>
      </Grid>
    </Container>

    <Modal open={statusInfoOpen} onClose={() => setStatusInfoOpen(false)}>
      <Grid style={modalStyle} className={classes.modal}>
        <Typography variant="h4" className={classes.modalHeader}>{`Application process`}</Typography>
        <Grid item className={classes.modalSection}>
          <Typography variant="h6" display="inline" className={classes.modalHeader}>{`Application: `}</Typography>
          <Typography variant="subtitle1" display="inline">{`Once submitted, your application will be sent to your agency.`}</Typography>
        </Grid>
        <Grid item className={classes.modalSection}>
          <Typography variant="h6" display="inline" className={classes.modalHeader}>{`Verification: `}</Typography>
          <Typography variant="subtitle1" display="inline">{`Application will be reviewed by your agency.`}</Typography>
        </Grid>
        <Grid item className={classes.modalSection}>
          <Typography variant="h6" display="inline" className={classes.modalHeader}>{`Pledge: `}</Typography>
          <Typography variant="subtitle1" display="inline">{`If approved, a payment will be applied to your utility account.`}</Typography>
        </Grid>
      </Grid>
    </Modal>
    </>
  )
}
