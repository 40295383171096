import { Button, CircularProgress, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'
import { useMutation } from 'react-query';
import { createApplicantPDF } from '../api/ApplicationsAPI';
import { useErrorViewer } from '../context/errorContext';
import { getMergedInProgressFileForApplication } from "../api/FileAPI"
import { GetApp } from '@mui/icons-material'
import { formatDate } from '../utils/utilFunctions';

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: 'none'
  },
  downloadIcon: {
    marginRight: '.5rem'
  }
}));

export default function SavePDF({ appData }) {
  const classes = useStyles();
  const appId = appData.id;
  const setError = useErrorViewer();

  const savePdfMutation = useMutation(appId => {
    return createApplicantPDF(appId);
  })

  const getFileName = () => {
    return `my_liheap_application_${formatDate(appData.createdDate, "MM_dd_yyyy")}`;
  }

  const downloadPDF = (encryptedUrl) => {
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = `/api/file/${encryptedUrl}`;
    a.download = getFileName();
    a.click();
    document.body.removeChild(a);
  }

  const onSavePDF = () => {
    savePdfMutation.mutate(appId, {
      onSuccess: (data) => {
        getMergedInProgressFileForApplication(appData?.id)
            .then((response) => {
              downloadPDF(response.data?.url)          
            })
            .catch((error) => {
                console.log(error); //TODO: show a generic error message
            });
      },
      onError: (error) => setError("An Error Occurred Saving The PDF") 
    })
  }

  if (savePdfMutation.isLoading) {
    return (
      <Grid container item justifyContent="flex-start" className={classes.spinner}>
        <CircularProgress color="primary" size={30} />
      </Grid>
    )
  }
  return (
    <Button
      className={classes.button}
      onClick={onSavePDF}
      variant="text"
      color="primary"
    >
      <GetApp className={classes.downloadIcon}/>
      Save PDF
    </Button>
)
}
