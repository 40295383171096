import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useState } from 'react'
import FormTextField from './FormTextField'
import InputMask from "react-input-mask";


export default function FormSSN({ name, label="Social Security Number", register, errors, validate, trigger, ...rest }) {

  const [showSSN, setShowSSN] = useState(false);

  const handleMouseDownSSN = (event) => {
    event.preventDefault();
  };

  const handleChange = () => {
    if(validate){
      trigger(name)
    }
  }

  return (
    <InputMask mask={'999999999'} onChange={handleChange} {...rest}>      
    <FormTextField
      autoComplete="no"
      name={name}
      label={label}
      register={register}
      errors={errors}
      type={showSSN ? 'text' : 'password'}
      InputProps={{
        endAdornment:
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle SSN visibility"
              onClick={() => setShowSSN(!showSSN)}
              onMouseDown={handleMouseDownSSN}
              edge="end"
              size="large">
              {showSSN ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
      }}
      
    />
    </InputMask>   

  );
}
