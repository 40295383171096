import React from 'react'
import { Container, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FormCheckboxes from '../../../form/FormCheckboxes';
import FormRadioAsButtons from '../../../form/FormRadioAsButtons';
import StepHeader from '../../../StepHeader';
import StepButtons from '../../../StepButtons';
import FormSection from '../../../FormSection';
import useLiheapForm from '../../../../hooks/useLiheapForm';
import { healthInsuranceTypeOptions } from '../../../../constants/tx/checkboxes/healthInsuranceTypeOptions';
import { educationLevelOptions } from '../../../../constants/tx/radios/educationLevelOptions';
import { useParams } from 'react-router';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    marginLeft: "0"
  },
  row: {
    width: "auto"
  },
  sectionHeader: {
    fontWeight: "bold"
  }
}));

export default function MemberAdditionalInfo({ data, back, next, save, validate, validationSchema }) {
  const { nestedItemId } = useParams();
  const classes = useStyles();
  const nestedSave= (formData) => {
    formData.id = nestedItemId;  //add nestedItemId to returned formData
    save(formData);
  }
  const memberData = data?.householdMembers?.find(x => x.id === nestedItemId);
  const { control, errors, register } = useLiheapForm(nestedSave, memberData, validate, validationSchema);

  const getTitle = () => {
    const itemIndex = memberData && (data?.householdMembers?.indexOf(memberData) >= 0) ? data?.householdMembers?.indexOf(memberData) + 1 : data?.householdMembers?.length + 1 || 1;
    return memberData && memberData.lastName && memberData.firstName ? `${memberData.firstName} ${memberData.lastName}` : `Household Member #${itemIndex}`;
  }
 
  return (
    <Container className={classes.mainContainer} component="main">
        <Grid container spacing={1}>
          <StepHeader 
            title={getTitle()} 
            desc = "Please provide the following information for this household member."
          />
          <FormSection>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.sectionHeader}>Additional Information</Typography>
            </Grid>
            <Grid className={classes.row} item xs={12} container justifyContent="flex-start">
              <FormCheckboxes
                defaultValues={memberData?.healthInsurance}
                register={register}
                name="healthInsurance"
                label="Health Insurance (more than one may be chosen)"
                errors={errors}
                options={healthInsuranceTypeOptions}
                gridSize={12}
                noOption="None"
              />
            </Grid>
            <Grid className={classes.row} item xs={12} sm={6} container justifyContent="flex-start">
              <FormRadioAsButtons 
                name="education" 
                label="Education"
                control={control}
                errors={errors}
                options={educationLevelOptions}
              />
            </Grid>
          </FormSection>
          <StepButtons back={back} next={next}/>
        </Grid>
    </Container>
  );
}
