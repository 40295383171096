import React, { useContext, useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { useQuery } from 'react-query';
import { StyledDataGrid } from '../../../StyledDataGrid';
import { getFullName, getPermissionOption, getSortComparatorFullName } from '../../../../utils/utilAgencyTableFunctions';
import { AuthContext } from '../../../../context/authContext';
import { getAgencyUsers } from '../../../../api/AgencyAPI';
import { reviewAppPermissionOptions } from '../../../../constants/dropdowns/reviewAppPermissionOptions';
import { clientIntakePermissionOptions } from '../../../../constants/dropdowns/clientIntakePermissionOptions';
import { agencyPermissions } from '../../../../constants/agencyPermissions';
import AdminPermissionSelect from './AdminPermissionSelect';
import AdminDisableUser from './AdminDisableUser';
import AdminRemoveUser from './AdminRemoveUser';
import { reportPermissionOptions } from '../../../../constants/dropdowns/reportPermissionOptions';
import AdminUserName from './AdminUserName';

const useStyles = makeStyles((theme) => ({
  searchResult: {
    color: theme.customColors.mediumGray
  },
  table: {
    minWidth: '75rem',
  },
  column: {
    fontWeight: '500',
    marginLeft: '.5rem',
    whiteSpace: 'break-spaces',
    maxHeight: 'none !important',
    lineHeight: '1.5rem',
  },
  cell: {
    fontWeight: '500',
    paddingLeft: '.5rem',
    width: '100%',
    whiteSpace: 'break-spaces',
    lineHeight: '1.5rem'
  }
}));

export default function AdminUserTable({ searchValue }) {
  const classes = useStyles();
  const { userInfo } = useContext(AuthContext);
  const agencyId = userInfo?.agencyId;

  const { data, isFetching } = useQuery(['users', {agencyId: agencyId}], async () => {
    const { data } = await getAgencyUsers();
    let filtered = searchAndFilterData(searchValue, data);
    setUsers(filtered);
    return data;
  }, {enabled: !!agencyId});

  const [users, setUsers] = useState(data);
  const [sortModel, setSortModel] = useState([{field: 'email', sort: 'desc'}]);

  const getColumns = () => {
    return [
      {
        field: 'name', valueGetter: getFullName, flex: .6, sortComparator: getSortComparatorFullName,
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Name</Typography>),
        renderCell: (params) => (<AdminUserName user={params.row}/>)
      },
      {
        field: 'email', flex: .6,
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Email</Typography>),
        renderCell: (params) => (<Typography variant='subtitle1' className={classes.cell}>{params.value}</Typography>)
      },
      {
        field: 'clientIntake', 
        valueGetter: (params) => getPermissionOption(params.row.permissions ? params.row.permissions : [], "ClientIntakeDashboard", "", clientIntakePermissionOptions), 
        flex: .5,
        sortComparator: permissionsSortComparator,
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Dashboard</Typography>),
        renderCell: (params) => (
          <AdminPermissionSelect 
            type="ClientIntakeDashboard"
            user={params.row}
            optionSelected={params.value}
            options={clientIntakePermissionOptions}
          />
        )
      },
      {
        field: 'application', 
        valueGetter: (params) => getPermissionOption(params.row.permissions ? params.row.permissions : [], "ReviewApplication", agencyPermissions.REVIEW_APPLICATION_NONE, reviewAppPermissionOptions),
        flex: .5,
        sortComparator: permissionsSortComparator,
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Application</Typography>),
        renderCell: (params) => (
          <AdminPermissionSelect
            type="ReviewApplication"
            user={params.row}
            optionSelected={params.value}
            options={reviewAppPermissionOptions}
          />
        )
      },
      {
        field: 'reports', 
        valueGetter: (params) => getPermissionOption(params.row.permissions ? params.row.permissions : [], "Reports", agencyPermissions.REPORTS_NONE, reportPermissionOptions),
        flex: .5,
        sortComparator: permissionsSortComparator,
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Reports</Typography>),
        renderCell: (params) => (
          <AdminPermissionSelect
            type="Reports"
            user={params.row}
            optionSelected={params.value}
            options={reportPermissionOptions}
          />
        )
      },
      {
        field: 'invitedByFullName', flex: .5,
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Invited By</Typography>),
        renderCell: (params) => (<Typography variant='subtitle1' className={classes.cell}>{params.value}</Typography>)
      },
      {
        field: 'disabled',
        flex: 'auto',
        sortable: false,
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Disable</Typography>),
        renderCell: (params) => (
          <AdminDisableUser
            user={params.row}
            value={params.row?.disabled}
          />
        )
      },
      {
        field: 'remove',
        flex: 'auto',
        sortable: false,
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Remove</Typography>),
        renderCell: (params) => (
          <AdminRemoveUser user={params.row} isActiveUser={true}/>
        )
      }       
    ];
  }

  const permissionsSortComparator = (v1, v2) => {
    return (v1.value).localeCompare(v2.value);
  }

  const SortedDescendingIcon = () => {
    return <KeyboardArrowDown style={{color: '#fff'}}/>;
  }

  const SortedAscendingIcon = () => {
    return <KeyboardArrowUp style={{color: '#fff'}}/>;
  }

  const handleSortChange = (model) => {
    if (JSON.stringify(sortModel[0]) !== JSON.stringify(model[0])){
      setSortModel(model)
    }
  }

  const searchAndFilterData = (searchValue, dataToFilter) => {
    let filtered = dataToFilter?.filter(item => {
        let fullName = item.firstName.toLowerCase() + item.lastName.toLowerCase();
        return (
            fullName.includes(searchValue.toLowerCase())
        )
        });
    return filtered;
  }

  useEffect(() => {
        let filtered = searchAndFilterData(searchValue, data);
        setUsers(filtered);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  return (
    <div className={classes.table}>
      <Grid item xs={12}>
        <StyledDataGrid
          rows={users ? users : []}
          columns={getColumns()}
          hideFooterSelectedRowCount
          hideFooterPagination
          disableSelectionOnClick
          disableColumnMenu
          loading={isFetching}
          components={{
            ColumnSortedDescendingIcon: SortedDescendingIcon,
            ColumnSortedAscendingIcon: SortedAscendingIcon,
          }}
          sortModel={sortModel}
          onSortModelChange={handleSortChange}
        />
      </Grid>
      <Grid item container xs={12} justifyContent="flex-end">
        <Typography variant='subtitle1' className={classes.searchResult}>
          Search Results: {users ? users.length : 0}
        </Typography>          
      </Grid>

    </div>
  );
}
