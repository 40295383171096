export const getDocumentFileNames = (fileTypeObj, fileData, memberId) => {

    const filesArr = getFilesByTypeAndEmployer(fileTypeObj.value, fileData, fileTypeObj.employerId, memberId)
    const fileNamesArr = filesArr.map((item) => (item.originalFileName? item.originalFileName: item.fileName));

    return fileNamesArr.join(", ");

  }

  export const getDocumentFileData = (fileTypeObj, fileData, memberId) => {
    const filesArr = getFilesByTypeAndEmployer(fileTypeObj.value, fileData, fileTypeObj.employerId, memberId)
    const fileDataArr = filesArr.map((item) => ({
        'fileName': item.originalFileName ? item.originalFileName: item.fileName, 
        'fileUrl': item.url,
        'fileType': item.fileType,
        'id': item.id,
    }))
    return fileDataArr
  }
  
export const validateDocuments = (requiredFiles, documentQueries, memberId="") => {
  let isValid = true;
  requiredFiles.forEach((f) => {
    const fileNames = getDocumentFileNames(f, documentQueries, memberId);
    if(!fileNames && (f.optional === undefined || f.optional === false)){
      isValid = false;
    }
  });
  return isValid;
}

export const getFilesByTypeAndEmployer = (fileType, fileData, employerId = "", memberId="") => {
  const { compare } = Intl.Collator('en-US');

  if(!fileData) return [];

  const filesArr = fileData.filter((item) => (
      item.fileType === fileType && 
      (item.employerId ?? '') === employerId && 
      (item.memberId ?? '') === memberId)).sort((a,b) => compare(a.createdDate, b.createdDate));

  return filesArr;
}
