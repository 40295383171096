import { useErrorViewer } from "../../../../context/errorContext";
import { useMutation, useQueryClient } from 'react-query';
import { updateUserDisabledStatus } from '../../../../api/AgencyAPI';
import { useContext, useState } from 'react';
import { Checkbox, Grid } from '@mui/material';
import ConfirmationDialog from "../../../ConfirmationDialog";
import { AuthContext } from "../../../../context/authContext";

export default function AdminDisableUser({user, value}){
  const queryClient = useQueryClient();
  const setError = useErrorViewer();
  const [currentValue, setCurrentValue] = useState(value ? value : false);
  const [disableConfirmationOpen, setDisableConfirmationOpen] = useState(false);
  const [enableConfirmationOpen, setEnableConfirmationOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { userInfo } = useContext(AuthContext);

  const disableConfirmationText = `${user?.firstName} ${user?.lastName}’s account will be disabled. Any applications without a decision assigned to ${user?.firstName} ${user?.lastName} will be un-assigned. The user can be re-enabled at any time. Are you sure you want to disable ${user?.firstName} ${user?.lastName}’s account?`;
  const enableConfirmationText = `${user?.firstName} ${user?.lastName}’s account will be enabled. Are you sure you want to enable ${user?.firstName} ${user?.lastName}’s account?`

  const saveDisabledStatusMutation = useMutation(({id, data}) => {
    return updateUserDisabledStatus(id, data);
  })

  const handleChange = (event) => {
    const newValue = event.target.checked;
    setCurrentValue(newValue);
    setDisableConfirmationOpen(newValue);
    setEnableConfirmationOpen(!newValue);
  }

  const handleCancel = () => {    
    setDisableConfirmationOpen(false);
    setEnableConfirmationOpen(false);
    setCurrentValue(!currentValue);   
  }

  const handleDisableOrEnable = () => {
    setIsLoading(true);
    const updateObject = {
      disabled: currentValue
    }
    
    saveDisabledStatusMutation.mutate(({id: user.id, data: updateObject}), {
      onSuccess: (response) => {
        setIsLoading(false);
        setDisableConfirmationOpen(false);
        setEnableConfirmationOpen(false);
        queryClient.invalidateQueries(['users']);
        queryClient.invalidateQueries(['assignableUsers']);        
      },
      onError: (error) => {
        setIsLoading(false);
        setDisableConfirmationOpen(false);
        setEnableConfirmationOpen(false);
        setCurrentValue(!currentValue);
        setError('Error saving user.');
      }
    })

  }

  return (
     <Grid container item xs={12} justifyContent="center" justifyItems="center" alignContent="center">
       {userInfo?.id !== user.id &&
        <>
        <Checkbox
          checked={currentValue}
          onChange={handleChange}
          inputProps={{ 'aria-label': 'controlled' }}
        /> 

        <ConfirmationDialog 
            open={disableConfirmationOpen}
            title={"Disable Account"}
            desc={disableConfirmationText}
            cancelLabel="No, cancel"
            okLabel={`Yes, disable`}
            handleCancel={() => handleCancel()}
            handleOk={() => handleDisableOrEnable()}
            isLoading={isLoading}
          /> 

        <ConfirmationDialog 
            open={enableConfirmationOpen}
            title={"Enable Account"}
            desc={enableConfirmationText}
            cancelLabel="No, cancel"
            okLabel={`Yes, enable`}
            handleCancel={() => handleCancel()}
            handleOk={() => handleDisableOrEnable()}
            isLoading={isLoading}
          />  
        </>
      }
     </Grid>
  )
}
