import { agencyPermissions } from "../agencyPermissions";

export const clientIntakePermissionOptions = [
  {
    value: agencyPermissions.CLIENT_INTAKE_DASHBOARD_EDIT,
    label: 'Edit'
  },
  {
    value: agencyPermissions.CLIENT_INTAKE_DASHBOARD_VIEW,
    label: 'View Only'
  }
];