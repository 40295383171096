export const workStatusOptions = [
    {
      value: 'fullTime',
      label: 'Full-Time'
    },
    {
      value: 'partTime',
      label: 'Part-Time'
    },
    {
      value: 'migrantSeasonalOrFarmWorker',
      label: 'Migrant, Seasonal or Farm Worker'
    },
    {
      value: 'unemployed',
      label: 'Unemployed'
    },
    {
      value: 'retired',
      label: 'Retired'
    }
  ]
  
  