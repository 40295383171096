import React, { useState, useContext } from 'react';
import Typography from '@mui/material/Typography';
import { AuthContext } from '../context/authContext';
import { Button, CircularProgress, Container, Grid, Snackbar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { createApplication, getApplications, getPreviousSeasonApplications } from '../api/ApplicationsAPI';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { Alert } from '@mui/material';
import { formatPhoneNumber } from "../utils/utilFunctions";
import StepHeader from '../components/StepHeader';
import FormSection from '../components/FormSection';
import AgencyInfo from '../components/liheap/AgencyInfo';
import MessageBanner from '../components/MessageBanner';
import FeatureFlag from '../components/FeatureFlag';
import { getFormattedDate } from '../utils/utilAgencyTableFunctions';
import { DataGrid } from '@mui/x-data-grid';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { getStatusChip } from '../utils/dashboardUtils/utilSharedTableFunctions';
import { statuses } from '../constants/appStatuses';
import ConfirmationDialog from '../components/ConfirmationDialog';

const useStyles = makeStyles((theme) => ({
  approved: {
    backgroundColor: theme.customColors.applicationStatus.approved
  },
  denied: {
    backgroundColor: theme.customColors.applicationStatus.denied
  },
  returned: {
    backgroundColor: theme.customColors.applicationStatus.returned   
  },
  submitted: {
    backgroundColor: theme.customColors.applicationStatus.submitted
  },
  reSubmitted: {
    backgroundColor: theme.customColors.applicationStatus.reSubmitted
  },
  inProgress: {
    backgroundColor: theme.customColors.applicationStatus.inProgress
  },
  onPaper: {
    backgroundColor: theme.customColors.applicationStatus.onPaper
  },
  mainContainer: {
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down('sm')]: {
      padding: '.5rem'
    }
  },
  pageSpinner:{
    position: "absolute", 
    textAlign: "center",
    top: '50%'
  },
  tableSpinner: {
    margin: '1rem',
    marginLeft: 'auto'
  },
  newAppButton: {
    margin: '1rem 0rem',
    padding: '0 2rem',
    width: '100%',
    fontSize: '1rem',
    textTransform: 'none',
  },
  detailsButton: {
    fontWeight: 500,
    textDecoration: 'none',
    minHeight: '1.5rem',
    padding: '.75rem 0',
    fontSize: '.875rem',
    color: theme.palette.primary.main
  },
  table:{
    padding: '0 !important',
    marginBottom: '1rem'
  },
  chip: {
    borderRadius: '.5rem', 
    fontSize: '.875rem',
    fontWeight: 500,
    height: '2rem',
    color: '#fff',
    width: "7rem",
    cursor: 'pointer',
    justifyContent: 'left',
    '& > .MuiChip-label':{
      paddingLeft: '.6rem',
      paddingRight: '.6rem'
    }
  },
  dataGrid:{
    height: "auto !important", 
    '& .MuiDataGrid-main': {
      height: '30rem'
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      overflow: 'visible',
      height: '3rem'
    },
    '& .MuiDataGrid-columnHeaders': {
      maxHeight: 'none !important',
      backgroundColor: theme.palette.primary.main,
      color: '#FFF'
    },
    '& .MuiDataGrid-columnHeaderTitleContainerContent':{
      '& > .MuiTypography-root': {
      fontSize: '1rem !important'
      }
    },
    '& .MuiDataGrid-columnSeparator': {
      visibility: 'hidden'
    },
    '& .MuiDataGrid-viewport': {
      maxHeight: "none !important",
    },
    '& .MuiDataGrid-renderingZone': {
      maxHeight: "none !important",
      height: '35rem'
    },
    '& .MuiDataGrid-columnHeader' :{
      display: 'grid',
    },
    '& .MuiDataGrid-cell': {
      lineHeight: "unset !important",
      maxHeight: "none !important",
      whiteSpace: "normal",
      overflowWrap: 'break-word',
      cursor: 'pointer'
    },
    '& .MuiDataGrid-cell:focus': {
      outline: 'none'
    },
    '& .MuiDataGrid-cell:focus-within': {
      outline: 'none'
    },
    '& .MuiDataGrid-row': {
      maxHeight: "none !important"
    },
    '& .MuiDataGrid-iconButtonContainer':{
      visibility: 'visible',
      color: '#fff'
    },
    '& .MuiButtonBase-root':{
      color: '#fff'
    },
    '& .MuiDataGrid-footerContainer':{
      display: 'none'
    }
  }
}));

export default function Dashboard() {
  const classes = useStyles();
  const { userInfo } = useContext(AuthContext);
  const history = useHistory();
  const queryClient = useQueryClient();
  const userId = userInfo?.id;
  const appState = userInfo?.serviceState;
  const processSteps = userInfo?.stateInfo?.processSteps;
  const [createErrorOpen, setCreateErrorOpen] = useState(false);
  const [newAppConfirmationOpen, setNewAppConfirmationOpen] = useState(false);

  const [sortModel, setSortModel] = useState([{field: 'createdDate', sort: 'desc'}]);
  const handleSortChange = (model) => {
      setSortModel(model)
  }
  
  const { data, status, isFetching} = useQuery(['applications', {userId: userId}], async () => {
    const { data } = await getApplications(userId);
    return data;
  }, {enabled: !!userId});

  const { data:previousSeasonData, isFetching:previousSeasonIsFetching} = useQuery(['previousSeasonApplications', {userId: userId}], async () => {
    const { data } = await getPreviousSeasonApplications();
    return data;
  }, {enabled: !!userId});

  const mutation = useMutation(({formData, copyPreviousSeason}) => {
    return createApplication(formData, copyPreviousSeason);
  },{
    onSuccess: data => {
      queryClient.invalidateQueries('applications');
    }
  })

  const handleNewApplicationClick = () => { 
    if (previousSeasonData && Array.isArray(previousSeasonData) && previousSeasonData.length > 0){
      setNewAppConfirmationOpen(true)
    }else{
      createNewApplication(false)
    }         
  }

  const createNewApplication = (copyPreviousSeason) => {
    const newApplication = {
      "userId": userInfo?.id,
      "firstName": userInfo?.firstName,
      "middleName": userInfo?.middleName,
      "lastName": userInfo?.lastName,
      "email": userInfo?.email,
      "phoneNumber": formatPhoneNumber(userInfo?.phoneNumber),
      "birthDate": userInfo?.birthDate,
      "serviceStreetAddress": userInfo?.serviceStreetAddress,
      "serviceApartmentNumber": userInfo?.serviceApartmentNumber,
      "serviceCity": userInfo?.serviceCity,
      "serviceState": userInfo?.serviceState,
      "serviceZip": userInfo?.serviceZip,
      "serviceCountyOrParish": userInfo?.serviceCountyOrParish,
    }

    mutation.mutate(({formData: newApplication, copyPreviousSeason: copyPreviousSeason}), {
      onSuccess: (response) => {
        const path = `/liheapform/${appState}/${processSteps[0].location}/${response.data.id}`;
        history.push({
          pathname: path
        })
      },
      onError: () => {
        setCreateErrorOpen(true)
        setNewAppConfirmationOpen(false)
      }
    })
  }

  const onRowClick = (e) => {
    const path = `/liheapform/${appState}/status/${e?.id}`
    history.push({
      pathname: path
    })
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setCreateErrorOpen(false);
  };

  const getLinkValue = (id, state) => {
    return `/liheapform/${state}/status/${id}`
  }

  const getDisplayStatus = (appData, status, classes) => {
    let displayStatus = status
    //if current status is Pending, Error or In-Review, display latest status between submitted and re-submitted
    if([statuses.PENDING, statuses.ERROR, statuses.IN_REVIEW].includes(status)){
      const sortedHistory = appData.statusHistory.sort((a, b) => new Date(b.statusDate) - new Date(a.statusDate))
      for(let i=0; i<sortedHistory.length; i++){
        if(sortedHistory[i].status === statuses.SUBMITTED || sortedHistory[i].status === statuses.RE_SUBMITTED){
          displayStatus = sortedHistory[i].status
          break
        }
      }
    }
    
    return getStatusChip(displayStatus, classes)
  }

  const getColumns = () => {
    return [
      {
        field: 'status', flex: 1.5,
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Status</Typography>),
        renderCell: (params) => (<div className={classes.cell}>{getDisplayStatus(params.row, params.value, classes)}</div>)
      },
      {
        field: 'createdDate', flex: 1.3,
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Date</Typography>),
        renderCell: (params) => (<Typography variant='subtitle1' className={classes.cell}>{getFormattedDate(params.value)}</Typography>)
      },
      {
        field: 'action', flex: .9, sortable: false,
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Action</Typography>),
        renderCell: (params) => (<Link className={classes.detailsButton} to={getLinkValue(params.id, params.row.agencyState)}>Details</Link>)
      }
    ]
  }

  
  const SortedDescendingIcon = () => {
    return <KeyboardArrowDown/>;
  }

  const SortedAscendingIcon = () => {
    return <KeyboardArrowUp/>;
  }

  if(mutation.isLoading || status !== 'success' ){
    return (
      <Grid container justifyContent="center" className={classes.pageSpinner}>
        <CircularProgress color="primary" size={50} />
      </Grid>
    )
  }

    return (
      <>
        <Grid xs={12} item container justifyContent="center" direction="column" className={classes.mainContainer}> 
        <MessageBanner messageType="Dashboard" />
          <Container >
              <StepHeader 
                title="Your Applications"
                desc="You will be able to check your application status changes online as well as get notified along the process."
                showInfo={true}
              />
              <FeatureFlag feature="CreateApplication" hideOnDisabled={true}>
                <Grid container item xs={12} justifyContent="flex-end" >
                  <Grid item xs={12} sm={3}>
                    <Button className={classes.newAppButton} variant="contained" color="primary" onClick={() => handleNewApplicationClick()}>
                      New Application
                    </Button>
                  </Grid>
                </Grid>
              </FeatureFlag>
              {status === 'success' && !isFetching && !previousSeasonIsFetching
                ? 
                  <Grid item xs={12} className={classes.table}>
                    <DataGrid
                      className={classes.dataGrid}
                      rows={data ? data : []}
                      columns={getColumns()}
                      hideFooterSelectedRowCount
                      hideFooterPagination
                      disableSelectionOnClick
                      disableColumnMenu
                      components={{
                        ColumnSortedDescendingIcon: SortedDescendingIcon,
                        ColumnSortedAscendingIcon: SortedAscendingIcon,
                      }}
                      sortModel={sortModel}
                      onSortModelChange={handleSortChange}
                      onRowClick={(e)=>onRowClick(e)}
                    />
                  </Grid>
                :
                  <Grid className={classes.tableSpinner} container item xs={12} justifyContent="center" >
                    <CircularProgress color="primary" size={50} />
                  </Grid>
              }
              <FormSection>
                <Grid justifyContent="center">
                  <AgencyInfo/>
                </Grid>
              </FormSection>
          </Container>
        </Grid>
        <Snackbar open={createErrorOpen} autoHideDuration={5000} onClose={handleClose}>
          <Alert severity="error" onClose={handleClose}>
            Error Creating New Application!
          </Alert>
        </Snackbar>

        <ConfirmationDialog
          open={newAppConfirmationOpen}
          title={"New Application"}
          desc={"Would you like to update your information from the previous season or start with a blank application?"}
          cancelLabel="Blank Application"
          okLabel="Update Information"
          handleCancel={() => createNewApplication(false)}
          handleOk={() => createNewApplication(true)}
          isLoading={mutation.isLoading}
        />
      </>
    )
}
