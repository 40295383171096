import { APIUtils } from './APIUtils';

const getAllApplications = () => {
  return APIUtils.get(`agency/applications/all`)
}

const updateApplicationStatus = (data) => {
  return APIUtils.patch(`agency/applications/updateStatus`, data)
}

const addApplicationNote = (data) => {
  return APIUtils.patch(`agency/applications/addNote`, data)
}

const editApplicationNote = (data) => {
  return APIUtils.patch(`agency/applications/editNote`, data)
}

const removeApplicationNote = (data) => {
  return APIUtils.patch(`agency/applications/removeNote`, data)
}

const getHistoricApplicationExcludePrev = (id, recordType) => {
  recordType = encodeURIComponent(recordType)
  return APIUtils.get(`agency/applications/${id}/${recordType}?includePrevious=false`)
}

const getApplicationByIdExcludePrev = (id) => {
  return APIUtils.get(`agency/applications/${id}?includePrevious=false`)
}

const getApplicationByIdForAgent = (id) => {
  return APIUtils.get(`agency/applications/${id}?includePrevious=true`)
}

const updateAssignee = (data) => {
  return APIUtils.patch(`agency/applications/updateAssignee`, data)
}

const getAssignableAgencyUsers = () => {
  return APIUtils.get(`user/agency/assignee/list`)
}

const getAgencyUsers = () => {
  return APIUtils.get(`user/admin/agency/list`)
}

const getAgencyInvitations = () => {
  return APIUtils.get(`invitations`);
}

const resendAgencyInvitation = (id) => {
  return APIUtils.post(`invitation/resend/${id}`)
}

const removeAgencyInvitation = (id) => {
  return APIUtils.patch(`invitation/remove/${id}`)
}

const updateAgencyUserInfo = (id, data) => {
  return APIUtils.patch(`user/admin/update/name/${id}`, data)
}

const updateUserPermissions = (id, data) => {
  return APIUtils.patch(`user/admin/update/permission/${id}`, data)
}

const updateUserDisabledStatus = (id, data) => {
  return APIUtils.patch(`user/admin/update/disabled/${id}`, data)
}

const archiveAgencyUser = (id) => {
  return APIUtils.post(`user/admin/archive/${id}`)
}

//also handles search and filter for statewide applications
const searchAndFilterApplications = (data) => {
  return APIUtils.post(`search/applications`, data)
}

const updateApplicationUrgency = (data) => {
  return APIUtils.patch(`agency/applications/updateUrgency`, data)
}

const updateApplicationDuplicate = (data) => {
  return APIUtils.patch(`agency/applications/updateDuplicate`, data)
}

const getReportData = (data) => {
  return APIUtils.post(`search/report`, data)
}

const getReportsAuditData = () => {
   return APIUtils.get(`agency/report/audits?reportType=strickland`)
}

export {
  getAllApplications,
  updateApplicationStatus,
  addApplicationNote,
  editApplicationNote,
  removeApplicationNote,
  getHistoricApplicationExcludePrev,
  getApplicationByIdExcludePrev,
  getApplicationByIdForAgent,
  getAgencyUsers,
  getAgencyInvitations,
  resendAgencyInvitation,
  removeAgencyInvitation,
  updateAgencyUserInfo,
  updateUserPermissions,
  updateUserDisabledStatus,
  archiveAgencyUser,
  getAssignableAgencyUsers,
  updateAssignee,
  searchAndFilterApplications,
  updateApplicationUrgency,
  updateApplicationDuplicate,
  getReportData,
  getReportsAuditData
};
