export const getHelpTextBlocks = [
    {
        label: "Simple",
        desc: "You can use any device, anywhere, even from the comfort of your own home."
    },
    {
        label: "Trackable",
        desc: "You will receive text and email updates to track where your application is in the process."
    },
    {
        label: "Secure",
        desc: "You can rest assured that your data is secure."
    },
    {
        label: "Convenient",
        desc: "No appointments needed, you can apply anytime day or night."
    },
    
]
