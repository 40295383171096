import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography, Button,  } from '@mui/material';
import { useHistory } from 'react-router';
import { getHelpTextBlocks } from '../../constants/home-page/getHelp';

const useStyles = makeStyles((theme) => ({
    mainContainer:{
        width: 'fit-content',
    },
    info: {
        margin: 'auto',
        padding: '2rem 6rem 2rem 3rem !important',
        [theme.breakpoints.down('lg')]: {
            padding: '1rem 2rem !important'
        }
    },
    image: {
        position: 'relative',
        width: '100%',
        [theme.breakpoints.down('lg')]: {
          margin: 'auto',
        },
    },
    title: {
        color: '#000',
        fontSize: '2.25rem',
        marginBottom: '1rem',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        },
    },
    subsection:{
        [theme.breakpoints.down('sm')]: {
            padding: '.5rem 1rem'
        },
    },
    label: {
        fontWeight: 600,
        color: '#000',
        fontSize: '1rem',
        marginTop: '.5rem',
        textAlign: 'center'
    },
    desc: {
        color: '#000',
        fontSize: '1rem',
        marginTop: '1rem',
        textAlign: 'start',
    },
    buttonContainer:{
        alignItems: 'center',
        margin: '2rem 0'
    },
    button: {
        width: '13.625rem',
        textTransform: 'none',
        marginTop: '1rem',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
          },
    }
}));

export default function GetHelp() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid container item className={classes.mainContainer}>
        <Grid item md={12} lg={5} className={classes.image}>
            <img src={`/img/homepage-get-help.png`} alt="" width="100%" height="100%" />
        </Grid>
    <Grid container item md={12} lg={7} className={classes.info}>
        <Grid item xs={12}>
            <Typography className={classes.title}>Easy To Access, Easy To Use</Typography>
        </Grid>
        <Grid container item xs={12} spacing={2}>
            {getHelpTextBlocks?.map((textBlock, index) => 
            <Grid key={index} item xs={12} sm={6} md={6} className={classes.subsection}>
                <Typography className={classes.label}>
                    {textBlock.label}
                </Typography>
                <Typography className={classes.desc}>
                    {textBlock.desc}
                </Typography>
            </Grid>
            )}
        </Grid>
        <Grid container item xs={12} className={classes.buttonContainer}>
            <Button variant='contained' color='primary' className={classes.button} onClick={() => history.push(`/register`)}>
                Apply For Help Today
            </Button>
        </Grid>
    </Grid>
  </Grid>
)}

