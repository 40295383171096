import React, { useState, useEffect } from 'react';
import { Grid, Button } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    separator: {
        padding: '1rem'
    },
    clearButton: {
        margin: '0 .5rem'
    }
}));

export default function DateRangePicker({defaultStartVal=null, defaultEndVal=null, onDateRangeSelection, disableStartDate=false, showClear=true }) {
    const classes = useStyles();

    const [startDate, setStartDate] = useState(defaultStartVal);
    const [endDate, setEndDate] = useState(defaultEndVal);

    const [startDateError, setStartDateError] = useState(false);
    const [endDateError, setEndDateError] = useState(false);
    const [startHelperText, setStartHelperText] = useState('')
    const [endHelperText, setEndHelperText] = useState('')

    useEffect(() => {
        let errors = startDateError || endDateError;
        onDateRangeSelection(startDate, endDate, errors);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, startDateError, endDateError]);

    const handleStartError = (error) => {
        if(error){
            setStartDateError(true)
            setStartHelperText('Invalid Date')
        } 
        else{
            setStartDateError(false)
            setStartHelperText('')
        }
    }

    const handleEndError = (error) => {
        if(error){
            setEndDateError(true)
            if(error === 'minDate'){
                setEndHelperText('End date cannot be before start date.')
                
            }
            else {
                setEndHelperText('Invalid Date')
            }
        } 
        else{
            setEndDateError(false)
            setEndHelperText('')
        }
    }

    return (
        <Grid container alignItems="baseline" >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    className={classes.datePicker}
                    value={startDate}
                    label="Start Date"
                    disableHighlightToday
                    onChange={(date) => setStartDate(date)}
                    onError={(e) => handleStartError(e)}
                    disabled={disableStartDate}
                    slotProps={{
                        textField: {
                          helperText: startHelperText,
                        }
                    }}
                />
            </LocalizationProvider>
            <div className={classes.separator}>to</div>
            <LocalizationProvider dateAdapter={AdapterDateFns} >
                <DatePicker
                    className={classes.datePicker}
                    value={endDate}
                    label="End Date"
                    disableHighlightToday
                    minDate={startDate}
                    onChange={(date) => setEndDate(date)}
                    onError={(e) => handleEndError(e)}
                    slotProps={{
                        textField: {
                          helperText: endHelperText,
                        }
                    }}
                />
            </LocalizationProvider>
            {showClear &&
                <Button className={classes.clearButton} onClick={() => {
                    setStartDate(null);
                    setEndDate(null);
                    onDateRangeSelection(null, null);
                }}>
                    Clear
                </Button>
            }
        </Grid>

    );
}
