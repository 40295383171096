import { FormHelperText, TextField, Checkbox, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { getErrorMessage } from '../../utils/utilFunctions';
import Autocomplete from '@mui/material/Autocomplete';
import CheckedIcon from '../CheckedIcon';
import styled from '@emotion/styled';

const StyledAutoComplete = styled(Autocomplete)(({theme}) => (
    {
      '& .MuiAutocomplete-inputRoot': {
        paddingRight: '1.5rem !important',
        display: 'flex',
        flexDirection: 'column-reverse',
        marginTop: '1rem',
        alignItems: 'flex-start'
      },
      '& .MuiChip-root': {
          background: theme.customColors.modalBackground,
          borderRadius: 0,
          justifyContent: 'space-between'
      },
      '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
          width: 'fit-content',
      },
      '& .MuiAutocomplete-endAdornment': {
          top: 0
      },
      '& .MuiAutocomplete-tag': {
          width: '100%',
          maxWidth: '30rem'
      }
    }));

const useStyles = makeStyles(theme => ({
  label: {
    fontWeight: "bold", 
    marginBottom: ".6rem",
    color: "#fff"
  },
  textField: {
    width: '100%',
    '& > .MuiFormHelperText-contained' : {
      margin: '0rem',
    }
  },
  errorMessage: {
      color: theme.palette.error.main,
      margin: '0rem',
  },
  optionContainer: {
    alignItems: 'baseline'
  },
  option: {
    padding: 0,
  },
  selectedOption: {
    padding: 0,
    background: theme.customColors.modalBackground,

  },
  checkbox: {
    marginRight: '0 !important',
    padding: '.5rem',
    color: theme.customColors.mediumGray 
  },
}));

export default function FormMultiSelect({ control, name, label, errors, options, defaultValue, disabled, onSelect, clearAllTrigger=false, fileSelect=false }) {
  const classes = useStyles();


  const [val, setVal] = useState(defaultValue ? defaultValue : [])

  useEffect(() => {
    if(clearAllTrigger){
      setVal([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearAllTrigger]);


  return (
    <>
    <Controller
        name={name}
        control={control}
        defaultValue={defaultValue ? defaultValue : []}
        error={Boolean(getErrorMessage(errors, name))}
        render={() => (
            <StyledAutoComplete
                disabled={disabled}
                disableCloseOnSelect
                multiple
                options={options}
                getOptionLabel={(option) => fileSelect ? option.title : option}
                renderOption={(props, option, { selected }) => (
                  <li {...props} className={selected ? classes.selectedOption : classes.option}>
                    <Grid container className={classes.optionContainer}>
                      <Grid item xs={1}>
                        <Checkbox
                          className={classes.checkbox}
                          checkedIcon={<CheckedIcon/>}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                      </Grid>
                      <Grid item xs={11}>
                        {fileSelect ? option.title : option}
                      </Grid>
                    </Grid>
                  </li>
                )}
                isOptionEqualToValue={(option, value) => option === value}
                value={val}
                onChange={(e, value) => {
                    onSelect(value)
                    setVal(value)
                }}
                renderInput={(params) => (
                    <TextField
                        className={classes.textField}
                        {...params}
                        variant="standard"
                        placeholder={label}
                        error={Boolean(getErrorMessage(errors, name))}
                    />
                )}
            />
        )}
    />
    <FormHelperText className={classes.errorMessage}>{getErrorMessage(errors, name)}</FormHelperText>
    </>
  )
}
