import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Typography, Grid, List, ListItem } from '@mui/material';
import { statuses } from '../../../../../constants/appStatuses';
import { Check, Clear, KeyboardReturn, Notifications, Replay, Restore, WarningAmber } from '@mui/icons-material';
import { formatDate } from '../../../../../utils/utilFunctions';

const useStyles = makeStyles((theme) => ({
  mainContainer: {   
    marginTop: '1rem'
},
  status: {
    display: 'flex',
    marginLeft: '.5rem',
    marginBottom: '.5rem'
  },
  statusIcon: {
      height: '1.25rem'
  },  
  statusLabel: {
    marginLeft: '.25rem',
    color: '#44474F'
  },
  statusInfoGrid: {
    display: 'flex',
    marginBottom: '.5rem'
  },
  statusInfo: {
    wordBreak: 'break-word',
    marginBottom: '.5rem',
    marginLeft: '.25rem',
    color: '#44474F'
  },
  statusInfoLabel: {
    fontWeight: "bold"
  },
  errorLabel: {
    marginLeft: '.25rem',
    color: '#BA1B1B'
  },
  errorInfo: {
    wordBreak: 'break-word',
    marginBottom: '.5rem',
    marginLeft: '.25rem',
    color: '#BA1B1B'
  }
}));

export default function TXCurrentStatus({ status, statusInfo, includeFinaApproveWarning=false }) {

  const classes = useStyles();

  const renderWarning = () => {
    return (
        <>
        {includeFinaApproveWarning && 
            <Grid item>
                <Typography variant="subtitle2" className={classes.statusInfoLabel}>
                    <List sx={{ listStyleType: 'decimal', pl: 3 }}>
                        <ListItem sx={{ display: 'list-item' }}>
                            Approving the application without a valid account number will not post a pledge, but it will notify the applicant. 
                        </ListItem>
                        <ListItem sx={{ display: 'list-item' }}>
                            Please make sure you resolved all FINA issues that caused the Error.  
                        </ListItem>
                        <ListItem sx={{ display: 'list-item' }}>
                            Pledge should have been be made on FINA before changing the status to Approved. 
                        </ListItem>
                    </List>
                </Typography> 
            </Grid>
        }
        </>
    )
  }

  const renderCurrentStatus = () => {
    return (
        <>
        {status && status === statuses.SUBMITTED && 
            <Grid item className={classes.status}>
                <Notifications className={classes.statusIcon} style={{color: '#6324B5'}}/> 
                <Typography variant="subtitle2" className={classes.statusLabel}>New</Typography>   
            </Grid>
        }
        {status && status === statuses.RE_SUBMITTED && 
            <Grid item className={classes.status}>
                <Replay className={classes.statusIcon} style={{color: '#1057A8'}}/> 
                <Typography variant="subtitle2" className={classes.statusLabel}>Re-Submitted</Typography>   
            </Grid>
        }
        {status && status === statuses.APPROVED && 
            <Grid item className={classes.status}>
                <Check className={classes.statusIcon} style={{color: '#35811A'}}/>
                <Typography variant="subtitle2" className={classes.statusLabel}>Approved</Typography> 
            </Grid> 
        }
        {status && status === statuses.DENIED && 
        <Grid item className={classes.status}>
            <Clear className={classes.statusIcon} style={{color: '#C10B2B'}}/> 
            <Typography variant="subtitle2" className={classes.statusLabel}>Denied</Typography>  
        </Grid>
        }
        {status && status === statuses.RETURNED && 
        <Grid item className={classes.status}>
            <KeyboardReturn className={classes.statusIcons} style={{color: '#804800'}}/> 
            <Typography variant="subtitle2" className={classes.statusLabel}>Returned</Typography>  
        </Grid>
        }
        {status && status === statuses.PENDING && 
            <Grid item className={classes.status}>
                <Restore className={classes.statusIcon} style={{color: '#505A5F'}}/>
                <Typography variant="subtitle2" className={classes.statusLabel}>Pending</Typography> 
            </Grid> 
        }
        {status && status === statuses.ERROR && 
            <Grid item className={classes.status}>
                <WarningAmber className={classes.statusIcon} style={{color: '#BA1B1B'}}/>
                <Typography variant="subtitle2" className={classes.errorLabel}>Error</Typography> 
            </Grid> 
        }
        </>
    )
  }

  const renderCurrentStatusInfo = () => {
    return (
        <>
        {status && status === statuses.ERROR &&
            <Grid item >
                <Typography variant="subtitle2" className={classes.errorInfo}>
                An error has occurred. This could be the result of an invalid account number, the account being in final status or the account having zero balance. Contact the FINA Desk to troubleshoot if needed, or return the application so that the client can enter a valid account number. If the account is in final status, they will need to contact customer service to set up a new account before proceeding.             
                </Typography> 
            </Grid> 
        }
        {status && status === statuses.PENDING &&
            <Grid item >
                <Typography variant="subtitle2" className={classes.statusInfo}>
                    The site is communicating with Entergy's system to post a pledge to the customer's account. 
                    <List sx={{ listStyleType: 'disc', pl: 3 }}>
                        <ListItem sx={{ display: 'list-item' }}>
                            Pledges usually take around 30 seconds to post. Please check back later. 
                        </ListItem>
                        <ListItem sx={{ display: 'list-item' }}>
                            If an error occurs, the status will change to "Error." This could be the result of an invalid account number, the account being in final status or the account having zero balance. Contact the FINA Desk to troubleshoot if needed. 
                        </ListItem>
                        <ListItem sx={{ display: 'list-item' }}>
                            If the pledge is posted successfully, the applicant will be notified of the approval. 
                        </ListItem>
                    </List>
                </Typography> 
            </Grid> 
        }
        {status && [statuses.APPROVED, statuses.PENDING].includes(status) && 
        <>
            {statusInfo.payments?.map((payment, i) => (
             <Grid item key={i} className={classes.statusInfoGrid}>
                <Typography variant="subtitle2" >
                    <span className={classes.statusInfoLabel}>
                        {`Amount #${i+1}:`}
                    </span>
                    <span variant="subtitle2" className={classes.statusInfo}> 
                        {`$${payment.amount}. Provider: ${payment.provider}. ${payment.finaPledgeOptOut === "yes" ? "Pledge not posted.": ""} Type: ${payment.type}. Fund Source: ${payment.source}.`}
                    </span>
                </Typography> 
            </Grid> 
            ))}
            {statusInfo.payments &&
                <Grid item className={classes.statusInfoGrid}>
                    <Typography variant="subtitle2" className={classes.statusInfoLabel}>Date:</Typography> 
                    <Typography variant="subtitle2" className={classes.statusInfo}>{formatDate(statusInfo.pledgeDate)}</Typography> 
                </Grid>
            }
            {!statusInfo.payments &&
            <>
                <Grid item >
                    <Typography variant="subtitle2" className={classes.statusInfoLabel}>Pledge Amount:&nbsp;</Typography> 
                    <Typography variant="subtitle2" className={classes.statusInfo}>{`$ ${statusInfo.pledgeAmount}`}</Typography> 
                </Grid> 
                <Grid item >
                    <Typography variant="subtitle2" className={classes.statusInfoLabel}>Pledge Date:&nbsp;</Typography>
                    <Typography variant="subtitle2" className={classes.statusInfo}>{formatDate(statusInfo.pledgeDate)}</Typography> 
                </Grid> 
                <Grid item >
                    <Typography variant="subtitle2" className={classes.statusInfoLabel}>Utility provider payment was sent to:&nbsp;</Typography> 
                    <Typography variant="subtitle2" className={classes.statusInfo}>{statusInfo.checkRecipient ? statusInfo.checkRecipient : ' N/A'}</Typography> 
                </Grid> 
            </>
            }
        </>
        }
        {status && status === statuses.DENIED && 
        <>
            <Grid item >
                <Typography variant="subtitle2" className={classes.statusInfoLabel}>Provided reason for denial:&nbsp;</Typography> 
                <Typography variant="subtitle2" className={classes.statusInfo}>{statusInfo.deniedReasonSpecification ? statusInfo.deniedReasonSpecification  : 'N/A'}</Typography> 
            </Grid> 
        </>
        }
        {status && status === statuses.RETURNED && 
        <>
            <Grid item >
                <Typography variant="subtitle2" className={classes.statusInfoLabel}>Needed Information:&nbsp;</Typography> 
                <Typography variant="subtitle2" className={classes.statusInfo}>{statusInfo.returnedNeededInfo}</Typography> 
            </Grid> 
            <Grid item >
                <Typography variant="subtitle2" className={classes.statusInfoLabel}>Due Date:&nbsp;</Typography> 
                <Typography variant="subtitle2" className={classes.statusInfo}>{formatDate(statusInfo.returnedDueDate)}</Typography> 
            </Grid> 
        </>
        }
      </>
    )
  }

  return (
    <Grid className={classes.mainContainer}> 
        {renderWarning()}
        <Typography variant="subtitle2" className={classes.statusInfoLabel}>Current Status:&nbsp;</Typography>
        {renderCurrentStatus()}
        {renderCurrentStatusInfo()}
    </Grid>
  );
}
