import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'

const useStyles = makeStyles((theme) => ({
  modal: {
    position: 'absolute',
    background: theme.customColors.modalBackground,
    boxShadow: '0 0 .3rem rgba(0, 0, 0, 0.2)',
    bozSizing: 'inherit',
    borderRadius: '1.75rem',
    maxWidth: '40rem'
  },
  background:{
    background: theme.customColors.modalBackground,
  },
  button:{
    textTransform: "unset !important",
    fontWeight: "bold"
  },
}));

export default function ConfirmationDialog({ open, title, desc="", handleCancel, handleOk, contentChildren=false, cancelLabel="Cancel", okLabel="Ok", isLoading=false}) {
  const classes = useStyles();

  return (
    <Dialog
        classes={{ 
          paper: classes.modal 
      }}
        disableEscapeKeyDown
        maxWidth="xs"
        open={open}
      >
      <DialogTitle className={classes.background} id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent className={classes.background}>
        {desc &&
          <Typography variant="subtitle1">{desc}</Typography>
        }
        {contentChildren &&
          <>{contentChildren}</>          
        }
      </DialogContent>
      <DialogActions className={classes.background}>
        {isLoading ?
          <>
            <CircularProgress color="primary"/>
          </>
        :
        <>
          <Button autoFocus onClick={handleCancel} color="primary" className={classes.button}>
            {cancelLabel}
          </Button>
          <Button onClick={handleOk} color="primary" className={classes.button}>
            {okLabel}
          </Button>
        </>
        }
      </DialogActions>
    </Dialog>
  )
}
