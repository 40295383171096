export const ethnicityOptions = [
    {
      value: 'hispanicLatinoSpanish',
      label: 'Hispanic, Latino, or Spanish Origins'
    },
    {
      value: 'notHispanicLatinoSpanish',
      label: 'Not Hispanic or Latino'
    }
  ]

  