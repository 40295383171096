import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';
import { Grid, Typography } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { StateReportsStyledDataGrid } from './StateReportsStyledDataGrid';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: '75rem',
  },
  column: {
    fontWeight: '500',
    marginLeft: '.5rem',
    whiteSpace: 'normal',
    lineHeight: 'normal',
    height: "unset !important",
    maxHeight: "168px !important"
  },
  cell: {
    fontWeight: '500',
    paddingLeft: '.5rem',
    width: '100%',
    whiteSpace: 'break-spaces',
    lineHeight: '1.5rem',
    textAlign: 'start'
  },
  numberCell: {
    fontWeight: '500',
    width: '100%',
    whiteSpace: 'break-spaces',
    lineHeight: '1.5rem',
    textAlign: 'right'
  }
}));

export default function StateStatusReportTable({ data, isFetching }) {
  const classes = useStyles();
  const [sortModel, setSortModel] = useState([{field: 'agencyId', sort: 'asc'}]);

  const handleSortChange = (model) => {
    if (JSON.stringify(sortModel[0]) !== JSON.stringify(model[0])){
      setSortModel(model)
    }
  }

  const SortedDescendingIcon = () => {
    return <KeyboardArrowDown style={{color: '#000'}}/>;
  }

  const SortedAscendingIcon = () => {
    return <KeyboardArrowUp style={{color: '#000'}}/>;
  }

  const formatTurnaroundTime = (value) => {
    return value ? (Math.round(value * 100) / 100).toFixed(1) : 0
  }

  const formatNumber = (value) => {
    return value ? Number(value).toLocaleString("en-US") : 0
  }

  const getColumns = () => {
    return [
      {
        field: 'agencyId', flex: .4,
        align:'left',
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Agency</Typography>),
        renderCell: (params) => (<Typography variant='subtitle1' className={classes.cell}>{params.value}</Typography>)
      },
      {
        field: 'appTotal', flex: .6,
        type:'number',
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Applications Total</Typography>),
        renderCell: (params) => (<Typography variant='subtitle1' className={classes.numberCell}>{formatNumber(params.value)}</Typography>)
      },
      {
        field: 'approvedTotal', flex: .4,
        type:'number',
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Approved</Typography>),
        renderCell: (params) => (<Typography variant='subtitle1' className={classes.numberCell}>{formatNumber(params.value)}</Typography>)
      },
      {
        field: 'approvedTurnaroundTimeDays', flex: .8,
        type:'number',
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Approved Avg Turnaround Days </Typography>),
        renderCell: (params) => (<Typography variant='subtitle1' className={classes.numberCell}>{formatTurnaroundTime(params.value)}</Typography>)
      },
      {
        field: 'deniedTotal', flex: .4,
        type:'number',
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Denied</Typography>),
        renderCell: (params) => (<Typography variant='subtitle1' className={classes.numberCell}>{formatNumber(params.value)}</Typography>)
      },
      {
        field: 'deniedTurnaroundTimeDays', flex: .8,
        type:'number',
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Denied Avg Turnaround Days </Typography>),
        renderCell: (params) => (<Typography variant='subtitle1' className={classes.numberCell}>{formatTurnaroundTime(params.value)}</Typography>)
      },
      {
        field: 'returnedTotal', flex: .4,
        type:'number',
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Returned</Typography>),
        renderCell: (params) => (<Typography variant='subtitle1' className={classes.numberCell}>{formatNumber(params.value)}</Typography>)
      },
      {
        field: 'returnedTurnaroundTimeDays', flex: .8,
        type:'number',
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Returned Avg Turnaround Days </Typography>),
        renderCell: (params) => (<Typography variant='subtitle1' className={classes.numberCell}>{formatTurnaroundTime(params.value)}</Typography>)
      }   
    ];
  }

  return (
    <div className={classes.table}>
      <Grid item xs={12} sx={{height: "30rem"}}>
        <StateReportsStyledDataGrid
          rows={data ? data : []}
          columns={getColumns()}
          getRowId={(row) => Math.floor(Math.random() * 100000000)}
          hideFooter
          hideFooterSelectedRowCount
          hideFooterPagination
          disableSelectionOnClick
          disableColumnMenu          
          loading={isFetching}
          components={{
            ColumnSortedDescendingIcon: SortedDescendingIcon,
            ColumnSortedAscendingIcon: SortedAscendingIcon,
          }}
          sortModel={sortModel}
          onSortModelChange={handleSortChange}
        />
      </Grid>
    </div>
  );
}
