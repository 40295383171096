import React, { useContext, useState } from "react";
import { Grid, Typography, Button, CircularProgress, Chip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { WbTwilight, AcUnit } from "@mui/icons-material";
import DateRangePicker from "../../DateRangePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { createStateConfig, updateStateConfig } from "../../../api/ConfigAPI";
import StatePoliciesTable from "./StateSeasonPoliciesTable";
import SeasonFundingTable from "./StateSeasonFundingTable";
import { AuthContext } from "../../../context/authContext";
import ConfirmationDialog from "../../ConfirmationDialog";
import SuccessModal from "../../SuccessModal";
import { useMutation, useQueryClient } from "react-query";
import { useErrorViewer } from "../../../context/errorContext";
import { programTypes } from "../../../constants/programTypes";
import { parseISO } from "date-fns";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  warning: {
    border: `.188rem solid ${theme.palette.warning.main}`,
    borderRadius: ".5rem",
    margin: "1.5rem 0",
    alignItems: "center",
  },
  subtitleStyle: {
    padding: "1rem 0rem",
  },
  paddingStyle: {
    paddingBottom: "2rem",
    paddingTop: "1rem",
  },
  summerIcon: {
    color: "#ed6c02 !important",
    marginRight: "0.5rem",
  },
  winterIcon: {
    color: "#0059C8 !important",
    marginRight: "0.5rem",
  },
  separator: {
    padding: "1.5rem",
    paddingTop: "2rem",
    marginTop: "1rem",
    display: "inline",
    alignItems: "center",
  },
  chip: {
    borderRadius: '.5rem', 
    padding: '.5rem',
    fontSize: '.875rem',
    height: '2.5rem',
    color: '#000',
    width: "12rem",
    justifyContent: 'left',
  },
  selectedChip: {
    backgroundColor: "rgba(0, 0, 0, 0.08)"
  },
}));

export default function StateSeasonEdit({ data, agencyData, season, creatingNew = false, onEdit }) {
  const classes = useStyles();
  const { userInfo } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const [dateRangeValue, setDateRangeValue] = useState(() => creatingNew ? [null, null] : [data?.startDate, data?.endDate]);
  const [validDateRange, setValidDateRange] = useState(false);
  const dateUtil = new AdapterDateFns();
  const [startTimeValue, setStartTimeValue] = useState(creatingNew ? null : dayjs(data?.startDate));
  const [endTimeValue, setEndTimeValue] = useState(creatingNew ? null : dayjs(data?.endDate));
  const [disableTimepicker, setDisableTimepicker] = useState(!startTimeValue || !endTimeValue);
  const [policiesList, setPoliciesList] = useState(data?.policies);
  const [fundSourcesList, setFundSourcesList] = useState(data?.fundSources);
  const [enableMissingInfoOpen, setEnableMissingInfoOpen] = useState(false);
  const [enableLaunchSeasonInfoOpen, setEnableLaunchSeasonInfoOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [programType, setProgramType] = useState(() => creatingNew ? "none" : data?.programType);

  let start = dateRangeValue[0]?.toString().substr(0, 25);
  let end = dateRangeValue[1]?.toString().substr(0, 25);
  const enableMissingInfoText = `Verify Selected Program Type: ${programType}. Verify Selected Program Dates: ${start} to ${end}. Verify Policies and Fund Sources.`;
  const setError = useErrorViewer();

  const updateSeasonPolicies = (updatedPoliciesList) => {
    setPoliciesList(updatedPoliciesList);
  };

  const updateSeasonFundSources = (updatedFundSourcesList) => {
    setFundSourcesList(updatedFundSourcesList);
  };

  const handleSeasonProgramType = (newSeason) => {
    setProgramType(newSeason);
  };

  const handleDateRangeSelection = (startDate, endDate, errors) => {
    setDateRangeValue([startDate, endDate]);
    setValidDateRange(
      !errors &&
        startDate &&
        dateUtil.isValid(startDate) &&
        endDate &&
        dateUtil.isValid(startDate)
    );
    setDisableTimepicker(false);
    //Disable and reset timepickers if no start and end date selected
    if (
      (!startDate && !endDate) ||
      (!startDate && !endDate && (startTimeValue || endTimeValue))
    ) {
      setDisableTimepicker(true);
      setStartTimeValue(null);
      setEndTimeValue(null);
    }
  };

  const handleTimeStartSelection = (startTime) => {
    setStartTimeValue(startTime);
    let startDate = dateRangeValue[0];
    let endDate = dateRangeValue[1];
    startDate?.setHours(startTime.$H, startTime.$m, startTime.$s);
    setDateRangeValue([startDate, endDate ? endDate : null]);
    setValidDateRange(startDate < endDate);
  };

  const handleTimeEndSelection = (endTime) => {
    setEndTimeValue(endTime);
    let startDate = dateRangeValue[0];
    let endDate = dateRangeValue[1];
    endDate?.setHours(endTime.$H, endTime.$m, endTime.$s);
    setDateRangeValue([startDate ? startDate : null, endDate]);
    setValidDateRange(startDate < endDate);
  };

  const handleLaunchSeasonConfirm = (
    policies,
    fundSources,
    programType,
    dateRangeValue
  ) => (
    <span>
      {`${
        creatingNew ? "Launching" : "Updating"
      } the season will make the LIHEAP ${programType} program application open from ${dateRangeValue[0]
        ?.toString()
        .substr(0, 25)} to ${dateRangeValue[1]
        ?.toString()
        .substr(0, 25)}. Are you sure you wish to ${
        creatingNew ? "launch" : "update"
      } this season?`}
      <ul>
        <b>{`POLICIES`}</b>
        {policies?.map((policy) => (
          <li key={policy.id}>
            <span>{policy.policyName}</span>
          </li>
        ))}
      </ul>
      <ul>
        <b>{`FUND SOURCES`}</b>
        {fundSources?.map((fund, i) => (
          <li key={i}>
            <span>{fund.fundName}</span>
          </li>
        ))}
      </ul>
    </span>
  );

  const {mutate: submitNewSeason} = useMutation(
    (stateConfigSeasonData) => createStateConfig(stateConfigSeasonData),
        {
          onSuccess: () => {
            setLoading(false);
            queryClient.invalidateQueries([`stateConfigData-${season}`]);
            onEdit();
          },
            onError: (error) => {
              let err = error.response.data ? error.response.data.message : "An error occurred creating a new season."
              setError(err);
              setLoading(false);
            },
        }
  );

  const {mutate: updateSeason} = useMutation(
    ({startDate, state, config}) => updateStateConfig({startDate, state, config}),
        {
          onSuccess: () => {
            setLoading(false);
            queryClient.invalidateQueries([`stateConfigData-${season}`]);
            onEdit();
          },
            onError: (error) => {
              let err = error.response.data ? error.response.data.message : "An error occurred creating a new season."
              setError(err);
              setLoading(false);
            },
        }
  );

  const handleSubmitConfirm = () => {
    setEnableLaunchSeasonInfoOpen(false);
    setLoading(true);
    if (creatingNew) {
      let newStateConfig = {
        fundSources: fundSourcesList,
        createdDate: new Date(Date.now()).toISOString(),
        endDate: dateRangeValue[1],
        createdBy: userInfo.id,
        recordType: userInfo.agencyState,
        policies: policiesList,
        state: userInfo.agencyState,
        startDate: dateRangeValue[0],
        programType: programType,
      };

      submitNewSeason(newStateConfig);
    }
    else{
      let updatedStateConfig = {
        fundSources: fundSourcesList,
        endDate: dateRangeValue[1],
        policies: policiesList,
        startDate: dateRangeValue[0],
        programType: programType,
      };

      updateSeason({startDate: data?.startDate, state: data?.state, config: updatedStateConfig});
    };
  }

  const handleSubmitLaunchSeason = () => {
    if (
      !fundSourcesList ||
      fundSourcesList.length === 0 ||
      (!dateRangeValue[0] && !dateRangeValue[1]) ||
      (dateRangeValue[0] === null && dateRangeValue[0] === null) ||
      !policiesList ||
      policiesList.length === 0 ||
      !programType
    ) {
      setEnableMissingInfoOpen(true);
    } else {
      setEnableLaunchSeasonInfoOpen(true);
    }
  };

  return (
    <>
      <Grid container>
        {loading ? (
          <Grid container item xs={12} justifyContent="center">
            <CircularProgress color="primary" />
          </Grid>
          ) : 
          <>
        <Grid item xs={12} className={classes.subtitleStyle}>
          <Typography variant="h5">Step 1. Set Up Program Dates </Typography>
          <Typography variant="subtitle2">Select Season</Typography>
        </Grid>
        
        <Grid container item gap={2}>
          <Chip variant="outlined" className={`${classes.chip} ${programType === programTypes.SUMMER_COOLING ?  classes.selectedChip : ''}`} icon={<WbTwilight className={classes.summerIcon}/>} label={programTypes.SUMMER_COOLING} onClick={() =>handleSeasonProgramType(programTypes.SUMMER_COOLING)}/>
          <Chip variant="outlined" className={`${classes.chip} ${programType === programTypes.WINTER_HEATING ?  classes.selectedChip : ''}`} icon={<AcUnit className={classes.winterIcon}/>} label={programTypes.WINTER_HEATING} onClick={() =>handleSeasonProgramType(programTypes.WINTER_HEATING)}/>
        </Grid>
        <Grid item xs={12} className={classes.paddingStyle}>
          <Typography variant="subtitle2" className={classes.subtitleStyle}>
            Applications can be submitted from
          </Typography>
          <DateRangePicker
            onDateRangeSelection={handleDateRangeSelection}
            defaultStartVal={creatingNew ? null : parseISO(data?.startDate)}
            defaultEndVal={creatingNew ? null : parseISO(data?.endDate)}
          />
          <br></br>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              label="Start Time"
              disabled={disableTimepicker}
              value={startTimeValue}
              onChange={handleTimeStartSelection}
            />
            <div className={classes.separator} />
            <TimePicker
              label="End Time"
              disabled={disableTimepicker}
              value={endTimeValue}
              onChange={handleTimeEndSelection}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} className={classes.subtitleStyle}>
          <Typography variant="h5">Step 2. Add Fund Sources </Typography>
          <Typography variant="subtitle2">
            Update the list of fund sources by clicking on the text fields.{" "}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.paddingStyle}>
          <SeasonFundingTable
            data={data.fundSources}
            agencyData={agencyData}
            updateSeasonFundSources={updateSeasonFundSources}
          />
        </Grid>

        <Grid item xs={12} className={classes.subtitleStyle}>
          <Typography variant="h5">Step 3. Add Policies </Typography>
          <Typography variant="subtitle2">
            Update the list of policies by clicking on text fields.{" "}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.paddingStyle}>
          <StatePoliciesTable
            data={data.policies}
            updateSeasonPolicies={updateSeasonPolicies}
          />
        </Grid>
        <SuccessModal
          open={enableMissingInfoOpen}
          title={"Verify Season Information"}
          desc={enableMissingInfoText}
          okLabel={"Go Back"}
          handleOk={() => setEnableMissingInfoOpen(false)}
        />

        <ConfirmationDialog
          open={enableLaunchSeasonInfoOpen}
          title={"Verify Season Information"}
          desc={handleLaunchSeasonConfirm(
            policiesList,
            fundSourcesList,
            programType,
            dateRangeValue
          )}
          okLabel={creatingNew ? "Launch Season" : "Update Season"}
          cancelLabel={"Go Back"}
          handleOk={() => handleSubmitConfirm()}
          handleCancel={() => setEnableLaunchSeasonInfoOpen(false)}
        />
        <Grid item xs={12} container justifyContent="end">
          <Button
            variant="outlined"
            color="primary"
            component="span"
            size="large"
            onClick={() => handleSubmitLaunchSeason()}
            disabled={!validDateRange}
          >
            {creatingNew ? `Launch Season` : `Update Season`}
          </Button>
        </Grid>
        </>
      }
      </Grid>
    </>
  );
}
