import React from 'react'
import { Container, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FormCheckboxes from '../../../form/FormCheckboxes';
import FormRadioAsButtons from '../../../form/FormRadioAsButtons';
import StepHeader from '../../../StepHeader';
import StepButtons from '../../../StepButtons';
import FormSection from '../../../FormSection';
import useLiheapForm from '../../../../hooks/useLiheapForm';
import { healthInsuranceTypeOptions } from '../../../../constants/tx/checkboxes/healthInsuranceTypeOptions';
import { educationLevelOptions } from '../../../../constants/tx/radios/educationLevelOptions';
import { programOptions } from '../../../../constants/tx/checkboxes/programOptions';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    marginLeft: "0"
  },
  row: {
    width: "auto"
  },
  sectionHeader: {
    fontWeight: "bold"
  },
}));

export default function ApplicantAdditionalInfo({ data, back, next, save, validate, validationSchema }) {
  const { register, control, errors } = useLiheapForm(save, data, validate, validationSchema);

  const classes = useStyles();
 
  return (
    <Container className={classes.mainContainer} component="main">
        <Grid container spacing={1}>
          <StepHeader
            title="Applicant Information"
            desc="Please provide the following information for the applicant."
          />
          <FormSection>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.sectionHeader}>Additional Information</Typography>
            </Grid>
            <Grid className={classes.row} item xs={12} container justifyContent="flex-start">
              <FormCheckboxes
                defaultValues={data?.healthInsurance}
                register={register}
                name="healthInsurance"
                label="Health Insurance (more than one may be chosen)"
                errors={errors}
                options={healthInsuranceTypeOptions}
                gridSize={12}
                noOption="None"
              />
            </Grid>
            <Grid className={classes.row} item xs={12} sm={6} container justifyContent="flex-start">
              <FormRadioAsButtons 
                name="education" 
                label="Education"
                control={control}
                errors={errors}
                options={educationLevelOptions}
              />
            </Grid>
            <Grid className={classes.row} item xs={12} container justifyContent="flex-start">
              <FormCheckboxes
                defaultValues={data?.programApplyingFor}
                register={register}
                name="programApplyingFor"
                label="Please check the program you are applying for"
                errors={errors}
                options={programOptions}
                gridSize={12}
              />
            </Grid>
          </FormSection>
          <StepButtons back={back} next={next}/>
        </Grid>
    </Container>
  );
}
