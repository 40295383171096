import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Typography, Grid } from '@mui/material';
import FormTextField from '../../../../form/FormTextField';

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: "bold", 
  },
  textbox: {
    backgroundColor: '#FFF',
    marginBottom: '1.5rem',
    "& > .MuiFormHelperText-root.Mui-error": { 
      margin:0
    },
  },
}));

export default function OnPaperStatusInfo({ application, register, errors, loading }) {
  const classes = useStyles();

  return (
    <Grid container spacing={1}>
        <Grid item xs={12}>
            <Typography variant='subtitle2' className={classes.label} >
                Notes:
            </Typography>
            <FormTextField
                className={classes.textbox}
                disabled={loading}  
                name="onPaperNotes" 
                label="Type message to applicant"
                defaultValue={application?.statusInfo?.onPaperNotes}
                multiline
                rows={4}
                register={register} 
                errors={errors}     
            />
        </Grid>
    </Grid>
)}
