import { useEffect, useState } from 'react'
import FormSection from '../../FormSection';
import { Divider, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FormCheckboxes from '../../form/FormCheckboxes';
import { workStatusOptions } from '../../../constants/ar/radios/workStatusOptions';
import { payFrequency as payFrequencyOptions } from '../../../constants/dropdowns/payFrequency';
import { unearnedBenefitOptions } from '../../../constants/ar/checkboxes/unearnedBenefitOptions';
import FormRadioAsButtons from '../../form/FormRadioAsButtons';
import FormTextField from '../../form/FormTextField';
import FormCurrencyField from '../../form/FormCurrencyField';
import { incomeFieldLabels } from '../../../constants/fieldLabels';

const useStyles = makeStyles((theme) => ({
  inputField: {
    maxWidth: "40rem",
    margin: "2rem 0rem 1.5rem 0rem"
  },
  divider:{
    margin: "2rem 0rem 1.5rem 0rem"
  }
}));

const UnearnedBenefitDetails = ({type, control, errors, register, selection, selectionLabel}) => {
  const classes = useStyles();
  return (
    <>
      <Divider className={classes.divider}/>
      {selection === 'other' &&
        <Grid item xs={12} className={classes.inputField}>
          <FormTextField
            name="otherUnearnedDetails" 
            label='Please clarify "Other".' 
            labelPlacement="top" 
            register={register} 
            errors={errors}                
          />
        </Grid>   
      } 
      <Grid item xs={12} className={classes.inputField}>
        <FormCurrencyField
          name={`${selection}MonthlyIncomeAmount`}
          label={`What is ${type === "applicant" ? "your" : "this household member's"} average monthly gross pay for "${selectionLabel}"?`}
          labelPlacement="top"
          control={control}
          errors={errors}
        />
      </Grid>
      <Grid item xs={12} className={classes.inputField}>
        <FormRadioAsButtons
          control={control}
          name={`${selection}PayFrequency`} 
          label={`How often ${type === "applicant" ? "are you" : "is this household member"} paid for "${selectionLabel}"?`}
          errors={errors}
          options={payFrequencyOptions}
        />
      </Grid>
    </>
  );
}

export default function WorkStatusIncome({ control, register, setValue, errors, type, data}) {  
  const [unearnedBenefits, setUnearnedBenefits] = useState(data?.unearnedBenefits ? data.unearnedBenefits : []);

  const getSelectionLabel = (selection) => {
    const result = unearnedBenefitOptions.find(obj =>  obj.value === selection);
    return result?.label;
  }

  const unearnedBenefitsHandler = (selectedVals) => {
    unearnedBenefits.forEach(prevSelectedBenefit =>{
      if(!selectedVals.includes(prevSelectedBenefit)){
        setValue(`${prevSelectedBenefit}MonthlyIncomeAmount`, '', { shouldDirty: true });
        setValue(`${prevSelectedBenefit}PayFrequency`, '', { shouldDirty: true });
      }
    })
    
    setUnearnedBenefits(selectedVals);
    if(selectedVals[0] === 'noOption'){
      clearUnearnedBenefitDetails();
    }
  }

  const clearUnearnedBenefitDetails = () => {
    incomeFieldLabels.forEach(field => {
      if(field.value !== 'workStatus' && field.value !== 'unearnedBenefits'){
        setValue(field.value, '', { shouldDirty: true });
      }
    })
  }
  
  const workStatusHandler = (val) => {
    if (val !== 'employed'){
      setValue('employers', [], { shouldDirty: true });
    }else{
      setValue("lastEmployedDate", null, { shouldDirty: true });
      setValue("lastEmployerName", '', { shouldDirty: true });
      setValue("hasBeenEmployed", '', { shouldDirty: true });
      if (data?.employers){
        setValue('employers', data?.employers, { shouldDirty: true });
      }
    }
  }

  //register "employers" and previous employment fields so they can be set via workStatusHandler
  useEffect(()=> { 
    register("employers");
    register("lastEmployedDate");
    register("lastEmployerName");
    register("hasBeenEmployed");
  },[register])

  return (
    <>
      <FormSection>
        <Grid item xs={12}>
          <FormRadioAsButtons
            control={control}   
            name="workStatus"
            label={`What is ${type === "applicant" ? "your" : "this household member's"} current work status?`}
            errors={errors}
            options={workStatusOptions}
            onSelect={workStatusHandler}
          />             
        </Grid>
      </FormSection>
      <FormSection>
        <Grid item xs={12}>            
          <FormCheckboxes 
            defaultValues={unearnedBenefits}
            register={register}
            name="unearnedBenefits"
            label={`${type === "applicant" ? "Do you" : "Does the household member"} receive any of the following unearned income?`}
            options={unearnedBenefitOptions}
            onSelect={unearnedBenefitsHandler}
            gridSize={6}
            errors={errors}
            noOption={`No, ${type === "applicant" ? "I" : "they"} do not receive any unearned income`}
          />          
        </Grid>
        {
          unearnedBenefits?.map((selection, i) => {
            if (selection !== 'noOption') {
              return(
                <UnearnedBenefitDetails 
                  key={i} 
                  type={type} 
                  control={control} 
                  errors={errors} 
                  register={register} 
                  selection={selection} 
                  selectionLabel={getSelectionLabel(selection)}
                />
              )
            }else {
              return ""
            }
          })}
        
      </FormSection>
    </>
  )
}
