import styled from "styled-components";

export const FooterSection = styled("footer")`
  padding: 2.5rem 0;
`;

export const Para = styled("div")`
  color: #1e68c1;
  font-size: 14px;
  width: 100%;
  text-align: center;
  align-items: center;
`;
