import { Container, Grid, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { lastEmployedDateOnEditSchema } from '../../../../constants/tx/stepValidationSchemas';
import useLiheapForm from '../../../../hooks/useLiheapForm';
import StepButtons from '../../../StepButtons'
import StepHeader from '../../../StepHeader'
import Employers from '../Employers';
import PreviousEmployment from '../PreviousEmployment';

const useStyles = makeStyles((theme) => ({
  mainContainer:{
    margin: "0"
  }
}));

export default function AddEditEmployers({ data, back, next, save, validationSchema, validate, isSaving }) {
  const classes = useStyles();
  const validateOnEditValidationSchema = lastEmployedDateOnEditSchema;
  const { register, control, errors, watch, setValue, trigger } = useLiheapForm(save, data, validate, validationSchema, false, validateOnEditValidationSchema);
  const employersData = data?.employers;
  const workStatus = data?.workStatus;

  //show same page as unemployment if they did not set workStatus
  const determineEmploymentStatus = () => {
    if (workStatus === 'fullTime' || workStatus === 'partTime' || workStatus === 'migrantSeasonalOrFarmWorker'){
      return "employed";
    }else {
      return "unemployed";
    }
  }

  const employmentStatus = determineEmploymentStatus();

  return (
    <Container className={classes.mainContainer}>
        <input type="hidden" name="workStatus" ref={register} defaultValue={data.workStatus ? data.workStatus : ""}/>
        <Grid container spacing={1}>
          <StepHeader 
            title="Applicant Income Information" 
            desc = {`Please provide information for ${employmentStatus === 'unemployed' ? "previous employment" : "each employer"}.`}
          />
          { isSaving ? 
          <Grid container justifyContent="center">
            <CircularProgress color="primary" size={50} />
          </Grid>
          :
          <>
          { employmentStatus === 'employed' &&
            <Employers 
              employersData={employersData} 
              parentType={"applicant"} 
              control={control} 
              register={register}
              errors={errors}
              watch={watch}
            />
          }
          { employmentStatus === 'unemployed' &&
            <PreviousEmployment
              control={control}
              register={register}
              errors={errors}
              setValue={setValue}
              type={"applicant"}
              data={data}
              trigger={trigger}
            />
          }
          </>
        }
          <StepButtons back={back} next={next}/>
        </Grid>
    </Container>
  )
}
