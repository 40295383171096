import React, { useContext, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getApplicationById, saveApplication } from '../../api/ApplicationsAPI';
import { CircularProgress, Grid, Snackbar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory, useParams } from 'react-router';
import { Alert } from '@mui/material';
import ApplicationStatus from './ApplicationStatus';
import { statuses } from '../../constants/appStatuses';
import FeatureFlag from '../FeatureFlag';
import SidePanelStepper from './SidePanelStepper';
import { createFile, deleteFile, getFilesForApplication } from '../../api/FileAPI';
import { useErrorViewer } from '../../context/errorContext';
import { AuthContext } from '../../context/authContext';

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    paddingTop: "1.5rem"
  },
  spinner:{
    position: "absolute", 
    textAlign: "center",
    top: '50%'
  },
}));

export default function LiheapMultiStepForm() {
  const { id: appId, stepName, nestedItemId } = useParams();
  const { userInfo } = useContext(AuthContext);
  const stateConfig = userInfo?.stateInfo;
  const state = userInfo?.stateInfo?.state;
  const processSteps = stateConfig.processSteps;
  const classes = useStyles();
  const history = useHistory();
  const queryClient = useQueryClient();
  const [validate, setValidate] = useState(false);
  const [saveSuccessOpen, setSaveSuccessOpen] = useState(false);
  const [saveErrorOpen, setSaveErrorOpen] = useState(false);
  const [fileData, setFileData] = useState();
  const [deleteFileInfo, setDeleteFileInfo] = useState();
  const [createFileInfo, setCreateFileInfo] = useState();
  const setError = useErrorViewer();

  const getCurrStep = (path) => {
    const retVal = processSteps.find((item) => { return (item.location === path); });
    return retVal;
  }



  const currStep = getCurrStep(stepName);

  const mutation = useMutation(appToUpdate => {
    return saveApplication(appToUpdate.id, appToUpdate);
  },{
    // When save is called cancel any queries
    onMutate: updated => {
      queryClient.cancelQueries(['application', { id: appId }])
    },
    // Invalidate on error
    onError: (err, updated, context) => {
      queryClient.invalidateQueries(['application', { id: appId }])
    },
    // Set data to response on success
    onSuccess: response => {
      queryClient.setQueryData(['application', { id: appId }], response.data);
      //invalidate applications query for /dashboard
      queryClient.invalidateQueries('applications');
    }
  })

  const { isFetching:appLoading, error:appError, data } = useQuery(['application', { id: appId }], async () => {
    const { data } = await getApplicationById(appId);
    return data;
  }, {enabled: !!appId})

  const { isFetching:getFilesLoading, error:getFilesError } = useQuery(['applicationFiles', { id: appId }], async () => {
    const { data } = await getFilesForApplication(appId);
    return data;
  }, {enabled: !!appId, 
      onSuccess: (data) => { setFileData(data) } });
  
  const { isFetching:createFileLoading } = useQuery(['createFile', { createFileInfo }], async () => {
    const { data } = await createFile(createFileInfo.files, appId, createFileInfo.fileType, createFileInfo.fileTypeLabel, createFileInfo.employerId, createFileInfo.memberId);
    return data;
  }, {enabled: !!createFileInfo, 
    retry: false,
    onError: (err) => { setError(err.response?.data ?? "An error occurred uploading your file."); },
    onSuccess: (data) => { 
      setFileData(data); 
    }
  });

  const { isFetching:deleteFileLoading } = useQuery(['deleteFile', { appId, deleteFileInfo }], async () => {
    const { data } = await deleteFile(appId, deleteFileInfo.fileId, deleteFileInfo.fileFolder);
    return data;
  }, {enabled: !!deleteFileInfo, 
      retry: false,
      onError: (err) => { setError(err.response?.data ?? "An error occurred uploading your file"); },
      onSuccess: (data) => { 
        setFileData(data); 
      } 
  });


  const handleFileDelete = (fileInfo) => {
    setDeleteFileInfo(fileInfo)
  };

  const handleFileCreate = (fileInfo) => {
    setCreateFileInfo(fileInfo);
  }

  //Can handle 1 or 2 nested collections (i.e. "householdMembers" or "householdMembers.employers")
  const getNestedCollectionInfo = (currStep, data, locationNestedItemId, itemId) => {
    let nestedFieldName = currStep.nestedFieldName;
    let resultFormData = {};
    let secondNestedFieldName = "";
    if (nestedFieldName.includes(".")) {
      let fieldNames = nestedFieldName.split(".");
      nestedFieldName = fieldNames[0];
      secondNestedFieldName = fieldNames[1];
    }
  
    resultFormData[nestedFieldName] = data[nestedFieldName] ? data[nestedFieldName] : [];
    let nestedCollection = resultFormData[nestedFieldName];
  
    if (secondNestedFieldName) {
      //get the second nested collection
      let nestedItemIndex = nestedCollection.findIndex(x => x.id === locationNestedItemId);
      nestedCollection[nestedItemIndex][secondNestedFieldName] =
        nestedCollection[nestedItemIndex][secondNestedFieldName]
          ? nestedCollection[nestedItemIndex][secondNestedFieldName]
          : [];
      nestedCollection = nestedCollection[nestedItemIndex][secondNestedFieldName];
    }
  
    //find item's index in collection
    let itemIndex = nestedCollection.findIndex(x => x.id === itemId);
    return { itemIndex, nestedCollection, resultFormData };
  }

  const handleNestedItem = (nestedItem) => {
    let { itemIndex, nestedCollection, resultFormData } = getNestedCollectionInfo(currStep, data, nestedItemId, nestedItem.id);
    if(itemIndex !== -1){
      //nested item found, update item in collection
      Object.assign(nestedCollection[itemIndex], nestedItem);
    }else{
      //nested item not found, add to collection
      nestedCollection.push(nestedItem);
    }
    return resultFormData;
  }

  const removeNestedItem = (itemId) => {
    let { itemIndex, nestedCollection, resultFormData } = getNestedCollectionInfo(currStep, data, nestedItemId, itemId);
    if(itemIndex !== -1){
      nestedCollection.splice(itemIndex, 1);
      save(resultFormData, false);
    }
  }

  const next = (nextInfo) => {
    let navData = data;
    if (nextInfo?.hasConditionData){
      navData = nextInfo.conditionData
    }
    navigateToStep(
      navData,
      currStep.hasNextCondition,
      currStep.nextConditionalFunction,
      currStep.nextConditional,
      currStep.next
    );
  }

  const save = (formData, checkForNested = true) => {
    if (checkForNested) {
      if(currStep.isNestedItem){
        //step has a collection to save. an item (formData) has been added/edited
        formData = handleNestedItem(formData);
      }
    }
    formData.id = appId;
    formData.userId = data.userId;
    if(currStep.location !== 'dashboard'){
      //TODO: hard-coded url
      formData.currentPath = `/liheapform/${state}/${currStep.location}/${appId}/${currStep.isNestedItem ? nestedItemId : ''}`;
      if(data.highestStepEdited){
        if(currStep.stepIndex > data.highestStepEdited){
          formData.highestStepEdited = currStep.stepIndex
        }
      }
      else {
        formData.highestStepEdited = currStep.stepIndex
      }
    }
    Object.assign(data, formData);
    mutation.mutate(formData, {
      onError: () => setSaveErrorOpen(true)
    });
  }

  const navigateToStep = (navData, hasCondition, conditionalFunction, conditionalPath, defaultPath) => {
    let stepPath;
    if(hasCondition && conditionalFunction(navData)) {
      stepPath = conditionalPath;
    }else{
      stepPath = defaultPath;
    }
    history.push(stepPath.replace(":id", appId).replace(":nestedItemId", nestedItemId));
  }

  const back = (backInfo) => {
    let navData = data;
    if (backInfo?.hasConditionData){
      navData = backInfo.conditionData
    }
    navigateToStep(
      navData,
      currStep.hasPreviousCondition,
      currStep.previousConditionalFunction,
      currStep.previousConditional,
      currStep.previous
    );
  }

  const cancel = () => {
    history.push("/dashboard");
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSaveSuccessOpen(false);
    setSaveErrorOpen(false);
  };

  const renderRoutes = () => {
    let StepComponent = currStep.component;
    if(data?.status === statuses.NEW || data?.status === statuses.RETURNED){
      return (
        <StepComponent 
          data={data} 
          cancel={cancel} 
          next={next} 
          back={back}
          save={save} 
          validationSchema={currStep.stepValidationSchema}
          appId={appId} 
          removeNestedItem={removeNestedItem}
          validate={validate}
          setValidate={setValidate}
          isSaving={mutation.isLoading}
          processSteps={processSteps}
          appState={state}
          fileData={fileData}
          handleFileCreate={handleFileCreate}
          handleFileDelete={handleFileDelete}
        />
       );
    }else{
      return <ApplicationStatus data={data} />
    }
  }
  if(appLoading || createFileLoading || deleteFileLoading || getFilesLoading) {
    return(
      <Grid container item justifyContent="center" className={classes.spinner}>
        <CircularProgress color="primary" size={50} />
      </Grid>
    );
  }
  if(appError || getFilesError) {
    return(
      <Grid container item justifyContent="center" className={classes.spinner}>
        An Error has occurred
      </Grid>
    );
  }
  else {
    return (
      <div>
          <Grid container className={classes.mainGrid} justifyContent="center"> 
            { currStep.showStep && 
              <Grid xs={12} sm={3} item container direction="column">
                <SidePanelStepper data={data} currStep={currStep} fileData={fileData} />
              </Grid>
            }
              {stepName === 'status'
                ? 
                <Grid xs={12} item>
                  {renderRoutes()}
                </Grid>
                : 
                <Grid xs={12} sm={9} item container justifyContent="flex-start">
                  <FeatureFlag feature={`Update${data?.status}Application`}>{renderRoutes()}</FeatureFlag>
                </Grid>
              }
              
          </Grid>
          <Snackbar open={saveSuccessOpen} autoHideDuration={5000} onClose={handleClose}>
              <Alert severity="success" variant="filled" onClose={handleClose}>
                Saved Successfully!
              </Alert>
          </Snackbar>
          <Snackbar open={saveErrorOpen} autoHideDuration={5000} onClose={handleClose}>
            <Alert severity="error" variant="filled" onClose={handleClose}>
              Error Saving Application!
            </Alert>
          </Snackbar>
      </div>
    );
  }
}
