import * as Yup from 'yup';
const requiredError = "Required Information";

export const userInviteValidationSchema = Yup.object().shape({
  firstName: Yup.string().required(requiredError),
  lastName: Yup.string().required(requiredError),
  email: Yup.string().required(requiredError).email("Invalid Email"),
  confirmEmail: Yup.string().oneOf([Yup.ref("email"), null], "Emails don't match"),
  clientIntakePermission: Yup.string().required(requiredError),
  reviewApplicationPermission: Yup.string().required(requiredError),
  reportPermission: Yup.string().required(requiredError)
  })

export const adminInviteValidationSchema = Yup.object().shape({
  firstName: Yup.string().required(requiredError),
  lastName: Yup.string().required(requiredError),
  email: Yup.string().required(requiredError).email("Invalid Email"),
  confirmEmail: Yup.string().oneOf([Yup.ref("email"), null], "Emails don't match")
  })
    