export const documentLinks = {
  'saveForm': 'https://www.fortworthtexas.gov/files/assets/public/neighborhoods/images/documents/save-form.pdf',
  'zeroIncome': 'https://www.tdhca.state.tx.us/pmcdocs/Cert_Zero.pdf',
  'declarationIncomeStatement': 'https://www.tdhca.state.tx.us/community-affairs/csbg/docs/DeclarationIncomeStatement.pdf',
  'landlordAgreement': 'https://www.tdhca.state.tx.us/community-affairs/wap/docs/10-WAPLandlord.pdf'
}

export const documentDescriptions = {
    'saveForm': 'Electronic immigration status verification form administered by U.S. Citizenship and Immigration Services, a component of the Department of Homeland Security.',
    'zeroIncome': 'For households who are reporting having no source of income, each adult household member must complete this form.',
    'declarationIncomeStatement': 'For all household members 18 years and older, who have no documentation of the income received in the 30 day period prior to the date of application for assistance.', 
    'landlordAgreement': 'Current policy requires that renters must have the following landlord agreement signed prior to processing any weatherization applications.'
  }
  