import {
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Check, PriorityHigh, RotateLeft } from '@mui/icons-material';
import React from 'react'
import { statuses } from '../../constants/appStatuses';
import theme from '../../themes/theme';
import { formatDate } from '../../utils/utilFunctions';
import { styled } from '@mui/styles';

const StatusConnector = styled(StepConnector)(({theme}) => ({
  top: '1.5rem',
  '&.Mui-active': {
    '& .MuiStepConnector-line': {
      borderColor: theme.palette.primary.main,
    },
  },
  '&.Mui-completed': {
    '& .MuiStepConnector-line': {
      borderColor: theme.palette.primary.main,
    },
  },
  '& .MuiStepConnector-line': {
    borderColor: theme.customColors.lightGrey,
    borderWidth: '.6rem',
  },
}));

const useStepIconStyles = makeStyles({
  iconRoot: {
    backgroundColor: theme.customColors.lightGrey,
    zIndex: 1,
    width: 50,
    height: 50,
    border: '.3rem solid',
    borderColor: theme.customColors.lightGrey,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    borderColor: theme.palette.primary.main,
  },
  completed: {
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    color: '#FFF',
  },
  error: {
    borderColor: theme.customColors.errorIcon,
    color: theme.customColors.errorIcon,
    backgroundColor: '#FFF',
    fontSize: '3rem',
    borderRadius: '50%',
  },
  errorIcon: {
    backgroundColor: theme.customColors.errorIcon
  },
  whiteIcon: {
    color: '#FFF',
    fontSize: '3rem',
    borderRadius: '50%',
  }
});

function StatusStepIcon(props) {
  const classes = useStepIconStyles();
  const { active, completed, status } = props;
  
  const getCssClasses = (active, completed, status) => {
    const errorStep = (status === statuses.RETURNED || status === statuses.DENIED || status === statuses.ON_PAPER);
    let cssClasses = `${classes.iconRoot}`;
    if (active){
      if(errorStep){
        cssClasses += ` ${classes.error}`
        if(status === statuses.DENIED || status === statuses.ON_PAPER){
          cssClasses += ` ${classes.errorIcon}`
        }
      }else{
        cssClasses += ` ${classes.active}`
      }
    }else if (completed){
      cssClasses += ` ${classes.completed}`
    }
    return cssClasses;
  }

  const getActiveStepIcon = (status) => {
    switch (status) {
      case statuses.NEW:
        return;
      case statuses.RETURNED:
        return <RotateLeft className={classes.error}/>;
      case statuses.DENIED:
      case statuses.ON_PAPER:
        return <PriorityHigh className={classes.whiteIcon} fontSize="large"/>;
      case statuses.APPROVED:
        return <Check className={`${classes.iconRoot} ${classes.completed}`} fontSize="large"/>;
      default:
        return <RotateLeft color="primary" fontSize="large"/>;
    }  
  }

  return (
    <div className={getCssClasses(active, completed, status)}>
      {completed ? <Check className={classes.whiteIcon}/> : ''}
      {active ? getActiveStepIcon(status) : ''}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  statusStepper:{
    margin: "3rem 0 4rem 0",
    [theme.breakpoints.down('sm')]: {
      margin: ".6rem 0rem",
    },
    padding: "0",
    width: "100%"
  },
  stepLabel: {
    '& > .MuiStepLabel-labelContainer' :{
    '& > .MuiStepLabel-label.MuiStepLabel-completed' : {
      color: theme.customColors.lightMedGray
    }
    }
  },
  status: {
    fontWeight: 600,
    marginBottom: '.5rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: "1rem",
    },
  },
  statusInfo: {
    fontWeight: 600,
    fontSize: '1rem',
    maxWidth: '80%',
    margin: 'auto'
  }
}));

export default function StatusStepper({ currentStatus='', currentStatusDate='', statusInfo = {}, statusHistory = [] }) {
  const classes = useStyles();
  const theme = useTheme();
  const matchesXsDown = useMediaQuery(theme.breakpoints.down('sm'));

  const getSteps = () => {
    return ['Application', 'Verification', 'Pledge'];
  }
  
  const getActiveStep = (status) => {
    switch (status) {
      case statuses.NEW:
        return 0
      case statuses.SUBMITTED:
      case statuses.RE_SUBMITTED:
      case statuses.IN_REVIEW:
      case statuses.RETURNED:
      case statuses.DENIED:
      case statuses.ON_PAPER:
        return 1
      case statuses.APPROVED:
        return 2
      default:
        return 'Unknown status';
    }
  }

  const buildStatusMap = () => {
    let statusMap = {}
    if(currentStatus && currentStatusDate){
      statusMap[currentStatus] = currentStatusDate;
    }
    if(statusHistory?.length > 0){
      statusHistory.forEach((item) => {
        statusMap[item.status] = item.statusDate;
      })
    }
    return statusMap;
  }
  
  const statusMap = buildStatusMap();

  const getStatusDateInfo = (index, currentStatus, currentStatusDate, statusInfo) => {
      switch (index) {
        case 0:
          if(statuses.RE_SUBMITTED in statusMap){
            return `${statuses.RE_SUBMITTED}: ` + formatDate(statusMap[statuses.RE_SUBMITTED], "MM/dd/yyyy")
          }   
          if(statuses.SUBMITTED in statusMap){
            return `${statuses.SUBMITTED}: ` + formatDate(statusMap[statuses.SUBMITTED], "MM/dd/yyyy")
          }   
          return 'Once submitted, your application will be sent to your agency.'       
        case 1:
          if(currentStatus === statuses.RETURNED){
            return `Returned: ` + formatDate(currentStatusDate, "MM/dd/yyyy")
          } else if(currentStatus === statuses.DENIED){
            return `Denied: ` + formatDate(currentStatusDate, "MM/dd/yyyy")
          }else if(currentStatus === statuses.APPROVED){
            return `Approved: ` + formatDate(currentStatusDate, "MM/dd/yyyy")
          } else if(currentStatus === statuses.ON_PAPER){
            return `In Person: ` + formatDate(currentStatusDate, "MM/dd/yyyy")
          } else {
            return 'Application will be reviewed by your agency.'
          }
        case 2:
          if(currentStatus === statuses.APPROVED){
            return `Pledged on: ` + formatDate(statusInfo?.pledgeDate, "MM/dd/yyyy")
          }else{
            return 'If approved, a payment will be applied to your utility account.'
          }
        default: 
          return ''
      }
  }

  return (
    <Stepper 
      alternativeLabel
      className={classes.statusStepper}
      activeStep={getActiveStep(currentStatus)} 
      connector={<StatusConnector/>} 
    >
      {getSteps().map((label, index) => {
        return (
          <Step key={index}>
            <StepLabel className={classes.stepLabel} StepIconComponent={StatusStepIcon} StepIconProps={{status: currentStatus}}>
              <Typography variant="h5" className={classes.status}>{label}</Typography>             
              {!matchesXsDown && 
                <Typography className={classes.statusInfo} variant="subtitle2" >
                  {getStatusDateInfo(index, currentStatus, currentStatusDate, statusInfo)}
                </Typography>
            }
            </StepLabel>
          </Step>
        )
      })}
    </Stepper>
  )
}
