import React, { useState } from "react";
import { Grid, CircularProgress, Typography, Button } from "@mui/material";
import StateSeasonEdit from "../components/StateSeasonEdit";
import StateSeasonView from "../components/StateSeasonView";
import useStateConfigData from "../../../hooks/useStateConfigData";
import useAgencyData from "../../../hooks/useAgencyData";
import makeStyles from "@mui/styles/makeStyles";
import StateSeasonEditClosedModal from "../components/StateSeasonEditClosedModal";

const useStyles = makeStyles((theme) => ({
  getStarted: {
    backgroundColor: theme.customColors.formSection.backgroundColor,
    borderRadius: ".3rem",
    minHeight: "20rem",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  tabs: {
    marginBottom: "1.5rem",
  },
  tabHeader: {
    padding: "0",
    margin: "0 1rem 0 0 ",
  },
}));

export default function StateSeasonSettingsView({ season }) {
  const classes = useStyles();

  const [editView, setEditView] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [seasonToFetch, setSeasonToFetch] = useState(season);
  const [creatingNew, setCreatingNew] = useState(false);

  const handleOnEdit = () => {
    if(data.isOpen || season !== 'current')
      setEditView((prevState) => !prevState)
    else
      setOpenEditModal(true);

    if(creatingNew){
      setSeasonToFetch('next')
    }
  };

  const handleCloseModal = () => {
    setOpenEditModal(false);
  }

  const { isFetching, data } = useStateConfigData(seasonToFetch);
  const { isFetching: isFetchingAgency, data: agencyData } = useAgencyData();

  const createNextSeason = () => {
    setSeasonToFetch("current");
    setEditView(true);
    setCreatingNew(true);
  };

  return (
    <Grid container>
      {isFetching || isFetchingAgency ? (
        <Grid container item xs={12} justifyContent="center">
          <CircularProgress color="primary" />
        </Grid>
      ) : (
        <>
          {data ? (
            <>
              {editView ? (
                <StateSeasonEdit
                  data={data}
                  agencyData={agencyData}
                  creatingNew={creatingNew}
                  season={season}
                  onEdit={handleOnEdit}
                />
              ) : (
                <StateSeasonView
                  data={data}
                  agencyData={agencyData}
                  onEdit={handleOnEdit}
                  season={season}
                />
              )}
            </>
          ) : (
            <Grid container item xs={12} className={classes.getStarted}>
              <Typography variant="subtitle1">
                There is no information on next season, yet.
              </Typography>
              <br></br>
              <Button variant="contained" onClick={createNextSeason}>
                Click here to get started
              </Button>
            </Grid>
          )}
        </>
      )}
      <StateSeasonEditClosedModal data={data} season={season} open={openEditModal} onClose={handleCloseModal}/>
    </Grid>
  );
}
