import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography, Button } from '@mui/material';
import { aboutTextBlock,aboutTextBlock2 } from '../../constants/home-page/about';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
  mainContainer:{
    width: 'fit-content',
  },
  info: {
    margin: 'auto',
    padding: '2rem 6rem 2rem 3rem !important',
    [theme.breakpoints.down('lg')]: {
      padding: '1rem 2rem  !important',
    }
  },
  image: {
    position: 'relative',
    width: '100%',
    [theme.breakpoints.down('lg')]:  {
      margin: 'auto',
    },
  },
  title: {
    color: '#000',
    fontSize: '2.25rem'
  },
  subtext: {
    color: '#000',
    fontSize: '1rem',
    margin: '1rem 0rem',
  },
  button: {
    width: '13.625rem',
    textTransform: 'none',
    margin: '2rem 0rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  }
}));

export default function About() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid container item spacing={1} className={classes.mainContainer}>
      <Grid item md={12} lg={5} className={classes.image}>
        <img src={`/img/homepage-about.png`} alt="" width="100%" height="100%" />
      </Grid>
      <Grid container item md={12} lg={7} className={classes.info}>
        <Typography className={classes.title}>About LIHEAP</Typography>
        <Grid container item xs={12}>
        <Typography className={classes.subtext}>
            {aboutTextBlock}
        </Typography>
        <Typography className={classes.subtext}>
            {aboutTextBlock2}
        </Typography>
        </Grid>
        <Grid container item xs={12}>
          <Button variant='contained' color='primary' className={classes.button} onClick={() => history.push(`/register`)}>
            Apply Today
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )}

