import React, { useState } from 'react'
import { Grid, Button, CircularProgress, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { createInvitation } from '../../../api/UserAPI';
import { useForm } from 'react-hook-form';
import FormTextField from '../../form/FormTextField';
import FormRadioAsButtons from '../../form/FormRadioAsButtons';
import { reviewAppPermissionOptions } from '../../../constants/dropdowns/reviewAppPermissionOptions';
import { clientIntakePermissionOptions } from '../../../constants/dropdowns/clientIntakePermissionOptions';
import { yupResolver } from '@hookform/resolvers/yup';
import { userInviteValidationSchema } from '../../../constants/inviteValidationSchema';
import { agencyPermissions } from '../../../constants/agencyPermissions';
import { useErrorViewer } from '../../../context/errorContext';
import { reportPermissionOptions } from '../../../constants/dropdowns/reportPermissionOptions';
import { useQueryClient } from 'react-query';
import SuccessModal from '../../SuccessModal';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: theme.customColors.veryLightGray,
    borderRadius: 12,
    padding: '3rem !important',
  },
  section: {
    marginTop: "2rem"
  },
  subHeader:{
    margin: '1rem 0'
  },
  button:{
    textTransform: "unset !important",
    fontWeight: "bold"
  }
}));

export default function InviteUser() {
  const { handleSubmit, register, control, errors, reset, watch, setValue, getValues } = useForm({
    defaultValues: { 
      "clientIntakePermission": agencyPermissions.CLIENT_INTAKE_DASHBOARD_VIEW,
      "reviewApplicationPermission": agencyPermissions.REVIEW_APPLICATION_NONE,
      "reportPermission": agencyPermissions.REPORTS_NONE
    },
    resolver: yupResolver(userInviteValidationSchema)
  });
  const dashboardWatch = watch('clientIntakePermission');

  const [loading, setLoading] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  
  const setError = useErrorViewer();
  const classes = useStyles();

  const handleDashboardChange = (val) => {
    if(val === agencyPermissions.CLIENT_INTAKE_DASHBOARD_VIEW){
      setValue("reviewApplicationPermission", agencyPermissions.REVIEW_APPLICATION_NONE)
    }
  }

  const getSuccessModalDesc = () => {
    return (
    <>
      {`${getValues('firstName')} ${getValues('lastName')} will receive an invitation link to use the platform.`}<br /><br />
      {`The user's information will appear in the Invited tab on the Admin Panel. Once the user registers, their information will appear in the Active Users tab.`}
    </>
    )
  }

  const queryClient = useQueryClient();

  const submit = (formData) => {    
    setLoading(true);
    //set up invitation to be saved
    const permissionsList = [];
    permissionsList.push(formData.clientIntakePermission);
    permissionsList.push(formData.reviewApplicationPermission);
    permissionsList.push(formData.reportPermission);
    formData["permissions"] = permissionsList;

    delete formData.clientIntakePermission;
    delete formData.reviewApplicationPermission;
    delete formData.reportPermission;
    delete formData.confirmEmail;

    createInvitation({userType: 'agency', ...formData}).then((response) => {        
        setLoading(false);
        setSuccessModalOpen(true);
        queryClient.invalidateQueries(['invitations']);
    }).catch((error) => {
        setLoading(false);
        setError("Error creating invitation!");
    })
  }
  
  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
    reset({
      "clientIntakePermission": agencyPermissions.CLIENT_INTAKE_DASHBOARD_VIEW,
      "reviewApplicationPermission": agencyPermissions.REVIEW_APPLICATION_NONE,
      "reportPermission": agencyPermissions.REPORTS_NONE 
    });
  }

  return (
    <>
    <Grid item className={classes.mainContainer}>
      <Typography component="h1" variant="h5">
        User
      </Typography>
      <Typography className={classes.subHeader} component="h1" variant="body1">
        Please provide the following information for the new user.
      </Typography>
      <form noValidate onSubmit={handleSubmit(submit)}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <FormTextField name="firstName" label="First Name" register={register} errors={errors} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField name="lastName" label="Last Name" register={register} errors={errors} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField name="email" label="Email" inputMode="email" register={register} errors={errors} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField name="confirmEmail" label="Confirm Email" inputMode="email" register={register} errors={errors} />
          </Grid>
          <Grid item xs={12} className={classes.section}>
            <Typography component="h1" variant="h5">
              Grant permissions
            </Typography>
          </Grid>
          <Grid container item xs={12} sm={12} md={8}>
          <Grid item xs={12} sm={4}>
            <FormRadioAsButtons
              control={control}
              name="clientIntakePermission"
              label="Dashboard"
              errors={errors}
              options={clientIntakePermissionOptions}
              onSelect={handleDashboardChange}
            />
          </Grid>
          <Grid item xs={12} sm={4} >
            <FormRadioAsButtons
              control={control}
              name="reviewApplicationPermission"
              label="Application"
              errors={errors}
              options={reviewAppPermissionOptions}
              disabled={dashboardWatch === agencyPermissions.CLIENT_INTAKE_DASHBOARD_VIEW}
            />
          </Grid>
          <Grid item xs={12} sm={4} >
            <FormRadioAsButtons
              control={control}
              name="reportPermission"
              label="Reports"
              errors={errors}
              options={reportPermissionOptions}
            />
          </Grid>
          </Grid>
          {dashboardWatch === agencyPermissions.CLIENT_INTAKE_DASHBOARD_VIEW && 
          <Grid item xs={12} >
            <Typography component="h1" variant="body1">
              The user with Dashboard “View Only” permissions cannot access any of the applications on the Application page.
            </Typography>
          </Grid>          
          }
          <Grid item container xs={12} justifyContent="flex-end" className={classes.section}>
            {loading ? <CircularProgress color="primary" size={36} /> :
              <Button
                className={classes.button}
                id="submit"
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleSubmit}>
                Send Invite
              </Button>
            }
          </Grid>
        </Grid>
      </form>
      </Grid>

      <SuccessModal
        open={successModalOpen}
        title={"User Invited"}
        desc={getSuccessModalDesc()}
        okLabel="Okay"
        handleOk={handleCloseSuccessModal}
      />
    </>
  )
}
