import { APIUtils } from './APIUtils';

const getAppConfig = () => {
  return APIUtils.get("/config")
}

const getStateConfig = (season='current') => {
  return APIUtils.get(`/stateConfig?season=${season}`)
}

const getAgencies = () => {
  return APIUtils.get(`/stateConfig/agencies`)
}

const createStateConfig = (data) => {
  return APIUtils.post(`/stateConfig`, data)
}

const updateStateConfig = ({startDate, state, config}) => {
  return APIUtils.patch(`/stateConfig/${startDate}`, {state, config})
}

export {
  getAppConfig,
  getStateConfig,
  createStateConfig,
  getAgencies,
  updateStateConfig
};