
import React, { useState, useContext } from 'react';
import {
  Grid,
  Container,
  FormControlLabel,
  Button,
  Modal,
  Typography,
  useMediaQuery,
  useTheme,
  Switch,
  CircularProgress,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Redirect } from 'react-router-dom';
import { ArrowBackIos } from '@mui/icons-material';
import SettingsStepper from '../../components/SettingsStepper';
import { updateUserInfo } from '../../api/UserAPI';
import { AuthContext } from '../../context/authContext';
import { useErrorViewer } from '../../context/errorContext';

function getModalStyle() {
  const top = 50;
  const left = 50;
  
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "2.8rem",
    marginLeft: 'auto',
    marginRight: 'auto',
    width: "95%",
    [theme.breakpoints.down('lg')]: {
      padding: '2rem 1rem 0rem 1rem'
    }
  },
  notificationType: {
      alignItems: 'center',
      marginTop: '1rem'
  },
  switchLabel: {
    color: theme.customColors.darkGrey,
  },
  stepButtons: {
    marginTop: '9rem',
    [theme.breakpoints.down('sm')]: {
        marginTop: '3rem'
      },
  },
  saveButton: {
    margin: '.5rem 0rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  backButton: {
    padding: '0rem 1rem',
    margin: '.5rem 0rem',
    color: theme.palette.primary.main,    
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  modal: {
    position: 'absolute',
    background: '#FFFFFF',
    boxShadow: '0 0 .3rem rgba(0, 0, 0, 0.2)',
    boxSizing: 'inherit',
    borderRadius: '1.75rem',
    padding: '2rem',
    width: '20rem'
  },
  modalHeader:{
    marginBottom: '1rem'
  },
  modalButton: {
    marginTop: '2.5rem',
    textTransform: 'none',
    fontSize: '1rem'
  }
}));

export default function UpdateNotifications() {
  const classes = useStyles();
  const theme = useTheme();
  const matchesXsDown = useMediaQuery(theme.breakpoints.down('sm'));
  const { userInfo, setUserInfo } = useContext(AuthContext);

  const [navToAccountSettings, setNavToAccountSettings] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  
  const [email, setEmail ] = useState(userInfo.emailNotificationsEnabled);
  const [text, setText] = useState(userInfo.smsNotificationsEnabled);

  const [importantModalOpen, setImportantModalOpen] = useState(false);
  const [allSetModalOpen, setAllSetModalOpen] = useState(false);
  const [modalStyle] = useState(getModalStyle);

  const setError = useErrorViewer();

  const handleChange = (event) => {
    let key = event.target.name;
    let value = event.target.checked;
    if(key==='email'){
        if(!value && !text){
            setImportantModalOpen(true)
        }
        else{
            setEmail(!email)
        }
    }
    if(key==='text'){
        if(!value && !email){
            setImportantModalOpen(true)
        }
        else{
            setText(!text)
        }
    }
};

const handleSave = () => {
  setSaveLoading(true);
  updateUserInfo(userInfo.id, {'emailNotificationsEnabled': email, 'smsNotificationsEnabled': text}).then(() => {
    setUserInfo((prevState) => {return {...prevState, 'emailNotificationsEnabled': email, 'smsNotificationsEnabled': text}})
    setAllSetModalOpen(true)
    setSuccessful(true)
    setSaveLoading(false)
  }).catch((error) => {
    setSaveLoading(false)
    const err = error.response.data? error.response.data.message : "Unknown Error";
    setError(err);
  })
}

if (navToAccountSettings)
  return (
    <Redirect to={{pathname: "/account", state: {successfulUpdate: successful, attribute: 'Notifications'}}}></Redirect>
)

else 
  return (
    <>
    <Container className={classes.mainContainer}>
      <Grid container spacing={1}>
      {matchesXsDown &&
          <SettingsStepper attribute={true} attributeLabel={'Notifications'} url={'/notifications'}/>
        }
        <Grid container item xs={12} justifyContent="flex-start" direction="column">
          <Typography variant="h2">
            Notification Preferences
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            Choose how you would like to receive application updates. At least one method must be turned on.
          </Typography>
        </Grid>
        <Grid container item className={classes.notificationType}>
          <Grid container item xs={6}>
            <Typography variant="h6">Email Notification</Typography>
          </Grid>
          <Grid container item xs={6} justifyContent={matchesXsDown ? "flex-end" : "flex-start"}>
            <FormControlLabel
              className={classes.switchLabel}
              checked={email}
              name="email"
              control={<Switch color="primary"/>}
              onChange={handleChange}
              label={email ? 'On' : 'Off'}
            />
          </Grid>
        </Grid>
        <Grid container item className={classes.notificationType}>
          <Grid container item xs={6}>
            <Typography variant="h6">Text Notification</Typography>
          </Grid>
          <Grid container item xs={6} justifyContent={matchesXsDown ? "flex-end" : "flex-start"}>
            <FormControlLabel
              className={classes.switchLabel}
              checked={text}
              name="text"
              control={<Switch color="primary"/>}
              onChange={handleChange}
              label={text ? 'On' : 'Off'}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} className={classes.stepButtons} direction={matchesXsDown ? "column-reverse" : "row"}>
          <Grid sm={2} item container justifyContent={matchesXsDown ? "center" : "flex-start"}>
            <Button
              className={classes.backButton} 
              onClick={() => setNavToAccountSettings(true)}
              color="primary"
              variant={matchesXsDown ? "outlined" : "text"}
              startIcon={matchesXsDown ? "" : <ArrowBackIos/>}
            >
              Back
            </Button>
          </Grid>
          <Grid sm={10} item container justifyContent={matchesXsDown ? "center" : "flex-end"}>
          {saveLoading ?
            <CircularProgress color="primary" /> 
              :
            <Button
             className={classes.saveButton} 
              onClick={handleSave}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          }
          </Grid>
        </Grid>
      </Grid>
    </Container>
    
    <Modal open={importantModalOpen}>
      <Grid style={modalStyle} className={classes.modal}>
        <Grid item container justifyContent="flex-start">
          <Typography variant="h4" className={classes.modalHeader}>
            Important
          </Typography>
        </Grid>
        <Grid item container justifyContent="flex-start">
          <Typography variant="subtitle1">
            You must have either email or text notifications turned on to be able to continue.
          </Typography>
        </Grid>
        <Grid item container justifyContent="flex-end" >
          <Button
            className={classes.modalButton} 
            onClick={() => setImportantModalOpen(false)}
            variant="text"
            color="primary"
          >
            Ok
          </Button>
        </Grid>
      </Grid>
    </Modal>

    <Modal open={allSetModalOpen} >
      <Grid style={modalStyle} className={classes.modal}>
        <Grid item container justifyContent="flex-start">
          <Typography variant="h4" className={classes.modalHeader}>
            All Set
          </Typography>
        </Grid>
        <Grid item container justifyContent="flex-start">
          <Typography variant="subtitle1">
            {`You will receive updates via ${email ? 'email' : ''}${email && text ? ' & ' : ''}${text ? 'text' : ''}.`}
          </Typography>
        </Grid>
        <Grid item container justifyContent="flex-end" >
          <Button
            className={classes.modalButton} 
            onClick={() => setNavToAccountSettings(true)}
            variant="text"
            color="primary"
          >
            Ok
          </Button>
        </Grid>
      </Grid>
    </Modal>
  </>
  );
}
