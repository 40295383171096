import { Button, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ArrowBackIos } from '@mui/icons-material'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  mainGridContainer: {
    marginTop: "3rem"
  }
}));

export default function StepButtons( { back, next, disableContinue = false, showContinue = true, continueLabel = 'Continue', }) {
  const classes = useStyles();
  return (
    <Grid item xs={12} container className={classes.mainGridContainer}>
      <Grid xs={3} item container justifyContent="flex-start">
        {back && 
          <Button
            onClick={back}
            color="primary"
            startIcon={<ArrowBackIos/>}
          >
            Back
          </Button>
        }
      </Grid>
      <Grid xs ={9} item container justifyContent="flex-end">
        {showContinue &&
          <Button
            onClick={next}
            variant="contained"
            color="primary"
            disabled={disableContinue}
          >
            {continueLabel}
          </Button>
        }
      </Grid>
    </Grid>
  )
}
