import React from 'react'
import { Container, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FormRadioAsButtons from '../../../form/FormRadioAsButtons';
import FormCheckboxes from '../../../form/FormCheckboxes';
import StepHeader from '../../../StepHeader';
import StepButtons from '../../../StepButtons';
import FormSection from '../../../FormSection';
import useLiheapForm from '../../../../hooks/useLiheapForm';
import { proofOfCitizenshipOptions } from '../../../../constants/tx/radios/proofOfCitizenshipOptions';
import { governmentIssuedPhotoIDOptions } from '../../../../constants/tx/radios/governmentIssuedPhotoIDOptions';
import { otherFormOfIdentificationOptions } from '../../../../constants/tx/checkboxes/otherFormOfIdentificationOptions';
import { useParams } from 'react-router';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    marginLeft: "0"
  },
  sectionHeader: {
    fontWeight: "bold"
  },
}));

export default function MemberDocumentInfo({ data, back, next, save, validate, validationSchema }) {
  const classes = useStyles();

  const { nestedItemId } = useParams();
  const nestedSave= (formData) => {
    formData.id = nestedItemId;  //add nestedItemId to returned formData
    save(formData);
  }
  const memberData = data?.householdMembers?.find(x => x.id === nestedItemId);

  const { control, errors, register, setValue, watch } = useLiheapForm(nestedSave, memberData, validate, validationSchema);

  const proofOfCitizenshipWatch = watch('proofOfCitizenship');
  const governmentIssuedPhotoIDWatch = watch('governmentIssuedPhotoID');

  const getTitle = () => {
    const itemIndex = memberData && (data?.householdMembers?.indexOf(memberData) >= 0) ? data?.householdMembers?.indexOf(memberData) + 1 : data?.householdMembers?.length + 1 || 1;
    return memberData && memberData.lastName && memberData.firstName ? `${memberData.firstName} ${memberData.lastName} Documents` : `Household Member #${itemIndex} Documents`;
  }

  const proofOfCitizenshipHandler = (val) => {
    if (val !== 'noneOfTheAbovePOC'){
      setValue('governmentIssuedPhotoID', '', { shouldDirty: true });
      setValue('otherFormOfIdentification', [], { shouldDirty: true });
    }
  }

  const governmentIssuedPhotoIDHandler = (val) => {
    if (val !== 'noneOfTheAboveGovId'){
      setValue('otherFormOfIdentification', [], { shouldDirty: true });
    }
  }
 
  return (
    <Container className={classes.mainContainer} component="main">
        <Grid container spacing={1}>
          <StepHeader
            title={getTitle()}
            desc="Please provide the following information for the household member."
          />
          <FormSection>
            <Grid item xs={12} container justifyContent="flex-start">
              <FormRadioAsButtons 
                name="proofOfCitizenship" 
                label="Does this household member have any of the following documents?"
                control={control}
                errors={errors}
                options={proofOfCitizenshipOptions}
                onSelect={proofOfCitizenshipHandler}
              />
            </Grid>
          </FormSection>
          {proofOfCitizenshipWatch === 'noneOfTheAbovePOC' &&
          <>
            <FormSection>
              <Grid item xs={12} container justifyContent="flex-start">
                <FormRadioAsButtons 
                  name="governmentIssuedPhotoID" 
                  label="You will have to provide a birth certificate as well as one of the documents below. Does this household have any of the following documents?"
                  control={control}
                  errors={errors}
                  options={governmentIssuedPhotoIDOptions}
                  onSelect={governmentIssuedPhotoIDHandler}
                />
              </Grid>
            </FormSection>
            {governmentIssuedPhotoIDWatch === 'noneOfTheAboveGovId' &&          
             <FormSection>
                <Grid item xs={12} container justifyContent="flex-start">
                  <FormCheckboxes
                    defaultValues={memberData?.otherFormOfIdentification}
                    register={register}
                    name="otherFormOfIdentification"
                    label="You will have to provide a birth certificate as well as two of the documents below. Please select two of the following documents this household member can provide."
                    errors={errors}
                    options={otherFormOfIdentificationOptions}
                    gridSize={12}
                  />
                </Grid>
              </FormSection>
            }
          </>
          }
          <StepButtons back={back} next={next}/>
        </Grid>
    </Container>
  );
}
