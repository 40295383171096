import { useContext, useEffect, useState } from 'react';
import { ErrorContext } from '../context/errorContext';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export default function ErrorViewer() {

    const { error, setError, severity } = useContext(ErrorContext)
    const [ open, setOpen ]  = useState(false);

    useEffect(() => {
        if(error) 
            setOpen(true);
    }, [error])

    const handleClose = () => {
        setOpen(false);
        setError("");
    }

    return (
        <>
            <Snackbar 
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                open={open}  
                onClose={handleClose} 
            >
                <Alert onClose={handleClose} severity={severity ? severity : "error"}>
                    {error}
                </Alert>
            </Snackbar>
        </>)
  }
