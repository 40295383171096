import { ListItem, List, ListItemText, Typography, Grid,} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { getAdditionalFileType, getGeneralFileTypes, getIncomeFileTypes, getUnearnedIncomeFileTypes, getUtilityFileTypes } from '../../../constants/tx/fileUtils';
import FormSection from '../../FormSection'
import FileTypeUploadSection from '../FileTypeUploadSection';

const useStyles = makeStyles((theme) => ({
  sectionHeader: {
    paddingLeft: "0",
    fontWeight: "bold"
  },
  sectionDesc:{
   paddingLeft: "0",
  },
  listItem: {
    marginTop: ".6rem",
    paddingLeft: "0"
  },
  listItemTextPrimary: {
    fontWeight: "bold"
  },
  nameInfo: {
    marginLeft: ".6rem",
    fontWeight: "bold"
  },
  reqAdditional: {
    fontWeight: "bold"
  }
}));

export default function Documents({ appId, appData, fileData, handleFileCreate, handleFileDelete, type, memberId}) {
  const classes = useStyles();
  const employers = appData?.employers;
  const generalFileTypes = getGeneralFileTypes(appData, type);
  const unearnedIncomeFileTypes = getUnearnedIncomeFileTypes(appData);
  const utilityFileTypes = getUtilityFileTypes(appData);
  const additionalFileTypes = getAdditionalFileType();

  const showIncomeSection = (data) => {
    return (data && (data['workStatus'] === 'fullTime' || data['workStatus'] === 'partTime' || data['workStatus'] === 'migrantSeasonalOrFarmWorker'));
  }

  const initializeEmployersToIncomeTypesMap = (data) => {
    let empIncomeTypes = {};
    data?.employers?.forEach((employer) => {
      empIncomeTypes[employer.id] = getIncomeFileTypes(data, employer)
    })
    
    return empIncomeTypes;
  }

  const employersToIncomeTypesMap = initializeEmployersToIncomeTypesMap(appData);

  const renderUtilitySection = () => {
    return (
      <List>
        { utilityFileTypes.map((item, index) => {
          return(
            <FormSection key={index}>
              <FileTypeUploadSection
                appId={appId}
                fileTypeObj={item}
                index={index}
                fileData={fileData}
                fileTypeLength={utilityFileTypes?.length}
                includeDivider={false}
                deleteHandler={handleFileDelete}
                createHandler={handleFileCreate}
                memberId={memberId}
              />
            </FormSection>
          )
          })
        } 
      </List>
    )
  }

  const renderAdditionalDocumentsSection = () => {
    return (
      <List>
        {additionalFileTypes.map((item, index) => {
          return(
            <FormSection key={index} >
              {item.value === 'saveForm' && 
                <Typography variant='h6' className={classes.reqAdditional}>
                  Required Supporting Forms 
                </Typography>
              }
              <FileTypeUploadSection
                appId={appId}
                fileTypeObj={item}
                index={index}
                fileData={fileData}
                fileTypeLength={additionalFileTypes?.length}
                includeDivider={false}
                optional={item.optional}
                deleteHandler={handleFileDelete}
                createHandler={handleFileCreate}
                memberId={memberId}
              />
            </FormSection>  
          )
        })
      }
    </List>
    )
  }
  const renderGeneralTypesSection = () => {
    return (
      <>
        {generalFileTypes?.length > 0 &&
          <FormSection>
            <Grid item xs={12} container alignItems="center">
              <Typography variant="h6" className={classes.sectionHeader}>General Documents</Typography>
              {appData?.firstName && 
                <Typography variant="subtitle1" color="textSecondary" className={classes.nameInfo}>{`( ${appData.firstName} ${appData.lastName ? appData.lastName : ''} )`}</Typography>
              }
            </Grid>
            <Grid item xs={12}>            
              <List>
                {generalFileTypes.map((item, index) => {
                  return (
                    <FileTypeUploadSection
                      appId={appId}
                      fileTypeObj={item}
                      key={index}
                      index={index}
                      fileData={fileData}
                      fileTypeLength={generalFileTypes.length}
                      deleteHandler={handleFileDelete}
                      createHandler={handleFileCreate}
                      memberId={memberId}
                    />
                  )
                })}
              </List>            
            </Grid>
          </FormSection>
        }
      </>
    )
  }

  const renderIncomeSection = () => {
    return (
      <>
      {unearnedIncomeFileTypes?.length > 0 &&
        <FormSection>
          <Grid item xs={12} container alignItems="center">
            <Typography variant="h6" className={classes.sectionHeader}>Unearned Income Documents</Typography>
          </Grid>
          <Grid item xs={12}>            
            <List>
              {unearnedIncomeFileTypes.map((item, index) => {
                return (
                  <FileTypeUploadSection
                    key={index}
                    appId={appId}
                    deleteHandler={handleFileDelete}
                    createHandler={handleFileCreate}
                    fileTypeObj={item}
                    index={index}
                    fileData={fileData}
                    fileTypeLength={unearnedIncomeFileTypes.length}
                    memberId={memberId}
                  />
                )
              })}
            </List>            
          </Grid>
        </FormSection>
      }
      {showIncomeSection(appData) && employers?.map((employer, index) => {
        return(
          <FormSection key={index}>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.sectionHeader}>{`Employer ${index + 1} Income Documents`}</Typography>
              <Typography variant="subtitle1" color="textSecondary" className={classes.sectionDesc}>
                {`Please provide copies of ${type === 'applicant' ? `the ${type}` : `this ${type}`}'s most recent paystubs. (One full month of paystubs are required)`}
              </Typography>
            </Grid>
            <Grid item xs={12}>                          
              <List>
                <ListItem className={classes.listItem} key="100000">
                  <ListItemText
                    primary={`Employer ${index + 1}`}
                    primaryTypographyProps={{className: classes.listItemTextPrimary}}
                    secondary={
                      <Typography color="textSecondary" component="span">
                      {employer.name ? employer.name : ""}
                      </Typography>
                    }
                  />
                </ListItem>
                {employersToIncomeTypesMap[employer.id]?.map((item, index) => {     
                  return (
                    <FileTypeUploadSection
                      appId={appId}
                      deleteHandler={handleFileDelete}
                      createHandler={handleFileCreate}
                      fileTypeObj={item}
                      key={index}
                      index={index}
                      fileData={fileData}
                      fileTypeLength={employersToIncomeTypesMap[employer.id]?.length}
                      employerId={employer.id}
                      memberId={memberId}
                    />
                  )       
                })}
              </List>
            </Grid>
          </FormSection>
        )
      })}
    </>
    )
  }

  switch (type) {
    case 'utility':
      return renderUtilitySection();
    case 'additionalDocuments':
      return renderAdditionalDocumentsSection();
    case 'applicant':
      return (
        <>
          { renderGeneralTypesSection() }
          { renderIncomeSection() }
        </>
      );
    case 'household member':
      return (
        <>
          { renderGeneralTypesSection() }
          { renderIncomeSection() }
        </>
      );
    default :
      return (<>Invalid Document Type</>);
  }
}
