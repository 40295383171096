import { unearnedBenefitOptions } from "./checkboxes/unearnedBenefitOptions"
import { calculateAge } from "../../utils/utilFunctions"
import { statuses } from "../appStatuses"

const disconnectFileType = {
  value: 'disconnectNotice',
  label: 'Disconnect Notification',
  description: "Disconnect Notification"
}

const shutOffNoticeFileType = {
  value: 'shutOffNotice',
  label: 'Shutoff Notification',
  description: "Shutoff Notification"
}

const disabilityFileType = {
  value: 'disability',
  label: 'Proof of Disability',
  description: "Please upload a copy of :typeContext proof of disability."
}

const idCardFileType = {
  value: 'idCard',
  label: 'Picture ID',
  description: "Please upload a copy of :typeContext driver's license or picture identification."
}

const socialSecurityCardFileType = {
  value: 'ssCard',
  label: 'Social Security Card',
  description: "Please upload a copy of :typeContext Social Security card."
}

const incomeFileType = {
  value: 'paystub',
  label: 'Paystub',
  description: "",
  employerName: "",
  employerId: ""
}

const electricityFileType = {
  value: 'electricity',
  label: 'Electricity Bill',
  description: "Please provide your most recent heating/cooling bill."
}

const naturalGasFileType = {
  value: 'naturalGas',
  label: 'Natural Gas',
  description: "Please provide your most recent natural gas bill."
}

const propaneFileType = {
  value: 'propane',
  label: 'Propane, Butane, or LPG Bill',
  description: "Please provide your most recent propane, butane, or LPG' bill."
}

const rentLeaseFileType = {
  value: 'rentLease',
  label: 'Lease',
  description: 'Please provide a copy of your lease that indicates utilities are included in your rent.'
}

const additionalFileType = {
  value: 'additional',
  label: 'Additional Documents',
  description: 'You may add additional documents here.'
}

export const getAdditionalFileType = () => {
  return [additionalFileType];
}

export const getRequiredAdditionalFileTypes = (appData, requiredStateForms) => {
  const fileTypes = [];
  const fileIds = [];
  
  requiredStateForms?.forEach(form => {
    fileTypes.push({value: `requiredAdditional_${form.id}`, label: form.title, description: "Please download, fill out, and upload this form before submitting your application.", url: form.url})
    fileIds.push(form.id)
  })

  if(appData?.status === statuses.RETURNED && appData?.statusInfo?.returnedNeededForms){
    const neededForms = appData?.statusInfo?.returnedNeededForms
    neededForms?.forEach(form => {
      if(!fileIds.includes(form.id)){
        fileTypes.push({value: `requiredAdditional_${form.id}`, label: form.title, description: "Please download, fill out, and upload this form before submitting your application.", url: form.url})
      }
    })
  }

  return fileTypes;
}

export const getGeneralFileTypes = (appData, type) => {
  let fileTypes = [];
  const age = appData && appData['birthDate'] ? calculateAge(appData['birthDate']) : null;
  if(appData && (age === null || age >= 18)){
    addGeneralFileType(idCardFileType, fileTypes, type);
    addGeneralFileType(socialSecurityCardFileType, fileTypes, type);
  }
  else{
    let optionalSocialSecurityCardFileType = {...socialSecurityCardFileType, optional: true}
    addGeneralFileType(optionalSocialSecurityCardFileType, fileTypes, type);
  }
  if(appData && appData['disabled'] === 'yes'){
    addGeneralFileType(disabilityFileType, fileTypes, type);
  }
  return fileTypes;
}

const addGeneralFileType = (generalFileType, fileTypes, type = 'applicant') => {
  let typeContext = "the applicant's";
  if(type !== 'applicant'){
    typeContext = "this household member's";
  }
  let fileType = Object.assign({}, generalFileType);
  fileType.description = fileType.description.replace(':typeContext', typeContext);
  fileTypes.push(fileType);
}

export const getUnearnedIncomeFileTypes = (appData) => {
  let fileTypes = [];
  const unearnedBenefits = appData?.unearnedBenefits;
  unearnedBenefits?.forEach((item) => {
    if(item !== "noOption"){
      let selection = unearnedBenefitOptions.find(obj =>  obj.value === item);
      if (selection){
        let displayLabel = selection.label;
        if(selection.value === 'other'){
          let otherUnearnedDetails = appData['otherUnearnedDetails'];
          if (otherUnearnedDetails){
            displayLabel = `${displayLabel} - ${otherUnearnedDetails}`
          }
        }      
        let fileType = {
          value: `unearned-${selection.value}`,
          label: displayLabel,
          description: selection.value === 'unemploymentInsurance' ? 'Please contact Arkansas Division of Workplace Services to obtain and upload proof of Unemployment Insurance benefits. Your application cannot be approved without this documentation.' : `Supporting documentation for ${selection.label}.`
        }
        fileTypes.push(fileType);
      }
    }
  })
  return fileTypes;
}

export const getIncomeFileTypes = (appData, employer) => {
  let fileTypes = [];
  if(appData && appData['workStatus'] === 'employed'){    
    let fileType = Object.assign({}, incomeFileType)
    fileType["employerId"] = employer.id;
    fileType["employerName"] = employer.name;
    fileTypes.push(fileType);  
  }
  return fileTypes;
}

export const getUtilityFileTypes = (appData) => {
  let fileTypes = [];
  
  if(appData?.primarySource === 'electricity' || appData?.secondarySources?.includes('electricity')){
    fileTypes.push(electricityFileType);
  }
  if(appData?.primarySource === 'naturalGas' || appData?.secondarySources?.includes('naturalGas')){
    fileTypes.push(naturalGasFileType);
  }
  if(appData?.primarySource === 'propane' || appData?.secondarySources?.includes('propane')){
    fileTypes.push(propaneFileType);
  }
  if(appData && appData['serviceShutOff'] === 'yes'){
    fileTypes.push(shutOffNoticeFileType);
  }
  if(appData && appData['disconnectNotice'] === 'yes'){
    fileTypes.push(disconnectFileType);
  }
  if(appData && appData['rentOrOwn'] === 'rent' && appData['rentEnergyCostIncluded'] === 'yes'){
    fileTypes.push(rentLeaseFileType);
  }
  return fileTypes;
}

export const getRequiredGenAndIncomeFileTypes = (appData) => {
  let requiredFileTypes = [];
  requiredFileTypes.push(...getGeneralFileTypes(appData));
  requiredFileTypes.push(...getUnearnedIncomeFileTypes(appData));
  appData?.employers?.forEach(employer => requiredFileTypes = requiredFileTypes.concat(getIncomeFileTypes(appData, employer)));
  return requiredFileTypes;
}
