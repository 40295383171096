export const heatOptions = [
    {
      value: 'electricity',
      label: 'Electricity'
    },
    {
      value: 'fuelOilOrKerosene',
      label: 'Fuel Oil or Kerosene'
    },
    {
      value: 'naturalGas',
      label: 'Natural Gas'
    }, 
    {
      value: 'propane',
      label: 'Propane, Butane, or LPG'
    },
    {
      value: 'other',
      label: 'Other'
    }
]