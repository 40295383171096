import { useState } from 'react';
import { Grid, Typography, Modal, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
    };
}

const useStyles = makeStyles((theme) => ({
  modal: {
    position: 'absolute',
    background: theme.customColors.modalBackground,
    boxShadow: '0 0 .3rem rgba(0, 0, 0, 0.2)',
    bozSizing: 'inherit',
    borderRadius: '1.75rem',
    padding: '1.5rem',
    width: '20rem'
  },
  modalHeader:{
    marginBottom: '1rem',
    textTransform: 'capitalize'
  },
  modalDesc: {
    overflowWrap: 'anywhere'
  },
  modalButton: {
    marginTop: '1rem',
    textTransform: 'none'
  }
}));

export default function SuccessModal({ open, title, desc="", handleOk, okLabel="Okay"}){
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  return (
        <Modal open={open} onClose={handleOk}>
          <Grid  style={modalStyle} className={classes.modal}>
            <Grid item container justifyContent="flex-start">
              <Typography variant="h5" className={classes.modalHeader}>
                {title}
              </Typography>
            </Grid>
            <Grid item container justifyContent="flex-start">
              <Typography variant="subtitle1" className={classes.modalDesc}>
                {desc}
              </Typography>
            </Grid>
            <Grid item container justifyContent="flex-end" >
              <Button className={classes.modalButton} onClick={handleOk} variant="text" color="primary">
                {okLabel}
              </Button>
            </Grid>
          </Grid>
        </Modal>
  )
}
