import { useContext } from 'react';
import {Redirect, Route } from 'react-router-dom';
import { AuthContext } from '../context/authContext';

export default function ProtectedRoute (props) {
        const { userInfo } = useContext(AuthContext);
        const userType = props.userType? props.userType : "";
        const state = props.state? props.state : "";
        const allowedPermissions = props.allowedPermissions ? props.allowedPermissions : [];
        const unauthorized = "/unauthorized";

        const renderRoute = () => {
            return (
                <Route path={props.path}>
                    {props.children}
                </Route>
                ); 
        }

        const renderUnauthorizedRoute = () => {
            return (
                <Route path={props.path}>
                    <Redirect to={unauthorized}></Redirect>
                </Route>
                ); 
        }

        const renderLoginRoute = () => {
            const url = `/login?redirect=${window.location.pathname}`
            return (
                <Route path={props.path}>
                    <Redirect to={url}></Redirect>
                </Route>
                );
        }

        if (!userInfo){
            return renderLoginRoute();
        }

        if(userType === userInfo["userType"]) {
            if(state && state !== userInfo["serviceState"]){
                return renderUnauthorizedRoute();
            }
            if (allowedPermissions && allowedPermissions.length > 0 ){
                const allowed = allowedPermissions.some(permission => userInfo.permissions && userInfo.permissions.includes(permission))
                if (allowed){
                    return renderRoute();
                }else{
                    return renderUnauthorizedRoute();
                }
            }
            else {
                return renderRoute();
            }
        }
        else {
            return renderUnauthorizedRoute();
        } 
    }
