import { useEffect, useState } from 'react'
import { Grid, Typography, Modal, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Notes from './Notes';
import History from './History';
import { useQuery } from 'react-query';
import { getHistoricApplicationExcludePrev } from '../api/AgencyAPI';
import { useQueryClient } from 'react-query';


function getModalStyle() {
    const top = 50;
    const left = 50;
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`
    };
  }

const useStyles = makeStyles((theme) => ({
  modal: {
    position: 'absolute',
    background: 'linear-gradient(0deg, rgba(29, 93, 177, 0.11), rgba(29, 93, 177, 0.11)), #FDFBFF',
    boxShadow: '0 0 .3rem rgba(0, 0, 0, 0.2)',
    bozSizing: 'inherit',
    borderRadius: '1.75rem',
    padding: '3rem',
    width: '40rem',
    height: '30rem',
    overflow: 'scroll'
  },
  modalHeader:{
    marginBottom: '1rem',
    textTransform: 'capitalize'
  },
  selectedModalView: {
    color: '#3576CB',
    cursor: "pointer",
  },
  otherModalView: {
    cursor: "pointer",
  },
  spinner:{
    left: '50%;',
    position: "absolute", 
    textAlign: "center",
    top: '50%',
    marginTop: '1rem'
  },
}));

export default function NotesAndHistory({ application, open, handleClose, canEdit, handleChange }){
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  const [notesAndHistoryOpen, setNotesAndHistoryOpen] = useState(open);
  const [notesView, setNotesView] = useState('notes');
  const queryClient = useQueryClient();

  const appId = application?.id
  const recordType = application?.recordType

  const { isLoading, isFetching, data } = useQuery(['applicationNotesAndHistory', { id: appId, recordType: recordType }], async () => {
    const { data } = await getHistoricApplicationExcludePrev(appId, recordType)
    return data;
  }, {enabled: !!appId}, {enabled: !!recordType})

  useEffect(() => {
    setNotesView('notes')
    setNotesAndHistoryOpen(open)
  // eslint-disable-next-line
}, [open]);

  const handleNotesChange = () => {
    handleChange('notes');
    queryClient.invalidateQueries(['applicationNotesAndHistory']);
  }

  return(
    <>
      <Modal open={notesAndHistoryOpen} onClose={() => handleClose('notes')}>
        <Grid  style={modalStyle} className={classes.modal}>
          <Grid container className={classes.modalHeader}>
            <Grid container item xs={6} justifyContent='center'>
              <Typography variant='h6' onClick={() => setNotesView('notes')} className={notesView === 'notes' ? classes.selectedModalView : classes.otherModalView}>
                Notes
              </Typography>
            </Grid>
            <Grid container item xs={6} justifyContent='center' onClick={() => setNotesView('history')} className={notesView === 'history' ? classes.selectedModalView : classes.otherModalView}>
              <Typography variant='h6'>
                History
              </Typography>
            </Grid>
          </Grid>
          {(isLoading || isFetching) ? 
            <CircularProgress className={classes.spinner}/>         
          :
          <>
            {notesView === 'notes' &&
              <Notes application={data} onChange={handleNotesChange} canEdit={canEdit}/>
            }
            {notesView === 'history' &&
              <History application={data}/>
            }
          </>
          }
        </Grid>
      </Modal>

    </>
  );
}
