import { format } from 'date-fns';

/**
 * Formats given date string into given format.
 * 
 * @param {string} date 
 * @param {string} formatArg
 * @returns 
 */
const formatDate = (date, formatArg="MM/dd/yyyy") => {
  let dateFormatted = "";
  if(date){
    dateFormatted = format(new Date(date), formatArg);
  }
  return dateFormatted;
}

/**
 * Returns true if obj is empty {}.
 * 
 * @param {object} obj 
 * @returns 
 */
const isEmptyObject = (obj) => {
  return obj && Object.keys(obj).length === 0;
}

/**
 * Returns message from errors object with property name.
 * 
 * @param {object} errors 
 * @param {string} name 
 * @returns 
 */
const getErrorMessage = (errors, name) => {
  let msg = ""
  if(!isEmptyObject(errors)){
    //field array
    if(name?.includes('.')){
      let collectionName = name.substr(0, name.indexOf('['));
      let index = name.substr(name.indexOf('[') + 1, 1)
      let propertyName = name.substr(name.indexOf('.') + 1)
      msg = errors?.[collectionName]?.[index]?.[propertyName]?.message;
    }
    else{
      msg = errors[name]?.message;
    }
  }
  return msg
}

/**
 * Truncate string to n characters and append '...' to the end.
 * 
 * @param {string} str 
 * @param {int} n 
 * @returns 
 */
const truncateForDisplay = (str, n) => {
  return (str && str.length > n) ? str.substr(0, n) + '...' : str;
};

/**
 * Given a date of birth string value, return age in years
 * 
 * @param {string} dobString 
 * @returns 
 */
const calculateAge = (dobString) => { 
  let age = "";
  if(dobString){
    const dob = new Date(dobString);
    const diff_ms = Date.now() - dob.getTime();
    const age_dt = new Date(diff_ms); 
    age = Math.abs(age_dt.getUTCFullYear() - 1970);
  }
  return age;
}

/**
 * Given an amount string, return formatted currency for usd ($x,xxx.xx).
 * 
 * @param {string} amount 
 * @returns 
 */
const formatCurrency = (amount) => {
  if(amount){
    // Create our number formatter.
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',

      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
    return formatter.format(amount);
  }
}

/**
 * Format phone number string.
 * 
 * @param {string} phoneNumberString 
 * @returns 
 */
const formatPhoneNumber = (phoneNumberString)=> {
  let phoneNum = '';
  if(phoneNumberString) {
    phoneNum = phoneNumberString;
    if (phoneNum.startsWith("+1")) 
      phoneNum =  phoneNum.slice(2);

    let cleaned = ('' + phoneNum).replace(/\D/g, '');
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      phoneNum = '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
  }
  return phoneNum;
}

/**
 * Strips formatting from phone number and adds US contry code.
 * 
 * @param {string} phoneNumberString 
 * @returns 
 */
 const stripPhoneNumber = (phoneNumberString)=> {
  let phoneNum = ''
  if(phoneNumberString) {
    phoneNum = '+1' + (phoneNumberString).replace(/\D/g, '');
  }
  return phoneNum;
}

/**
 * Converts list of users into a list of value/label options for dropdowns/radios/etc.
 * 
 * @param {array} users 
 * @returns 
 */
const getUserOptions = (users, emptyValue="", emptyLabel="") => {
  let options = [];  
  if (users){
    for (const user of users){
      let option = {};
      option["value"] = user.id;
      option["label"] = `${user.lastName}, ${user.firstName}`;
      option["firstName"] = `${user.firstName}`;
      option["lastName"] = `${user.lastName}`;
      options.push(option);
    }      
  }
  options.sort((a, b) => a.label.localeCompare(b.label))
  //add empty option to front
  options.unshift(
    {
      value: emptyValue,
      label: emptyLabel
    }
  )
  return options;
}

const downloadCSV = (csv, filename) => {
  const fakeLink = document.createElement('a');
  fakeLink.style.display = 'none';
  document.body.appendChild(fakeLink);
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      // Manage IE11+ & Edge
      window.navigator.msSaveOrOpenBlob(blob, `${filename}.csv`);
  } else {
      fakeLink.setAttribute('href', URL.createObjectURL(blob));
      fakeLink.setAttribute('download', `${filename}.csv`);
      fakeLink.click();
  }
}

const isEqual = (a, b) => {
  if (Array.isArray(a) && Array.isArray(b)){
    if (a.length !== b.length)
      return false;
    else {
      for (let i = 0; i < a.length; i++)
        if (a[i] !== b[i])
          return false;
      return true;
    }
  }else {
    return a === b
  }
}

export {
  formatDate,
  isEmptyObject,
  getErrorMessage,
  truncateForDisplay,
  calculateAge,
  formatCurrency,
  formatPhoneNumber,
  stripPhoneNumber,
  getUserOptions,
  downloadCSV,
  isEqual
}