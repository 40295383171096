import React, { useState } from 'react'
import { Grid, Button, CircularProgress, Typography, Modal, Chip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { updateAgencyUserInfo } from '../../../../api/AgencyAPI';
import { useForm } from 'react-hook-form';
import FormTextField from '../../../form/FormTextField';
import { yupResolver } from '@hookform/resolvers/yup';
import { userNameValidationSchema } from '../../../../constants/sharedAgencyValidationSchema';
import { useErrorViewer } from '../../../../context/errorContext';
import { useQueryClient } from 'react-query';
import SuccessModal from '../../../SuccessModal';
import { agencyPermissions } from '../../../../constants/agencyPermissions';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const useStyles = makeStyles((theme) => ({
  userName: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    overflowWrap: 'anywhere',
    fontWeight: 400
  },
  chip: {
    borderRadius: '.5rem', 
    fontSize: '.75rem',
    height: '1.25rem',
    color: '#fff',
    width: "5rem",
    marginBottom: '.1rem',
    fontWeight: 700,
    '& > .MuiChip-label': {
      textOverflow: 'clip',
    }
  },
  section: {
    marginTop: "2rem"
  },
  modal: {
    position: 'absolute',
    background: theme.customColors.modalBackground,
    boxShadow: '0 0 .3rem rgba(0, 0, 0, 0.2)',
    bozSizing: 'inherit',
    borderRadius: '1.75rem',
    padding: '0 3rem',
    width: '40rem',
    maxHeight: '30rem',
    overflow: 'scroll'
  },
  modalButton: {
    textTransform: "unset !important",
    fontWeight: "bold",
    marginBottom: '1rem'
  },
}));

export default function AdminUserName({ user }) {
  const { handleSubmit, register, errors, getValues } = useForm({
    resolver: yupResolver(userNameValidationSchema)
  });

  const [loading, setLoading] = useState(false);
  const [editUserNameOpen, setEditUserNameOpen] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  const isAdmin = user?.permissions.includes(agencyPermissions.AGENCY_ADMIN_EDIT)
  
  const setError = useErrorViewer();
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const queryClient = useQueryClient();

  const submit = () => {    
    setLoading(true);

    updateAgencyUserInfo(user?.id, {'firstName': getValues('firstName'), 'lastName': getValues('lastName')}).then(() => {
      setEditUserNameOpen(false)
      setSuccessModalOpen(true)
      setLoading(false)
      queryClient.invalidateQueries(['users']);
      queryClient.invalidateQueries(['assignableUsers']); 
    }).catch((error) => {
      setLoading(false)
      setError('Error updating user.');
    })
  }

  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
  }

  return (
    <>
    <Grid>
    <Typography className={classes.userName} onClick={() => setEditUserNameOpen(true)}>
      {`${user?.firstName} ${user?.lastName}`}
    </Typography>
    {isAdmin &&
      <Chip variant="filled" style={{backgroundColor: "#BA1B1B"}} className={classes.chip} label="ADMIN" />
    }
    </Grid>

    <Modal open={editUserNameOpen} onClose={() => setEditUserNameOpen(false)}>
    <Grid style={modalStyle} className={classes.modal}>
      <form noValidate onSubmit={handleSubmit(submit)}>
        <Grid container spacing={1}>
          <Grid item xs={12} className={classes.section}>
            <Typography component="h1" variant="h5">
                Edit Name
            </Typography>
          </Grid>
          <Grid container spacing={1}>
          <Grid item xs={12} sm={6} className={classes.field}>
            <FormTextField name="firstName" label="First Name" defaultValue={user?.firstName} register={register} errors={errors} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField name="lastName" label="Last Name" defaultValue={user?.lastName}  register={register} errors={errors} />
          </Grid>
          </Grid>
          <Grid item container xs={12} justifyContent="flex-end" className={classes.section}>
          <Button onClick={() => setEditUserNameOpen(false)} color="primary" className={classes.modalButton}>
            Cancel
          </Button>
            {loading ? <CircularProgress color="primary" size={36} /> :
              <Button
                className={classes.modalButton}
                id="submit"
                type="submit"
                color="primary"
                onClick={handleSubmit}>
                Save
              </Button>
            }
          </Grid>
        </Grid>
      </form>
      </Grid>
      </Modal>

      <SuccessModal
        open={successModalOpen}
        title={"Name Changes Saved"}
        desc={"User's information has been updated."}
        okLabel="Okay"
        handleOk={handleCloseSuccessModal}
      />
    </>
  )
}
