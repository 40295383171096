import { useErrorViewer } from "../../../../context/errorContext";
import { useMutation } from 'react-query';
import { resendAgencyInvitation } from '../../../../api/AgencyAPI';
import { useState } from 'react';
import { Grid, IconButton } from '@mui/material';
import { ForwardToInboxOutlined } from '@mui/icons-material';
import ConfirmationDialog from '../../../ConfirmationDialog';
import { reviewAppPermissionOptions } from '../../../../constants/dropdowns/reviewAppPermissionOptions';
import { clientIntakePermissionOptions } from '../../../../constants/dropdowns/clientIntakePermissionOptions';
import { reportPermissionOptions } from '../../../../constants/dropdowns/reportPermissionOptions';
import SuccessModal from "../../../SuccessModal";
import { getPermissionOption } from "../../../../utils/utilAgencyTableFunctions";

export default function AdminResendInvite({invite}){
  const setError = useErrorViewer();

  const [resendConfirmationOpen, setResendConfirmationOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const getModalDesc = () => {
    if(invite){

      let dashBoard= getPermissionOption(invite?.permissions, "ClientIntakeDashboard", "", clientIntakePermissionOptions)
      let review = getPermissionOption(invite?.permissions, "ReviewApplication", "", reviewAppPermissionOptions)
      let reports = getPermissionOption(invite?.permissions, "Reports", "", reportPermissionOptions)
      
      return (
      <>
        {`Are you sure you want to resend invite to ${invite?.firstName} ${invite?.lastName}? `} <br /><br />
        {`${invite?.firstName} ${invite?.lastName}`} <br />
        {`${invite?.email}`} <br />
        {`Dashboard: ${dashBoard?.label}`} <br />
        {`Application: ${review?.label}`} <br />
        {`Reports: ${reports?.label}`} <br />
      </>
      )
    }
  }

  const resendAgencyInvitationMutation = useMutation(({id}) => {
    return resendAgencyInvitation(id);
  })

  const handleResend = (inviteId) => {
    setIsLoading(true);
      resendAgencyInvitationMutation.mutate(({id: inviteId}), {
        onSuccess: (response) => {
          setIsLoading(false);
          setResendConfirmationOpen(false);
          setSuccessOpen(true)
        },
        onError: (error) => {
          setIsLoading(false);
          setResendConfirmationOpen(false);
          setError('Error resending invitation.');
        }
      })
  }

  return (
     <Grid container item xs={12} justifyContent="center" justifyItems="center" alignContent="center">
        <IconButton
          aria-label="resend"
          onClick={() => setResendConfirmationOpen(true)}
        >
          <ForwardToInboxOutlined />
        </IconButton>

        <ConfirmationDialog 
            open={resendConfirmationOpen}
            title={"Resend Invite"}
            desc={getModalDesc()}
            cancelLabel="Cancel"
            okLabel='Resend'
            handleCancel={() => setResendConfirmationOpen(false)}
            handleOk={() => handleResend(invite?.id)}
            isLoading={isLoading}
          />

        <SuccessModal 
          title={"Invite Sent"} 
          desc={`Invite to ${invite?.firstName} ${invite?.lastName} has been resent.`} 
          open={successOpen} 
          handleOk={() => setSuccessOpen(false)}
        />
     </Grid>
  )
}
