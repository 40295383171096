import makeStyles from "@mui/styles/makeStyles";
import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {
  GridToolbarContainer,
  GridRowModes,
  GridActionsCellItem,
  DataGrid,
} from "@mui/x-data-grid";
import ConfirmationDialog from "../../ConfirmationDialog";
import SuccessModal from "../../SuccessModal";

const useStyles = makeStyles((theme) => ({
  noEditGrid:{
    '& .MuiDataGrid-cell:focus': {
      outline: 'none !important',
    },
  }
}));

export default function SeasonFundingTable({
  data,
  agencyData,
  updateSeasonFundSources,
  editableVersion = true,
}) {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [agencyNames, setAgencyNames] = useState([]);

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState({});

  let finalTotalsRow = {};
  let calculatedTotalsRow = [];

  const getAgencies = (agencyData) => {
    let agencies = agencyData?.map((agency) => agency.id);
    setAgencyNames(agencies);
    return agencies;
  };

  const getRows = (data) => {
    const dataWithID = data.map((row, i) => {return {id: i, ...row}})
    setRows(dataWithID);
    getColumns();
  };

  useEffect(() => {
    getAgencies(agencyData);
    getRows(data);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, agencyData]);

  function createId() {
    let id = Math.floor(Math.random() * 100000000);
    return id;
  }

  function EditToolbar() {
    const handleAddClick = () => {
      const id = createId();
      setRowModesModel((rowModel) => ({
        ...rowModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: "fundName", id: id },
      }));
      setRows((rowsToAddTo) => [
        {
          id: createId(),
          fundName: "",
          fundTotal: 0,
          isAddedRow: true,
        },
        ...rowsToAddTo
      ]);
      data = rows;
      return data;
    };

    return (
      <GridToolbarContainer>
        <Button
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddClick}
        >
          Add Funding Source
        </Button>
      </GridToolbarContainer>
    );
  }

  const handleRowEditStop = (params, event) => {
    if (event.key === "Enter") {
      // this is here because syntheticbaseevent "Enter" prevents default behavior and can't be reset for some reason
      event.defaultPrevented = true; // this fails silently defaultPrevented will always be true, it cannot be set to false;
      event.defaultMuiPrevented = true; //defaultMuiPrevented prevents enter keystroke from leaving editable row << which is needed
      event.isTrusted = false; //disable "enter" key method of saving input data
    } else {
      const updatedPolicy = Object.entries(params)[2][1];
      let updatedFundIndex = rows.findIndex(
        (row) => row.id === updatedPolicy.id
      );
      const updatedFundRows = [...rows];
      updatedFundRows[updatedFundIndex] = updatedPolicy;
      setRows(updatedFundRows);
      data = rows;
      setRowModesModel({
        ...rowModesModel,
        [updatedPolicy.id]: { mode: GridRowModes.View },
      });
      let addAgencyFunds = [];
      agencyNames.forEach((agency) => {
        if (updatedPolicy[agency]) {
          addAgencyFunds.push(parseInt(updatedPolicy[agency]));
        }
      });
      const sum = addAgencyFunds.reduce((prev, curr) => prev + curr, 0);
      updatedPolicy.fundTotal = sum;
      updateSeasonFundSources(updatedFundRows);
    }
  };

  const handleDeleteRowClick = (row) => () => {
    setDeleteConfirmationOpen(true)
    setRowToDelete(row)
};

const handleDelete = (id) => {
    setRows(rows.filter((row) => row.id !== id));
    updateSeasonFundSources(rows.filter((row) => row.id !== id));
    setDeleteConfirmationOpen(false);
    setSuccessOpen(true);
};

const handleCloseSuccessModal = () => {
    setSuccessOpen(false);
    setRowToDelete('');
}

  const handleRowModesModelChange = (currRowModesModel) => {
    setRowModesModel(currRowModesModel);
  };

  //Prepare agency totals and create grand total to fit on one row
  const createAgencyFundTotals = (rows, agencyNames) => {
    const agencyFundsTotalData = agencyNames.map((agency) => {
      const agencyTotalsPerFund = rows.map((items) => {
        if (isNaN(items[agency])) {
          return (items[agency] = 0);
        } else {
          return items[agency];
        }
      });
      return {
        [agency]: agencyTotalsPerFund.reduce(
          (prev, curr) => parseInt(prev) + parseInt(curr),
          0
        ),
      };
    });

    const createTotalsRow = (fundsDataObj) => {
      let mergeTotals = Object.assign({}, ...fundsDataObj);
      finalTotalsRow = mergeTotals;
      const grandTotal = rows
        .map((item) => parseInt(item.fundTotal))
        .reduce((prev, curr) => parseInt(prev) + parseInt(curr), 0);
      finalTotalsRow.grandTotal = grandTotal;
      calculatedTotalsRow = [finalTotalsRow];
    };

    finalTotalsRow = createTotalsRow(agencyFundsTotalData);
  };

  const getTotalsColumns = () => {
    createAgencyFundTotals(rows, agencyNames);
    const totalsColumns = agencyNames.map((agency, index) => {
      return {
        field: agency,
        flex: 0.055,
        type: "number",
        renderHeader: () => (
          <Typography variant="subtitle2">
            {agency}
          </Typography>
        ),
        renderCell: (params) => (
          <Typography variant="h7">
            {params.value}
          </Typography>
        ),
      };
    });
    totalsColumns.unshift({
      field: "label",
      flex: 0.095,
      renderHeader: () => (
        <Typography variant="subtitle2">
          Total Funds Per Agency
        </Typography>
      ),
      renderCell: (params) => (
        <Typography variant="h10">
          $, per Agency
        </Typography>
      ),
    });
    totalsColumns.push({
      field: "grandTotal",
      type: "number",
      flex: 0.055,
      renderHeader: () => (
        <Typography variant="subtitle2">
          Grand Total, $
        </Typography>
      ),
      renderCell: (params) => (
        <Typography variant="h7">
          {params.value}
        </Typography>
      ),
    });

    if (totalsColumns.length > 0) {
      return totalsColumns;
    } else getTotalsColumns();
  };

  const getColumns = () => {
    const agencyColumns = agencyNames.map((agency) => {
      return {
        field: agency,
        flex: 0.075,
        editable: true,
        type: "number",
        renderHeader: () => (
          <Typography variant="subtitle2">
            {agency}
          </Typography>
        ),
        renderCell: (params) => (
          <Typography variant="h7">
            {params.value}
          </Typography>
        ),
      };
    });
    agencyColumns.unshift({
      field: "fundName",
      flex: 0.2,
      editable: true,
      renderHeader: () => (
        <Typography variant="subtitle2">
          Fund
        </Typography>
      ),
      renderCell: (params) => (
        <Typography variant="h7">
          {params.value}
        </Typography>
      ),
    });
    agencyColumns.push({
      field: "fundTotal",
      type: "number",
      renderHeader: () => (
        <Typography variant="subtitle2">
          Total
        </Typography>
      ),
      renderCell: (params) => (
        <Typography variant="h7">
          {params.value}
        </Typography>
      ),
    });

    if (editableVersion) {
      agencyColumns.push({
        field: "actions",
        type: "actions",
        flex: 0.05,
        cellClassName: "actions",
        renderHeader: () => (
          <Typography variant="subtitle2">
            Remove
          </Typography>
        ),
        getActions: (params) => {
          return [
            <GridActionsCellItem
              icon={<DeleteIcon />}
              onClick={handleDeleteRowClick(params.row)}
              color="inherit"
              label="Remove"
            />,
          ];
        },
      });
    }

    if (agencyColumns.length > 0) {
      return agencyColumns;
    } else getColumns();
  };

  return (
    <>
    <div>
      {editableVersion ? (
        <>
          <Grid item xs={12} sx={{ height: "30rem" }}>
            <DataGrid
              columns={getColumns()}
              disableColumnMenu={true}
              rows={rows ? rows : []}
              editMode="row"
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStop={handleRowEditStop}
              components={{
                Toolbar: EditToolbar,
              }}
              componentsProps={{
                Toolbar: { setRows, setRowModesModel },
              }}
            />
          </Grid>
          <br></br>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              This table will auto-populate total dollar amount of fund sources
              per agency.
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ backgroundColor: "lightgray" }}>
            <DataGrid
              columns={getTotalsColumns()}
              disableColumnMenu={true}
              rows={calculatedTotalsRow ? calculatedTotalsRow : []}
              getRowId={(row) => 1}
              autoHeight
              rowsPerPageOptions={[]}
              hideFooter={true}
            />
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          <DataGrid
            columns={getColumns()}
            className={classes.noEditGrid}
            rows={rows ? rows : []}
            hideFooter
            autoHeight
            isCellEditable={(params) => {
              return false;
            }}
          />
          <br></br>
          <DataGrid
            columns={getTotalsColumns()}
            className={classes.noEditGrid}
            rows={calculatedTotalsRow ? calculatedTotalsRow : []}
            hideFooter
            getRowId={(row) => 1}
            autoHeight
            isCellEditable={(params) => {
              return false;
            }}
          />
        </Grid>
      )}
    </div>

    <ConfirmationDialog
      open={deleteConfirmationOpen}
      title={"Remove Fund Source"}
      desc={`Are you sure you want to remove ${rowToDelete.fundName}? This can not be undone.`}
      cancelLabel="Cancel"
      okLabel='Remove'
      handleCancel={() => setDeleteConfirmationOpen(false)}
      handleOk={() => handleDelete(rowToDelete.id)}
    />

    <SuccessModal
      title={"Fund Source Removed"}
      desc={`${rowToDelete.fundName} has been removed.`} 
      open={successOpen} 
      handleOk={handleCloseSuccessModal}
    />
</>
  );
}
