
import React from 'react';
import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { openingTextBlock1, openingTextBlock2 } from '../constants/terms-of-use/opening';
import { infoAndDocsTextBlock } from '../constants/terms-of-use/infoAndDocs';
import { obligationsTextBlock1, obligationsTextBlock2, obligationsTextBlock3, obligationsTextBlock4, obligationsTextBlock5 } from '../constants/terms-of-use/obligations';
import { releaseTextBlock1, releaseTextBlock2 } from '../constants/terms-of-use/release';
import { miscTextBlock1, miscTextBlock2 } from '../constants/terms-of-use/misc';
import { retentionTextBlock } from '../constants/terms-of-use/retention';
import { otherTermsTextBlock } from '../constants/terms-of-use/otherTerms';


const useStyles = makeStyles((theme) => ({
  mainContainer: {
    margin: 'auto',
    padding: "4rem 0rem",
    width: "85%"
  },
  header: {
    fontWeight: '600',
    marginBottom: '3rem',
    color: theme.palette.primary.main
  },
  subHeader: {
    fontWeight: '600',
    marginTop: '2.5rem'
  },
  termsText: {
      fontWeight: '300',
      margin: ".5rem 0rem .5rem 1.5rem",
  },
  termsTextBoldAndItalic: {
      fontWeight: '600',
      margin: ".5rem 0rem .5rem 1.5rem",
      fontStyle: 'italic'
  },
  inlineBoldAndItalic: {
    fontWeight: '600',
    fontStyle: 'italic',
    display: 'inline'
}
}));

export default function TermsOfUse() {
  const classes = useStyles();

  return (
    <Grid xs={12} item container justifyContent="flex-start" spacing={1} direction="column" className={classes.mainContainer}>
        <Typography variant="h3" className={classes.header}>
            LIHEAP Applicant Portal Terms of Use
        </Typography>
            <Typography className={classes.termsText} variant="subtitle1">
                {openingTextBlock1}
            </Typography>
            <Typography className={classes.termsTextBoldAndItalic} variant="subtitle1">
                {openingTextBlock2}
            </Typography>
        <Typography variant="h6" className={classes.subHeader}>
            1) INFORMATION AND DOCUMENTS YOU WILL NEED TO SUBMIT AND SHARE
        </Typography>
            <Typography className={classes.termsText} variant="subtitle1">
                {infoAndDocsTextBlock}
            </Typography>
        <Typography variant="h6" className={classes.subHeader}>
            2) YOUR PORTAL OBLIGATIONS
        </Typography>
            <Typography className={classes.termsText} variant="subtitle1">
                {obligationsTextBlock1}
            </Typography>
            <Typography className={classes.termsText} variant="subtitle1">
                {obligationsTextBlock2}
            </Typography>
            <Typography className={classes.termsText} variant="subtitle1">
                {obligationsTextBlock3}
            </Typography>
            <Typography className={classes.termsText} variant="subtitle1">
                {obligationsTextBlock4}
            </Typography>
            <Typography className={classes.termsText} variant="subtitle1">
                {obligationsTextBlock5}
            </Typography>
        <Typography variant="h6" className={classes.subHeader}>
            3) INFORMATION RETENTION
        </Typography>
            <Typography className={classes.termsText} variant="subtitle1">
                {retentionTextBlock}
            </Typography>
        <Typography variant="h6" className={classes.subHeader}>
            4) RELEASE
        </Typography>
            <Typography className={classes.termsText} variant="subtitle1">
                {releaseTextBlock1}
            </Typography>
            <Typography className={classes.termsText} variant="subtitle1">
                {releaseTextBlock2}
            </Typography>
        <Typography variant="h6" className={classes.subHeader}>
            5) MISC
        </Typography>
            <Typography className={classes.termsText} variant="subtitle1">
                {miscTextBlock1}
            </Typography>
            <Typography className={classes.termsText} variant="subtitle1">
                {miscTextBlock2}
            </Typography>
        <Typography variant="h6" className={classes.subHeader}>
            6) OTHER TERMS
        </Typography>
            <Typography className={classes.termsText} variant="subtitle1">
                i) Except as otherwise provided herein, Entergy’s 
                <Typography className={classes.inlineBoldAndItalic} variant="subtitle1">
                    {' '}General Terms, Conditions, and Policies for the Use of Entergy Websites and Mobile Applications,{' '}
                </Typography>
                available at 
                <a href="https://www.entergy.com/terms-of-use/" target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}> 
                    {' '}https://www.entergy.com/terms-of-use/{' '} 
                </a> 
                and Entergy’s 
                <Typography className={classes.inlineBoldAndItalic} variant="subtitle1">
                    {' '}Privacy Policy,{' '}
                </Typography>
                available at 
                <a href="https://www.entergy.com/privacy-policy/" target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}> 
                    {' '}https://www.entergy.com/privacy-policy/{' '} 
                </a> 
                are applicable to your use of the Portal.
            </Typography>
            <Typography className={classes.termsText} variant="subtitle1">
                {otherTermsTextBlock}
            </Typography>
    </Grid>

  );
}
