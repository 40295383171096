import { Container, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import StepButtons from '../../../StepButtons';
import StepHeader from '../../../StepHeader';
import WorkStatusIncome from '../WorkStatusIncome';
import useLiheapForm from '../../../../hooks/useLiheapForm';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    margin: "0",
  }
}));

export default function ApplicantIncome({ data, back, next, save, validate, validationSchema }) {
  const classes = useStyles();
  const { setValue, control, register, errors} = useLiheapForm(save, data, validate, validationSchema, false);

  return (
    <Container className={classes.mainContainer}>
        <Grid container spacing={1}>
          <StepHeader 
            title="Applicant Income Information" 
            desc = "Please provide the following information for the applicant."
          />
          <WorkStatusIncome 
            type="applicant"
            register={register}
            control={control}
            errors={errors}
            data={data}
            setValue={setValue}
          />
          <StepButtons back={back} next={next}/>
        </Grid>
    </Container>
  )
}
