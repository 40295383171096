import makeStyles from '@mui/styles/makeStyles';
import { useErrorViewer } from "../../../../context/errorContext";
import { useMutation, useQueryClient } from 'react-query';
import { updateUserPermissions } from '../../../../api/AgencyAPI';
import { useState } from 'react';
import { FormControl, MenuItem, Select } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  select: {
    padding: "0.5rem",
    width: "12rem"
  }
}));

export default function AdminPermissionSelect({type, user, optionSelected, options}){
  const classes = useStyles();
  const queryClient = useQueryClient();
  const setError = useErrorViewer();
  const [currentOption, setCurrentOption] = useState(optionSelected);
  const startingOptionSelected = optionSelected;

  const savePermissionsMutation = useMutation(({id, data}) => {
    return updateUserPermissions(id, data);
  })

  const getUserCurrentPerm = () => {
    const userPermissions = user.permissions ? user.permissions : [];
    let permission = "";
    for (const i in userPermissions) {
      if (userPermissions[i].startsWith(type)){
        permission = userPermissions[i];        
        break;
      }
    }
    return permission
  }

  const handleChange = (event) => {
    const updateObject = {
      oldValue: getUserCurrentPerm(),
      newValue: event.target.value
    }

    setCurrentOption(options.find((option) => option.value === event.target.value));
    
    savePermissionsMutation.mutate(({id: user.id, data: updateObject}), {
      onSuccess: (response) => {
        queryClient.invalidateQueries(['users']);
        queryClient.invalidateQueries(['assignableUsers']); 
      },
      onError: (error) => {
        setCurrentOption(startingOptionSelected);
        setError('Error saving user.');
      }
    })

  }

  return (
    <FormControl fullWidth className={classes.select} size="small">
      <Select
        style={{borderRadius: 8, fontWeight: 500}}
        id={`${type}_${user.id}`}
        value={currentOption.value}
        onChange={handleChange}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
