import styled from "styled-components";
import { Link } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';

export const ContentWrapper = styled("div")`
  width 100%;
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 99;
`;

export const HeaderSection = styled("header")`
  padding: 1rem 0.5rem;
  background: #fff;
  width: 90%;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (max-width: 1024px) {
    max-width: calc(100% - 71px);
    padding: 30px;
  }

  @media only screen and (max-width: 768px) {
    max-width: calc(100% - 41px);
    padding: 18px;
  }

  @media only screen and (max-width: 414px) {
    max-width: 100%;
    padding: 18px;
  }
`;

export const Row = styled('div')`
  text-align: end;
`;

export const Col = styled('div')`
  margin: 1.5rem;
  min-width: 250px;
`;

export const NavLink = styled("div")`
  display: inline-block;
  text-align: center;
`;

export const CustomNavLinkSmall = styled(NavLink)`
  font-size: 1.2rem;
  color: #1e68c1;
  transition: color 0.2s ease-in;

  @media only screen and (max-width: 768px) {
    margin: 1.25rem 2rem;
  }
`;

export const CustomLinkSmall = styled(Link)`
  font-size: 1.2rem;
  color: #1e68c1;
  transition: color 0.2s ease-in;
  text-decoration: none;
  text-align: center;

  @media only screen and (max-width: 768px) {
    padding: 1.25rem 2rem;
  }
`;

export const Span = styled("span")`
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin: 0.5rem 1.5rem;

  &:hover,
  &:active,
  &:focus {
    color: rgb(30, 103, 193);
    text-underline-position: under;
    text-decoration: rgb(30, 103, 193) wavy underline;
  }
`;

export const ButtonSpan = styled("span")`
  cursor: pointer;
  margin: 0rem;
  margin-left: 1.5rem;

  @media only screen and (max-width: 768px) {
    margin: 0;
  }
`;


export const Burger = styled("div")`
  @media only screen and (max-width: 890px) {
    display: block;
  }
  display: none;
  svg {
    fill: #1e68c1;
  }
`;

export const NotHidden = styled("div")`
  @media only screen and (max-width: 890px) {
    display: none;
  }
`;

export const Outline = styled(MenuIcon)<any>`
  font-size: 30px;
`;
