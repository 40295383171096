import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Typography, Grid } from '@mui/material';
import FormTextField from '../../../../form/FormTextField';
import FormDatePicker from '../../../../form/FormDatePicker';

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: "bold", 
  },
  dateLabel: {
    fontWeight: "bold", 
    marginBottom: '.5rem'
  },
  standardInput: {
    backgroundColor: 'transparent',
    margin: '0'
  },
  textbox: {
    backgroundColor: '#FFF',
    marginBottom: '1.5rem',
    "& > .MuiFormHelperText-root.Mui-error": { 
      margin:0
    },
  },
}));

export default function ReturnedStatusInfo({ application, control, register, errors, loading, trigger }) {
  const classes = useStyles();

  return (
    <Grid container spacing={1}>
        <Grid item xs={12}>
            <Typography variant='subtitle2' className={classes.label} >
                The following information is needed to determine eligibility:
            </Typography>
            <FormTextField
                className={classes.textbox}
                disabled={loading}  
                name="returnedNeededInfo" 
                label="Type message to applicant"
                defaultValue={application?.statusInfo?.returnedNeededInfo}
                multiline
                rows={4}
                register={register} 
                errors={errors}     
            />
        </Grid>

          <Grid item xs={12}>
            <Typography variant='subtitle2' className={classes.dateLabel}>
                Application will be denied if this information is not received at this office by 
            </Typography>
            <FormDatePicker
                className={classes.standardInput}
                disabled={loading} 
                name="returnedDueDate"
                defaultValue={application?.statusInfo?.returnedDueDate}
                InputAdornmentProps={{ position: "end" }}
                id="returnedDueDate-date-picker"
                control={control}
                errors={errors}
                variant='standard'
                userType='agent'
                validationTrigger={trigger}
            />
        </Grid>
    </Grid>
)}
