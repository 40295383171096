import { Grid, Typography,Accordion, AccordionSummary, AccordionDetails, Button, Link } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ExpandMore, DeleteOutlineOutlined } from '@mui/icons-material'
import React, { useState, useEffect } from 'react'
import { deleteStateForm } from '../../../api/FileAPI';
import { useMutation } from 'react-query';
import ModalWarning from '../../ModalWarning';
import ConfirmationDialog from '../../ConfirmationDialog';
import { useErrorViewer } from '../../../context/errorContext';
import SuccessModal from '../../SuccessModal';

const useStyles = makeStyles((theme) => ({
    accordion:{
        width: '100%',
        '& > .MuiAccordionSummary-root':{
            padding: '0 1.5rem',
            '& > .MuiAccordionSummary-content':{
            },
            '& > .Mui-expanded':{
                margin: 0
            },
            '& > .MuiAccordionSummary-expandIconWrapper':{
                alignSelf: 'flex-end',
            }
        }
    },
    expandIcon: {
        color: theme.palette.primary.main,
        padding: '.75rem'
    },
    details: {
        display: 'flex',
    },
    required: {
        color: theme.customColors.errorIcon,
        paddingLeft: '.5rem'
    },
    docLink:{
        color: theme.palette.primary.main,
        fontWeight: "bold",
    },
    buttons: {
        textTransform: 'none',
        fontWeight: 600
    }
}));

export default function StateFormInfo({ form, openAll, onRemove, canEdit }) {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const setError = useErrorViewer();
    const [successModalOpen, setSuccessModalOpen] = useState(false);

    const setExpandedValue = () => {
        setExpanded(prevState => {return !prevState})
    }

    const deleteMutation = useMutation(form => {
        return deleteStateForm(form.id, form.state);
      });
    
    const handleDelete = () => {
        deleteMutation.mutate(form, {
            onSuccess: () => {
                setSuccessModalOpen(true);
            },
          onError: (err) => { setError(err.response?.data ?? "An error occurred removing your form."); }
        })
          
        setDeleteConfirmationOpen(false);
    }

    useEffect(() => {
        setExpanded(openAll)
      // eslint-disable-next-line
    }, [openAll]);

    return (
        <>
        <Accordion expanded={expanded} className={classes.accordion}>
            <AccordionSummary expandIcon={<ExpandMore className={classes.expandIcon}/>} onClick={setExpandedValue}>
                <Grid container item xs={12}>
                    <Typography variant="subtitle1" fontWeight='bold'>
                        {form?.title}
                    </Typography>
                    { form?.required === 'yes' && 
                        <Typography className={classes.required} variant="subtitle1" fontWeight='bold'>
                            Required
                        </Typography>
                    }
                </Grid>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
                <Grid item xs={6}>
                    <Link variant="outlined" href={`/api/file/stateForm?fileKey=${form?.url}`} target="__new" className={classes.docLink}>
                        {form?.fileName}
                    </Link>
                    <Typography>
                        {`Description: ${form?.description}`}
                    </Typography>
                </Grid>
                <Grid item xs={6} textAlign='end'>
                      <Button 
                        className={classes.buttons} 
                        disabled={!canEdit} 
                        onClick={() => setDeleteConfirmationOpen(true)} 
                        variant="text" 
                        color="primary"
                      >
                        <DeleteOutlineOutlined className={classes.editIcons}/>
                        Remove
                      </Button>
                </Grid>
            </AccordionDetails>
        </Accordion>

        <ConfirmationDialog
            open={deleteConfirmationOpen}
            title={"Remove Form"}
            desc={`Are you sure you want to remove the ${form?.title} form? This can not be undone.`}
            cancelLabel="Cancel"
            okLabel='Remove'
            handleCancel={() => setDeleteConfirmationOpen(false)}
            handleOk={() => handleDelete()}
            contentChildren={<ModalWarning description={`Warning! Deleting the ${form?.title} form will remove it from the system. This form will no longer be accessible to caseworkers and applicants.`}/>}
          />

        <SuccessModal
            open={successModalOpen}
            title={"Form Removed"}
            desc={`Form has been removed. Applicants and caseworkers will no longer be able to access this form on the platform.`}
            okLabel="Okay"
            handleOk={() => onRemove()}
    />
          </>
        );
}
