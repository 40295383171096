import ApplicantIncome from "../../components/liheap/ar/applicant_info/ApplicantIncome";
import PersonalAndContactInfo from "../../components/liheap/ar/applicant_info/PersonalAndContactInfo";
import AddEditMembers from "../../components/liheap/ar/household/AddEditMembers";
import MemberIncome from "../../components/liheap/ar/household/MemberIncome";
import MemberPersonalInfo from "../../components/liheap/ar/household/MemberPersonalInfo";
import MemberAddEditEmployers from "../../components/liheap/ar/household/MemberAddEditEmployers";
import Review from "../../components/liheap/ar/Review";
import UtilityGeneralInformation from "../../components/liheap/ar/utility/UtilityGeneralInformation";
import UtilitySituationInformation from "../../components/liheap/ar/utility/UtilitySituationInformation";
import MemberDocumentUpload from "../../components/liheap/ar/household/MemberDocumentUpload";
import AddEditEmployers from "../../components/liheap/ar/applicant_info/AddEditEmployers";
import ApplicantDocumentUpload from "../../components/liheap/ar/applicant_info/ApplicantDocumentUpload";
import AdditionalDocumentUpload from "../../components/liheap/ar/AdditionalDocumentUpload";
import UtilityDocumentUpload from "../../components/liheap/ar/utility/UtilityDocumentUpload";
import Submit from "../../components/liheap/ar/Submit";
import ApplicationStatus from "../../components/liheap/ApplicationStatus";
import ApplicantFinancialResources from "../../components/liheap/ar/applicant_info/ApplicantFinancialResources";
import MemberFinancialResources from "../../components/liheap/ar/household/MemberFinancialResources";
import {
  incomeSchema, memberPersonalInfoSchema, personalAndContantInfoSchema, 
  utilityInfoSchema, utilitySituationSchema, addEditEmployerSchema, memberAddEditEmployerSchema, financialResourcesSchema
} from "./stepValidationSchemas";
import { 
  employerFieldLabels, financialResourcesFieldLabels, incomeFieldLabels, 
  memberPersonalInfoFieldLabels, personalAndContantInfoFieldLabels, utilityFieldLabels, utilitySituationFieldLabels
} from "../fieldLabels";
import RequiredDocuments from "../../components/liheap/RequiredDocuments";

export const processStepLabels = [
  {
    value: "Applicant Information",
    location: "personalAndContactInfo",
    link: "/liheapform/AR/personalAndContactInfo"
  },
  {
    value: "Household Members",
    location: "addEditHouseholdMembers",
    link: "/liheapform/AR/addEditHouseholdMembers"
  },
  {
    value: "Utility Information",
    location: "utilityGeneralInformation",
    link: "/liheapform/AR/utilityGeneralInformation"
  },
  {
    value: "Additional Documents",
    location: "additionalDocumentUpload",
    link: "/liheapform/AR/additionalDocumentUpload"
  },
  {
    value: "Review & Complete",
    location: "review",
    link: "/liheapform/AR/review"
  },
  {
    value: "Submit",
    location: "submit",
    link: "/liheapform/AR/submit"
  }
];

export const processSteps = [
  {
    "location": "requiredDocuments",
    "component": RequiredDocuments,
    "showStep": true,
    "stepIndex": 0,
    "previous": "/dashboard",
    "next": "/liheapform/AR/personalAndContactInfo/:id",
  },
  {
    "location": "personalAndContactInfo",
    "component": PersonalAndContactInfo,
    "showStep": true,
    "stepIndex": 0,
    "stepSection": "applicantGeneralInformation",
    "stepValidationSchema": personalAndContantInfoSchema,
    "fields": personalAndContantInfoFieldLabels,
    "previous": "/liheapform/AR/requiredDocuments/:id",
    "next": "/liheapform/AR/applicantIncome/:id",
  },
  {
    "location": "applicantIncome",
    "component": ApplicantIncome,
    "showStep": true,
    "stepIndex": 0,
    "stepSection": "applicantIncomeInformation",
    "stepValidationSchema": incomeSchema,
    "fields": incomeFieldLabels,
    "previous": "/liheapform/AR/personalAndContactInfo/:id",
    "next": "/liheapform/AR/addEditEmployers/:id"
  },
  {
    "location": "addEditEmployers",
    "component": AddEditEmployers,
    "showStep": true,
    "stepIndex": 0,
    "stepSection": "applicantIncomeInformation",
    "stepValidationSchema": addEditEmployerSchema,
    "fields": employerFieldLabels,
    "previous": "/liheapform/AR/applicantIncome/:id",
    "next": "/liheapform/AR/applicantFinancialResources/:id"
  },
  {
    "location": "applicantFinancialResources",
    "component": ApplicantFinancialResources,
    "showStep": true,
    "stepIndex": 0,
    "stepSection": "applicantIncomeInformation",
    "stepValidationSchema": financialResourcesSchema,
    "fields": financialResourcesFieldLabels,
    "previous": "/liheapform/AR/addEditEmployers/:id",
    "next": "/liheapform/AR/applicantDocumentUpload/:id"
  },
  {
    "location": "applicantDocumentUpload",
    "component": ApplicantDocumentUpload,
    "showStep": true,
    "stepIndex": 0,
    "stepSection": "applicantDocuments",
    "previous": "/liheapform/AR/applicantFinancialResources/:id",
    "next": "/liheapform/AR/addEditHouseholdMembers/:id",
  },
  {
    "location": "addEditHouseholdMembers",
    "component": AddEditMembers,
    "showStep": true,
    "isNestedItem": true,
    "nestedFieldName": "householdMembers",
    "stepIndex": 1,
    "previous": "/liheapform/AR/applicantDocumentUpload/:id",
    "next": "/liheapform/AR/utilityGeneralInformation/:id",
  },
  {
    "location": "memberPersonalInfo",
    "component": MemberPersonalInfo,
    "showStep": true,
    "isNestedItem": true,
    "nestedFieldName": "householdMembers",
    "stepIndex": 1,
    "stepSection": "memberGeneralInformation",
    "stepValidationSchema": memberPersonalInfoSchema,
    "fields": memberPersonalInfoFieldLabels,
    "previous": "/liheapform/AR/addEditHouseholdMembers/:id",
    "next": "/liheapform/AR/memberIncome/:id/:nestedItemId"
  },
  {
    "location": "memberIncome",
    "component": MemberIncome,
    "showStep": true,
    "isNestedItem": true,
    "nestedFieldName": "householdMembers",
    "stepIndex": 1,
    "stepSection": "memberIncomeInformation",
    "stepValidationSchema": incomeSchema,
    "fields": incomeFieldLabels,
    "previous": "/liheapform/AR/memberPersonalInfo/:id/:nestedItemId",
    "next": "/liheapform/AR/memberAddEditEmployers/:id/:nestedItemId"
  },
  {
    "location": "memberAddEditEmployers",
    "component": MemberAddEditEmployers,
    "showStep": true,
    "isNestedItem": true,
    "nestedFieldName": "householdMembers",
    "stepIndex": 1,
    "stepSection": "memberIncomeInformation",
    "stepValidationSchema": memberAddEditEmployerSchema,
    "fields": employerFieldLabels,
    "previous": "/liheapform/AR/memberIncome/:id/:nestedItemId",
    "next": "/liheapform/AR/memberFinancialResources/:id/:nestedItemId"
  },
  {
    "location": "memberFinancialResources",
    "component": MemberFinancialResources,
    "showStep": true,
    "isNestedItem": true,
    "nestedFieldName": "householdMembers",
    "stepIndex": 1,
    "stepSection": "memberIncomeInformation",
    "stepValidationSchema": financialResourcesSchema,
    "fields": financialResourcesFieldLabels,
    "previous": "/liheapform/AR/memberAddEditEmployers/:id/:nestedItemId",
    "next": "/liheapform/AR/memberDocumentUpload/:id/:nestedItemId"
  },
  {
    "location": "memberDocumentUpload",
    "component": MemberDocumentUpload,
    "showStep": true,
    "nestedFieldName": "householdMembers",
    "stepIndex": 1,
    "stepSection": "memberDocuments",
    "previous": "/liheapform/AR/memberFinancialResources/:id/:nestedItemId",
    "next": "/liheapform/AR/addEditHouseholdMembers/:id",
  },
  {
    "location": "utilityGeneralInformation",
    "component": UtilityGeneralInformation,
    "showStep": true,
    "stepIndex": 2,
    "stepSection": "utilityGeneralInformation",
    "stepValidationSchema": utilityInfoSchema,
    "fields": utilityFieldLabels,
    "previous": "/liheapform/AR/addEditHouseholdMembers/:id",
    "next": "/liheapform/AR/utilitySituationInformation/:id"
  },
  {
    "location": "utilitySituationInformation",
    "component": UtilitySituationInformation,
    "showStep": true,
    "stepIndex": 2,
    "stepSection": "utilityGeneralInformation",
    "stepValidationSchema": utilitySituationSchema,
    "fields": utilitySituationFieldLabels,
    "previous": "/liheapform/AR/utilityGeneralInformation/:id",
    "next": "/liheapform/AR/utilityDocumentUpload/:id"
  },
  {
    "location": "utilityDocumentUpload",
    "component": UtilityDocumentUpload,
    "showStep": true,
    "stepIndex": 2,
    "stepSection": "utilityDocuments",
    "previous": "/liheapform/AR/utilitySituationInformation/:id",
    "next": "/liheapform/AR/additionalDocumentUpload/:id"
  },
  {
    "location": "additionalDocumentUpload",
    "component": AdditionalDocumentUpload,
    "showStep": true,
    "stepIndex": 3,
    "stepSection": "additionalDocuments",
    "previous": "/liheapform/AR/utilityDocumentUpload/:id",
    "next": "/liheapform/AR/review/:id"
  },
  {
    "location": "review",
    "component": Review,
    "showStep": true,
    "stepIndex": 4,
    "nestedFieldName": "householdMembers",
    "previous": "/liheapform/AR/additionalDocumentUpload/:id",
    "next": "/liheapform/AR/submit/:id"
  },
  {
    "location": "submit",
    "component": Submit,
    "showStep": true,
    "stepIndex": 5,
    "previous": "/liheapform/AR/review/:id",
    "next": "/liheapform/AR/status/:id"
  },
  {
    "location": "status",
    "component": ApplicationStatus,
    "showStep": false
  },
];
