export const heaterOptions = [
    {
      value: 'gasSpaceHeater',
      label: 'Gas Space Heater'
    },
    {
      value: 'electricHeater',
      label: 'Electric Heater'
    },
    {
      value: 'centralUnit',
      label: 'Central Unit'
    },
    {
      value: 'wallFurnace',
      label: 'Wall Furnace'
    },
    {
      value: 'stoveGas',
      label: 'Stove - Gas'
    },
    {
      value: 'wood',
      label: 'Wood'
    }
  ]
  
  