
import React from 'react';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AgencyTable from '../components/agency/pages/AgencyTable';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    marginLeft: '4rem',
    padding: "2rem",
    width: "95%"
  },
  button: {
    fontWeight: 'bold',
    margin: '1.5rem .5rem 0rem .5rem',
    minWidth: '6rem',
    color: theme.palette.primary.main   
  }  
}));

export default function AgencyHome() {
  const classes = useStyles();
  
  return (
    <Grid xs={12} item container justifyContent="flex-start" spacing={1} direction="column" className={classes.mainContainer}>
      <AgencyTable />
    </Grid>
  );
}