export const yesNoDontKnowOptions = [
  {
    value: 'yes',
    label: 'Yes'
  },
  {
    value: 'no',
    label: 'No'
  },
  {
    value: 'unknown',
    label: 'I\'m Not Sure'
  }
]