import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';
import { Grid, Tooltip, Typography } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { StateReportsStyledDataGrid } from './StateReportsStyledDataGrid';
import { formatCurrency, truncateForDisplay } from '../../../utils/utilFunctions';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: '75rem',
  },
  column: {
    fontWeight: '500',
    marginLeft: '.5rem',
    whiteSpace: 'normal',
    lineHeight: 'normal',
    height: "unset !important",
    maxHeight: "168px !important"
  },
  cell: {
    fontWeight: '500',
    paddingLeft: '.5rem',
    width: '100%',
    whiteSpace: 'break-spaces',
    lineHeight: '1.5rem',
    textAlign: 'start'
  },
  numberCell: {
    fontWeight: '500',
    width: '100%',
    whiteSpace: 'break-spaces',
    lineHeight: '1.5rem',
    textAlign: 'right'
  }
}));

export default function StateFundsReportTable({ data, isFetching }) {
  const classes = useStyles();
  const [sortModel, setSortModel] = useState([{field: 'agencyId', sort: 'asc'}]);

  const handleSortChange = (model) => {
    if (JSON.stringify(sortModel[0]) !== JSON.stringify(model[0])){
      setSortModel(model)
    }
  }

  const SortedDescendingIcon = () => {
    return <KeyboardArrowDown style={{color: '#000'}}/>;
  }

  const SortedAscendingIcon = () => {
    return <KeyboardArrowUp style={{color: '#000'}}/>;
  }

  const formatAmount = (value) => {
    return value ? formatCurrency(value) : "$0.00"
  }

  const getColumns = () => {
    let columns = [
      {
        field: 'agencyId', flex: .3,
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Agency</Typography>),
        renderCell: (params) => (<Typography variant='subtitle1' className={classes.cell}>{params.value}</Typography>)
      },
      {
        field: 'fundType', flex: .3,
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Fund</Typography>),
        renderCell: (params) => (<Typography variant='subtitle1' className={classes.cell}>{params.value}</Typography>)
      },
      {
        field: 'totalFundsAmount', flex: .5, type: 'number',
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Funds, $ </Typography>),
        renderCell: (params) => (<Typography variant='subtitle1' className={classes.numberCell}>{formatAmount(params.value)}</Typography>)
      },
    ]

    // Add columns for fund sources
    if (data){
      let existingSourceKeys = []
      for (const fundInfo of data){
        for (const [key] of Object.entries(fundInfo)) {
          if (!(['agencyId', 'fundType', 'totalFundsAmount'].includes(key))){
            let sourceInfo = {
              field: key, flex: .6, type: 'number',
              renderHeader: () => (
                <Tooltip title={key} placement='top'>
                  <Typography variant='h6' className={classes.column}>{truncateForDisplay(key, 20)}</Typography>
                </Tooltip>
              ),
              renderCell: (params) => (<Typography variant='subtitle1' className={classes.numberCell}>{formatAmount(params.value)}</Typography>)
            }
            if (!existingSourceKeys.includes(key)){
              columns.push(sourceInfo)
              existingSourceKeys.push(key)
            }
          }
        }
      }
    }
    
    return columns
  }

  return (
    <div className={classes.table}>
      <Grid item xs={12} sx={{height: "30rem"}}>
        <StateReportsStyledDataGrid
          rows={data ? data : []}
          columns={getColumns()}
          getRowId={(row) => Math.floor(Math.random() * 100000000)}
          hideFooter
          hideFooterSelectedRowCount
          hideFooterPagination
          disableSelectionOnClick
          disableColumnMenu
          loading={isFetching}
          components={{
            ColumnSortedDescendingIcon: SortedDescendingIcon,
            ColumnSortedAscendingIcon: SortedAscendingIcon,
          }}
          sortModel={sortModel}
          onSortModelChange={handleSortChange}
        />
      </Grid>
    </div>
  );
}
