import React, { useState } from 'react'
import { Grid, Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { createInvitation } from '../../../api/UserAPI';
import { useForm } from 'react-hook-form';
import FormTextField from '../../form/FormTextField';
import { yupResolver } from '@hookform/resolvers/yup';
import { adminInviteValidationSchema } from '../../../constants/inviteValidationSchema';
import { agencyPermissions } from '../../../constants/agencyPermissions';
import { useErrorViewer } from '../../../context/errorContext';
import { useQueryClient } from 'react-query';
import ConfirmationDialog from '../../ConfirmationDialog';
import SuccessModal from '../../SuccessModal';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: theme.customColors.veryLightGray,
    borderRadius: 12,
    padding: '3rem !important',
  },
  section: {
    marginTop: "2rem"
  },
  subHeader:{
    margin: '1rem 0'
  },
  button:{
    textTransform: "unset !important",
    fontWeight: "bold"
  }
}));

export default function InviteAdmin() {
  const { handleSubmit, register, errors, reset, getValues } = useForm({
    resolver: yupResolver(adminInviteValidationSchema)
  });
  const [loading, setLoading] = useState(false);
  const [inviteConfirmationOpen, setInviteConfirmationOpen] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  const setError = useErrorViewer();
  const classes = useStyles();

  const getModalDesc = () => {
      return (
      <>
        {`You are about to grant Admin rights to ${getValues('firstName')} ${getValues('lastName')}.`}<br /><br />
        {`The new Admin will be granted all permissions, including the ability to invite other Users and Admins, grant and change permissions and permanently remove users.`}
      </>
      )
  }

  const getSuccessModalDesc = () => {
    return (
    <>
      {`${getValues('firstName')} ${getValues('lastName')} will receive an invitation link to use the platform.`}<br /><br />
      {`The user's information will appear in the Invited tab on the Admin Panel. Once the user registers, their information will appear in the Active Users tab.`}
    </>
    )
  }

  const queryClient = useQueryClient();

  const submit = (formData) => {    
    setLoading(true);
    //set up invitation to be saved
    const permissionsList = [];
    permissionsList.push(agencyPermissions.CLIENT_INTAKE_DASHBOARD_EDIT);
    permissionsList.push(agencyPermissions.REVIEW_APPLICATION_EDIT);
    permissionsList.push(agencyPermissions.REPORTS_VIEW);
    permissionsList.push(agencyPermissions.AGENCY_ADMIN_EDIT)
    formData["permissions"] = permissionsList;

    delete formData.confirmEmail;

    createInvitation({userType: 'agency', ...formData}).then((response) => {              
        setLoading(false);
        setInviteConfirmationOpen(false);
        setSuccessModalOpen(true);
        queryClient.invalidateQueries(['invitations']);
    }).catch((error) => {
        setLoading(false);
        setInviteConfirmationOpen(false);
        setError("Error creating invitation!");
    })
  }

  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
    reset();
  }

  return (
    <>
    <Grid item className={classes.mainContainer}>
      <Typography component="h1" variant="h5">
        Admin
      </Typography>
      <Typography className={classes.subHeader} component="h1" variant="body1">
        Please provide the following information to invite a new Admin to register. The new Admin will be granted all permissions, including the ability to invite other Users and Admins, grant and change permissions, and permanently remove users.
      </Typography>
      <form noValidate onSubmit={handleSubmit(() => setInviteConfirmationOpen(true))}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <FormTextField name="firstName" label="First Name" register={register} errors={errors} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField name="lastName" label="Last Name" register={register} errors={errors} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField name="email" label="Email" inputMode="email" register={register} errors={errors} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField name="confirmEmail" label="Confirm Email" inputMode="email" register={register} errors={errors} />
          </Grid> 
          <Grid item container xs={12} justifyContent="flex-end" className={classes.section}>
              <Button
                className={classes.button}
                id="submit"
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleSubmit}>
                Send Invite
              </Button>
          </Grid>
        </Grid>
      </form>
      </Grid>

      <ConfirmationDialog
        open={inviteConfirmationOpen}
        title={"Invite Admin"}
        desc={getModalDesc()}
        cancelLabel="Cancel"
        okLabel="Send Invite"
        handleCancel={() => setInviteConfirmationOpen(false)}
        handleOk={handleSubmit(submit)}
        isLoading={loading}
      />

      <SuccessModal 
        open={successModalOpen}
        title={"Admin Invited"}
        desc={getSuccessModalDesc()}
        okLabel="Okay"
        handleOk={handleCloseSuccessModal}
      />
    </>
  )
}
