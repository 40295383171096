import axios from 'axios';

const REFRESH_URL = '/user/refresh';

const APIUtils = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

const setAPIRequestInterceptor = (tokenCallback) => {
    APIUtils.interceptors.request.use(req => {
        const token = tokenCallback();
        if(token)
          req.headers.Authorization = `Bearer ${token}`;
        else
          req.headers.Authorization = "";
    
        return req;
    });
}

const setAPIResponseInterceptor = (logoutCallback, refreshTokenCallback, setTokenCallback) => {
    APIUtils.interceptors.response.use((res) => {
            return res;
        },
        async (err) => {
            if(err.response?.status === 401) {
                const refreshAndRetryResponse = await refreshToken(refreshTokenCallback, setTokenCallback).then((data) => {
                    const newIdToken = data["IdToken"];
                    err.config.headers["Authorization"] = `Bearer ${newIdToken}`;
                    return axios.request(err.config);
                }).catch((innerErr) => {
                    if(innerErr.response.status === 401 || innerErr.response.status === 400)
                        logoutCallback();
                        
                    return Promise.reject(innerErr);
                })
                return refreshAndRetryResponse;
            }
            else
                return Promise.reject(err);
        }
    );
}

const refreshToken = async (refreshTokenCallback, setTokenCallback) => {
    const refToken = refreshTokenCallback();

    return _getRefreshToken(refToken).then((response) => {
        const token = response["data"]
        setTokenCallback(token);

        return Promise.resolve(token);
    }).catch((err) => {
        return Promise.reject(err);
    });
}

const _getRefreshToken = (refreshToken) => {
    const refreshApi = axios.create({ baseURL: process.env.REACT_APP_API_URL });

    return refreshApi.post(REFRESH_URL, { refreshToken });
}

export {
  APIUtils,
  setAPIRequestInterceptor,
  setAPIResponseInterceptor,
}