
import React, { useContext, useState } from 'react';
import {
  Grid,
  Container,
  TextField,
  Button,
  CircularProgress,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
  Modal,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AuthContext } from '../../context/authContext';
import { Redirect } from 'react-router-dom';
import { ArrowBackIos } from '@mui/icons-material';
import { userUpdatePhone, userConfirmResetAttribute, userResendConfirm } from '../../api/UserAPI';
import InputMask from "react-input-mask";
import { stripPhoneNumber } from "../../utils/utilFunctions"
import SettingsStepper from '../../components/SettingsStepper';
import { useErrorViewer } from '../../context/errorContext';

function getModalStyle() {
  const top = 50;
  const left = 50;
  
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "2.8rem",
    marginLeft: 'auto',
    marginRight: 'auto',
    width: "95%",
    [theme.breakpoints.down('lg')]: {
      padding: '2rem 1rem 0rem 1rem'
    }
  },
  stepButtons: {
      marginTop: '9rem',
      [theme.breakpoints.down('sm')]: {
        marginTop: '3rem'
      },
  },
  saveButton: {
    margin: '.5rem 0rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  backButton: {
    padding: '0rem 1rem',
    margin: '.5rem 0rem',
    color: theme.palette.primary.main,    
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  resend: {
    fontWeight: '400',
    fontSize: '1rem'
  },
  confcodemuifield: {
    width: '100%',
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  },
  congtxtinput: {
    textAlign: "center",
    letterSpacing: "0.7em",
    fontSize: 20
  },
  modal: {
    position: 'absolute',
    background: '#FFFFFF',
    boxShadow: '0 0 .3rem rgba(0, 0, 0, 0.2)',
    boxSizing: 'inherit',
    borderRadius: '1.75rem',
    padding: '2rem',
    width: '20rem'
  },
  modalHeader:{
    marginBottom: '1rem'
  },
  modalButton: {
    marginTop: '1rem',
    textTransform: 'none'
  }
}));

export default function UpdatePhone() {
  const classes = useStyles();
  const { userInfo, setUserInfo } = useContext(AuthContext);
  const userInfoPhone = userInfo.phoneNumber;
  const theme = useTheme();
  const matchesXsDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [formData, setFormData] = useState({
    currentPhone: "", newPhone: "", confirmationCode: ""
  });

  const [correctCurrentPhone, setCorrectCurrentPhone ] = useState(false);
  const [validCurrentPhone, setValidCurrentPhone ] = useState(false);
  const [validNewPhone, setValidNewPhone ] = useState(false);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [navToAccountSettings, setNavToAccountSettings] = useState(false);
  const [successful, setSuccessful] = useState(false);

  const [saveLoading, setSaveLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalStyle] = useState(getModalStyle);

  const setError = useErrorViewer();

  const handleChange = (event) => {
    let key = event.target.name;
    let value = event.target.value;
    if(key === 'currentPhone'){
      setValidCurrentPhone(validatePhoneFormat(value))
      setCorrectCurrentPhone(stripPhoneNumber(value) === userInfoPhone)
    }
    else if(key === 'newPhone'){
        setValidNewPhone(validatePhoneFormat(value))
    }
    setFormData({
        ...formData,
        [key]: value
    });
  };

  const handleResendCode = () => {
    setResendLoading(true);
    userResendConfirm(userInfo.email).then(() => {
        setResendLoading(false);
    }).catch((error) => {
        setResendLoading(false);
        const err = error.response.data? error.response.data.message : "Unknown Error";
        setError(err);
    })
  }

  const validatePhoneFormat = (phone) => {
    const regex = /^\(?\d{3}[.)-]?\s*\d{3}[-.\s]?\d{4}$/
    return regex.test(phone);
  }

  const getCurrentPhoneErrorMessage = () => {
    if(formData.currentPhone && !correctCurrentPhone){
        return 'Value does not match phone number linked to your account.'
    }
    else{
        return ''
    }
  }

  const handleSave = () => {
    setSaveLoading(true);
    const formattedPhoneNumber = stripPhoneNumber(formData.newPhone)
    userUpdatePhone(userInfo.id, userInfo.accessToken, formattedPhoneNumber).then(() => {
        setUserInfo((prevState) => {return {...prevState, 'phoneNumber': formattedPhoneNumber}})
        setSaveLoading(false)
        setShowConfirmation(true);
    }).catch((error) => {
        setSaveLoading(false)
        const err = error.response.data? error.response.data.message : "Unknown Error";
        setError(err);
    })
  }

  const handleConfirm = () => {
    setConfirmLoading(true);
    userConfirmResetAttribute(userInfo.accessToken, 'phone_number', formData.confirmationCode).then(() => {
        setConfirmLoading(false)
        setSuccessful(true)
        setModalOpen(true);
    }).catch((error) => {
        setConfirmLoading(false)
        const err = error.response.data? error.response.data.message : "Unknown Error";
        setError(err);
    })
  }

if (navToAccountSettings)
  return (
    <Redirect to={{pathname: "/account", state: {successfulUpdate: successful, attribute: 'Phone'}}}></Redirect>

)
if (showConfirmation)
  return (
    <>
      <Container className={classes.mainContainer}>
        <Grid container spacing={1}>
          { matchesXsDown &&
            <SettingsStepper attribute={true} attributeLabel={'Phone'} url={'/phone'}/>
          }
          <Grid container item xs={12} justifyContent="flex-start" direction="column">
            <Typography variant="h2">
              Mobile Verification
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
                Enter the 6 digit code that was text messaged to the new phone number. Click resend if you haven't receive the verification code in 5 minutes.
            </Typography>
          </Grid>
          <Grid item container xs={10} sm={4}>
            <TextField
              className={classes.confcodemuifield}
              id="confirmCode"
              variant="outlined"
              margin="normal"
              value={formData.confirmationCode}
              name="confirmationCode"
              label="Six Digit Code"
              onChange={handleChange}
              placeholder="000000"
              type="number"
              inputProps={{
                inputMode: 'tel',
                className: classes.congtxtinput,
              }}
            />
          </Grid>
        </Grid>
        {resendLoading ?
          <CircularProgress color="primary" size={30} /> :
          <Grid item container xs={12} >
            <Link href="#" className={classes.resend} onClick={handleResendCode}>
              Didn't get the code? Resend
            </Link>
          </Grid>
        }
        <Grid item xs={12} container className={classes.stepButtons} direction={matchesXsDown ? "column-reverse" : "row"}>
          <Grid sm={2} item container justifyContent={matchesXsDown ? "center" : "flex-start"}>
            <Button
              className={classes.backButton} 
              onClick={() => setShowConfirmation(false)}
              color="primary"
              variant={matchesXsDown ? "outlined" : "text"}
              startIcon={matchesXsDown ? "" : <ArrowBackIos/>}
            >
              Back
            </Button>
          </Grid>
          <Grid sm={10} item container justifyContent={matchesXsDown ? "center" : "flex-end"}>
            {confirmLoading ?
              <CircularProgress color="primary" /> 
                :
              <Button
                className={classes.saveButton} 
                onClick={handleConfirm}
                variant="contained"
                color="primary"
                disabled={formData.confirmationCode.length < 6}
              >
                Confirm
              </Button>
            }
          </Grid>
        </Grid>
      </Container>
      <Modal open={modalOpen} >
        <Grid style={modalStyle} className={classes.modal}>
          <Grid item container justifyContent="flex-start">
            <Typography variant="h5" className={classes.modalHeader}>
              Success
            </Typography>
          </Grid>
          <Grid item container justifyContent="flex-start">
            <Typography variant="subtitle2">
              Your phone number has been updated.
            </Typography>
          </Grid>
          <Grid item container justifyContent="flex-end" >
            <Button
              className={classes.modalButton} 
              onClick={() => setNavToAccountSettings(true)}
              variant="text"
              color="primary"
            >
              Ok
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </>
  )

else 
  return (
    <Container className={classes.mainContainer}>
      <Grid container spacing={1}>
        { matchesXsDown &&
            <SettingsStepper attribute={true} attributeLabel={'Phone'}/>
        }
        <Grid container item xs={12} justifyContent="flex-start" direction="column">
          <Typography variant="h2">
            Update Phone Number
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            To update your phone number, please provide your current number as well as the new number.
          </Typography>
        </Grid>
        <Grid item xs={10} sm={6}>
          <InputMask mask="(999) 999-9999" value={formData.currentPhone} onChange={handleChange}>      
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="currentPhone"
              label="Current Phone Number"
              id="currentPhone"
              error={Boolean(formData.currentPhone && !correctCurrentPhone)}
              helperText={getCurrentPhoneErrorMessage()}
            />
          </InputMask>
          <InputMask mask="(999) 999-9999" value={formData.newPhone} onChange={handleChange}>      
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="newPhone"
              label="New Phone Number"
              id="newPhone"
            />
          </InputMask>
        </Grid>
        <Grid item xs={12} container className={classes.stepButtons} direction={matchesXsDown ? "column-reverse" : "row"}>
          <Grid sm={2} item container justifyContent={matchesXsDown ? "center" : "flex-start"}>
            <Button
              className={classes.backButton} 
              onClick={() => setNavToAccountSettings(true)}
              color="primary"
              variant={matchesXsDown ? "outlined" : "text"}
              startIcon={matchesXsDown ? "" : <ArrowBackIos/>}
            >
              Back
            </Button>
          </Grid>
          <Grid sm={10} item container justifyContent={matchesXsDown ? "center" : "flex-end"}>
            {saveLoading ?
              <CircularProgress color="primary" /> 
                :
              <Button
                className={classes.saveButton} 
                onClick={handleSave}
                variant="contained"
                color="primary"
                disabled={!correctCurrentPhone || !validCurrentPhone || !validNewPhone}
              >
                Save & Verify
              </Button>
            }
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
