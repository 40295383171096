import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useContext } from "react";
import { features, isFeatureDisabled } from "../constants/features";
import { ConfigContext } from "../context/configContext";
import PropTypes from "prop-types";
import makeStyles from '@mui/styles/makeStyles';
import { AuthContext } from "../context/authContext";

const useStyles =  makeStyles((theme) => ({
  button: {
    fontWeight: 'bold',
    margin: '1.5rem .5rem 0rem .5rem',
    minWidth: '6rem',
    color: theme.palette.primary.main   
  },
  title: {
    fontWeight: 'bold',
    fontSize: '1.2rem'
  },
  paragraph: {
    margin: "0.5rem"  
  }
}));

export default function FeatureFlag (props) {
    const { feature, hideOnDisabled } = props;
    const { clientConfig } = useContext(ConfigContext);
    const style = useStyles();
    const { userInfo } = useContext(AuthContext);
    const agencyId = userInfo?.agencyId;

    const goBack = () => {
        window.history.go(-1);
    }

    const renderErrorBody = (errMsg) => {
        const splitErr = errMsg.split("\n");

        return splitErr.map((err) => (
            <Typography component="p" style={style.paragraph}>
                {err}
            </Typography>
        ));
    }

    const renderErrorMessage = (ft, hideOnDisabled) => {
        return (features[ft]["showError"] && !hideOnDisabled) ?
            (<Dialog open>
                <DialogTitle>
                    <Typography style={style.title} align="center">
                        {features[ft]["errorTitle"] ?? "Feature Disabled"}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    {renderErrorBody(features[ft]["errorMessage"])}
                </DialogContent>
                <DialogActions>
                    <Button style={style.button} onClick={goBack}>
                        Go Back
                    </Button>
                </DialogActions>
            </Dialog>)
            :
            null;
    }

    return (
        <>
        {isFeatureDisabled(clientConfig, agencyId, feature) ? 
            renderErrorMessage(feature, hideOnDisabled)            
            :
            props.children
        }
        </>
    );
}

FeatureFlag.propTypes = {
    hideOnDisabled: PropTypes.bool,
    feature: PropTypes.string.isRequired
};