import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'
import { formatCurrency } from '../../utils/utilFunctions';

const useStyles = makeStyles((theme) => ({
  pledgeAmount: {
    color: theme.customColors.grey,
    fontWeight: "bold",
    fontSize: '3.5rem'
  },
  pledgedText: {
    color: theme.customColors.grey,
    fontWeight: "bold"
  }
}));

export default function Pledged({ amount = 0}) {
  const classes = useStyles();
  return (
    <Grid item container direction="column" alignItems="center" alignContent="center">
      <Typography variant="h2" className={classes.pledgeAmount}>{`${formatCurrency(amount)}`}</Typography>
      <Typography variant="h5" className={classes.pledgedText}>Pledged</Typography>
    </Grid>
  )
}
