import Container from "../Container";
import { FooterSection, Para } from "./styles";

const Footer = () => {

  return (
    <>
      <FooterSection>
        <Container>
            <Para>
              {`© `}{new Date().getFullYear()} {` Entergy Services, LLC. All rights reserved.`}
            </Para>
            {process.env.REACT_APP_ENV !== 'prod' && 
        <Para>
          {'Environment: ' + process.env.REACT_APP_ENV}
          <br />
          {'Version: ' + process.env.REACT_APP_GIT_HASH }
        </Para>
      }
        </Container>
      </FooterSection>
    </>
  );
};

export default Footer;
