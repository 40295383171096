import { APIUtils } from './APIUtils';

const getApplications = () => {
  return APIUtils.get(`applications`)
}

const getPreviousSeasonApplications = () => {
  return APIUtils.get('applications/previousSeason')
}

const getApplicationById = (id) => {
  return APIUtils.get(`applications/${id}`)
}

const createApplication = (data, copyPreviousSeason=false) => {
  return APIUtils.post(`applications?copyPreviousSeason=${copyPreviousSeason}`, data)
}

const saveApplication = (id, data) => {
  return APIUtils.patch(`applications/${id}`, data)
}

const submitApplication = (id, signatureData) => {
  return APIUtils.post(`applications/${id}/submit`, signatureData)
}

const createApplicantPDF = (id) => {
  return APIUtils.post(`applications/${id}/createApplicantPDF`)
}

export {
  getApplications,
  getApplicationById,
  createApplication,
  saveApplication,
  submitApplication,
  createApplicantPDF,
  getPreviousSeasonApplications
};