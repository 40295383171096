import makeStyles from '@mui/styles/makeStyles';
import React from 'react'
import { Grid, Typography, Container, Tooltip } from '@mui/material';
import { VerifiedOutlined, ErrorOutline } from '@mui/icons-material'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    marginLeft: "0",
    padding: "0"
  }, 
  summaryBox: {
    padding: "2rem 0"
  },
  row: {
      marginBottom: '1rem'
  },
  label: {
      fontWeight: '600'
  },
  verifyIcon: {
    color: '#3576CB',
    width: '1rem',
    height: '1rem'
  },
  warningIcon: {
    color: '#DD3730',
    width: '1rem',
    height: '1rem'
  },
  spinner: {
    margin: '1rem',
    justifyContent: 'center'
  },
}));


export default function AccountSummary( { application } ) {
  const classes = useStyles();
  
  const acctInfo = application?.entergyAccountInfo;
  const verified = acctInfo ? Object.keys(acctInfo).length > 1 : false;

  const getAccountStatus = (status) => {
    switch (status) {
        case "A":
          return "Active"
        case "D":
          return "Disconnected"
        case "F":
          return "Final"
        case "PA":
          return "Pending Account"
        case "PD":
          return "Pending Disconnect"
        case "PF":
          return "Pending Final"
        case "PI":
          return "Pending Initiate"
        case "WO":
          return "Written Off"
        default:
          return 'N/A'
      }
  }

  const getFetchDate = (value) => {
    const options = {
        year: "2-digit",
        month:"2-digit",
        day:"2-digit",
      };
      return new Date(value).toLocaleDateString("en-US", options).replace(', ','\n');
  }

  const getDollarValue = (value) => {
    if(verified){
      if(value >= 0){
        return `$${value}`
      }
      else if(value < 0){
        return `-$${value*-1}`
      }
      else{
        return 'N/A'
      }
    }
    else{
      return 'N/A'
    }
  }

  return (
    <Container className={classes.mainContainer}>
        <Grid item xs={12}>
            <Typography variant='subtitle2'>
              { application?.entergyAccountInfo ? 
                `(Account info last retrieved ${getFetchDate(application?.entergyAccountInfo?.infoFetchDate)})`
                :
                '(Account verification in progress)'
              }
            </Typography> 
        </Grid>
        <Grid container item xs={12} className={classes.summaryBox}>
            <Grid container item xs={12} className={classes.row}>
                <Grid item xs={6} >
                    <Typography variant='subtitle2' className={classes.label}>
                        Entergy Account Number
                    </Typography> 
                    {verified ?
                      <Grid container item>
                        <Typography variant='subtitle1'>
                          {application?.entergyAccountInfo?.contractAccountNumber}
                        </Typography>
                        <Tooltip title="Verified" placement='right'>
                          <VerifiedOutlined fontSize='small' className={classes.verifyIcon}/>
                        </Tooltip>
                      </Grid>
                    : 
                      <Grid container item>
                        <Typography variant='subtitle1'>
                          {application?.electricityAcctNo}
                        </Typography>
                        <Tooltip title="Account Not Verified" placement='right'>
                          <ErrorOutline className={classes.warningIcon}/>
                        </Tooltip>
                      </Grid>
                    }
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='subtitle2' className={classes.label}>
                        Account Status
                    </Typography> 
                    <Typography variant='subtitle2'>
                        {verified ? getAccountStatus(application?.entergyAccountInfo?.accountStatus) : 'N/A'}
                    </Typography> 
                </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.row}>
                <Grid item xs={6}>
                    <Typography variant='subtitle2' className={classes.label}>
                        Total Amount Due
                    </Typography> 
                    <Typography variant='subtitle2'>
                        {getDollarValue(application?.entergyAccountInfo?.dueAmount)}
                    </Typography> 
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='subtitle2' className={classes.label}>
                        Total Amount Past Due
                    </Typography> 
                    <Typography variant='subtitle2'>
                        {getDollarValue(application?.entergyAccountInfo?.overdueAmount)}                        
                    </Typography> 
                </Grid>
            </Grid>
            <Grid container item xs={12} >
                <Grid item xs={6}>
                    <Typography variant='subtitle2' className={classes.label}>
                        Verified Name
                    </Typography> 
                    <Typography variant='subtitle2'>
                        {verified ? application?.entergyAccountInfo?.customerName : 'N/A'}
                    </Typography> 
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='subtitle2' className={classes.label}>
                        Verified Address
                    </Typography> 
                    <Typography variant='subtitle2'>
                        {verified ? application?.entergyAccountInfo?.streetAddress : 'N/A'}
                    </Typography> 
                </Grid>
            </Grid>
        </Grid>
    </Container>
  )
}
