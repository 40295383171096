export const raceOptions = [
  {
    value: 'asian',
    label: 'Asian'
  },
  {
    value: 'africanAmerican',
    label: 'Black or African-American'
  },
  {
    value: 'americanIndian',
    label: 'American Indian or Alaskan Native'
  },
  {
    value: 'hawaiianPacificIslander',
    label: 'Native Hawaiian or other Pacific Islander'
  },
  {
    value: 'white',
    label: 'White'
  },
  {
    value: 'multiRace',
    label: 'Multi-race'
  },
  {
    value: 'other',
    label: 'Other'
  }
]
