export const agencyPermissions = {
  CLIENT_INTAKE_DASHBOARD_EDIT: "ClientIntakeDashboard::edit",
  CLIENT_INTAKE_DASHBOARD_VIEW: "ClientIntakeDashboard::view",
  REVIEW_APPLICATION_EDIT: "ReviewApplication::edit",
  REVIEW_APPLICATION_VIEW: "ReviewApplication::view",
  REVIEW_APPLICATION_NONE: "ReviewApplication::none",
  AGENCY_ADMIN_EDIT: "AgencyAdmin::edit",
  REPORTS_VIEW: "Reports::view",
  REPORTS_NONE: "Reports::none"
}
