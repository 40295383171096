export const genderOptions = [
  {
    value: 'female',
    label: 'Self-Identified Female'
  },
  {
    value: 'male',
    label: 'Self-Identified Male'
  },
  {
    value: 'other',
    label: 'Other'
  },
  {
    value: 'unknownGender',
    label: 'Prefer not to say'
  },

]

export const agencyGenderOptions = [
  {
    value: 'female',
    label: 'Self-Identified Female'
  },
  {
    value: 'male',
    label: 'Self-Identified Male'
  },
  {
    value: 'other',
    label: 'Other'
  },
  {
    value: 'unknownGender',
    label: 'Unknown / Not Reported'
  },

]