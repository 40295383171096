import { Grid, CssBaseline, Container, Button, CircularProgress, Link, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { userConfirm, userResendConfirm } from '../../api/UserAPI';
import makeStyles from '@mui/styles/makeStyles';
import SecurityIcon from '@mui/icons-material/Security';
import Avatar from '@mui/material/Avatar';
import 'react-phone-input-2/lib/material.css';

const useStyles = makeStyles((theme) => ({
    congtxtinput: {
        textAlign: "center",
        letterSpacing: "0.7em",
        fontSize: 20
    },
    confcodemuifield: {
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    },
    paper: {
        marginTop: theme.spacing(5),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: "center",
        background: "#ffffff",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.dark,
        fontWeight: "bolder"
    },
    link: {
        margin: theme.spacing(1),
        color: theme.palette.primary.dark,
    },
    phone: {
        margin: '1rem 0'
    },
    form: {
        width: 'auto', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function ConfirmUser({userName, phone, confirmSuccess}) {
    const classes = useStyles();
    const [confirmCode, setConfirmCode ] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [invalidConfirmCode, setInvalidConfirmCode] = useState(false);
    const [resendLoading, setResendLoading] = useState(false);

    const validateConfirmCode = (confirmCode) => {
        const regex = /^\d{6}$/
        return regex.test(confirmCode);
    }

    const getMaskedPhone = (ph) => {
        if(ph) {
            const lastFour = ph.substr(ph.length - 4);
            return `XXX-${lastFour}`;
        }
        else {
            return "XXX-XXXX";
        }
    };

    const handleConfirm = () => {
        setConfirmLoading(true);
        userConfirm(userName, confirmCode).then(() => {
            setConfirmLoading(false);
            confirmSuccess();
        }).catch(() => {
            setConfirmLoading(false);
            setConfirmCode("");
            setInvalidConfirmCode(true);
        })
    }

    const handleResendCode = () => {
        setResendLoading(true);
        userResendConfirm(userName).then(() => {
            setResendLoading(false);
        }).catch((error) => {
            setResendLoading(false);
            alert(error.response.data);
        })
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <SecurityIcon />
                </Avatar>
                <Typography variant="body2" gutterBottom>
                    Protecting your data is our top priority. Please confirm your account by entering the code sent to <b>{getMaskedPhone(phone)}</b>.
                </Typography>

                <TextField
                    className={classes.confcodemuifield}
                    id="confirmCode"
                    variant="outlined"
                    margin="normal"
                    value={confirmCode}
                    name="confirmCode"
                    label="Six Digit Code"
                    onChange={(event) => setConfirmCode(event.target.value)}
                    placeholder="000000"
                    type="number"
                    error={invalidConfirmCode}
                    inputProps={{
                        inputMode: 'tel',
                        max: 6,
                        className: classes.congtxtinput,
                    }}
                />
                <Grid item xs={12}>
                    {confirmLoading ?
                        <CircularProgress color="primary" size={36} /> :
                        <Button
                            className={classes.submit}
                            fullWidth
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={!validateConfirmCode(confirmCode)}
                            onClick={handleConfirm}
                        >
                            Verify My Account
                        </Button>
                    }
                    {resendLoading ?
                        <CircularProgress color="primary" size={30} /> :
                        <Grid item>
                            <Typography variant="caption" >It may take a minute to get the code. Haven't recieved it?</Typography>
                            <Link href="#" className={classes.link} variant="caption" onClick={handleResendCode}>
                                {<b>Resend again.</b>}
                            </Link>
                        </Grid>
                    }
                </Grid>
            </div>
        </Container>
    )
}