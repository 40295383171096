import { FormControlLabel, Checkbox, Typography, Grid, FormHelperText } from '@mui/material';
import React, { useState, useEffect } from 'react'
import { FormTooltip } from './FormTooltip';
import makeStyles from '@mui/styles/makeStyles';
import { getErrorMessage } from '../../utils/utilFunctions';
import CheckedIcon from '../CheckedIcon';

const useStyles = makeStyles(theme => ({
  tooltip: {
    fontWeight: "bold"
  },
  label: {
    fontWeight: "bold",
    margin: ".6rem 0"
  },
  noOption: {
    marginTop: ".5rem"
  },
  optionSpacing: {
    marginBottom: "1rem"
  },
  checkBox: {
    color: theme.customColors.mediumGray,
    padding: '.75rem'
  },
  error: {
    color: theme.palette.error.main
  }
}));

export default function FormCheckboxes({ defaultValues, register, name, label, toolTipText, options, gridSize, onSelect, errors = [], noOption, increasedOptionSpace = false}) {
  const [checkedValues, setCheckedValues] = useState(defaultValues);
  const classes = useStyles();

  useEffect(() => {
    //if the default value prop changes, reset the checked values 
    //needed for when selection of another component changes the options for the checkbox
    if(defaultValues){
      setCheckedValues(defaultValues);
    }
  }, [defaultValues]);

  function handleSelect(checkedName) {
    let newNames = [];
    if(checkedName==="noOption"){
      newNames = checkedValues?.includes(checkedName)
      ? checkedValues?.filter(name => name !== checkedName)
      : [checkedName];
    }
    else{
      newNames = checkedValues?.includes(checkedName)
        ? checkedValues?.filter(name => name !== checkedName)
        : [...(checkedValues ?? []), checkedName];
      newNames = newNames?.filter(name => name !== "noOption");
    }
    setCheckedValues(newNames);
    if(onSelect){
      onSelect(newNames);
    }
    return newNames;
  }
  
  return (
    <>
      {toolTipText 
        ?
        <FormTooltip title={toolTipText} placement="top-end" arrow>
           <Typography variant="subtitle1" className={classes.tooltip}>{label}</Typography>
        </FormTooltip>
        :
        <Typography variant="subtitle1" className={classes.label}>{label}</Typography>
      }
      <Grid container item justifyContent="flex-start"> 
        {
          options.map ((option, i) => (
            <Grid item xs={12} md={gridSize} key={i} className={increasedOptionSpace ? classes.optionSpacing : ''}>
              <FormControlLabel
                value={option.value}
                checked={checkedValues ? checkedValues.includes(option.value) : false}
                control={<Checkbox className={classes.checkBox} checkedIcon={<CheckedIcon />}/>}
                onChange={() => handleSelect(option.value)}
                label={option.label}
                name={name}
                error={Boolean(getErrorMessage(errors, name)).toString()}
                inputRef={register}
              />
            </Grid>
          ))
        }
        {noOption && 
        <Grid item xs={12} className={classes.noOption}>
          <FormControlLabel
            value={"noOption"}
            checked={checkedValues ? checkedValues.includes("noOption") : false}
            control={<Checkbox className={classes.checkBox} checkedIcon={<CheckedIcon />}/>}
            onChange={() => handleSelect("noOption")}
            label={noOption}
            name={name}
            inputRef={register}
          />
        </Grid>
        }
        <FormHelperText className={classes.error}>{getErrorMessage(errors, name)}</FormHelperText>
      </Grid>
    </>
  )
}
