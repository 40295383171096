import { Container, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { yesNoOptions } from '../../../../constants/radios/yesNoOptions';
import { ownHousingTypeOptions } from '../../../../constants/tx/radios/ownHousingTypeOptions';
import { rentHousingTypeOptions } from '../../../../constants/tx/radios/rentHousingTypeOptions';
import useLiheapForm from '../../../../hooks/useLiheapForm';
import FormCurrencyField from '../../../form/FormCurrencyField';
import FormRadioAsButtons from '../../../form/FormRadioAsButtons';
import FormTextField from '../../../form/FormTextField';
import FormSection from '../../../FormSection';
import StepButtons from '../../../StepButtons';
import StepHeader from '../../../StepHeader';

const useStyles = makeStyles((theme) => ({
  mainContainer:{
    margin: "0"
  }
}));

export default function UtilityHousingInformation({ data, back, next, save, validationSchema, validate }) {
  const classes = useStyles();
  const { register, control, errors, watch, setValue } = useLiheapForm(save, data, validate, validationSchema, false);

  const handleCovidSelect = (val) => {
    if(val === 'no'){
      setValue("covidExplaination", '', { shouldDirty: true });
    }
  }
  const affectedByCovidWatch = watch("affectedByCovid");

  const determineHousingStatus = () => {
    if (data?.rentOrOwn === 'rent'){
      setValue("ownHousingType", '', { shouldDirty: true });
      return "rent";
    }else {
      setValue("rentHousingType", '', { shouldDirty: true });
      return "own";
    }
  }

  const housingStatus = determineHousingStatus();

  return (
    <Container className={classes.mainContainer}>
        <Grid container spacing={1}>
          <StepHeader 
            title="Utility Information" 
            desc = "Please provide the following information for the applicant."
          />
            <FormSection>
              <Grid item xs={12}>
                {housingStatus === 'rent' ?
                  <FormRadioAsButtons
                    control={control}
                    name="rentHousingType"
                    label="What is your type of housing?"
                    errors={errors}
                    options={rentHousingTypeOptions}
                  />
                :
                  <FormRadioAsButtons
                    control={control}
                    name="ownHousingType"
                    label="What is your type of housing?"
                    errors={errors}
                    options={ownHousingTypeOptions}
                  />
                }
              </Grid>
            </FormSection>
            <FormSection>
              <Grid item xs={12} sm={6}>
                <FormCurrencyField
                  name="monthlyRentOrMortgage"
                  label={`Please provide your monthly ${housingStatus === 'rent' ? 'rent' : 'mortgage'} amount`}
                  defaultValue={data?.monthlyRentOrMortgage}
                  labelPlacement="top"
                  control={control}
                  errors={errors}
                />
              </Grid>
            </FormSection>
            <FormSection>
              <Grid item xs={12} sm={9}>
                <FormTextField
                  name="needForAssistanceExplaination" 
                  label="Please explain what has happened in the past 30 days that has caused you to seek our assistance and/or a reduction in income:"
                  labelPlacement="top" 
                  multiline
                  rows={6}
                  register={register} 
                  errors={errors}                
                />
              </Grid>
            </FormSection>
            <FormSection>
              <Grid item xs={12}>
                <FormRadioAsButtons
                  control={control}
                  name="affectedByCovid"
                  label="Have you been affected by Covid?"
                  errors={errors}
                  options={yesNoOptions}
                  onSelect={handleCovidSelect}
                />
              </Grid>
              <Grid item xs={12} sm={9}>
                {affectedByCovidWatch === 'yes' &&
                  <FormTextField
                    name="covidExplaination" 
                    label="Please explain in detail"
                    labelPlacement="top" 
                    multiline
                    rows={6}
                    register={register} 
                    errors={errors}                
                  />
                }
              </Grid>
            </FormSection>
          <StepButtons back={back} next={next}/>
        </Grid>
    </Container>
  )
}
