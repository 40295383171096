export const agencyOptions = [
    {
      value: 'ARVAC-AR',
      label: 'ARVAC-AR'
    },
    {
      value: 'CADC-AR',
      label: 'CADC-AR'
    },
    {
      value: 'CSO-AR',
      label: 'CSO-AR'
    },
    {
      value: 'CDCAA-AR',
      label: 'CDCAA-AR'
    },
    {
        value: 'CAPCA-AR',
        label: 'CAPCA-AR'
    },
    {
        value: 'AR',
        label: 'AR'
    }
  ];