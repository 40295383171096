import { useEffect } from 'react';
import { useBeforeunload } from 'react-beforeunload';
import { weatherProofingResetFields } from '../constants/resetFields';
import { isEmptyObject } from '../utils/utilFunctions';

export default function useSaveChanges(dirtyFields, save, getValues) {

  const isDirty = () => {
    return !isEmptyObject(dirtyFields);
  }

  const saveOnChanges = () => {
    //only save if changes have been made
    if (isDirty()) {
      //only save what changed
      let fieldsToSave = getValues(Object.keys(dirtyFields));
      
      //incase of edit to previous question that controls conditional fields, wipe those fields on selection of controlling field
      if(fieldsToSave?.weatherProofingOrEfficiency === 'no'){
        let fieldsToReset = weatherProofingResetFields;
        fieldsToSave = {...fieldsToSave, ...fieldsToReset}
      }

      //current python verison doesn't support isoformat with trailing Z. can remove after upgrade to python 3.11
      else if(fieldsToSave?.birthDate){
        fieldsToSave = {...fieldsToSave, 'birthDate': fieldsToSave?.birthDate.toISOString().replace('Z', '')}
      }
      else if(fieldsToSave?.lastEmployedDate){
        fieldsToSave = {...fieldsToSave, 'lastEmployedDate': fieldsToSave?.lastEmployedDate.toISOString().replace('Z', '')}
      }

      save(fieldsToSave)
    }
  }

  //warn user if navigates away from browser tab with unsaved changes
  useBeforeunload((event) => {    
    if (isDirty()) {
      event.preventDefault();
    }
  });

  //save on effect cleanup (if user clicks on any links within the app like continue, back, stepper links, etc.)
  useEffect(() => {
    return () => {      
      saveOnChanges()
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  
}