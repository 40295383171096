import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  Typography,
  Modal,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import { submitApplication } from '../../../api/ApplicationsAPI';
import StepButtons from '../../StepButtons'
import StepHeader from '../../StepHeader';
import ConfirmationDialog from '../../ConfirmationDialog';
import FormSection from "../../FormSection";
import CheckedIcon from '../../CheckedIcon';
import { submitTermHeading, submitTerm1, submitTerm2, submitTerm3, 
  submitTerm4, submitTerm5, submitTerm6, submitTerm7, submitTerm8 
} from '../../../constants/ar/submitTerms';
import { useErrorViewer } from '../../../context/errorContext';
import SignaturePad from 'react-signature-canvas';
import FeatureFlag from '../../FeatureFlag';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    marginLeft: "0"
  },
  subHeader: {
    color: theme.customColors.darkGrey,
    fontWeight: "bold",
    marginTop: ".6rem"
  },
  termsBox: {
    margin: "1.3rem 0px",
    backgroundColor: theme.customColors.lightGrey,
    padding: "2rem",
    overflow: "auto"
  },
  termsText: {
    color: theme.customColors.darkGrey,
    padding: ".1rem"
  },
  checkbox: {
    margin: ".6rem 0rem",
    width: "80%"
  },
  checkboxBox: {
    padding: ".75rem",
    alignSelf: "start",
    color: theme.customColors.mediumGray 
  },
  submitButton: {
    marginTop: "1.3rem",
    marginBottom: "4rem",
    width: "18rem",
    display: "block"
  },
  spinner:{
    position: "relative", 
    textAlign: "center",
    top: '50%',
  },
  modal: {
    position: 'absolute',
    backgroundColor: theme.customColors.veryLightGray,
    boxShadow: '0 0 .3rem rgba(0, 0, 0, 0.2)',
    bozSizing: 'inherit',
    borderRadius: '.5rem',
    padding: '1rem',
    width: '-webkit-fill-available',
    maxWidth: '30rem'
  },
  modalButton: {
    margin: '.6rem .3rem'
  },
  sigPadHeader: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    margin: ".6rem 0rem"
  },
  sigPad: {
    borderColor: theme.customColors.darkGrey,
    borderWidth: '.5rem 1.5rem',
    borderStyle: 'solid',
    overflow: 'hidden',
    backgroundColor: '#fff',
    width: '100%'
  },
  canvas: {
    height: '20rem'
  },
  signatureImage: {
    width: '-webkit-fill-available',
    maxWidth: '20rem',
    maxHeight: '10rem'
  },
  sign: {
    marginTop: '1rem',
    textAlign: 'center'
  },
  edit: {
    marginTop: '1rem',
    textAlign: 'end'
  }
}));

export default function Submit({ data, next, back }) {
  const classes = useStyles();
  const { id: appId } = useParams();
  const queryClient = useQueryClient();
  const [dialogOpen, setDialogOpen] = useState(false);
  const setError = useErrorViewer();
  const submitFeature = `${data?.status}Submit`;

  const { handleSubmit } = useForm({
    defaultValues: data
  })

  const [checked, setChecked] = useState(false);

  const [modalStyle] = useState(getModalStyle);
  
  const [openApplicantModal, setOpenApplicantModal] = useState(false);
  const handleOpenApplicantModal= () => {
    setOpenApplicantModal(true);
  };
  const handleCloseApplicantModal = () => {
    setOpenApplicantModal(false);
  };

  const [openPreparerModal, setOpenPreparerModal] = useState(false);
  const handleOpenPreparerModal = () => {
    setOpenPreparerModal(true);
  };
  const handleClosePreparerModal= () => {
    setOpenPreparerModal(false);
  };

  const [applicantSignature, setApplicantSignature] = useState('');
  const [preparerSignature, setPreparerSignature] = useState('');

  const submittingAsPreparer = data.submittingAs && data.submittingAs === 'someoneElse';

  const applicantSigCanvas = useRef('');
  const clearApplicant = () => {
    applicantSigCanvas.current.clear();
    setApplicantSignature('');
  }
  const saveApplicant = () => {
    if(applicantSigCanvas.current.isEmpty()){
      setApplicantSignature('')
    }
    else{
      setApplicantSignature(applicantSigCanvas.current.getTrimmedCanvas().toDataURL("image/png")); 
    }
    handleCloseApplicantModal()
  }

  const preparerSigCanvas = useRef({});
  const clearPreparer = () => {
    preparerSigCanvas.current.clear();
    setPreparerSignature('');
  }
  const savePreparer = () => {
    if(preparerSigCanvas.current.isEmpty()){
      setPreparerSignature('')
    }
    else{
      setPreparerSignature(preparerSigCanvas.current.getTrimmedCanvas().toDataURL("image/png")); 
    }
    handleClosePreparerModal()
  }

  const submitMutation = useMutation(appId => {
    const signatureData = {
      applicantSignature: applicantSignature, 
      preparerSignature: preparerSignature, 
      signedDate: new Date()
    };
    return submitApplication(appId, signatureData);
  })

  const onChange = (event) => {
    setChecked(event.target.checked);
  };

  const onSubmit = () => {
    submitMutation.mutate(appId, {
      onSuccess: (data) => {
        let response = data.data;
        if(response.valid){
          //invalidate applications/application queries for dashboard/status
          queryClient.invalidateQueries('applications');
          queryClient.invalidateQueries(['application', { id: appId }]);
          next({});
        }else{
          setDialogOpen(true);
        }
      },
      onError: (error) => setError("An Error Occurred Submitting The Application") 
    })
  }

  if (submitMutation.isLoading) {
    return (
      <Grid container item justifyContent="center" className={classes.spinner}>
        <CircularProgress color="primary" size={50} />
      </Grid>
    )
  }
  return (
    <Container className={classes.mainContainer}>
      <form noValidate autoComplete="false" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1}>
          <StepHeader 
            title="Submit Your Application" 
            desc = "Please read the Terms and Conditions and digitally sign below before submitting your application."
          />
          <FormSection>
          <Grid container item xs={11}>
            <Typography className={classes.subHeader} variant="h5">Accepting of terms</Typography>
            <Box className={classes.termsBox}>
              <Typography className={classes.termsText} variant="subtitle1">
                {submitTermHeading}
              </Typography>
              <Typography className={classes.termsText} variant="subtitle1">
                {submitTerm1}
              </Typography>
              <Typography className={classes.termsText} variant="subtitle1">
                {submitTerm2}
              </Typography>
              <Typography className={classes.termsText} variant="subtitle1">
                {submitTerm3}
              </Typography>
              <Typography className={classes.termsText} variant="subtitle1">
                {submitTerm4}
              </Typography>
              <Typography className={classes.termsText} variant="subtitle1">
                {submitTerm5}
              </Typography>
              <Typography className={classes.termsText} variant="subtitle1">
                {submitTerm6}
              </Typography>
              <Typography className={classes.termsText} variant="subtitle1">
                {submitTerm7}
              </Typography>
              <Typography className={classes.termsText} variant="subtitle1">
                {submitTerm8}
              </Typography>
            </Box>
            </Grid>
            <FormControlLabel
              className={classes.checkbox}
              control={
                <Checkbox
                  className={classes.checkboxBox}
                  checked={checked}
                  onChange={onChange}
                  name="acceptTerms"
                  color="primary"
                  checkedIcon={<CheckedIcon />}
                />
              }
              label="I understand that the information provided in this certification is subject to audit and verification as deemed necessary and failure to comply with any request for additional information in conjunction with such audit and verification."
            >
            </FormControlLabel>
            </FormSection>
            <FormSection>
              <Grid container item xs={12} sm={12} md={6}>
              <Typography variant="h6" className={classes.sigPadHeader}>
                {`${data?.firstName} ${data?.lastName}'s Signature`}
              </Typography>
              {applicantSignature ? 
              <>
              <Grid item xs={12} className={classes.sign}>
                <img className={classes.signatureImage} src={applicantSignature} alt=''/>
              </Grid>
              <Grid item xs={12} className={classes.edit}>
                <Button className={classes.sigPadClear} variant="contained" color="primary" onClick={handleOpenApplicantModal}>edit signature</Button>
              </Grid>
              </>
              :
              <Grid item xs={12} className={classes.sign}>
                <Button className={classes.sigPadClear} variant="contained" color="primary" onClick={handleOpenApplicantModal}>click here to sign</Button>
              </Grid>
              }
              </Grid>
              
            </FormSection>
            {submittingAsPreparer &&
              <FormSection>
                <Grid container item xs={12} sm={12} md={6}>
                    <Typography variant="h6" className={classes.sigPadHeader}>
                      {`${data?.preparerName}'s Signature`}
                    </Typography>
                  {preparerSignature ? 
                  <>
                  <Grid item xs={12} className={classes.sign}>
                    <img className={classes.signatureImage} src={preparerSignature} alt=''/>
                  </Grid>
                  <Grid item xs={12} className={classes.edit}>
                    <Button  variant="contained" color="primary" onClick={handleOpenPreparerModal}>edit signature</Button>
                  </Grid>
                  </>
                  :
                  <Grid item xs={12} className={classes.sign}>
                    <Button className={classes.sigPadClear} variant="contained" color="primary" onClick={handleOpenPreparerModal}>click here to sign</Button>
                  </Grid>
                  }
                </Grid>
              </FormSection>
            }
            <Grid container item xs={12} justifyContent="center">
              <FeatureFlag feature={submitFeature}>
                <Button
                  className={classes.submitButton}
                  variant="contained"
                  color="primary"
                  disabled={!checked || !applicantSignature || (submittingAsPreparer && !preparerSignature)}
                  type="submit"
                >
                  Sign & Submit
                </Button>
              </FeatureFlag>
            </Grid>
          <StepButtons back={back} showContinue={false} />
        </Grid>
      </form>
      <ConfirmationDialog 
          open={dialogOpen}
          title="Error Submitting Application"
          desc={`Please close this dialog ("OK" or "CANCEL") and click the "BACK" link to return the Review screen and verify all sections are "Completed". If all sections are "Completed", please contact support.`}
          handleCancel={() => setDialogOpen(false)}
          handleOk={() => setDialogOpen(false)}
        />

    <Modal open={openApplicantModal} onClose={handleCloseApplicantModal}>
      <Grid  style={modalStyle} className={classes.modal}>
        <Typography variant="h6" className={classes.sigPadHeader}>
          {`${data?.firstName} ${data?.lastName}'s Signature`}
        </Typography>
        <Grid container item xs={12} sm={12} justifyContent="flex-end">
          <div className={classes.sigPad}>
            <SignaturePad ref={applicantSigCanvas} canvasProps={{className: classes.canvas}}/>
          </div>
          <Button className={classes.modalButton} variant="outlined" color="primary" onClick={handleCloseApplicantModal}>cancel</Button>
          <Button className={classes.modalButton} variant="contained" color="primary" onClick={clearApplicant}>clear</Button>
          <Button className={classes.modalButton} variant="contained" color="primary"onClick={saveApplicant}>save</Button>
        </Grid> 
      </Grid>
    </Modal>

    <Modal open={openPreparerModal} onClose={handleClosePreparerModal}>
      <Grid  style={modalStyle} className={classes.modal}>
        <Typography variant="h6" className={classes.sigPadHeader}>
          {`${data?.preparerName}'s Signature`}
        </Typography>
        <Grid container item xs={12} justifyContent="flex-end">
          <div className={classes.sigPad}>
            <SignaturePad ref={preparerSigCanvas} canvasProps={{className: classes.canvas}}/>
          </div>
          <Button className={classes.modalButton} variant="outlined" color="primary" onClick={handleClosePreparerModal}>cancel</Button>
          <Button className={classes.modalButton} variant="contained" color="primary" onClick={clearPreparer}>clear</Button>
          <Button className={classes.modalButton} variant="contained" color="primary" onClick={savePreparer}>save</Button>
        </Grid> 
      </Grid>
    </Modal>

  </Container>
)
}
