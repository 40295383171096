
import React from 'react';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Intro from '../components/home-page/Intro';
import About from '../components/home-page/About';
import HowItWorks from '../components/home-page/HowItWorks';
import GetHelp from '../components/home-page/GetHelp';
import FAQ from '../components/home-page/FAQ';
import Legal from '../components/home-page/Legal';
import MessageBanner from '../components/MessageBanner';

const useStyles = makeStyles((theme) => ({
  background:{
    backgroundColor: theme.customColors.homepageGrey
  }
}));

export default function Homepage() {
  const classes = useStyles();
  
  return (
    <Grid xs={12} item container justifyContent="flex-start" spacing={1} direction="column">
      <MessageBanner messageType="FrontPage"></MessageBanner>
      <Grid>
        <Intro/>
      </Grid>
      <Grid className={classes.background}>
        <About/>
      </Grid>
      <Grid id='howItWorksScrollTo'>
        <HowItWorks/>
      </Grid>
      <Grid className={classes.background}>
        <GetHelp />
      </Grid>
      <Grid>
        <FAQ/>
      </Grid>
      <Grid>
        <Legal/>
      </Grid>
    </Grid>
  );
}
