import React, { useState, useEffect, useContext } from 'react'
import { Grid, TextField, InputAdornment, Typography, IconButton, Divider, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Search, InsertDriveFile, DescriptionOutlined } from '@mui/icons-material';
import { useMutation } from 'react-query';
import { searchAndFilterApplications } from '../../../api/AgencyAPI';
import { statuses } from '../../../constants/appStatuses';
import TableFilter from '../components/TableFilter';
import { yesNoOptions } from '../../../constants/radios/yesNoOptions';
import { urgentOptions } from '../../../constants/dropdowns/urgentOptions';
import { statusOptions } from '../../../constants/dropdowns/statusOptions';
import { seasonOptions } from '../../../constants/dropdowns/seasonOptions';
import { getFullName, getAddress, getSortComparatorFullName, getFormattedDate, getPledgeAmount, getDuplicateStatus } from '../../../utils/utilAgencyTableFunctions';
import { Link } from 'react-router-dom';
import { StyledDataGrid } from '../../StyledDataGrid';
import { agencyPermissions } from '../../../constants/agencyPermissions';
import AssignAgencyUser from '../components/AssignAgencyUser';
import useAssignableAgencyUsers from '../../../hooks/useAssignableAgencyUsers';
import { AuthContext } from '../../../context/authContext';
import { useErrorViewer } from "../../../context/errorContext";
import UrgencyInfo from "../../UrgencyInfo";
import { retryFilter, getUrgentIcon, SortedAscendingIcon, SortedDescendingIcon, appSearch, getCountyOptions, getStatusChip, getDuplicateIcon } from '../../../utils/dashboardUtils/utilSharedTableFunctions';
import DuplicateInfo from '../components/DuplicateInfo';
import NotesAndHistory from '../../NotesAndHistory';
import SaveMergedPDF from '../components/SaveMergedPDF';

const SEARCH_DELAY = 300;
const FILTER_CALL_DELAY = 1000;
const PAGE_SIZE = 50;


const useStyles = makeStyles((theme) => ({
  approved: {
    backgroundColor: theme.customColors.applicationStatus.approved
  },
  denied: {
    backgroundColor: theme.customColors.applicationStatus.denied
  },
  returned: {
    backgroundColor: theme.customColors.applicationStatus.returned   
  },
  submitted: {
    backgroundColor: theme.customColors.applicationStatus.submitted
  },
  reSubmitted: {
    backgroundColor: theme.customColors.applicationStatus.reSubmitted
  },
  inProgress: {
    backgroundColor: theme.customColors.applicationStatus.inProgress
  },
  onPaper: {
    backgroundColor: theme.customColors.applicationStatus.onPaper
  },
  pending: {
    backgroundColor: theme.customColors.applicationStatus.pending
  },
  error: {
    color: theme.customColors.applicationStatus.error + " !important",
    borderColor: theme.customColors.applicationStatus.error + " !important",
  },
  primaryColor: {
    color: theme.palette.primary.main
  },
  table: {
    minWidth: '75rem',
  },
  tableActions: {
    alignItems: 'center',
    marginTop: '1.25rem',
    marginBottom: '1.25rem'
  },
  column: {
    fontWeight: '500',
    marginLeft: '.5rem',
    whiteSpace: 'break-spaces',
    maxHeight: 'none !important',
    lineHeight: '1.5rem',
  },
  cell: {
    fontWeight: '500',
    paddingLeft: '.5rem',
    width: '100%',
    whiteSpace: 'break-spaces',
    lineHeight: '1.5rem'
  },
  linkCell: {
    fontWeight: '500',
    paddingLeft: '.5rem',
    width: '100%',
    whiteSpace: 'break-spaces',
    fontSize: '1rem',
    color: theme.palette.primary.main
  },
  centeredCell: {
    fontWeight: '500',
    margin: 'auto',
    whiteSpace: 'nowrap',
    lineHeight: '1.5rem'
  },
  search: {
    borderRadius: '.5rem',
    height: '3rem'
  },
  searchInput: {
    height: '1.5rem',
    padding: '.75rem .25rem'
  },
  actionIcon: {
    color: theme.customColors.mediumGray,
    height: '2.75rem',
    width: '6rem',
    padding: '.1rem',
    display: 'initial',
  },
  notesIcon: {
    marginLeft: '1.2rem',
    paddingLeft: '.8rem',
    height: '2.5rem',
    width: '2.5rem'
  },
  chip: {
    borderRadius: '.5rem', 
    fontSize: '.875rem',
    height: '2rem',
    color: '#fff',
    width: "7rem",
    justifyContent: 'left',
    '& > .MuiChip-label': {
      textOverflow: 'clip',
    }
  },
  divider: {
    borderColor: theme.customColors.mediumGray,
    width: '1.5rem',
    margin: 'auto'
  },
  filters: {
    alignItems: 'center'
  },
  filter: {
    margin: '.25rem',
  },
  clearFilters: {
    margin: '.25rem',
    padding: '0 1rem',
    textTransform: 'none',
    fontSize: '.875rem',
    fontWeight: 500
  },
  greeting: {
    marginTop: '1rem',
    color: theme.palette.primary.main
  }
}));

export default function AgencyTable(){
  const classes = useStyles();
  const [openNotes, setOpenNotes] = useState(false);
  const [openUrgent, setOpenUrgent] = useState(false);
  const [openDuplicate, setOpenDuplicate] = useState(false);
  const [page, setPage] = useState(0);
  const { userInfo } = useContext(AuthContext);
  let userFullName= `${userInfo?.firstName} ${userInfo.lastName}`
  
  const agencyId = userInfo?.agencyId;
  const userPermissions = userInfo?.permissions ? userInfo.permissions : [];
  const {data: userOptions} = useAssignableAgencyUsers();

  const setError = useErrorViewer();
  const [isFetching, setIsFetching] = useState(false)

  const mutation = useMutation(searchPayload => {
    return searchAndFilterApplications(searchPayload);
  })

  //TODO future story: Agency view-only previous season filter
  const defaultSeason = seasonOptions[0].value //Defaulted to "LIHEAP_APP"
  const [applications, setApplications] = useState([]);
  const [selectedApplication, setSelectedApplication] = useState('');
  const [selectionModel, setSelectionModel] = useState('');
  const [searchValue, setSearchValue] = useState(window.sessionStorage.getItem('storedSearchValue') ? window.sessionStorage.getItem('storedSearchValue') : '');
  const [totalApps, setTotalApps] = useState(0);
  const [sortString, setSortString] = useState('initialSubmittedDate:asc:date');

  const [duplicateFilter, setDuplicateFilter] = useState(window.sessionStorage.getItem('storedDuplicateFilter') ? window.sessionStorage.getItem('storedDuplicateFilter').split(',') : []);
  const [urgentFilter, setUrgentFilter] = useState(window.sessionStorage.getItem('storedUrgentFilter') ? window.sessionStorage.getItem('storedUrgentFilter').split(',') : []);
  //default status filter to submitted, re-submitted
  const [statusFilter, setStatusFilter] = useState(window.sessionStorage.getItem('defaultFilterSettingsChaged') ? window.sessionStorage.getItem('storedStatusFilter') ? window.sessionStorage.getItem('storedStatusFilter').split(',') : [] : [statuses.SUBMITTED, statuses.RE_SUBMITTED]);  
  const [dateFilter, setDateFilter] = useState(window.sessionStorage.getItem('storedDateFilterStart') && window.sessionStorage.getItem('storedDateFilterEnd') ? [new Date(window.sessionStorage.getItem('storedDateFilterStart')), new Date(window.sessionStorage.getItem('storedDateFilterEnd'))] : [null, null]);
  const [countyFilter, setCountyFilter] = useState(window.sessionStorage.getItem('storedCountyFilter') ? window.sessionStorage.getItem('storedCountyFilter').split(',') : []);
  const [assigneeFilter, setAssigneeFilter] = useState(window.sessionStorage.getItem('storedAssigneeFilter') ? window.sessionStorage.getItem('storedAssigneeFilter').split(',') : []);
  const [seasonFilter, setSeasonFilter] = useState(window.sessionStorage.getItem('storedSeasonFilter') ? window.sessionStorage.getItem('storedSeasonFilter').split(',') : [defaultSeason]);
  const [clearAllTrigger, setClearAllTrigger] = useState(false);

  useEffect(() => {
    getFilteredApplications(searchValue);
    saveFilterStates();
    setClearAllTrigger(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duplicateFilter, urgentFilter, statusFilter, countyFilter, dateFilter, assigneeFilter, seasonFilter, searchValue, sortString, page]);

  const getFilteredApplications = () => {
    setIsFetching(true);
    let matchObject = null; let filterTerms = []; let dateObject = null;

    if(searchValue){
      matchObject = {'value': searchValue, 'fields' : ['firstName', 'lastName', 'ssn', 'serviceStreetAddress', 'serviceCountyOrParish']}
    }

    if(duplicateFilter.length > 0){
      let duplicateObject = {"field" : "duplicateInfo.status", "values" : duplicateFilter}
      filterTerms.push(duplicateObject)
    }
    if(urgentFilter.length > 0){
      let urgentObject = {"field" : "urgent", "values" : urgentFilter}
      filterTerms.push(urgentObject)
    }
    if(statusFilter.length > 0){
      let statusObject = {'field': 'status', 'values': statusFilter}
      filterTerms.push(statusObject)
    }
    if(countyFilter.length > 0){
      let countyObject = {"field" : "serviceCountyOrParish", "values" : countyFilter}
      filterTerms.push(countyObject)
    }
    if(assigneeFilter.length > 0){
      let assigneeObject = {"field" : "agencyAssigneeInfo.assigneeUserId", "values" : assigneeFilter}
      filterTerms.push(assigneeObject)
    }
    if(seasonFilter.length > 0){
      let seasonObject = {"field" : "recordType", "values" : seasonFilter}
      filterTerms.push(seasonObject)
    }

    let dateFilterStart = dateFilter[0] ? new Date(dateFilter[0]) : null
    let dateFilterEnd = dateFilter[1] ? new Date(dateFilter[1]) : null

    let dateFilterEndPlusDay = dateFilterEnd ? new Date(dateFilterEnd.getTime()) : null;
    if(dateFilterEndPlusDay){
      dateFilterEndPlusDay.setDate(dateFilterEndPlusDay.getDate() + 1);
    }

    if(dateFilterStart && dateFilterEndPlusDay){
      dateObject = {"field" : "initialSubmittedDate", "gte" : dateFilterStart, "lte" : dateFilterEndPlusDay}
    }

    let searchAndFilterPayload = {
      "match": matchObject,
      "filterTerms": filterTerms,
      "dateFilter": dateObject,
      "sort": sortString,
      "pageSize" : PAGE_SIZE,
      "pageStart" : page * PAGE_SIZE,
    };

    mutation.mutate(searchAndFilterPayload, {
      onSuccess: (response) => {
        if(response){
          setIsFetching(false);
          let total = response?.data?.hits?.total?.value;
          setTotalApps(total);
          let hits = response?.data?.hits?.hits
          let data = hits ? hits.map((hit) => hit._source) : []
          setApplications(data)
        }
        else{
          retryFilter(
              () => {
                getFilteredApplications();
              }, FILTER_CALL_DELAY);
        }
      },
      onError: () => {
        setIsFetching(false);
        setError('Error fetching applications.')
      }
    })
  }

  const renderReviewInfo = (pdfFileData) => {
    return (
      <Grid container>
        <Grid item container xs={8} justifyContent="flex-start" className={classes.pdfActions}>
          <SaveMergedPDF appData={pdfFileData} disabled={isFetching}/> 
        </Grid>
      </Grid>
    )
  }
  
  const getColumns = () => {
    return [
      {
        field: 'duplicateInfo', flex: .9, valueGetter: getDuplicateStatus,
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Duplicate</Typography>),
        renderCell: (params) => (<IconButton id={`${params.id}-duplicate`} aria-label={`${params.id} Duplicate`} disabled={isFetching || params.value === 'no'} className={`${classes.cell} ${classes.actionIcon}`} onClick={() => handleOpenModal(params, 'duplicate')} size="large">{getDuplicateIcon(params.value)}</IconButton>)
      },
      {
        field: 'urgent', flex: .7,
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Urgent</Typography>),
        renderCell: (params) => (<IconButton id={`${params.id}-urgent`} aria-label={`${params.id} Urgent`} disabled={isFetching || params.value === 'no'} className={`${classes.cell} ${classes.actionIcon}`} onClick={() => handleOpenModal(params, 'urgency')} size="large">{getUrgentIcon(params.value)}</IconButton>)
      },
      {
        field: 'status', flex: 1,
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Status</Typography>),
        renderCell: (params) => (<Typography variant='subtitle1' className={classes.cell}>{getStatusChip(params.value, classes, true)}</Typography>)
      },
      {
        field: 'applicantName', valueGetter: getFullName, flex: 1.2, sortComparator: getSortComparatorFullName,
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Client</Typography>),
        renderCell: (params) => (isFetching ? <Link to='/' className={classes.linkCell} onClick={ (event) => event.preventDefault() }>{params.value}</Link> 
        :
        ((params.row.status !== statuses.ON_PAPER && (userPermissions.includes(agencyPermissions.REVIEW_APPLICATION_EDIT) || userPermissions.includes(agencyPermissions.REVIEW_APPLICATION_VIEW))) && (params.row.recordType === defaultSeason)
        ?
        <Link to={`/agency/applicationReview/${params.row.agencyState}/${params.row.id}`} className={classes.linkCell}>{params.value}</Link>
        :
        (params.row.recordType !== defaultSeason)
        ?
        renderReviewInfo(params.row)
        :
        <Typography variant='subtitle1' className={classes.cell}>{params.value}</Typography>
        ))
      },
      {
        field: 'ssn', flex: .5,
        renderHeader: () => (<Typography variant='h6' className={classes.column}>SSN</Typography>),
        renderCell: (params) => (<Typography variant='subtitle1' className={classes.cell}>{params.value ? params.value.substring(5, 10) : ''}</Typography>)
      },
      {
        field: 'initialSubmittedDate', flex: 1,
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Submitted</Typography>),
        renderCell: (params) => (<Typography variant='subtitle1' className={classes.cell}>{getFormattedDate(params.value)}</Typography>)
      },
      {
        field: 'address', valueGetter: getAddress, flex: 1,
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Address</Typography>),
        renderCell: (params) => (<Typography variant='subtitle1' className={classes.cell}>{params.value}</Typography>)
      },
      {
        field: 'serviceCountyOrParish', flex: .8,
        renderHeader: () => (<Typography variant='h6' className={classes.column}>County</Typography>),
        renderCell: (params) => (<Typography variant='subtitle1' className={classes.cell}>{params.value}</Typography>)
      },
      {
        field: 'agencyAssigneeInfo', flex: 1.2,
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Assignee</Typography>),
        renderCell: (params) => (
          <AssignAgencyUser
            applicationId={params.row.id}
            canEdit={getCanEditAssignee(params.row)}
            options={userOptions ? userOptions : [{value: 'Unassigned', label: 'Unassigned'}]}
            value={params.row.agencyAssigneeInfo?.assigneeUserId}
            valueLabel={`${params.row.agencyAssigneeInfo?.assigneeLastName ? params.row.agencyAssigneeInfo?.assigneeLastName : ''}${params.row.agencyAssigneeInfo?.assigneeFirstName ? ', ' + params.row.agencyAssigneeInfo?.assigneeFirstName : ''}`}
            onClose={handleCloseAssign}
          />
        )
      },
      {
        field: 'pledgeAmount', valueGetter: getPledgeAmount, flex: 0.7, sortable: false,
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Amount</Typography>),
        renderCell: (params) => (<Typography variant='subtitle1' className={classes.centeredCell}>{getFormattedPledgeAmount(params.value)}</Typography>)
      },
      {
        field: 'notes', flex: 0.6, sortable: false,
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Notes</Typography>),
        renderCell: (params) => (<IconButton id={`${params.id}-notes`} aria-label={`${params.id} Notes`}disabled={isFetching} className={`${classes.cell} ${classes.notesIcon}`} onClick={() => handleOpenModal(params, 'notes')} size="large">{getNotesIcon(params)}</IconButton>)
      }
    ];
  }

  /**
  * Store the user's selected search and filter values to have table in the same state as they go from page to page.
  * Cleared on logout.
  */
  const saveFilterStates = () => {
    window.sessionStorage.setItem('storedSearchValue', searchValue);
    window.sessionStorage.setItem('storedDuplicateFilter', duplicateFilter);
    window.sessionStorage.setItem('storedUrgentFilter', urgentFilter);
    window.sessionStorage.setItem('storedStatusFilter', statusFilter);
    window.sessionStorage.setItem('storedCountyFilter', countyFilter);
    window.sessionStorage.setItem('storedAssigneeFilter', assigneeFilter);
    window.sessionStorage.setItem('storedSeasonFilter', seasonFilter);

    if(dateFilter[0] === null && dateFilter[1] === null){
      window.sessionStorage.setItem('storedDateFilterStart', '');
      window.sessionStorage.setItem('storedDateFilterEnd', '');
    }
    else{
      window.sessionStorage.setItem('storedDateFilterStart', dateFilter[0]);
      window.sessionStorage.setItem('storedDateFilterEnd', dateFilter[1]);
    }
  }

  const getCanEditAssignee = (appData) => {
    return userPermissions.includes(agencyPermissions.CLIENT_INTAKE_DASHBOARD_EDIT)
            && appData?.status !== statuses.APPROVED 
            && appData?.status !== statuses.DENIED 
            && appData?.status !== statuses.ON_PAPER 
  }

  const getFormattedPledgeAmount = (val) => {
    if(val)
      return "$ {0}"
        .replace("{0}", val);
    else
      return <Divider className={classes.divider}/>;
  }

  const getNotesIcon = (params) => {
    if(params.row.notesAndHistory){
      return <InsertDriveFile className={classes.primaryColor} />
    }else{
      return <DescriptionOutlined style={{color: "#74777F"}}/>
    }
  }
  
  const handleOpenModal = (params, type) => {
    if(params.id !== selectedApplication.id){
      handleRowSelection(params)
    }
    switch (type) {
      case "duplicate":
        setOpenDuplicate(true);
        break
      case "urgency":
        setOpenUrgent(true);
        break
      case "notes":
        setOpenNotes(true);
        break
      default:
        break
    }
  }

  const handleCloseModal = (type) => {
    switch (type) {
      case "duplicate":
        setOpenDuplicate(false);
        break
      case "urgency":
        setOpenUrgent(false);
        break
      case "notes":
        setOpenNotes(false);
        break
      default:
        break
    }

    setSelectedApplication('');
    setSelectionModel('');
  };

  const handleModalChange = (type) => {
    switch (type) {
      case "duplicate":
        setOpenDuplicate(false);
        break
      case "urgency":
        setOpenUrgent(false);
        break
      case "notes":
        setOpenNotes(false);
        break
      default:
        break
    }

    setSelectedApplication('');
    setSelectionModel('');
    getFilteredApplications();
  };

  const handleCloseAssign = () => {
    setSelectedApplication('');
    setSelectionModel('');
    getFilteredApplications();
  };

  const handleFilterChange = (label, value) => {
    switch (label) {
      case "Season":
        setSeasonFilter(value)
        window.sessionStorage.setItem('defaultSeasonFilterSettingChanged', true)
        break
      case "Submitted":
        setDateFilter(value)
        break
      case "Duplicate":
        setDuplicateFilter(value)
        break
      case "Urgent":
        setUrgentFilter(value)
        break
      case "Status":
        setStatusFilter(value)
        window.sessionStorage.setItem('defaultFilterSettingsChaged', true)
        break
      case "County":
        setCountyFilter(value)
        break
      case "Assignee":
        setAssigneeFilter(value)
        break
      default:
        break
    }
  };

  const handleRowSelection = (row) => {
    if(selectedApplication && selectedApplication.id === row.id){
      setSelectionModel('');
      setSelectedApplication('');
    }
    else{
      setSelectionModel(row.id);
      const application = applications?.filter((app) => {return app.id === row.id});
      setSelectedApplication(application ? application[0]: '');
    }
  }

  const handlePageChange = (newPage) => {
    setPage(newPage);
  }

  const handleSortChange = (model) => {
    switch (model[0]?.field) {
      case "urgent":
      case "status":
      case "serviceCountyOrParish":
      case "ssn":
        setSortString(`${model[0]?.field}:${model[0]?.sort}:`)
        break
      case "initialSubmittedDate":
        setSortString(`${model[0]?.field}:${model[0]?.sort}:date`)
        break
      case "applicantName":
        setSortString(`lastName:${model[0]?.sort}:`)
        break
      case "address":
        setSortString(`serviceStreetAddress:${model[0]?.sort}:`)
        break
      case "pledgeAmount":
        setSortString(`statusInfo.pledgeAmount:${model[0]?.sort}:`)
        break
      case "agencyAssigneeInfo":
        setSortString(`agencyAssigneeInfo.assigneeLastName:${model[0]?.sort}:`)
        break
      case "duplicateInfo":
        setSortString(`duplicateInfo.status:${model[0]?.sort}:`)
        break
      default:
        break
    }
  }

  const resetFilters = () => {
    setDuplicateFilter([]);
    setUrgentFilter([]); 
    setStatusFilter([]); 
    setDateFilter([null, null]); 
    setCountyFilter([]); 
    setAssigneeFilter([]);
    setClearAllTrigger(true);
    setSeasonFilter([defaultSeason]);
    window.sessionStorage.setItem('defaultFilterSettingsChaged', true)
    window.sessionStorage.setItem('defaultSeasonFilterSettingChanged', false)
  }

  const throttleSearchChange = (event) => {
    appSearch(
      () => {
        setSearchValue(event.target.value);
      }, SEARCH_DELAY);
  }

  return(
    <div className={classes.table}>
      <Grid item xs={12} className={classes.greeting}>
        <Typography variant="h6">Welcome, {userFullName ? userFullName : 'User'}</Typography>
      </Grid>
      <Grid item xs={12} >
        <Typography variant="h5">Dashboard</Typography>
      </Grid>
      <Grid className={classes.tableActions} container item xs={12} justifyContent="flex-start">
        <Grid item xs={3}>
          <TextField
            id="outlined-search"
            fullWidth
            className={classes.search}
            defaultValue={searchValue}
            placeholder="Search"
            onChange={throttleSearchChange}
            InputProps={{
              startAdornment: (<InputAdornment position="start"><Search /></InputAdornment>), 
              classes: {input: classes.searchInput}
            }}
          />
        </Grid>
        <Grid item xs={9} container justifyContent="flex-end" className={classes.filters}>
          <Grid className={classes.filter} item >
          <TableFilter initialValue={[defaultSeason]} label="Season" options={seasonOptions} handleFilterChange={handleFilterChange} clearAllTrigger={clearAllTrigger}/>
          </Grid>
          <Grid className={classes.filter} item >
            <TableFilter initialValue={duplicateFilter} label="Duplicate" options={urgentOptions} handleFilterChange={handleFilterChange} clearAllTrigger={clearAllTrigger}/>
          </Grid>
          <Grid className={classes.filter} item >
            <TableFilter initialValue={urgentFilter} label="Urgent" options={urgentOptions} handleFilterChange={handleFilterChange} clearAllTrigger={clearAllTrigger}/>
          </Grid>
          <Grid className={classes.filter} item >
            <TableFilter initialValue={statusFilter} label="Status" options={statusOptions} handleFilterChange={handleFilterChange} clearAllTrigger={clearAllTrigger}/>
          </Grid>
          <Grid className={classes.filter} item >
            <TableFilter initialValue={dateFilter} label="Submitted" options={yesNoOptions} handleFilterChange={handleFilterChange} clearAllTrigger={clearAllTrigger}/>
          </Grid>
          <Grid className={classes.filter} item >
            <TableFilter initialValue={countyFilter} label="County" options={getCountyOptions(agencyId)} handleFilterChange={handleFilterChange} clearAllTrigger={clearAllTrigger}/>
          </Grid>
          <Grid className={classes.filter} item >
            <TableFilter initialValue={assigneeFilter} label="Assignee" options={userOptions ? userOptions : []} handleFilterChange={handleFilterChange} clearAllTrigger={clearAllTrigger}/>
          </Grid>
          <Grid item >
            <Button onClick={resetFilters} variant="outlined" className={classes.clearFilters}>Clear Filters</Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <StyledDataGrid
          className={classes.dataGrid}
          rows={applications ? applications : []}
          rowCount={totalApps}
          columns={getColumns()}
          hideFooterSelectedRowCount
          disableSelectionOnClick
          disableColumnMenu
          paginationMode="server"
          pagination
          onPageChange={handlePageChange}
          pageSize={PAGE_SIZE}
          loading={isFetching}
          selectionModel={selectionModel}
          components={{
            ColumnSortedDescendingIcon: SortedDescendingIcon,
            ColumnSortedAscendingIcon: SortedAscendingIcon,
          }}
          onSortModelChange={handleSortChange}
          rowsPerPageOptions={[PAGE_SIZE]}
          sortingOrder={['asc', 'desc']}
          sortingMode="server"
        />
      </Grid>

      <NotesAndHistory application={selectedApplication} open={openNotes} handleClose={handleCloseModal} handleChange={handleModalChange} canEdit={userPermissions.includes(agencyPermissions.CLIENT_INTAKE_DASHBOARD_EDIT)}/>
      
      <UrgencyInfo application={selectedApplication} open={openUrgent} handleClose={handleCloseModal} handleChange={handleModalChange}/>
      
      <DuplicateInfo application={selectedApplication} open={openDuplicate} handleClose={handleCloseModal} handleChange={handleModalChange}/>
      
    </div>
  );
}
