import { useQuery } from "react-query";
import { getStateConfig } from "../api/ConfigAPI";

export default function useStateConfigData(season) {
  return useQuery(
    [`stateConfigData-${season}`],
    async () => {
      const { data } = await getStateConfig(season).then((response) => {
        if (response) {
          let data = [];
          data = response?.data;
          return { data: data };
        }
      });
      return data;
    },
    { enabled: true }
  );
}
