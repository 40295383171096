export const relationToApplicantOptions = [
  {
    value: 'child',
    label: 'Child'
  },
  {
    value: 'cousin',
    label: 'Cousin'
  },
  {
    value: 'grandParent',
    label: 'Grandparent'
  },
  {
    value: 'parent',
    label: 'Parent'
  },
  {
    value: 'sibling',
    label: 'Sibling'
  },
  {
    value: 'spouse',
    label: 'Spouse'
  },
  {
    value: 'other',
    label: 'Other'
  }
]