import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import useSaveChanges from './useSaveChanges';
import useTriggerValidations from './useTriggerValidations';

export default function useLiheapForm(save, defaultData, validate, validationSchema, shouldUnregisterOption = false, validateOnEditValidationSchema = null) {

  const methods = useForm({
    mode: "onChange", //clear errors if valid on change after validation is triggered
    defaultValues: defaultData,
    shouldUnregister: shouldUnregisterOption,
    resolver: validate ? yupResolver(validationSchema) : validateOnEditValidationSchema ? yupResolver(validateOnEditValidationSchema) : ''
  })

  const { trigger, getValues, formState: {dirtyFields} } = methods;
  
  useSaveChanges(dirtyFields, save, getValues);
  
  useTriggerValidations(trigger, validate);

  return methods;
}
