import { TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'
import InputMask from "react-input-mask";
import { getErrorMessage } from '../../utils/utilFunctions'

const useStyles = makeStyles(theme => ({
  textField: {
    "& > .MuiOutlinedInput-root": { 
      backgroundColor: '#fff',
      margin:0
    }
  }
}));

export default function FormTextField( { name, register, errors, labelPlacement, label, mask, maskPlaceholder=null, ...rest } ) {
  const classes = useStyles();
  return (
    <>
      {(labelPlacement && labelPlacement === 'top') &&
        <Typography variant="subtitle1" style={{fontWeight: "bold", marginTop: "10px"}}>
          <label htmlFor={name}>
            {label}
          </label>
        </Typography>
      }
      {mask &&
        <InputMask mask={mask} maskPlaceholder={maskPlaceholder}>      
          <TextField
            className={classes.textField}
            fullWidth
            autoComplete="no"
            variant="outlined"
            margin="normal"
            inputRef={register}
            id={name}
            name={name}
            aria-label={label}
            label={(labelPlacement && labelPlacement === 'top') ? "" : label}
            error={Boolean(getErrorMessage(errors, name))}
            helperText={getErrorMessage(errors, name)}
            {...rest}
          />
        </InputMask>   
      }
      {!mask &&
        <TextField
          className={classes.textField}
          fullWidth
          autoComplete="no"
          variant="outlined"
          margin="normal"
          inputRef={register}
          id={name}
          name={name}
          aria-label={label}
          label={(labelPlacement && labelPlacement === 'top') ? "" : label}
          error={Boolean(getErrorMessage(errors, name))}
          helperText={getErrorMessage(errors, name)}
          {...rest}
        />
      }
    </> 
  )
}
