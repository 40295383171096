import { Button, CircularProgress, IconButton, InputAdornment, FormHelperText, Grid, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Redirect } from 'react-router-dom';
import { userResetPassword, userConfirmReset } from '../../api/UserAPI';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import makeStyles from '@mui/styles/makeStyles';
import Email from '@mui/icons-material/Email';
import Avatar from '@mui/material/Avatar';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { Visibility, VisibilityOff, Check, Clear } from '@mui/icons-material';


const useStyles = makeStyles((theme) => ({
    successInput: {
        color: theme.palette.success.main
    },
    errorInput: {
        color: theme.palette.error.main
    },
    grayInput: {
        color: theme.customColors.lightMedGray
    },
    congtxtinput: {
        textAlign: "center",
        letterSpacing: "0.7em",
        fontSize: 20,
        maxLength: 6,
    },
    confcodemuifield: {
        textAlign: "center",

        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }

    },
    paper: {
        marginTop: theme.spacing(5),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: "center",
        justifyContent: 'center'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.dark,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    passwordReqs: {
        alignItems: 'flex-start',
        marginBottom: '1rem'
    },
    reqs: {
        display:'flex'
      },
      icon: {
        height: '1rem',
        marginTop: '.2rem'
      },
}));

export default function Reset() {
    const [formData, setFormData] = useState({ email: "", password: "", confirmPassword: "", confirmationCode: "" });
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [userName, setUserName] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [resetLoading, setResetLoading] = useState(false);
    const [userError, setUserError] = useState(false);
    const [confirmError, setConfirmError] = useState(false);
    const [navToLogin, setNavToLogin] = useState(false);
    const classes = useStyles();
    const [showPASS, setShowPASS] = useState(false);

    const [eightCharError, setEightCharError] = useState(false);
    const [uppercaseError, setUppercaseError] = useState(false);
    const [lowercaseError, setLowercaseError] = useState(false);
    const [numberError, setNumberError] = useState(false);
    const [specialCharError, setSpecialCharError] = useState(false);

    const handleMouseDownPASS = (event) => {
        event.preventDefault();
    };

    const getInputClasses = (val, isErr) => {
        return `${classes.reqs} ${val ? isErr ? classes.successInput : classes.errorInput : classes.grayInput}`
    }

    const validateSendReset = () => {
        return formData.email
            && validateEmailFormat(formData.email)
    }

    const validateReset = () => {
        return validatePassword(formData.password)
            // && validateConfirmPassword()
            && validateConfirmCode(formData.confirmationCode);
    }

    const validatePassword = (password) => {
        const regex = /^.*(?=.{8,50})(?=.*[a-zA-Z])(?=.*\d).*$/
        return regex.test(password);
    }

    // const validateConfirmPassword = () => {
    //     return formData.password
    //         && (formData.password === formData.confirmPassword);
    // }

    const validateConfirmCode = (confirmCode) => {
        const regex = /^\d{6}$/

        return regex.test(confirmCode);
    }

    //https://www.w3resource.com/javascript/form/email-validation.php
    const validateEmailFormat = (email) => {
        const regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:|\\)*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:|\\)+)\])/;
        return regex.test(email)
    }
    const handleChange = (event) => {
        let key = event.target.name;
        let value = event.target.value;
        if(key === 'password'){
            let regex1 = /^.{8,}$/;
            setEightCharError(regex1.test(value));
            
            let regex2 = /^.*(?=.*[A-Z]).*$/;
            setUppercaseError(regex2.test(value));
            
            let regex3 = /^.*(?=.*\d).*$/;
            setNumberError(regex3.test(value));
            
            let regex4 = /^.*(?=.*[!$@%]).*$/;
            setSpecialCharError(regex4.test(value));

            let regex5 = /^.*(?=.*[a-z]).*$/;
            setLowercaseError(regex5.test(value));
        }
        setFormData({
            ...formData,
            [key]: value
        });
    };

    const handleSubmit = () => {
        setConfirmLoading(true);
        userResetPassword(formData["email"]).then(() => {
            setConfirmLoading(false);
            setShowConfirmation(true);
            setUserName(formData["email"]);
        }).catch(() => {
            setConfirmLoading(false);
            setUserError(true);
            setFormData({ "email": "" });
        })
    }

    const handleReset = () => {
        setResetLoading(true);
        userConfirmReset(userName, formData["confirmationCode"], formData["password"]).then(() => {
            setResetLoading(false);
            setNavToLogin(true);
        }).catch(() => {
            setResetLoading(false);
            setConfirmError(true);
            setFormData({ "confirmationCode": "", "password": "", "confirmPassword": "" })
        });
    }

    if (navToLogin)
        return (
            <Redirect to="/login"></Redirect>
        )
    if (showConfirmation)
        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <VpnKeyIcon />
                    </Avatar>
                    <Typography variant="body2" gutterBottom>
                        Let's reset your password.
                    </Typography>
                    <form className={classes.form} >
                        <Grid container>
                            <Grid item xs={12} >
                                <FormHelperText id="cc-helper-text">
                                    A six-digit code was sent to the mobile phone number on record for your account
                                </FormHelperText>
                                <TextField
                                    className={classes.confcodemuifield}
                                    //fullWidth
                                    id="confirmCode"
                                    variant="outlined"
                                    margin="normal"
                                    value={formData.confirmationCode}
                                    name="confirmationCode"
                                    label="Six Digit Code"
                                    onChange={handleChange}
                                    placeholder="000000"
                                    type="number"
                                    //helperText={`${formData.confirmCode.length}/6`}
                                    error={confirmError}
                                    inputProps={{
                                        inputMode: 'tel',
                                        className: classes.congtxtinput,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="New Password"
                                    type={showPASS ? 'text' : 'password'}
                                    id="password"
                                    onChange={handleChange}
                                    value={formData.password}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setShowPASS(!showPASS)}
                                                    onMouseDown={handleMouseDownPASS}
                                                    edge="end"
                                                    size="large">
                                                    {showPASS ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                    }}
                                />
                            </Grid>
                            <Grid item container direction="column" className={classes.passwordReqs}>
                           <Grid item className={getInputClasses(formData.password, eightCharError)}>
                                {formData.password && eightCharError && <Check className={classes.icon}/>}
                                {formData.password && !eightCharError && <Clear className={classes.icon}/>}
                                <Typography variant="subtitle2">
                                At least 8 characters
                                </Typography>
                            </Grid>
                            <Grid item className={getInputClasses(formData.password, lowercaseError)}>
                                {formData.password && lowercaseError && <Check className={classes.icon}/>}
                                {formData.password && !lowercaseError && <Clear className={classes.icon}/>}
                                <Typography variant="subtitle2">
                                    At least one lowercase letter (a-z)
                                </Typography>
                            </Grid>
                            <Grid item className={getInputClasses(formData.password, uppercaseError)}>
                                {formData.password && uppercaseError && <Check className={classes.icon}/>}
                                {formData.password && !uppercaseError && <Clear className={classes.icon}/>}
                                <Typography variant="subtitle2">
                                    At least one uppercase letter (A-Z)
                                </Typography>
                            </Grid>
                            <Grid item className={getInputClasses(formData.password, numberError)}>
                                {formData.password && numberError && <Check className={classes.icon}/>}
                                {formData.password && !numberError && <Clear className={classes.icon}/>}
                                <Typography variant="subtitle2">
                                    At least one number (0-9)
                                </Typography>
                            </Grid>
                            <Grid item className={getInputClasses(formData.password, specialCharError)}>
                                {formData.password && specialCharError && <Check className={classes.icon}/>}
                                {formData.password && !specialCharError && <Clear className={classes.icon}/>}
                                <Typography variant="subtitle2">
                                    At least one special character (!$@%)
                                </Typography>
                            </Grid>
                        </Grid>
                            {resetLoading ? <CircularProgress color="primary" size={36} /> :
                               
                                <Button
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    onClick={handleReset}
                                    disabled={!validateReset()}
                                    >
                                   Reset my password
                                </Button>
                               
                            }
                        </Grid>
                    </form>
                </div>
            </Container>
        );
    else
        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <Email />
                    </Avatar>
                    <Typography component="h2" >
                        Enter your E-mail address
                    </Typography>
                    <form className={classes.form} noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="E-mail"
                            name="email"

                            autoFocus
                            onChange={handleChange}
                            error={userError}
                            value={formData.email}
                        />
                        {confirmLoading ?
                            <CircularProgress color="primary" size={36} /> :
                            <Button
                                id="signin"
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={handleSubmit}
                                disabled={!validateSendReset()}
                            >
                                Send Reset Code
                            </Button>
                        }
                    </form>
                </div>
            </Container>
        )
}
