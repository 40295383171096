export const features = {
    "CreateApplication" : {
        "showError" : true,
        "errorTitle" : "We are no longer taking online applications",        
        "errorMessage" : ""
    },
    "Register" : {
        "showError" : true,
        "errorTitle" : "We are no longer taking online applications",
        "errorMessage" : ""
    },
    "Login": {
        "showError" : true,
        "errorTitle" : "We are no longer taking online applications",
        "errorMessage" : ""
    },
    "CreateInvitation": {
        "showError" : true,
        "errorTitle" : "Feature Disabled",
        "errorMessage" : "Invitations are disabled at this time"
    },
    "UpdateIn ProgressApplication": {
        "showError" : true,
        "errorTitle" : "We are no longer taking online applications",
        "errorMessage" : ""
    },
    "UpdateReturnedApplication": {
        "showError" : true,
        "errorTitle" : "We are no longer taking online applications",
        "errorMessage" : ""
    },
    "In ProgressSubmit": {
        "showError" : true,
        "errorTitle" : "We are no longer taking online applications",
        "errorMessage" : ""
    },
    "ReturnedSubmit": {
        "showError" : true,
        "errorTitle" : "We are no longer taking online applications",
        "errorMessage" : ""
    },
    "OtherElectricProviders": {
        "showError" : false,
        "errorTitle" : "",
        "errorMessage" : ""
    },
    "FINACreatePledge": {
        "showError" : false,
        "errorTitle" : "",
        "errorMessage" : ""
    }
}

export const isFeatureDisabled = (clientConfig, agencyId, ft) => {
    let isDisabled = false;
    if (clientConfig){
        if (clientConfig["DisabledFeatures"]){
            if (clientConfig["DisabledFeatures"][agencyId] &&
                (ft in clientConfig["DisabledFeatures"][agencyId])){
                //agency specific check
                isDisabled = clientConfig["DisabledFeatures"][agencyId][ft];
            }else{
                //global check
                isDisabled = clientConfig["DisabledFeatures"][ft];
            }
        }            
    }
    return isDisabled
  }
