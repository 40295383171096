import { Grid, IconButton, Typography, Toolbar, AppBar, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router';
import ReviewSidePanel from '../components/application-review/ReviewSidePanel';
import { ArrowBackRounded, Inventory2Outlined, StickyNote2Outlined, PendingActionsOutlined } from '@mui/icons-material'
import { getApplicationByIdForAgent } from '../../../api/AgencyAPI';
import AssignAgencyUser from '../components/AssignAgencyUser';
import usePermissions from '../../../hooks/usePermissions';
import { agencyPermissions } from '../../../constants/agencyPermissions';
import useAssignableAgencyUsers from '../../../hooks/useAssignableAgencyUsers';
import { statuses } from '../../../constants/appStatuses';
import { useQuery, useQueryClient } from 'react-query';
import ReviewMain from '../components/application-review/ReviewMain';
import { getFilesForApplication } from '../../../api/FileAPI';
import { AuthContext } from '../../../context/authContext';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    marginLeft: '4rem',
    display: 'flex',
  },
  toolbar: {
    color: '#000',
    background: 'linear-gradient(0deg, rgba(124, 172, 248, 0.08), rgba(124, 172, 248, 0.08)), linear-gradient(0deg, rgba(117, 117, 117, 0.02), rgba(117, 117, 117, 0.02)), #FFFFFF',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
    padding: '0 2rem',
    marginLeft: '4rem',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '1rem'
  },
  sticky: {
    position: 'sticky',
    top: 0,
    zIndex: 99,
  },
  pdf: {
    border: '2px solid',
    borderColor: theme.customColors.lightGrey,
    overflow: 'scroll',
    margin: '1rem 2rem'
  },
  pdfHeader:{
    display: 'flex',
    height: '2rem',
    color: theme.customColors.mediumGray,
    marginLeft: '5rem'
  },
  main: {
    margin: '1rem 0 0 2rem'
  },
  sidePanel: {
    paddingRight: '1rem',
    overflow: 'scroll',
    height: '100vh',
    position: 'sticky',
    top: 60,
    zIndex: 9,
  },
  spinner:{
    left: '50%;',
    position: "absolute", 
    textAlign: "center",
    top: '50%'
  },
  actions: {
    display: "flex",
    paddingRight: '5rem',
    marginLeft: 'auto'
  },
  assignee: {
    paddingRight: '2rem'
  },
  tabSection: {
    marginLeft: '2rem',
    display: 'flex'
  },
  tab: {
    margin: '0 .5rem',
    display: 'initial',
  },
  selectedTab: {
    color: "#3576CB",
    margin: '0 .5rem',
    display: 'initial',
  },
  tabLabel: {
    fontSize: '.75rem',
  },
  returned: {
    padding: '2rem',
    display: 'block',
    textAlign: 'center'
  }
}));

export default function ApplicationReview() {
  const { appId } = useParams();
  const queryClient = useQueryClient();

  const { userInfo } = useContext(AuthContext);
  const stateConfig = userInfo?.stateInfo;

  const { isLoading, isFetching, data } = useQuery(['applicationByIdForAgent', { id: appId }], async () => {
    const { data } = await getApplicationByIdForAgent(appId);
    return data;
  }, {enabled: !!appId})  

  const { isLoading:fileDataIsLoading, data:fileData } = useQuery(['documentsById', { id: appId}], async () => {
    const { data } = await getFilesForApplication(appId);
    return data;
  }, {enabled: !!appId})

  const userPermissions = usePermissions();
  const {data: userOptions} = useAssignableAgencyUsers();

  const history = useHistory();
  const classes = useStyles();

  const [selectedTab, setSelectedTab] = useState();

  const handleSidePanelSelect = (tab) => {
    setSelectedTab((prevState) => {
      if(prevState === tab){
        return ''
      }
      else{
        return tab
      }
    })
  }

  const getCanEditAssignee = (appData) => {
    return userPermissions.includes(agencyPermissions.REVIEW_APPLICATION_EDIT)
            && appData?.status !== statuses.APPROVED 
            && appData?.status !== statuses.DENIED 
            && appData?.status !== statuses.ON_PAPER 
  }

  const onUpdate = () => {
      queryClient.invalidateQueries(['applicationByIdForAgent', { id: appId }]);
  }

  return (
    <>
    <Grid className={classes.sticky}>
      <AppBar className={classes.toolbar} position="static">
        <Toolbar variant="dense">
          <Grid item className={classes.header}>
            <IconButton onClick={() => history.push(`/agency/home`)} edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
              <ArrowBackRounded/>
            </IconButton>
            <Typography variant="h6" color="inherit" component="div">
              {data?.firstName} {data?.lastName}
            </Typography>
          </Grid>
          <Grid item className={classes.actions}>
            <AssignAgencyUser
              label="Agent assigned:"
              showLabel
              applicationId={data?.id}
              canEdit={getCanEditAssignee(data)}
              options={userOptions ? userOptions : [{value: 'Unassigned', label: 'Unassigned'}]}
              value={data?.agencyAssigneeInfo?.assigneeUserId}
              valueLabel={`${data?.agencyAssigneeInfo?.assigneeLastName ? data?.agencyAssigneeInfo?.assigneeLastName : ''}${data?.agencyAssigneeInfo?.assigneeFirstName? ', ' + data?.agencyAssigneeInfo?.assigneeFirstName : ''}`}
            />
            <Grid className={classes.tabSection}>
            <IconButton className={selectedTab === 'history' ? classes.selectedTab : classes.tab} onClick={() => handleSidePanelSelect('history')}>
              <Inventory2Outlined/>
              <Typography className={classes.tabLabel}>
                History
              </Typography>
            </IconButton>
            <IconButton className={selectedTab === 'notes' ? classes.selectedTab : classes.tab} onClick={() => handleSidePanelSelect('notes')}>
              <StickyNote2Outlined/>
              <Typography className={classes.tabLabel}>
                Notes
              </Typography>
            </IconButton>
            <IconButton className={selectedTab === 'actions' ? classes.selectedTab : classes.tab} onClick={() => handleSidePanelSelect('actions')}>
              <PendingActionsOutlined/>
              <Typography className={classes.tabLabel}>
                Actions
              </Typography>
            </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Grid>

    <Grid container item xs={12} className={classes.mainContainer}>
      {(isLoading || fileDataIsLoading) ?
        <CircularProgress className={classes.spinner}/> 
        :
        <>
        <Grid item xs={selectedTab ? 6 : 12} className={classes.main}>
          {data?.status === statuses.RETURNED ?
          <Grid container className={classes.returned}>
            <Typography variant='h4'>Application in Returned Status for {data?.firstName} {data?.lastName}</Typography>
            <Typography variant='h6'>This application was returned to the client. While you cannot view application details at this time, you can still take action on it.</Typography>
          </Grid>
          :
          <>
          {data?.status === statuses.ON_PAPER ?
            <Grid container className={classes.returned}>
              <Typography variant='h4'>Application Converted to Paper</Typography>
              <Typography variant='h6'>This application was completed in person with the client.</Typography>
            </Grid>
            :
          <ReviewMain application={data} fileData={fileData} stateConfig={stateConfig}/>
          }
          </>
        }
        </Grid>
        { selectedTab &&
        <Grid className={classes.sidePanel} item xs={5} justifyContent='flex-end'>
          <ReviewSidePanel application={data} stateConfig={stateConfig} selectedTab={selectedTab} setTab={setSelectedTab} onUpdate={onUpdate} loading={isLoading || isFetching}/>
        </Grid>
        }
        </>
      }
    </Grid>   
    </>
  )
}
