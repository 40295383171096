import React, { useState, useEffect, useContext } from 'react'
import { Grid, TextField, InputAdornment, Typography, IconButton, Divider, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Search } from '@mui/icons-material';
import { useMutation } from 'react-query';
import { searchAndFilterApplications } from '../../../api/AgencyAPI';
import { statuses } from '../../../constants/appStatuses';
import TableFilter from '../../agency/components/TableFilter';
import { yesNoOptions } from '../../../constants/radios/yesNoOptions';
import { urgentOptions } from '../../../constants/dropdowns/urgentOptions';
import { agencyOptions } from '../../../constants/dropdowns/agencyOptions';
import { statusOptions } from '../../../constants/dropdowns/statusOptions';
import { seasonOptions } from '../../../constants/dropdowns/seasonOptions';
import { getFullName, getSortComparatorFullName, getFormattedDate, getPledgeAmount } from '../../../utils/utilAgencyTableFunctions';
import { StyledDataGrid } from '../../StyledDataGrid';
import { AuthContext } from '../../../context/authContext';
import { useErrorViewer } from "../../../context/errorContext";
import UrgencyInfo from '../../UrgencyInfo';
import SaveMergedPDF from '../../agency/components/SaveMergedPDF';
import { retryFilter, getUrgentIcon, SortedAscendingIcon, SortedDescendingIcon, appSearch, getStateCountyOptions, getStatusChip} from '../../../utils/dashboardUtils/utilSharedTableFunctions';
import { InsertDriveFile, DescriptionOutlined } from '@mui/icons-material'; 
import NotesAndHistory from '../../NotesAndHistory';

const SEARCH_DELAY = 300;
const FILTER_CALL_DELAY = 1000;
const PAGE_SIZE = 50;

const useStyles = makeStyles((theme) => ({
  approved: {
    backgroundColor: theme.customColors.applicationStatus.approved
  },
  denied: {
    backgroundColor: theme.customColors.applicationStatus.denied
  },
  returned: {
    backgroundColor: theme.customColors.applicationStatus.returned   
  },
  submitted: {
    backgroundColor: theme.customColors.applicationStatus.submitted
  },
  reSubmitted: {
    backgroundColor: theme.customColors.applicationStatus.reSubmitted
  },
  inProgress: {
    backgroundColor: theme.customColors.applicationStatus.inProgress
  },
  onPaper: {
    backgroundColor: theme.customColors.applicationStatus.onPaper
  },
  pending: {
    backgroundColor: theme.customColors.applicationStatus.pending
  },
  error: {
    color: theme.customColors.applicationStatus.error + " !important",
    borderColor: theme.customColors.applicationStatus.error + " !important",
  },
  primaryColor: {
    color: theme.palette.primary.main
  },
  table: {
    minWidth: '75rem',
  },
  tableActions: {
    alignItems: 'center',
    marginTop: '1.25rem',
    marginBottom: '1.25rem'
  },
  column: {
    fontWeight: '500',
    marginLeft: '.5rem',
    whiteSpace: 'break-spaces',
    maxHeight: 'none !important',
    lineHeight: '1.5rem',
  },
  cell: {
    fontWeight: '500',
    paddingLeft: '.5rem',
    width: '100%',
    whiteSpace: 'break-spaces',
    lineHeight: '1.5rem'
  },
  centeredCell: {
    fontWeight: '500',
    margin: 'auto',
    whiteSpace: 'nowrap',
    lineHeight: '1.5rem'
  },
  noWrapCell: {
    fontWeight: '500',
    whiteSpace: 'nowrap',
    lineHeight: '1.5rem'
  },
  search: {
    borderRadius: '.5rem',
    height: '3rem'
  },
  searchInput: {
    height: '1.5rem',
    padding: '.75rem .25rem'
  },
  urgentIcon: {
    color: theme.customColors.mediumGray,
    height: '2.75rem',
    width: '6rem',
    padding: '.1rem',
    display: 'initial',
  },
  chip: {
    borderRadius: '.5rem', 
    fontSize: '.875rem',
    height: '2rem',
    color: '#fff',
    width: "7rem",
    justifyContent: 'left',
    '& > .MuiChip-label': {
      textOverflow: 'clip',
    }
  },
  divider: {
    borderColor: theme.customColors.mediumGray,
    width: '1.5rem',
    margin: 'auto'
  },
  filters: {
    alignItems: 'center'
  },
  filter: {
    margin: '.25rem',
  },
  clearFilters: {
    margin: '.25rem',
    padding: '0 1rem',
    textTransform: 'none',
    fontSize: '.875rem',
    fontWeight: 500
  },
  greeting: {
    marginTop: '1rem',
    color: theme.palette.primary.main
  },
  notesIcon: {
    marginLeft: '1.2rem',
    paddingLeft: '.8rem',
    height: '2.5rem',
    width: '2.5rem'
  },
}));
  export default function StateReviewTable() {
  const classes = useStyles();
  const [openUrgent, setOpenUrgent] = useState(false);
  const [page, setPage] = useState(0);
  const { userInfo } = useContext(AuthContext);
  let userFullName= `${userInfo?.firstName} ${userInfo.lastName}`

  const [isFetching, setIsFetching] = useState(false)
  const agencyState = userInfo?.agencyState;
  const setError = useErrorViewer();

  const mutation = useMutation(searchPayload => {
    return searchAndFilterApplications(searchPayload);
  })

  const defaultSeason = seasonOptions[0].value //Defaulted to "LIHEAP_APP"
  const [applications, setApplications] = useState([]);
  const [selectedApplication, setSelectedApplication] = useState('');
  const [selectionModel, setSelectionModel] = useState('');
  const [searchValue, setSearchValue] = useState(window.sessionStorage.getItem('storedSearchValue') ? window.sessionStorage.getItem('storedSearchValue') : '');
  const [totalApps, setTotalApps] = useState(0);
  const [sortString, setSortString] = useState('initialSubmittedDate:asc:date');
  const [urgentFilter, setUrgentFilter] = useState(window.sessionStorage.getItem('storedUrgentFilter') ? window.sessionStorage.getItem('storedUrgentFilter').split(',') : []);
  const [statusFilter, setStatusFilter] = useState(window.sessionStorage.getItem('defaultFilterSettingsChaged') ? window.sessionStorage.getItem('storedStatusFilter') ? window.sessionStorage.getItem('storedStatusFilter').split(',') : [] : []);  
  const [dateFilter, setDateFilter] = useState(window.sessionStorage.getItem('storedDateFilterStart') && window.sessionStorage.getItem('storedDateFilterEnd') ? [new Date(window.sessionStorage.getItem('storedDateFilterStart')), new Date(window.sessionStorage.getItem('storedDateFilterEnd'))] : [null, null]);
  const [countyFilter, setCountyFilter] = useState(window.sessionStorage.getItem('storedCountyFilter') ? window.sessionStorage.getItem('storedCountyFilter').split(',') : []);
  const [agencyFilter, setAgencyFilter] = useState(window.sessionStorage.getItem('storedAgencyFilter') ? window.sessionStorage.getItem('storedAgencyFilter').split(',') : []);
  const [seasonFilter, setSeasonFilter] = useState(window.sessionStorage.getItem('storedSeasonFilter') ? window.sessionStorage.getItem('storedSeasonFilter').split(',') : [defaultSeason])

  const [openNotes, setOpenNotes] = useState(false);

  const [clearAllTrigger, setClearAllTrigger] = useState(false);

  useEffect(() => {
    getFilteredApplications(searchValue);
    saveFilterStates();
    setClearAllTrigger(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [urgentFilter, statusFilter, countyFilter, dateFilter, agencyFilter, seasonFilter, searchValue, sortString, page]);

  const getFilteredApplications = () => {
    setIsFetching(true);
    let matchObject = null; let filterTerms = []; let dateObject = null;
    if(searchValue){
      matchObject = {'value': searchValue, 'fields' : ['firstName', 'lastName', 'serviceCountyOrParish', 'agencyId']}
    }
    if(urgentFilter.length > 0){
      let urgentObject = {"field" : "urgent", "values" : urgentFilter}
      filterTerms.push(urgentObject)
    }
    if(statusFilter.length > 0){
      let statusObject = {'field': 'status', 'values': statusFilter}
      filterTerms.push(statusObject)
    }
    if(countyFilter.length > 0){
      let countyObject = {"field" : "serviceCountyOrParish", "values" : countyFilter}
      filterTerms.push(countyObject)
    }
    if(agencyFilter.length > 0){
      let agencyObject = {"field" : "agencyId", "values" : agencyFilter}
      filterTerms.push(agencyObject)
    }
    if(seasonFilter.length > 0){
      let seasonObject = {"field" : "recordType", "values" : seasonFilter}
      filterTerms.push(seasonObject)
    }

    let dateFilterStart = dateFilter[0] ? new Date(dateFilter[0]) : null
    let dateFilterEnd = dateFilter[1] ? new Date(dateFilter[1]) : null

    let dateFilterEndPlusDay = dateFilterEnd ? new Date(dateFilterEnd.getTime()) : null;
    if(dateFilterEndPlusDay){
      dateFilterEndPlusDay.setDate(dateFilterEndPlusDay.getDate() + 1);
    }

    if(dateFilterStart && dateFilterEndPlusDay){
      dateObject = {"field" : "initialSubmittedDate", "gte" : dateFilterStart, "lte" : dateFilterEndPlusDay}
    }

    let searchAndFilterPayload = {
      "match": matchObject,
      "filterTerms": filterTerms,
      "dateFilter": dateObject,
      "sort": sortString,
      "pageSize" : PAGE_SIZE,
      "pageStart" : page * PAGE_SIZE
    };
    
    mutation.mutate(searchAndFilterPayload, {
      onSuccess: (response) => {
        if(response){
          setIsFetching(false);
          let total = response?.data?.hits?.total?.value;
          setTotalApps(total);
          let hits = response?.data?.hits?.hits
          let data = hits ? hits.map((hit) => hit._source) : []
          setApplications(data)
        }
        else{
          retryFilter(
              () => {
                getFilteredApplications();
              }, FILTER_CALL_DELAY);
        }
      },
      onError: () => {
        setIsFetching(false);
        setError('Error fetching applications.')
      }
    })
  }
  
  const getColumns = () => {
    return [
      {
        field: 'agency', flex: .5,
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Agency</Typography>),
        renderCell: (params) => (<Typography variant='subtitle1' className={classes.cell}>{params.row.agencyId}</Typography>)
      },
      {
        field: 'urgent', flex: .5,
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Urgent</Typography>),
        renderCell: (params) => (<IconButton id={`${params.id}-urgent`} aria-label={`${params.id} Urgent`} disabled={isFetching || params.value === 'no'} className={`${classes.cell} ${classes.urgentIcon}`} onClick={() => handleOpenUrgent(params)} size="large">{getUrgentIcon(params.value)}</IconButton>)
      },
      {
        field: 'status', flex: .5, 
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Status</Typography>),
        renderCell: (params) => (<Typography variant='subtitle1' className={classes.cell}>{getStatusChip(params.value, classes, true)}</Typography>)
      },
      {
        field: 'applicantName', valueGetter: getFullName, flex: .7, sortComparator: getSortComparatorFullName,
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Client</Typography>),
        renderCell: (params) => (<Typography variant='subtitle1' className={classes.cell}>{params.value ? params.value : ''}</Typography>)
      },
      {
        field: 'initialSubmittedDate', flex: .7,
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Submitted</Typography>),
        renderCell: (params) => (<Typography variant='subtitle1' className={classes.noWrapCell}>{getFormattedDate(params.value)}</Typography>)
      },
      {
        field: 'serviceCountyOrParish', flex: .5, 
        renderHeader: () => (<Typography variant='h6' className={classes.column}>County</Typography>),
        renderCell: (params) => (<Typography variant='subtitle1' className={classes.cell}>{params.value}</Typography>)
      },
      {
        field: 'pledgeAmount', valueGetter: getPledgeAmount, flex: .5,
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Amount</Typography>),
        renderCell: (params) => (<Typography variant='subtitle1' className={classes.centeredCell}>{getFormattedPledgeAmount(params.value)}</Typography>)
      },
      {
        field: 'notes', flex: .5, sortable: false,
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Notes & History</Typography>),
        renderCell: (params) => (<IconButton id={`${params.id}-notes`} aria-label={`${params.id} Notes`}disabled={isFetching} className={`${classes.cell} ${classes.notesIcon}`} onClick={() => handleOpenNotes(params)} size="large">{getNotesIcon(params)}</IconButton>)
      },
      {
        field: 'applicationPdf', flex: .7, sortable: false,
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Application</Typography>),
        renderCell: (params) => ( params.row.status !== statuses.RETURNED ? renderReviewInfo(params.row) : <></>)
      }
    ];
  }

  /**
  * Store the user's selected search and filter values to have table in the same state as they go from page to page.
  * Cleared on logout.
  */
  const saveFilterStates = () => {
    window.sessionStorage.setItem('storedAgencyFilter', agencyFilter);
    window.sessionStorage.setItem('storedSearchValue', searchValue);
    window.sessionStorage.setItem('storedUrgentFilter', urgentFilter);
    window.sessionStorage.setItem('storedStatusFilter', statusFilter);
    window.sessionStorage.setItem('storedCountyFilter', countyFilter);
    window.sessionStorage.setItem('storedSeasonFilter', seasonFilter);

    if(dateFilter[0] === null && dateFilter[1] === null){
      window.sessionStorage.setItem('storedDateFilterStart', '');
      window.sessionStorage.setItem('storedDateFilterEnd', '');
    }
    else{
      window.sessionStorage.setItem('storedDateFilterStart', dateFilter[0]);
      window.sessionStorage.setItem('storedDateFilterEnd', dateFilter[1]);
    }
  }

  const getFormattedPledgeAmount = (val) => {
    if(val)
      return "$ {0}"
        .replace("{0}", val);
    else
      return <Divider className={classes.divider}/>;
  }

  const getNotesIcon = (params) => {
    if(params.row.notesAndHistory){
      return <InsertDriveFile className={classes.primaryColor} />
    }else{
      return <DescriptionOutlined style={{color: "#74777F"}}/>
    }
  }
  
  const handleOpenUrgent = (params) => {
    if(params.id !== selectedApplication.id){
      handleRowSelection(params)
    }
    setOpenUrgent(true);
  }

  const handleOpenNotes = (params) => {
    if(params.id !== selectedApplication.id){
      handleRowSelection(params)
    }
    setOpenNotes(true);
  }
 
  const handleCloseUrgent = () => {
    setOpenUrgent(false);
    setSelectedApplication('');
    setSelectionModel('');
  };

  const handleCloseNotes = () => {
    setOpenNotes(false);
    setSelectedApplication('');
    setSelectionModel('');
  };

  const handleFilterChange = (label, value) => {
    switch (label) {
      case "Season":
        setSeasonFilter(value)
        break
      case "Submitted":
        setDateFilter(value)
        break
      case "Urgent":
        setUrgentFilter(value)
        break
      case "Status":
        setStatusFilter(value)
        window.sessionStorage.setItem('defaultFilterSettingsChaged', true)
        break
      case "County":
        setCountyFilter(value)
        break
      case "Agency":
        setAgencyFilter(value)
        break
      default:
        break
    }
  };

  const handleRowSelection = (row) => {
    if(selectedApplication && selectedApplication.id === row.id){
      setSelectionModel('');
      setSelectedApplication('');
    }
    else{
      setSelectionModel(row.id);
      const application = applications?.filter((app) => {return app.id === row.id});
      setSelectedApplication(application ? application[0]: '');
    }
  }

  const handlePageChange = (newPage) => {
    setPage(newPage);
  }

  const handleSortChange = (model) => {
    switch (model[0]?.field) {
      case "urgent":
      case "status":
      case "serviceCountyOrParish":
        setSortString(`${model[0]?.field}:${model[0]?.sort}:`)
        break
      case "agency":
        setSortString(`agencyId:${model[0]?.sort}:`)
        break
      case "initialSubmittedDate":
        setSortString(`${model[0]?.field}:${model[0]?.sort}:date`)
        break
      case "applicantName":
        setSortString(`lastName:${model[0]?.sort}:`)
        break
      case "pledgeAmount":
        setSortString(`statusInfo.pledgeAmount:${model[0]?.sort}:`)
        break
      default:
        break
    }
  }

  const resetFilters = () => {
    setUrgentFilter([]); 
    setStatusFilter([]); 
    setDateFilter([null, null]); 
    setCountyFilter([]); 
    setAgencyFilter([]);
    setSeasonFilter([defaultSeason]);
    setClearAllTrigger(true);
    window.sessionStorage.setItem('defaultFilterSettingsChaged', true)
  }

  const throttleSearchChange = (event) => {
    appSearch(
      () => {
        setSearchValue(event.target.value);
      }, SEARCH_DELAY);
  }

  const renderReviewInfo = (pdfFileData) => {
    return (
      <Grid container>
        <Grid item container xs={8} justifyContent="flex-start" className={classes.pdfActions}>
          <SaveMergedPDF appData={pdfFileData} disabled={isFetching}/> 
        </Grid>
      </Grid>
    )
  }
  return(
    <div className={classes.table}>
      <Grid item xs={12} className={classes.greeting}>
        <Typography variant="h6">Welcome, {userFullName ? userFullName : 'User'}</Typography>
      </Grid>
      <Grid item xs={12} >
        <Typography variant="h5">Dashboard</Typography>
      </Grid>
      <Grid className={classes.tableActions} container item xs={12} justifyContent="flex-start">
        <Grid item xs={3}>
          <TextField
            id="outlined-search"
            fullWidth
            className={classes.search}
            defaultValue={searchValue}
            placeholder="Search"
            onChange={throttleSearchChange}
            InputProps={{
              startAdornment: (<InputAdornment position="start"><Search /></InputAdornment>), 
              classes: {input: classes.searchInput}
            }}
          />
        </Grid>
        <Grid item xs={9} container justifyContent="flex-end" className={classes.filters}>
          <Grid className={classes.filter} item >
          <TableFilter initialValue={[defaultSeason]} label="Season" options={seasonOptions} handleFilterChange={handleFilterChange} clearAllTrigger={clearAllTrigger}/>
          </Grid>
          <Grid className={classes.filter} item >
            <TableFilter initialValue={agencyFilter} label="Agency" options={agencyOptions} handleFilterChange={handleFilterChange} clearAllTrigger={clearAllTrigger}/>
          </Grid>
          <Grid className={classes.filter} item >
            <TableFilter initialValue={urgentFilter} label="Urgent" options={urgentOptions} handleFilterChange={handleFilterChange} clearAllTrigger={clearAllTrigger}/>
          </Grid>
          <Grid className={classes.filter} item >
            <TableFilter initialValue={statusFilter} label="Status" options={statusOptions} handleFilterChange={handleFilterChange} clearAllTrigger={clearAllTrigger}/>
          </Grid>
          <Grid className={classes.filter} item >
            <TableFilter initialValue={dateFilter} label="Submitted" options={yesNoOptions} handleFilterChange={handleFilterChange} clearAllTrigger={clearAllTrigger}/>
          </Grid>
          <Grid className={classes.filter} item >
            <TableFilter initialValue={countyFilter} label="County" options={getStateCountyOptions(agencyState)} handleFilterChange={handleFilterChange} clearAllTrigger={clearAllTrigger}/>
          </Grid>
          <Grid item >
            <Button onClick={resetFilters} variant="outlined" className={classes.clearFilters}>Clear Filters</Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <StyledDataGrid
          className={classes.dataGrid}
          rows={applications ? applications : []}
          rowCount={totalApps}
          columns={getColumns()}
          hideFooterSelectedRowCount
          disableSelectionOnClick
          disableColumnMenu
          paginationMode="server"
          pagination
          onPageChange={handlePageChange}
          pageSize={PAGE_SIZE}
          loading={isFetching}
          selectionModel={selectionModel}
          components={{
            ColumnSortedDescendingIcon: SortedDescendingIcon,
            ColumnSortedAscendingIcon: SortedAscendingIcon,
          }}
          onSortModelChange={handleSortChange}
          rowsPerPageOptions={[PAGE_SIZE]}
          sortingOrder={['asc', 'desc']}
          sortingMode="server"
        />
      </Grid>

      <NotesAndHistory application={selectedApplication} open={openNotes} handleClose={handleCloseNotes}/>
      <UrgencyInfo application={selectedApplication} open={openUrgent} handleClose={handleCloseUrgent}/>

    </div>
  );
}
