import { InputAdornment, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { TextField } from "@mui/material";
import NumberFormat from "react-number-format";
import { getErrorMessage } from '../../utils/utilFunctions';
import { Controller } from 'react-hook-form';

const useStyles = makeStyles(theme => ({
  textField: {
    backgroundColor: '#fff',
    "& > .MuiFormHelperText-root.Mui-error": { 
      backgroundColor: theme.customColors.veryLightGray,
      margin:0
    }
  },
  agencyTextField: {
    backgroundColor: '#fff',
    marginTop:0,
    "& > .MuiFormHelperText-root.Mui-error": { 
      backgroundColor: "#fff",
      margin:0
    }
  }
}));

export default function FormCurrencyField({ name, label, labelPlacement, defaultValue='', errors, control, agency=false }) {
  const classes = useStyles();

  return (
    <>
      {(labelPlacement && labelPlacement === 'top') &&
        <Typography variant="subtitle1" style={{fontWeight: "bold", marginTop: "10px"}}>
          <label htmlFor={name}>
            {label}
          </label>
        </Typography>
      }
      <Controller 
        as={
          <NumberFormat
            customInput={TextField}
            className={agency ? classes.agencyTextField : classes.textField}
            aria-label={label}
            id={name}
            label={(labelPlacement && labelPlacement === 'top') ? "" : label}
            fullWidth
            autoComplete="false"
            variant="outlined"
            margin="normal"
            error={Boolean(getErrorMessage(errors, name))}
            helperText={getErrorMessage(errors, name)}
            isNumericString
            decimalScale={2}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            inputProps={{
              inputMode: 'numeric'
            }}
          />
        }
        name={name}
        control={control}
        defaultValue={defaultValue}
      />
    </>
  )
}
