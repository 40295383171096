import React, { useState, useContext, useEffect } from 'react'
import { AuthContext } from "../context/authContext";
import { Modal, Button, Grid, CircularProgress, Typography, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation } from 'react-query';
import { addApplicationNote, editApplicationNote, removeApplicationNote } from '../api/AgencyAPI';
import { useForm } from 'react-hook-form';
import FormTextField from './form/FormTextField'
import { yupResolver } from '@hookform/resolvers/yup';
import { EditOutlined, DeleteOutlineOutlined } from '@mui/icons-material';
import { useErrorViewer } from "../context/errorContext";
import { AgencyNoteValidationSchema } from '../constants/sharedAgencyValidationSchema';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const useStyles = makeStyles((theme) => ({
  modal: {
    position: 'absolute',
    background: theme.customColors.modalBackground,
    boxShadow: '0 0 .3rem rgba(0, 0, 0, 0.2)',
    bozSizing: 'inherit',
    borderRadius: '1.75rem',
    padding: '3rem',
    width: '40rem',
    maxHeight: '35rem',
    overflow: 'scroll'
  },
  modalHeader:{
    marginBottom: '1rem'
  },
  modalButton: {
    marginTop: '1rem',
    textTransform: 'none',
    fontWeight: 'bold'
  },
  postButton: {
    fontWeight: 'bold',
    textTransform: 'none',
    marginBottom: '2rem'
  },
  spinner: {
    position: 'absolute',
    color: theme.palette.primary.main,
    zIndex: '999'
  },
  notes:{
    overflow: 'scroll'
  },
  noNotes:{
    justifyContent: 'center',
    display: 'flex',
    margin: '2rem 0'
  },
  noteObject: {
    marginBottom: '1rem'
  },
  noteText: {
    wordBreak: 'break-word'
  },
  infoLabel: {
    fontWeight: 600,
    wordBreak: 'break-word'
  },
  date: {
    color: theme.customColors.veryDarkGray
  },
  statusInfo: {
    marginBottom: '.5rem',
    marginLeft: '1rem',
    fontWeight: 700,
    fontSize: '1rem'
  },
  statusInfoReason: {
    color: theme.customColors.darkGray,
    fontWeight: 500
  },
  info: {
    marginBottom: '.5rem',
    color: '#44474F'
  },
  editButtons: {
    fontWeight: 'bold',
    textTransform: 'none',
    marginBottom: '1rem',
    padding: '1rem'
  },
  editIcons: {
    marginRight: '.5rem'
  },  
    divider: {
    marginTop: '1rem',
    width: '100%',
    border: '1px solid #C4C4C4'
},
}));

export default function Notes({ application, onChange, canEdit=false }){
  const { userInfo } = useContext(AuthContext);
  const userId = userInfo?.id;
  const [notes, setNotes] = useState([]);
  const setError = useErrorViewer();

  const [noteToEdit, setNoteToEdit] = useState({});
  const [openEditNote, setOpenEditNote] = useState(false);

  const [noteToRemove, setNoteToRemove] = useState({});
  const [openRemoveNote, setOpenRemoveNote] = useState(false);

  useEffect(() => {
    if(application && application?.notesAndHistory){
      const nh = application?.notesAndHistory
      const notes = nh?.filter(x => x.type === 'note').sort((a, b) => new Date(b.date) - new Date(a.date))
      setNotes(notes)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  const { handleSubmit, errors, register, getValues } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(AgencyNoteValidationSchema)
  })

  const [changeSucessful, setChangeSucessful] = useState(false);
  const [changeType, setChangeType] = useState('');

  const saveNotesMutation = useMutation(newNote => {
    const updateNotesObject = {
      applicationId: application.id,
      note: newNote,
      userId: userId
    }
    return addApplicationNote(updateNotesObject);
  })

  const editNoteMutation = useMutation(newNoteObj => {
    const editNoteObject = {
      applicationId: application.id,
      editedNote: newNoteObj
    }
    return editApplicationNote(editNoteObject);
  })

  const removeNoteMutation = useMutation(noteToRemove => {
    const removeNoteObject = {
      applicationId: application.id,
      removedNote: noteToRemove
    }
    return removeApplicationNote(removeNoteObject);
  })

  const handleSaveNotes = () => {
    const newNote = getValues('note');
    saveNotesMutation.mutate(newNote, {
      onSuccess: (data) => {
        setChangeType('added')
        handleSuccess();
      },
      onError: (error) => {
        setError("An Error Occurred Saving The Note")
      }
    })
  }

  const handleSuccess= () => {
    setChangeSucessful(true);
  };

  const handleCloseSuccess = () => {
    setChangeSucessful(false);
    setChangeType('');
    onChange();
  };

  const handleOpenEditNote = (noteObj) => {
    setOpenEditNote(true);
    setNoteToEdit(noteObj);
  };

  const handleCloseEditNote = () => {
    setOpenEditNote(false);
    setNoteToEdit({});
  };

  const handleEditNote = () => {
    const newNoteObj = {...noteToEdit, 'note': getValues('editNoteText')}
    editNoteMutation.mutate(newNoteObj, {
      onSuccess: (data) => {
        setChangeType('edited')
        handleSuccess();
      },
      onError: (error) => {
        setError("An Error Occurred Editing The Note")
      }
    })
    handleCloseEditNote()
  }

  const handleOpenRemoveNote = (noteObj) => {
    setOpenRemoveNote(true);
    setNoteToRemove(noteObj);
  };

  const handleCloseRemoveNote = () => {
    setOpenRemoveNote(false);
    setNoteToRemove({});
  };

  const handleRemoveNote = () => {
    removeNoteMutation.mutate(noteToRemove, {
      onSuccess: (data) => {
        setChangeType('removed')
        handleSuccess();
      },
      onError: (error) => {
        setError("An Error Occurred Removing The Note")
      }
    })
    handleCloseRemoveNote()
  }

  const getFormattedDate = (val) => {
    const options = {
      year: "numeric",
      month:"2-digit",
      day:"2-digit",
      hour:  "2-digit",
      minute: "2-digit",
   };
   return new Date(val).toLocaleDateString("en-US", options).replace(', ','\n');
  }

  return(
    <>
    {!changeSucessful && 
      <form noValidate autoComplete="false">
        <Grid item xs={12}>
          {(saveNotesMutation.isLoading || editNoteMutation.isLoading || removeNoteMutation.isLoading) && 
            <Grid style={modalStyle} justifyContent="center" className={classes.spinner}>
              <CircularProgress color="primary" />
            </Grid>
          }
          {canEdit &&
          <>
            <FormTextField 
              name="note" 
              label="Type internal notes here"
              defaultValue={''}
              disabled={saveNotesMutation.isLoading || editNoteMutation.isLoading || removeNoteMutation.isLoading}
              multiline
              rows={3}
              register={register} 
              errors={errors}     
            />
            <Grid item xs={12} container justifyContent="flex-end">
              <Button 
                className={classes.postButton} 
                disabled={saveNotesMutation.isLoading || editNoteMutation.isLoading || removeNoteMutation.isLoading} 
                onClick={handleSubmit(handleSaveNotes)} 
                variant="text" 
                color="primary"
              >
                  Post a note
              </Button>
            </Grid>
          </>
          }
        </Grid>
        {notes.length > 0 ?
          <Grid item xs={12} className={classes.notes}>
            {notes.map ((noteObject, index) => {
                return (
                  <Grid key={index} item xs={12} container className={classes.noteObject}>
                    <Grid item container xs={6} justifyContent="flex-start">
                      <Typography variant='subtitle2' className={classes.infoLabel}>Note by {noteObject?.userFullName}</Typography>
                    </Grid>
                    <Grid item container xs={6} justifyContent="flex-end">
                      <Typography variant='subtitle2' className={classes.info}>{getFormattedDate(noteObject?.date)}</Typography>
                    </Grid>
                    <Grid item container className={classes.noteText} justifyContent="flex-start">
                      <Typography variant='subtitle2' className={classes.info}>"{noteObject?.note}"</Typography>
                    </Grid>
                    {(canEdit && userId === noteObject.userId) &&
                    <Grid item xs={12} container justifyContent="flex-end">
                      <Button 
                        className={classes.editButtons} 
                        disabled={saveNotesMutation.isLoading || editNoteMutation.isLoading || removeNoteMutation.isLoading} 
                        onClick={() => handleOpenEditNote(noteObject)} 
                        variant="text" 
                        color="primary"
                      >
                        <EditOutlined className={classes.editIcons}/> 
                        Edit
                      </Button>
                      <Button 
                        className={classes.editButtons} 
                        disabled={saveNotesMutation.isLoading || editNoteMutation.isLoading || removeNoteMutation.isLoading} 
                        onClick={() => handleOpenRemoveNote(noteObject)} 
                        variant="text" 
                        color="primary"
                      >
                        <DeleteOutlineOutlined className={classes.editIcons}/>
                        Remove
                      </Button>
                    </Grid>
                    }
                    <Divider className={classes.divider}/>
                  </Grid> 
                )
            })}
          </Grid>
          :
          <Grid item xs={12} className={classes.noNotes}>
            <Typography>This application has no notes.</Typography>
          </Grid>
        }
      </form>
}

      <Modal open={openEditNote} onClose={handleCloseEditNote}>
        <Grid  style={modalStyle} className={classes.modal}>
        <Grid item container justifyContent="flex-start">
          <Typography variant="h5" className={classes.modalHeader}>
            Edit note
          </Typography>
        </Grid>
        <Grid item container justifyContent="flex-start">
          <FormTextField 
            name="editNoteText" 
            label="Edit note here"
            defaultValue={noteToEdit?.note}
            disabled={editNoteMutation.isLoading}
            multiline
            rows={5}
            register={register} 
            errors={errors}     
          />
        </Grid>
        <Grid item container justifyContent="flex-end" >
          <Button className={classes.modalButton} onClick={handleCloseEditNote} variant="text" color="primary">
            Cancel
          </Button>
          <Button className={classes.modalButton} onClick={handleEditNote} variant="text" color="primary">
            Save
          </Button>
        </Grid>
        </Grid>
      </Modal>

      <Modal open={openRemoveNote} onClose={handleCloseRemoveNote}>
        <Grid  style={modalStyle} className={classes.modal}>
        <Grid item container justifyContent="flex-start">
          <Typography variant="h5" className={classes.modalHeader}>
            Remove note
          </Typography>
        </Grid>
        <Grid item container justifyContent="flex-start">
          <Typography variant="subtitle1">
            Are you sure you want to remove your note? This cannot be undone.
          </Typography>
        </Grid>
        <Grid item container justifyContent="flex-start">
          <FormTextField 
            name="removeNoteText" 
            label=""
            aria-label="Note to remove"
            defaultValue={noteToRemove?.note}
            inputProps={{
              readOnly: true,
            }}
            multiline
            rows={5}
            register={register} 
            errors={errors}     
          />
        </Grid>
        <Grid item container justifyContent="flex-end" >
          <Button className={classes.modalButton} onClick={handleCloseRemoveNote} variant="text" color="primary">
            Cancel
          </Button>
          <Button className={classes.modalButton} onClick={handleRemoveNote} variant="text" color="primary">
            Remove
          </Button>
        </Grid>
        </Grid>
      </Modal>

      <Modal open={changeSucessful} onClose={handleCloseSuccess}>
        <Grid  style={modalStyle} className={classes.modal}>
        <Grid item container justifyContent="flex-start">
          <Typography variant="h5" className={classes.modalHeader}>
            Success
          </Typography>
        </Grid>
        <Grid item container justifyContent="flex-start">
          <Typography variant="subtitle2">
          Your internal note for {application?.firstName} {application?.lastName}'s application has been {changeType}.
          </Typography>
        </Grid>
        <Grid item container justifyContent="flex-end" >
          <Button className={classes.modalButton} onClick={handleCloseSuccess} variant="text" color="primary">
            Okay
          </Button>
        </Grid>
        </Grid>
      </Modal>
</>
  );
}
