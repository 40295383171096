export const statuses = {
  NEW: "In Progress",
  SUBMITTED: "Submitted",
  IN_REVIEW: "In-Review",
  RE_SUBMITTED: "Re-Submitted",
  APPROVED: "Approved",
  DENIED: "Denied",
  RETURNED: "Returned",
  ON_PAPER: "On Paper",
  PENDING: "Pending",
  ERROR: "Error"
}