import { Button, TextField, Grid, Typography, CircularProgress, IconButton, InputAdornment } from '@mui/material'
import { useContext, useState } from 'react'
import { userLogin } from '../../api/UserAPI';
import { AuthContext } from '../../context/authContext';
import { Redirect } from 'react-router';
import makeStyles from '@mui/styles/makeStyles';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import ConfirmUser from './confirm';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import MessageBanner from '../MessageBanner';
import { useErrorViewer } from '../../context/errorContext';
import { statePermissions } from '../../constants/statePermissions';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(5),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: "center",
        background: "#ffffff"
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.dark,
        fontWeight: "bolder"
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));
export default function Login() {
    const [formData, setFormData] = useState({ username: "", password: "" });
    const [loading, setLoading] = useState(false);
    const [invalidLogin, setInvalidLogin] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [showPASS, setShowPASS] = useState(false);
    const setError = useErrorViewer();

    const auth = useContext(AuthContext);
    const classes = useStyles();

    const isAgent = (userInfo) => {
        return (userInfo["userType"] === "agency");
    }

    const isStateUser = (userInfo) => {
        return (userInfo["userType"] === "state");
    }
    
    const showInvalidLogin = () => {
        setFormData({ username: "", password: ""});
        setInvalidLogin(true);
    }

    const handleSubmit = () => {
        setLoading(true);
        userLogin(formData["username"], formData["password"])
            .then((response) => {
                auth.getTokenFromLoginResponse(response, true); 
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                const err = error.response.data? error.response.data.message : "Unknown Error";
                setError(err);
                switch(err) {
                    case "Invalid Username or Password":
                        showInvalidLogin();
                        break;
                    case "User Not Confirmed":
                        setShowConfirm(true);
                        break;
                    case "Applicant login is not allowed":
                        break;
                    default:
                        showInvalidLogin();
                }
            });

    }

    const handleChange = (event) => {
        let key = event.target.name;
        let value = event.target.value;
        setFormData({
            ...formData,
            [key]: value
        });
    };

    const handleMouseDownPASS = (event) => {
        event.preventDefault();
    };

    const confirmSuccess = () => {
        setShowConfirm(false);
    }

    
    if(auth["userInfo"]) {
        const params = new URLSearchParams(window.location.search);
        const redirect = params.get("redirect");
        
        if(redirect)
            return (<Redirect to={redirect} />)
        else {
            if(isAgent(auth["userInfo"])){
                return (<Redirect to="/agency/home"></Redirect>)
            } 
            else if (isStateUser(auth["userInfo"])){
                const userPermissions = auth.userInfo?.permissions ? auth.userInfo.permissions : [];
                if (userPermissions.includes(statePermissions.STATE_REVIEW_APPLICATION_VIEW))
                    return (<Redirect to="/state/dashboard"></Redirect>)
                else if (userPermissions.includes(statePermissions.STATE_REPORTS_VIEW))
                    return (<Redirect to="/state/reports"></Redirect>)
                else
                    return (<Redirect to="/unauthorized"></Redirect>)
            }
            else {
                return (<Redirect to="/dashboard"></Redirect>)
            }
        }
    }
    else
        if(showConfirm) {
            return (<ConfirmUser userName={formData.username} phone={false} confirmSuccess={confirmSuccess}/>)
        }
        else {
            return <>
                <Grid xs={12} item container justifyContent="center" direction="column"> 
                    <MessageBanner messageType="Login" />
                </Grid>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />

                    <div className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <AccountCircleIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign in to your account
                        </Typography>
                        <form className={classes.form} noValidate autoComplete="off">
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="E-mail Address"
                                name="username"
                                autoFocus
                                onChange={handleChange}
                                error={invalidLogin}
                                value={formData.username}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type={showPASS ? 'text' : 'password'}
                                id="password"
                                onChange={handleChange}
                                error={invalidLogin}
                                value={formData.password}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPASS(!showPASS)}
                                                onMouseDown={handleMouseDownPASS}
                                                edge="end"
                                                size="large">
                                                {showPASS ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />
                            {loading ?
                                <CircularProgress color="primary" size={36} /> :
                                <Button
                                    id="signin"
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={handleSubmit}
                                    disabled={!(formData.username && formData.password)}
                                >
                                    Sign In

                                </Button>
                            }
                            <Grid container>
                                <Grid item xs={6}>
                                    <Link href="/reset" variant="body2">
                                        {"Forgot your password?"}
                                    </Link>
                                </Grid>
                                <Grid item xs={6} >
                                    <Link href="/register" variant="body2">
                                        {"Need an account? Sign Up"}
                                    </Link>
                                </Grid>
                            </Grid>
                            <Box mt={1}>
                                <Typography variant="body2">
                                    By continuing, you agree to Entergy's{' '}
                                    <Link href="/termsOfUse" variant="body2">                                    
                                    {"Terms of Service"}
                                    </Link>
                                    {' '}and acknowledge you've read our {' '}
                                    <a href="https://www.entergy.com/privacy-policy/" target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
                                        Privacy Policy
                                    </a>
                                </Typography>
                            </Box>
                        </form>
                    </div>
                </Container>
            </>;
        }
}

