import React, { useState } from "react";
import { Grid, Typography, Tab, Tabs, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import SuccessModal from "../../SuccessModal";
import ModalWarning from "../../ModalWarning";
import StateSeasonSettingsView from "./StateSeasonSettingsView";

const useStyles = makeStyles((theme) => ({
  subtitleStyle: {
    paddingBottom: "1rem",
    marginTop: "2rem",
  },
  tabs: {
    marginBottom: "1.5rem",
  },
  tabHeader: {
    padding: "0",
    margin: "0 1rem 0 0 ",
  },
}));

export default function StateSeasonSettings() {
  const classes = useStyles();

  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  const [successModalOpen, setSuccessModalOpen] = useState(false);

  return (
    <>
      <Grid container>
        <Box sx={{ ml: "2rem", textAlign: "left" }}>
          <ModalWarning description="Program dates will be posted for both agencies and customers to see. Once the season begins, clients will be able to submit applications. Once it concludes, the platform will no longer accept new applications. Caseworkers will still be able to process submitted, returned and resubmitted applications." />
          <Box>
            <Typography variant="h5" className={classes.subtitleStyle}>
              Season
            </Typography>
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              className={classes.tabs}
            >
              <Tab label="Current Season" className={classes.tabHeader} />
              <Tab label="Next Season" className={classes.tabHeader} />
            </Tabs>
          </Box>
          <Box>
            {tabIndex === 0 && <StateSeasonSettingsView season="current" />}
          </Box>

          <Box>
            {tabIndex === 1 && <StateSeasonSettingsView season="next" />}
          </Box>
        </Box>
        <SuccessModal
          open={successModalOpen}
          title={"Success"}
          desc={"New Season Successfully Created"}
          okLabel={"Close"}
          handleOk={() => setSuccessModalOpen(false)(window.location.reload())}
        />
      </Grid>
    </>
  );
}
