
import React, { useContext, useState } from 'react';
import {
    Grid,
    Container,
    TextField,
    IconButton,
    InputAdornment,
    Modal,
    Typography,
    Button,
    CircularProgress,
    useTheme,
    useMediaQuery,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/authContext';
import { Redirect } from 'react-router-dom';
import { userChangePassword } from '../../api/UserAPI'
import { Visibility, VisibilityOff, ArrowBackIos, Check, Clear } from '@mui/icons-material';
import SettingsStepper from '../../components/SettingsStepper';
import { useErrorViewer } from '../../context/errorContext';

function getModalStyle() {
    const top = 50;
    const left = 50;
    
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`
    };
}

const useStyles = makeStyles((theme) => ({
  successInput: {
    color: theme.palette.success.main
  },
  errorInput: {
      color: theme.palette.error.main
  },
  grayInput: {
      color: theme.customColors.lightMedGray
  },
  mainContainer: {
    padding: "2.8rem",
    marginLeft: 'auto',
    marginRight: 'auto',
    width: "95%",
    [theme.breakpoints.down('lg')]: {
        padding: '2rem 1rem 0rem 1rem'
    }
  },
  passwords: {
    height: 'fit-content'
  },
  field: {
      width: '100%',
      maxWidth: '30rem',
      height: 'fit-content'
  },
  reqs: {
    display:'flex'
  },
  icon: {
    height: '1.25rem',
    marginTop: '.15rem'
  },
  forgotPass: {
      marginTop: '2rem',
      textDecoration: 'none',
      color: theme.palette.primary.main,  
  },
  stepButtons: {
    marginTop: '6rem',
    [theme.breakpoints.down('sm')]: {
        marginTop: '1rem'
      },
  },
  saveButton: {
    margin: '.5rem 0rem',
    [theme.breakpoints.down('sm')]: {
        width: '100%'
    }
  },
  backButton: {
    padding: '0rem 1rem',
    margin: '.5rem 0rem',
    color: theme.palette.primary.main,    
    [theme.breakpoints.down('sm')]: {
        width: '100%'
    }
  },
  modal: {
    position: 'absolute',
    background: '#FFFFFF',
    boxShadow: '0 0 .3rem rgba(0, 0, 0, 0.2)',
    boxSizing: 'inherit',
    borderRadius: '1.75rem',
    padding: '2rem',
    width: '20rem'
  },
  modalHeader:{
    marginBottom: '1rem'
  },
  modalButton: {
    marginTop: '1rem',
    textTransform: 'none'
  }
}));

export default function UpdatePassword() {
  const classes = useStyles();
  const { userInfo } = useContext(AuthContext);
  const theme = useTheme();
  const matchesXsDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [formData, setFormData] = useState({
    currentPassword: "", newPassword: "", confirmPassword: ""
    });

  const [navToAccountSettings, setNavToAccountSettings] = useState(false);
  const [successful, setSuccessful] = useState(false);

  const [showCurrentPass, setShowCurrentPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [matchesNewPass, setMatchesNewPass] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const [eightCharError, setEightCharError] = useState(false);
  const [uppercaseError, setUppercaseError] = useState(false);
  const [lowercaseError, setLowercaseError] = useState(false);
  const [numberError, setNumberError] = useState(false);
  const [specialCharError, setSpecialCharError] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalStyle] = useState(getModalStyle);

  const setError = useErrorViewer();
  
  const getInputClasses = (val, isErr) => {
    return `${classes.reqs} ${val ? isErr ? classes.successInput : classes.errorInput : classes.grayInput}`
  }

  const handleChange = (event) => {
    let key = event.target.name;
    let value = event.target.value;
    if(key === 'newPassword'){
        let regex1 = /^.{8,}$/;
        setEightCharError(regex1.test(value));
        
        let regex2 = /^.*(?=.*[A-Z]).*$/;
        setUppercaseError(regex2.test(value));
        
        let regex3 = /^.*(?=.*\d).*$/;
        setNumberError(regex3.test(value));
        
        let regex4 = /^.*(?=.*[!$@%]).*$/;
        setSpecialCharError(regex4.test(value));

        let regex5 = /^.*(?=.*[a-z]).*$/;
        setLowercaseError(regex5.test(value));

        setMatchesNewPass(formData.confirmPassword === value)
    }
    else if(key === 'confirmPassword'){
        setMatchesNewPass(formData.newPassword === value)
    }
    setFormData({
        ...formData,
        [key]: value
    });
  };

  const handleSave = () => {
    setSaveLoading(true);
    userChangePassword(userInfo.accessToken, formData.currentPassword, formData.newPassword).then((data) => {
        setSaveLoading(false)
        setSuccessful(true)
        setModalOpen(true);
    }).catch((error) => {
        setSaveLoading(false)
        const err = error.response.data? error.response.data.message : "Unknown Error";
        setError(err);
    })
  }

  const getConfirmPassErrorMessage = () => {
    if(formData.confirmPassword && !matchesNewPass){
        return 'Value does not match with first value given for new password.'
    }
    else{
        return ''
    }
  }
  
  const handleMouseDownPASS = (event) => {
    event.preventDefault();
  };

if (navToAccountSettings)
    return (
        <Redirect to={{pathname: "/account", state: {successfulUpdate: successful, attribute: 'Password'}}}></Redirect>
    )
else
  return <>
  <Container className={classes.mainContainer}>
    <Grid container spacing={1}>
      { matchesXsDown &&
        <SettingsStepper attribute={true} attributeLabel={'Password'} url={'/password'}/>
      }
      <Grid container item xs={12} justifyContent="flex-start" direction="column">
          <Typography variant="h2">
              Update Password
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
              To update your password, please provide your current password as well as the new password.            
          </Typography>
      </Grid>
      <Grid container className={classes.passwords} direction={matchesXsDown ? "row" : "column"}>
          <Grid item container xs={11} sm={4}>
              <TextField
                  className={classes.field}
                  variant="outlined"
                  margin="normal"
                  name="currentPassword"
                  label="Current Password"
                  type={showCurrentPass ? 'text' : 'password'}
                  id="currentPassword"
                  onChange={handleChange}
                  value={formData.currentPassword}
                  InputProps={{
                  endAdornment:
                  <InputAdornment position="end">
                      <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowCurrentPass(!showCurrentPass)}
                          onMouseDown={handleMouseDownPASS}
                          edge="end"
                          size="large">
                          {showCurrentPass ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                  </InputAdornment>
                  }}
              />
          </Grid>
          <Grid item container xs={11} sm={4}>
              <TextField
                  className={classes.field}
                  variant="outlined"
                  margin="normal"
                  name="newPassword"
                  label="New Password"
                  type={showNewPass ? 'text' : 'password'}
                  id="newPassword"
                  onChange={handleChange}
                  value={formData.newPassword}
                  InputProps={{
                  endAdornment:
                  <InputAdornment position="end">
                      <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowNewPass(!showNewPass)}
                          onMouseDown={handleMouseDownPASS}
                          edge="end"
                          size="large">
                          {showNewPass ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                  </InputAdornment>
                  }}
              />
          </Grid>
          <Grid item container xs={11} sm={4}>
              <TextField
              className={classes.field}
                  variant="outlined"
                  margin="normal"
                  name="confirmPassword"
                  label="Confirm New Password"
                  type={showConfirmPass ? 'text' : 'password'}
                  id="confirmPassword"
                  onChange={handleChange}
                  error={Boolean(formData.confirmPassword && !matchesNewPass)}
                  helperText={getConfirmPassErrorMessage()}
                  value={formData.confirmPassword}
                  InputProps={{
                  endAdornment:
                  <InputAdornment position="end">
                      <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowConfirmPass(!showConfirmPass)}
                          onMouseDown={handleMouseDownPASS}
                          edge="end"
                          size="large">
                          {showConfirmPass ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                  </InputAdornment>
                  }}
              />
          </Grid>
          </Grid>
          <Grid item container direction="column">
            <Grid item className={getInputClasses(formData.newPassword, eightCharError)}>
              {formData.newPassword && eightCharError && <Check className={classes.icon}/>}
              {formData.newPassword && !eightCharError && <Clear className={classes.icon}/>}
              <Typography>
                At least 8 characters
              </Typography>
            </Grid>
            <Grid item className={getInputClasses(formData.newPassword, lowercaseError)}>
              {formData.newPassword && lowercaseError && <Check className={classes.icon}/>}
              {formData.newPassword && !lowercaseError && <Clear className={classes.icon}/>}
              <Typography>
                At least one lowercase letter (a-z)
              </Typography>
            </Grid>
            <Grid item className={getInputClasses(formData.newPassword, uppercaseError)}>
              {formData.newPassword && uppercaseError && <Check className={classes.icon}/>}
              {formData.newPassword && !uppercaseError && <Clear className={classes.icon}/>}
              <Typography>
                At least one uppercase letter (A-Z)
              </Typography>
            </Grid>
            <Grid item className={getInputClasses(formData.newPassword, numberError)}>
              {formData.newPassword && numberError && <Check className={classes.icon}/>}
              {formData.newPassword && !numberError && <Clear className={classes.icon}/>}
              <Typography>
                At least one number (0-9)
              </Typography>
            </Grid>
            <Grid item className={getInputClasses(formData.newPassword, specialCharError)}>
              {formData.newPassword && specialCharError && <Check className={classes.icon}/>}
              {formData.newPassword && !specialCharError && <Clear className={classes.icon}/>}
              <Typography>
                At least one special character (!$@%)
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} >
              <Link to="/reset" className={classes.forgotPass}>
                  Forgot password?
              </Link>
          </Grid>
          <Grid item xs={12} container className={classes.stepButtons} direction={matchesXsDown ? "column-reverse" : "row"}>
            <Grid sm={2} item container justifyContent={matchesXsDown ? "center" : "flex-start"}>
              <Button
                className={classes.backButton} 
                onClick={() => setNavToAccountSettings(true)}
                color="primary"
                variant={matchesXsDown ? "outlined" : "text"}
                startIcon={matchesXsDown ? "" : <ArrowBackIos/>}
              >
                Back
              </Button>
            </Grid>
            <Grid sm={10} item container justifyContent={matchesXsDown ? "center" : "flex-end"}>
              {saveLoading ?
                  <CircularProgress color="primary" /> 
                  :
                  <Button
                      className={classes.saveButton} 
                      onClick={handleSave}
                      variant="contained"
                      color="primary"
                      disabled={!formData.currentPassword || !matchesNewPass || !eightCharError|| !lowercaseError || !uppercaseError || !numberError || !specialCharError}
                  >
                      Save
                  </Button>
              }
          </Grid>
      </Grid>
    </Grid>
  </Container>

  <Modal open={modalOpen} >
      <Grid style={modalStyle} className={classes.modal}>
        <Grid item container justifyContent="flex-start">
          <Typography variant="h5" className={classes.modalHeader}>
            Success
          </Typography>
        </Grid>
        <Grid item container justifyContent="flex-start">
          <Typography variant="subtitle2">
            Your password has been reset.
          </Typography>
        </Grid>
        <Grid item container justifyContent="flex-end" >
          <Button
            className={classes.modalButton} 
            onClick={() => setNavToAccountSettings(true)}
            variant="text"
            color="primary"
          >
            Ok
          </Button>
        </Grid>
      </Grid>
    </Modal>
  </>;
}
