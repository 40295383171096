export const utilityProviderOptions = [
  {
    value: 'entergy',
    label: 'Entergy'
  },
  {
    value: 'other',
    label: 'Other'
  }
]
