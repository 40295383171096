export const statusOptions = [
    {
      value: 'Approved',
      label: 'Approved'
    },
    {
      value: 'Denied',
      label: 'Denied'
    },
    {
      value: 'Error',
      label: 'Error'
    },
    {
      value: 'Submitted',
      label: 'New'
    },
    {
      value: 'On Paper',
      label: 'On Paper'
    },
    {
      value: 'Pending',
      label: 'Pending'
    },
    {
      value: 'Re-Submitted',
      label: 'Re-Submitted'
    },
    {
      value: 'Returned',
      label: 'Returned'
    }
  ]
  