import { Container, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import StepButtons from '../../StepButtons'
import StepHeader from '../../StepHeader'
import Documents from './Documents';


const useStyles = makeStyles((theme) => ({
  mainContainer: {
    margin: "0",
  }
}));

export default function AdditionalDocumentUpload({ data: appData, back, next, appId, fileData, handleFileCreate, handleFileDelete }) {
  const classes = useStyles();
  
  return (
    <Container className={classes.mainContainer}>
      <Grid container spacing={1}>
        <StepHeader 
          title="Additional Forms" 
          desc = "Your application may require additional forms. Required forms are listed below. You can access a list of all forms in the forms page."
        />
        <Documents
          appId={appId} 
          appData={appData} 
          type='additionalDocuments'
          fileData={fileData}
          handleFileCreate={handleFileCreate}
          handleFileDelete={handleFileDelete} />
        <Grid item xs={12}>
          <StepButtons back={back} next={next}/>
        </Grid>
      </Grid>      
    </Container>
  )
}
