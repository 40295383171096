import makeStyles from '@mui/styles/makeStyles';
import { Grid } from '@mui/material';
import StateReviewTable from '../components/state/components/StateReviewTable';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    marginLeft: '4rem',
    padding: "2rem 4rem",
    width: "95%"
  }
}));

export default function StateDashboard() {
  const classes = useStyles();
  return (    
    <>
    <Grid xs={12} item container justifyContent="flex-start" spacing={1} direction="column" className={classes.mainContainer}>
      <StateReviewTable/>
    </Grid>
    </>
  )
  }
