export const crisisSituationOptions = [
  {
    value: 'lowHeatingFuelWithTank',
    label: 'My heating fuel is at or below 10% of the tank capacity and the fuel supplier will not deliver additional fuel without payment.'
  },
  {
    value: 'lowHeatingFuelWithoutTank',
    label: 'I have 3 week\'s supply or less of heating fuel (wood, coal, or other heating fuel not kept in a tank) and the fuel supplier will not deliver additional fuel without payment.'
  },
  {
    value: 'evictionNotice',
    label: 'I have received an eviction notice which is partly due to my failure to pay my heating and/or electricity expenses to my landlord.'
  },
  {
    value: 'depositAssistanceNeeded',
    label: 'I need assistance to pay a deposit to have my utility connected/reconnected.'
  }
]
