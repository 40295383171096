import React from 'react'
import { Container, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FormRadioAsButtons from '../../../form/FormRadioAsButtons';
import FormCheckboxes from '../../../form/FormCheckboxes';
import StepHeader from '../../../StepHeader';
import StepButtons from '../../../StepButtons';
import FormSection from '../../../FormSection';
import useLiheapForm from '../../../../hooks/useLiheapForm';
import { proofOfCitizenshipOptions } from '../../../../constants/tx/radios/proofOfCitizenshipOptions';
import { governmentIssuedPhotoIDOptions } from '../../../../constants/tx/radios/governmentIssuedPhotoIDOptions';
import { otherFormOfIdentificationOptions } from '../../../../constants/tx/checkboxes/otherFormOfIdentificationOptions';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    marginLeft: "0"
  },
  sectionHeader: {
    fontWeight: "bold"
  },
}));

export default function ApplicantDocumentInfo({ data, back, next, save, validate, validationSchema }) {
  const { control, errors, register, setValue, watch } = useLiheapForm(save, data, validate, validationSchema);

  const classes = useStyles();

  const proofOfCitizenshipWatch = watch('proofOfCitizenship');
  const governmentIssuedPhotoIDWatch = watch('governmentIssuedPhotoID');

  const proofOfCitizenshipHandler = (val) => {
    if (val !== 'noneOfTheAbovePOC'){
      setValue('governmentIssuedPhotoID', '', { shouldDirty: true });
      setValue('otherFormOfIdentification', [], { shouldDirty: true });
    }
  }

  const governmentIssuedPhotoIDHandler = (val) => {
    if (val !== 'noneOfTheAboveGovId'){
      setValue('otherFormOfIdentification', [], { shouldDirty: true });
    }
  }

  return (
    <Container className={classes.mainContainer} component="main">
        <Grid container spacing={1}>
          <StepHeader
            title="Applicant Documents"
            desc="Please provide the following information for the applicant."
          />
          <FormSection>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.sectionHeader}>Proof of Citizenship</Typography>
            </Grid>
            <Grid item xs={12} container justifyContent="flex-start">
              <FormRadioAsButtons 
                name="proofOfCitizenship" 
                label="Do you have any of the following documents?"
                control={control}
                errors={errors}
                options={proofOfCitizenshipOptions}
                onSelect={proofOfCitizenshipHandler}
              />
            </Grid>
          </FormSection>
          {proofOfCitizenshipWatch === 'noneOfTheAbovePOC' &&
          <>
            <FormSection>
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.sectionHeader}>Government Issued Photo ID</Typography>
              </Grid>
              <Grid item xs={12} container justifyContent="flex-start">
                <FormRadioAsButtons 
                  name="governmentIssuedPhotoID" 
                  label="You will have to provide a birth certificate as well as one of the documents below. Do you have any of the following documents?"
                  control={control}
                  errors={errors}
                  options={governmentIssuedPhotoIDOptions}
                  onSelect={governmentIssuedPhotoIDHandler}
                />
              </Grid>
            </FormSection>
            {governmentIssuedPhotoIDWatch === 'noneOfTheAboveGovId' &&
             <FormSection>
                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.sectionHeader}>Other Form of Identification</Typography>
                </Grid>
                <Grid item xs={12} container justifyContent="flex-start">
                  <FormCheckboxes
                    defaultValues={data?.otherFormOfIdentification}
                    register={register}
                    name="otherFormOfIdentification"
                    label="You will have to provide a birth certificate as well as two of the documents below. Please select two of the following documents you can provide."
                    errors={errors}
                    options={otherFormOfIdentificationOptions}
                    gridSize={12}
                  />
                </Grid>
              </FormSection>
            }
          </>
          }
          <StepButtons back={back} next={next}/>
        </Grid>
    </Container>
  );
}
