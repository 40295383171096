export const urgentOptions = [
  {
    value: 'confirmed',
    label: 'Confirmed'
  },
  {
    value: 'no',
    label: 'No'
  },
  {
    value: 'removed',
    label: 'Removed'
  },
  {
    value: 'yes',
    label: 'Yes'
  },
]
