
import React from 'react';
import { Grid, Container, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ExpandMore } from '@mui/icons-material';
import StepHeader from '../components/StepHeader';
import FormSection from '../components/FormSection';
import AgencyInfo from '../components/liheap/AgencyInfo';
import { documentDescriptions, documentLinks } from '../constants/tx/additionalDocumentInfo';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    margin: 'auto',
    padding: "2.8rem",
    width: "95%",
    [theme.breakpoints.down('lg')]: {
      padding: '2rem 1rem 0rem 1rem'
    }
  },
  header: {
    fontWeight: '400',
    margin: '1rem 0',
  },
  forms:{
    alignItems: 'center',
    margin: '1rem 0'
  },
  formName:{
      fontWeight: 500
  },
  formDetails: {
      margin: '0 2rem 1rem 2rem',
  },
  text: {
      marginBottom: '1rem',
      fontWeight: 500
  },
  accordion: {
    width: '100%',
    },
  expandIcon: {
    color: theme.palette.primary.main,
  },
  linkText: {
    color: theme.palette.primary.main,
    fontWeight: 500
  }
}));

export default function ApplicantResources() {
  const classes = useStyles();

  const renderDetails = (linkUrl, linkLabel, desc, required) => {
      return(
        <Grid item xs={12} className={classes.formDetails}>
            <Typography className={classes.text}>
              Document link: <a className={classes.linkText} href={linkUrl} target="_blank" rel="noreferrer">{linkLabel}</a>
            </Typography> 
            <Typography className={classes.text}>
             {desc}
            </Typography> 
            {required && 
              <Typography className={classes.text}>
                Required document for all applicants
              </Typography> 
            }
        </Grid>
      )
  }
  
  return (
    <Container className={classes.mainContainer}>
      <Grid container spacing={1}>
        <StepHeader 
          title="Applicant Resources"
          desc="Your application may require additional forms. Required forms are listed below."
        />
        <FormSection>
          <Typography variant='h5' className={classes.header}>
            Forms You May Need
          </Typography>
          <Grid container className={classes.forms} >
            <Accordion className={classes.accordion}>
                <AccordionSummary expandIcon={<ExpandMore className={classes.expandIcon}/>}>
                    <Grid item xs={12}>
                        <Typography className={classes.formName}>
                            SAVE Form
                        </Typography> 
                    </Grid>
                </AccordionSummary>
                <AccordionDetails >
                    {renderDetails(
                      documentLinks['saveForm'], 
                      'SAVE Form PDF', 
                      documentDescriptions['saveForm'],
                      true
                      )}
                </AccordionDetails>
            </Accordion>
            <Accordion className={classes.accordion}>
                <AccordionSummary expandIcon={<ExpandMore className={classes.expandIcon} />}>
                    <Grid item xs={12}>
                        <Typography className={classes.formName}>
                          Zero Income
                        </Typography> 
                    </Grid>
                </AccordionSummary>
                <AccordionDetails >
                    {renderDetails(
                      documentLinks['zeroIncome'], 
                      'Zero Income PDF', 
                      documentDescriptions['zeroIncome'],
                      false
                    )}
                </AccordionDetails>
            </Accordion>
            <Accordion className={classes.accordion}>
                <AccordionSummary expandIcon={<ExpandMore className={classes.expandIcon} />}>
                    <Grid item xs={12}>
                        <Typography className={classes.formName}>
                          Declaration of Income Statement
                        </Typography> 
                    </Grid>
                </AccordionSummary>
                <AccordionDetails >
                    {renderDetails(
                      documentLinks['declarationIncomeStatement'], 
                      'Declaration of Income Statement PDF', 
                      documentDescriptions['declarationIncomeStatement'],
                      false
                    )}
                </AccordionDetails>
            </Accordion>
            <Accordion className={classes.accordion}>
                <AccordionSummary expandIcon={<ExpandMore className={classes.expandIcon} />}>
                    <Grid item xs={12}>
                        <Typography className={classes.formName}>
                          Landlord Agreement
                        </Typography> 
                    </Grid>
                </AccordionSummary>
                <AccordionDetails >
                    {renderDetails(
                      documentLinks['landlordAgreement'],
                      'Landlord Agreement PDF', 
                      documentDescriptions['landlordAgreement'],
                      false
                    )}
                </AccordionDetails>
            </Accordion>
          </Grid>

        </FormSection>
        <FormSection>
            <Grid justifyContent="center">
              <AgencyInfo/>
            </Grid>
        </FormSection>
      </Grid>
    </Container>
  );
}
