/**
 * Returns the applicant's full name (first and last)
 */  
  const getFullName = (params) => {
    return "{1}, {0}"
      .replace("{0}", params.row.firstName)
      .replace("{1}", params.row.lastName);
  }

/**
 * Sort comparator used for 'Client' column
 */  
  const getSortComparatorFullName = (v1, v2) => {
    return (v1).localeCompare(v2);
  }

/**
 * Sort comparator used for 'SSN' column
 */  
  const getSortComparatorSsn = (v1, v2) => {
    let v1Sub = v1 ? v1.substring(5, 10) : v1
    let v2Sub = v2 ? v2.substring(5, 10) : v2
    return (v1Sub).localeCompare(v2Sub, 'en', {numeric: true});
  }

/**
 * Sort comparator used for 'Assignee' column
 */  
 const getSortComparatorAssignee= (v1, v2) => {
   const value1 = v1?.assigneeLastName && v1?.assigneeFirstName ? `${v1.assigneeLastName}, ${v1.assigneeFirstName}` : "Unassigned";
   const value2 = v2?.assigneeLastName && v2?.assigneeFirstName? `${v2.assigneeLastName}, ${v2.assigneeFirstName}`  : "Unassigned";
  return (value1).localeCompare(value2);
}

/**
 * Returns the given date in the format of MM/DD/YYYY HH:MM AM/PM
 */
  const getFormattedDate = (val) => {
    const options = {
      year: "numeric",
      month:"2-digit",
      day:"2-digit",
      hour:  "2-digit",
      minute: "2-digit",
   };
   return new Date(val).toLocaleDateString("en-US", options).replace(', ','\n');
  }

/**
 * Returns the applicant's street address with apartment number if given 
 */  
   const getAddress = (params) => {
    if(params.row.serviceApartmentNumber){
      return "{0}, {1}"
      .replace("{0}", params.row.serviceStreetAddress)
      .replace("{1}", params.row.serviceApartmentNumber);
    }
    return params.row.serviceStreetAddress;
  }

/**
 * Returns the application's pledge amount if it has been approved
 */    
  const getPledgeAmount = (params) => {
    return params.row.statusInfo?.pledgeAmount ? params.row.statusInfo?.pledgeAmount : ''
  }

/**
 * Sort comparator used for 'Amount' column
 */  
  const getSortComparatorNumbers = (v1, v2) => {
    return (v1).localeCompare(v2, 'en', {numeric: true});
  }

/**
 * Get the permission option in the user's set permissions of the given type
 */  
  const getPermissionOption = (userPermissions, type, defaultValue, permissionOptions) => {
    let permission = defaultValue;
    let optionValue = {};
    for (const i in userPermissions) {
      if (userPermissions[i].startsWith(type)){
        permission = userPermissions[i];        
        break;
      }
    }

    for (const j in permissionOptions){
      if (permission === permissionOptions[j].value){
        optionValue = permissionOptions[j];
      }
    }
    return optionValue;
  }

  const getDuplicateStatus = (params) => {
    return params.value ? params.value?.status : 'no'
  }

  export {
    getFullName,
    getAddress,
    getSortComparatorFullName,
    getFormattedDate,
    getPledgeAmount,
    getSortComparatorNumbers,
    getSortComparatorSsn,
    getSortComparatorAssignee,
    getPermissionOption,
    getDuplicateStatus
  }
  