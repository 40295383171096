export const raceOptions = [
  {
    value: 'asianPacificIslander',
    label: 'Asian or Pacific Islander'
  },
  {
    value: 'africanAmerican',
    label: 'Black or African-American'
  },
  {
    value: 'americanIndian',
    label: 'Native American or Alaskan Native'
  },
  {
    value: 'spanishAmerican',
    label: 'Spanish American/Hispanic'
  },
  {
    value: 'white',
    label: 'White or Caucasian'
  },
  {
    value: 'multiRace',
    label: 'Multi-race'
  },
  {
    value: 'other',
    label: 'Other'
  }
]
