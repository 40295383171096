import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react'
import FormDatePicker from '../../form/FormDatePicker'
import FormTextField from '../../form/FormTextField'
import FormSection from '../../FormSection'
import FormRadioAsButtons from '../../form/FormRadioAsButtons'
import { yesNoOptions } from '../../../constants/radios/yesNoOptions'

const useStyles = makeStyles((theme) => ({
  inputField: {
    maxWidth: "40rem",
    margin: "2rem 0rem 1.5rem 0rem"
  }
}));

export default function PreviousEmployment({ control, register, errors, type, setValue, data, trigger }) {
  const classes = useStyles();
  const [hasBeenEmployed, setHasBeenEmployed] = useState(data?.hasBeenEmployed);

  const handleRadioSelect = (val) => {
    if(val === 'no'){
      setValue("lastEmployedDate", null, { shouldDirty: true });
      setValue("lastEmployerName", '', { shouldDirty: true });
    }
    setHasBeenEmployed(val)
  }

  return (
    <>
    <FormSection>
      <FormRadioAsButtons
        control={control}
        name="hasBeenEmployed"
        label={`${type === 'applicant' ? "Have you" : "Has this household member"} ever been employed?`}
        errors={errors}
        options={yesNoOptions}
        onSelect={handleRadioSelect}
      />     
    </FormSection>
    {hasBeenEmployed === 'yes' && 
      <FormSection>
        <Grid item xs={12} className={classes.inputField}>
          <FormDatePicker 
            control={control}
            name="lastEmployedDate"
            labelPlacement="top"
            label={`When ${type === 'applicant' ? "were you" : "was this household member"} last employed?`}
            defaultValue={data?.lastEmployedDate}
            InputAdornmentProps={{ position: "end" }}
            id="lastEmployedDate-date-picker"
            errors={errors}
            variant='outlined'
            userType='applicant'
            validationTrigger={trigger}
          />
        </Grid>
        <Grid item xs={12} className={classes.inputField}>
          <FormTextField
            name="lastEmployerName" 
            label={`Name of ${type === 'applicant' ? "your" : "this household member's"} former employer`}
            labelPlacement="top" 
            register={register} 
            errors={errors}                
          />
        </Grid>
      </FormSection>
    }
    </>
  )
}
