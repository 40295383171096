export const capcaCountyOptions = [
  {
    value: 'Cleburne',
    label: 'Cleburne'
  },
  {
    value: 'Faulkner',
    label: 'Faulkner'
  },{
    value: 'White',
    label: 'White'
  },
];

