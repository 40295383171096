import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add, DeleteOutlined } from '@mui/icons-material';
import React, { useEffect, useState } from 'react'
import { useFieldArray } from 'react-hook-form';
import { v4 as uuidv4} from 'uuid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ConfirmationDialog from '../../ConfirmationDialog';
import FormTextField from '../../form/FormTextField';
import { payFrequency as payFrequencyOptions } from '../../../constants/dropdowns/payFrequency';
import { employerSchema } from '../../../constants/ar/stepValidationSchemas';
import FormRadioAsButtons from '../../form/FormRadioAsButtons';
import FormCurrencyField from '../../form/FormCurrencyField';

const useStyles = makeStyles((theme) => ({
  section: {
    backgroundColor: theme.customColors.formSection.backgroundColor,
    marginBottom: ".6rem",
    border: `.1rem solid ${theme.customColors.lightGrey}`,
    borderRadius: ".3rem",
    padding: "1rem 2.8rem"
  },
  details:{
    padding: "0",
    display: "block"
  },
  summary:{
    padding: "0"
  },
  inputField: {
    maxWidth: "30rem",
    margin: "2rem 0rem 1.5rem 0rem"
  },
  addEmployerGrid: {
    margin: "2.8rem"
  },
  addEmployerText:{
    fontWeight: "bold",
    marginBottom: "1.3rem"
  },
  employerNumber:{
    fontWeight: "bold"
  },
  completed:{
    color: "green"
  },
  incompleted:{
    color: theme.palette.error.main
  },
  remove:{
    fontWeight: "bold"
  },
}));

export default function Employers({ employersData, control, register, errors, parentType, watch }) {
  const classes = useStyles();
  const [removeEmployerOpen, setRemoveEmployerOpen] = useState(false);
  const [employerToRemove, setEmployerToRemove] = useState({});
  const [employerToRemoveIndex, setEmployerToRemoveIndex] = useState();
  const [expanded, setExpanded] = useState(false);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "employers",
    keyName: "key" 
  });

  const handleRemoveIcon = (employer, index) => {
    setEmployerToRemove(employer);
    setEmployerToRemoveIndex(index);
    setRemoveEmployerOpen(true);
  }

  const addEmployer = () => {
    const newId = uuidv4();
    append({ "id": newId});
    setExpanded(newId);
  }

  const removeEmployer = () => {
    remove(employerToRemoveIndex);
    setRemoveEmployerOpen(false);
  }

  const handleExpandedChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const isExpanded = (employerId) => {
    if(fields.length === 1) {
      return true;
    }
    return expanded === employerId;
  }

  const showSummary = (employerFields) => {
    return employerFields?.length > 1;
  }

  useEffect(() => {
    if(!employersData || employersData?.length === 0){
      addEmployer()
    }
    // eslint-disable-next-line
  }, [employersData]);

  const renderEmployer = (employer, index) => {
    const watchedEmployerFields =  watch('employers')

    const isComplete = (index) => {
      return employerSchema.isValidSync(watchedEmployerFields[index]);
    };

    return (
      <Accordion 
        key={index}
        className={classes.section} 
        expanded={isExpanded(employer.id)} 
        onChange={handleExpandedChange(employer.id)}
      >
        {showSummary(fields) &&
          <AccordionSummary
            className={classes.summary}
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${employer.id}-content`}
            id={`${employer.id}-header`}
          >
            <Grid container direction="column">
              <Typography variant="h5">{`Employer #${index + 1}`}</Typography>
              {!isExpanded(employer.id) && 
              <>
                <Typography variant="subtitle2">{watchedEmployerFields[index].name ? watchedEmployerFields[index].name : ""}</Typography>              
                <Typography 
                  variant="subtitle2"
                  className={isComplete(index) ? classes.completed : classes.incompleted}>
                    {isComplete(index) ? 'Completed' : 'Incomplete'}
                </Typography>
              </>
              }
            </Grid>
          </AccordionSummary>
        }
        {(showSummary(fields) && isExpanded(employer.id)) &&
          <Divider/>
        }
        <AccordionDetails className={classes.details}>
            <input type="hidden" name={`employers[${index}].id`}  ref={register} defaultValue={employer.id}/>
            <Grid item className={classes.inputField} xs={12} sm={6}>
              <FormTextField
                name={`employers[${index}].name`} 
                defaultValue={employer.name}
                label={`What is the name of ${parentType === "applicant" ? "your" : "this household member's"} employer?`} 
                labelPlacement="top" 
                register={register} 
                errors={errors}                
              />
            </Grid>
            <Grid item className={classes.inputField} xs={12} sm={6}>
              <FormCurrencyField
                name={`employers[${index}].monthlyIncomeAmount`}  
                defaultValue={employer.monthlyIncomeAmount}
                label={`What is ${parentType === "applicant" ? "your" : "this household member's"} average monthly gross pay?`} 
                labelPlacement="top"
                control={control}
                errors={errors}
              />
            </Grid>
            <Grid item xs={12}>
              <FormRadioAsButtons
                control={control}
                name={`employers[${index}].payFrequency`} 
                defaultValue={employer.payFrequency}
                label={`How often ${parentType === "applicant" ? "are you" : "is this household member"} paid by ${parentType === "applicant" ? "your" : "their"} employer?`}
                errors={errors}
                options={payFrequencyOptions}
              />
            </Grid>            
        </AccordionDetails>
        <AccordionActions>
          {showSummary(fields) &&
            <Button
              className={classes.remove}
              color="primary"
              onClick={() => handleRemoveIcon(employer, index)}
              variant="outlined"
              startIcon={<DeleteOutlined />}
            >
              Remove
            </Button>
          }
        </AccordionActions>
      </Accordion>      
    )
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {
          fields.map((employer, i) => (
            renderEmployer(employer, i)
          ))
        }
      </Grid>
      <Grid item container xs={12} direction="column" alignItems="flex-start" className={classes.addEmployerGrid}>
        <Typography className={classes.addEmployerText}>{`${parentType === "applicant" ? "Do you" : "Does this household member"} have multiple jobs? You can add additional employers.`}</Typography>            
        <Button color="primary" onClick={() => addEmployer()} variant="outlined" startIcon={<Add />}>
          Add Employer
        </Button>       
      </Grid>
      <ConfirmationDialog 
        open={removeEmployerOpen}
        title="Remove Employer"
        desc={`Are you sure you want to remove ${employerToRemove.name ? employerToRemove.name : `Employer #${employerToRemoveIndex + 1}`}?`}
        handleCancel={() => setRemoveEmployerOpen(false)}
        handleOk={() => removeEmployer()}
      />
    </Grid>
  )
}
