import { agencyPermissions } from "../agencyPermissions";

export const reportPermissionOptions = [
  {
    value: agencyPermissions.REPORTS_VIEW,
    label: 'View Only',
    color: '#006974'
  },
  {
    value: agencyPermissions.REPORTS_NONE,
    label: 'No Access',
    color: '#BA1B1B'
  }
];