import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/system';

export const StateReportsStyledDataGrid = styled(DataGrid)(({theme}) => ({
    '& .MuiDataGrid-columnHeaderTitle': {
      whiteSpace: 'normal',
      lineHeight: 'normal'
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      overflow: 'visible',
    },
    '& .MuiDataGrid-columnHeaderTitleContainerContent':{
      cursor: 'pointer',
      height: '4rem'
    },
    '& .MuiDataGrid-columnHeader': {
      // Forced to use important since overriding inline styles
      height: 'unset !important',
      display: 'grid',
      cursor: 'default'
    },
    '& .MuiDataGrid-columnHeaders': {
      // Forced to use important since overriding inline styles
      maxHeight: '5rem !important',
      backgroundColor: '#FDFDFD'
    },
    '& .MuiDataGrid-columnSeparator': {
      visibility: 'hidden'
    }
  }
));
