export const arvacCountyOptions = [
    {
        value: 'Conway',
        label: 'Conway'
    },
    {
        value: 'Logan',
        label: 'Logan'
    },
    {
        value: 'Perry',
        label: 'Perry'
    },
    {
        value: 'Pope',
        label: 'Pope'
    },
    {
        value: 'Scott',
        label: 'Scott'
    },
    {
        value: 'Yell',
        label: 'Yell'
    }
  ];
  
