import React, { useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Button, Checkbox, Chip, FormControlLabel, Grid, Typography } from '@mui/material';
import FormDatePicker from '../../../../form/FormDatePicker';
import FormCurrencyField from '../../../../form/FormCurrencyField';
import { Add } from '@mui/icons-material';
import { paymentTypeOptions } from '../../../../../constants/paymentTypeOptions';
import FormRadioAsButtons from '../../../../form/FormRadioAsButtons';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { ApprovedPaymentsValidationSchema } from '../../../../../constants/sharedAgencyValidationSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { AuthContext } from '../../../../../context/authContext';
import FormSelect from '../../../../form/FormSelect';
import { isSupportedPledgeProvider } from '../../../../../utils/finaUtils';
import { pledgePaymentTypeOptions } from '../../../../../constants/pledgePaymentTypeOptions';
import { ConfigContext } from '../../../../../context/configContext';
import { isFeatureDisabled } from '../../../../../constants/features';
import CheckedIcon from '../../../../CheckedIcon';

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: "bold", 
    marginBottom: ".6rem"
  },
  outlinedInput: {
    margin:0,
    backgroundColor: '#FFF',    
    "& > .MuiFormHelperText-root.Mui-error": { 
      margin:0
    },
  },
  paymentChip: {
    borderRadius:0,
    background: theme.customColors.modalBackground,
    height: 'fit-content',
    '& > .MuiChip-label': {
      whiteSpace: 'normal',
      overflowWrap: 'break-word'
    }
  }
}));

export default function ApprovedStatusInfo({ application, control, register, errors, loading, trigger, fundSourceOptions }) {
  const classes = useStyles();
  const { clientConfig } = useContext(ConfigContext);
  const { userInfo } = useContext(AuthContext);
  const providers = userInfo?.agencyInfo?.providers;
  const fundSourceNames = fundSourceOptions?.map((item)=>item.fundName)

  const { fields, append, remove } = useFieldArray({
    control,
    name: "approvedPayments",
    keyName: "key" 
  });

  const { control:paymentControl, errors:paymentErrors, getValues, setValue, watch} = useForm({ 
    mode: 'onChange',
    resolver: yupResolver(ApprovedPaymentsValidationSchema)
  })

  const addPaymentEnabled = () => {
    const valid = ApprovedPaymentsValidationSchema.isValidSync(watch());
    return valid;
  }

  const addPayment = async() => {
    const newPayment = {
      "amount": getValues("amount"),
      "provider": getValues("provider"),
      "type": getValues("type"), 
      "source": getValues("source"), 
      "finaPledgeOptOut": getValues("finaPledgeOptOut")
    }
    append(newPayment);
    // reset fields
    setValue("amount", "");
    setValue("provider", "");
    setValue("type", "");
    setValue("source", "");
    setValue("finaPledgeOptOut", "no");
  }

  const removePayment = (paymentToRemoveIndex) => {
    remove(paymentToRemoveIndex);
  }

  const showSupportedPledgeProviderOptOut = () => {
    if (!isFeatureDisabled(clientConfig, application.agencyId, "FINACreatePledge")){
      const provider = getValues("provider");
      return isSupportedPledgeProvider(provider)
    }
    return false
  }

  const getTypeOptions = () => {
    const provider = getValues("provider");
    if (isSupportedPledgeProvider(provider)){
      return pledgePaymentTypeOptions
    }
    return paymentTypeOptions    
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} >
        <Typography variant="subtitle2" className={classes.label}>Amount #{fields.length ? fields.length + 1: "1"}</Typography>
        <FormCurrencyField 
            className={classes.outlinedInput}
            name="amount" 
            aria-label="Amount"
            label=""
            agency={true}
            defaultValue=""
            disabled={loading} 
            control={paymentControl} 
            errors={paymentErrors}
        />
      </Grid>        
      <Grid item xs={12}>
        <Typography variant="subtitle2" className={classes.label}>Provider</Typography>
        <FormSelect
          name="provider"
          label="Provider"
          labelPlacement="inside"
          control={paymentControl}
          errors={paymentErrors}
          options={providers}
          disabled={loading}
        />     
      </Grid>
      { showSupportedPledgeProviderOptOut() &&       
        <Grid item xs={12}>
          <FormControlLabel
            label={"Do not post a pledge"}          
            control={
              <Controller
                name="finaPledgeOptOut"
                defaultValue={"no"}
                control={paymentControl}
                render={({ onChange, value }) => (
                  <Checkbox 
                    color="primary" 
                    checked={value === "yes"}
                    onChange={(e) => onChange(e.target.checked === true ? "yes" : "no")} 
                    checkedIcon={<CheckedIcon /> }
                  />
                )}                               
              />
            }
          />
        </Grid>
      }
      <Grid item xs={12}>
        <FormRadioAsButtons
          label={"Type"}
          labelVariant="subtitle2"
          control={paymentControl}
          name="type"
          errors={paymentErrors}
          options={getTypeOptions()}
          defaultValue=""
        />
      </Grid>  
      <Grid item xs={12}>
        <FormRadioAsButtons
          label={"Fund Source"}
          labelVariant="subtitle2"
          control={paymentControl}
          name="source"
          errors={paymentErrors}
          options={(fundSourceNames.includes(undefined)) ? fundSourceOptions : fundSourceNames}
          defaultValue=""
        />
      </Grid>  
      <Grid item container xs={12} justifyContent="flex-end">          
        <Button 
          color="primary" 
          onClick={() => addPayment()}
          variant="outlined" 
          startIcon={<Add />}
          disabled={!addPaymentEnabled()}
        >
            Add Payment
        </Button>       
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2" className={classes.label}>Date</Typography>
        <FormDatePicker
            className={classes.outlinedInput}
            name="pledgeDate"
            defaultValue={application?.statusInfo?.pledgeDate}
            disabled={loading} 
            InputAdornmentProps={{ position: "end" }}
            id="pledgeDate-date-picker"
            control={control}
            errors={errors}
            variant='outlined'
            userType='agent'
            validationTrigger={trigger}
        />
      </Grid>
      {fields.map((payment, i) => (
        <Grid item xs={12} key={`${payment.key}`}>
          {/* hidden fields so that payments are registered for validation */}
          <input type="hidden" name={`approvedPayments[${i}].amount`}  ref={register()} defaultValue={payment.amount}/>
          <input type="hidden" name={`approvedPayments[${i}].type`}  ref={register()} defaultValue={payment.type}/>
          <input type="hidden" name={`approvedPayments[${i}].source`}  ref={register()} defaultValue={payment.source}/>
          <input type="hidden" name={`approvedPayments[${i}].provider`}  ref={register()} defaultValue={payment.provider}/>            
          <input type="hidden" name={`approvedPayments[${i}].finaPledgeOptOut`}  ref={register()} defaultValue={payment.finaPledgeOptOut}/>                   
          <Chip 
            className={classes.paymentChip}
            size="small"
            label={`Amount #${i+1}: $ ${payment.amount}. Provider: ${payment.provider}. ${payment.finaPledgeOptOut === "yes" ? "Pledge not posted.": ""} Type: ${payment.type}. Fund Source: ${payment.source}.`} 
            onDelete={() => removePayment(i)} 
          />
        </Grid>
      ))}
    </Grid>
)}
