import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Input,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ExpandMore, WarningRounded } from '@mui/icons-material'
import React, {useEffect, useState} from 'react'
import { useHistory } from 'react-router';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { formatDate } from '../../../utils/utilFunctions';

const useStyles = makeStyles((theme) => ({
  expandIcon: {
    color: theme.palette.primary.main,
    padding: '.75rem',
    [theme.breakpoints.down('md')]: {
      marginLeft: '1rem'
    }
  },
  summary: {
    alignItems: 'center',
    color: '#000',
    display: 'flex'
  },
  details: {
    padding: '0 1rem'
  },
  fieldLabel:{
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
    padding: '.75rem 0',
    minHeight: '1.5rem',
    marginRight: '1rem'
  },
  fieldValue:{
    display: 'flex',
    alignItems: 'center',
    overflow: 'auto',
    color: '#000'
  },
  errorField: {
    color: theme.customColors.errorIcon,
    overflow: 'auto'
  },
  errorIcon: {
    marginLeft: '1.5rem',
    color: theme.customColors.errorIcon,
    height: '1.5rem',
  },
  ssnIcon: {
    marginLeft: '.5rem',
    padding: '.75rem',
    height: '3rem',
    width: '3rem'
  },
  ssnField: {
    maxWidth: '5.4rem',
    maxHeight: '1.5rem',
    overflow: 'hidden',
    padding: '1.5rem 0'
  }
}));


export default function ReviewSubSection({ info, setValidate, showSSN, setShowSSN, openAll }) {
  const history = useHistory();
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
      setExpanded(openAll)
    // eslint-disable-next-line
  }, [openAll]);

  const getShowSSN = (fieldId) => {
    return showSSN.filter((el) => el.id === fieldId)[0].visible
  }

  const handleSSNClick = (fieldId) => {
    setShowSSN((prevState) => {
      const visible = prevState.filter((el) => el.id === fieldId)[0].visible
      const newState = prevState.filter((el) => el.id !== fieldId)
      newState.push({id: fieldId, visible: !visible})
      return newState
    })
  }

  const handleMouseDownSSN = (event) => {
    event.preventDefault();
  };

  const editOnClick = (field) => {
    if (!field.isValid) {
      setValidate(true);
    }
    history.push(field.link);
  }

  const setExpandedValue = () => {
    setExpanded(prevState => {return !prevState})
  }

  return (
    <Accordion expanded={expanded}>
      <AccordionSummary
        expandIcon={<ExpandMore className={classes.expandIcon} />}
        aria-label={`${info.title}-content`}
        id={`${info.title}-header`}
        onClick={setExpandedValue}
      >
        <Grid className={classes.summary} item xs={12}>
        <Grid item xs={11}>
        <Typography variant='subtitle2'>{info.title}</Typography> 
        </Grid>

        <Grid item xs={1}>
          {!info.isValid && <WarningRounded className={classes.errorIcon} />}
          </Grid>

          </Grid>
      </AccordionSummary>
      {info?.fields.map((field, index) => (
      <AccordionDetails className={classes.details} key={index}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.fieldLabel} variant='subtitle2' onClick={() => editOnClick(field)}>
              <label htmlFor={`${field.label}-id`}>
              {field.label}
              </label>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.fieldValue}>
            { field.label === 'Social Security Number' && field.value ?
              <>
                <Input 
                  readOnly 
                  disableUnderline 
                  id={`${field.label}-id`}
                  aria-label={`${field.label}`}
                  type={getShowSSN(field.appOrMemberId) ? 'text' : 'password'} 
                  value={field.value} 
                  className={!field.isValid ? `${classes.errorField} ${classes.ssnField}` : classes.ssnField}
                /> 
                  <IconButton
                    aria-label="toggle SSN visibility"
                    onClick={() => handleSSNClick(field.appOrMemberId)}
                    onMouseDown={handleMouseDownSSN}
                    edge="end"
                    className={!field.isValid ? `${classes.errorField} ${classes.ssnIcon}` : classes.ssnIcon}
                    size="large">
                    {getShowSSN(field.appOrMemberId) ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </>
              : 
              <>
              {(field.label === 'Birth Date' || field.label === 'Last Employed Date') ?
              <Typography variant='subtitle2' className={field.isValid ? classes.fieldValue : classes.errorField}> 
                  {field.value ? formatDate(field.value) : 'No answer'}
                </Typography>
              :
                <Typography variant='subtitle2' className={field.isValid ? classes.fieldValue : classes.errorField}> 
                  {field.value ? field.value.toString() : 'No answer'}
                </Typography>
            }
            </>
            }
            {!field.isValid && <WarningRounded className={classes.errorIcon} />}
          </Grid>
        </Grid>
      </AccordionDetails>
      ))}
    </Accordion>
  );
}
