import styled from "styled-components";

export const StyledButton = styled("button")<any>`
  background: ${(p) => p.color || "#3576CB"};
  color: ${(p) => (p.color ? "#3576CB" : "#fff")};
  font-size: 1rem;
  font-weight: 600;
  width: 100%;
  border: 1px solid #3576CB;
  border-radius: 100px;
  height: 48px;
  cursor: pointer;
  margin-top: 0.625rem;
  max-width: 180px;
  transition: all 0.3s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    background: #fff;
    color: #3576CB;
    border: .125rem solid #3576CB;
  }
`;
