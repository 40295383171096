import { Container, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import StepButtons from '../../../StepButtons'
import StepHeader from '../../../StepHeader'
import Documents from '../Documents';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    margin: "0",
  },
  list: {
    padding: "0",
    width: "100%"
  }   
}));

export default function UtilityDocumentUpload({ data: appData, back, next, appId, fileData, handleFileCreate, handleFileDelete }) {
  const classes = useStyles();
  
  return (
    <Container className={classes.mainContainer}>
      <Grid container spacing={1}>
        <StepHeader 
          title="Utility Documents" 
          desc = "Please provide the following information for the applicant."
        />
        <Documents
          appId={appId} 
          appData={appData} 
          type='utility'
          fileData={fileData}
          handleFileCreate={handleFileCreate}
          handleFileDelete={handleFileDelete} />
        <Grid item xs={12}>
          <StepButtons back={back} next={next}/>
        </Grid>
      </Grid>      
    </Container>
  )
}
