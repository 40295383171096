export const submittingAsOptions = [
    {
      value: 'myself',
      label: 'Myself'
    },
    {
      value: 'someoneElse',
      label: 'Someone Else'
    }
  ]
  