export const payFrequency = [
    {
        "label" : "Weekly",
        "value" : "weekly",
    },
    {
        "label" : "Bi-weekly",
        "value" : "biWeekly",
    },
    {
        "label" : "Semi-monthly",
        "value" : "semiMonthly",
    },
    {
        "label" : "Monthly",
        "value" : "monthly",
    }
]