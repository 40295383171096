import { useEffect, useState, useContext } from 'react'
import { Grid, Typography, Button, IconButton, CircularProgress, Modal } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation } from 'react-query';
import { updateApplicationDuplicate } from '../../../api/AgencyAPI'
import { AuthContext } from "../../../context/authContext";
import { useErrorViewer } from "../../../context/errorContext";
import { Close } from '@mui/icons-material';
import { agencyPermissions } from '../../../constants/agencyPermissions';
import { Link } from 'react-router-dom';
import SuccessModal from '../../SuccessModal';

function getModalStyle() {
    const top = 50;
    const left = 50;
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`
    };
  }

const useStyles = makeStyles((theme) => ({
  header: {
    padding: '1rem 0'
  },
  linkInfo: {
    display: 'block',
    margin: '1rem 0 ',
    color: theme.palette.primary.main
  },
  button: {
    textTransform: 'none',
    fontWeight: 600,
    margin: '1rem 0'
  },
  duplicateInfo: {
    overflow: 'scroll'
  },
  spinner: {
    margin: '1rem'
  },
  modal: {
    position: 'absolute',
    background: 'linear-gradient(0deg, rgba(29, 93, 177, 0.11), rgba(29, 93, 177, 0.11)), #FDFBFF',
    boxShadow: '0 0 .3rem rgba(0, 0, 0, 0.2)',
    bozSizing: 'inherit',
    borderRadius: '1.75rem',
    padding: '3rem',
    width: '40rem',
    maxHeight: '35rem',
    overflow: 'scroll'
  },
  modalHeader:{
    marginBottom: '1rem',
    textTransform: 'capitalize'
  },
  modalButton: {
    marginTop: '1rem',
    textTransform: 'none',
    fontWeight: 'bold'
  },
}));

export default function DuplicateInfo({ application, open, handleClose, handleChange }){
  const { userInfo } = useContext(AuthContext);
  const userId = userInfo?.id;
  const classes = useStyles();
  const setError = useErrorViewer();
  const [modalStyle] = useState(getModalStyle);

  const [duplicateInfoOpen, setDuplicateInfoOpen] = useState(open);
  const [changeSucessful, setChangeSucessful] = useState(false);
  const [changeType, setChangeType] = useState('');
  const userPermissions = userInfo?.permissions ? userInfo.permissions : [];

  useEffect(() => {
    setDuplicateInfoOpen(open)
  // eslint-disable-next-line
}, [open]);


  const updateDuplicateMutation = useMutation(newDuplicateStatus => {
    const duplicateObject = {
      applicationId: application.id,
      duplicateStatus: newDuplicateStatus,
      userId: userId
    }
    return updateApplicationDuplicate(duplicateObject);
  })

  const handleUpdateDuplicate = (duplicateVal) => {
    updateDuplicateMutation.mutate(duplicateVal, {
      onSuccess: (data) => {
        handleSuccess(duplicateVal);
      },
      onError: (error) => {
        setError("An Error Occurred Updating The Application's Duplicate")
      }
    })
  }

  const handleSuccess= (duplicateVal) => {
    setDuplicateInfoOpen(false);
    setChangeSucessful(true);
    setChangeType(duplicateVal);
  };

  const handleCloseSuccess = () => {
    handleChange('duplicate');
    setChangeSucessful(false);
  }

  return(
    <>
    <Modal open={duplicateInfoOpen} onClose={() => handleClose('duplicate')}>
      <Grid  style={modalStyle} className={classes.modal}>
        <Grid container item xs={12}>
          <Grid container className={classes.header}>
            <Grid container item xs={10}>
              <Typography variant='h6' >
                {`Duplicates for ${application?.firstName} ${application?.lastName}`}
              </Typography>
            </Grid>
            <Grid container item xs={2} justifyContent='flex-end'>
              <IconButton onClick={() => handleClose('duplicate')}>
                <Close/>
              </IconButton>
            </Grid>
          </Grid>
        
          <Grid item xs={12} container className={classes.duplicateInfo}>
            <Grid item xs={12}>
              <Typography variant='subtitle2' className={classes.infoLabel}>
                {`The following applicants have similar account numbers to ${application?.firstName} ${application?.lastName}:`}
              </Typography>
              <Typography variant='subtitle2' className={classes.infoLabel}>
                {`Account Number: ${application?.duplicateInfo?.electricityAcctNo} Provider: ${application?.duplicateInfo?.electricityProvider}`}
              </Typography>
              {application?.duplicateInfo?.duplicateApps.map((app, index) => (
                <Link to={`/agency/applicationReview/${application?.agencyState}/${app.id}`} className={classes.linkInfo} key={index}>
                  <Typography variant='subtitle2'>{`${app.lastName}, ${app.firstName}`}</Typography>
                  <Typography variant='subtitle2'>{`${app.serviceStreetAddress} ${app?.serviceApartmentNumber}, ${app.serviceCity}, ${app.serviceState} ${app.serviceZip}`}</Typography>
                </Link>
              ))} 
            </Grid>
          </Grid>

          {userPermissions.includes(agencyPermissions.REVIEW_APPLICATION_EDIT) && 
            <Grid item container xs={12} justifyContent='flex-end'>
              {updateDuplicateMutation.isLoading ? 
                <Grid justifyContent="center" className={classes.spinner}>
                  <CircularProgress color="primary" />
                </Grid>
              :
              <>
                <Button variant='text' className={classes.button} onClick={() => handleUpdateDuplicate('removed')}>Remove</Button>
                <Button variant='text' className={classes.button} onClick={() => handleUpdateDuplicate('confirmed')}>Confirm</Button>
              </>
              }
            </Grid>
          }
        </Grid>
      </Grid>
    </Modal>

    <SuccessModal
      open={changeSucessful}
      title={changeType}
      desc={`Duplicate status for ${application?.firstName} ${application?.lastName} ${changeType}.`}
      okLabel="Okay"
      handleOk={handleCloseSuccess}
    />
    </>
  );
}
