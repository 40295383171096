import * as Yup from 'yup';

const requiredError = "Required Information";
const minOf2Error = "Must select 2 options";

export const personalAndContantInfoSchema = Yup.object().shape({
  submittingAs: Yup.string().required(requiredError), 
  preparerName: Yup.string().when('submittingAs', {
    is: (submittingAs) => submittingAs === 'someoneElse',
    then: Yup.string().required(requiredError)
  }), 
  firstName: Yup.string().required(requiredError),
  lastName: Yup.string().required(requiredError),
  ssn: Yup.string().required(requiredError).matches(/^\d{9}$/, "Invalid SSN. Must contain 9 numbers"),
  birthDate: Yup.date().typeError('Invalid Date').required(requiredError).min('1900-01-01', 'Invalid Date').max('2099-12-31', 'Invalid Date'),
  phoneNumber: Yup.string().required(requiredError).length(14, "Invalid Phone Number"),
  email: Yup.string().required(requiredError).email("Invalid Email"),
  serviceStreetAddress: Yup.string().required(requiredError),
  serviceCity: Yup.string().required(requiredError),
  serviceState: Yup.string().required(requiredError),
  serviceZip: Yup.string().required(requiredError).length(5, "Invalid Zip"),
  serviceCountyOrParish: Yup.string().required(requiredError),
  mailingStreetAddress: Yup.string().required(requiredError),
  mailingCity: Yup.string().required(requiredError),
  mailingState: Yup.string().required(requiredError),
  mailingZip: Yup.string().required(requiredError).length(5, "Invalid Zip"),
  mailingCountyOrParish: Yup.string().required(requiredError),
  gender: Yup.string().required(requiredError),
  race: Yup.array().required(requiredError).min(1, requiredError),
  ethnicity: Yup.string().required(requiredError),
  disabled: Yup.string().required(requiredError),
  veteranOrActivelyServing: Yup.string().required(requiredError)
});

export const applicantAdditionalInfoSchema = Yup.object().shape({
  healthInsurance: Yup.array().required(requiredError).min(1, requiredError),
  education: Yup.string().required(requiredError),
  programApplyingFor: Yup.array().required(requiredError).min(1, requiredError),
});

export const memberPersonalInfoSchema = Yup.object().shape({
  firstName: Yup.string().required(requiredError),
  lastName: Yup.string().required(requiredError),
  ssn: Yup.string().required(requiredError).matches(/^\d{9}$/, "Invalid SSN. Must contain 9 numbers"),
  birthDate: Yup.date().typeError('Invalid Date').required(requiredError).min('1900-01-01', 'Invalid Date').max('2099-12-31', 'Invalid Date'),
  relationToApplicant: Yup.string().required(requiredError),
  gender: Yup.string().required(requiredError),
  race: Yup.array().required(requiredError).min(1, requiredError),
  ethnicity: Yup.string().required(requiredError),
  disabled: Yup.string().required(requiredError),
  veteranOrActivelyServing: Yup.string().required(requiredError)
});

export const memberAdditionalInfoSchema = Yup.object().shape({
  healthInsurance: Yup.array().required(requiredError).min(1, requiredError),
  education: Yup.string().required(requiredError)
});

export const birthDateOnEditSchema = Yup.object().shape({
  birthDate: Yup.date().typeError('Invalid Date').required(requiredError).min('1900-01-01', 'Invalid Date').max('2099-12-31', 'Invalid Date'),
});

export const incomeSchema = Yup.object().shape({
  workStatus: Yup.string().required(requiredError),
  unearnedBenefits: Yup.array().required(requiredError).min(1, requiredError),
  alimonyOrSpousalSupportPayFrequency: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("alimonyOrSpousalSupport"),
      then: Yup.string().required(requiredError)
    }),
  alimonyOrSpousalSupportMonthlyIncomeAmount: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("alimonyOrSpousalSupport"),
      then: Yup.string().required(requiredError)
    }),
  disabilityPensionPayFrequency: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("disabilityPension"),
      then: Yup.string().required(requiredError)
    }),
  disabilityPensionMonthlyIncomeAmount: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("disabilityPension"),
      then: Yup.string().required(requiredError)
    }),
  pensionsAndAnnuitiesPayFrequency: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("pensionsAndAnnuities"),
      then: Yup.string().required(requiredError)
    }),
  pensionsAndAnnuitiesMonthlyIncomeAmount: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("pensionsAndAnnuities"),
      then: Yup.string().required(requiredError)
    }),
  privateDisabilityBenefitsPayFrequency: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("privateDisabilityBenefits"),
      then: Yup.string().required(requiredError)
    }),
  privateDisabilityBenefitsMonthlyIncomeAmount: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("privateDisabilityBenefits"),
      then: Yup.string().required(requiredError)
    }),
  publicHousingPayFrequency: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("publicHousing"),
      then: Yup.string().required(requiredError)
    }),
  publicHousingMonthlyIncomeAmount: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("publicHousing"),
      then: Yup.string().required(requiredError)
    }),
  socialSecurityPayFrequency: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("socialSecurity"),
      then: Yup.string().required(requiredError)
    }),
  socialSecurityMonthlyIncomeAmount: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("socialSecurity"),
      then: Yup.string().required(requiredError)
    }),
  ssdiPayFrequency: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("ssdi"),
      then: Yup.string().required(requiredError)
    }),
  ssdiMonthlyIncomeAmount: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("ssdi"),
      then: Yup.string().required(requiredError)
    }),
  ssiPayFrequency: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("ssi"),
      then: Yup.string().required(requiredError)
    }),
  ssiMonthlyIncomeAmount: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("ssi"),
      then: Yup.string().required(requiredError)
    }),
  tanfPayFrequency: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("tanf"),
      then: Yup.string().required(requiredError)
    }),
  tanfMonthlyIncomeAmount: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("tanf"),
      then: Yup.string().required(requiredError)
    }),
  unemploymentInsurancePayFrequency: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("unemploymentInsurance"),
      then: Yup.string().required(requiredError)
    }),
  unemploymentInsuranceMonthlyIncomeAmount: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("unemploymentInsurance"),
      then: Yup.string().required(requiredError)
    }),
  vaNonServiceConnectedPayFrequency: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("vaNonServiceConnected"),
      then: Yup.string().required(requiredError)
    }),
  vaNonServiceConnectedMonthlyIncomeAmount: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("vaNonServiceConnected"),
      then: Yup.string().required(requiredError)
    }),
  workersCompPayFrequency: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("workersComp"),
      then: Yup.string().required(requiredError)
    }),
  workersCompMonthlyIncomeAmount: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("workersComp"),
      then: Yup.string().required(requiredError)
    }),
  otherPayFrequency: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("other"),
      then: Yup.string().required(requiredError)
    }),
  otherMonthlyIncomeAmount: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("other"),
      then: Yup.string().required(requiredError)
    }),
  otherUnearnedDetails: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("other"),
      then: Yup.string().required(requiredError)
    }),
});

export const employerSchema = Yup.object().shape({
  name: Yup.string().required(requiredError),
  payFrequency: Yup.string().required(requiredError),
  monthlyIncomeAmount: Yup.string().required(requiredError)
});

export const addEditEmployerSchema = Yup.object().shape({
  employers: Yup.array()
    .when("workStatus", {
      is: (workStatus) => workStatus === "fullTime" || workStatus === "partTime" || workStatus === "migrantSeasonalOrFarmWorker",
      then: Yup.array().required(requiredError).of(
        employerSchema
      )
    }),
  hasBeenEmployed: Yup.string()
    .when('workStatus', {
      is: (workStatus) => workStatus === "unemployed" || workStatus === "retired",
      then: Yup.string().required(requiredError)
    }),
  lastEmployedDate: Yup.date().nullable()
    .when(['workStatus', 'hasBeenEmployed'], {
      is: (workStatus, hasBeenEmployed) => (workStatus === "unemployed" || workStatus === "retired") && hasBeenEmployed === "yes",
      then: Yup.date().typeError('Invalid Date').required(requiredError).min('1900-01-01', 'Invalid Date').max('2099-12-31', 'Invalid Date')
    }),
  lastEmployerName: Yup.string()
    .when(['workStatus', 'hasBeenEmployed'], {
      is: (workStatus, hasBeenEmployed) => (workStatus === "unemployed" || workStatus === "retired") && hasBeenEmployed === "yes",
      then: Yup.string().required(requiredError)
    })
});

export const memberAddEditEmployerSchema = Yup.object().shape({
  employers: Yup.array()
    .when("workStatus", {
      is: (workStatus) => workStatus === "fullTime" || workStatus === "partTime" || workStatus === "migrantSeasonalOrFarmWorker",
      then: Yup.array().required(requiredError).of(
        employerSchema
      )
    }),
  hasBeenEmployed: Yup.string()
    .when('workStatus', {
      is: (workStatus) => workStatus === "unemployed" || workStatus === "retired",
      then: Yup.string().required(requiredError)
    }),
  lastEmployedDate: Yup.date().nullable()
    .when(['workStatus', 'hasBeenEmployed'], {
      is: (workStatus, hasBeenEmployed) => (workStatus === "unemployed" || workStatus === "retired") && hasBeenEmployed === "yes",
      then: Yup.date().typeError('Invalid Date').required(requiredError).min('1900-01-01', 'Invalid Date').max('2099-12-31', 'Invalid Date')
    }),
  lastEmployerName: Yup.string()
    .when(['workStatus', 'hasBeenEmployed'], {
      is: (workStatus, hasBeenEmployed) => (workStatus === "unemployed" || workStatus === "retired") && hasBeenEmployed === "yes",
      then: Yup.string().required(requiredError)
    })
});

export const lastEmployedDateOnEditSchema = Yup.object().shape({
  lastEmployedDate: Yup.date().typeError('Invalid Date')
    .when(['workStatus', 'hasBeenEmployed'], {
      is: (workStatus, hasBeenEmployed) => (workStatus === "unemployed" || workStatus === "retired") && hasBeenEmployed === "yes",
      then: Yup.date().typeError('Invalid Date').required(requiredError).min('1900-01-01', 'Invalid Date').max('2099-12-31', 'Invalid Date')
    })
});

export const householdMemberSchema = memberPersonalInfoSchema.concat(incomeSchema).concat(memberAddEditEmployerSchema);

export const documentInfoSchema = Yup.object().shape({
  proofOfCitizenship: Yup.string().required(requiredError),
  governmentIssuedPhotoID: Yup.string()
    .when('proofOfCitizenship', {
      is: (proofOfCitizenship) => proofOfCitizenship === 'noneOfTheAbovePOC',
      then: Yup.string().required(requiredError)
  }),
  otherFormOfIdentification: Yup.array().when(['proofOfCitizenship', 'governmentIssuedPhotoID'], {
    is: (proofOfCitizenship, governmentIssuedPhotoID) => proofOfCitizenship === 'noneOfTheAbovePOC' && governmentIssuedPhotoID === 'noneOfTheAboveGovId',
    then: Yup.array().required(requiredError).min(2, minOf2Error)
  }),
});

export const utilityInfoSchema = Yup.object().shape({
  rentOrOwn: Yup.string().required(requiredError),
  rentEnergyCostIncluded: Yup.string()
    .when('rentOrOwn', {
      is: (rentOrOwn) => rentOrOwn === "rent",
      then: Yup.string().required(requiredError)
    }),
  rentLandlordName: Yup.string()
    .when('rentOrOwn', {
      is: (rentOrOwn) => rentOrOwn === "rent",
      then: Yup.string().required(requiredError)
    }),
  rentLandlordPhoneNo: Yup.string()
    .when('rentOrOwn', {
      is: (rentOrOwn) => rentOrOwn === "rent",
      then: Yup.string().required(requiredError).length(14, "Invalid Phone Number")
    }),
  rentLandlordStreetAddress: Yup.string()
    .when('rentOrOwn', {
      is: (rentOrOwn) => rentOrOwn === "rent",
      then: Yup.string().required(requiredError)
    }),
  rentLandlordCity: Yup.string()
    .when('rentOrOwn', {
      is: (rentOrOwn) => rentOrOwn === "rent",
      then: Yup.string().required(requiredError)
    }),
  rentLandlordState: Yup.string()
    .when('rentOrOwn', {
      is: (rentOrOwn) => rentOrOwn === "rent",
      then: Yup.string().required(requiredError)
    }),
  rentLandlordZip: Yup.string()
    .when('rentOrOwn', {
      is: (rentOrOwn) => rentOrOwn === "rent",
      then: Yup.string().required(requiredError)
    }),
  rentLandlordCountyOrParish: Yup.string()
    .when('rentOrOwn', {
      is: (rentOrOwn) => rentOrOwn === "rent",
      then: Yup.string().required(requiredError)
    }),

  residenceAllElectric: Yup.string().required(requiredError),
  electricityProvider: Yup.string().required(requiredError),
  otherElectricityProvider: Yup.string()
    .when('electricityProvider', {
      is: (electricityProvider) => electricityProvider === "other",
      then: Yup.string().required(requiredError)
    }),
  electricityAcctNo: Yup.string().required(requiredError),
  electricityAccountClosed: Yup.string().required(requiredError),
  electricityInApplicantName: Yup.string().required(requiredError),
  electricityAccountOwnerName: Yup.string()
    .when('electricityInApplicantName', {
      is: (electricityInApplicantName) => electricityInApplicantName === "no",
      then: Yup.string().required(requiredError)
    }),
  electricityAccountOwnerLiveWithApplicant: Yup.string()
    .when('electricityInApplicantName', {
      is: (electricityInApplicantName) => electricityInApplicantName === "no",
      then: Yup.string().required(requiredError)
    }),
  electricityAccountOwnerRelationToApplicant: Yup.string()
    .when('electricityInApplicantName', {
      is: (electricityInApplicantName) => electricityInApplicantName === "no",
      then: Yup.string().required(requiredError)
    }),

  primarySource: Yup.string().required(requiredError),
  primarySourceAccountClosed: Yup.string()
    .when('primarySource', {
      is: (primarySource) => primarySource !== "electricity",
      then: Yup.string().required(requiredError)
    }),
  primarySourceInApplicantName: Yup.string()
    .when('primarySource', {
      is: (primarySource) => primarySource !== "electricity",
      then: Yup.string().required(requiredError)
    }),
  primarySourceAccountOwnerName: Yup.string()
    .when(['primarySource', 'primarySourceInApplicantName'], {
      is: (primarySource, primarySourceInApplicantName) => primarySource !== "electricity" && primarySourceInApplicantName === "no",
      then: Yup.string().required(requiredError)
    }),
  primarySourceAccountOwnerLiveWithApplicant: Yup.string()
    .when(['primarySource', 'primarySourceInApplicantName'], {
      is: (primarySource, primarySourceInApplicantName) => primarySource !== "electricity" && primarySourceInApplicantName === "no",
      then: Yup.string().required(requiredError)
    }),
  primarySourceAccountOwnerRelationToApplicant: Yup.string()
    .when(['primarySource', 'primarySourceInApplicantName'], {
      is: (primarySource, primarySourceInApplicantName) => primarySource !== "electricity" && primarySourceInApplicantName === "no",
      then: Yup.string().required(requiredError)
    }),

  naturalGasProvider: Yup.string()
    .when('primarySource', {
      is: (primarySource) => primarySource === "naturalGas",
      then: Yup.string().required(requiredError)
    })
    .when('secondarySources', {
      is: (secondarySources) => secondarySources?.includes("naturalGas"),
      then: Yup.string().required(requiredError)
    }),
  naturalGasAcctNo: Yup.string()
    .when('primarySource', {
      is: (primarySource) => primarySource === "naturalGas",
      then: Yup.string().required(requiredError)
    })
    .when('secondarySources', {
      is: (secondarySources) => secondarySources?.includes("naturalGas"),
      then: Yup.string().required(requiredError)
    }),
  naturalGasAccountClosed: Yup.string()
    .when('secondarySources', {
      is: (secondarySources) => secondarySources?.includes("naturalGas"),
      then: Yup.string().required(requiredError)
    }),
  naturalGasInApplicantName: Yup.string()
    .when('secondarySources', {
      is: (secondarySources) => secondarySources?.includes("naturalGas"),
      then: Yup.string().required(requiredError)
    }),
  naturalGasAccountOwnerName: Yup.string()
    .when(['secondarySources', 'naturalGasInApplicantName'], {
      is: (secondarySources, naturalGasInApplicantName) => secondarySources?.includes("naturalGas") && naturalGasInApplicantName === "no",
      then: Yup.string().required(requiredError)
    }),
  naturalGasAccountOwnerLiveWithApplicant: Yup.string()
    .when(['secondarySources', 'naturalGasInApplicantName'], {
      is: (secondarySources, naturalGasInApplicantName) => secondarySources?.includes("naturalGas") && naturalGasInApplicantName === "no",
      then: Yup.string().required(requiredError)
    }),
  naturalGasAccountOwnerRelationToApplicant: Yup.string()
    .when(['secondarySources', 'naturalGasInApplicantName'], {
      is: (secondarySources, naturalGasInApplicantName) => secondarySources?.includes("naturalGas") && naturalGasInApplicantName === "no",
      then: Yup.string().required(requiredError)
    }),

  propaneProvider: Yup.string()
    .when('primarySource', {
      is: (primarySource) => primarySource === "propane",
      then: Yup.string().required(requiredError)
    })
    .when('secondarySources', {
      is: (secondarySources) => secondarySources?.includes("propane"),
      then: Yup.string().required(requiredError)
    }),
  propaneAcctNo: Yup.string()
    .when('primarySource', {
      is: (primarySource) => primarySource === "propane",
      then: Yup.string().required(requiredError)
    })
    .when('secondarySources', {
      is: (secondarySources) => secondarySources?.includes("propane"),
      then: Yup.string().required(requiredError)
    }),
  propaneAccountClosed: Yup.string()
    .when('secondarySources', {
      is: (secondarySources) => secondarySources?.includes("propane"),
      then: Yup.string().required(requiredError)
    }),
  propaneInApplicantName: Yup.string()
    .when('secondarySources', {
      is: (secondarySources) => secondarySources?.includes("propane"),
      then: Yup.string().required(requiredError)
    }),
  propaneAccountOwnerName: Yup.string()
    .when(['secondarySources', 'propaneInApplicantName'], {
      is: (secondarySources, propaneInApplicantName) => secondarySources?.includes("propane") && propaneInApplicantName === "no",
      then: Yup.string().required(requiredError)
    }),
  propaneAccountOwnerLiveWithApplicant: Yup.string()
    .when(['secondarySources', 'propaneInApplicantName'], {
      is: (secondarySources, propaneInApplicantName) => secondarySources?.includes("propane") && propaneInApplicantName === "no",
      then: Yup.string().required(requiredError)
    }),
  propaneAccountOwnerRelationToApplicant: Yup.string()
    .when(['secondarySources', 'propaneInApplicantName'], {
      is: (secondarySources, propaneInApplicantName) => secondarySources?.includes("propane") && propaneInApplicantName === "no",
      then: Yup.string().required(requiredError)
    }),

  otherHeatSource: Yup.string()
    .when('primarySource', {
      is: (primarySource) => primarySource === "other",
      then: Yup.string().required(requiredError)
    })
    .when('secondarySources', {
      is: (secondarySources) => secondarySources?.includes("other"),
      then: Yup.string().required(requiredError)
    }),
  otherAccountClosed: Yup.string()
    .when('secondarySources', {
      is: (secondarySources) => secondarySources?.includes("other"),
      then: Yup.string().required(requiredError)
    }),
  otherInApplicantName: Yup.string()
    .when('secondarySources', {
      is: (secondarySources) => secondarySources?.includes("other"),
      then: Yup.string().required(requiredError)
    }),
  otherAccountOwnerName: Yup.string()
    .when(['secondarySources', 'otherInApplicantName'], {
      is: (secondarySources, otherInApplicantName) => secondarySources?.includes("other") && otherInApplicantName === "no",
      then: Yup.string().required(requiredError)
    }),
  otherAccountOwnerLiveWithApplicant: Yup.string()
    .when(['secondarySources', 'otherInApplicantName'], {
      is: (secondarySources, otherInApplicantName) => secondarySources?.includes("other") && otherInApplicantName === "no",
      then: Yup.string().required(requiredError)
    }),
  otherAccountOwnerRelationToApplicant: Yup.string()
    .when(['secondarySources', 'otherInApplicantName'], {
      is: (secondarySources, otherInApplicantName) => secondarySources?.includes("other") && otherInApplicantName === "no",
      then: Yup.string().required(requiredError)
    }),

  weatherProofingOrEfficiency: Yup.string().required(requiredError)

});

export const utilityHousingSchema = Yup.object().shape({
  rentHousingType: Yup.string().when('rentOrOwn', {
    is: (rentOrOwn) => rentOrOwn === 'rent',
    then: Yup.string().required(requiredError)
  }),
  ownHousingType: Yup.string().when('rentOrOwn', {
    is: (rentOrOwn) => rentOrOwn === 'own',
    then: Yup.string().required(requiredError)
  }),
  monthlyRentOrMortgage: Yup.string().required(requiredError),
  needForAssistanceExplaination: Yup.string().required(requiredError),
  affectedByCovid:  Yup.string().required(requiredError),
  covidExplaination: Yup.string().when('affectedByCovid', {
    is: (affectedByCovid) => affectedByCovid === 'yes',
    then: Yup.string().required(requiredError)
  })
});

export const utilityWeatherProofingSchema = Yup.object().shape({
  weatherProofingPrevAsst: Yup.string().when('weatherProofingOrEfficiency', {
    is: (weatherProofingOrEfficiency) => weatherProofingOrEfficiency === 'yes',
    then: Yup.string().required(requiredError)
  }),
  weatherProofingPrevAsstDate: Yup.string()
    .when(['weatherProofingOrEfficiency', 'weatherProofingPrevAsst'], {
      is: (weatherProofingOrEfficiency, weatherProofingPrevAsst) => weatherProofingOrEfficiency === 'yes' && weatherProofingPrevAsst === 'yes',
      then: Yup.string().required(requiredError)
    }),
  yearHomeWasBuilt: Yup.string().when('weatherProofingOrEfficiency', {
    is: (weatherProofingOrEfficiency) => weatherProofingOrEfficiency === 'yes',
    then: Yup.string().required(requiredError)
  }),
  airConditionerType: Yup.string().when('weatherProofingOrEfficiency', {
    is: (weatherProofingOrEfficiency) => weatherProofingOrEfficiency === 'yes',
    then: Yup.string().required(requiredError)
  }),
  heaterType: Yup.array().when('weatherProofingOrEfficiency', {
    is: (weatherProofingOrEfficiency) => weatherProofingOrEfficiency === 'yes',
    then: Yup.array().required(requiredError).min(1, requiredError)
  }),
  gasSpaceHeaterCount: Yup.string()
    .when(['weatherProofingOrEfficiency', 'heaterType'], {
      is: (weatherProofingOrEfficiency, heaterType) => weatherProofingOrEfficiency === 'yes' && heaterType && heaterType.length > 0 && heaterType.includes('gasSpaceHeater'),
      then: Yup.string().required(requiredError)
  }),
  electricHeaterCount: Yup.string()
    .when(['weatherProofingOrEfficiency', 'heaterType'], {
      is: (weatherProofingOrEfficiency, heaterType) => weatherProofingOrEfficiency === 'yes' && heaterType && heaterType.length > 0 && heaterType.includes('electricHeater'),
      then: Yup.string().required(requiredError)
    }),
  roofLeaking: Yup.string().when('weatherProofingOrEfficiency', {
    is: (weatherProofingOrEfficiency) => weatherProofingOrEfficiency === 'yes',
    then: Yup.string().required(requiredError)
  }),
  roofLeakingTimeLength: Yup.string()
    .when(['weatherProofingOrEfficiency' ,'roofLeaking'], {
      is: (weatherProofingOrEfficiency, roofLeaking) => weatherProofingOrEfficiency === 'yes' && roofLeaking === 'yes',
      then: Yup.string().required(requiredError)
  }),
  roofLeakingRoomCount: Yup.string()
    .when(['weatherProofingOrEfficiency', 'roofLeaking'], {
      is: (weatherProofingOrEfficiency, roofLeaking) => weatherProofingOrEfficiency === 'yes' && roofLeaking === 'yes',
      then: Yup.string().required(requiredError)
  }),
  holesInFloor: Yup.string().when('weatherProofingOrEfficiency', {
    is: (weatherProofingOrEfficiency) => weatherProofingOrEfficiency === 'yes',
    then: Yup.string().required(requiredError)
  }),
  goodFoundation: Yup.string().when('weatherProofingOrEfficiency', {
    is: (weatherProofingOrEfficiency) => weatherProofingOrEfficiency === 'yes',
    then: Yup.string().required(requiredError)
  })
});

export const utilitySituationSchema = Yup.object().shape({
  utilityAssistanceNeeded: Yup.array().required(requiredError).min(1, requiredError),
  serviceShutOff: Yup.string().required(requiredError),
  disconnectNotice: Yup.string().required(requiredError),
  crisisLifeThreatening:  Yup.string().required(requiredError),
  crisisExplaination: Yup.string().when('crisisLifeThreatening', {
    is: (crisisLifeThreatening) => crisisLifeThreatening === 'yes',
    then: Yup.string().required(requiredError)
  }),
  overdueBalance: Yup.string().when('electricityProvider', {
    is: (electricityProvider) => electricityProvider === "other",
    then: Yup.string().required(requiredError)
  }),
  overdueBalanceValue: Yup.string().when(['electricityProvider', 'overdueBalance'],{
    is: (electricityProvider, overdueBalance) => electricityProvider === "other" && overdueBalance === "yes",
    then: Yup.string().required(requiredError)
  })
});
