import makeStyles from '@mui/styles/makeStyles';
import React, { createElement } from 'react'
import { Grid, Typography, Container, Divider, CircularProgress, IconButton } from '@mui/material';
import Notes from '../../../Notes'
import History from '../../../History';
import usePermissions from '../../../../hooks/usePermissions';
import { agencyPermissions } from '../../../../constants/agencyPermissions';
import { Close } from '@mui/icons-material';
import { statuses } from '../../../../constants/appStatuses';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    marginLeft: "0",
    backgroundColor: '#FDFDFD',
    border: '.5px solid rgba(0, 0, 0, 0.25)',
    height: '100%',
    overflow: 'scroll',
    padding: '1rem 1rem 3rem 1rem',
  }, 
  tabHeader: {
    marginBottom: '1rem',
    alignItems: 'center'
  },
  divider: {
      margin: '1rem 0',
      border: '1px solid #C4C4C4'
  },
  spinner: {
    textAlign: 'center'
  }
}));


export default function ReviewSidePanel( { application, stateConfig, onUpdate, loading, selectedTab, setTab } ) {
  const classes = useStyles();

  const userPermissions = usePermissions();
  const canEditReview = userPermissions ? userPermissions.includes(agencyPermissions.REVIEW_APPLICATION_EDIT) : false;

  const renderCurrentStatus = () => {
    return(
      createElement(stateConfig.agencyConfigs.currentStatusComponent, { status: application?.status, statusInfo:application.statusInfo })
    );
  }
  
  const renderChangeStatus = () => {
    return(
      createElement(stateConfig.agencyConfigs.changeStatusComponent, { application: application, onUpdate: onUpdate, stateConfig: stateConfig })
    );
  }

  return (
    <Container className={classes.mainContainer}>
      <Grid container item xs={12}>
        {selectedTab && selectedTab === 'history' &&
          <>
            <Grid className={classes.tabHeader} container item xs={12}>
              <Grid container item xs={10}>
                <Typography variant='h6'>
                  History
                </Typography> 
              </Grid>
              <Grid container item xs={2} justifyContent='flex-end'>
                <IconButton onClick={() => setTab('')}>
                  <Close/>
                </IconButton>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {loading ? 
                <Grid item xs={12} className={classes.spinner}>
                  <CircularProgress />
                </Grid>
              :
               <History application={application}/>
              }
            </Grid>
          </>
        }
        {selectedTab && selectedTab === 'notes' &&
          <>
            <Grid className={classes.tabHeader} container item xs={12}>
              <Grid container item xs={10}>
                <Typography variant='h6'>
                  Notes
                </Typography> 
              </Grid>
              <Grid container item xs={2} justifyContent='flex-end'>
                <IconButton onClick={() => setTab('')}>
                  <Close/>
                </IconButton>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {loading ? 
                <Grid item xs={12} className={classes.spinner}>
                  <CircularProgress />
                </Grid>
              :
                <Notes application={application} onChange={onUpdate} canEdit={canEditReview}/>
              }
            </Grid>
          </>
        }
        {selectedTab && selectedTab === 'actions' &&
          <>
            <Grid className={classes.tabHeader} container item xs={12}>
              <Grid container item xs={10}>
                <Typography variant='h6'>
                  Actions
                </Typography> 
              </Grid>
              <Grid container item xs={2} justifyContent='flex-end'>
                <IconButton onClick={() => setTab('')}>
                  <Close/>
                </IconButton>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {loading ? 
                <Grid item xs={12} className={classes.spinner}>
                  <CircularProgress />
                </Grid>
              :
              <>
                {renderCurrentStatus()}
              </>
              }
              {canEditReview && application?.status !== statuses.ON_PAPER &&
                <>
                  <Divider className={classes.divider}/>
                  {renderChangeStatus()}
                </>
               }
            </Grid>
          </>
        }
      </Grid>
    </Container>
  )
}
