export const proofOfCitizenshipOptions = [
    {
      value: 'usPassport',
      label: 'U.S. Passport (Can be expired)',
      description: 'Please upload a copy of :typeContext U.S. Passport.'
    },
    {
      value: 'usTribalCard',
      label: 'U.S. American Indian or Alaskan Native Tribal Card with Photo',
      description: 'Please upload a copy of :typeContext U.S. American Indian or Alaskan Native Tribal Card with Photo.'
    },
    {
      value: 'certificateOfNaturalization',
      label: 'Certificate of Naturalization',
      description: 'Please upload a copy of :typeContext Certificate of Naturalization.'
    },
    {
      value: 'certificateOfUSCitizenship',
      label: 'Certificate of U.S. Citizenship',
      description: 'Please upload a copy of :typeContext Certificate of U.S. Citizenship.'
    },
    {
      value: 'residentAlienCard',
      label: 'Resident Alien Card',
      description: 'Please upload a copy of :typeContext Resident Alien Card.'
    },
    {
      value: 'permanentResidentCard',
      label: 'Permanent Resident Card',
      description: 'Please upload a copy of :typeContext Permanent Resident Card.'
    },
    {
      value: 'noneOfTheAbovePOC',
      label: 'None of the above',
      description: ''
    }
  ]
  
  