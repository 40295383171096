import { createContext, useContext } from 'react';

export const ErrorContext = createContext();

export function useErrorViewer() {
    const errorCtx = useContext(ErrorContext)
    return (message, severity) => {
        errorCtx.setError(message);
        errorCtx.setSeverity((severity) || "error");
    }
}
