import { cdcaaCountyOptions } from "./ar/dropdowns/cdcaaCountyOptions"
import { getcapCountyOptions } from "./tx/dropdowns/getcapCountyOptions"
import { arvacCountyOptions } from "./ar/dropdowns/arvacCountyOptions"
import { cadcCountyOptions } from "./ar/dropdowns/cadcCountyOptions"
import { capcaCountyOptions } from "./ar/dropdowns/capcaCountyOptions"
import { csoCountyOptions } from "./ar/dropdowns/csoCountyOptions"
import { arkansasCountyOptions } from "./ar/dropdowns/arkansasCountyOptions"
import { seacacCountyOptions } from "./ar/dropdowns/seacacCountyOptions"
import { mcaeocCountyOptions } from "./ar/dropdowns/mcaeocCountyOptions"
import { bvcapCountyOptions } from "./tx/dropdowns/bvcapCountyOptions"
import { texasCountyOptions } from "./tx/dropdowns/texasCountyOptions"


export const agencyCountyMap = {
  "ARVAC-AR": arvacCountyOptions,
  "CADC-AR": cadcCountyOptions,
  "CSO-AR": csoCountyOptions,
  "CDCAA-AR": cdcaaCountyOptions,
  "CAPCA-AR": capcaCountyOptions,
  "SEACAC-AR": seacacCountyOptions,
  "MCAEOC-AR": mcaeocCountyOptions,
  "GETCAP-TX": getcapCountyOptions,
  "BVCAP-TX": bvcapCountyOptions,
  "TX": texasCountyOptions,
  "AR": arkansasCountyOptions
}
