import makeStyles from '@mui/styles/makeStyles';
import PropTypes from "prop-types";


const useStyles = makeStyles((theme) => ({
  message: {
    position: "relative",
    border: "0.25rem",
    borderRadius: "0.25rem",
    margin: "1rem 6rem",
    fontSize: "1rem",
    [theme.breakpoints.down('lg')]: {
        margin: '1rem'
      },
  },
  header: {
    fontSize: "1.3rem",
    fontWeight: "bold",
    padding: "0.5rem",
    margin: "0px",
    border: "0px"
  },
  body: {
    margin: "1.5rem 0.75rem",
  },
  error: {
    border : `0.25rem solid ${theme.palette.error.main}`,
    backgroundColor: theme.customColors.veryLightGray,
    "& #messageTitle": {
        backgroundColor: theme.palette.error.main,
        color: "white"
    }
  },
  info: {
    border : `0.25rem solid ${theme.palette.primary.main}`,
    backgroundColor: theme.customColors.veryLightGray,
    "& #messageTitle": {
        backgroundColor: theme.palette.primary.main,
        color: "white"
    }
  },
  success: {
    border : `0.25rem solid ${theme.palette.success.main}`,
    backgroundColor: theme.customColors.veryLightGray,
    "& #messageTitle": {
        backgroundColor: theme.palette.success.main,
        color: "white"
    }
  },  
}));

export default function Banner (props) {
    const { messageType, title, body } = props;
    const classes = useStyles();
    
    return (
      <div className={`${classes.message} ${classes[messageType]} `}>
        <div id="messageTitle" className={`${classes[messageType]} ${classes.header}`}>
          {title}
        </div>
        <div id="messageBody" className={classes.body} dangerouslySetInnerHTML={{ __html: body }}/>
      </div>
    );
}

Banner.propTypes = {
    messageType: PropTypes.string,
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired
};