import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Typography, Grid, CircularProgress } from '@mui/material';
import FormTextField from '../../../../form/FormTextField';
import FormDatePicker from '../../../../form/FormDatePicker';
import FormMultiSelect from '../../../../form/FormMultiSelect';
import useStateForms from '../../../../../hooks/useStateForms';

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: "bold", 
  },
  dateLabel: {
    fontWeight: "bold", 
    marginBottom: '.5rem'
  },
  standardInput: {
    backgroundColor: 'transparent',
    margin: '0'
  },
  textbox: {
    backgroundColor: '#FFF',
    marginBottom: '1.5rem',
    "& > .MuiFormHelperText-root.Mui-error": { 
      margin:0
    },
  },
}));

export default function ReturnedStatusInfo({ application, control, register, errors, loading, setValue, trigger, policyOptions, state}) {
  const classes = useStyles();

  const onPolicySelect = (values) => {
    setValue("returnedPolicyNumber", values, { shouldDirty: true });
    trigger("returnedPolicyNumber");
  }

  const onStateFormSelect = (values) => {
    setValue("returnedNeededForms", values, { shouldDirty: true });
  }

  const { isFetching: fetchingStateForms, data: stateForms } = useStateForms(state, 'no')
  const [formOptions, setFormOptions] = useState([])

 /**
  * After fetching all state forms not marked as required for all,
  * compare that array with all forms previously marked as required for the application in review.
  * Only add forms not already marked as required for the application to the selectable options.
  */
  useEffect(() => {
    if(stateForms){
      const previouslyNeededForms = application?.statusInfo?.neededForms
      if(previouslyNeededForms){
        let options = []
        const previouslyNeededFormIds = previouslyNeededForms?.map(form => form.id)
        stateForms.forEach(form => {
          if(!previouslyNeededFormIds.includes(form.id)){
            options.push(form)
          }
        })
        setFormOptions(options)
      }
      else{
        setFormOptions(stateForms)
      }
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateForms]);

  return (
    <Grid container spacing={1}>
        <Grid item xs={12}>
            <Typography variant='subtitle2' className={classes.label} >
                The following information is needed to determine eligibility:
            </Typography>
            <FormTextField
                className={classes.textbox}
                disabled={loading}  
                name="returnedNeededInfo" 
                label="Type message to applicant"
                defaultValue={application?.statusInfo?.returnedNeededInfo}
                multiline
                rows={4}
                register={register} 
                errors={errors}     
            />
        </Grid>

          <Grid item xs={12}>
            <Typography variant='subtitle2' className={classes.dateLabel}>
                Application will be denied if this information is not received at this office by 
            </Typography>
            <FormDatePicker
                className={classes.standardInput}
                disabled={loading} 
                name="returnedDueDate"
                defaultValue={application?.statusInfo?.returnedDueDate}
                InputAdornmentProps={{ position: "end" }}
                id="returnedDueDate-date-picker"
                control={control}
                errors={errors}
                variant='standard'
                userType='agent'
                validationTrigger={trigger}
            />
        </Grid>
        <Grid item xs={12}>
            <Typography variant='subtitle2' className={classes.label}>
                based on Policy # 
            </Typography>
            <FormMultiSelect 
                className={classes.standardInput}
                disabled={loading}  
                name="returnedPolicyNumber" 
                label="Policy #"
                defaultValue={application?.statusInfo?.returnedPolicyNumber}
                control={control}
                errors={errors}
                options={policyOptions}
                onSelect={onPolicySelect}
            />
        </Grid>
        <Grid item xs={12}>
            <Typography variant='subtitle2' className={classes.label}>
                Forms
            </Typography>
            {fetchingStateForms ? <CircularProgress/> :
            <>
              {formOptions?.length > 0 ? 
                <FormMultiSelect 
                  className={classes.standardInput}
                  disabled={loading}  
                  name="returnedNeededForms" 
                  label="Forms"
                  defaultValue={application?.statusInfo?.returnedNeededForms}
                  control={control}
                  errors={errors}
                  options={formOptions}
                  onSelect={onStateFormSelect}
                  fileSelect={true}
                />
              :
                <Typography variant='subtitle2'>
                  All available forms have already been marked as required for this application.
                </Typography>
              }
            </>
            }
        </Grid>
    </Grid>
)}
