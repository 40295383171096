import { Grid, Button, CircularProgress, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useState, useContext } from "react";
import { getMergedFileForApplication } from "../../../api/FileAPI";
import { Download} from '@mui/icons-material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { formatDate } from "../../../utils/utilFunctions";
import { useErrorViewer } from "../../../context/errorContext";
import { AuthContext } from "../../../context/authContext";
import { agencyPermissions } from "../../../constants/agencyPermissions";
import { statePermissions } from "../../../constants/statePermissions";
import { seasonOptions } from "../../../constants/dropdowns/seasonOptions";

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: 'none',
    padding: '.75rem'
  },
  downloadIcon: {
    marginRight: '.5rem'
  },
  downloadIconSmall: {
    marginRight: '100rem',
    fontSize: '30px',
  },
  cell: {
    fontWeight: '500',
    width: '100%',
    whiteSpace: 'nowrap',
    lineHeight: '1.5rem',
    justifyContent: 'flex-start',
    textDecoration: 'underline'
  },
}));

export default function SaveMergedPDF ({ appData, disabled=false }) {
  const classes = useStyles();
  const [ loading, setLoading ] = useState(false);
  const setError = useErrorViewer();

  const { userInfo } = useContext(AuthContext);
  const userPermissions = userInfo?.permissions ? userInfo.permissions : [];
  const agencyView = userPermissions.includes(agencyPermissions.REVIEW_APPLICATION_EDIT)
  const stateView = userPermissions.includes(statePermissions.STATE_REVIEW_APPLICATION_VIEW)
  const agencyDefaultSeasonView = appData.recordType === seasonOptions[0].value //Defaulted to "LIHEAP_APP"

  const getFileName = () => {
    return `${appData.lastName}_${appData.firstName}_${formatDate(appData.initialSubmittedDate, "MM_dd_yyyy")}`;
  }

  const downloadPDF = (encryptedUrl) => {
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = `/api/file/${encryptedUrl}`;
    a.download = getFileName();
    a.click();
    window.open().location =a.href;
    document.body.removeChild(a);
  }

  const handleClick = (appData) => {
    if(!appData){
      setError("Application data is empty.")
      return;
    }
    setLoading(true);

    getMergedFileForApplication(appData?.id, appData?.recordType)
        .then((response) => {
          downloadPDF(response.data?.url)          
          setLoading(false);
        })
        .catch((error) => {
            setLoading(false);
            setError("An Error occurred saving the consolated PDF. However, each file is saveable individually from the Documents section.")
        });
  }

  if (loading) {
    return (
      <Grid item justifyContent="flex-start" className={classes.spinner}>
        <CircularProgress color="primary" size={20} />
      </Grid>
    )
  }
  return (
    <>
    {agencyView && agencyDefaultSeasonView &&
      <Grid item justifyContent="center">
        <Button
        className={classes.button}
        onClick={() => handleClick(appData)}
        variant="text"
        color="primary"
        >
        <Download className={classes.downloadIcon}/>
        Save PDF
        </Button>
      </Grid>
    }
    {/* Agency Previous-Season View Merged PDF */}
    {!agencyDefaultSeasonView &&
      <Grid item justifyContent="center">

        <Button
        className={classes.button}
        onClick={() => handleClick(appData)}
        color="primary"
        disabled={disabled}
        >
      <Typography variant='subtitle1' className={classes.cell}>{appData.lastName + ", " + appData.firstName}</Typography>
      </Button>
    </Grid>
    }
    {stateView &&
      <Grid item justifyContent="center">
        <Button
        className={classes.button}
        onClick={() => handleClick(appData)}
        color="primary"
        disabled={disabled}
        >
      <OpenInNewIcon className={classes.downloadIcon}/>
      </Button>
    </Grid>
   }
   </>
   )
}
