import makeStyles from '@mui/styles/makeStyles';
import React from 'react'

const useStyles = makeStyles((theme) => ({
    radioButton: {
        display: 'flex',
        position: 'relative',
        fill: theme.customColors.mediumGray,
        width: '1.5rem',
        height: '1.5rem'
      },
      radioButtonCenter: {
        left: '0',
        position: 'absolute',
        fill: theme.palette.primary.main,
        width: '1.5rem',
        height: '1.5rem'
      }
}));

export default function RadioIcon() {
  const classes = useStyles();
  return (
    <div className={classes.radioButton}>
    <svg className="MuiSvgIcon-root">
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path>
    </svg>
    <svg className={`MuiSvgIcon-root ${classes.radioButtonCenter}`}>
      <path d="M8.465 8.465C9.37 7.56 10.62 7 12 7C14.76 7 17 9.24 17 12C17 13.38 16.44 14.63 15.535 15.535C14.63 16.44 13.38 17 12 17C9.24 17 7 14.76 7 12C7 10.62 7.56 9.37 8.465 8.465Z"></path>
    </svg>
  </div>
  );
}

