import { useState, useMemo } from "react";
import { ErrorContext } from "../context/errorContext";

export function ErrorContextProvider({ children }) {
    
    const [error, setError] = useState(null);
    const [severity, setSeverity] = useState("error");
    const ctx = useMemo(() => ({ error, setError, severity, setSeverity }), [error, severity]);
  
    return <ErrorContext.Provider value={ctx}>{children}</ErrorContext.Provider>
  }