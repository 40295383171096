import { Container, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import StepButtons from '../../../StepButtons'
import StepHeader from '../../../StepHeader'
import Documents from '../Documents'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    margin: "0",
  }
}));

export default function ApplicantDocumentUpload({ data: appData, back, next, appId, validate, fileData, handleFileCreate, handleFileDelete }) {
  const classes = useStyles();

  const filteredFileData = fileData?.filter((x) => (x.memberId === "") );
  return (
    <Container className={classes.mainContainer}>
        <Grid container spacing={1}>
          <StepHeader 
            title="Applicant Documents" 
            desc = "Please provide the following information for the applicant."
          />
          <Documents 
            appId={appId}
            appData={appData}
            validate={validate}
            type="applicant"
            handleFileCreate={handleFileCreate}
            handleFileDelete={handleFileDelete}
            fileData={filteredFileData}
          />
          <Grid item xs={12}>
            <StepButtons back={back} next={next} />
          </Grid>
        </Grid>
    </Container>
  )
}
