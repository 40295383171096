
import { Tooltip } from '@mui/material';
import { styled } from '@mui/system';

export const FormTooltip = styled(Tooltip)((theme) => ({
  tooltip: {
    padding: '20px 20px 20px 20px',
    backgroundColor: theme.customColors.tooltip,
    borderRadius: '4px',
    color: 'white',
    fontSize: '14px',
    fontFamily: 'Montserrat',
    fontWeight: 10,
    boxShadow: '4px 2px 5px gray'
  },
  arrow: {
    color: theme.customColors.tooltip,
  }
}));
