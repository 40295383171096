export const otherFormOfIdentificationOptions = [
    {
      value: 'ssCard',
      label: 'Social Security card',
      description: 'Please upload a copy of :typeContext Social Security card.'
    },
    {
      value: 'voterRegistration',
      label: 'Voter Registration',
      description: 'Please upload a copy of :typeContext Voter Registration.'
    },
    {
      value: 'medicareOrOtherHealthCard',
      label: 'Medicare or other Health Card',
      description: 'Please upload a copy of :typeContext Medicare or other Health Card.'
    },
    {
      value: 'studentID',
      label: 'Student ID',
      description: 'Please upload a copy of :typeContext Student ID.'
    },
    {
      value: 'schoolRecords',
      label: 'School Records',
      description: 'Please upload a copy of :typeContext School Records.'
    },
    {
      value: 'immunizationRecords',
      label: 'Immunization Records',
      description: 'Please upload a copy of :typeContext Immunization Records.'
    }
    
  ]
  
  