import { useContext, useEffect } from 'react'
import { Redirect } from 'react-router';
import { AuthContext } from '../../context/authContext';

export default function Logout() {
    
    const auth = useContext(AuthContext);

    useEffect(() => {
        auth.logout();
    // eslint-disable-next-line
    }, [])

    return (
        <Redirect to="/login"></Redirect>
    )
}
