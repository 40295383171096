import { Container, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState, useContext } from 'react'
import FormTextField from '../../../form/FormTextField'
import FormCheckboxes from '../../../form/FormCheckboxes'
import StepButtons from '../../../StepButtons'
import StepHeader from '../../../StepHeader'
import FormSection from '../../../FormSection'
import FormRadioAsButtons from '../../../form/FormRadioAsButtons'
import { heatOptions } from '../../../../constants/ar/checkboxes/heatOptions'
import { utilityProviderOptions } from '../../../../constants/dropdowns/utilityProviderOptions'
import { rentOwnOptions } from '../../../../constants/radios/rentOwnOptions'
import { yesNoOptions } from '../../../../constants/radios/yesNoOptions'
import { relationToApplicantOptions } from '../../../../constants/dropdowns/relationToApplicantOptions'
import FormSelect from '../../../form/FormSelect'
import { electricityProviderOptions, electricityProviderEntergyOptions } from '../../../../constants/radios/electricityProviderOptions'
import useLiheapForm from '../../../../hooks/useLiheapForm'
import FeatureFlag from '../../../FeatureFlag';
import { ConfigContext } from "../../../../context/configContext";
import { AuthContext } from '../../../../context/authContext';
import { isFeatureDisabled } from '../../../../constants/features';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    marginLeft: "0"
  },
  label: {
    fontWeight: "bold", 
    marginBottom: ".6rem",
  },
  section: {
    marginTop: '1.5rem'
  },
  inputFields: {
    maxWidth: "21rem",
    backgroundColor: "#fff",
    "& > .MuiFormHelperText-root.Mui-error": { 
      backgroundColor: theme.customColors.veryLightGray,
      margin:0
    }
  }
}));

const Electricity = ({control, errors, register, electricityProviderWatch, onSelect}) => {
  const classes = useStyles();
  const { clientConfig } = useContext(ConfigContext);
  const { userInfo } = useContext(AuthContext);
  
  const providers = userInfo?.agencyInfo?.providers ? userInfo?.agencyInfo?.providers.filter(prov => prov !== 'Entergy Services') : []
  const agencyId = userInfo?.agencyId;
  const [availableOptions, setAvailableOptions] = useState([]);

  useEffect(() => {
    let isDisabled = true
    if (clientConfig && clientConfig["DisabledFeatures"]){
      isDisabled = isFeatureDisabled(clientConfig, agencyId, "OtherElectricProviders")
      setAvailableOptions(isDisabled ? electricityProviderEntergyOptions : electricityProviderOptions)
    }
    }, [agencyId, clientConfig]);

  return (
    <>
      <Grid item xs={12} container className={classes.section}>
        <FormRadioAsButtons
          control={control}
          name="electricityProvider"
          label="Please select your Electricity provider."
          errors={errors}
          options={availableOptions}
          onSelect={onSelect}
        />  
        <FeatureFlag feature="OtherElectricProviders" hideOnDisabled={true} >
        { electricityProviderWatch === 'other' && 
        <Grid item xs={12}>
          <FormSelect
            className={classes.inputFields}
            name="otherElectricityProvider"
            label="Electricity provider"
            labelId="otherElectricityProvider-label"
            labelPlacement="inside"
            control={control}
            errors={errors}
            options={providers}
            width="21rem"
          />       
        </Grid>
        }
        </FeatureFlag>
        <Grid item xs={12}>
          <Typography variant="subtitle1" className={classes.label}>Please enter your account number here.</Typography>
        </Grid>
        <FormTextField className={classes.inputFields} name="electricityAcctNo" label="Account Number" register={register} errors={errors}/>
      </Grid>
    </>
  );
}

const NaturalGas = ({control, errors, register}) => {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} container className={classes.section}>
        <FormRadioAsButtons
          control={control}
          name="naturalGasProvider"
          label="Please select your Natural Gas provider."
          errors={errors}
          options={utilityProviderOptions}
        />
        <Grid item xs={12}>
          <Typography variant="subtitle1" className={classes.label}>Please enter your account number here.</Typography>
        </Grid>  
        <FormTextField className={classes.inputFields} name="naturalGasAcctNo" label="Account Number" register={register} errors={errors}/>
      </Grid>
    </>
  );
}

const FuelOilOrKerosene = ({control, errors, register}) => {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} container className={classes.section}>
        <FormRadioAsButtons
          control={control}
          name="fuelOilOrKerosenseProvider"
          label="Please select your Fuel Oil or Kerosense provider."
          errors={errors}
          options={utilityProviderOptions}
        />
        <Grid item xs={12}>
          <Typography variant="subtitle1" className={classes.label}>Please enter your account number here.</Typography>
        </Grid>  
        <FormTextField className={classes.inputFields} name="fuelOilOrKerosenseAcctNo" label="Account Number" register={register} errors={errors}/>
      </Grid>
    </>
  );
}

const Propane = ({control, errors, register}) => {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} container className={classes.section}>
        <FormRadioAsButtons
          control={control}
          name="propaneProvider"
          label="Please select your Propane, Butane, or LPG provider."
          errors={errors}
          options={utilityProviderOptions}
        />
        <Grid item xs={12}>
          <Typography variant="subtitle1" className={classes.label}>Please enter your account number here.</Typography>
        </Grid>  
        <FormTextField className={classes.inputFields} name="propaneAcctNo" label="Account Number" register={register} errors={errors}/>
      </Grid>
    </>
  );
}

const Other = ({errors, register}) => {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} container className={classes.section}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" className={classes.label}>Please enter the other way you heat your home.</Typography>
        </Grid>
        <FormTextField className={classes.inputFields} name="otherHeatSource" label="Other Heat Source" register={register} errors={errors}/>
      </Grid>
    </>
  );
}

const AdditionalAccountDetails = ({control, register, errors, accountType, accountTypeLabel, accountTypeWatch}) => {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12}>
        <FormRadioAsButtons
          control={control}
          name={`${accountType}AccountClosed`}
          label="Is this account closed?"
          errors={errors}
          options={yesNoOptions}
        />
      </Grid>
      
      <Grid item xs={12}>
        <FormRadioAsButtons
          control={control}
          name={`${accountType}InApplicantName`}
          label={`Is your ${accountTypeLabel} bill in your name?`}
          errors={errors}
          options={yesNoOptions}
        />
      </Grid>
      {accountTypeWatch === 'no' &&
        <>
          <Grid item xs={12}>
            <FormTextField
              className={classes.inputFields}
              name={`${accountType}AccountOwnerName`} 
              label="Whose name is the account in?"
              labelPlacement="top" 
              register={register} 
              errors={errors}                
            />  
          </Grid>
          <Grid item xs={12}>
            <FormRadioAsButtons
              control={control}
              name={`${accountType}AccountOwnerLiveWithApplicant`}
              label="Does this person live with you?"
              errors={errors}
              options={yesNoOptions}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormSelect 
              width="21rem"
              name={`${accountType}AccountOwnerRelationToApplicant`} 
              labelId={`${accountType}AccountOwnerRelationToApplicant-label`}
              label="What is this person's relationship to you?"
              control={control}
              errors={errors}
              options={relationToApplicantOptions}
            />
          </Grid>
        </>
      }
    </>
  )
}

const PrimarySource = ({selection, control, errors, register}) => {
  switch(selection) {
    // case 'electricity':
    //   return (<Electricity control={control} errors={errors} register={register}/>);
    case 'naturalGas':
      return (<NaturalGas control={control} errors={errors} register={register}/>);
    case 'fuelOilOrKerosene':
      return (<FuelOilOrKerosene control={control} errors={errors} register={register}/>);
    case 'propane':
      return (<Propane control={control} errors={errors} register={register}/>);
    case 'other':
      return (<Other errors={errors} register={register}/>);
    default:
      return <></>;
  }
}

export default function UtilityGeneralInformation({ data, back, next, save, validate, validationSchema }) {
  const { control, errors, register, watch, setValue } = useLiheapForm(save, data, validate, validationSchema, false);

  const [primarySource, setPrimarySource] = useState(data?.primarySource);
  const [secondarySources, setSecondarySources] = useState(data?.secondarySources);

  const [secondaryOptions, setSecondaryOptions] = useState(heatOptions.filter((option) => option.value !== data?.primarySource));

  const classes = useStyles();

  const primarySourceHandler = (selectedVal) => {
    setPrimarySource(selectedVal);
    setSecondaryOptions(heatOptions.filter((option) => option.value !== selectedVal));
    setSecondarySources(prevSecondarySources => prevSecondarySources?.filter((source) => source !== selectedVal));
  }

  const secondarySourcesHandler = (selectedVals) => {
    setSecondarySources(selectedVals);
  }

  const handleElectricitySelect = (val) => {
    if(val === 'entergy'){
      setValue("otherElectricityProvider", '', { shouldDirty: true });
      setValue("overdueBalance", '', { shouldDirty: true });
      setValue("overdueBalanceValue", '', { shouldDirty: true });
    }
  }
  
  //register "overdueBalance" and "overdueBalanceValue" fields so they can be reset via handleElectricitySelect
  useEffect(()=> { 
    register("overdueBalance");
    register("overdueBalanceValue");
  },[register])

  const electricityProviderWatch = watch('electricityProvider');
  const rentOrOwnWatch = watch('rentOrOwn');
  const rentEnergyCostIncludedWatch = watch('rentEnergyCostIncluded');
  const electricityInApplicantNameWatch = watch('electricityInApplicantName');
  const primarySourceInApplicantNameWatch = watch('primarySourceInApplicantName');
  const weatherizationReferralWatch = watch('weatherizationReferral');

  const naturalGasInApplicantNameWatch = watch('naturalGasInApplicantName');
  const fuelOilOrKeroseneInApplicantNameWatch = watch('fuelOilOrKeroseneInApplicantName');
  const propaneInApplicantNameWatch = watch('propaneInApplicantName');
  const otherInApplicantNameWatch = watch('otherInApplicantName');

  return (
    <Container className={classes.mainContainer}>
        <Grid container spacing={1}>
          <StepHeader 
            title="Utility Information" 
            desc = "Please provide the following information for the applicant."
          />
          <FormSection>
            <Grid item xs={12}>
              <FormRadioAsButtons
                control={control}
                name="rentOrOwn"
                label="Do you rent or own?"
                errors={errors}
                options={rentOwnOptions}
              />
            </Grid>
            { rentOrOwnWatch === 'rent' && 
              <>
                <Grid item xs={12}>
                  <FormRadioAsButtons
                    control={control}
                    name="rentEnergyCostIncluded"
                    label="Is your energy cost included in your rent payment?"
                    errors={errors}
                    options={yesNoOptions}
                  />
                </Grid>
                {rentEnergyCostIncludedWatch === 'yes' &&
                  <>
                    <Grid item xs={12}>
                      <FormTextField
                        className={classes.inputFields}
                        name="rentLandlordName" 
                        label="Name of landlord"
                        labelPlacement="top" 
                        register={register} 
                        errors={errors}                
                      />  
                    </Grid>
                    <Grid item xs={12}>
                      <FormTextField
                        mask="(999) 999-9999"
                        className={classes.inputFields}
                        name="rentLandlordPhoneNo" 
                        label="Phone number of landlord"
                        labelPlacement="top" 
                        register={register} 
                        errors={errors}  
                        inputProps={{
                          inputMode: 'tel',
                          maxLength: 14
                        }}              
                      /> 
                    </Grid>
                  </>
                }
              </>
            }
          </FormSection>
          <FormSection>
            <Grid item xs={12}>
              <FormRadioAsButtons
                control={control}
                name="residenceAllElectric"
                label="Is your residence all electric?"
                errors={errors}
                options={yesNoOptions}
              />
            </Grid>
            <Electricity 
              control={control} 
              errors={errors} 
              register={register}
              electricityProviderWatch={electricityProviderWatch}
              onSelect={handleElectricitySelect}
            />
            <AdditionalAccountDetails
              control={control}
              errors={errors}
              register={register}
              accountType="electricity"
              accountTypeLabel="electricity"
              accountTypeWatch={electricityInApplicantNameWatch}
            />
          </FormSection>
          <FormSection>
            <Grid item xs={12}>
              <FormRadioAsButtons
                control={control}
                name="primarySource"
                label="How do you primarily heat your home?"
                errors={errors}
                options={heatOptions}
                defaultValue={primarySource}
                onSelect={primarySourceHandler}
              />
            </Grid>
            {primarySource && 
              <>
                <PrimarySource 
                  selection={primarySource} 
                  control={control} 
                  errors={errors} 
                  register={register}
                />
                {primarySource !== 'electricity' &&
                  <>
                    <AdditionalAccountDetails
                      control={control}
                      errors={errors}
                      register={register}
                      accountType="primarySource"
                      accountTypeLabel="primary heating source"
                      accountTypeWatch={primarySourceInApplicantNameWatch}
                    />
                  </>
                }
              </>
            }
          </FormSection>
          <FormSection>
            <Grid item xs={12}>
              <FormCheckboxes
                defaultValues={secondarySources}
                register={register}
                name="secondarySources"
                label="Do you use any secondary sources to heat your home? (Please select all that apply)"
                options={secondaryOptions}
                gridSize={12}
                onSelect={secondarySourcesHandler}
                errors={errors}
                noOption="None"
              />
            </Grid>

          {/* {secondarySources?.some(option => option === 'electricity') && 
            <Electricity control={control} errors={errors} register={register}/>
          } */}
          {secondarySources?.some(option => option === 'naturalGas') &&             
            <>
              <NaturalGas control={control} errors={errors} register={register}/>
              <AdditionalAccountDetails
                control={control}
                errors={errors}
                register={register}
                accountType="naturalGas"
                accountTypeLabel="natural gas"
                accountTypeWatch={naturalGasInApplicantNameWatch}
              />
            </>
          }
          {secondarySources?.some(option => option === 'fuelOilOrKerosene') && 
            <>
              <FuelOilOrKerosene control={control} errors={errors} register={register}/>
              <AdditionalAccountDetails
                control={control}
                errors={errors}
                register={register}
                accountType="fuelOilOrKerosene"
                accountTypeLabel="fuel oil or kerosene"
                accountTypeWatch={fuelOilOrKeroseneInApplicantNameWatch}
              />
            </>
          }
          {secondarySources?.some(option => option === 'propane') && 
            <>
              <Propane control={control} errors={errors} register={register}/>
              <AdditionalAccountDetails
                control={control}
                errors={errors}
                register={register}
                accountType="propane"
                accountTypeLabel="propane"
                accountTypeWatch={propaneInApplicantNameWatch}
              />
            </>
          }
          {secondarySources?.some(option => option === 'other') && 
            <>
              <Other errors={errors} register={register}/>
              <AdditionalAccountDetails
                control={control}
                errors={errors}
                register={register}
                accountType="other"
                accountTypeLabel="other secondary source"
                accountTypeWatch={otherInApplicantNameWatch}
              />
            </>
          }       
          </FormSection>
          <FormSection>
            <Grid item xs={12}>
              <FormRadioAsButtons
                control={control}
                name="weatherizationReferral"
                label="Would you like to be referred for home Weatherization?"
                errors={errors}
                options={yesNoOptions}
              />       
            </Grid>
            { weatherizationReferralWatch === 'yes' &&
              <Grid item xs={12}>
                <FormRadioAsButtons
                  control={control}
                  name="weatherAssistanceProgram"
                  label="May LIHEAP send your application to the Weatherization Assistance Program?"
                  errors={errors}
                  options={yesNoOptions}
                />
              </Grid>
            }   
          </FormSection>   
          <StepButtons back={back} next={next}/>
        </Grid>
    </Container>
  )
}
