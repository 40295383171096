import { Container, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useParams } from 'react-router';
import useLiheapForm from '../../../../hooks/useLiheapForm';
import StepButtons from '../../../StepButtons';
import StepHeader from '../../../StepHeader';
import FinancialResources from '../FinancialResources';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    marginLeft: "0"
  }
}));

export default function MemberFinancialResources({ data, back, next, save, validate, validationSchema }) {
  const classes = useStyles();
  const { nestedItemId } = useParams();
  const nestedSave = (formData) => {
    formData.id = nestedItemId; //add nestedItemId to returned formData
    save(formData);
  }
  const memberData = data?.householdMembers?.find(x => x.id === nestedItemId);
  const getTitle = (heading) => {
    const itemIndex = memberData && (data?.householdMembers?.indexOf(memberData) >= 0) ? data?.householdMembers?.indexOf(memberData) + 1 : data?.householdMembers?.length + 1 || 1;
    return memberData && memberData.lastName && memberData.firstName ? `${heading} for ${memberData.firstName} ${memberData.lastName}` : `${heading} for Household Member #${itemIndex}`;
  }

  const { control, errors, register, watch, getValues } = useLiheapForm(nestedSave, memberData, validate, validationSchema, false);

  const backWithInfo = () => {
    let backData = getValues();
    backData["birthDate"] = memberData?.birthDate;
    
    back({
      hasConditionData: true,
      conditionData: backData
    })
  }

  return (
    <Container className={classes.mainContainer}>
        <Grid container spacing={1}>
          <StepHeader 
            title={getTitle("Financial Resources")}
            desc = "Please provide the following information for this household member."
          />
          <FinancialResources 
            control={control}
            register={register}
            type="householdMember"
            errors={errors}
            watch={watch}
          />
          <StepButtons back={backWithInfo} next={next}/>
        </Grid>
    </Container>
  )
}
