export const seasonOptions = [
  {
    value: 'LIHEAP_APP',
    label: 'Current Season'
  },
  {
    value: 'LIHEAP_APP#2024#01',
    label: 'Winter 2024 Season'
  },
  {
    value: 'LIHEAP_APP#2023#02',
    label: 'Summer 2023 Season'
  }
];