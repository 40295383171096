import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  mainGridContainer: {
    marginBottom: "1rem"
  },
  link: {
      margin: '0 .5rem',
      minWidth: '3rem',
      minHeight: '3rem',
      color: theme.palette.primary.main,
  },
  arrow: {
    color: theme.palette.primary.main,
}
}));

export default function SettingsStepper( { attribute, attributeLabel, url }) {
  const classes = useStyles();
  return (
  <Grid item xs={12} container className={classes.mainGridContainer}>
      <div className={classes.arrow}>
        {`<`}
      </div>
      <Link to="/" variant="body2" className={classes.link}>
          Home
      </Link>
      <div className={classes.arrow}>
        {`<`}
      </div>
      <Link to="/account" variant="body2" className={classes.link}>
          Account
      </Link>
      { attribute &&
      <>
        <div className={classes.arrow}>
            {`<`}
        </div>
        <Link to={url} variant="body2" className={classes.link}>
            {`${attributeLabel}`}
        </Link>
        </>
        }
    </Grid>
  )
}
