import { Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import { getFilesByTypeAndEmployer } from "../../constants/sharedFileUtils";
import FileUpload from "../FileUpload";
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from "@mui/styles";
import { InsertLink, Download } from "@mui/icons-material";
import { documentLinks } from "../../constants/tx/additionalDocumentInfo";

const useStyles = makeStyles((theme) => ({
  listItem: {
    marginTop: ".6rem",
    paddingLeft: "0"
  },
  listItemTextPrimary: {
    fontWeight: "bold",
    marginBottom: '.5rem'
  },
  fileUploadGrid: {
    marginBottom: ".6rem",
  },
  completed:{
    color: "green"
  },
  incompleted:{
    color: theme.palette.error.main
  },
  noEncryptedMessage: {
    margin: '.5rem 0'
  },
  link: {
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    margin: '.5rem 0'
  },
  linkText: {
    color: theme.palette.primary.main,
    marginLeft: '.5rem',
    fontWeight: 500,
    textDecoration: 'none'
  }
}));

export default function FileTypeUploadSection({appId, createHandler, deleteHandler, fileTypeObj, index, fileData, fileTypeLength, employerId = "", includeDivider = true, optional = false, memberId }) {
  const classes = useStyles();
  const theme = useTheme();
  const matchesXsDown = useMediaQuery(theme.breakpoints.down('sm'));
  const filesArray = getFilesByTypeAndEmployer(fileTypeObj.value, fileData, employerId, memberId);

  const renderDocLink = (fileType) => {
    return (
      <Grid className={classes.link}>
        {fileType === 'saveForm' ? 
        <>
          <InsertLink/> 
        <InsertLink/>
          <InsertLink/> 
          <a className={classes.linkText} href={documentLinks['saveForm']} target="_blank" rel="noreferrer">Link to SAVE form</a>
          </>
          : 
          <>
          <Download/>
          <a className={classes.linkText} href={`/api/file/stateForm?fileKey=${fileTypeObj.url}`} target="_new" rel="noreferrer">Download PDF</a>
          </>
          }
    
      </Grid>
    )
  }

  return (
    <>
      <Grid container alignItems="center" spacing={1} paddingBottom={2}>
        <Grid item xs={12} sm={6} className={classes.listItem}>
            <Typography className={classes.listItemTextPrimary}>
                {fileTypeObj.label}
            </Typography>
            <Typography color="textSecondary">
              {fileTypeObj.description}
            </Typography>
            <Typography className={classes.noEncryptedMessage} color="textSecondary">
              {`*Please do not upload any encrypted or password protected files.`} 
            </Typography>
            {(fileTypeObj.value === 'saveForm' || fileTypeObj.value.startsWith('requiredAdditional')) && renderDocLink(fileTypeObj.value)}
            {!optional ? 
              <Typography className={filesArray?.length > 0 ? classes.completed : classes.incompleted}>
                {filesArray?.length > 0 ? "Completed" : "Incomplete"}
              </Typography> 
              :
              <Typography >
                {"Not Required"}
              </Typography> 
            }
        </Grid>
        {filesArray.map((file, fileIdx) => {
          return (
          <Grid item xs={12} sm={fileIdx === 0 ? 6 : 12} container key={fileIdx} className={classes.fileUploadGrid} justifyContent={matchesXsDown ? "flex-start" : "flex-end"}>
            <FileUpload deleteHandler={deleteHandler} fileId={appId} fileType={fileTypeObj.value} fileTypeLabel={fileTypeObj.label} existingFile={file} employerId={employerId} memberId={memberId} />
          </Grid>
          )
        })}
        <Grid item xs={12} sm={filesArray?.length === 0 ? 6 : 12} container className={classes.fileUploadGrid} justifyContent={matchesXsDown ? "flex-start" : "flex-end"}>
          <FileUpload createHandler={createHandler} fileId={appId} fileType={fileTypeObj.value} fileTypeLabel={fileTypeObj.label} employerId={employerId} memberId={memberId} />
        </Grid>  
      </Grid>
      {includeDivider && fileTypeLength !== (index + 1) &&
        <Divider/>
      }
    </>
  )
}