import { Container, Grid, Typography, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { CheckCircle, RotateLeft, Error } from '@mui/icons-material';
import React, { useContext } from 'react'
import { useHistory } from 'react-router';
import { statuses } from '../../constants/appStatuses';
import { formatCurrency, formatDate } from '../../utils/utilFunctions';
import FormSection from '../FormSection';
import StepHeader from '../StepHeader';
import Pledged from './Pledged';
import StatusStepper from './StatusStepper';
import AgencyInfo from './AgencyInfo'
import MessageBanner from '../MessageBanner';
import FeatureFlag from '../FeatureFlag';
import { AuthContext } from '../../context/authContext';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  gridStatus: {
    padding: "2rem 10rem",
    [theme.breakpoints.down('md')]: {
      padding: '2rem 0rem',
    }
  },
  status: {
    fontWeight: "bold",
    fontSize: '1.25rem',
    marginTop: '.5rem'
  },
  statusIcon: {
    width: 60, 
    height: 60
  },
  returnedIcon: {
    border: '.3rem solid',
    borderRadius: '50%',
    borderColor: theme.customColors.errorIcon,
    color: theme.customColors.errorIcon
  },
  deniedIcon: {
    color: theme.customColors.errorIcon
  },
  appText:{
    [theme.breakpoints.down('sm')]: {
      textAlign: "justify",
      fontWeight: "400"
    }
  },
  editButton: {
    width: '50%',
    margin: '1rem 0',
    [theme.breakpoints.down('md')]: {
      width: '80%'
    }
  },
  agencySection: {
    display: 'grid',
    justifyContent: 'center',
    padding: "6rem 0rem 2rem 0rem"
  },
  bullets:{
    paddingInlineStart: '1rem'
  },
  returnedSection: {
    padding: '.5rem 0'
  },
  docLink:{
    color: theme.palette.primary.main,
    fontWeight: "bold",
    textTransform: "none",
    padding: '1rem 0',
    display: 'block',
    textDecoration: "underline",
    [theme.breakpoints.down('sm')]: {
      textAlign: "justify",
      fontWeight: "400"
    }
  },

}));

const getLegalText = (classes, status) => {
  return (
    <>
      <Typography className={classes.appText} variant="subtitle1">{`PLEASE CONTACT YOUR LOCAL LIHEAP OFFICE IMMEDIATELY IF:`}</Typography>
      <Typography className={classes.appText} variant="subtitle1">{`1. You believe the decision on this form is wrong.`}</Typography> 
      <Typography className={classes.appText} variant="subtitle1">{`2. You have any questions or need more information.`}</Typography>
      { status === statuses.APPROVED && 
        <Typography className={classes.appText} variant="subtitle1">{`3. The amount of assistance that you are eligible for is not correctly applied to your energy account.`}</Typography>
      }
      <br/>
      <Typography className={classes.appText} variant="subtitle1">{`FILING AN APPEAL`}</Typography>
      <Typography className={classes.appText} variant="subtitle1">
        {`You  have  the  right to  appeal  any  decision  or  delay  in  decision  by  your  local  LIHEAP  Office. 
          If  you  disagree  with the decision made on your application and want to file an appeal 
          complete the following steps within 30 days of the date of this notice:`}
      </Typography>
      <Typography className={classes.appText} variant="subtitle1">{`1.  Make a copy of the mailed Notice of Action form,`}</Typography>
      <Typography className={classes.appText} variant="subtitle1">{`2.  Get a Request for Appeal Hearing form from the agency where you applied for benefits,`}</Typography>
      <Typography className={classes.appText} variant="subtitle1">{`3.  Please complete the entire form,`}</Typography>
      <Typography className={classes.appText} variant="subtitle1">{`4.  Include a copy of the Notice of Action that you are appealing, and`}</Typography>
      <Typography className={classes.appText} variant="subtitle1">{`5.  Mail to the address found on the Request for Appeal Hearing form.`}</Typography>
    </>
  )
}

const isApplicationEditable = (status) => {
  if (status === statuses.RETURNED || status === statuses.NEW) {
    return true;
  }else{
    return false;
  }
}

const showLegalText = (status) => {
  if (status === statuses.NEW || status === statuses.SUBMITTED || status === statuses.IN_REVIEW || status === statuses.RE_SUBMITTED) {
    return false;
  }else{
    return true;
  }
}

export default function ApplicationStatus({data}) {
  const { userInfo } = useContext(AuthContext);
  const state = userInfo?.stateInfo?.state
  const processSteps = userInfo?.stateInfo?.processSteps;
  const classes = useStyles();
  const history = useHistory();

  const hasEntergyPayment = (statusInfo) => {
    let entergy = false
    statusInfo?.payments?.forEach(payment =>{
      if(payment.provider === 'Entergy Services'){
        entergy = true
      }
    })
    return entergy
  }

  const getStatusData = (status, history) => {
    if([statuses.PENDING, statuses.ERROR, statuses.IN_REVIEW].includes(status)){
      const sortedHistory = history.sort((a, b) => new Date(b.statusDate) - new Date(a.statusDate))
      for(let i=0; i<sortedHistory.length; i++){
        if(sortedHistory[i].status === statuses.SUBMITTED || sortedHistory[i].status === statuses.RE_SUBMITTED){
          return sortedHistory[i]
        }
      }
    }
    else {
      return data
    }
  }
  
  const getStatusIcon = (status) => {
    switch (status) {
      case statuses.NEW:
        return "";
      case statuses.SUBMITTED:
      case statuses.RE_SUBMITTED:
      case statuses.APPROVED:
        return <CheckCircle className={classes.statusIcon} color="primary"/>;
      case statuses.RETURNED:
        return <RotateLeft className={`${classes.returnedIcon} ${classes.statusIcon}`}/>;
      case statuses.DENIED:
      case statuses.ON_PAPER:
        return <Error className={`${classes.deniedIcon} ${classes.statusIcon}`}/>;
      default:
        return 'Unknown status';
    }  
  }

  const getStatusDescription = (status, statusInfo) => {
    switch (status) {
      case statuses.NEW:
        return (
          <Typography variant="subtitle1" className={classes.appText}>
            {`Application is still in progress. Complete required information to continue the process.`}
          </Typography>
        );
      case statuses.SUBMITTED:
      case statuses.RE_SUBMITTED:
      case statuses.IN_REVIEW:
        return (
          <>
            <Typography variant="subtitle1" className={classes.appText}>
              {`Your application has been submitted to your agency. If your application has been submitted after normal business hours, your application will be reviewed and prioritized in the order was received on the next business day.`}
            </Typography>
            <Typography variant="subtitle1" className={classes.appText}>
              {`You will receive a status message once a caseworker has reviewed your application. Please be ready for notices about changes or any new information needed for approval.`}
            </Typography>
          </>
          );
      case statuses.RETURNED:
        let returnedPolicyArray = []
        if(statusInfo?.returnedPolicyNumber){
          returnedPolicyArray = statusInfo.returnedPolicyNumber.map((policy) => ` #${policy}`)
        }
        return (
          <>
            {statusInfo?.returnedNeededInfo &&
              <div className={classes.returnedSection}>
                <Typography variant="subtitle1" className={classes.appText}>
                  {`Your application was delayed because the following information is needed to check your eligibility:`}
                </Typography>
                <Typography variant="subtitle1" className={classes.appText}>
                  {`${statusInfo ? statusInfo.returnedNeededInfo : "N/A"}`}
                </Typography>
              </div>
            }
            {statusInfo?.returnedNeededForms && statusInfo?.returnedNeededForms.length > 0 &&
              <div className={classes.returnedSection}>
                <Typography variant="subtitle1" className={classes.appText}>
                  {`Your application requires one or more additional forms. Follow the link below to see the list of additional form(s) needed:`}
                </Typography>
                <a href={`/liheapform/${state}/additionalDocumentUpload/${data.id}`} className={classes.docLink}>{`Link to Additional Documents`}</a>
              </div>
            }
            {statusInfo?.returnedDueDate && statusInfo?.returnedPolicyNumber &&
              <div className={classes.returnedSection}>
                <Typography variant="subtitle1" className={classes.appText}>
                  {`Your application will be denied if this information is not received at this office by ${statusInfo ? formatDate(statusInfo.returnedDueDate) : "N/A"} 
                    based on policy${statusInfo ? returnedPolicyArray : "N/A"}`}
                </Typography>
              </div>
            }
          </>
        );
      case statuses.DENIED:
        const deniedWithdrawn = statusInfo ? statusInfo.deniedReason === "withdrawal" : false
        let deniedPolicyArray = []
        if(statusInfo?.deniedPolicyNumber){
          deniedPolicyArray = statusInfo.deniedPolicyNumber.map((policy) => ` #${policy}`)
        }
        if (deniedWithdrawn){
          return(
            <>
              <Typography variant="subtitle1" className={classes.appText}>
                {`Your application has been denied based on your request to withdraw (policy #4040).`}
              </Typography>
              <Typography variant="subtitle1" className={classes.appText}>
                {`However, you may reapply anytime during the application period. `}
              </Typography>
          </>
          )
        } else{
          return (
            <>
              <Typography className={classes.appText} variant="subtitle1">
                {`Your application has been denied based on Policy${statusInfo ? deniedPolicyArray : "N/A"}.`}
              </Typography>
              <Typography className={classes.appText} variant="subtitle1">
                  {`A copy of your Notice of Action will be mailed to your mailing address listed on the application.
                  Your application has been denied because of the following:`}
              </Typography>
              <Typography className={classes.appText} variant="subtitle1">
                {`${statusInfo ? statusInfo.deniedReasonSpecification : "N/A"}`}
              </Typography>
            </>
            );
        }
        case statuses.APPROVED:
          const payments = statusInfo?.payments
          if(payments && payments.length > 1){
            return (
              <>
                <Typography className={classes.appText} variant="subtitle1">
                  <ul className={classes.bullets}>
                    <li>{`Your application has been approved for a total of ${statusInfo ? formatCurrency(statusInfo.pledgeAmount) : "N/A"}.`}</li>
                    {payments.map((payment, index) => (
                      <li key={index}>{`${formatCurrency(payment.amount)} will be sent to ${payment.provider}.`}</li>
                    ))}
                    <li>{`You are responsible for any remaining balance on your account.`}</li>
                  </ul>
                </Typography>
              </>
              );
          }
          else{
          return (
            <>
              <Typography className={classes.appText} variant="subtitle1">
                <ul className={classes.bullets}>
                  <li>{`Your application has been approved for a total of ${statusInfo ? formatCurrency(statusInfo.pledgeAmount) : "N/A"}.`}</li>
                  <li>{`A check for this amount will be sent to ${payments[0]?.provider} and applied to your account.`}</li>
                  <li>{`You are responsible for any remaining balance on your account.`}</li>
                </ul>
              </Typography>
            </>
            );
          }
        case statuses.ON_PAPER:
          return (
            <>
              <Typography className={classes.appText} variant="subtitle1">
                {`Although you applied online, your application was completed in person. You should have been notified of your approval or denial status by your caseworker. If approved, a payment will be applied to your utility account.`}
              </Typography>
              <Typography className={classes.appText} variant="subtitle1">
                {`For any additional questions or concerns, please contact your local agency using the information below.`}
              </Typography>
            </>
          );
      default:
        return 'Unknown status';
    }  
  }

  // if status is Pending, Error or In-Review, return last Submitted/Re-Submitted data 
  // else returns data 
  const displayData = getStatusData(data.status, data.statusHistory);

  const editApplication = () => {
    history.push({
      // TODO: Make this better
      pathname: `/liheapform/${state}/${processSteps[0].location}/${data.id}`
    })
  }
  return (
    <>
    <Grid xs={12} item container justifyContent="center" direction="column" className={classes.mainContainer}> 
      <MessageBanner messageType="ApplicationStatus" />
    </Grid>
    <Container className={classes.mainContainer}>
      <Grid container spacing={1}>
        <StepHeader 
          title="Your application status"
          desc="You will be able to check your application status changes online as well as get notified along the process."
          showInfo={true}
        />
        <Grid item xs={12}>
          <StatusStepper 
            currentStatus={displayData.status} 
            currentStatusDate={displayData.statusDate}
            statusInfo={displayData.statusInfo}
            statusHistory={data.statusHistory}
          />
        </Grid>
        { displayData &&
          <FormSection>
            <Grid container justifyContent="center" className={classes.gridStatus} spacing={4}> 
              <Grid item xs={12} container alignItems="center" alignContent="center" direction="column" spacing={2}>
                {getStatusIcon(displayData.status)}
                  { displayData.status === statuses.APPROVED ? 
                      <Pledged amount={displayData?.statusInfo?.pledgeAmount}/>
                    : 
                    <Typography className={classes.status}>
                      {displayData.status === statuses.ON_PAPER ? 'Completed In Person' : displayData.status}
                    </Typography>
                  }
                <Typography variant="subtitle1">
                  {displayData?.statusDate ? formatDate(displayData.statusDate, "MM/dd/yyyy") : ''}
                </Typography> 
              </Grid>
              <Grid item xs={12}>
                {getStatusDescription(displayData.status, displayData.statusInfo)}
              </Grid>  
              { isApplicationEditable(displayData.status) &&
                <FeatureFlag feature={`Update${displayData.status}Application`} hideOnDisabled={true}>
                  <Grid item xs={12} container justifyContent="center">
                    <Button className={classes.editButton} variant="contained" color="primary" onClick={editApplication}>
                      Edit Application
                    </Button>
                  </Grid>     
                </FeatureFlag>
              } 
              { (displayData.status === statuses.APPROVED && hasEntergyPayment(displayData.statusInfo)) &&
                <Grid item xs={12}>
                  <Typography variant="subtitle1">{`ABOUT PLEDGE`}</Typography>
                  <Typography variant="subtitle1">
                    {`A pledge is a promise to pay. Please continue to pay your monthly current
                      charges that are billed after the date of this pledge. Your account will be
                      protected from a shut off for 60 days if paid in full.`}
                  </Typography>
                </Grid>  
              } 
              { showLegalText(displayData.status) && 
                <Grid item xs={12}>
                  {getLegalText(classes, displayData.status)}
                </Grid>
              }
            </Grid>
            <Grid item xs={12} className={classes.agencySection}>
              <AgencyInfo/>
            </Grid>
          </FormSection>
        }
      </Grid>
    </Container>
    </>
  )
}
