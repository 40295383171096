import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography } from '@mui/material';
import { legalTextBlock1, legalTextBlock2 } from '../../constants/home-page/legal';

const useStyles = makeStyles((theme) => ({
    mainContainer:{
        margin: '2.5rem 0rem',
        width: 'fit-content',
        padding: '2rem 6rem !important',
        [theme.breakpoints.down('lg')]: {
            padding: '1rem 2rem!important',
            margin: 'auto'
          },
      },
      text: {
        color: '#000',
        fontSize: '1rem',
        margin: '2rem 0rem'
      },
}));

export default function Legal() {
  const classes = useStyles();

  return (
    <Grid container spacing={1} className={classes.mainContainer}>
        <Typography variant="h6" className={classes.text}>
            {legalTextBlock1}
            <a href="https://www.adeq.state.ar.us/energy/initiatives/pdfs/LIHEAP_Flyer.pdf" target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}> Click here for information</a> 
        </Typography>
        <Typography variant="h6" className={classes.text}>
            {legalTextBlock2}
        </Typography>
    </Grid>
)}

