export const stateDocsLinks = {
  "eligibilityChart": "https://www.adeq.state.ar.us/energy/initiatives/pdfs/LIHEAP_Eligibility_Chart.pdf",
  "energySourceCharts": {
    "electricity": "https://www.adeq.state.ar.us/energy/initiatives/pdfs/LIHEAP-ElectricityBenefitChart.pdf",
    "fuelOilOrKerosene": "https://www.adeq.state.ar.us/energy/initiatives/pdfs/LIHEAP-FuelOilBenefitChart.pdf",
    "naturalGas": "https://www.adeq.state.ar.us/energy/initiatives/pdfs/LIHEAP-NaturalGasBenefitChart.pdf",
    "propane": "https://www.adeq.state.ar.us/energy/initiatives/pdfs/LIHEAP-PropaneBenefitChart.pdf",
    "other": "https://www.adeq.state.ar.us/energy/initiatives/pdfs/LIHEAP-OtherWoodPelletsBenefitChart.pdf"
  }
}
