import * as Yup from 'yup';

const requiredError = "Required Information";

export const AgencyNoteValidationSchema = Yup.object().shape({
  note: Yup.string().required(requiredError)
  })

export const ApprovedPaymentsValidationSchema = Yup.object().shape({
  amount: Yup.string().required(requiredError),
  provider: Yup.string().required(requiredError),
  type: Yup.string().required(requiredError),
  source: Yup.string().required(requiredError)
})

export const userNameValidationSchema = Yup.object().shape({
  firstName: Yup.string().required(requiredError),
  lastName: Yup.string().required(requiredError)
  })
  