import * as Yup from 'yup';

const requiredError = "Required Information";

export const personalAndContantInfoSchema = Yup.object().shape({
  submittingAs: Yup.string().required(requiredError), 
  preparerName: Yup.string().when('submittingAs', {
    is: (submittingAs) => submittingAs === 'someoneElse',
    then: Yup.string().required(requiredError)
  }), 
  firstName: Yup.string().required(requiredError),
  lastName: Yup.string().required(requiredError),
  ssn: Yup.string().required(requiredError).matches(/^\d{9}$/, "Invalid SSN. Must contain 9 numbers"),
  birthDate: Yup.date().typeError('Invalid Date').required(requiredError).min('1900-01-01', 'Invalid Date').max('2099-12-31', 'Invalid Date'),
  phoneNumber: Yup.string().required(requiredError).length(14, "Invalid Phone Number"),
  email: Yup.string().required(requiredError).email("Invalid Email"),
  serviceStreetAddress: Yup.string().required(requiredError),
  serviceCity: Yup.string().required(requiredError),
  serviceState: Yup.string().required(requiredError),
  serviceZip: Yup.string().required(requiredError).length(5, "Invalid Zip"),
  serviceCountyOrParish: Yup.string().required(requiredError),
  mailingStreetAddress: Yup.string().required(requiredError),
  mailingCity: Yup.string().required(requiredError),
  mailingState: Yup.string().required(requiredError),
  mailingZip: Yup.string().required(requiredError).length(5, "Invalid Zip"),
  mailingCountyOrParish: Yup.string().required(requiredError),
  gender: Yup.string().required(requiredError),
  race: Yup.array().required(requiredError).min(1, requiredError),
  ethnicity: Yup.string().required(requiredError),
  disabled: Yup.string().required(requiredError),
  veteranOrActivelyServing: Yup.string().required(requiredError)
});

export const memberPersonalInfoSchema = Yup.object().shape({
  firstName: Yup.string().required(requiredError),
  lastName: Yup.string().required(requiredError),
  ssn: Yup.string().required(requiredError).matches(/^\d{9}$/, "Invalid SSN. Must contain 9 numbers"),
  birthDate: Yup.date().typeError('Invalid Date').required(requiredError).min('1900-01-01', 'Invalid Date').max('2099-12-31', 'Invalid Date'),
  relationToApplicant: Yup.string().required(requiredError),
  gender: Yup.string().required(requiredError),
  race: Yup.array().required(requiredError).min(1, requiredError),
  ethnicity: Yup.string().required(requiredError),
  disabled: Yup.string().required(requiredError),
  veteranOrActivelyServing: Yup.string().required(requiredError)
});

export const birthDateOnEditSchema = Yup.object().shape({
  birthDate: Yup.date().typeError('Invalid Date').required(requiredError).min('1900-01-01', 'Invalid Date').max('2099-12-31', 'Invalid Date'),
});

export const incomeSchema = Yup.object().shape({
  workStatus: Yup.string().required(requiredError),
  unearnedBenefits: Yup.array().required(requiredError).min(1, requiredError),
  childSupportPayFrequency: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("childSupport"),
      then: Yup.string().required(requiredError)
    }),
  childSupportMonthlyIncomeAmount: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("childSupport"),
      then: Yup.string().required(requiredError)
    }),
  contributionsPayFrequency: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("contributions"),
      then: Yup.string().required(requiredError)
    }),
  contributionsMonthlyIncomeAmount: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("contributions"),
      then: Yup.string().required(requiredError)
    }),
  fosterCarePaymentsPayFrequency: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("fosterCarePayments"),
      then: Yup.string().required(requiredError)
    }),
  fosterCarePaymentsMonthlyIncomeAmount: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("fosterCarePayments"),
      then: Yup.string().required(requiredError)
    }),
  interestAndDividensPayFrequency: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("interestAndDividens"),
      then: Yup.string().required(requiredError)
    }),
  interestAndDividensMonthlyIncomeAmount: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("interestAndDividens"),
      then: Yup.string().required(requiredError)
    }),
  partBHudUtilityPaymentsGreaterEqualToFiftyDollarsPayFrequency: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("partBHudUtilityPaymentsGreaterEqualToFiftyDollars"),
      then: Yup.string().required(requiredError)
    }),
  partBHudUtilityPaymentsGreaterEqualToFiftyDollarsMonthlyIncomeAmount: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("partBHudUtilityPaymentsGreaterEqualToFiftyDollars"),
      then: Yup.string().required(requiredError)
    }),
  pensionsAndAnnuitiesPayFrequency: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("pensionsAndAnnuities"),
      then: Yup.string().required(requiredError)
    }),
  pensionsAndAnnuitiesMonthlyIncomeAmount: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("pensionsAndAnnuities"),
      then: Yup.string().required(requiredError)
    }),
  socialSecurityPayFrequency: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("socialSecurity"),
      then: Yup.string().required(requiredError)
    }),
  socialSecurityMonthlyIncomeAmount: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("socialSecurity"),
      then: Yup.string().required(requiredError)
    }),
  retirementAndVeteranBenefitsPayFrequency: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("retirementAndVeteranBenefits"),
      then: Yup.string().required(requiredError)
    }),
  retirementAndVeteranBenefitsMonthlyIncomeAmount: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("retirementAndVeteranBenefits"),
      then: Yup.string().required(requiredError)
    }),
  ssdiPayFrequency: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("ssdi"),
      then: Yup.string().required(requiredError)
    }),
  ssdiMonthlyIncomeAmount: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("ssdi"),
      then: Yup.string().required(requiredError)
    }),
  ssiPayFrequency: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("ssi"),
      then: Yup.string().required(requiredError)
    }),
  ssiMonthlyIncomeAmount: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("ssi"),
      then: Yup.string().required(requiredError)
    }),
  tanfPayFrequency: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("tanf"),
      then: Yup.string().required(requiredError)
    }),
  tanfMonthlyIncomeAmount: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("tanf"),
      then: Yup.string().required(requiredError)
    }),
  teaPayFrequency: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("tea"),
      then: Yup.string().required(requiredError)
    }),
  teaMonthlyIncomeAmount: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("tea"),
      then: Yup.string().required(requiredError)
    }),
  unemploymentInsurancePayFrequency: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("unemploymentInsurance"),
      then: Yup.string().required(requiredError)
    }),
  unemploymentInsuranceMonthlyIncomeAmount: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("unemploymentInsurance"),
      then: Yup.string().required(requiredError)
    }),
  workersCompPayFrequency: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("workersComp"),
      then: Yup.string().required(requiredError)
    }),
  workersCompMonthlyIncomeAmount: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("workersComp"),
      then: Yup.string().required(requiredError)
    }),
  otherPayFrequency: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("other"),
      then: Yup.string().required(requiredError)
    }),
  otherMonthlyIncomeAmount: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("other"),
      then: Yup.string().required(requiredError)
    }),
  otherUnearnedDetails: Yup.string()
    .when('unearnedBenefits', {
      is: (unearnedBenefits) => unearnedBenefits?.includes("other"),
      then: Yup.string().required(requiredError)
    }),
});

export const employerSchema = Yup.object().shape({
  name: Yup.string().required(requiredError),
  payFrequency: Yup.string().required(requiredError),
  monthlyIncomeAmount: Yup.string().required(requiredError)
});

export const addEditEmployerSchema = Yup.object().shape({
  employers: Yup.array()
    .when("workStatus", {
      is: (workStatus) => workStatus === "employed",
      then: Yup.array().required(requiredError).of(
        employerSchema
      )
    }),
  hasBeenEmployed: Yup.string()
    .when('workStatus', {
      is: (workStatus) => workStatus === "unemployed",
      then: Yup.string().required(requiredError)
    }),
  lastEmployedDate: Yup.date().nullable()
    .when(['workStatus', 'hasBeenEmployed'], {
      is: (workStatus, hasBeenEmployed) => workStatus === "unemployed" && hasBeenEmployed === "yes",
      then: Yup.date().typeError('Invalid Date').required(requiredError).min('1900-01-01', 'Invalid Date').max('2099-12-31', 'Invalid Date')
    }),
  lastEmployerName: Yup.string()
    .when(['workStatus', 'hasBeenEmployed'], {
      is: (workStatus, hasBeenEmployed) => workStatus === "unemployed" && hasBeenEmployed === "yes",
      then: Yup.string().required(requiredError)
    })
});

export const memberAddEditEmployerSchema = Yup.object().shape({
  employers: Yup.array()
    .when("workStatus", {
      is: (workStatus) => workStatus === "employed",
      then: Yup.array().required(requiredError).of(
        employerSchema
      )
    }),
  hasBeenEmployed: Yup.string()
    .when('workStatus', {
      is: (workStatus) => workStatus === "unemployed",
      then: Yup.string().required(requiredError)
    }),
  lastEmployedDate: Yup.date().nullable()
    .when(['workStatus', 'hasBeenEmployed'], {
      is: (workStatus, hasBeenEmployed) => workStatus === "unemployed" && hasBeenEmployed === "yes",
      then: Yup.date().typeError('Invalid Date').required(requiredError).min('1900-01-01', 'Invalid Date').max('2099-12-31', 'Invalid Date')
    }),
  lastEmployerName: Yup.string()
    .when(['workStatus', 'hasBeenEmployed'], {
      is: (workStatus, hasBeenEmployed) => workStatus === "unemployed" && hasBeenEmployed === "yes",
      then: Yup.string().required(requiredError)
    })
});

export const lastEmployedDateOnEditSchema = Yup.object().shape({
  lastEmployedDate: Yup.date().typeError('Invalid Date')
    .when(['workStatus', 'hasBeenEmployed'], {
      is: (workStatus, hasBeenEmployed) => workStatus === "unemployed" && hasBeenEmployed === "yes",
      then: Yup.date().typeError('Invalid Date').required(requiredError).min('1900-01-01', 'Invalid Date').max('2099-12-31', 'Invalid Date')
    })
});

export const householdMemberSchema = memberPersonalInfoSchema.concat(incomeSchema).concat(memberAddEditEmployerSchema);

export const utilityInfoSchema = Yup.object().shape({
  rentOrOwn: Yup.string().required(requiredError),
  rentEnergyCostIncluded: Yup.string()
    .when('rentOrOwn', {
      is: (rentOrOwn) => rentOrOwn === "rent",
      then: Yup.string().required(requiredError)
    }),
  rentLandlordName: Yup.string()
    .when(['rentOrOwn', 'rentEnergyCostIncluded'], {
      is: (rentOrOwn, rentEnergyCostIncluded) => rentOrOwn === "rent" && rentEnergyCostIncluded === "yes",
      then: Yup.string().required(requiredError)
    }),
  rentLandlordPhoneNo: Yup.string()
    .when(['rentOrOwn', 'rentEnergyCostIncluded'], {
      is: (rentOrOwn, rentEnergyCostIncluded) => rentOrOwn === "rent" && rentEnergyCostIncluded === "yes",
      then: Yup.string().required(requiredError).length(14, "Invalid Phone Number")
    }),

  residenceAllElectric: Yup.string().required(requiredError),
  electricityProvider: Yup.string().required(requiredError),
  otherElectricityProvider: Yup.string()
    .when('electricityProvider', {
      is: (electricityProvider) => electricityProvider === "other",
      then: Yup.string().required(requiredError)
    }),
  electricityAcctNo: Yup.string().required(requiredError),
  electricityAccountClosed: Yup.string().required(requiredError),
  electricityInApplicantName: Yup.string().required(requiredError),
  electricityAccountOwnerName: Yup.string()
    .when('electricityInApplicantName', {
      is: (electricityInApplicantName) => electricityInApplicantName === "no",
      then: Yup.string().required(requiredError)
    }),
  electricityAccountOwnerLiveWithApplicant: Yup.string()
    .when('electricityInApplicantName', {
      is: (electricityInApplicantName) => electricityInApplicantName === "no",
      then: Yup.string().required(requiredError)
    }),
  electricityAccountOwnerRelationToApplicant: Yup.string()
    .when('electricityInApplicantName', {
      is: (electricityInApplicantName) => electricityInApplicantName === "no",
      then: Yup.string().required(requiredError)
    }),

  primarySource: Yup.string().required(requiredError),
  primarySourceAccountClosed: Yup.string()
    .when('primarySource', {
      is: (primarySource) => primarySource !== "electricity",
      then: Yup.string().required(requiredError)
    }),
  primarySourceInApplicantName: Yup.string()
    .when('primarySource', {
      is: (primarySource) => primarySource !== "electricity",
      then: Yup.string().required(requiredError)
    }),
  primarySourceAccountOwnerName: Yup.string()
    .when(['primarySource', 'primarySourceInApplicantName'], {
      is: (primarySource, primarySourceInApplicantName) => primarySource !== "electricity" && primarySourceInApplicantName === "no",
      then: Yup.string().required(requiredError)
    }),
  primarySourceAccountOwnerLiveWithApplicant: Yup.string()
    .when(['primarySource', 'primarySourceInApplicantName'], {
      is: (primarySource, primarySourceInApplicantName) => primarySource !== "electricity" && primarySourceInApplicantName === "no",
      then: Yup.string().required(requiredError)
    }),
  primarySourceAccountOwnerRelationToApplicant: Yup.string()
    .when(['primarySource', 'primarySourceInApplicantName'], {
      is: (primarySource, primarySourceInApplicantName) => primarySource !== "electricity" && primarySourceInApplicantName === "no",
      then: Yup.string().required(requiredError)
    }),

  naturalGasProvider: Yup.string()
    .when('primarySource', {
      is: (primarySource) => primarySource === "naturalGas",
      then: Yup.string().required(requiredError)
    })
    .when('secondarySources', {
      is: (secondarySources) => secondarySources?.includes("naturalGas"),
      then: Yup.string().required(requiredError)
    }),
  naturalGasAcctNo: Yup.string()
    .when('primarySource', {
      is: (primarySource) => primarySource === "naturalGas",
      then: Yup.string().required(requiredError)
    })
    .when('secondarySources', {
      is: (secondarySources) => secondarySources?.includes("naturalGas"),
      then: Yup.string().required(requiredError)
    }),
  naturalGasAccountClosed: Yup.string()
    .when('secondarySources', {
      is: (secondarySources) => secondarySources?.includes("naturalGas"),
      then: Yup.string().required(requiredError)
    }),
  naturalGasInApplicantName: Yup.string()
    .when('secondarySources', {
      is: (secondarySources) => secondarySources?.includes("naturalGas"),
      then: Yup.string().required(requiredError)
    }),
  naturalGasAccountOwnerName: Yup.string()
    .when(['secondarySources', 'naturalGasInApplicantName'], {
      is: (secondarySources, naturalGasInApplicantName) => secondarySources?.includes("naturalGas") && naturalGasInApplicantName === "no",
      then: Yup.string().required(requiredError)
    }),
  naturalGasAccountOwnerLiveWithApplicant: Yup.string()
    .when(['secondarySources', 'naturalGasInApplicantName'], {
      is: (secondarySources, naturalGasInApplicantName) => secondarySources?.includes("naturalGas") && naturalGasInApplicantName === "no",
      then: Yup.string().required(requiredError)
    }),
  naturalGasAccountOwnerRelationToApplicant: Yup.string()
    .when(['secondarySources', 'naturalGasInApplicantName'], {
      is: (secondarySources, naturalGasInApplicantName) => secondarySources?.includes("naturalGas") && naturalGasInApplicantName === "no",
      then: Yup.string().required(requiredError)
    }),

  propaneProvider: Yup.string()
    .when('primarySource', {
      is: (primarySource) => primarySource === "propane",
      then: Yup.string().required(requiredError)
    })
    .when('secondarySources', {
      is: (secondarySources) => secondarySources?.includes("propane"),
      then: Yup.string().required(requiredError)
    }),
  propaneAcctNo: Yup.string()
    .when('primarySource', {
      is: (primarySource) => primarySource === "propane",
      then: Yup.string().required(requiredError)
    })
    .when('secondarySources', {
      is: (secondarySources) => secondarySources?.includes("propane"),
      then: Yup.string().required(requiredError)
    }),
  propaneAccountClosed: Yup.string()
    .when('secondarySources', {
      is: (secondarySources) => secondarySources?.includes("propane"),
      then: Yup.string().required(requiredError)
    }),
  propaneInApplicantName: Yup.string()
    .when('secondarySources', {
      is: (secondarySources) => secondarySources?.includes("propane"),
      then: Yup.string().required(requiredError)
    }),
  propaneAccountOwnerName: Yup.string()
    .when(['secondarySources', 'propaneInApplicantName'], {
      is: (secondarySources, propaneInApplicantName) => secondarySources?.includes("propane") && propaneInApplicantName === "no",
      then: Yup.string().required(requiredError)
    }),
  propaneAccountOwnerLiveWithApplicant: Yup.string()
    .when(['secondarySources', 'propaneInApplicantName'], {
      is: (secondarySources, propaneInApplicantName) => secondarySources?.includes("propane") && propaneInApplicantName === "no",
      then: Yup.string().required(requiredError)
    }),
  propaneAccountOwnerRelationToApplicant: Yup.string()
    .when(['secondarySources', 'propaneInApplicantName'], {
      is: (secondarySources, propaneInApplicantName) => secondarySources?.includes("propane") && propaneInApplicantName === "no",
      then: Yup.string().required(requiredError)
    }),

  otherHeatSource: Yup.string()
    .when('primarySource', {
      is: (primarySource) => primarySource === "other",
      then: Yup.string().required(requiredError)
    })
    .when('secondarySources', {
      is: (secondarySources) => secondarySources?.includes("other"),
      then: Yup.string().required(requiredError)
    }),
  otherAccountClosed: Yup.string()
    .when('secondarySources', {
      is: (secondarySources) => secondarySources?.includes("other"),
      then: Yup.string().required(requiredError)
    }),
  otherInApplicantName: Yup.string()
    .when('secondarySources', {
      is: (secondarySources) => secondarySources?.includes("other"),
      then: Yup.string().required(requiredError)
    }),
  otherAccountOwnerName: Yup.string()
    .when(['secondarySources', 'otherInApplicantName'], {
      is: (secondarySources, otherInApplicantName) => secondarySources?.includes("other") && otherInApplicantName === "no",
      then: Yup.string().required(requiredError)
    }),
  otherAccountOwnerLiveWithApplicant: Yup.string()
    .when(['secondarySources', 'otherInApplicantName'], {
      is: (secondarySources, otherInApplicantName) => secondarySources?.includes("other") && otherInApplicantName === "no",
      then: Yup.string().required(requiredError)
    }),
  otherAccountOwnerRelationToApplicant: Yup.string()
    .when(['secondarySources', 'otherInApplicantName'], {
      is: (secondarySources, otherInApplicantName) => secondarySources?.includes("other") && otherInApplicantName === "no",
      then: Yup.string().required(requiredError)
    }),
});

export const utilitySituationSchema = Yup.object().shape({
  utilityAssistanceNeeded: Yup.array().required(requiredError).min(1, requiredError),
  serviceShutOff: Yup.string().required(requiredError),
  disconnectNotice: Yup.string().required(requiredError),
  crisisLifeThreatening:  Yup.string().required(requiredError),
  crisisExplaination: Yup.string().when('crisisLifeThreatening', {
    is: (crisisLifeThreatening) => crisisLifeThreatening === 'yes',
    then: Yup.string().required(requiredError)
  }),
  crisisSituations: Yup.array().required(requiredError).min(1, requiredError),
  overdueBalance: Yup.string().when('electricityProvider', {
    is: (electricityProvider) => electricityProvider === "other",
    then: Yup.string().required(requiredError)
  }),
  overdueBalanceValue: Yup.string().when(['electricityProvider', 'overdueBalance'],{
    is: (electricityProvider, overdueBalance) => electricityProvider === "other" && overdueBalance === "yes",
    then: Yup.string().required(requiredError)
  }),
});

export const financialResourcesSchema = Yup.object().shape({
  cashOnHand: Yup.string().required(requiredError),
  cashOnHandAmount: Yup.string()
    .when('cashOnHand', {
      is: (cashOnHand) => cashOnHand === "yes",
      then: Yup.string().required(requiredError)
    }),
  cashOnHandLocation: Yup.string()
    .when('cashOnHand', {
      is: (cashOnHand) => cashOnHand === "yes",
      then: Yup.string().required(requiredError)
    }),
  checkingAccount: Yup.string().required(requiredError),
  checkingAccountBankName: Yup.string()
    .when('checkingAccount', {
      is: (checkingAccount) => checkingAccount === "yes",
      then: Yup.string().required(requiredError)
    }),
  checkingAccountAmount: Yup.string()
    .when('checkingAccount', {
      is: (checkingAccount) => checkingAccount === "yes",
      then: Yup.string().required(requiredError)
    }),
  otherBankAccount: Yup.string().required(requiredError),
  otherBankAccountBankName: Yup.string()
    .when('otherBankAccount', {
      is: (otherBankAccount) => otherBankAccount === "yes",
      then: Yup.string().required(requiredError)
    }),
  otherBankAccountAmount: Yup.string()
    .when('otherBankAccount', {
      is: (otherBankAccount) => otherBankAccount === "yes",
      then: Yup.string().required(requiredError)
    }),
  certificateOfDeposit: Yup.string().required(requiredError),
  certificateOfDepositLocation: Yup.string()
    .when('certificateOfDeposit', {
      is: (certificateOfDeposit) => certificateOfDeposit === "yes",
      then: Yup.string().required(requiredError)
    }),
  certificateOfDepositAmount: Yup.string()
    .when('certificateOfDeposit', {
      is: (certificateOfDeposit) => certificateOfDeposit === "yes",
      then: Yup.string().required(requiredError)
    }),
  otherFinancialResources: Yup.string().required(requiredError),
  otherFinancialResourcesLocation: Yup.string()
    .when('otherFinancialResources', {
      is: (otherFinancialResources) => otherFinancialResources === "yes",
      then: Yup.string().required(requiredError)
    }),
  otherFinancialResourcesAmount: Yup.string()
    .when('otherFinancialResources', {
      is: (otherFinancialResources) => otherFinancialResources === "yes",
      then: Yup.string().required(requiredError)
    }),
});
