import React, {useState, useContext, useEffect} from 'react';
import { Grid, IconButton, Typography, Toolbar, Drawer, Box, AppBar, Button, ClickAwayListener } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Folder, BarChart, Settings, AdminPanelSettings, ArrowCircleLeftOutlined, Menu, Description } from '@mui/icons-material'
import { AuthContext } from "../context/authContext";
import { useHistory } from 'react-router';
import { agencyPermissions } from '../constants/agencyPermissions';
import { statePermissions } from '../constants/statePermissions';
import { useLocation } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    appBar: {
      left: 0,
      right: 'auto',
      height: '100%',
      width: '5rem',
      borderRadius: '0 1rem 1rem 0',
    },
    toolbar: {
        padding: 0,
        alignItems: 'center',
        flexDirection: 'column',
    },
    drawer:{
        '& > .MuiPaper-root': {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            borderRadius: '0 1rem 1rem 0',
            width: '17rem'
        }
    },
    menu: {
        width: '100%',
        paddingTop: '2rem',
    },
    section: {
        width: '100%',
        marginTop: '6rem',
    },
    button: {
        color: '#fff',
        textTransform: 'none',
        display: 'flex',
        alignItems: 'center',
        padding: '1rem',
        width: '100%',
        justifyContent: 'flex-start',
        borderRadius: '0', 
        '&:hover':{
            backgroundColor: theme.customColors.navBar.highlightColor,
            borderRadius: '0', 
          }
    },
    iconButtton: {
        width: '100%',
        color: '#fff',
        padding: '.5rem',
        display: 'initial',
        borderRadius: '0', 
        '&:hover':{
            backgroundColor: theme.customColors.navBar.highlightColor,
            borderRadius: '0', 
          }
    },
    selectedOption: {
        backgroundColor: theme.customColors.navBar.highlightColor,
        borderRadius: '0',  
    },
    label: {
        marginLeft: '.6rem',
        fontSize: '1rem',
        color: '#fff'
    },
    iconLabel: {
        fontSize: '.75rem',
        color: '#fff'
    }
  }));

export default function NavigationBar() {
  const history = useHistory();
  const location = useLocation();

  const currentLocation = location.pathname  

  const { userInfo, logout } = useContext(AuthContext);
  const userPermissions = userInfo?.permissions ? userInfo.permissions : [];
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(currentLocation)

  useEffect(() => {
    setSelectedOption(currentLocation)
  }, [currentLocation]);


  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleSignOut = () => {
    logout()
    handleDrawerClose()
  }

  return (
      <>
    {((userInfo && userInfo.userType === "agency") ||  (userInfo && userInfo.userType === "state")) &&
    <ClickAwayListener onClickAway={handleDrawerClose}>
    <Box>
      <AppBar className={classes.appBar} open={open} >
        <Toolbar className={classes.toolbar}>
        <Grid className={classes.menu}>
            <IconButton 
                id="menu" 
                aria-label="Menu" 
                className={classes.iconButtton} 
                onClick={handleDrawerOpen}
            >
                <Menu/> 
            </IconButton>
        </Grid>
        <Grid className={classes.section}>
            {(userPermissions.includes(agencyPermissions.CLIENT_INTAKE_DASHBOARD_EDIT) || userPermissions.includes(agencyPermissions.CLIENT_INTAKE_DASHBOARD_VIEW)) &&
                <IconButton 
                    id="dashboard" 
                    aria-label="Dashboard" 
                    className={selectedOption === '/agency/home' || selectedOption === '/agency/applicationReview' ? `${classes.selectedOption} ${classes.iconButtton}` : classes.iconButtton} 
                    onClick={() => history.push(`/agency/home`)}
                >
                    <Folder/>
                    <Typography variant='subtitle1' className={classes.iconLabel}>Dashboard</Typography> 
                </IconButton>
            }
            {userPermissions.includes(agencyPermissions.REPORTS_VIEW) &&
                <IconButton 
                    id="reports" 
                    aria-label="Reports" 
                    className={selectedOption === '/agency/reports' ? `${classes.selectedOption} ${classes.iconButtton}` : classes.iconButtton}
                    onClick={() => history.push(`/agency/reports`)}
                >
                    <BarChart/> 
                    <Typography variant='subtitle1' className={classes.iconLabel}>Reports</Typography> 
                </IconButton>
            }
            {userPermissions.includes(agencyPermissions.AGENCY_ADMIN_EDIT) &&
                <IconButton 
                    id="adminPanel" 
                    aria-label="Admin Panel" 
                    className={selectedOption === '/agency/admin' ? `${classes.selectedOption} ${classes.iconButtton}` : classes.iconButtton}
                    onClick={() => history.push(`/agency/admin`)}
                >
                    <AdminPanelSettings/> 
                    <Typography variant='subtitle1' className={classes.iconLabel}>Admin Panel</Typography> 
                </IconButton>
            }
            {userPermissions.includes(statePermissions.STATE_REVIEW_APPLICATION_VIEW) &&
                <IconButton 
                    id="stateDashboard" 
                    aria-label="State Dashboard" 
                    className={selectedOption === '/state/dashboard' ? `${classes.selectedOption} ${classes.iconButtton}` : classes.iconButtton}
                    onClick={() => history.push(`/state/dashboard`)}
                >
                    <Folder/>
                    <Typography variant='subtitle1' className={classes.iconLabel}>State Dashboard</Typography> 
                </IconButton>
            }
            {userPermissions.includes(statePermissions.STATE_REPORTS_VIEW) &&
                <IconButton 
                    id="stateReports" 
                    aria-label="State Reports" 
                    className={selectedOption === '/state/reports' ? `${classes.selectedOption} ${classes.iconButtton}` : classes.iconButtton}
                    onClick={() => history.push(`/state/reports`)}
                >
                    <BarChart/> 
                    <Typography variant='subtitle1' className={classes.iconLabel}>State Reports</Typography> 
                </IconButton>
            }
            {(userPermissions.includes(statePermissions.STATE_FORM_VIEW) || userPermissions.includes(statePermissions.STATE_FORM_EDIT)) &&
                <IconButton 
                    id="stateForms" 
                    aria-label="State Forms" 
                    className={selectedOption === '/state/forms' ? `${classes.selectedOption} ${classes.iconButtton}` : classes.iconButtton}
                    onClick={() => history.push(`/state/forms`)}
                >
                    <Description/> 
                    <Typography variant='subtitle1' className={classes.iconLabel}>State Forms</Typography> 
                </IconButton>
            }
            {(userPermissions.includes(statePermissions.STATE_CONFIG_VIEW) || userPermissions.includes(statePermissions.STATE_CONFIG_EDIT)) &&
                <IconButton 
                    id="stateSettings" 
                    aria-label="State Settings" 
                    className={selectedOption === '/state/settings' ? `${classes.selectedOption} ${classes.iconButtton}` : classes.iconButtton}
                    onClick={() => history.push(`/state/settings`)}
                >
                    <Settings/> 
                    <Typography variant='subtitle1' className={classes.iconLabel}>State Settings</Typography> 
                </IconButton>
            }
        </Grid>
        <Grid className={classes.section}>
            <IconButton 
                id="signOut" 
                aria-label="Sign Out" 
                className={classes.iconButtton} 
                onClick={handleSignOut}    
            >
                <ArrowCircleLeftOutlined/> 
                <Typography variant='subtitle1' className={classes.iconLabel}>Sign Out</Typography> 
            </IconButton>
        </Grid>
        </Toolbar>
      </AppBar>
    
      <Drawer className={classes.drawer} variant="persistent" anchor="left" open={open}>
      <Grid className={classes.menu}>
            <Button className={classes.button} onClick={handleDrawerClose}>
                <Menu/> 
            </Button>
        </Grid>
        <Grid className={classes.section}>
            {(userPermissions.includes(agencyPermissions.CLIENT_INTAKE_DASHBOARD_EDIT) || userPermissions.includes(agencyPermissions.CLIENT_INTAKE_DASHBOARD_VIEW)) &&
                <Button 
                    className={selectedOption === '/agency/home' || selectedOption === '/agency/applicationReview'  ? `${classes.selectedOption} ${classes.button}` : classes.button} 
                    onClick={() => {history.push(`/agency/home`); handleDrawerClose();}}
                >
                    <Folder/> 
                    <Typography variant='subtitle1' className={classes.label}>Dashboard</Typography>
                </Button>
            }
            {userPermissions.includes(agencyPermissions.REPORTS_VIEW) &&
                <Button 
                    className={selectedOption === '/agency/reports' ? `${classes.selectedOption} ${classes.button}` : classes.button} 
                    onClick={() => {history.push(`/agency/reports`); handleDrawerClose();}}
                >
                    <BarChart/> 
                    <Typography variant='subtitle1' className={classes.label}>Reports</Typography>
                </Button>
            }
            {userPermissions.includes(agencyPermissions.AGENCY_ADMIN_EDIT) &&
                <Button 
                    className={selectedOption === '/agency/admin' ? `${classes.selectedOption} ${classes.button}` : classes.button} 
                    onClick={() => {history.push(`/agency/admin`); handleDrawerClose();}}
                >
                    <AdminPanelSettings/> 
                    <Typography variant='subtitle1' className={classes.label}>Admin Panel</Typography>
                </Button>
            }
            {userPermissions.includes(statePermissions.STATE_REVIEW_APPLICATION_VIEW) &&
                <Button 
                    className={selectedOption === '/state/dashboard' ? `${classes.selectedOption} ${classes.button}` : classes.button} 
                    onClick={() => {history.push(`/state/dashboard`); handleDrawerClose();}}
                >
                    <Folder/> 
                    <Typography variant='subtitle1' className={classes.label}>State Dashboard</Typography>
                </Button>
            }
            {userPermissions.includes(statePermissions.STATE_REPORTS_VIEW) &&

                <Button 
                    className={selectedOption === '/state/reports' ? `${classes.selectedOption} ${classes.button}` : classes.button} 
                    onClick={() => {history.push(`/state/reports`); handleDrawerClose();}}
                    >
                    <BarChart/> 
                    <Typography variant='subtitle1' className={classes.label}>State Reports</Typography>
                </Button>

            }
            {(userPermissions.includes(statePermissions.STATE_FORM_VIEW) || userPermissions.includes(statePermissions.STATE_FORM_EDIT)) &&
                <Button 
                    className={selectedOption === '/state/forms' ? `${classes.selectedOption} ${classes.button}` : classes.button} 
                    onClick={() => {history.push(`/state/forms`); handleDrawerClose();}}
                >
                    <Description/> 
                    <Typography variant='subtitle1' className={classes.label}>State Forms</Typography>
                </Button>
            }
            {(userPermissions.includes(statePermissions.STATE_CONFIG_VIEW) || userPermissions.includes(statePermissions.STATE_CONFIG_EDIT)) &&
            <Button 
                className={selectedOption === '/state/settings' ? `${classes.selectedOption} ${classes.button}` : classes.button} 
                onClick={() => {history.push(`/state/settings`); handleDrawerClose();}}
                >
                <Folder/> 
                <Typography variant='subtitle1' className={classes.label}>State Settings</Typography>
            </Button>
            }
        </Grid>
        <Grid className={classes.section}>
            <Button className={classes.button} onClick={handleSignOut}>
                <ArrowCircleLeftOutlined/> 
                <Typography variant='subtitle1' className={classes.label}>Sign Out</Typography>
            </Button>
        </Grid>
      </Drawer>
    </Box>
    </ClickAwayListener>
}
    </>
  );
}