import { Container, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import StepButtons from '../../../StepButtons'
import StepHeader from '../../../StepHeader'
import { useParams } from 'react-router';
import Documents from '../Documents'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    margin: "0",
  }
}));

export default function MemberDocumentUpload({ data: appData, back, next, appId, fileData, handleFileCreate, handleFileDelete }) {
  const classes = useStyles();
  const { nestedItemId } = useParams();
  const filteredFileData = fileData?.filter(x => x.memberId === nestedItemId);
  const memberData = appData?.householdMembers?.find(x => x.id === nestedItemId);

  const getTitle = (heading) => {
    const person = appData?.householdMembers?.find(x => x.id === nestedItemId);
    const itemIndex = person && (appData?.householdMembers?.indexOf(person) >= 0) ? appData?.householdMembers?.indexOf(person) + 1 : appData?.householdMembers?.length + 1 || 1;
    return person && person.lastName && person.firstName ? `${heading} for ${person.firstName} ${person.lastName}` : `${heading} for Household Member #${itemIndex}`;
  }
  
  return (
    <Container className={classes.mainContainer}>
        <Grid container spacing={1}>
          <StepHeader 
            title={getTitle("Documents")}
            desc = "Please provide the following information for this household member."
          />
          <Documents 
            appId={appId}
            appData={memberData}
            fileData={filteredFileData}
            handleFileCreate={handleFileCreate}
            handleFileDelete={handleFileDelete}
            type="household member"
            memberId={nestedItemId}
          />                  
          <Grid item xs={12}>
            <StepButtons back={back} next={next}/>
          </Grid>
        </Grid>
    </Container>
  )
}
