export const seacacCountyOptions = [
  {
    value: 'Ashley',
    label: 'Ashley'
  },
  {
    value: 'Bradley',
    label: 'Bradley'
  },
  {
    value: 'Chicot',
    label: 'Chicot'
  },
  {
    value: 'Desha',
    label: 'Desha'
  },
  {
    value: 'Drew',
    label: 'Drew'
  }
];