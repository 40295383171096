export const faqs = [
    {
        question: "What is LIHEAP?",
        answer: "The Low Income Home Energy Assistance Program (LIHEAP) is a federally funded program that helps eligible low-income households with their home energy expenses. The LIHEAP program is funded at the state level and managed/administered by local community action agencies. The LIHEAP program may provide heating/cooling bill payment assistance, crisis bill payment assistance, and/or heating/cooling equipment repair/replacement assistance."
    },
    {
        question: "What is the process?",
        answer: "By answering questions about your household and income, etc. and uploading required documents, our platform allows you to apply for LIHEAP assistance digitally from anywhere anytime. A caseworker will review your application to determine if you qualify. You will receive status updates once a determination has been made. If approved, a payment will be sent directly to your utility provider."
    },
    {
        question: "How long does it take?",
        answer: "Most applications are approved for a payment within two weeks. It could take up to 60 days for your utility provider to receive payment."
    },
    {
        question: "Can someone else apply for me?",
        answer: "Yes, someone can apply on your behalf."
    },
    {
        question: "How do I know my data is secure?",
        answer: "We take your data security very seriously and have put many protections in place to keep it safe. We use special codes to make sure nobody can read your data without permission, and we follow all the rules for keeping data safe. We also have a team of people who work to make sure everything is secure all the time. So, you can trust us to keep your data safe."
    }
]
