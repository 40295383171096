import { useState, useMemo, useEffect } from "react";
import { ConfigContext } from "../context/configContext";
import { getAppConfig } from "../api/ConfigAPI";
export function ConfigContextProvider({ children }) {
    
    const [clientConfig, setClientConfig] = useState(false);
    const ctx = useMemo(() => ({ clientConfig, setClientConfig }), [clientConfig]);
  
    useEffect(() => {
        getAppConfig().then((response) => {
            setClientConfig(response.data)
        }).catch((err) => {
            setClientConfig(false);
            console.log(`Error Fetching App Configuration - ${err}`);
        });
// eslint-disable-next-line
    }, []);

    return <ConfigContext.Provider value={ctx}>{children}</ConfigContext.Provider>
  }