import { useContext, useState } from "react";
import { Button } from "../Button";
import {
  HeaderSection,
  Row,
  Span,
  CustomLinkSmall,
  Col,
  Burger,
  NotHidden,
  Outline,
  ButtonSpan,
  ContentWrapper,
} from "./styles";
import { AuthContext } from "../../../context/authContext";
import { Drawer } from '@mui/material';
import { useLocation } from 'react-router-dom'

const Header = () => {

  const location = useLocation();
  const { userInfo, logout } = useContext(AuthContext);

  const state = userInfo?.serviceState

  const [visible, setVisibility] = useState(false);

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    return (
      <>
        {!userInfo && 
          <>
            <CustomLinkSmall to='/' onClick={() => setVisibility(false)}>
              <Span>{"Home"}</Span>
            </CustomLinkSmall>
          <CustomLinkSmall to='/login' onClick={() => setVisibility(false)} >
              <Span>{"Login"}</Span>
            </CustomLinkSmall>
            <CustomLinkSmall to='/register'>
              <ButtonSpan>
                <Button>{"Sign Up"}</Button>
              </ButtonSpan>
            </CustomLinkSmall>
          </>
        }
        {userInfo && 
          <>
            <CustomLinkSmall to='/' onClick={() => setVisibility(false)}>
              <Span>{"Home"}</Span>
            </CustomLinkSmall>
            <CustomLinkSmall to='/dashboard' onClick={() => setVisibility(false)}>
              <Span>{"Applications"}</Span>
            </CustomLinkSmall>
            {state === 'TX' &&<CustomLinkSmall to={`/resources/${state}`} onClick={() => setVisibility(false)}>
              <Span>{"Applicant Resources"}</Span>
            </CustomLinkSmall>}
            <CustomLinkSmall to='/account' onClick={() => setVisibility(false)}>
              <Span>{"Account"}</Span>
            </CustomLinkSmall>
            <CustomLinkSmall to='/logout' onClick={() => logout() } >
              <ButtonSpan>
                <Button>{"Sign Out"}</Button>
              </ButtonSpan>
            </CustomLinkSmall>
          </>
        }
      </>
    );
  };

  return (
    <>
    {(!location.pathname.startsWith('/agency/register') && (!userInfo || (userInfo && userInfo.userType === 'applicant'))) && 
    <ContentWrapper>
      <HeaderSection>
        <Row>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={showDrawer}>
            <Outline />
          </Burger>
        </Row>
        <Drawer anchor={'right'} open={visible} onClose={onClose}>
          <Col>
            <Burger onClick={onClose}>
              <Outline />
            </Burger>
          </Col>
          <MenuItem />
        </Drawer>
      </HeaderSection>
    </ContentWrapper>
  }
  </>
  );
};

export default (Header);
 
