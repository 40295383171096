import React, { useState, useEffect } from 'react'
import { Grid, Typography, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { statuses } from '../constants/appStatuses';
import { formatDate } from '../utils/utilFunctions';

const useStyles = makeStyles((theme) => ({
  history:{
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  histObject: {
    padding: '.5rem 0rem'
  },
  infoLabel: {
    fontWeight: 600,
  },
  urgency: {
    textTransform: 'capitalize'
  },
  info: {
    marginBottom: '.5rem',
    color: '#44474F'
  },
  divider: {
    marginTop: '1rem',
    width: '100%',
    border: '1px solid #C4C4C4'
  },
  infoGrid: {
    display: 'flex',
    marginBottom: '.5rem'
  },
  noHistory:{
    justifyContent: 'center',
    display: 'flex',
    margin: '2rem 0'
  },
}));

export default function History({ application }){
  const [history, setHistory] = useState([]);

  useEffect(() => {
    if(application && application?.notesAndHistory){
      const nh = application?.notesAndHistory
      const history = nh?.filter(x => x.type === 'history').sort((a, b) => new Date(a.date) - new Date(b.date))
      const urgencyAndDulicateHistory = nh?.filter(x => x.type === 'history-urgency' || x.type === 'history-duplicate')
      
      const statusHistory = application?.statusHistory.sort((a, b) => new Date(a.statusDate) - new Date(b.statusDate))
  
      let newHistory = []

      history.forEach((hist, index) => {
        if(index !== history.length-1){
          let statHist = statusHistory[index+1]
          hist['newStatusInfo'] = statHist?.statusInfo
          newHistory.push(hist)
        }
        else if(index === history.length-1){
          hist['newStatusInfo'] = application?.statusInfo
          newHistory.push(hist)
        }
      })

      setHistory(history.concat(urgencyAndDulicateHistory).sort((a, b) => new Date(b.date) - new Date(a.date)))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();

  const getFormattedDate = (val) => {
    const options = {
      year: "numeric",
      month:"2-digit",
      day:"2-digit",
      hour:  "2-digit",
      minute: "2-digit",
   };
   return new Date(val).toLocaleDateString("en-US", options).replace(', ','\n');
  }

  return(
      <>
        {history.length > 0 ?
          <Grid item xs={12} className={classes.history}>
            {history.map ((histObject, index) => {
              return (
                <Grid key={index} item xs={12} container className={classes.histObject}>
                  <Grid item container xs={6} justifyContent="flex-start">
                    {histObject?.type === 'history' &&
                      <Typography variant='subtitle2' className={classes.infoLabel}>{histObject?.newStatus} </Typography>
                    }
                    {histObject?.type === 'history-urgency' &&
                      <Typography variant='subtitle2' className={`${classes.infoLabel} ${classes.urgency}`}>Urgency {histObject?.newStatus}</Typography>
                    }
                    {histObject?.type === 'history-duplicate' &&
                      <Typography variant='subtitle2' className={`${classes.infoLabel} ${classes.urgency}`}>Duplicate {histObject?.newStatus}</Typography>
                    }
                  </Grid>
                  <Grid item container xs={6} justifyContent="flex-end">
                    <Typography variant='subtitle2' className={classes.info}>{getFormattedDate(histObject?.date)}</Typography>
                  </Grid>
                  <Grid item container justifyContent="flex-start">
                    <Typography variant='subtitle2' className={classes.info}>Status changed by {histObject?.userFullName}</Typography>
                  </Grid>

                  {histObject?.newStatus && (histObject?.newStatus === statuses.APPROVED || histObject?.newStatus === statuses.PENDING) &&   
                    <Grid container item xs={12}>
                      {histObject?.newStatusInfo?.payments?.map((payment, i) => (
                        <Grid item xs={12} key={i} className={classes.infoGrid}>
                          <Typography variant="subtitle2" className={classes.infoLabel}>{`Amount #${i+1}:`}&nbsp;</Typography> 
                          <Typography variant="subtitle2" className={classes.info}>
                            {`$${payment.amount}. Provider: ${payment.provider}. Type: ${payment.type}. Fund Source: ${payment.source}.`}
                          </Typography> 
                        </Grid>
                      ))}
                      {histObject?.newStatusInfo?.payments &&
                        <Grid item xs={12} className={classes.infoGrid}>
                          <Typography className={classes.infoLabel} variant="subtitle2">Date:&nbsp;</Typography> 
                          <Typography className={classes.info} variant="subtitle2">{formatDate(histObject?.newStatusInfo.pledgeDate)}</Typography> 
                        </Grid> 
                      }
                      {!histObject?.newStatusInfo?.payments &&
                      <>
                        <Grid item xs={12}>
                          <Typography className={classes.infoLabel} variant="subtitle2">Pledge Amount:&nbsp;</Typography> 
                          <Typography className={classes.info} variant="subtitle2" >{`$ ${histObject?.newStatusInfo.pledgeAmount}`}</Typography> 
                        </Grid> 
                        <Grid item xs={12}>
                          <Typography className={classes.infoLabel} variant="subtitle2">Pledge Date:&nbsp;</Typography> 
                          <Typography className={classes.info} variant="subtitle2">{formatDate(histObject?.newStatusInfo.pledgeDate)}</Typography> 
                        </Grid> 
                        <Grid item xs={12}>
                          <Typography className={classes.infoLabel} variant="subtitle2" >Utility provider payment was sent to:&nbsp;</Typography> 
                          <Typography className={classes.info} variant="subtitle2">{histObject?.newStatusInfo.checkRecipient ? histObject?.newStatusInfo.checkRecipient : ' N/A'}</Typography> 
                        </Grid> 
                      </>
                      }
                    </Grid>
                  }

                  {histObject?.newStatus && histObject?.newStatus === statuses.DENIED && 
                    <Grid container item xs={12}>
                      <Grid item xs={12}>
                        {histObject?.newStatusInfo.deniedReason &&
                        <>
                          <Typography className={classes.infoLabel} variant="subtitle2">Reasons:&nbsp;</Typography> 
                            {histObject?.newStatusInfo.deniedReason === 'withdrawal' ?
                              <Typography variant="subtitle2" className={`${classes.info} ${classes.statusInfoReason}`}>#4040 - Withdrawal of Application</Typography> 
                              :
                              <>
                                {histObject?.newStatusInfo.deniedPolicyNumber.map((policy, index) => (
                                  <Typography key={index} variant="subtitle2" className={`${classes.info} ${classes.info}`}>{policy}</Typography> 
                                ))}
                              </>
                            }
                        </>
                        }
                      </Grid> 
                      <Grid item xs={12}>
                        <Typography className={classes.infoLabel} variant="subtitle2">Note for the applicant:&nbsp;</Typography> 
                        <Typography variant="subtitle2" className={classes.info}>{histObject?.newStatusInfo.deniedReasonSpecification ? `"${histObject?.newStatusInfo.deniedReasonSpecification}"`  : 'N/A'}</Typography> 
                      </Grid> 
                    </Grid>
                  }

                  {histObject?.newStatus && histObject?.newStatus === statuses.RETURNED && 
                    <Grid container item xs={12}>
                      {histObject?.newStatusInfo.returnedPolicyNumber &&
                        <Grid item xs={12}>
                          <Typography className={classes.infoLabel} variant="subtitle2">Reasons:&nbsp;</Typography> 
                            {histObject?.newStatusInfo.returnedPolicyNumber.map((policy, index) => (
                              <Typography key={index} variant="subtitle2" className={classes.info}>{policy}</Typography> 
                            ))}
                        </Grid> 
                      }
                      {histObject?.newStatusInfo.returnedNeededForms &&
                        <Grid item xs={12}>
                          <Typography className={classes.infoLabel} variant="subtitle2">Needed Forms:&nbsp;</Typography> 
                            {histObject?.newStatusInfo.returnedNeededForms.map((form, index) => (
                              <Typography key={index} variant="subtitle2" className={classes.info}>{form.title}</Typography> 
                            ))}
                        </Grid> 
                      }
                      <Grid item xs={12}>
                        <Typography className={classes.infoLabel} variant="subtitle2">Return by Date:&nbsp;</Typography> 
                        <Typography variant="subtitle2" className={classes.info}>{formatDate(histObject?.newStatusInfo.returnedDueDate)}</Typography> 
                      </Grid> 
                      <Grid item xs={12}>
                        <Typography className={classes.infoLabel} variant="subtitle2" >Note for the applicant:&nbsp;</Typography> 
                        <Typography variant="subtitle2" className={classes.info}>"{histObject?.newStatusInfo.returnedNeededInfo}"</Typography> 
                      </Grid> 
                    </Grid>
                  }

                  {histObject?.newStatus && histObject?.newStatus === statuses.ON_PAPER && histObject?.newStatusInfo.onPaperNotes &&
                    <Grid container item xs={12}>
                      <Typography className={classes.infoLabel} variant="subtitle2" >Notes:&nbsp;</Typography> 
                      <Typography variant="subtitle2" className={classes.info}>"{histObject?.newStatusInfo.onPaperNotes}"</Typography> 
                    </Grid> 
                  }

                  {histObject?.newStatus && histObject?.newStatus === statuses.ERROR && histObject?.newStatusInfo.finaResponseInfo &&
                    <Grid container item xs={12}>
                      {histObject?.newStatusInfo?.finaResponseInfo?.map((paymentInfo, i) => (
                        <>
                          <Grid item xs={12} key={i} className={classes.infoGrid}>
                            <Typography variant="subtitle2" className={classes.infoLabel}>{`Amount #${i+1}:`}&nbsp;</Typography> 
                            <Typography variant="subtitle2" className={classes.info}>
                              {`$${paymentInfo.payment.amount}. Provider: ${paymentInfo.payment.provider}. Type: ${paymentInfo.payment.type}. Fund Source: ${paymentInfo.payment.source}.`}
                            </Typography> 
                          </Grid>
                          <Grid item xs={12}>
                            <Typography className={classes.infoLabel} variant="subtitle2">Return Code:&nbsp;</Typography> 
                            <Typography className={classes.info} variant="subtitle2" >{`${paymentInfo.response.ReturnCode}`}</Typography> 
                          </Grid> 
                          <Grid item xs={12}>
                            <Typography className={classes.infoLabel} variant="subtitle2">Source System:&nbsp;</Typography> 
                            <Typography className={classes.info} variant="subtitle2">{paymentInfo.response.ErrorDetails.SourceSystem}</Typography> 
                          </Grid> 
                          <Grid item xs={12}>
                            <Typography className={classes.infoLabel} variant="subtitle2" >Error Message:&nbsp;</Typography> 
                            <Typography className={classes.info} variant="subtitle2">{paymentInfo.response.ErrorDetails.Message ? paymentInfo.response.ErrorDetails.Message : ' N/A'}</Typography> 
                          </Grid>
                        </>
                      ))}
                    </Grid> 
                  }
                  
                  <Divider className={classes.divider}/>
                </Grid>
              )
            })}
          </Grid>
          :
          <Grid item xs={12} className={classes.noHistory}>
            <Typography>This application has no history.</Typography>
          </Grid>
        }
    </>
  );
}
