import { grey, blueGrey } from '@mui/material/colors';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
let theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 100,
          padding: '0 2rem',
          height: 44,
        },
        outlined: {
          padding: '0 2rem',
          border: '.125rem solid',
          backgroundColor: '#FFF',
          '&:hover':{
            backgroundColor: '#FFF',
            border: '.075rem solid',
          }
        } 
      }, 
    },
  }, 
  palette: {
    background: {
      default: grey[50] //#fafafa
    },
    primary: {
      main: '#3576CB',
      dark: '#1E68C1',
      veryDark: '#274271'
    },
    secondary: blueGrey,
  },
  typography: {
    fontFamily: [
      '"Open Sans"',
      'sans-serif'
    ].join(','),
    button: {
      minHeight: 48
    }
  },
  customColors: {
    lightGrey: "#E1E2E3",
    grey: "#A5AAAC",
    darkGrey: "#505A5F",
    mediumGray: "#707070",
    veryDarkGray: "#263238",
    lightMedGray: "#979797",
    tooltip: "#444444",
    shadowGray: "#C4C4C4",
    errorIcon: '#B00020',
    errorText: '#d32f2f',
    veryLightGray: "#FAFBFD",
    deepBlue: "#0059C8",
    lightBlue: "#eef0f9",
    homepageGrey: "#EFF1F2",
    formSection: {
      backgroundColor: "#FAFBFD"
    },
    navBar: {
      highlightColor: "#75ADFF"
    },
    modalBackground: 'linear-gradient(0deg, rgba(29, 93, 177, 0.11), rgba(29, 93, 177, 0.11)), #FDFBFF',
    applicationStatus: {
      approved: "#3F6A00",
      denied: "#DD3730",
      returned: "#804800",
      submitted: "#6324B5",
      reSubmitted: "#1057A8",
      inProgress: "#000000",
      onPaper: "#505A5F",
      pending: "#505A5F",
      error: "#BA1B1B"
    }
  }
});
theme = responsiveFontSizes(theme);
export default theme;