import { Container, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'
import FormTextField from '../../../form/FormTextField'
import FormCheckboxes from '../../../form/FormCheckboxes'
import StepButtons from '../../../StepButtons'
import StepHeader from '../../../StepHeader'
import FormSection from '../../../FormSection'
import FormRadioAsButtons from '../../../form/FormRadioAsButtons'
import { yesNoOptions } from '../../../../constants/radios/yesNoOptions'
import useLiheapForm from '../../../../hooks/useLiheapForm'
import { yesNoDontKnowOptions } from '../../../../constants/radios/yesNoDontKnowOptions';
import { airConditionerOptions } from '../../../../constants/tx/radios/airConditionerOptions';
import { heaterOptions } from '../../../../constants/tx/checkboxes/heaterOptions';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    marginLeft: "0"
  },
  label: {
    fontWeight: "bold", 
    marginBottom: ".6rem",
  },
  section: {
    marginTop: '1.5rem'
  },
  inputFields: {
    maxWidth: "21rem",
    backgroundColor: "#fff",
    "& > .MuiFormHelperText-root.Mui-error": { 
      backgroundColor: theme.customColors.veryLightGray,
      margin:0
    }
  }
}));

export default function UtilityWeatherProofingInformation({ data, back, next, save, validate, validationSchema }) {
  const { control, errors, register, watch, setValue } = useLiheapForm(save, data, validate, validationSchema, false);

  const classes = useStyles();

  const handlePrevAsstSelect = (val) => {
    if(val !== 'yes'){
      setValue("weatherProofingPrevAsstDate", '', { shouldDirty: true });
    }
  }

  const handleHeaterTypeSelect = (val) => {
    if(val && !val.includes('gasSpaceHeater')){
      setValue("gasSpaceHeaterCount", '', { shouldDirty: true });
    }
    if(val && !val.includes('electricHeater')){
      setValue("electricHeaterCount", '', { shouldDirty: true });
    }
  }

  const handleRoofLeakingSelect = (val) => {
    if(val !== 'yes'){
      setValue("roofLeakingTimeLength", '', { shouldDirty: true });
      setValue("roofLeakingRoomCount", '', { shouldDirty: true });
    }
  }

  const weatherProofingPrevAsstWatch = watch("weatherProofingPrevAsst");
  const roofLeakingWatch = watch("roofLeaking");
  const heaterTypeWatch = watch("heaterType");

  return (
    <Container className={classes.mainContainer}>
        <Grid container spacing={1}>
          <StepHeader 
            title="Utility Information" 
            desc = "Please provide the following information for the applicant."
          />
          <FormSection>
            <Grid item xs={12}>
              <FormRadioAsButtons
                control={control}
                name="weatherProofingPrevAsst"
                label="Has this residence ever recieved services from the Weatherization Assistance Program?"
                errors={errors}
                options={yesNoDontKnowOptions}
                onSelect={handlePrevAsstSelect}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {weatherProofingPrevAsstWatch === 'yes' &&
                <FormTextField
                  name="weatherProofingPrevAsstDate" 
                  label="If yes, when?"
                  labelPlacement="top" 
                  register={register} 
                  errors={errors}                
                />
              }
            </Grid>
          </FormSection>
          <FormSection>
            <Grid item xs={12} sm={6}>
              <FormTextField
                name="yearHomeWasBuilt" 
                label="What year was this home built?"
                labelPlacement="top" 
                register={register} 
                errors={errors}                
              />
            </Grid>
          </FormSection>
          <FormSection>
            <Grid item xs={12}>
              <FormRadioAsButtons
                control={control}
                name="airConditionerType"
                label="What type of air conditioner do you use?"
                errors={errors}
                options={airConditionerOptions}
              />
            </Grid>
          </FormSection>
          <FormSection>
            <Grid item xs={12}>
              <FormCheckboxes
                defaultValues={data?.heaterType}
                register={register}
                name="heaterType"
                label="What type of heater do you use? (Please select all that apply)"
                options={heaterOptions}
                gridSize={12}
                errors={errors}
                noOption="None"
                onSelect={handleHeaterTypeSelect}
              />
            </Grid>
            {heaterTypeWatch && heaterTypeWatch.includes('gasSpaceHeater') &&
              <Grid item xs={12} sm={6}>
                <FormTextField
                  name="gasSpaceHeaterCount" 
                  label="How many gas space heaters do you use?"
                  labelPlacement="top" 
                  register={register} 
                  errors={errors}                
                />
              </Grid>
            }
            {heaterTypeWatch && heaterTypeWatch.includes('electricHeater') &&
              <Grid item xs={12} sm={6}>
                <FormTextField
                  name="electricHeaterCount" 
                  label="How many electric heaters do you use?"
                  labelPlacement="top" 
                  register={register} 
                  errors={errors}                
                />
              </Grid>
            }
          </FormSection>
          <FormSection>
            <Grid item xs={12}>
              <FormRadioAsButtons
                control={control}
                name="roofLeaking"
                label="Is your roof leaking?"
                errors={errors}
                options={yesNoOptions}
                onSelect={handleRoofLeakingSelect}
              />       
            </Grid>
            {roofLeakingWatch === 'yes' &&
            <>
              <Grid item xs={12} sm={6}>
                <FormTextField
                  name="roofLeakingTimeLength" 
                  label="How long has it been leaking?"
                  labelPlacement="top" 
                  register={register} 
                  errors={errors}                
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormTextField
                  name="roofLeakingRoomCount" 
                  label="In how many rooms is it leaking?"
                  labelPlacement="top" 
                  register={register} 
                  errors={errors}                
                />
              </Grid>
            </>
            }
          </FormSection>   
          <FormSection>
            <Grid item xs={12}>
              <FormRadioAsButtons
                control={control}
                name="holesInFloor"
                label="Are there holes in your floors?"
                errors={errors}
                options={yesNoOptions}
              />       
            </Grid>
              <Grid item xs={12}>
                <FormRadioAsButtons
                  control={control}
                  name="goodFoundation"
                  label="Does your home have a good foundation?"
                  errors={errors}
                  options={yesNoOptions}
                />
              </Grid>
          </FormSection>   
          <StepButtons back={back} next={next}/>
        </Grid>
    </Container>
  )
}
