export const airConditionerOptions = [
    {
      value: 'windowUnit',
      label: 'Window Unit'
    },
    {
      value: 'centralUnit',
      label: 'Central Unit'
    },
    {
      value: 'evaporativeCooler',
      label: 'Evaporative Cooler'
    },
    {
      value: 'noneAC',
      label: 'None'
    }
  ]
  
  