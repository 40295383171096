import { Button, Card, CardHeader, Container, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add, DeleteOutlined, EditOutlined } from '@mui/icons-material';
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router';
import { v4 as uuidv4} from 'uuid';
import ConfirmationDialog from '../../../ConfirmationDialog';
import StepButtons from '../../../StepButtons'
import StepHeader from '../../../StepHeader'
import { householdMemberSchema } from '../../../../constants/tx/stepValidationSchemas'
import { getRequiredGenAndIncomeFileTypes } from '../../../../constants/tx/fileUtils'
import { validateDocuments } from '../../../../constants/sharedFileUtils'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    marginLeft: "0"
  },
  addMemberGrid: {
    margin: "2.8rem"
  },
  addMemberText:{
    fontWeight: "bold",
    marginBottom: "1.3rem"
  },
  memberInfo: {
    fontWeight: "bold"
  },
  cardHeader: {
    padding: "0"
  },
  section: {
    backgroundColor: theme.customColors.formSection.backgroundColor,
    marginBottom: ".6rem",
    border: `.1rem solid ${theme.customColors.lightGrey}`,
    borderRadius: ".3rem",
    padding: "1rem 2.8rem"
  },
  actionButtons: {
    padding: '0rem .5rem',
  }, 
  completed:{
    color: theme.palette.success.main
  },
  incompleted:{
    color: theme.palette.error.main
  }
}));

export default function AddEditMembers({ data, back, next, save, removeNestedItem, fileData }) {
  const [removeMemberOpen, setRemoveMemberOpen] = useState(false);
  const [memberToRemove, setMemberToRemove] = useState({});
  const [memberToRemoveIndex, setMemberToRemoveIndex] = useState();
  const { id: appId } = useParams();
  const history = useHistory();
  const classes = useStyles();
  //TODO: Make this better 
  const householdMemberFirstStepPath = "/liheapform/TX/memberPersonalInfo/:id/:nestedItemId"

  const documentQueries = fileData;

  const handleRemoveIcon = (member, index) => {
    setMemberToRemove(member);
    setMemberToRemoveIndex(index);
    setRemoveMemberOpen(true);
  }

  const removeMember = (id) => {
    removeNestedItem(id);
    setRemoveMemberOpen(false);
  }

  const showAddMembers = () => {
    return true;
  }

  const addHouseHoldMember = () => {
    const newMemberData = {"id": uuidv4()};
    save(newMemberData);
    return history.push(householdMemberFirstStepPath.replace(":id", appId).replace(":nestedItemId", newMemberData.id))
  }

  const isComplete = (memberData) => {
    let memberRequiredFiles = getRequiredGenAndIncomeFileTypes(memberData);
    return householdMemberSchema.isValidSync(memberData) && validateDocuments(memberRequiredFiles, documentQueries, memberData.id)
  };

  return (
    <Container className={classes.mainContainer}>
        <Grid container spacing={1}>
          <StepHeader 
            title="Household Member Information" 
            desc = "Please provide information for each household member."
          />
          <Grid item xs={12}>
            {
              data?.householdMembers?.map ((member, index) => (
                <Card key={index} variant="outlined" className={classes.section}>
                  <CardHeader className={classes.cardHeader}
                    action={
                      <>
                        <Button
                          className={classes.actionButtons}
                          color="primary"
                          onClick={() => history.push(householdMemberFirstStepPath.replace(":id", appId).replace(":nestedItemId", member.id))}
                          variant="text"
                          startIcon={<EditOutlined />}
                        >
                          Edit
                        </Button>
                        <Button
                          className={classes.actionButtons}
                          color="primary"
                          onClick={() => handleRemoveIcon(member, index)}
                          variant="text"
                          startIcon={<DeleteOutlined />}
                        >
                          Remove
                        </Button>
                      </>
                    }
                    title={
                      <Typography variant="h5">{`Household Member #${index + 1}`}</Typography>
                    }
                    subheader={
                      <>                        
                        <Typography variant="subtitle2">{`${member.firstName ? member.firstName : "" } ${member.lastName ? member.lastName : ""}`}</Typography>
                        <Typography 
                          variant="subtitle2"
                          className={isComplete(member) ? classes.completed : classes.incompleted}>
                            {isComplete(member) ? 'Completed' : 'Incomplete'}
                        </Typography>
                      </>                    
                    }
                  />
                </Card>
              ))
            }
          </Grid>
          { showAddMembers() &&
            <Grid item container xs={12} direction="column" alignItems="flex-start" className={classes.addMemberGrid}>
              <Typography className={classes.addMemberText}>If you have more household members, click on add household member to add their information.</Typography>            
              <Button
                  color="primary"
                  onClick={() => addHouseHoldMember()}
                  variant="outlined"
                  startIcon={<Add />}
                >
                Add Household Member
              </Button>      
            </Grid>            
          }  
          <StepButtons back={back} next={next}/>
        </Grid>
      <ConfirmationDialog 
        open={removeMemberOpen}
        title="Remove Household Member"
        desc={`Are you sure you want to remove ${memberToRemove.firstName ? memberToRemove.firstName: `Household Member #${memberToRemoveIndex + 1}`}?`}
        handleCancel={() => setRemoveMemberOpen(false)}
        handleOk={() => removeMember(memberToRemove.id)}
      />
    </Container>
  )
}
