import { Button, CircularProgress, Grid, Typography} from "@mui/material";
import { useState, useContext } from "react";
import { Add } from "@mui/icons-material";
import makeStyles from '@mui/styles/makeStyles';
import StateFormInfo from "../components/StateFormInfo";
import StateFormUpload from "../components/StateFormUpload";
import { getFormsForState } from "../../../api/FileAPI";
import { useQuery, useQueryClient } from "react-query";
import { AuthContext } from "../../../context/authContext";
import { statePermissions } from "../../../constants/statePermissions";

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: '3rem'
  },
  sidePanel: {
    padding: '0 1rem'
  },
  spinner: {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    position: 'absolute',
    zIndex: '999'
  },
  buttons: {
    textTransform: 'none',
    fontWeight: 600
  },  
}));

export default function StateFormsMain() {
  const classes = useStyles();
  const queryClient = useQueryClient();

  const { userInfo } = useContext(AuthContext);
  const state = userInfo?.agencyState;
  const userPermissions = userInfo?.permissions ? userInfo.permissions : [];
  const canEdit = userPermissions.includes(statePermissions.STATE_FORM_EDIT)

  const { isFetching, data } = useQuery(['getFormsForState', { state: state }], async () => {
    const { data } = await getFormsForState(state);
    return data;
  }, {enabled: !!state})  

  const [openUpload, setOpenUpload] = useState(false)
  const [openAll, setOpenAll] = useState(false)

  const onChange = () => {
    queryClient.invalidateQueries(['getFormsForState']);
    setOpenUpload(false)
  }

  return(
    <Grid container>
      <Grid className={classes.header} item xs={12}>
        <Typography variant='h5'>
          Forms
        </Typography>
      </Grid>
      {isFetching ? 
        <Grid justifyContent="center" className={classes.spinner}>
          <CircularProgress color="primary" size={50}/>
        </Grid>
      :
      <>
      <Grid item xs={openUpload ? 8 : 12}>
        <Grid container item xs={12}> 
          <Grid item xs={12} textAlign='end'>
            <Button className={classes.buttons} disabled={!canEdit} color="primary" onClick={() => setOpenUpload(true)} variant="outlined" startIcon={<Add />}>
              Add New Form
            </Button>
          </Grid>
          {data && data?.length > 0 &&
            <>
            <Grid item xs={8} alignSelf='center'>
              <Typography variant='subtitle1'>
                Update the list of forms for applicants
              </Typography> 
            </Grid>
            <Grid item xs={4} textAlign={'right'}>
              <Button
                className={classes.buttons}
                color="primary"
                onClick={() => setOpenAll((prevState) => {return !prevState})}
                variant="text"
              >
                {openAll ? 'Close All' : 'Open All'}
              </Button>
            </Grid>
            <Grid item xs={12} >
              {data?.map((form, index) => (
                <StateFormInfo key={index} form={form} openAll={openAll} onRemove={onChange} canEdit={canEdit}/>
              ))}
            </Grid>
            </>
          }
        </Grid>
      </Grid>
      {openUpload &&
        <Grid className={classes.sidePanel} item xs={4} justifyContent='flex-end'>
          <StateFormUpload onClose={setOpenUpload} onUpload={onChange} state={state}/>
        </Grid>
      }
      </>
    }
  </Grid>
)
}
