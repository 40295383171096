export const pledgePaymentTypeOptions = [
  {
      "label" : "Crisis",
      "value" : "Crisis",
  },
  {
      "label" : "Regular",
      "value" : "Regular",
  },
  {
      "label" : "Regular Pledged",
      "value" : "Regular Pledged",
  }
]