import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { StateReportsStyledDataGrid } from './StateReportsStyledDataGrid';
import { formatCurrency } from '../../../utils/utilFunctions';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: '75rem',
  },
  column: {
    fontWeight: '500',
    marginLeft: '.5rem',
    whiteSpace: 'normal',
    lineHeight: 'normal',
    height: "unset !important",
    maxHeight: "168px !important"
  },
  cell: {
    fontWeight: '500',
    paddingLeft: '.5rem',
    width: '100%',
    whiteSpace: 'break-spaces',
    lineHeight: '1.5rem',
    textAlign: 'start'
  },
  numberCell: {
    fontWeight: '500',
    width: '100%',
    whiteSpace: 'break-spaces',
    lineHeight: '1.5rem',
    textAlign: 'right'
  }
}));

export default function StateCrisisVsRegReportTable({ data, isFetching }) {
  const classes = useStyles();

  const SortedDescendingIcon = () => {
    return <KeyboardArrowDown style={{color: '#000'}}/>;
  }

  const SortedAscendingIcon = () => {
    return <KeyboardArrowUp style={{color: '#000'}}/>;
  }

  const formatTurnaroundTime = (value) => {
    return value || value === 0 ? (Math.round(value * 100) / 100).toFixed(1) : "-"
  }

  const formatAmount = (value) => {
    return value ? formatCurrency(value) : "$0.00"
  }

  const sortedReportData = () => {
    let sortedData = [...data]
    sortedData.sort((a, b) => {
      return a.agencyId.localeCompare(b.agencyId) || b.type.localeCompare(a.type) || b.source.localeCompare(a.source)
    });
    return sortedData;
  }

  const getColumns = () => {
    return [
      {
        field: 'agencyId', flex: .3,
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Agency</Typography>),
        renderCell: (params) => (<Typography variant='subtitle1' className={classes.cell}>{params.value}</Typography>)
      },
      {
        field: 'type', flex: .3,
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Type</Typography>),
        renderCell: (params) => (<Typography variant='subtitle1' className={classes.cell}>{params.value}</Typography>)
      },
      {
        field: 'source', flex: .6,
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Source</Typography>),
        renderCell: (params) => (<Typography variant='subtitle1' className={classes.cell}>{params.value}</Typography>)
      },
      {
        field: 'fundsUsed', flex: .3, type: 'number',
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Funds Used</Typography>),
        renderCell: (params) => (<Typography variant='subtitle1' className={classes.numberCell}>{formatAmount(params.value)}</Typography>)
      },
      {
        field: 'turnaroundDays', flex: .6, type: 'number',
        renderHeader: () => (<Typography variant='h6' className={classes.column}>Average Turnaround, Days</Typography>),
        renderCell: (params) => (<Typography variant='subtitle1' className={classes.numberCell}>{formatTurnaroundTime(params.value)}</Typography>)
      }
    ];
  }

  return (
    <div className={classes.table}>
      <Grid item xs={12} sx={{height: "30rem"}}>
        <StateReportsStyledDataGrid
          rows={data ? sortedReportData() : []}
          columns={getColumns()}
          getRowId={(row) => Math.floor(Math.random() * 100000000)}
          hideFooter
          hideFooterSelectedRowCount
          hideFooterPagination
          disableSelectionOnClick
          disableColumnMenu
          loading={isFetching}
          components={{
            ColumnSortedDescendingIcon: SortedDescendingIcon,
            ColumnSortedAscendingIcon: SortedAscendingIcon,
          }}
        />
      </Grid>
    </div>
  );
}
