import './App.css';
import { Route, BrowserRouter } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Dashboard from './pages/dashboard';
import theme from './themes/theme'
import LiheapMultiStepForm from './components/liheap/LiheapMultiStepForm';
import Footer from './components/header-and-footer/footer/index';
import Header from './components/header-and-footer/header/index';
import Homepage from './pages/homepage';
import { Styles } from "./components/header-and-footer/styles/styles"
import Login from './components/login/login';
import Register from './components/login/register';
import Reset from './components/login/reset';
import Logout from './components/login/logout';
import ScrollToTop from './components/ScrollToTop';
import AgencyHome from './pages/agencyHome';
import ErrorViewer from './components/errorViewer';
import { AuthContextProvider } from './providers/AuthContextProvider';
import { ErrorContextProvider } from './providers/ErrorContextProvider';
import { ConfigContextProvider } from './providers/ConfigContextProvider';
import TermsOfUse from './pages/termsOfUse';
import AgencyRegister from './components/login/agency/agencyRegister';
import TagManager from 'react-gtm-module';
import AccountSettings from './pages/account/accountSettings';
import UpdatePassword from './pages/account/updatePassword';
import UpdatePhone from './pages/account/updatePhone';
import UpdateNotifications from './pages/account/updateNotifications';
import ApplicationReview from './components/agency/pages/ApplicationReview';
import NavigationBar from './components/NavigationBar';
import AgencyAdmin from './pages/agencyAdmin';
import StateDashboard from './pages/stateDashboard';
import StateSettings from './pages/stateSettings';
import { agencyPermissions } from './constants/agencyPermissions';
import { statePermissions } from './constants/statePermissions';
import Unauthorized from './pages/unauthorized';
import AgencyReports from './pages/agencyReports';
import ApplicantResources from './pages/applicantResources';
import StateReports from './pages/stateReports';
import StateForms from './pages/stateForms';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // globally default to 60 seconds
      staleTime: 1000 * 60,
      refetchOnWindowFocus: false
    },
  },
})

const styles = makeStyles(theme => ({
  contentWrapper: { 
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    flexGrow: "1"
  },
  contentArea: {
    flexGrow: "1",
    display: "flex",
    flexFlow: "column"
  },
  footer: {
    flexShrink: "0"
  }
}));

if (process.env.REACT_APP_ENV === 'prod') {

  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GOOGLE_GTAG_KEY
  }
  TagManager.initialize(tagManagerArgs)
}

function App() {
  const { contentWrapper, contentArea, footer } = styles();
  return <>
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <div className={contentWrapper}>
       <div className={contentArea}>
          <AuthContextProvider>
            <ErrorContextProvider>
              <QueryClientProvider client={queryClient}>
                  <ConfigContextProvider>
                    <BrowserRouter>
                      <ScrollToTop />
                      <Header />
                      <NavigationBar />
                      <Route path="/login" >
                          <Login />
                      </Route>                      
                      <Route path="/register" >
                        <Register />
                      </Route>
                      <Route path="/reset" >
                        <Reset />
                      </Route> 
                      <Route path="/logout">
                        <Logout />
                      </Route>
                      <Route path="/termsOfUse">
                        <TermsOfUse />
                      </Route>
                      <Route path="/unauthorized">
                        <Unauthorized />
                      </Route>
                      <Route path="/agency/register/:inviteId">
                        <AgencyRegister />
                      </Route>
                      <ProtectedRoute path="/dashboard" userType="applicant">
                        <Dashboard />
                      </ProtectedRoute>
                      <ProtectedRoute path="/liheapform/:appState/:stepName/:id/:nestedItemId?/:secNestedItemId?" userType="applicant">
                        <LiheapMultiStepForm/>
                      </ProtectedRoute>
                      <ProtectedRoute path="/resources/TX" userType="applicant" state="TX">
                        <ApplicantResources/>
                      </ProtectedRoute>
                      <ProtectedRoute path="/account" userType="applicant">
                        <AccountSettings />
                      </ProtectedRoute>
                      <ProtectedRoute path="/password" userType="applicant">
                        <UpdatePassword />
                      </ProtectedRoute>
                      <ProtectedRoute path="/phone" userType="applicant">
                        <UpdatePhone />
                      </ProtectedRoute>
                      <ProtectedRoute path="/notifications" userType="applicant">
                        <UpdateNotifications />
                      </ProtectedRoute>
                      <ProtectedRoute path="/agency/home" userType="agency" allowedPermissions={[agencyPermissions.CLIENT_INTAKE_DASHBOARD_EDIT, agencyPermissions.CLIENT_INTAKE_DASHBOARD_VIEW]}>
                        <AgencyHome />
                      </ProtectedRoute>
                      <ProtectedRoute path="/agency/admin" userType="agency" allowedPermissions={[agencyPermissions.AGENCY_ADMIN_EDIT]}>
                        <AgencyAdmin />
                      </ProtectedRoute>
                      <ProtectedRoute path="/state/dashboard" userType="state" allowedPermissions={[statePermissions.STATE_REVIEW_APPLICATION_VIEW]}>
                        <StateDashboard />
                      </ProtectedRoute>
                      <ProtectedRoute path="/state/settings" userType="state" allowedPermissions={[statePermissions.STATE_CONFIG_VIEW, statePermissions.STATE_CONFIG_EDIT]}>
                        <StateSettings/>
                      </ProtectedRoute>
                      <ProtectedRoute path="/state/reports" userType="state" allowedPermissions={[statePermissions.STATE_REPORTS_VIEW]}>
                        <StateReports/>
                      </ProtectedRoute>
                      <ProtectedRoute path="/state/forms" userType="state" allowedPermissions={[statePermissions.STATE_FORM_VIEW, statePermissions.STATE_FORM_EDIT]}>
                        <StateForms/>
                      </ProtectedRoute>
                      <ProtectedRoute path="/agency/applicationReview/:appState/:appId" userType="agency" allowedPermissions={[agencyPermissions.REVIEW_APPLICATION_EDIT, agencyPermissions.REVIEW_APPLICATION_VIEW]}>
                        <ApplicationReview />
                      </ProtectedRoute>
                      <ProtectedRoute path="/agency/reports" userType="agency" allowedPermissions={[agencyPermissions.REPORTS_VIEW]}>
                        <AgencyReports />
                      </ProtectedRoute>
                      <Route exact path="/">
                        <Styles />
                        <Homepage />
                      </Route>
                    </BrowserRouter>
                  </ConfigContextProvider>
                  <ErrorViewer>
                  </ErrorViewer>
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </ErrorContextProvider>
          </AuthContextProvider>
        </div>
        <div className={footer} >
          <Footer />
        </div>
      </div>
      </ThemeProvider>
    </StyledEngineProvider>

  </>;
}

export default App;