import React, { useState } from "react";
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  WbTwilight,
  AcUnit,
  ExpandMore,
  EditOutlined,
  WarningRounded,
} from "@mui/icons-material";
import { programTypes } from "../../../constants/programTypes";
import SeasonFundingTable from "./StateSeasonFundingTable";

const useStyles = makeStyles((theme) => ({
  dataRow: {
    padding: ".5rem 0",
  },
  summerIcon: {
    color: theme.palette.warning.main,
    marginRight: "0.5rem",
  },
  winterIcon: {
    color: theme.customColors.deepBlue,
    marginRight: "0.5rem",
  },
  closedIcon: {
    color: theme.customColors.errorIcon,
    marginRight: "0.5rem",
  },
  openAllButton: {
    textTransform: "none",
    padding: ".75rem",
  },
  spinner: {
    justifyContent: "center",
  },
}));

export default function StateSeasonView({ data, agencyData, onEdit, season }) {
  const classes = useStyles();
  const [openDates, setOpenDates] = useState(false);
  const [openSources, setOpenSources] = useState(false);
  const [openPolicies, setOpenPolicies] = useState(false);
  const [openAll, setOpenAll] = useState(false);

  const handleOpenAll = () => {
    setOpenAll((prevState) => {
      setOpenDates(!prevState);
      setOpenSources(!prevState);
      setOpenPolicies(!prevState);
      return !prevState;
    });
  };

  const formatDate = (date) => {
    const d = new Date(date);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      timeZoneName: "short",
    };
    return d.toLocaleDateString("en-US", options);
  };

  return (
    <>
      <Grid container item xs={12}>
        {season === "current" ? 
        <>
          {data.isOpen ? 
          <>
            {data?.programType === programTypes.SUMMER_COOLING && (<WbTwilight className={classes.summerIcon} />)}
            {data?.programType === programTypes.WINTER_HEATING && (<AcUnit className={classes.winterIcon} />)}
            <Typography>{`${data?.programType} OPEN`}</Typography>
          </> 
          : 
          <>
            <WarningRounded className={classes.closedIcon}/>
            <Typography>{`${data?.programType} CLOSED`}</Typography>
          </>
          }
        </>
        :
        <>
          {data?.programType === programTypes.SUMMER_COOLING && (<WbTwilight className={classes.summerIcon} />)}
          {data?.programType === programTypes.WINTER_HEATING && (<AcUnit className={classes.winterIcon} />)}
          <Typography>{data?.programType}</Typography>
        </>
      }
      </Grid>

      <Grid item container xs={12} justifyContent="flex-end">
        <Button
          className={classes.openAllButton}
          color="primary"
          onClick={onEdit}
          variant="text"
        >
          <EditOutlined />
          Edit
        </Button>
        <Button
          className={classes.openAllButton}
          color="primary"
          onClick={handleOpenAll}
          variant="text"
        >
          {openAll ? "Close All" : "Open All"}
        </Button>
      </Grid>

      <Grid item xs={12}>
        <Accordion expanded={openDates}>
          <AccordionSummary
            expandIcon={<ExpandMore className={classes.expandIcon} />}
            aria-label={`Program Dates`}
            id={`Program Dates`}
            onClick={() => setOpenDates((prevState) => !prevState)}
          >
            <Grid className={classes.summary} item xs={12}>
              <Typography variant="subtitle2">Program Dates</Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <Grid container>
              <Grid item xs={12} className={classes.dataRow}>
                <Typography variant="subtitle1">{`Season: ${data?.programType}\n`}</Typography>
              </Grid>
              <Grid item xs={12} className={classes.dataRow}>
                <Typography>{`Dates: ${formatDate(
                  data?.startDate
                )} - ${formatDate(data?.endDate)}`}</Typography>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={openSources}>
          <AccordionSummary
            expandIcon={<ExpandMore className={classes.expandIcon} />}
            aria-label={`Fund Sources`}
            id={`Fund Sources`}
            onClick={() => setOpenSources((prevState) => !prevState)}
          >
            <Grid className={classes.summary} item xs={12}>
              <Typography variant="subtitle2">Fund Sources</Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <Grid>
              <SeasonFundingTable
                data={data?.fundSources}
                agencyData={agencyData}
                editableVersion={false}
              />
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={openPolicies}>
          <AccordionSummary
            expandIcon={<ExpandMore className={classes.expandIcon} />}
            aria-label={`Policies`}
            id={`Policies`}
            onClick={() => setOpenPolicies((prevState) => !prevState)}
          >
            <Grid className={classes.summary} item xs={12}>
              <Typography variant="subtitle2">Policies</Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <Grid container>
              {data?.policies.map((policy) => (
                <Grid item xs={12} key={policy.id} className={classes.dataRow}>
                  <Typography>{policy.policyName}</Typography>
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </>
  );
}
