import { AccessTime, KeyboardArrowDown, KeyboardArrowUp, WarningAmber, FileCopyOutlined } from "@mui/icons-material";
import { Chip, Typography } from "@mui/material";
import { statuses } from "../../constants/appStatuses";
import { agencyCountyMap } from "../../constants/agencyCountyMap";

const getModalStyle = () => {
    const top = 50;
    const left = 50;
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`
    };
}

let filterTimer;
const retryFilter = (callback, limit) => {
  window.clearTimeout(filterTimer);
  filterTimer = window.setTimeout(callback, limit);
}

const getUrgentIcon = (val) => {
  if(val === 'yes'){
    return <AccessTime />
  }
  else if(val === 'confirmed'){
    return <><AccessTime /><Typography variant='subtitle1' style={{fontSize: '.75rem', fontWeight: 500}}>Confirmed</Typography> </>
  }
  else if(val === 'removed'){
    return <><AccessTime /><Typography variant='subtitle1' style={{fontSize: '.75rem', fontWeight: 500}} >Removed</Typography> </>
  }
  else{
    return ''
  }
}

const getDuplicateIcon = (status) => {
  if(status === 'yes'){
    return <FileCopyOutlined />
  }
  else if(status === 'confirmed'){
    return <><FileCopyOutlined /><Typography variant='subtitle1' style={{fontSize: '.75rem', fontWeight: 500}}>Confirmed</Typography> </>
  }
  else if(status === 'removed'){
    return <><FileCopyOutlined /><Typography variant='subtitle1' style={{fontSize: '.75rem', fontWeight: 500}} >Removed</Typography> </>
  }
  else{
    return ''
  }
}

const SortedDescendingIcon = () => {
  return <KeyboardArrowDown style={{color: '#fff'}}/>;
}

const SortedAscendingIcon = () => {
  return <KeyboardArrowUp style={{color: '#fff'}}/>;
}

let searchTimer;
const appSearch = (callback, limit) => {
  window.clearTimeout(searchTimer);
  searchTimer = window.setTimeout(callback, limit);
}

const getCountyOptions = (agencyId) => {
  const counties = agencyCountyMap[agencyId];
  return counties ?? [];
}

const getStateCountyOptions = (agencyState) => {
  const counties = agencyCountyMap[agencyState];
  return counties ?? [];
}

const getStatusChip = (val, classes, isAgency=false) => {
  switch (val) {
    case (statuses.APPROVED):
      return (<Chip variant="filled" className={`${classes.chip} ${classes.approved}`} label="Approved" />);
    case (statuses.DENIED):
      return (<Chip variant="filled" className={`${classes.chip} ${classes.denied}`} label="Denied" />);
    case (statuses.RETURNED):
      return (<Chip variant="filled" className={`${classes.chip} ${classes.returned}`} label="Returned" />);
    case (statuses.SUBMITTED):
      return (<Chip variant="filled" className={`${classes.chip} ${classes.submitted}`} label={isAgency ? "New" : "Submitted"} />);
    case (statuses.RE_SUBMITTED):
      return (<Chip variant="filled" className={`${classes.chip} ${classes.reSubmitted}`} label="Re-submitted" />);
    case (statuses.NEW):
      return (<Chip variant="filled" className={`${classes.chip} ${classes.inProgress}`} label="In Progress" />);
    case (statuses.ON_PAPER):
      return (<Chip variant="filled" className={`${classes.chip} ${classes.onPaper}`}  label="On Paper" />);
    case (statuses.PENDING):
      return (<Chip variant="filled" className={`${classes.chip} ${classes.pending}`}  label="Pending" />);
    case (statuses.ERROR):
      return (<Chip variant="outlined" className={`${classes.chip} ${classes.error}`} icon={<WarningAmber style={{color: '#BA1B1B'}}/>} label="Error" />);
    default:
      return <></>;
  }
}

export {
    getModalStyle,
    retryFilter,
    getUrgentIcon,
    SortedDescendingIcon,
    SortedAscendingIcon,
    appSearch,
    getCountyOptions,
    getStateCountyOptions,
    getStatusChip,
    getDuplicateIcon
}