import { Grid, Typography,Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ExpandMore } from '@mui/icons-material'
import React, {useEffect, useState} from 'react'
import { isEmptyObject } from '../../../../../utils/utilFunctions';

const useStyles = makeStyles((theme) => ({
  expandIcon: {
    color: theme.palette.primary.main,
    padding: '.75rem',
    [theme.breakpoints.down('md')]: {
      marginLeft: '1rem'
    }
  },
  details: {
    padding: '0 1rem 1rem 1rem'
  },
  field: {
    padding: '.5rem'
  },
  fieldBorder: {
    padding: '.5rem',
    borderBottom: '1px solid #c4c4c4'
  },
  fieldLabel:{
    padding: '.75rem 0',
    minHeight: '1.5rem',
    marginRight: '1rem',
    fontWeight: 'bold'
  },
  fieldValue:{
    color: '#000'
  },
  highlightValue:{
    color: theme.customColors.applicationStatus.approved
  },
  link: {
    color: theme.palette.primary.main
  }
}));

export default function ARReviewBenefitSection({ openAll, app, unearnedOptions, fuelSources, stateDocsLinks }) {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(true);
    const suggestedBenefit = app.suggestedBenefit;
    const crisisBenefits = suggestedBenefit?.suggestedCrisisBenefit;
    const hasCrisisBenefits = crisisBenefits && !isEmptyObject(crisisBenefits) && (app.urgent === "yes" || app.urgent === "confirmed");

    useEffect(() => {
        setExpanded(openAll)
        // eslint-disable-next-line
    }, [openAll]);

    const setExpandedValue = () => {
        setExpanded(prevState => {return !prevState})
    }

    const getField = (label, value, index, isBold, isDollars, showBorder, isHighlighted=false) => (
        <Grid container justifyContent="space-between" direction="row" key={`${label}-${index}`} className={showBorder? classes.fieldBorder : classes.field}>
                <Typography variant="subtitle2" fontWeight={isBold? 'bold' : 'normal'}>
                    <label htmlFor={`${label}-id`}>
                        {label}
                    </label>
                </Typography>
                <Typography variant="subtitle2" textAlign='right' className={isHighlighted ? classes.highlightValue: classes.fieldValue} fontWeight={isBold? 'bold' : 'normal'} > 
                    {isDollars? '$ ' : '' }
                    {value}
                </Typography>
        </Grid>
    )

    const getFieldLink = (label, link) => (
        <Grid container justifyContent="space-between" direction="row" className={classes.field}>
            <Typography variant="subtitle2" >
                <a href={link} target="_blank" rel="noreferrer" className={classes.link}>{label}</a>
            </Typography>
        </Grid>
    )

    const getFieldLabel = (fieldName, options) => {
        const retVal = options.find((item) => (item.value === fieldName));
        return retVal ? retVal.label : 'unknown';
    }

    if(!suggestedBenefit || isEmptyObject(suggestedBenefit))
        return <></>
    else 
        return (
            <Accordion expanded={expanded}>
                <AccordionSummary
                    expandIcon={<ExpandMore className={classes.expandIcon}/>}
                    aria-label={`benefit-content`}
                    id={`benefit-header`}
                    onClick={setExpandedValue}
                >
                    <Grid item xs={12}>
                        <Grid item xs={12}>
                            <Typography variant='subtitle2'>Suggested Benefit Amount</Typography> 
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                    <Grid container item xs={12} direction="row">
                        <Grid item md={12} lg={6} >
                            { suggestedBenefit.unearnedIncome.map((item, index) => (
                                    getField(getFieldLabel(item.label, unearnedOptions), item.value, index, false, true)
                                ))
                            }
                            { getField("Total Unearned Income", suggestedBenefit.unearnedIncomeTotal, 1, true, true, true) }
                            { suggestedBenefit.earnedIncome.map((item, index) => (
                                    getField(item.label, item.value, index, false, true, false)
                                ))
                            }
                            { getField("Total Earned Income", suggestedBenefit.earnedIncomeTotal, 1, true, true, false) }
                            { getField("80% of Earned Income", suggestedBenefit.earnedIncomePercentage, 1, true, true, true) }
                            { getField("Countable Income:", "", 0, true, false, false) }
                            { getField("Unearned Income + 80% Earned Income", suggestedBenefit.countableIncome, 1, true, true, true) }
                        </Grid>
                        <Grid item md={12} lg={6}>
                            <>
                            { getFieldLink("View Eligibility Chart", stateDocsLinks.eligibilityChart)}
                            { getField("Household Size", suggestedBenefit.householdSize, 1, false, false, false) }
                            { getField("Max Countable Income", suggestedBenefit.maxCountableIncome, 1, false, true, false) }
                            { getField("", suggestedBenefit.isEligible ? "Eligible" : "Not Eligible", 1, true, false, true, true) }
                            { getFieldLink("View Energy Source Chart Matrix", stateDocsLinks.energySourceCharts[suggestedBenefit.primarySource])}
                            { getField("Primary Source", getFieldLabel(suggestedBenefit.primarySource, fuelSources), 1, false, false, false) }
                            { getField("Benefit Payment Based On Household Size (Chart Matrix)", suggestedBenefit.suggestedBenefit, 1, true, true, hasCrisisBenefits, true) }
                            { hasCrisisBenefits &&
                                <>
                                { getField("Suggested Benefit Amount:", "", 0, true, false, false) }
                                { getField("Estimated Overdue Amount", crisisBenefits.overdueAmount, 1, false, true, false) }
                                { getField("Suggested Regular Benefit Amount", crisisBenefits.regularSuggestedAmount, 1, true, true, false, true) }
                                { getField("Suggested Crisis Benefit Amount", crisisBenefits.crisisSuggestedAmount , 1, true, true, false, true) }
                                { crisisBenefits?.overMaxBenefitsAmount > 0 &&
                                    <>
                                    { getField("Over Max Benefits Amount", crisisBenefits.overMaxBenefitsAmount , 1, true, true, false) }
                                    </>
                                }
                                </>
                            }
                            </>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        );
}
