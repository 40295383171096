
import React from 'react';
import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: "2rem 2.8rem",
    width: "95%"
  },
  text: {
    color: 'red'
  }
}));

export default function Unauthorized() {
  const classes = useStyles();
  
  return (
    <Grid xs={12} item container justifyContent="center" className={classes.mainContainer}>
      <Typography variant='h5' className={classes.text}>
        You are not authorized to view this page.
      </Typography>
    </Grid>
  );
}