import { Container, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useLiheapForm from '../../../../hooks/useLiheapForm';
import StepButtons from '../../../StepButtons';
import StepHeader from '../../../StepHeader';
import FinancialResources from '../FinancialResources';


const useStyles = makeStyles((theme) => ({
  mainContainer: {
    marginLeft: "0"
  }
}));

export default function ApplicantFinancialResources({ data, back, next, save, validate, validationSchema}) {
  const classes = useStyles();
  const { control, errors, register, watch } = useLiheapForm(save, data, validate, validationSchema, false);

  return (
    <Container className={classes.mainContainer}>
      <Grid container spacing={1}>
        <StepHeader 
          title="Applicant Financial Resources" 
          desc = "Please provide the following information for the applicant."
        />
        <FinancialResources 
          control={control}
          register={register}
          type="applicant"
          errors={errors}
          watch={watch}
        />
        <StepButtons back={back} next={next}/>
      </Grid>
    </Container>
  )
}
