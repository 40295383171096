import makeStyles from '@mui/styles/makeStyles';
import { useErrorViewer } from "../../../context/errorContext";
import { useMutation, useQueryClient } from 'react-query';
import { useState, useEffect } from 'react';
import { FormControl, Grid, MenuItem, Select, Typography, Modal, Button, CircularProgress } from '@mui/material';
import { updateAssignee } from '../../../api/AgencyAPI';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const useStyles = makeStyles((theme) => ({
  select: {
    padding: "0.5rem 0",
    width: "10rem"
  },
  label: {
    fontWeight: "500", 
    paddingRight: '.5rem'
  },
  cell: {
    fontWeight: '500',
    paddingLeft: '.5rem',
    width: '100%',
    whiteSpace: 'break-spaces',
    lineHeight: '1.5rem',
    overflowWrap: 'anywhere'
  },
  spinner: {
    margin: 'auto'
  },
  modal: {
    position: 'absolute',
    background: theme.customColors.modalBackground,
    boxShadow: '0 0 .3rem rgba(0, 0, 0, 0.2)',
    bozSizing: 'inherit',
    borderRadius: '1.75rem',
    padding: '3rem',
    width: '40rem'
  },
  modalHeader:{
    marginBottom: '1rem'
  },
  modalButton: {
    marginTop: '1rem',
    textTransform: 'none'
  }
}));

export default function AssignAgencyUser({applicationId, canEdit, options, value, valueLabel, label="", showLabel=false, onClose}){
  const classes = useStyles();
  const queryClient = useQueryClient();
  const setError = useErrorViewer();
  const [currentValue, setCurrentValue] = useState(value ? value : "Unassigned");

  const [loading, setLoading] = useState(false);

  const [openModal, setOpenModal] = useState(false)
  const [modalStyle] = useState(getModalStyle);

  const saveAssignedUserMutation = useMutation(assigneeUpdateObject => {
    return updateAssignee(assigneeUpdateObject);
  })

  useEffect(() => {
    if(value){
      setCurrentValue(value)
    }
  }, [value]);

  const handleChange = (event) => {
    setLoading(true);
    const selectedOption = options.find((option) => option.value === event.target.value);
    let assigneeUpdateObject = {};
    if(selectedOption.value === 'Unassigned'){
      assigneeUpdateObject = {
        applicationId: applicationId,
        userId: selectedOption.value,
        firstName: '',
        lastName: selectedOption.label
      };
    }
    else{
     assigneeUpdateObject = {
      applicationId: applicationId,
      userId: selectedOption.value,
      firstName: selectedOption.firstName,
      lastName: selectedOption.lastName
      };
    }

    const previousOptionValue = currentValue;
    setCurrentValue(selectedOption.value);

    saveAssignedUserMutation.mutate(assigneeUpdateObject, {
      onSuccess: (response) => {
        setLoading(false);
        setOpenModal(true);
        queryClient.invalidateQueries(['applicationByIdForAgent', { id: applicationId }]);
      },
      onError: (error) => {
        setLoading(false);
        setCurrentValue(previousOptionValue);
        setError('Error assigning user.');
      }
    })

  }

  const handleCloseModal = () => {
    setOpenModal(false);
    if(onClose)
    {
      onClose();
    }
  };

  return (
    <>
    {loading ? 
      <Grid container justifyContent="center" className={classes.spinner}>
        <CircularProgress color="primary" />
      </Grid>
    :
    <Grid container alignItems="center">
      {showLabel &&
        <Grid item>
          <Typography variant="subtitle1" className={classes.label}>{label}</Typography>
        </Grid>
        }  
      {canEdit 
        ?
          <Grid item>
            <FormControl fullWidth className={classes.select} size="small">                
              <Select
                style={{borderRadius: 8, fontWeight: 500}}
                id={`assign_user_${applicationId}`}
                value={currentValue}
                onChange={handleChange}
              >
                {options.map((option) => (
                  <MenuItem key={option.value} value={option.value} className={classes.label}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        :
          <Grid item>
            <Typography variant='subtitle1' className={classes.cell}>{valueLabel}</Typography>
          </Grid>
      }
    </Grid>
}
    <Modal open={openModal} onClose={handleCloseModal}>
        <Grid  style={modalStyle} className={classes.modal}>
        <Grid item container justifyContent="flex-start">
          <Typography variant="h5" className={classes.modalHeader}>
            Success
          </Typography>
        </Grid>
        <Grid item container justifyContent="flex-start">
          <Typography variant="subtitle2">
            Agent was assigned to the application
          </Typography>
        </Grid>
        <Grid item container justifyContent="flex-end" >
          <Button className={classes.modalButton} onClick={handleCloseModal} variant="text" color="primary">
            OKAY
          </Button>
        </Grid>
        </Grid>
      </Modal>
    </>
  )
}
