import { Container, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FormTextField from '../../../form/FormTextField';
import FormSelect from '../../../form/FormSelect';
import FormRadioAsButtons from '../../../form/FormRadioAsButtons';
import FormCheckboxes from '../../../form/FormCheckboxes';
import StepHeader from '../../../StepHeader';
import StepButtons from '../../../StepButtons';
import { relationToApplicantOptions } from '../../../../constants/dropdowns/relationToApplicantOptions';
import { useParams } from 'react-router';
import FormDatePicker from '../../../form/FormDatePicker';
import FormSection from '../../../FormSection';
import { yesNoOptions } from '../../../../constants/radios/yesNoOptions';
import { genderOptions } from '../../../../constants/radios/genderOptions';
import { raceOptions } from '../../../../constants/ar/checkboxes/raceOptions';
import { ethnicityOptions } from '../../../../constants/radios/ethnicityOptions';
import useLiheapForm from '../../../../hooks/useLiheapForm';
import FormSSN from '../../../form/FormSSN';
import { birthDateOnEditSchema } from '../../../../constants/ar/stepValidationSchemas';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    marginLeft: "0"
  },
  row: {
    width: "auto"
  },
  sectionHeader: {
    fontWeight: "bold"
  }
}));

export default function MemberPersonalInfo({ data, back, next, save, validate, validationSchema }) {
  const { nestedItemId } = useParams();
  const classes = useStyles();
  const nestedSave= (formData) => {
    formData.id = nestedItemId;  //add nestedItemId to returned formData
    save(formData);
  }
  const memberData = data?.householdMembers?.find(x => x.id === nestedItemId);
  const validateOnEditValidationSchema = birthDateOnEditSchema;

  const { control, errors, register, trigger } = useLiheapForm(nestedSave, memberData, validate, validationSchema, false, validateOnEditValidationSchema);

  const getTitle = () => {
    const itemIndex = memberData && (data?.householdMembers?.indexOf(memberData) >= 0) ? data?.householdMembers?.indexOf(memberData) + 1 : data?.householdMembers?.length + 1 || 1;
    return memberData && memberData.lastName && memberData.firstName ? `${memberData.firstName} ${memberData.lastName}` : `Household Member #${itemIndex}`;
  }

  return (
    <Container className={classes.mainContainer}>
        <Grid container spacing={1}>
          <StepHeader 
            title={getTitle()} 
            desc = "Please provide the following information for this household member."
          />
          <FormSection>
            <Grid item width="auto">
              <Typography className={classes.sectionHeader} variant="h6">General Information</Typography>
            </Grid>
            <Grid className={classes.row} container spacing={1}>
              <Grid item xs={12} sm={6}>
                <FormTextField name="firstName" label="First Name" register={register} errors={errors}/>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormTextField name="lastName" label="Last Name" register={register} errors={errors}/>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormTextField name="middleName" label="Middle Name" register={register} errors={errors}/>
              </Grid>             
              <Grid item xs={12} sm={6}>
                <FormDatePicker
                  control={control}
                  name="birthDate"
                  label="Date of Birth"
                  defaultValue={memberData?.birthDate}
                  InputAdornmentProps={{ position: "end" }}
                  id="birthDate-date-picker"
                  errors={errors}
                  variant='outlined'
                  userType='applicant'
                  validationTrigger={trigger}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormSSN name="ssn" register={register} errors={errors} validate={validate} trigger={trigger}/>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormSelect 
                  name="relationToApplicant" 
                  label="Relation to Applicant"
                  labelId="relationToApplicant-label"
                  labelPlacement="inside"
                  control={control}
                  errors={errors}
                  options={relationToApplicantOptions}
                />
              </Grid>
              </Grid>
          </FormSection>
          <FormSection>
            <Grid item xs={12}>
              <Typography className={classes.sectionHeader} variant="h6">Personal Information</Typography>
            </Grid>
            <Grid className={classes.row} item xs={12} container justifyContent="flex-start">
              <FormRadioAsButtons
                control={control}
                name="gender"
                label="What is this household member's gender?"
                errors={errors}
                options={genderOptions}
              />  
            </Grid>
            <Grid className={classes.row} item xs={12} container justifyContent="flex-start">
              <FormCheckboxes 
                defaultValues={memberData?.race}
                register={register}
                name="race"
                label="What is this household member's race? Please check all that apply."
                errors={errors}
                options={raceOptions}
                gridSize={12}
                noOption="Prefer not to say"
              />    
            </Grid>
            <Grid className={classes.row} item xs={12} container justifyContent="flex-start">
              <FormRadioAsButtons
                control={control}
                name="ethnicity"
                label="What is this household member's ethnicity?"
                errors={errors}
                options={ethnicityOptions}
              />
            </Grid>
            <Grid className={classes.row} item xs={12} container justifyContent="flex-start">
              <FormRadioAsButtons
                control={control}
                name="disabled"
                label="Does this household member have a disability?"
                errors={errors}
                options={yesNoOptions}
              />    
            </Grid>
            <Grid className={classes.row} item xs={12} container justifyContent="flex-start">
              <FormRadioAsButtons
                control={control}
                name="veteranOrActivelyServing"
                label="Is this household member a veteran or actively serving in the military?"
                errors={errors}
                options={yesNoOptions}
              />      
            </Grid>
          </FormSection>
          <StepButtons back={back} next={next}/>
        </Grid>
    </Container>
  )
}
