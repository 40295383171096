import makeStyles from '@mui/styles/makeStyles';
import { useMutation } from 'react-query';
import React, { useState } from 'react'
import { Grid, Typography, Container, Button, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import FormTextField from '../../form/FormTextField';
import { createStateForm } from '../../../api/FileAPI';
import { useErrorViewer } from '../../../context/errorContext';
import { useForm } from 'react-hook-form';
import FormRadioAsButtons from '../../form/FormRadioAsButtons';
import { yesNoOptions } from '../../../constants/radios/yesNoOptions';
import FormUpload from './FormUpload';
import ConfirmationDialog from '../../ConfirmationDialog';
import { StateFormUploadSchema } from '../../../constants/stateValidationSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import SuccessModal from '../../SuccessModal';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: '#FDFDFD',
    height: '100%',
    overflow: 'scroll',
    padding: '1rem 1rem 3rem 1rem',
    borderRadius: '.75rem',
    boxShadow: '0px 4px 4px 0px #00000040'
  }, 
  tabHeader: {
    marginBottom: '1rem',
    alignItems: 'center'
  }
}));


export default function StateFormUpload( { onClose, onUpload, state } ) {
  const classes = useStyles();
  const setError = useErrorViewer();

  const [file, setFile] = useState();
  const [fileInfo, setFileInfo] = useState();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationContent, setConfirmationContent] = useState();
  const [loading, setLoading] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  const { control, errors, register, getValues, handleSubmit, trigger} = useForm({ 
    mode: 'onChange',
    resolver: yupResolver(StateFormUploadSchema)
  })

  const onSubmit = async() => {
    const valid = await trigger();
    if(valid){
      const fileObj = {
        file: file,
        state: state,
        title: getValues('title'),
        required: getValues('required'), 
        description: getValues('description')
      }
      setFileInfo(fileObj)
      setConfirmationContent(getConfirmationContent(fileObj));
      setShowConfirmation(true);
    }
  };

  const submitMutation = useMutation(formData => {
    return createStateForm(formData);
  })

  const handleConfirmation = () => {
    setLoading(true);
    submitMutation.mutate(fileInfo, {
      onSuccess: () => {
        setSuccessModalOpen(true);
      },
      onError: (err) => { setError(err.response?.data ?? "An error occurred uploading your form."); }
    })
    setLoading(false);
    setShowConfirmation(false);
  }

  const getConfirmationContent = (fileInfo) => {
    return (
      <Grid>
        <Typography>
          {`Are you sure you want to add the ${fileInfo?.title} form?`}
        </Typography>
        <Typography>
          {`Document Title: ${fileInfo?.title}`}
        </Typography>
        <Typography>
          {`Document PDF file: ${fileInfo?.file.name}`}
        </Typography>
        <Typography>
          {`Is this form required for all applicants: ${fileInfo?.required}`}
        </Typography>
        <Typography>
         {`Document Description: ${fileInfo?.description}`}
        </Typography>
      </Grid>
    )
  }

  return (
    <>
    <form  autoComplete="false" >
      <Container className={classes.mainContainer}>
        <Grid container item xs={12}>
          <Grid className={classes.tabHeader} container item xs={12}>
            <Grid container item xs={10}>
              <Typography variant='h6'>
                Add New Form
              </Typography> 
            </Grid>
            <Grid container item xs={2} justifyContent='flex-end'>
              <IconButton onClick={() => onClose(false)}>
                <Close/>
             </IconButton>
            </Grid>
          </Grid>
          <Grid container>
            <Typography variant='subtitle2'>
              Provide a link and description below for the forms that your agency requires for all or certain applicants. Applicants will be able to access the links and upload the relevant form in their application. 
            </Typography> 
            <Grid item xs={12}>
              <Typography variant='subtitle1' fontWeight='bold'>
                Document's Title
              </Typography> 
              <FormTextField
                className={classes.textbox}
                disabled={loading}  
                name="title" 
                label="Add document title"
                register={register} 
                errors={errors}     
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='subtitle1' fontWeight='bold'>
                Document's PDF file
              </Typography> 
              <FormUpload changeHandler={setFile} register={register} errors={errors}/>
            </Grid>
            <Grid item xs={12}>
              <FormRadioAsButtons
                label={"Is this form required for all applicants?"}
                control={control}
                name="required"
                errors={errors}
                options={yesNoOptions}
                defaultValue=""
                inline={true}
                disabled={loading}  
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                className={classes.textbox}
                disabled={loading}  
                name="description" 
                label="Document Description"
                multiline
                rows={4}
                register={register} 
                errors={errors}     
              />
            </Grid>
            <Grid item xs={12} container justifyContent="flex-end">
              <Button 
                className={classes.notifyButton} 
                onClick={() => handleSubmit(onSubmit())} 
                variant="contained" 
                color="primary"
              >
                  Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </form>

    <ConfirmationDialog
      open={showConfirmation}
      title={`Add New Form`}
      contentChildren={confirmationContent}
      cancelLabel="Cancel"
      okLabel="Save"
      handleCancel={() => setShowConfirmation(false)}
      handleOk={() => handleConfirmation()}
    />

    <SuccessModal
      open={successModalOpen}
      title={"New Form Added"}
      desc={`${fileInfo?.title} form will be posted on the platform.`}
      okLabel="Okay"
      handleOk={() => onUpload()}
    />
    </>
  )
}
