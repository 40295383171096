import { Grid, CssBaseline, IconButton, InputAdornment, Container, Button, CircularProgress, Link, TextField, Typography, Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Redirect, useParams } from 'react-router-dom';
import { getInvitation, userConfirm, userResendConfirm } from '../../../api/UserAPI';
import makeStyles from '@mui/styles/makeStyles';
import SecurityIcon from '@mui/icons-material/Security';
import Avatar from '@mui/material/Avatar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Visibility, VisibilityOff, Check, Clear  } from '@mui/icons-material';
import 'react-phone-input-2/lib/material.css';
import { acceptInvite } from '../../../api/UserAPI';
import { useQuery } from 'react-query';
import { useErrorViewer } from '../../../context/errorContext';


const useStyles = makeStyles((theme) => ({
  successInput: {
    color: theme.palette.success.main
  },
  errorInput: {
      color: theme.palette.error.main
  },
  grayInput: {
      color: theme.customColors.lightMedGray
  },
  congtxtinput: {
    textAlign: "center",
    letterSpacing: "0.7em",
    fontSize: 20
  },
  confcodemuifield: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  },
  paper: {
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: "center",
    background: "#ffffff",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.dark,
    fontWeight: "bolder"
  },
  link: {
    margin: theme.spacing(1),
    color: theme.palette.primary.dark,
  },

  phone: {
    backgroundImage: 'none',
  },
  form: {
    width: 'auto', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  passwordReqs: {
    alignItems: 'flex-start'
  },
  reqs: {
    display: 'flex'
  },
  icon: {
    height: '1rem',
    marginTop: '.2rem'
  },
  spinner:{
    position: "relative", 
    textAlign: "center",
    marginTop: '50vh',
  },
}));
export default function AgencyRegister() {
  const [formData, setFormData] = useState({
    password: "", confirmPassword: "", confirmCode: "", inviteId: ""
  });
  const { inviteId } = useParams();

  const { data, isFetching } = useQuery(['invitation', { inviteId: inviteId }], async () => {
    const { data } = await getInvitation(inviteId);
    return data;
  }, { enabled: !!inviteId });

  useEffect(() => {
    setFormData({
      ...formData,
      "inviteId": inviteId || ""
    });
    // eslint-disable-next-line
  }, []);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [userName, setUserName] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navToLogin, setNavToLogin] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [invalidConfirmCode, setInvalidConfirmCode] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);

  const [eightCharError, setEightCharError] = useState(false);
  const [lowercaseError, setLowercaseError] = useState(false);
  const [uppercaseError, setUppercaseError] = useState(false);
  const [numberError, setNumberError] = useState(false);
  const [specialCharError, setSpecialCharError] = useState(false);
  const [confirmPassMatch, setConfirmPassMatch] = useState(false);

  const [showPASS, setShowPASS] = useState(false);
  const [showConfirmPASS, setShowConfirmPASS] = useState(false);
  const setError = useErrorViewer();

  const classes = useStyles();


  const getInputClasses = (val, isErr) => {
    return `${classes.reqs} ${val ? isErr ? classes.successInput : classes.errorInput : classes.grayInput}`
  }

  const handleChange = (event) => {
    let key = event.target.name;
    let value = event.target.value;
    if(key === 'password'){
      let regex1 = /^.{8,}$/;
      setEightCharError(regex1.test(value));
      
      let regex2 = /^.*(?=.*[A-Z]).*$/;
      setUppercaseError(regex2.test(value));
      
      let regex3 = /^.*(?=.*\d).*$/;
      setNumberError(regex3.test(value));
      
      let regex4 = /^.*(?=.*[!$@%]).*$/;
      setSpecialCharError(regex4.test(value));

      let regex5 = /^.*(?=.*[a-z]).*$/;
      setLowercaseError(regex5.test(value));

      setConfirmPassMatch(value === formData.confirmPassword)
    }
    if(key === 'confirmPassword'){
      setConfirmPassMatch(value === formData.password)
    }
    setFormData({
      ...formData,
      [key]: value
    });
  };

  const handleMouseDownPASS = (event) => {
    event.preventDefault();
  };

  const validateConfirmCode = (confirmCode) => {
    const regex = /^\d{6}$/

    return regex.test(confirmCode);
  }

  const enableSignUp = () => {
    return eightCharError && lowercaseError && uppercaseError && numberError && specialCharError && confirmPassMatch;

  }

  const handleSubmit = () => {
    setLoading(true);
    acceptInvite(formData).then((response) => {
      setShowConfirmation(true);
      setUserName(response["data"]["email"])
      setLoading(false);
    }).catch((error) => {
      setLoading(false);
      const err = error.response.data? error.response.data.message : "Unknown Error";
      setError(err);
    });
  }

  const handleConfirm = () => {
    setConfirmLoading(true);
    const confirmCode = formData["confirmCode"]
    userConfirm(userName, confirmCode).then(() => {
      setConfirmLoading(false);
      setNavToLogin(true);
    }).catch((error) => {
      setConfirmLoading(false);
      setFormData({ ...formData, "confirmCode": "" });
      setInvalidConfirmCode(true);
    })
  }

  const handleResendCode = () => {
    setResendLoading(true);
    userResendConfirm(userName).then(() => {
      setResendLoading(false);
    }).catch((error) => {
      setResendLoading(false);
      alert(error.response.data);
    })
  }

  if(isFetching)
    return (
      <Grid container item justifyContent="center" className={classes.spinner}>
        <CircularProgress color="primary" size={50}/>
      </Grid>
    )

  if (navToLogin)
    return (
      <Redirect to="/login"></Redirect>
    )
  if (showConfirmation)
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <SecurityIcon />
          </Avatar>
          <Typography variant="body2" gutterBottom>
            Protecting your data is our top priority. Please confirm your account by entering the code sent to <b>{data?.email}</b>.
          </Typography>

          <TextField
            className={classes.confcodemuifield}
            id="confirmCode"
            variant="outlined"
            margin="normal"
            value={formData.confirmCode}
            name="confirmCode"
            label="Six Digit Code"
            onChange={handleChange}
            placeholder="000000"
            type="number"
            //helperText={`${formData.confirmCode.length}/6`}
            error={invalidConfirmCode}
            inputProps={{
              inputMode: 'tel',
              max: 6,
              className: classes.congtxtinput,
            }}
          />

          {/* {resendLoading ?
                        <CircularProgress color="inherit" size={30} /> :
                        <Button component={Link} onClick={handleResendCode}> Didnt get a code?Resend a new code</Button>
                    } */}


          <Grid container>
            {confirmLoading ?
              <CircularProgress color="primary" size={36} /> :
              <Button
                className={classes.submit}
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                disabled={!validateConfirmCode(formData.confirmCode)}
                onClick={handleConfirm}
              >
                Verify My Account
              </Button>
            }
            {resendLoading ?
              <CircularProgress color="primary" size={30} /> :
              <Grid item>
                <Typography variant="caption" >It may take a minute to get the code. Haven't received it?</Typography>
                <Link href="#" className={classes.link} variant="caption" onClick={handleResendCode}>
                  {<b>Resend again.</b>}
                </Link>
              </Grid>
            }
          </Grid>
        </div>
      </Container>
    )
  else
    return (
      <Container component="main" >
        <CssBaseline />
        <div className={classes.paper}>
        {data ?
          <>
          <Avatar className={classes.avatar}>
            <AccountCircleIcon />
          </Avatar>
          <Typography component="h1" variant="h6">
            {data?.firstName} {data?.lastName}
          </Typography>
          <Typography component="h1" variant="h6">
            {data?.email}
          </Typography>
          <form className={classes.form} noValidate>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPASS ? 'text' : 'password'}
                id="password"
                onChange={handleChange}
                value={formData.password}
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPASS(!showPASS)}
                        onMouseDown={handleMouseDownPASS}
                        edge="end"
                        size="large">
                        {showPASS ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm"
                type={showConfirmPASS ? 'text' : 'password'}
                id="confirmPassword"
                onChange={handleChange}
                value={formData.confirmPassword}
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowConfirmPASS(!showConfirmPASS)}
                        onMouseDown={handleMouseDownPASS}
                        edge="end"
                        size="large">
                        {showConfirmPASS ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                }}
              />
            </Grid>
            <Grid item container direction="column" className={classes.passwordReqs}>
              <Grid item className={getInputClasses(formData.password, eightCharError)}>
                {formData.password && eightCharError && <Check className={classes.icon} />}
                {formData.password && !eightCharError && <Clear className={classes.icon} />}
                <Typography variant="subtitle2">
                  At least 8 characters
                </Typography>
              </Grid>
              <Grid item className={getInputClasses(formData.password, lowercaseError)}>
                {formData.password && lowercaseError && <Check className={classes.icon} />}
                {formData.password && !lowercaseError && <Clear className={classes.icon} />}
                <Typography variant="subtitle2">
                  At least one lowercase letter (a-z)
                </Typography>
              </Grid>
              <Grid item className={getInputClasses(formData.password, uppercaseError)}>
                {formData.password && uppercaseError && <Check className={classes.icon} />}
                {formData.password && !uppercaseError && <Clear className={classes.icon} />}
                <Typography variant="subtitle2">
                  At least one uppercase letter (A-Z)
                </Typography>
              </Grid>
              <Grid item className={getInputClasses(formData.password, numberError)}>
                {formData.password && numberError && <Check className={classes.icon} />}
                {formData.password && !numberError && <Clear className={classes.icon} />}
                <Typography variant="subtitle2">
                  At least one number (0-9)
                </Typography>
              </Grid>
              <Grid item className={getInputClasses(formData.password, specialCharError)}>
                {formData.password && specialCharError && <Check className={classes.icon} />}
                {formData.password && !specialCharError && <Clear className={classes.icon} />}
                <Typography variant="subtitle2">
                  At least one special character (!$@%)
                </Typography>
              </Grid>
              <Grid item className={getInputClasses(formData.password && formData.confirmPassword, confirmPassMatch)}>
                {formData.password && formData.confirmPassword && confirmPassMatch && <Check className={classes.icon} />}
                {formData.password && formData.confirmPassword && !confirmPassMatch && <Clear className={classes.icon} />}
                <Typography variant="subtitle2">
                  Confirmed password must match
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {loading ? <CircularProgress color="primary" size={36} /> :
                <Button
                  className={classes.submit}
                  fullWidth
                  id="submit"
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!enableSignUp()}
                  onClick={handleSubmit}>
                  Sign up
                </Button>
              }
            </Grid>
            <Grid container>
              <Grid item>
               <Box mt={3}>
                  <Typography variant="body2">
                      By continuing, you agree to Entergy's{' '}
                      <Link href="/termsOfUse" variant="body2">                                    
                          {"Terms of Service"}
                      </Link>
                      {' '}and acknowledge you've read our {' '}
                      <a href="https://www.entergy.com/privacy-policy/" target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
                          Privacy Policy
                      </a>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </form>
        </>
          :
        <Grid>
          <Typography variant="h6">
            This invitation is no longer valid. Please contact your Agency Admin to get a new invitation.
          </Typography>
        </Grid>
}
        </div>

      </Container>
    );
}
