import { FormControl, FormHelperText, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'
import { Controller } from 'react-hook-form';
import { getErrorMessage } from '../../utils/utilFunctions';

const useStyles = makeStyles(theme => ({
  label: {
    fontWeight: "bold", 
    marginBottom: ".6rem"
  },
  standardSelect: {
    margin: 0
  },
  standardInputLabel: {
    zIndex: '999'
  },
  dropdown: {
    maxHeight: 250
  },
  controller: {
    backgroundColor: '#fff'
  }
}));

export default function FormSelect({ control, name, label, labelId, labelPlacement, errors, options, width, maxInputWidth, defaultValue, variant="outlined", ...rest }) {
  const classes = useStyles();
  const selectProps = {
    ...((labelPlacement && labelPlacement === 'inside') && { labelId: labelId, label: label}) 
  }
  const _width = !width ? "100%" : width;
  const _maxInputWidth = !maxInputWidth ? "100%" : maxInputWidth;

  const optionIsObject = (option) => {
    return option.value || option.value === ''
  }

  if(variant==="standard"){
    return (
      <FormControl className={classes.standardSelect} component="fieldset" margin='normal' variant={variant} error={Boolean(getErrorMessage(errors, name))} style={{width: _width}}>
          <InputLabel id={labelId} className={classes.standardInputLabel}>{label}</InputLabel>
        <Controller
          as={
            <Select { ...selectProps }  MenuProps={{ classes: { paper: classes.dropdown } }} {...rest}> 
              {options.map((option) => {
              const isObject = optionIsObject(option)
              return (
                <MenuItem key={isObject ? option.value : option} value={isObject ? option.value : option}>
                  {isObject ? option.label : option}
                </MenuItem>
              )})}
            </Select>
          }
          name={name}
          control={control}
          defaultValue={defaultValue ? defaultValue : ""}
          style={{maxWidth: _maxInputWidth}}
        />
        <FormHelperText>{getErrorMessage(errors, name)}</FormHelperText>
      </FormControl>
    )
  }
  else{
  return (
    <FormControl component="fieldset" margin='normal' variant={variant} error={Boolean(getErrorMessage(errors, name))} style={{width: _width}}>
      {(labelPlacement && labelPlacement === 'inside') 
        ?
        <InputLabel id={labelId}>{label}</InputLabel>
        :
        <Typography variant="subtitle1" className={classes.label}>{label}</Typography>
      }
      <Controller
        as={
          <Select { ...selectProps }  MenuProps={{ classes: { paper: classes.dropdown } }} {...rest}> 
            {options.map((option) => {
              const isObject = optionIsObject(option)
              return (
                <MenuItem key={isObject ? option.value : option} value={isObject ? option.value : option}>
                  {isObject ? option.label : option}
                </MenuItem>
              )})}
          </Select>
        }
        name={name}
        control={control}
        defaultValue={defaultValue ? defaultValue : ""}
        className={classes.controller}
        style={{maxWidth: _maxInputWidth}}
      />
      <FormHelperText>{getErrorMessage(errors, name)}</FormHelperText>
    </FormControl>
  )
      }
}
