import * as Yup from 'yup';

const requiredError = "Required Information";

export const StateFormUploadSchema = Yup.object().shape({
  title: Yup.string().required(requiredError),
  required: Yup.string().required(requiredError),
  description: Yup.string().required(requiredError),
  file: Yup.mixed().test("file", requiredError, (value) => { if (value?.length > 0){ return true; } return false; })
})
